import React from 'react';
import {Card,Button,Popconfirm,message} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import {StandardTable} from '../../../sharedCommponents';
import { get,delHttp } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SearchForm from './SearchForm';
import AddAuthorityForm from './AddAuthorityForm';
import pcService from './../../../other/pc.service';
import constantConfig from './../../../configs/constant.config';

class AuthorityList extends React.Component {

    
    constructor(props)
    {
        super(props);

        this.state={
            loading:false,
            menu:{},
            selectedRows: [],
            data: 
            {
                list: [],
                pagination: {
                    pageIndex: 0,
                    pageSize: 10,
                    total:0
                }
            },
            searchInitValue:{
                keyword:''
            }
        }
    }

 

    columns=[
        {
            title: '名称',
            dataIndex: 'authorityName'
        },
        {
            title: '编号',
            dataIndex: 'authorityCode'
        }
    ]

    componentWillMount()
    {
        this.initMenuInfo();
    }
    
    initMenuInfo=()=>{
        if (this.props.menu.menuId) {
           get({
            url: apiConfig.rui.basic.menu.getMenuById,
            params: {
                menuId: this.props.menu.menuId
            }
        }).then((res) => {
            this.setState({
                loading: false,
                menu: {
                    ...res
                }
            },this.fetch)
        }).catch((error) => {
            this.setState({menu: {}});
        });
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps.menu&&nextProps.menu.menuId!==this.props.menu.menuId)
        {
            this.setState({
                data: 
                {
                    list: [],
                    pagination: {
                        pageIndex: 0,
                        pageSize: 10,
                        total:0
                    }
                },
                menu:nextProps.menu
            },()=>{
                this.fetch({});
            })
        }
    }
 
    fetch=(params = {})=>{
        this.setState({loading:true});
        let {pagination}=this.state.data;
        let pageIndex=pagination.pageIndex;
        let pageSize=pagination.pageSize;
        let keyword=this.state.searchInitValue.keyword;
        let menu=this.state.menu;
        if(params)
        {
            if(params.pagination)
            {
                pageIndex=params.pagination.pageIndex||0;
                pageSize=params.pagination.pageSize||pageSize;
            }
            if(!params.keyword)
            {
                keyword='';
            }
            else
            {
                keyword=params.keyword
            }
        }
        let queryParams={
            pageIndex,
            pageSize,
            keyword,
            menuId:menu.menuId
        };
        get({
            url:apiConfig.rui.basic.menu.getAuthoritiesById,
            params:queryParams
        }).then((res)=>{
            if(res)
            {
                this.setState({
                    loading:false,
                    data:{
                        list:res.list,
                        pagination:{
                            pageIndex: queryParams.pageIndex,
                            pageSize: queryParams.pageSize,
                            total:res.total
                        }
                    },
                    searchInitValue:{
                        keyword:queryParams.keyword
                    }
                });
            }
            else
            {
                this.setState({ loading:false});
            }
        }).catch((error)=>{
            this.setState({ loading:false});
        });
    } 

    handleSelectRows = rows => {
        this.setState({
            selectedRows: rows,
        });
    };

    handleStandardTableChange = pagination => {
        const params = {
            pagination:{
                pageIndex: pagination.current-1,
                pageSize: pagination.pageSize
            }
        };
        this.fetch(params);
    };

    handleSearch=(seacheValues)=>{
         let params;
         if(seacheValues)
         {
            params = {
                pagination:{
                    pageIndex: 0
                },
                keyword:seacheValues.keyword
            };
         }
         else
         {
            params = {
                pagination:{
                    pageIndex: 0
                },
                keyword:''
            };
         }
         this.fetch(params);
    }

    handleDeleteAuthorities=()=>{
        let {selectedRows}=this.state;
        let authorityIds='';
        if(selectedRows.length===0)
        {
            message.info('请选择要删除的功能');
            return;
        }
        for(let selectedRow of selectedRows)
        {
            authorityIds+=selectedRow.authorityId+',';
        }
        if(authorityIds)
        {
            authorityIds=authorityIds.substr(authorityIds,authorityIds.length-1);
        }
        //console.log("authorityIds",authorityIds);
        this.setState({loading:true});
        delHttp({
            url:apiConfig.rui.basic.menu.deleteAuthorities,
            params:{
                menuId:this.state.menu.menuId,
                authorityIds:authorityIds
            }
        }).then((res)=>{
            message.success(constantConfig.DEL_SUCCESS);
            this.setState({
                selectedRows:[]
            },()=>{
                const params = {
                    pagination:{
                        pageIndex: 0
                    }
                };
                this.fetch(params);
            });
        }).catch((error)=>{
            message.error(constantConfig.DEL_FAIL);
            this.setState({ loading:false});
         });
    }

    handleReturn=()=>{
        let panelId = pcService.getIdByPath("/app/menuSetAuthorities");
        pcService.removePanel(panelId);
        //this.props.removeTabPane(this.props.paneKey);
    }

    render() {
        const {/*selectedRows,*/loading, data,searchInitValue,menu} = this.state;
        return (
           <div>
               <Card bordered={false}>
                   <div className="operationTitle">
                        <h1>
                           {`设置【${menu.menuName}】的所属功能`}
                        </h1>
                   </div>
                   <Card title="新增所属功能" bordered={false} >
                        <AddAuthorityForm setMenuId={menu.menuId} onSeach={()=>{this.handleSearch()}} />
                   </Card>
                   <Card title="查询所属功能" bordered={false} >
                        <div className='tableSearchForm'>
                                    <SearchForm
                                        searchKey={'关键字'}
                                        handleSearch={this.handleSearch}
                                        searchInitValue={searchInitValue}
                                    />
                        </div>
                        <div className='tableAction' style={{marginLeft:120}}>
                                <Popconfirm title="您确定要删除选中的功能吗?" onConfirm={()=>this.handleDeleteAuthorities()} okText="是" cancelText="否">
                                        <Button
                                                icon="delete"
                                                type="primary"
                                                dataaction="create"
                                                loading={loading}
                                            >
                                                批量删除
                                        </Button>
                                </Popconfirm>
                                <Button
                                        icon="arrow-left"
                                        type="primary"
                                        dataaction="create"
                                        onClick={() => this.handleReturn()}
                                    >
                                        返回
                                </Button>
                        </div>
                        <StandardTable
                                    disableSelect={false}
                                    loading={loading}
                                    rowKey="authorityId"
                                    data={data}
                                    columns={this.columns}
                                    onSelectRow={this.handleSelectRows}
                                    onChange={this.handleStandardTableChange}
                                />
                   </Card>
               </Card>
           </div>
         )
      }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(AuthorityList);