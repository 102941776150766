import React from 'react';
import {TreeSelect} from 'antd';
import appConfig from './../../../configs/app.config';
// import {isDeveloper } from './../../../configures/user.service';
import { get } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import {convertListToTreeJson} from '../../../common/utils';
const { TreeNode } = TreeSelect;

export default class SelectOrganizationTree extends React.Component {

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
          const newState= {
             value: nextProps.value || '',
          };
          return newState;
        }
        return null;
   }

   constructor(props)
   {
       super(props);

       const value = props.value ||'';
       this.state = {
            loading:false,
            value,
            treeDefaultExpandedKeys:[],
            treeData:[]
       };
   }

   componentDidMount()
   {
       this.handleFocus();
   }

    handleFocus=()=>{
         if(this.state.treeData.length===0)
         {
            let orgs=[];
            let rootOrg;
            // if(isDeveloper())
            // {
            //     rootOrg=appConfig.initOrg;
            // }
            rootOrg=appConfig.initOrg;
            this.setState({loading:true});
            get({
                url:apiConfig.rui.basic.organization.getAllOrganizations,
                params:{
                    pageIndex:0,
                    pageSize:appConfig.maxPageSize
                }
            }).then((res)=>{
                 if(res&&res.list)
                 {
                    let treeData=convertListToTreeJson(res.list,'0','subStructure','structureId','pLevel');
                    if(rootOrg)
                    {
                        rootOrg.subStructure=treeData;
                        orgs=[
                           {
                               ...rootOrg
                           }
                        ];
                    }
                    else
                    {
                        orgs=[
                             ...treeData
                        ];
                    }
                 }
                 else
                 {
                    if(rootOrg)
                    {
                        orgs=[
                            {
                                ...rootOrg
                            }
                         ];
                    }
                 }
                 this.setState({
                     loading:false,
                     treeData:orgs
                 });
            }).catch((error)=>{
                this.setState({ loading:false});
             });
         }
    }

    handleLoadData = treeNode=>new Promise((resolve) => {
            if (treeNode.props.dataRef.subStructure) {
              resolve();
              return;
            }
            get({
                url:apiConfig.rui.basic.organization.getSubOrganizations,
                params:{
                    pageIndex:0,
                    pageSize:appConfig.maxPageSize,
                    structureId:treeNode.props.dataRef.structureId
                }
            }).then((res)=>{
                if(res&&res.list&&res.list.length>0)
                {
                    treeNode.props.dataRef.subStructure=res.list;
                    this.setState({
                        treeData: [...this.state.treeData],
                    });
                }
                resolve();
            }).catch((error)=>{
                this.setState({ loading:false});
                 resolve();
            });
    });

    handleSelect = (value,node, extra) => {
        if(!value)
        {
            return;
        }
        else
        {
            if (!('value' in this.props)) {
                this.setState({ value });
            }
            const selectTreeData=node.props.dataRef;
            const setSelectTreeData = this.props.setSelectTreeData;
            if(setSelectTreeData)
            {
                setSelectTreeData(selectTreeData);
            }
            this.triggerChange(value);
        }
    }

    triggerChange = (changedValue) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    };

    renderTreeNodes = data => data.map((item) => {
        if (item.subStructure) {
          return (
            <TreeNode title={item.structureName} key={item.structureId} value={item.structureId} dataRef={item}>
              {this.renderTreeNodes(item.subStructure)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.structureName} key={item.structureId} value={item.structureId} dataRef={item} isLeaf />;
    })

    render() {
      let { /*loading,*/ treeData,value/*,treeDefaultExpandedKeys*/ }=this.state;
      return (
            //  <Spin spinning={loading}>
                 <TreeSelect 
                          showSearch 
                          treeNodeFilterProp='title'
                          placeholder="请选择父组织机构"
                          value={value}
                        //   loadData={this.handleLoadData}
                          onSelect={this.handleSelect} 
                          onFocus={this.handleFocus}
                 >
                    {this.renderTreeNodes(treeData)}
                 </TreeSelect>
            //  </Spin>
         )
      }
}