import React, { Component } from 'react';
import { Row, Spin, Button, Upload, Icon, Modal, Divider, message, AutoComplete, Popconfirm } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcService from './../../other/pc.service';
import { getLocal, convertListToTreeJson, setJsonArray } from './../../common/utils';
import PcModal from '../../common/components/modal/Modals';
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import partyFee from './partyFee.xls';
import './partyFeeManagement.css';

let userId = getLocal("poc_headers") ? getLocal("poc_headers").user_id : "";
let thisYear = new Date().getFullYear();

class PartyFeeManagement extends Component {
    state = {
        loading: false,
        visible: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "缴纳说明",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: [],
        communistInfoName: "",
    }

    formItemLayoutStyle = {

        labelCol: {
            xs: { span: 24 },
            sm: { span: 9 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 15 }
        }
    }
    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        }, {
            title: '姓名',
            dataIndex: 'communistInfoName',
            key: 'communistInfoName'
        }, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" theme="outlined" /> 编辑</a>
                        {/* <Divider type="vertical" />
                        <a onClick={this.deleteItem.bind(this, record)} ><Icon type="delete" theme="outlined" /> 删除</a> */}
                    </span>
                )
            }
        }, {
            title: '年度',
            dataIndex: 'years',
            key: 'years'
        }, {
            title: '1月',
            dataIndex: 'month1',
            key: 'month1',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '2月',
            dataIndex: 'month2',
            key: 'month2',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '3月',
            dataIndex: 'month3',
            key: 'month3',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '4月',
            dataIndex: 'month4',
            key: 'month4',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '5月',
            dataIndex: 'month5',
            key: 'month5',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '6月',
            dataIndex: 'month6',
            key: 'month6',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '7月',
            dataIndex: 'month7',
            key: 'month7',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '8月',
            dataIndex: 'month8',
            key: 'month8',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '9月',
            dataIndex: 'month9',
            key: 'month9',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '10月',
            dataIndex: 'month10',
            key: 'month10',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '11月',
            dataIndex: 'month11',
            key: 'month11',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }, {
            title: '12月',
            dataIndex: 'month12',
            key: 'month12',
            render: (text, record, index) => text ? text.toFixed(2) : ""
        }
    ];
    seachFieldsList = [{
        name: "communistInfoIdTreeShow",
        editor: "node-select",
        nodeOptions: {
            multiple: false
        },
        displayName: "姓名",
        opts: [],
    }, {
        name: "structureId",
        editor: "node-select",
        value: "",
        originValue: "",
        opts: [],
        displayName: "党组织",
    }, {
        name: "years",
        editor: "select",
        value: "",
        displayName: "年份",
        opts: [
            { id: "", name: "全部" },
            { id: thisYear - 8, name: thisYear - 8 },
            { id: thisYear - 7, name: thisYear - 7 },
            { id: thisYear - 6, name: thisYear - 6 },
            { id: thisYear - 5, name: thisYear - 5 },
            { id: thisYear - 4, name: thisYear - 4 },
            { id: thisYear - 3, name: thisYear - 3 },
            { id: thisYear - 2, name: thisYear - 2 },
            { id: thisYear - 1, name: thisYear - 1 },
            { id: thisYear, name: thisYear },
            { id: thisYear + 1, name: thisYear + 1 },
            { id: thisYear + 2, name: thisYear + 2 },
            { id: thisYear + 3, name: thisYear + 3 },
            { id: thisYear + 4, name: thisYear + 4 },
            { id: thisYear + 5, name: thisYear + 5 },
            { id: thisYear + 6, name: thisYear + 6 },
        ]
    }];

    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "paymentBase",
            editor: "hidden",
            displayName: "缴纳基数",
            value: "",
            originValue: "",
        }, {
            name: "id",
            editor: "hidden",
            displayName: "id",
            value: "",
            originValue: "",
        }, {
            name: "communistInfoIdTreeShow",
            editor: "hidden",
            displayName: "党员id",
            value: "",
            originValue: "",
        }, {
            name: "communistInfoName",
            editor: "node-select",
            displayName: "姓名",
            unavailable: true,
            value: "",
            originValue: "",
            opts: [],
            rules: [
                {
                    required: true,
                    message: "请输入姓名"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ],
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            onChange: (item, form, val, title, option) => {
                let valStructId = val.split("_")[1];
                //处理小组，则挂载在支部
                let jsonArr = setJsonArray(option, "children");
                let filterJsonArr = jsonArr.filter(v => v.id === valStructId);
                if (filterJsonArr[0].partyType === "PARTY_CLASS") {
                    let parentJsonArr = jsonArr.filter(v => v.id === filterJsonArr[0].parentId);
                    form.setFieldsValue({
                        "communistInfoIdTreeShow": val,
                        "structureId": parentJsonArr[0].structureId,
                    })
                } else {
                    form.setFieldsValue({
                        "communistInfoIdTreeShow": val,
                        "structureId": valStructId,
                    });
                }

                this.setState({ communistInfoName: title[0] })
            },
        }, {
            name: "structureId",
            editor: "node-select",
            value: null,
            originValue: null,
            displayName: "所属党组织",
            unavailable: true,
            opts: [],
            nodeOptions: {
                multiple: true
            },
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
        }, {
            name: "years",
            editor: "select",
            value: "",
            displayName: "年份",
            opts: [
                { id: thisYear - 3, name: thisYear - 3 },
                { id: thisYear - 2, name: thisYear - 2 },
                { id: thisYear - 1, name: thisYear - 1 },
                { id: thisYear, name: thisYear },
                { id: thisYear + 1, name: thisYear + 1 },
                { id: thisYear + 2, name: thisYear + 2 },
                { id: thisYear + 3, name: thisYear + 3 },
            ],
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            rules: [
                { required: true, message: "请选择年份" }
            ],
        }, {
            name: "changeStatus",
            editor: "radio",
            value: "",
            originValue: "-1",
            displayName: "是否统一编辑",
            columns: 2,
            rules: [],
            opts: [
                { id: "-1", name: "是" },
                { id: "1", name: "否" }
            ],
            onChange: ($event, form, value) => {
                if (value === "-1") {
                    this.fieldsList.map(v => {
                        if (v.name === "absoluEdit") {
                            v.unavailable = false;
                        }
                    });
                } else if (value === "1") {
                    this.fieldsList.map(v => {
                        if (v.name === "absoluEdit") {
                            v.unavailable = true;
                        }
                    });
                }
                this.setState({});
            }
        }, {
            name: "absoluEdit",
            displayName: "统一编辑",
            editor: "normal",
            columns: 2,
            value: "",
            originValue: "",
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
                { whitespace: true, message: '不能全为空格' }
            ],
            onChange: (_item, form, _value, option) => {
                let batchValue = _value.target.value;
                form.setFieldsValue({
                    month1: batchValue,
                    month2: batchValue,
                    month3: batchValue,
                    month4: batchValue,
                    month5: batchValue,
                    month6: batchValue,
                    month7: batchValue,
                    month8: batchValue,
                    month9: batchValue,
                    month10: batchValue,
                    month11: batchValue,
                    month12: batchValue,
                });
            }
        }, {
            name: "month1",
            editor: "normal",
            displayName: "1月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month2",
            editor: "normal",
            displayName: "2月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month3",
            editor: "normal",
            displayName: "3月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month4",
            editor: "normal",
            displayName: "4月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month5",
            editor: "normal",
            displayName: "5月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month6",
            editor: "normal",
            displayName: "6月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month7",
            editor: "normal",
            displayName: "7月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month8",
            editor: "normal",
            displayName: "8月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month9",
            editor: "normal",
            displayName: "9月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month10",
            editor: "normal",
            displayName: "10月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month11",
            editor: "normal",
            displayName: "11月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }, {
            name: "month12",
            editor: "normal",
            displayName: "12月",
            value: "",
            originValue: "",
            columns: 3,
            options: {
                suffix: "元"
            },
            rules: [
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入非负数' },
            ],
            formItemLayout: this.formItemLayoutStyle,
        }
    ];

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.parytFeeManagement.page, _params, null, (data) => {
                let _pagination = PcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            });
        });

    }
    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }
    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                let createOrUpdateUrl = "";
                //新建或修改 
                if (create) {
                    createOrUpdateUrl = urls.app.parytFeeManagement.addEntity;
                } else {
                    createOrUpdateUrl = urls.app.parytFeeManagement.updateEntity;
                }
                this.setState({ loading: true });
                values.communistInfoName = this.state.communistInfoName ? this.state.communistInfoName : values.communistInfoName;
                let data = values;
                http.request({
                    method: 'post',
                    url: createOrUpdateUrl,
                    data: data
                }).then(res => {
                    if (res.success === true) {
                        this.fetchData();
                        this.setState({ loading: false });
                    } else {
                        message.error(res.message);
                        this.setState({ loading: false });
                    }
                }).catch(err => {
                    message.error(err.message);
                    this.setState({ loading: false });
                });

                setTimeout(function () {
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            visible: false
                        }
                    })
                }, 1000);
            }
        });
    }
    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    createOne = (item, create) => {
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            PcService.bindFormdData(item, this.fieldsList);
        } else {
            _create = true;
            PcService.initFormList(this.fieldsList);
            this.fieldsList.map(v => {
                if (v.name === "absoluEdit") {
                    v.unavailable = false;
                }
            });
            //新增功能，将党员信息从党员接口查出来，打开新增modal框赋值
            this.fieldsList.map(v => {
                if (v.name === 'communistInfoName') {
                    v.unavailable = false;
                }
            })
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    }
    /* 删除列表项 */
    deleteItem = (item, $event) => {
        let url = urls.app.parytFeeManagement.deleteEntityPhysical;
        let addStrng = '';
        if (item.length) {
            item.length && item.map(v => {
                addStrng += v + ',';
            });
        } else {
            addStrng = item.id;
        }
        let that = this;
        PcService.deleteItem(url, {}, addStrng,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                this.setState({ loading: false });
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    };
    importDataModel = () => {
        Modal.info({
            title: '导入模板',
            content: (
                <div>
                    <p>缴纳说明导入模板在&emsp;<a href={partyFee} >这里下载</a></p>
                </div>
            ),
            onOk() { },
        });
    }
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /* 选中事件 */
    getPartyInfo = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: {
                flag: true,
                type: 0,
                injob: "0"
            },
        }).then(res => {
            if (res.success) {
                if (res.data.length) {
                    let treeData = res.data || [];
                    let listsOrg = PcService.renderOrgAndUserNodes({ data: treeData, type: 1 });
                    let lists = PcService.renderOrgAndUserNodes({ data: treeData, type: 0, renderAll: true });
                    this.fieldsList.map(v => {
                        if (v.name === "communistInfoName") {
                            v.opts = lists;
                        }
                        if (v.name === "structureId") {
                            v.opts = listsOrg;
                        }
                    })
                    this.seachFieldsList.map(v => {
                        if (v.name === "communistInfoIdTreeShow") {
                            v.opts = lists;
                        }
                    })
                    this.setState({
                        loading: false
                    });
                }
            } else {
                message.error(res.message);
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({
                loading: false
            });
        });
    };
    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    // 所属党组织
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                let lists = treeData ? treeData : [];
                this.seachFieldsList.map(v => {
                    if (v.name === "structureId") {
                        v.opts = [{ id: "", name: "全部" }].concat(lists);
                    }
                })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    componentDidMount() {
        this.fetchData();
        //获取党员信息
        this.getPartyInfo();
        //获取党组织
        this.getOrganizationTree();
    }

    render() {
        const { visible, loading, selectedRowKeys, pagination, lists, modalOptions, create } = this.state;
        const { onSelectChange, columns, fetchData, seachFieldsList, seachOnSubmit, importDataModel,
            createOne, fieldsList, onOk, onCancel, deleteItem, cancel, handleVisibleChange } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 900, title: (create ? "新增" : "修改") + modalOptions.title };
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        const that = this;
        const props = {
            name: 'file',
            action: urls.app.parytFeeManagement.uploadTemplate,
            headers: getLocal("poc_headers"),
            showUploadList: false,
            accept: ".xlsx,.xls",
            onChange(info) {
                that.setState({ loading: true });
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.response.data}`);
                    that.fetchData();
                    that.setState({ loading: false });
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 文件上传失败.`);
                    that.setState({ loading: false });
                }
            },
        };
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="fee-sp-btns">
                                <Button onClick={createOne} type="primary" icon="file-add">新增</Button>
                                <Divider type="vertical" />
                                <Upload {...props}>
                                    <Button>
                                        <Icon type="upload" type="upload" /> 导入
                                    </Button>
                                </Upload>
                                <Divider type="vertical" />
                                <Button onClick={importDataModel} type="default" icon="vertical-align-bottom">模板下载</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                </Popconfirm>
                            </div>

                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset fieldsList={seachFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                                scroll={{ x: AutoComplete }}
                            />
                        </div>
                    </Row>
                </div>
                <PcModal formClassName={"partyFeeMangement"} fieldsList={fieldsList} modal={modal} formItemColumns="1" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />

            </Spin >
        )
    }
}

export default PartyFeeManagement;
