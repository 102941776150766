import React, { Component } from 'react';
import { Button, message, Icon, Divider, Modal } from 'antd';
import { hashHistory } from 'react-router';
import TimeLine from '../../common/components/timeLine/TimeLine';
import Forms from '../../common/components/form/Forms';
import urls from './../../configs/api.config';
import http from './../../common/axios/request';
import { convertListToTreeJson } from "../../common/utils";
import PcForm from '../../common/components/form/Forms';
import pcService from '../../other/pc.service';
import PcTable from './../../common/components/table/tableComponent';
import { getLocal } from "../../common/utils";
import moment from 'moment';
import "./orgIntegralSettlement.less"

export class PartyIntegralSettlement extends Component {
    state = {
        lists: [],
        listsLeader: [],
        selectedRowKeys: [],
        selectedRowKeysLeader: [],
        loading: false,
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        paginationLeader: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
    }

    columnsList = [
        [
            {
                title: '序号 ',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => index + 1
            },
            {
                title: '党支部名称',
                dataIndex: 'orgName',
                key: 'orgName'
            },
            {
                title: '结算人月数',
                dataIndex: 'manMonth',
                key: 'manMonth'
            },
            {
                title: '党员总积分',
                dataIndex: 'partyBranchPoint',
                key: 'partyBranchPoint'
            },
            {
                title: '支部加分',
                dataIndex: 'pointBranchAdd',
                key: 'pointBranchAdd'
            },
            {
                title: '年度积分',
                dataIndex: 'pointFourth',
                key: 'pointFourth'
            },
            {
                title: '状态',
                dataIndex: 'pointStatus',
                key: 'pointStatus',
                render: (text, record, index) => {
                    if (text) return "归档"
                    else return "未归档";
                }
            }
        ],
        [
            {
                title: '序号 ',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => index + 1
            }, {
                title: '党员姓名',
                dataIndex: 'communistName'
            }, {
                title: '人员类型',
                dataIndex: 'communistType',
            }, {
                title: '所属党支部',
                dataIndex: 'orgName',
            }, {
                title: '党员积分',
                dataIndex: 'personalPoint',
            }, {
                title: '党支部平均分',
                dataIndex: 'branchAvgPoint',
            }, {
                title: '年度积分',
                dataIndex: 'annualPoint',
            }, {
                title: '状态',
                dataIndex: 'pointStatus',
                render: (text, record, index) => {
                    if (text) return "归档"
                    else return "未归档";
                }
            }
        ]
    ]

    /* 数据筛选字段 */
    searchFieldsList = [{
        name: "type",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "人员类型",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("TYPE"))
    }, {
        name: "name",
        editor: "normal",
        value: "",
        displayName: "党员姓名",
        originValue: ""
    }];


    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /* 选中事件 */
    onSelectChangeLeader = (selectedRowKeysLeader) => {
        this.setState({ selectedRowKeysLeader });
    };

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.pointOrgYearly.page, _params, null, (data) => {
                //console.log(data)
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            });
        });
    }
    fetchDataLeader = (params) => {
        let { paginationLeader } = this.state;
        let { pageSize, current } = paginationLeader;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.pointPartyYearly.page, _params, null, (data) => {
                //console.log(data)
                let _pagination = pcService.getPagination(data, paginationLeader);
                let lists = data.rows ? data.rows : [];
                this.setState({
                    loading: false,
                    listsLeader: lists,
                    paginationLeader: _pagination
                });
            });
        });

    }
    close = () => { 
        let that = this;
        let url = urls.app.pointOrgYearly.settlementPartyAndCommunistAnnualPoint;
        let year = this.props.location ? parseInt(this.props.location.query.year) : "";
        this.setState({ loading: true });
        Modal.confirm({
            title: '党支部、党支部领导年度积分归档后，不能再修改!',
            content: '是否归档年度积分？',
            centered: true,
            maskClosable: true,
            onOk() {
                pcService.formSubmit(url, true, null, { year: year }, () => {
                    message.success("年度积分归档成功!");
                    that.setState({ loading: false }, that.componentDidMount);
                    let panelId = pcService.getIdByPath("/app/orgIntegralSettlement");
                    pcService.removePanel(panelId);
                    pcService.relativePath("/app/orgIntegralRecords");
                }, (err) => {
                    that.setState({ loading: false });
                });
            },
            width: '660px',
            okText: "积分归档",
            onCancel() {
                console.log('Cancel');
            },
        });

    }

    componentDidMount() {
        this.fetchData();
        this.fetchDataLeader();
        pcService.initFormList(this.searchFieldsList);
    }

    render() {
        const { fieldsList, lists, listsLeader, selectedRowKeys, selectedRowKeysLeader, pagination, paginationLeader, columns } = this.state;
        const { nextStamp, searchFieldsList, columnsList, fetchData, fetchDataLeader, fieldsListBase,
            fieldsListAccount, onSelectChange, onSelectChangeLeader, handleSave, handleCancel, close } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const rowSelectionLeader = { selectedRowKeysLeader, onChange: onSelectChangeLeader };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <div className={"ois-integral-record"}>
                <div className="ois-search">
                    <div className="sp-btns">
                        <Button onClick={close} icon="file-add" type="primary">归档年度积分</Button>

                    </div>
                </div>
                <div className={"list"}>
                    <div className="ois-list-first">
                        <h3>党支部年度积分结算信息</h3>
                        <PcTable
                            // rowSelection={rowSelection}
                            columns={columnsList[0]}
                            pagination={pagination}
                            dataSource={lists}
                            fetchData={fetchData}
                            bordered={false}
                        />
                    </div>
                    <div className="ois-list-second">
                        <h3>党支部领导年度积分结算信息</h3>
                        <PcTable
                            // rowSelection={rowSelectionLeader}
                            columns={columnsList[1]}
                            pagination={paginationLeader}
                            dataSource={listsLeader}
                            fetchData={fetchDataLeader}
                            bordered={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PartyIntegralSettlement;
