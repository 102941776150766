import React, { Component } from 'react';
import { message, Spin, Button, Input, Select } from 'antd';
import PcService from './../../other/pc.service';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import './targetedPovertyReduction.css';
import defaultPic from './../../img/commuImg.png';
const { Search } = Input;
const { Option } = Select;

export class TargetedPovertyReductionSign extends Component {
    state = {
        loading: false,
        none: 0,
        dataList: [],
        commentLists: [],
        signSuccess: 0,
        signUnDone: 0,
        signSelect: '',
    }

    listClick = (record) => {
        let { dataList = [] } = this.state;
        dataList.map((v, n) => {
            if (v.id === record.id) {
                v.none = 1;
            } else {
                v.none = 0;
            }
        })
        this.setState({
            dataList,
        })
        this.fetchCommentData({ publisher: record.partyMemberId });
    }
    /* 获取列表数据 */
    fetchCommentData = (params) => {
        let _params = Object.assign({}, { resourceType: PcService.getDataDictionary("ACTIVITY_CATEGORY")[3].id, resourceId: this.props.location.query.recordId }, params);
        PcService.fetchData(urls.app.comment.page, _params, null, (data) => {
            //console.log(data)
            let lists = data.rows ? data.rows : [];
            lists.length && lists.map(v => {
                v.active = "";
            })
            this.setState({
                commentLists: lists,
            });
        });

    }
    onMouseLeave = () => {
        let { dataList = [] } = this.state;
        dataList.map((v, n) => {
            v.none = 0;
        })
        this.setState({
            dataList,
        })
    }

    handleChange = (value) => {
        this.setState({ signSelect: value })
    }
    /* 搜索 */
    seachOnSubmit = value => {
        const { signSelect } = this.state;
        //console.log(value, signSelect)
        let _params = {
            name: value,
            signStatus: signSelect
        }
        this.fetchData(_params);
    }

    fetchData = (params) => {
        this.setState({ loading: true });
        const that = this;
        //获取签到信息
        let id = this.props.location ? this.props.location.query.recordId : '';
        let _params = Object.assign({activityId: id}, params)
        http.request({
            method: 'get',
            url: urls.app.partyActivity.signPage,
            params: _params
        }).then(res => {
            //console.log(res)
            if (res.success === true) {
                let record = res.data.rows;
                record.map(v => v.none = 0)
                that.setState({
                    signSuccess: record.length ? record[0].signSuccess : 0,
                    signUnDone: record.length ? record[0].signUnDone : 0,
                    dataList: record,
                    loading: false
                });

            } else {
                message.error(res.message);
                that.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({ loading: false });
        });
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        this.fetchData();
    }
    render() {
        const { loading, dataList, signUnDone, signSuccess, commentLists } = this.state;
        const { seachFieldsList, seachOnSubmit, handleChange } = this;

        return (
            <Spin spinning={loading}>
                <div className="partysign-activ-page">
                    <div className="panew-marin-tipinfo">
                        <span className="panew-main-tip" />
                        <label > 活动安排</label>
                    </div>
                    {/* 搜索框 */}
                    <div className="party-activ-bars">
                        <div className="party-activ-btns">
                            精准扶贫，已签到{signSuccess}人，未签到{signUnDone}人
                            </div>
                        <div className="party-activ-sign">
                            <label >&emsp;签到情况: &nbsp;</label>
                            <Select ref={"signSelect"} defaultValue="" style={{ width: 200 }} onChange={handleChange}>
                                <Option value={""}>{"全部"}</Option>
                                {
                                    PcService.getDataDictionary("ACTIVITY_SIGN_STATUS").map((item) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                            <label >&emsp;姓名:&nbsp; </label>
                            <Search style={{ width: '250px' }} placeholder="请输入姓名" onSearch={value => this.seachOnSubmit(value)} enterButton />
                        </div>
                    </div>
                    {/* 列表 */}
                    <div className="partysign-list">
                        <div>
                            <ul>
                                {
                                    dataList.map((item, key) => {
                                        let signUrl = item.photo ? urls.file.viewImg + '/' + item.photo : defaultPic;
                                        return (
                                            <li key={key}>
                                                <div className="sign-list-border" onClick={this.listClick.bind(this, item)} onMouseLeave={this.onMouseLeave} >
                                                    <div className={item.none === 1 && commentLists && commentLists.length > 0 ? 'sign-list-child' : null} >
                                                        <div className="sign-inner-pic">
                                                            <div className="pic-img">
                                                                <img src={signUrl}></img>
                                                                <div className="pic-name">
                                                                    <span style={{ float: "left", fontWeight: "normal", fontSize: 12 }}>{item.name}</span> 
                                                                </div>
                                                                <div className="sign-inner-in">{item.signStatus ? PcService.numberToText(item.signStatus, PcService.getDataDictionary("ACTIVITY_SIGN_STATUS")) : " "}</div>
                                                            </div>
                                                            <div className="sign-inner-time">{item.signTime ? item.signTime.substring(0, 16) : ""}</div>
                                                        </div>
                                                        <div className="sign-info" style={{ display: item.none === 1 ? 'block' : 'none' }}>
                                                            <div className={"sign-inner-pic"}>
                                                                <ul className={"sign-com"}>
                                                                    {
                                                                        commentLists.map((comItem, k) => {
                                                                            let signComUrl = comItem.headImg ? urls.file.viewImg + '/' + comItem.headImg : defaultPic;
                                                                            return (
                                                                                <li key={k}>
                                                                                    <div className="pic-img" >
                                                                                        <img src={signComUrl}></img>
                                                                                        <div className="pic-name">{comItem.publisherName ? comItem.publisherName : ""}</div>
                                                                                    </div>
                                                                                    <div>
                                                                                        {comItem.content}
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                         
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                </div>
            </Spin >
        )
    }
}

export default TargetedPovertyReductionSign
