import React, { Component } from 'react';
import { Form } from 'antd';
import FormsItem from '../../common/components/form/FormsItem';
import '../../common/components/form/bEditor.css';

const OrgInfoForm = Form.create()(
    class extends Component {
         
        render() {
            const { form, fieldsList = [], formStyle = {}, onSubmit } = this.props;
            const { getFieldDecorator } = form;
            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            }

            return ( 
                <Form onSubmit={onSubmit.bind(this, this.props.form)}>
                    {
                        fieldsList.map((item, index) => {
                            let FormItemStyle = item.editor === "hidden" ? { display: "none" } : null;
                            return (
                                <Form.Item style={FormItemStyle} {...formItemLayout} label={item.displayName} key={index}>
                                    {
                                        getFieldDecorator(item.name,
                                            {
                                                initialValue: item.value,
                                                rules: item.rules,
                                                validateFirst: true
                                            })(<FormsItem item={item} form={form} />)
                                    }
                                </Form.Item>
                            )
                        })
                    }
                </Form>
            )
        }
    }
);

export default OrgInfoForm;
