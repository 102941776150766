import React, { Component } from 'react';
import appConfig from './../../configs/app.config';

export class AttachList extends Component { 
    render() {
        const { itemAttach = [], itemName } = this.props;
        return (
            <div className={"fileslist"}>
                {itemName}：
            {
                    itemAttach && itemAttach.map((v, n) => {
                        return (
                            <div key={n}>
                                <a target="_blank" className={"ant-upload-list-item-name"} title={v.fileName}
                                    href={appConfig.pocAddress + 'views/' + v.fileId} >{v.fileName}</a>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default AttachList;
