import React, { Fragment } from 'react';
import { Card, Popconfirm, message } from 'antd';
import { StandardTable } from '../../../sharedCommponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane, removeTabPane } from '../../../action/menuTab.action';
import { getTabPaneData } from '../../../common/utils';
import AllComponents from '../../../components';
import { get, delHttp } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SearchForm from './SearchForm';
import { ButtonAuth, ALinkAuth } from '../../../sharedCommponents/AuthWidget';
import pcService from './../../../other/pc.service';
import contantConfig from './../../../configs/constant.config';

class TenantList extends React.Component {

    state = {
        loading: false,
        selectedRows: [],
        data:
        {
            list: [],
            pagination: {
                pageIndex: 0,
                pageSize: 10,
                total: 0
            }
        },
        searchInitValue: {
            keyword: ''
        }
    }

    columns = [
        {
            title: '操作',
            width: 240,
            align: 'center',
            render: (text, record) => (
                <Fragment>
                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        authCode={'67A4148EF2A64024A01EDD2ED10B7456'}
                        onClick={() => this.handleSetMenu(record)}
                    >
                        功能模块
                    </ALinkAuth>
                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        authCode={'454E917D88374312B8D696534B2AF123'}
                        onClick={() => this.handleEdit(record)}
                    >
                        编辑
                    </ALinkAuth>
                    {
                        !record.disabled &&
                        <Fragment>
                            <Popconfirm title="您确定要删除吗?" onConfirm={() => this.handleDelete(record)} okText="是" cancelText="否">
                                <ALinkAuth
                                    // href="javascript:;"
                                    authCode={'672890450BB94B9FBD416515E151E901'}
                                >
                                    删除
                                </ALinkAuth>
                            </Popconfirm>
                        </Fragment>
                    }

                </Fragment>
            ),
        },
        {
            title: '租户名称',
            dataIndex: 'tenantName'
        },
        {
            title: '租户代码',
            dataIndex: 'tenantCode'
        }
    ]

    componentWillMount() {
        this.fetch({});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.reloadState && nextProps.reloadState !== this.props.reloadState) {
            const params = {
                pagination: {
                    pageIndex: 0
                }
            };
            this.fetch(params);
        }
    }

    fetch = (params = {}) => {
        this.setState({ loading: true });
        let { pagination } = this.state.data;
        let pageIndex = pagination.pageIndex;
        let pageSize = pagination.pageSize;
        let keyword = this.state.searchInitValue.keyword;
        if (params) {
            if (params.pagination) {
                pageIndex = params.pagination.pageIndex || 0;
                pageSize = params.pagination.pageSize || pageSize;
            }
            if (!params.keyword) {
                keyword = '';
            }
            else {
                keyword = params.keyword
            }
        }
        let queryParams = {
            pageIndex,
            pageSize,
            keyword
        };
        get({
            url: apiConfig.rui.basic.tenant.list,
            params: queryParams
        }).then((res) => {
            if (res) {
                this.setState({
                    loading: false,
                    data: {
                        list: res.list,
                        pagination: {
                            pageIndex: queryParams.pageIndex,
                            pageSize: queryParams.pageSize,
                            total: res.total
                        }
                    },
                    searchInitValue: {
                        keyword: queryParams.keyword
                    }
                });
            }
            else {
                this.setState({ loading: false });
            }
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    handleSetMenu = (tenant) => {
        /*let { selectedMenuKey }=this.props;
        let paneKey="ser-tenantMenus-pane";
        let SetTenantMenus=AllComponents["SetTenantMenus"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"设置租户的功能模块",
            content: <SetTenantMenus tenant={tenant} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/setTenantMenus?tenantId=" + tenant.tenantId);
    }

    handleSelectRows = rows => {
        this.setState({
            selectedRows: rows,
        });
    };

    handleStandardTableChange = pagination => {
        const params = {
            pagination: {
                pageIndex: pagination.current - 1,
                pageSize: pagination.pageSize
            }
        };
        this.fetch(params);
    };

    handleSearch = (seacheValues) => {
        let params;
        if (seacheValues) {
            params = {
                pagination: {
                    pageIndex: 0
                },
                keyword: seacheValues.keyword
            };
        }
        else {
            params = {
                pagination: {
                    pageIndex: 0
                },
                keyword: ''
            };
        }
        this.fetch(params);
    }

    handleDelete = (tenant) => {
        this.setState({ loading: true });
        delHttp({
            url: apiConfig.rui.basic.tenant.delete,
            params: {
                tenantId: tenant.tenantId
            }
        }).then((res) => {
            const params = {
                pagination: {
                    pageIndex: 0
                }
            };
            message.success(contantConfig.DEL_SUCCESS);
            this.fetch(params);
        }).catch((error) => {
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading: false });
        });
    }


    handleDeleteAll = () => {
        let { selectedRows } = this.state;
        let tenantIds = '';
        if (selectedRows.length === 0) {
            message.info('请选择要删除的租户');
            return;
        }
        for (let selectedRow of selectedRows) {
            tenantIds += selectedRow.tenantId + ',';
        }
        if (tenantIds) {
            tenantIds = tenantIds.substr(tenantIds, tenantIds.length - 1);
        }
        this.setState({ loading: true });
        delHttp({
            url: apiConfig.rui.basic.tenant.deleteAll,
            params: {
                tenantIds
            }
        }).then((res) => {
            this.setState({
                selectedRows: []
            }, () => {
                const params = {
                    pagination: {
                        pageIndex: 0
                    }
                };
                message.success(contantConfig.DEL_SUCCESS);
                this.fetch(params);
            });
        }).catch((error) => {
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading: false });
        });
    }

    handleAdd = () => {
        /*let { selectedMenuKey }=this.props;
        let paneKey="add-tenant-pane";
        let CreateTenantForm=AllComponents["CreateTenantForm"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"新增租户",
            content: <CreateTenantForm paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        //this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/tenantManage");
    }

    handleEdit = (tenant) => {
        /* let { selectedMenuKey }=this.props;
         let CreateTenantForm=AllComponents["CreateTenantForm"];
         let paneKey="edit-tenant-pane";
         let addPane=getTabPaneData({
             key: paneKey,
             title:"编辑租户",
             content: <CreateTenantForm editTenant={tenant} paneKey={paneKey}/>,
             isWebDefault: false,
             closable:true,
             isMenu:false,
             refKey:selectedMenuKey,
             isExternal:false
           });
          this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/tenantManage?editId=" + tenant.tenantId);
    }

    render() {
        const { selectedRows, loading, data, searchInitValue } = this.state;
        return (
            <div className="mainDiv">
                <Card bordered={false}>
                    <div className='tableSearchForm'>
                        <SearchForm
                            handleSearch={this.handleSearch}
                            searchInitValue={searchInitValue}
                        />
                    </div>
                    <div className='tableAction'>
                        <ButtonAuth
                            icon="plus"
                            type="primary"
                            dataaction="create"
                            onClick={() => this.handleAdd()}
                            authCode={'8963CB60128041369710F96AA1E19789'}
                        >
                            新增
                        </ButtonAuth>
                        <Popconfirm title="您确定要删除选中的租户吗?" onConfirm={() => this.handleDeleteAll()} okText="是" cancelText="否">
                            <ButtonAuth
                                icon="delete"
                                type="primary"
                                dataaction="create"
                                loading={loading}
                                authCode={'01186B8284284985AB42ADB83CD46012'}
                            >
                                批量删除
                                        </ButtonAuth>
                        </Popconfirm>
                    </div>
                    <div className='TenantList'>
                        <StandardTable
                            selectedRows={selectedRows}
                            disableSelect={false}
                            loading={loading}
                            rowKey="tenantId"
                            data={data}
                            columns={this.columns}
                            onSelectRow={this.handleSelectRows}
                            onChange={this.handleStandardTableChange}
                        />
                    </div>
                </Card>
            </div>
        )
    }
}


const mapStateToProps = state => {
    const { menuTab } = state;
    return {
        selectedMenuKey: menuTab.selectedMenuKey
    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(TenantList);