import React, { Component } from 'react';
import { Row, Col, Spin, Table, Popover } from 'antd';
import './../votingManagement/voting-detail.less';
import http from '../../common/axios/request';
import urls from './../../configs/api.config';
import pcService from '../../other/pc.service';
import { setJsonArray } from '../../common/utils';

class democraticReviewDetail extends Component {

    state = {
        loading: false,
        detailLoading: false,
        activeId: "",
        activeName: "",
        //type: 1,
        //id: 0,
        taskId: 0,
        itemData: {},
        lists: [],
        dataSource: [],
        examPapers: [],//模板列表
        persons: [],
        options: [],
        activeItem: [],
        treeDatas: []
    }

    columns = [
        { title: "姓名", key: "name", dataIndex: "name" },
        { title: "票数", align: "right", key: "optionCount", dataIndex: "optionCount" },
    ];

    tColumns = [
        { title: "序号", key: "index", dataIndex: "index", render: (a, b, index) => index + 1 },
        { title: "姓名", key: "name", dataIndex: "name" },
        { title: "类型", key: "type", dataIndex: "type", render: (text) => text === 2 ? "多选" : "单选" },
        {
            title: "选项内容", key: "options", dataIndex: "options",
            render: (text, record, index) => (<ul className="xsse">
                {!!text && text.map((v, n) => <li key={n}>{`${v.name}：${v.items}`.length > 20 ? (
                    <Popover content={`${v.name}：${v.items}`} placement="topRight"
                        overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                        {`${v.name}：${v.items}`.slice(0, 20) + '...'}
                    </Popover>) : `${v.name}：${v.items}`}</li>)}
            </ul>)
        },
        {
            title: "票数", key: "optionCount", dataIndex: "optionCount",
            render: (text, record) => (<ul className="xsse">
                {!!record.options && record.options.map((v, n) => <li key={n}>{`${v.name}：${v.optionCount}票`}</li>)}
            </ul>)
        },
        { title: "有效票数", key: "volidTotalVotes", dataIndex: "volidTotalVotes" },
        { title: "投票人数", key: "votePeople", dataIndex: "votePeople" },
    ]

    /* 获取投票详情 */
    fetchData = () => {
        const { taskId } = this.state;
        this.setState({ loading: true });
        http.all([
            http.request({
                method: "get",
                params: { id: taskId },
                url: urls.app.examTask.findById
            }),
            http.request({
                method: "get",
                url: urls.app.organization.selectStructureTreeById,
                params: { flag: true, type: 0, injob: "0" },
            }),
            http.request({
                url: urls.app.examPaper.page,
                params: { pageIndex: 0, pageSize: 9999, paperCategory: 1, enabled: true },
                method: "get"
            })
        ]).then(reses => {
            let itemData = reses[0].data || {};
            let examPapers = reses[2].data.rows || [];
            examPapers = examPapers.map(v => ({ ...v, name: v.paperName }));
            let treeDatas = pcService.renderOrgAndUserNodes({
                data: reses[1].data || [],
                type: 0
            });
            let persons = itemData["participantIdsTreeShow"].map(v => pcService.getNodeTitleByValue(v, treeDatas))
            this.setState({ itemData, treeDatas, persons, examPapers, loading: false }, () => {
                itemData["participantIdsTreeShow"].length > 0 && this.getDetails(itemData["participantIdsTreeShow"][0]);
            })
        }).catch(erres => {
            this.setState({ loading: false });
        });
    }

    toggleUser = (item) => {
        let { options } = this.state;
        let _options = options.map(v => ({ ...v, active: v.id === item.id }))
        this.setState({
            options: _options,
            activeItem: { ...item }
        })
    }

    /* getDetails获取详情 */
    getDetails = (idTreeShow = "") => {
        let { taskId, treeDatas, activeId } = this.state;
        if (activeId === idTreeShow) return;
        let activeName = pcService.getNodeTitleByValue(idTreeShow, treeDatas);
        let appraisalObjectId = idTreeShow.split("_")[0];
        this.setState({ detailLoading: true, activeId: idTreeShow, activeName }, () => {
            http.request({
                method: "get",
                params: {
                    taskId,
                    appraisalObjectId
                },
                url: urls.app.examRecords.examRecordPage
            }).then(res => {
                let activeItem = res.data.rows ? res.data.rows : [];
                this.setState({ activeItem, detailLoading: false });
            }).catch(err => {
                this.setState({ detailLoading: false });
            })
        })
    }

    componentWillMount() {
        const { taskId } = this.props.location.query;
        if (taskId === undefined) return;
        this.setState({ taskId }, () => {
            this.fetchData();
        });
    }

    render() {
        const { itemData = {}, activeId, examPapers = [], persons, loading, detailLoading, treeDatas, activeName, activeItem } = this.state;
        const { getDetails } = this;
        const { taskName = "", organizerName = "", examPaperId = [], startTime = new Date(), endTime = new Date() } = itemData;
        return (
            <Spin spinning={loading}>
                <div className="voting-detail">
                    <div className="vd-panel">
                        <h3>基本信息</h3>
                        <div className="vd-content">
                            <Row>
                                <span className="spn-title">评议任务：</span>{taskName}
                            </Row>
                            <Row>
                                <Col span={8}><span className="spn-title">开展组织：</span>{organizerName}</Col>
                                <Col span={8}><span className="spn-title">评议模板：</span>{pcService.numberToText(examPaperId, examPapers) || "无"}</Col>
                                <Col span={8}></Col>
                            </Row>
                            <Row>
                                <Col span={8}><span className="spn-title">开始时间：</span>{pcService.dateFormat(startTime, "yyyy-MM-dd")}</Col>
                                <Col span={8}><span className="spn-title">结束时间：</span>{pcService.dateFormat(endTime, "yyyy-MM-dd")}</Col>
                            </Row>
                            <Row>
                                <span className="spn-title">评议对象： {persons.join("，")}</span>
                            </Row>
                        </div>
                    </div>
                    <div className="vd-panel has-line">
                        <Spin spinning={detailLoading}>
                            <h3>问卷汇总</h3>
                            <div className="vd-content">
                                <div className="h3">评议对象{persons.length || 0}人，有效问卷{activeItem.filter(v => v.complete).length || 0}份，</div>
                                <div className="vdcls democratics">
                                    <div className="vdc-lefts">
                                        <div className="inners-tables">
                                            {persons.length === 0 || !persons ? (
                                                <div className="demoItems">暂无数据</div>
                                            ) : (
                                                    itemData["participantIdsTreeShow"].map((v, n) => <div onClick={getDetails.bind(this, v)} className="demoItems" className={v === activeId ? 'demoItems active' : 'demoItems'} key={n}>{pcService.getNodeTitleByValue(v, treeDatas)}</div>)
                                                )}
                                        </div>
                                    </div>
                                    <div className="vdc-right">
                                        <div className="inners-tables">
                                            <div className="innertb-imgs">
                                                <img width="100" height="120" src={`${urls.file.viewImg}/${activeItem[0] ? activeItem[0].appraisalObjectHeadImg : ''}`} />
                                            </div>
                                            <div className="innertb-liste">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>{activeName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>评议最后得分</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span>{activeItem.length > 0 ? activeItem[0]["endScore"] : 0}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="one-pluse">
                                                <h4>参与评议人员</h4>
                                                <ul className="person-ules">
                                                    {activeItem.filter(v => v.complete).length === 0 ? <p>暂无！</p> : null}
                                                    {activeItem.filter(v => v.complete).map((v, i) => (
                                                        <li key={i}>
                                                            <div><img src={`${urls.file.viewImg}/${v.userHeadImg}`} alt="" /></div>
                                                            <p>{v.userName}</p>
                                                        </li>))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </Spin>
        )
    }
}

export default democraticReviewDetail
