import React, { Component } from 'react';
import './searchList.less';

declare interface SearchProps {
    id: string,
    name: string,
}

declare interface SearchListProps {
    level: string,
    label?: string,
    searchList: Array<SearchProps>,
    onCellClick: (searchProps: SearchProps, level: string) => void,
    activeColumnId: string
}

class SearchList extends Component<SearchListProps> {

    render() {
        const { label, searchList = [], onCellClick, level, activeColumnId } = this.props;
        return (
            <div className="searchList">
                <div className="sl-label">{label}：</div>
                <div className="sl-content">{searchList.map((v, n) => (
                    <span className={v.id === activeColumnId ? "activeSpan" : ""} title={v.name} key={n} onClick={onCellClick.bind(this, v, level)}>
                        {v.name}
                    </span>)
                )}</div>
            </div>
        )
    }
}

export default SearchList;
