export const ADD_NAV_PANEL = "ADD_NAV_PANEL";
export const CLOSE_NAV_PANEL = "CLOSE_NAV_PANEL";
export const UPDATE_NAV_PANEL = "UPDATE_NAV_PANEL";
export const CLOSE_OTHER_NAV_PANEL = "CLOSE_OTHER_NAV_PANEL";
export const CLOSE_ALL_NAV_PANEL = "CLOSE_ALL_NAV_PANEL";
export const RELOAD_CURRENTPANEL = "RELOAD_CURRENTPANEL";
export const SET_OPEN_MENU_UNACTIVE = "SET_OPEN_MENU_UNACTIVE";

export const addNavPanelAction = (item) => ({
        type: ADD_NAV_PANEL,
        data: item
});

export const closeNavPanelAction = (item) => ({
        type: CLOSE_NAV_PANEL,
        data: item
});

export const updatePanelAction = (item) => ({
        type: UPDATE_NAV_PANEL,
        data: item
});

export const closeOtherPanelAction = (item) => ({
        type: CLOSE_OTHER_NAV_PANEL,
        data: item
});

export const closeAllPanelAction = (item) => ({
        type: CLOSE_ALL_NAV_PANEL,
        data: item
});

export const reloadCurrentPanelAction = (item) => ({
        type: RELOAD_CURRENTPANEL,
        data: item
});

export const setOpenMenuUnActiveAction = (item) => ({
        type: SET_OPEN_MENU_UNACTIVE,
        data: item
});

export const clickMenuActiveAction = () => ({
        type: 'ClICK_MENU_ACTION'
});