import React, { Component } from 'react';
import { Button, Divider, message, Popover, Spin, Table } from 'antd';
import Forms from './../../common/components/form/Forms';
import { TablePopModal } from './../../common/components/tablePop/tablePop';
import PcService from "../../other/pc.service";
import urls from "../../configs/api.config";
import PcModal from "../../common/components/modal/Modals";
import http from "../../common/axios/request";

export class TemplateQuestionSelectModal extends Component {

    state = {
        create: true,
        selectedRowKeys: [],
        tablePopSelectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        modalOptions: {
            title: "评议项",
            visible: false,
            tablePopVisible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            lists: this.props.lists,
            tablePopLists: []
        }
    };
    detailItem = () => {

    };
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /************************************问题选项操作START*********************/

    /**
     * 行点击选中事件
     */
    selectRow = (record) => {
        const tablePopSelectedRowKeys = [...this.state.tablePopSelectedRowKeys];
        if (tablePopSelectedRowKeys.indexOf(record.key) >= 0) {
            tablePopSelectedRowKeys.splice(tablePopSelectedRowKeys.indexOf(record.key), 1);
        } else {
            tablePopSelectedRowKeys.push(record.key);
        }
        this.setState({ tablePopSelectedRowKeys });
    };
    /**
     * 问题列表选中事件
     * @param tablePopSelectedRowKeys
     */
    onTablePopSelectChange = (tablePopSelectedRowKeys) => {
        this.setState({ tablePopSelectedRowKeys });
    };

    /**
     * 加载弹出选择列表框数据
     * @param params
     */
    tablePopFetchData = (params) => {
        let { pagination, modalOptions } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        _params.category = "MZPY";
        _params.enabled = "true";
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.examQuestion.page, _params, null, (data) => {
                let _pagination = PcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.displayTip = 'none';
                });
                modalOptions.lists.map(item => {
                    lists.splice(lists.indexOf(item), 1)
                });
                this.setState({
                    loading: false,
                    pagination: _pagination,
                    modalOptions: {
                        ...modalOptions,
                        tablePopVisible: true,
                        tablePopLists: lists
                    }
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    };

    /**
     * 点击选择按钮的事件
     */
    selectQuestion = () => {
        this.tablePopFetchData();
    };

    /**
     * 点击弹出选择问题框取消按钮操作
     */
    onTablePopCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                tablePopVisible: false
            }
        });
    };

    /**
     * 点击弹出框确定按钮操作
     */
    onTablePopOk = () => {
        const { setParentState } = this.props;
        let { tablePopSelectedRowKeys, modalOptions } = this.state;
        let selectedQuestion = this.state.modalOptions.lists;
        if (tablePopSelectedRowKeys.length === 0) {
            message.warning("请选择问题");
            return;
        }
        tablePopSelectedRowKeys.map(key => {
            selectedQuestion.push(modalOptions.tablePopLists[tablePopSelectedRowKeys.indexOf(key)]);
        });
        setParentState({ selectedQuestion });
        this.setState({
            modalOptions: {
                ...modalOptions,
                lists: selectedQuestion,
                tablePopVisible: false
            }
        });
    };

    /**
     * 查询选项
     * @type {*[]}
     */
    searchFieldsList = [{
        name: "keyWord",
        editor: "normal",
        value: "",
        displayName: "题目或者描选项",
        hideDisplayName: true,
        originValue: ""
    }];
    /************************************问题选项操作END***********************/

    /************************************自动生成试题操作START*********************/
    /**
     * 点击自动生成按钮的操作
     */
    selectQuestionAuto = () => {
        let { modalOptions } = this.state;
        PcService.initFormList(this.fieldsListAuto);
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true
            }
        });
    };

    /**
     * 点击自动生成弹出框确定按钮
     * @param forms
     */
    onOk = (forms) => {
        let that = this;
        let { modalOptions } = this.state;
        let { setParentState } = this.props;
        let questionIds = modalOptions.lists.map(item => {
            return item.id
        });
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            }
            values.selectCategory = values.selectCategory.toString();
            values.questionIds = questionIds.toString();
            http.request({
                url: urls.app.examPaper.auto,
                method: 'get',
                params: values,
                headers: null
            }).then(res => {
                if (res.success) {
                    let lists = res.data ? res.data : [];
                    let existLists = modalOptions.lists;
                    let selectedQuestion = [...lists, ...existLists];
                    setParentState({ selectedQuestion });
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            lists: selectedQuestion,
                            visible: false
                        }
                    });
                } else {
                    message.error(res.message);
                }
            }).catch(function (err) {
                message.error(err.message);
            });
        });
    };

    /**
     * 点击自动生成弹出框取消按钮
     */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    };
    /************************************自动生成试题操作END***********************/
    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        }, {
            title: '题目',
            dataIndex: 'title'
        }, {
            title: '题目分值',
            dataIndex: 'score',
            key: 'clode'
        }, {
            title: '选项',
            dataIndex: 'optionView',
            align: 'center',
            render: (value, index) => {
                return value;
            }
        }
    ];

    tablePopColumns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        }, {
            title: '题目',
            dataIndex: 'title',
            render: (value, record, index) => {
                if (value.length > 20) {
                    return (
                        <Popover content={value} placement="topRight"
                            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                            {value.slice(0, 20) + '...'}
                        </Popover>)
                } else {
                    return value;
                }
            }
        }, {
            title: '题目分值',
            dataIndex: 'score',
            key: 'clode'
        }, {
            title: '选项',
            dataIndex: 'optionView',
            render: (value, record, index) => {
                if (value.length > 20) {
                    return (
                        <Popover content={value} placement="topRight"
                            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                            {value.slice(0, 20) + '...'}
                        </Popover>)
                } else {
                    return value;
                }
            }
        }
    ];

    /*自动生成试题窗口字段*/
    fieldsListAuto = [{
        name: "selectCategory",
        editor: "select",
        value: [],
        originValue: [],
        displayName: "分类",
        opts: PcService.getDataDictionary("QUESTION_CATEGORY"),
        mode: "multiple",
        rules: [{
            required: true,
            message: "请选择分类"
        }]
    }, {
        name: "singleChoice",
        editor: "number",
        displayName: "单选题数量",
        value: "",
        originValue: 5,
        rules: [{
            required: true,
            message: "请输入单选题数量"
        }]
    }, {
        name: "multipleChoice",
        editor: "number",
        displayName: "多选题数量",
        value: "",
        originValue: 5,
        rules: [{
            required: true,
            message: "请输入多选题数量"
        }]
    }];

    /* 删除操作*/
    deleteItem = () => {
        let { modalOptions, selectedRowKeys } = this.state;
        let oldList = modalOptions.lists.concat();
        selectedRowKeys.map(key => {
            let object = oldList[key];
            modalOptions.lists.splice(modalOptions.lists.indexOf(object), 1)
        });
        this.setState({
            modalOptions: { ...modalOptions }
        })
    };

    render() {
        const { selectedRowKeys, tablePopSelectedRowKeys, modalOptions, create } = this.state;
        const { fieldsList = [], modalsLoading = false, formItemLayout = {}, formItemColumns, lists = [] } = this.props;
        const {
            columns, onTablePopSelectChange, onSelectChange, selectQuestion, onOk, onCancel, searchFieldsList,
            deleteItem, tablePopColumns, onTablePopCancel, onTablePopOk, fieldsListAuto, tablePopFetchData
        } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title };
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const tablePopRowSelection = { tablePopSelectedRowKeys, onChange: onTablePopSelectChange };
        const allowDel = selectedRowKeys.length > 0;
        return (
            <div className="itemBank-modal">
                <Spin spinning={modalsLoading}>
                    <div className="ibm-part">
                        <h2>基本信息</h2>
                        <Forms ref="forms" onSubmit={this.props.onOk} columns={formItemColumns} fieldsList={fieldsList}
                            hideFooter
                            formItemLayout={formItemLayout} />
                    </div>
                    <div className="ibm-part">
                        <h2>评议项</h2>
                        <div className="ant-form ant-form-horizontal">
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-4">

                                </div>
                                <div className="ant-col ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-20">
                                    <div className="sp-bars">
                                        <div className="sp-btns">
                                            <Button onClick={selectQuestion} type="primary">手动选择</Button>
                                            <Divider type="vertical" />
                                            {/*<Button onClick={selectQuestionAuto}>自动生成</Button>
                                            <Divider type="vertical"/>*/}
                                            <Button disabled={!allowDel} onClick={deleteItem}>删除</Button>
                                        </div>
                                    </div>
                                    <Table size="small" bordered rowSelection={rowSelection} columns={columns}
                                        dataSource={modalOptions.lists} />
                                    <TablePopModal title="选择试题" searchName="题目或者选项"
                                        visible={modalOptions.tablePopVisible}
                                        columns={tablePopColumns} dataSource={modalOptions.tablePopLists}
                                        rowSelection={tablePopRowSelection} onCancel={onTablePopCancel}
                                        onOk={onTablePopOk} searchFieldsList={searchFieldsList}
                                        noSearch={false}
                                        fetchData={tablePopFetchData}/*onRow={(record) => ({onClick: () => {selectRow(record)}})}*/ />
                                    <PcModal fieldsList={fieldsListAuto}
                                        modal={modal}
                                        formItemLayout={formItemLayout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default TemplateQuestionSelectModal;
