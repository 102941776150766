import React from 'react';
import {StandardTable} from '../../../sharedCommponents';
export default class SetAuthoritiesForRole extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props)
    {
        super(props);
    }

    columns=[
        {
            title: '名称',
            dataIndex: 'authorityName'
        },
        {
            title: '编号',
            dataIndex: 'authorityCode'
        }
    ]

    handleSelectRows = (rows) => {
        this.props.handleSetAuthorities(rows);
    };

    render() {
        const { selectMenu } = this.props;
        let initSelectedRowKeys=[];
        let data={
            list: [],
            pagination:false
        };

        if(selectMenu&&selectMenu.authorities)
        {
            data.list=selectMenu.authorities;
            let hasAuthorityArray=selectMenu.authorities.filter(item=>item.hasAuthority);
            if(hasAuthorityArray)
            {
                for(let auth of hasAuthorityArray)
                {
                    initSelectedRowKeys.push(auth.authorityId);
                }
            }
        }

       return (
               <StandardTable
                    initSelectedRowKeys={initSelectedRowKeys}
                    disableSelect={false}
                    rowKey="authorityId"
                    data={data}
                    columns={this.columns}
                    onSelectRow={this.handleSelectRows}
                />
         )
      }
}