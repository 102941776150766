import React, { Component } from 'react';
import { Spin, message, Button, Modal } from 'antd';
import PcService from './../../other/pc.service';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import { hashHistory } from 'react-router';
import ReactToPrint from 'react-to-print';
import './partyActivity.css';
import AttachList from './AttachList';

export class PartyActivityDetail extends Component {
    state = {
        loading: false,
        isMode: "",
        pvModalOptions: {
            title: "图片预览",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        viewId: ""
    }

    isAble = [
        { id: true, name: '是' },
        { id: false, name: '否' }
    ]

    fieldsList = [
        {
            name: "activityTitle",
            editor: "normal",
            displayName: "会议名称",
        }, {
            name: "withSecret",
            displayName: "是否涉密",
            value: "",
            enumsArr: this.isAble
        }, {
            name: "ableMakeUp",
            displayName: "是否可以补课",
            value: "",
            enumsArr: this.isAble
        }, {
            name: "publishStatus",
            displayName: "发布状态",
            value: "",
            enumsArr: PcService.getDataDictionary("PUBLISH_STATUS")
        }, {
            name: "activityStatus",
            displayName: "会议状态",
            value: "",
            enumsArr: PcService.getDataDictionary("ACTIVITY_STATUS")
        }, {
            name: "signPlace",
            displayName: "签到地址",
            value: "",
            editor: "hidden",
        }, {
            name: "activityPlace",
            displayName: "会议地点",
            value: "",
        }, {
            name: "dataProcessorName",
            displayName: "会议资料员",
            value: "",
        }, {
            name: "publisherName",
            displayName: "负责人",
            value: "",
        }, {
            name: "teacherName",
            displayName: "授课老师",
            value: "",
        }, {
            name: "orgUnitName",
            displayName: "会议组织单位",
            value: "",
        }, {
            name: "activityBeginTime",
            displayName: "会议开始时间",
            value: "",
        }, {
            name: "activityEndTime",
            displayName: "会议结束时间",
            value: "",
        }, {
            name: "activityType",
            displayName: "会议类型",
            value: "",
            enumsArr: PcService.getDataDictionary("ACTIVITY_TYPE")
        }, {
            name: "signBeginTime",
            displayName: "签到开始时间",
            value: "",
            editor: "hidden",
        }, {
            name: "signEndTime",
            displayName: "签到结束时间",
            value: "",
            editor: "hidden",
        }, {
            name: "userIds",
            displayName: "参会人员",
            value: "",
        }
    ];
    fieldsListPlan = [
        {
            name: "activityDescription",
            displayName: "会议说明",
            value: "",
        },
        {
            name: "activityAttachments",
            displayName: "会议材料",
            value: "",
        }
    ];
    fieldsListRecord = [
        {
            name: "record",
            value: "",
            displayName: "会议记录",
        },
        {
            name: "images",
            value: "",
            displayName: "会议图像",

        },
        {
            name: "recordAttachments",
            value: "",
            displayName: "记录附件",

        }
    ];
    back = () => {
        let panelId = PcService.getIdByPath("/app/partyActivityDetail");
        PcService.removePanel(panelId);
    }
    print = () => {
        document.getElementById('bindpadetail').click();
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        let that = this;
        this.setState({ loading: true, });
        http.request({
            method: 'get',
            url: urls.app.partyActivity.findById,
            params: { id },
        }).then(res => {
            //console.log(res)
            if (res.success === true) {
                let record = res.data;
                //处理参会人员
                let userID = "";
                record.userIds.map(v => userID += v.name + ",");
                record.userIds = userID;
                //处理会议类型
                record.activityType = record.activityType.split(",");
                PcService.bindFormdData(record, this.fieldsList);
                PcService.bindFormdData(record, this.fieldsListPlan);
                PcService.bindFormdData(record, this.fieldsListRecord);

                that.setState({
                    loading: false,
                    isMode: record.mode
                });
            } else {
                message.error(res.message);
                that.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({
                loading: false
            });
        });
    }

    /* 图片预览 */
    viewImg = (viewId) => {
        if (!!viewId) {
            let { pvModalOptions } = this.state;
            this.setState({
                viewId,
                pvModalOptions: { ...pvModalOptions, visible: true }
            })
        };
    }

    pvOnCancel = () => {
        let { pvModalOptions } = this.state;
        this.setState({
            pvModalOptions: { ...pvModalOptions, visible: false }
        })
    }

    render() {
        const { loading, isMode, pvModalOptions, viewId } = this.state;
        const { back, print, viewImg, onCancel, pvOnCancel } = this;
        const preViewAttr = { ...pvModalOptions, onCancel: pvOnCancel, onOk: pvOnCancel };
        return (
            <Spin spinning={loading} className="">
                <div>
                    <div className="padetail-button">
                        <Button type="default" onClick={back}>返回</Button>
                        <Button type="primary" onClick={print}>打印</Button>
                    </div>
                    <ReactToPrint
                        trigger={() => <a href="javascript:void(0);" id={'bindpadetail'}></a>}
                        content={() => this.componentRef}
                    />
                    <div ref={el => (this.componentRef = el)} >
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label > 会议基础信息({isMode === "add_new" ? "新增" : "补录"})</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsList.map((item) => {
                                            return (
                                                <div className="padetail-detail-list" key={item.name} >
                                                    {
                                                        isMode !== "append" || item.editor !== "hidden" ? <div className="padetail-detail-item-base" value={item.value}>{item.displayName}：{item.enumsArr ? PcService.numberToText(item.value, item.enumsArr) : (item.name.includes("Time") && item.value ? item.value.substring(0, 16) : item.value)}</div> :
                                                            null
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label > 会议安排</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsListPlan.map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    {
                                                        item.name !== "activityAttachments" ?
                                                            <div className="padetail-detail-list" key={item.name} >
                                                                {
                                                                    item.name === "activityDescription" || item.name === "record" ? <div dangerouslySetInnerHTML={{ __html: item.displayName + "：" + (item.value ? item.value : "") }}></div>
                                                                        : <div className="padetail-detail-item-file" value={item.value}>{item.displayName}：<div className="meetingDesctrib">{item.value}</div></div>
                                                                }
                                                            </div> :
                                                            <AttachList itemAttach={item.value} itemName={"材料附件"} />
                                                    }
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label > 会议记录</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail pd-bottom" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsListRecord.map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    {
                                                        item.name !== "recordAttachments" ? <div className="padetail-detail-list" key={item.name} >
                                                            {item.name === "images" ? (
                                                                <div>
                                                                    <div className="padetail-detail-list">
                                                                        <div>{item.displayName}：{(item.value.split(",")).map((_v, i) => <span key={i}><img title="点击预览" onClick={viewImg.bind(this, _v)} src={`${urls.file.viewImg}/${_v}`} alt="" /></span>)}</div>
                                                                    </div>
                                                                </div>) : (item.name === "activityDescription" || item.name === "record" ?
                                                                    <div dangerouslySetInnerHTML={{ __html: item.displayName + "：" + (item.value ? item.value : "") }}></div> :
                                                                    <div className="padetail-detail-item-file" value={item.value}>{item.displayName}：<div className="meetingDesctrib">{item.value}</div></div>
                                                                )

                                                            }
                                                        </div> :
                                                            <AttachList itemAttach={item.value} itemName={"记录附件"} />
                                                    }
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal {...preViewAttr} >
                    <div className="pvModal">
                        <img src={`${urls.file.viewImg}/${viewId}`} alt="" />
                    </div>
                </Modal>
            </Spin>
        )
    }
}

export default PartyActivityDetail;
