/**
 * @Title: MenuIconChoose
 * @ProjectName react
 * @Description:
 * @author yulan
 * @date 2019/5/3109:11
 */
import React from 'react';
import { Select, Popover, Icon } from 'antd';
import classnames from 'classnames';
import { menuIconLibrary } from './MenuIconConfig';
import { getLocal } from "../../../common/utils";
import './../../../styles/menuIconChoose.less';
const ChooseScrollTop = 'menu_icon_choose_scroll_top';

class MenuIconChoose extends React.Component {
    isFirst = true;

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            value: undefined
        };
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.value) {
            return { value: nextProps.value }
        }
        return null;
    }

    //打开/关闭图标选择层
    togglePop = () => {
        const { visible } = this.state;
        this.setState({ visible: !visible });
        if (this.isFirst) this.setUlScrollTop();
        this.isFirst = false
    };

    // 点击非弹出层区域关闭弹出层
    onVisibleChange = (visible) => {
        this.setState({ visible })
    };

    //点选菜单图标选项获取菜单图标的type值
    menuIconClick = (e) => {
        this.getUlScrollTop(e);
        const { onChange } = this.props;
        this.setState({ value: e.currentTarget.type, visible: false });
        if (onChange) {
            onChange(e.currentTarget.type)
        }
    };

    // 获取ul容器当前滚动条的位置
    getUlScrollTop = (e) => {
        const type = e.currentTarget.type;
        const { getCurrentScrollTop } = this.props;
        setTimeout(() => {
            const ulDom = document.getElementsByClassName('menuIconContainer');
            if (getCurrentScrollTop) getCurrentScrollTop(type, ulDom[0].scrollTop);
        }, 0)
    };

    //从内存中读取设置ul容器的初始位置并设置
    setUlScrollTop = () => {
        const { value } = this.state;
        setTimeout(() => {
            const current = getLocal(ChooseScrollTop) ? JSON.parse(getLocal(ChooseScrollTop)) : [];
            const target = value && current.find(item => item.type === value);
            const ulDom = document.getElementsByClassName('menuIconContainer');
            ulDom[0].scrollTop = target ? target.scrollTop : 0;
        }, 0)
    };

    //清除图标时清除选项的值
    selectOnChange = (value) => {
        const { onChange, getCurrentScrollTop } = this.props;
        if (!value) {
            this.setState({ value });
            if (getCurrentScrollTop) getCurrentScrollTop(undefined, undefined);//清空图标时，更新当前图标的值
            if (onChange) {
                onChange(value)
            }
        }
    };


    render() {
        const { visible, value } = this.state;
        const content = (
            <ul className='menuIconContainer'>
                {
                    menuIconLibrary.map(item => (
                        <li className='menuIconItem' type={item} key={item} onClick={this.menuIconClick} title={item}>
                            <div className={classnames('menuIcon', { 'active': this.state.value === item })}><Icon
                                type={item} /></div>
                        </li>
                    ))
                }
            </ul>
        );
        return (
            <div id='menuIconPopChooseWrapper'>
                <Popover
                    getPopupContainer={() => document.getElementById('menuIconPopChooseWrapper')}
                    content={content}
                    title="Title"
                    trigger="click"
                    visible={visible}
                    placement="bottomLeft"
                    onVisibleChange={this.onVisibleChange}
                >
                    <Select
                        placeholder='请选择图标'
                        open={false}
                        allowClear
                        onDropdownVisibleChange={this.togglePop}
                        onChange={this.selectOnChange}
                        value={value ? value : undefined}
                        onFocus={this.onFocus}
                    >
                        {value &&
                            <Select.Option value={value}>
                                <Icon type={value} className='chooseIcon' />{value}
                            </Select.Option>}
                    </Select>
                </Popover>
            </div>
        )
    }
}

export default MenuIconChoose;

