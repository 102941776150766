import React, { Component } from 'react';
import { Spin, Row, Button, Divider, message, Icon, Modal, Popover, Popconfirm } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import pcService from '../../other/pc.service';
import urls from './../../configs/api.config';
import TreeList from './../../common/components/tree/TreeList';
import httpService from '../../common/axios/request';
import moment from 'moment';
import { getLocal } from '../../common/utils';
import './informationDelivery.less';

const { confirm } = Modal;

class InformationDelivery extends Component {


    /* 右键操作列 */
    rightMenus = [
        { id: 1, title: "新增栏目" },
        { id: 2, title: "修改栏目" },
        { id: 3, title: "删除栏目" },
        // { id: 4, title: "取消操作" }
    ];

    state = {
        visible: false,
        userName: "",
        rightMenus: [],
        loading: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        expandedKeys: [],
        create: true,
        cCreate: true,
        modalOptions: {
            title: "资讯发布",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 1000
        },
        cModalOptions: {
            title: "栏目",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            width: 600,
            modalsLoading: false
        },
        lists: [],
        columnsList: [],//栏目树数据
        treeSelectedKeys: [],//选中的节点(栏目id)
        rootId: 0,
        rightMenuItem: {
            pageX: 0,
            pageY: 0,
            id: 0,
            categoryName: "",
            visible: false
        },
    }

    /* 表格title */
    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                        <Divider type="vertical" />
                        <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" /> 编辑</a>
                    </span>
                )
            }
        },
        {
            title: '封面',
            dataIndex: 'logo',
            key: 'logo',
            render: text => <img alt={text} className="table-logo" src={`${urls.file.viewImg}/${text}`} />
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: text => {
                if (text && text.length > 10) {
                    return (
                        <Popover content={text} placement="topRight"
                            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                            {text.slice(0, 10) + '...'}
                        </Popover>
                    );
                } else {
                    return text;
                }
            }
        },
        {
            title: '类别',
            dataIndex: 'typeDictId',
            key: 'typeDictId',
            render: (text) => pcService.numberToText(text, pcService.getDataDictionary("PUBLICATION_TYPE"))
        },
        {
            title: '阅读数',
            dataIndex: 'browseCount',
            key: 'browseCount',
            sorter: (a, b) => a.browseCount - b.browseCount
        },
        {
            title: '收藏数',
            dataIndex: 'collectionCount',
            key: 'collectionCount',
            sorter: (a, b) => a.collectionCount - b.collectionCount
        },
        {
            title: '评论数',
            dataIndex: 'commentCount',
            key: 'commentCount',
            sorter: (a, b) => a.commentCount - b.commentCount
        },
        {
            title: '发布时间',
            dataIndex: 'releaseTime',
            key: 'releaseTime',
            render: text => pcService.dateFormat(text, "yyyy-MM-dd"),
            sorter: (a, b) => new Date(a.releaseTime) - new Date(b.releaseTime)
        }
    ];;

    /* 搜索表单项 */
    seachFieldsList = [{
        name: "title",
        editor: "normal",
        value: "",
        originValue: "",
        hideDisplayName: true,
        displayName: "标题"
    }];

    /* 切换视频图文 */
    checkType = (item, form, value, option = []) => {
        this.fieldsList.map(v => {
            if (v.name === "videoFileMongdbId") {
                if (value === "VIDEO") {
                    v.editor = "videoWall";
                    v.rules = [
                        { required: true, message: "请上传视频资源" }
                    ]
                } else {
                    v.editor = "hidden";
                    v.rules = [];
                }
            }
        });
        this.setState({});
    }

    /* 栏目新增修改表单项 */
    cFieldsList = [{
        name: "id",
        editor: "hidden",
        value: ""
    },
    {
        name: "parentId",
        editor: "node-select",
        value: "",
        displayName: "上级栏目",
        originValue: "",
        rules: [],
        opts: []
    },
    {
        name: "name",
        editor: "normal",
        value: "",
        displayName: "栏目名称",
        rules: [
            { required: true, message: "请输入栏目名称" },
            { max: 20, message: "最长为20个字符" },
            { whitespace: true, message: "不能输入空格" }
        ]
    },
    {
        name: "wechatType",
        editor: "hidden",
        value: 0,
        originValue: 0,
        displayName: "端类型",
        opts: [
            { id: 0, name: "全部" },
            { id: 1, name: "PC端" },
            { id: 2, name: "APP端" }
        ],
        rules: [
            {
                required: true,
                message: "请选择端类型"
            }
        ]
    },
    {
        name: "enabled",
        editor: "select",
        value: "",
        originValue: true,
        displayName: "启停用",
        opts: [
            { id: true, name: "启用" },
            { id: false, name: "停用" }
        ],
        rules: []
    },
    {
        name: "sortNum",
        editor: "number",
        value: "",
        originValue: "0",
        displayName: "排序",
        range: [0, 9999],
        rules: []
    },
    {
        name: "description",
        editor: "textarea",
        value: "",
        originValue: "",
        displayName: "栏目描述",
        rules: [
            { max: 200, message: "最长为200个字符" }
        ]
    },
    ];

    /* 新增修改表单项 */
    fieldsList = [
        {
            name: "id",
            editor: "hidden",
            value: ""
        },
        {
            name: "title",
            editor: "normal",
            value: "",
            displayName: "标题",
            rules: [
                { required: true, message: "请输入标题" },
                { whitespace: true, message: "不能输入空格" },
                { max: 50, message: "最长为50个字符" }
            ]
        },
        {
            name: "author",
            editor: "",
            value: "",
            originValue: "",
            displayName: "作者",
            rules: [
                { required: true, message: "请输入作者" },
                { whitespace: true, message: "不能输入空格" },
                { max: 20, message: "最长为20个字符" }
            ]
        }, {
            name: "typeDictId",
            editor: "select",
            value: "",
            originValue: pcService.getDataDictionary("PUBLICATION_TYPE")[0] ? pcService.getDataDictionary("PUBLICATION_TYPE")[0].id : "",
            displayName: "发布类型",
            opts: pcService.getDataDictionary("PUBLICATION_TYPE"),
            onChange: this.checkType,
            rules: [{ required: true, message: "请选择发布类型" }]
        },
        {
            name: "releaseTime",
            editor: "datePicker",
            value: "",
            originValue: null,
            displayName: "发布时间",
            rules: [
                {
                    required: true,
                    message: "请选择发布时间"
                }
            ]
        }, {
            name: "content",
            editor: "bEditor",
            value: "",
            displayName: "內容",
            columns: 1,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            }
        },
        {
            name: "logo",
            editor: "pictureWall",
            value: "",
            displayName: "封面图",
            originValue: "",
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                imageUrl: "",
                loading: false,
                listType: "picture-card",
                showUploadList: false,
                onPreview: (...args) => {
                    //console.log(args);
                },
                accept: "image/*",
                beforeUpload: (file) => {
                    const isLt2M = file.size / 1024 / 1024 < 2;
                    if (!isLt2M) {
                        message.error('图片必须小于2MB!');
                    }
                    return isLt2M;
                },
                onChange: (info, form, _onchange) => {
                    let _value = info.fileList.map(v => v.response);
                    _onchange(_value.join(","));
                }
            },
            columns: 1,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            rules: [
                {
                    required: true,
                    message: "请上传封面图"
                }
            ]
        },
        {
            name: "videoFileMongdbId",
            editor: "hidden",
            value: "",
            columns: 1,
            originValue: "",
            displayName: "视频资源",
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                imageUrl: "",
                listType: "picture-card",
                loading: false,
                showUploadList: false,
                accept: "video/*",
                onChange: (info, form, _onchange) => {
                    let { response } = info.file;
                    _onchange(response);
                    /* if (info.file.status === 'uploading') {
                        this.fieldsList.map(v => {
                            if (v.name === "videoFileMongdbId") {
                                v.fileUpLoadOption.loading = true;
                            }
                            return;
                        });
                        this.setState({});
                    }
                    if (info.file.status === 'done') {
                        let { response } = info.file;
                        _onchange(response);
                        this.fieldsList.map(v => {
                            if (v.name === "videoFileMongdbId") {
                                v.fileUpLoadOption.loading = true;
                                v.fileUpLoadOption.imageUrl = urls.file.viewImg + "/" + response;
                            }
                            return;
                        });
                        this.setState({});
                    } */
                }
            },
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            rules: []
        },
    ];

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 获取栏目树形结构 */
    getColumns = () => {
        this.setState({
            loading: true
        }, () => {
            httpService.request({
                url: urls.app.columnManage.treeList,
                method: "get"
            }).then(res => {
                if (res.success) {
                    let columnsList = res.data;
                    let rootId = columnsList[0].id;
                    let treeSelectedKeys = columnsList[0].childrenList.length > 0 ? [columnsList[0].childrenList[0].id] : [rootId];
                    let expandedKeys = [rootId];
                    this.setState({ columnsList, treeSelectedKeys }, () => {
                        this.setState({ columnsList, treeSelectedKeys, rootId, expandedKeys }, () => {
                            let forms = this.refs.search.getForm();
                            this.seachOnSubmit(forms);
                        });
                    });

                } else {
                    this.setState({ loading: false });
                }
            }).catch(err => {
                this.setState({ loading: false })
            });
        })

    }

    /* 获取列表数据 */
    fetchData = (params) => {
        this.setState({ loading: true }, () => {
            let { treeSelectedKeys } = this.state;
            let _params = Object.assign({}, params, { columnId: treeSelectedKeys[0] });
            pcService.fetchData(urls.app.informationRelease.list, _params, null, (data) => {
                this.setState({
                    loading: false,
                    lists: data.rows
                });
            }, err => this.setState({ loading: false }));
        });
    }

    /* 搜索提交 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }
    /* 详情 */
    detailItem = (item) => {
        pcService.relativePath(`/app/informationDeliveryDetail?id=${item.id}`);
    }

    /* 新增and修改信息 */
    createOne = (item, create = true) => {
        let { modalOptions, userName } = this.state;
        if (!!create) {
            pcService.initFormList(this.fieldsList);
            this.fieldsList.map(v => {
                if (v.name === "typeDictId") v.unavailable = false;
                if (v.name === "author") v.value = userName;
                if (v.name === "videoFileMongdbId") {
                    v.editor = "hidden";
                    v.rules = [];
                }
            });
        } else {
            let typeDictIdValue = item["typeDictId"];
            this.fieldsList.map(v => {
                if (v.name === "typeDictId") v.unavailable = true;
                if (v.name === "videoFileMongdbId") {
                    if (typeDictIdValue === "VIDEO") {
                        v.editor = "videoWall";
                        v.rules = [{ required: true, message: "请上传视频资源" }];
                    } else {
                        v.editor = "hidden";
                        v.rules = [];
                    }

                }
            })
            pcService.bindFormdData(item, this.fieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
                modalsLoading: false
            },
            create
        });
    };


    /* 新增and修改栏目 */
    cCreateOne = (item, create = true) => {
        let { cModalOptions } = this.state;
        this.setState({
            cModalOptions: {
                ...cModalOptions,
                visible: true,
                modalsLoading: false
            },
            create
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create, treeSelectedKeys } = this.state;
        let url = "";
        if (create) {
            url = urls.app.informationRelease.add;
        } else {
            url = urls.app.informationRelease.update;
        }
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                /* 数据格式转换 */
                values["columnId"] = treeSelectedKeys[0];
                // values["releaseTime"] = pcService.dateFormat(new Date(values["releaseTime"]), "yyyy-MM-dd hh:mm:ss");
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                }, () => {
                    pcService.formSubmit(url, create, values, null, (data) => {
                        message.success((create ? "新增" : "修改") + "操作成功");
                        setTimeout(function () {
                            that.setState({
                                modalOptions: {
                                    ...modalOptions,
                                    modalsLoading: false,
                                    visible: false
                                }
                            });
                            let forms = that.refs.search.getForm();
                            that.seachOnSubmit(forms);
                        }, 1000);
                    }, err => {
                        message.error(err);
                        that.setState({
                            modalOptions: {
                                ...modalOptions,
                                modalsLoading: false
                            }
                        });
                    });
                });
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    cOnOk = (forms) => {
        let { cModalOptions, cCreate } = this.state;
        let url = "";
        if (cCreate) {
            url = urls.app.columnManage.add;
        } else {
            url = urls.app.columnManage.update;
        }
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.setState({
                    cModalOptions: {
                        ...cModalOptions,
                        modalsLoading: true
                    }
                }, () => {
                    pcService.formSubmit(url, true, values, {}, (data) => {
                        message.success((cCreate ? "新增" : "修改") + "操作成功");
                        this.setState({
                            cModalOptions: {
                                ...cModalOptions,
                                visible: false,
                                modalsLoading: false
                            }
                        });
                        that.getColumns();
                    }, (err) => {
                        message.error(err.message);
                    })
                })
            }
        });
    }

    /* cModal取消回调函数 */
    cOnCancel = () => {
        let { cModalOptions } = this.state;
        this.setState({
            cModalOptions: {
                ...cModalOptions,
                visible: false
            }
        });
    }

    /* 删除消息 （逻辑删除）*/
    deleteItem = (selectedRowKeys) => {
        let ids = selectedRowKeys.join(",");
        let that = this;
        this.setState({ loading: true });
        pcService.deleteItem(urls.app.informationRelease.delete, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.componentDidMount();
                }, 500);
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    /* 树形结构点击事件*/
    onSelect = (nodeKey) => {
        let { treeSelectedKeys } = this.state;
        if (treeSelectedKeys[0] !== nodeKey[0]) {
            this.setState({
                treeSelectedKeys: nodeKey
            }, () => {
                let forms = this.refs.search.getForm();
                this.seachOnSubmit(forms);
            });
        }
    }

    /*栏目右键点击事件*/
    onRightClick = ({ event, node }) => {
        let { rootId } = this.state;
        //let { rightMenus } = this;
        let rightMenus = [];
        if (rootId === node.props.eventKey) {
            rightMenus = this.rightMenus.filter(v => v.id !== 2 && v.id !== 3);
        } else {
            rightMenus = this.rightMenus;
        }
        this.setState({
            rightMenus,
            rightMenuItem: {
                pageX: event.pageX,
                pageY: event.pageY,
                id: node.props.eventKey,
                categoryName: node.props.title,
                visible: true
            }
        });
        // 右键绑定左键
        this.onSelect([node.props.eventKey])
    }

    /* 删除栏目 */
    cDeleteItem = (ids) => {
        let that = this;
        this.setState({ loading: true });
        pcService.deleteItem(urls.app.columnManage.delete, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.componentDidMount();
                }, 500);
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    /* 确认删除提示 */
    showConfirm = (id, content) => {
        let that = this;
        confirm({
            title: "确定删除此栏目吗？",
            content: content,
            centered: true,
            onOk() {
                that.cDeleteItem(id);
            },
            onCancel() { },
        });
    }

    /* 右键点击确定事件 */
    onRightClickSubmit = (id = 0, $event = null) => {
        let { rightMenuItem, cModalOptions, columnsList } = this.state;
        let opts = pcService.dataToTreeData(columnsList, "name", "id", "childrenList");
        this.cFieldsList.map(v => {
            if (v.name === "parentId") {
                v.opts = opts;
                v.originValue = rightMenuItem.id;
                return;
            }
        });
        let _state = {};
        switch (id) {
            case 1:
                //新增
                _state = {
                    cCreate: true,
                    cModalOptions: {
                        ...cModalOptions,
                        visible: true
                    }
                };
                pcService.initFormList(this.cFieldsList);
                break;
            case 2:
                //修改
                _state = {
                    cCreate: false,
                    cModalOptions: {
                        ...cModalOptions,
                        visible: true
                    }
                };
                let item = pcService.getActiveItemById(columnsList, rightMenuItem.id);
                pcService.bindFormdData(item, this.cFieldsList);
                break;
            case 3:
                //删除
                this.showConfirm(rightMenuItem.id, "删除栏目：" + rightMenuItem.categoryName)
                break;
            default:
                break;
        }
        this.setState({
            ..._state,
            rightMenuItem: {
                ...rightMenuItem,
                visible: false
            }
        });
    }
    /*  */
    getNodeTreeRightClickMenu = () => {

    }

    /* 点击蒙层关闭右键菜单 */
    toggleShowRightMenu = () => {
        let { rightMenuItem } = this.state;
        this.setState({ rightMenuItem: { ...rightMenuItem, visible: false } });
    }

    componentWillMount() {
        let userInfo = getLocal("user_info");
        this.setState({ userName: userInfo.name });
        pcService.initFormList(this.seachFieldsList);
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentDidMount() {
        this.getColumns();
    }

    render() {

        const { rightMenus, visible, expandedKeys, loading, rootId, columnsList, rightMenuItem, lists, treeSelectedKeys, pagination, modalOptions, cModalOptions, create, cCreate, selectedRowKeys } = this.state;
        const { toggleShowRightMenu, cancel, handleVisibleChange, seachFieldsList, cOnOk, cOnCancel, cFieldsList, onSelect, onRightClick, onRightClickSubmit, getNodeTreeRightClickMenu, seachOnSubmit, fetchData, fieldsList, columns, onOk, onCancel, deleteItem, createOne, onSelectChange } = this;
        const modal = { ...modalOptions, onOk, cOnOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const cModal = { ...cModalOptions, onOk: cOnOk, onCancel: cOnCancel, title: (cCreate ? "新增" : "编辑") + cModalOptions.title }
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const allowDel = selectedRowKeys.length > 0 && lists.length > 0;
        const allowCreate = !(rootId !== treeSelectedKeys[0] && !!rootId && !!treeSelectedKeys[0]);
        const formItemLayout = {
            labelCol: { xs: { span: 24 }, sm: { span: 6 } },
            wrapperCol: { xs: { span: 24 }, sm: { span: 18 } }
        },
            cFormItemLayout = {
                labelCol: { xs: { span: 24 }, sm: { span: 6 } },
                wrapperCol: { xs: { span: 24 }, sm: { span: 18 } }
            };
        return (

            <Spin spinning={loading}>
                <div className="info-d">
                    <div onClick={toggleShowRightMenu} className="infod-right-menu-mask" style={{ "display": rightMenuItem.visible ? "block" : "none" }}>
                        <div className="infod-right-menu" style={{ left: rightMenuItem.pageX, top: rightMenuItem.pageY }}>
                            <ul>
                                {rightMenus.map(v => <li onClick={onRightClickSubmit.bind(this, v.id)} key={v.id}>{v.title}</li>)}
                            </ul>
                        </div>
                    </div>
                    <div className="infod-left">
                        <div className="title">栏目分类</div>
                        <TreeList expandedKeys={expandedKeys} defaultExpandAll={true} childrenName="childrenList" selectedKeys={treeSelectedKeys}
                            treeList={columnsList} valueAndKey={["id", "name"]} onSelect={onSelect} onRightClick={onRightClick} />
                    </div>
                    <div className="infod-right">
                        <Row>
                            <div className="id-bars">
                                <div className="sp-btns">
                                    <Button disabled={allowCreate} icon="file-add" onClick={createOne} type="primary">资讯发布</Button>
                                    <Divider type="vertical" />
                                    <Popconfirm
                                        title="确定要删除吗?"
                                        visible={visible}
                                        onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                        onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                        onCancel={cancel}
                                        okText="确定"
                                        cancelText="取消" >
                                        <Button disabled={!allowDel} icon="delete" type="default">删除资讯</Button>
                                    </Popconfirm>
                                </div>
                                <div className="sp-forms">
                                    <PcForm ref="search" layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset fieldsList={seachFieldsList} />
                                </div>
                            </div>
                            <div className="sp-content">
                                <PcTable
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    pagination={pagination}
                                    dataSource={lists}
                                    fetchData={fetchData}
                                    bordered={false}
                                />
                            </div>
                        </Row>
                    </div>
                    <PcModal fieldsList={fieldsList} modal={modal} formItemColumns="2" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                    <PcModal fieldsList={cFieldsList} modal={cModal} modalsLoading={cModal.modalsLoading} formItemLayout={cFormItemLayout} />
                </div>
            </Spin>
        )
    }
}

export default InformationDelivery;
