import React, { Component } from 'react';
import { Button, Divider, message, Modal, Popover, Row, Spin, Icon } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import pcService from './../../other/pc.service';
import urls from './../../configs/api.config';
import ItemBankModal from './ItemBankModal';
import DetailModal from "../../common/components/detailModal/DetailModal";
import './democraticReviewList.css';
import PcService from "../../other/pc.service";

const { confirm } = Modal;

class DemocraticReviewItemBank extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        detailVisible: false,
        options: [],
        modalOptions: {
            title: "评议题",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: []
    };
    searchFieldsList = [{
        name: "createdTime",
        editor: "datePicker",
        originValue: null,
        timeOptions: {
            showToday: true,
            format: 'YYYY-MM-DD'
        },
        displayName: "创建时间"
    }, {
        name: "keyWord",
        editor: "normal",
        value: "",
        displayName: "名称",
        hideDisplayName: true,
        originValue: ""
    }];
    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: "id",
        editor: "hidden",
        value: ""
    }, {
        name: "category",
        editor: "hidden",
        value: "",
        originValue: "MZPY"
    }, {
        name: "title",
        editor: "normal",
        value: "",
        displayName: "题目",
        rules: ["required"]
    }, {
        name: "type",
        editor: "select",
        value: "",
        originValue: 0,
        displayName: "类型",
        unavailable: true,
        opts: [{
            id: 0,
            name: "单选"
        }],
        rules: ["required"]
    }];

    detailList = [{
        name: "title",
        editor: "normal",
        value: "",
        displayName: "题目",
    }, {
        name: "type",
        editor: "select",
        value: "",
        originValue: 0,
        displayName: "类型",
        unavailable: true,
        opts: [{
            id: 0,
            name: "单选"
        }],
    }];

    listColumns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '选项',
            dataIndex: 'examOption',
            key: 'clode',
        },
        {
            title: '选项内容',
            dataIndex: 'content',
            render: (value, record, index) => {
                if (value.length > 20) {
                    return (
                        <Popover content={value} placement="topRight"
                            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                            {value.slice(0, 20) + '...'}
                        </Popover>)
                } else {
                    return value;
                }
            }
        }
    ];

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        _params.category = "MZPY";
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.examQuestion.page, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.displayTip = 'none';
                });
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    };
    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                let createdTime = values["createdTime"] ? PcService.dateFormat(values["createdTime"], "yyyy-MM-dd hh:mm:ss") : values["createdTime"];
                let createdTimeStr = values["createdTime"] ? pcService.dateFormat(values["createdTime"], "yyyy-MM-dd") : values["createdTime"];
                let _values = {
                    ...values,
                    "createdTime": createdTime,
                    "createdTimeStr": createdTimeStr
                };
                this.fetchData(_values);
            }
        });
    };
    /* 新增试题 */
    createOne = (item, create) => {
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            pcService.bindFormdData(item, this.fieldsList);
            this.setState({
                options: item.examOptionList
            });
        } else {
            _create = true;
            pcService.initFormList(this.fieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    };
    /* modal确定回调函数 */
    onOk = ($event) => {
        let forms = this.refs.examQuestionModal.refs.forms.getForm();
        let { modalOptions, options, create } = this.state;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                if (options.length === 0) {
                    message.warning("问题选项不能为空，请检查");
                    return;
                }
                let url = "";
                if (create) {
                    url = urls.app.examQuestion.addEntity;
                } else {
                    url = urls.app.examQuestion.updateEntity;
                }
                options.map(item => delete item.id);
                values.options = options;
                pcService.formSubmit(url, true, values, {}, (data) => {
                    message.success("操作成功");
                    //console.log(data);
                    this.setState({ loading: false, modalOptions: { ...modalOptions, visible: false }, options: [] });
                    this.fetchData();
                }, (err) => {
                    message.error(err);
                    this.setState({ loading: false, modalOptions: { ...modalOptions, visible: false }, options: [] });
                });
            }
        });
    };
    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false,
            },
            options: []
        });
    };

    onDetailCancel = () => {
        this.setState({
            detailVisible: false
        });
    };
    /* 详情 */
    detailItem = (item) => {
        pcService.bindFormdData(item, this.detailList);
        this.setState({
            options: item.examOptionList,
            detailVisible: true,
        });
    };

    /**
     * 设置父组件状态值
     * @param args
     */
    setParentState = (...args) => {
        this.setState({
            ...args
        });
    };

    /**
     * 删除数据操作
     * @param item
     * @param form
     * @param $event
     */
    deleteItem = (item = [], form, $event) => {
        let that = this;
        confirm({
            title: '提示信息',
            content: '确认删除所选中的' + item.length + "项数据？",
            onOk() {
                let ids;
                if (form === 'button') {
                    ids = item.join(",");
                }
                that.setState({ loading: true });
                pcService.deleteItem(urls.app.examQuestion.logic, {}, ids,
                    (data) => {
                        message.success("删除成功!");
                        setTimeout(function () {
                            that.fetchData();
                        }, 500);
                        that.setState({ loading: false });
                    }, err => {
                        that.setState({ loading: false });
                    }
                );
            },
            onCancel() {
            }
        });
    };

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        }, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                        <Divider type="vertical" />
                        <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                    </span>
                )
            }
        },
        {
            title: '题目',
            dataIndex: 'title',
            key: 'title',
            //width: 200,
            render: (value) => value.length > 0 ? (
                <Popover content={value} placement="topRight"
                    overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                    {value.slice(0, 20) + '...'}
                </Popover>) : value
        }, {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            //width: 60,
            render: (value) => value === 0 ? "单选" : "多选"
        }, {
            title: '选项',
            dataIndex: 'optionView',
            key: 'optionView',
            render: (value) => value.length > 0 ? (
                <Popover content={value} placement="topRight"
                    overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                    {value.slice(0, 20) + '...'}
                </Popover>) : value
        }/* , {
            title: '状态',
            dataIndex: 'enabled',
            key: 'enabled',
            //width: 80,
            render: (value) => value === true ? "启用" : "停用"
        }, {
            title: '创建时间',
            dataIndex: 'createdTime',
            render: (value) => pcService.dateFormat(value, "yyyy-MM-dd hh:mm:ss"),
            sorter: (a, b) => new Date(a.createdTime) - new Date(b.createdTime)
        } */
    ];

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        this.fetchData();
    }

    render() {

        const { loading, selectedRowKeys, pagination, lists, modalOptions, create, options } = this.state;
        const { onSelectChange, columns, fetchData, searchFieldsList, searchOnSubmit, createOne, fieldsList, detailList, onOk, onCancel, setParentState, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 960, title: (create ? "新增" : "编辑") + modalOptions.title };
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        const dformItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 2 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 22 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Button icon="delete" disabled={!allowDel} onClick={deleteItem.bind(this, selectedRowKeys, 'button')}>删除</Button>
                            </div>
                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset
                                    fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                <Modal {...modal}>
                    <ItemBankModal ref="examQuestionModal" lists={options} onOk={modal.onOk}
                        fieldsList={fieldsList}
                        modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout}
                        setParentState={setParentState} />
                </Modal>
                <Modal title="评议试题详情" centered width={"960px"} visible={this.state.detailVisible} footer={null} onCancel={this.onDetailCancel}>
                    <DetailModal lists={options} fieldsList={detailList} formItemLayout={dformItemLayout} columns={this.listColumns} name={"基本信息"} />
                </Modal>
            </Spin>
        )
    }
}

export default DemocraticReviewItemBank;
