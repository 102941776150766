import React, { Component } from 'react';
import { Tabs } from 'antd';
import BannerList from './BannerList';
import BannerPosition from './BannerPosition';
import './bannerManagement.less';

const { TabPane } = Tabs;

class BannerManagement extends Component {

    /* 选项卡菜单 */
    tabsMenus = [{
        tab: "横幅管理",
        component: BannerList
    },
    {
        tab: "横幅位置管理",
        component: BannerPosition
    }];

    render() {
        /* 导航菜单 */
        const { tabsMenus } = this;
        return (
            < div className="bm-magage" >
                <Tabs defaultActiveKey="0">
                    {
                        tabsMenus.map((v, n) => (
                            <TabPane tab={v.tab} key={n}>
                                <v.component />
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div >
        )
    }
}

export default BannerManagement;
