import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import { Row, Spin, Button, Divider, Icon, message } from 'antd';
import urls from './../../configs/api.config';
import "./partyMemberEducationView.less";
import video from './video.jpg';
import selectOff from '../../img/fileImg/select_off.png';
import selectOn from '../../img/fileImg/select_on.png'
import PcService from './../../other/pc.service';

class PartyMemberEducationView extends Component {

    detailItem = (item) => {
        let id = item.id;
        PcService.relativePath(`/app/partyMemberEducationViewDetail?recordId=${id}`);
    }
    selectItem = (item) => {
        let { id } = item;
        this.props.selectItem(id)
    }
    createOne = (item, create) => {
        this.props.createOne(item, false)
    }

    render() {
        const { lists } = this.props;

        return (
            <div>
                <div className={"pmev-bg"}>
                    <div className={"pmev-box"}>
                        <div className={"pmev-lists"}>
                            {
                                lists ? lists.map((v, n) => {
                                    const face = v.courseCoverMongdbId ? urls.file.viewImg + '/' + v.courseCoverMongdbId : video;
                                    return (
                                        <div className={"pmev-item"} key={n}>
                                            <div className="select-container" >
                                                <a href="javascript:;" onClick={this.selectItem.bind(this, v)} > <img src={v.isSelect ? selectOn : selectOff} ></img></a>
                                                <div className={"pmev-eidt"}><a href="javascript:;" onClick={this.createOne.bind(this, v, false)} > <Icon type="edit" /></a></div>
                                            </div>
                                            <img src={face} alt="logo" className="face" onClick={this.detailItem.bind(this, v)} />
                                            <div>{v.courseName}</div>
                                            {/* <div className={"content"} dangerouslySetInnerHTML={{ __html: v.desp ? (v.desp.length > 35 ? v.desp.substr(0.35) + "..." : v.desp) : "" }}></div> */}
                                            <div className={"func"}>
                                                <Icon type="eye" /><span style={{ paddingRight: "24px" }}>{v.readedCount}</span>
                                                <Icon type="clock-circle" /><span>{v.createdTime ? v.createdTime.substr(0, 10) : ''}</span>
                                                {v.mustRequired ?
                                                    <span style={{ float: 'right', color: 'red' }}>必修</span> :
                                                    <span style={{ float: 'right' }}>选修</span>}
                                            </div>
                                        </div>
                                    )
                                }) : []
                            }


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PartyMemberEducationView;
