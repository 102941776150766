import React,{Fragment} from 'react';
import {Card,Popconfirm,message } from 'antd';
import {StandardTable} from '../../../sharedCommponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import { getTabPaneData} from '../../../common/utils';
import AllComponents from '../../../components';
import {convertListToTreeJson} from '../../../common/utils';
import {ButtonAuth,ALinkAuth} from '../../../sharedCommponents/AuthWidget';
import { get,delHttp } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SearchForm from './SearchForm';
import appConfig from './../../../configs/app.config';
import pcService from './../../../other/pc.service';
import contantConfig from './../../../configs/constant.config';
class RoleList extends React.Component {

    state={
        loading:false,
        selectedRowKeys:[],
        selectedRows: [],
        data: 
        {
            list: [],
            pagination: {
                pageIndex: 0,
                pageSize: appConfig.maxPageSize,
                total:0
            }
        },
        searchInitValue:{
            keyword:''
        }
    }

    columns=[
        {
            title: '操作',
            width: 280,
            align: 'center',
            render: (text, record) => (
                <Fragment>
                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        onClick={() => this.handleSetPermission(record)}
                        authCode={'9FD3C75A84E74711BEDEEF7FF40FBE3C'}
                    >
                        功能权限
                    </ALinkAuth>
                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        onClick={() => this.handleEdit(record)}
                        authCode={'0F8FFE1F3F3148ADB0D37793153626E1'}
                    >
                        编辑
                    </ALinkAuth>
                    <Popconfirm title="您确定要删除吗?" onConfirm={()=>this.handleDelete(record)} okText="是" cancelText="否">
                        <ALinkAuth
                            // href="javascript:;"
                            authCode={'F32F390ADB424E35BF871214934ED7CD'}
                        >
                            删除
                        </ALinkAuth>
                   </Popconfirm>
                </Fragment>
            ),
        },
        {
            title: '名称',
            dataIndex: 'roleName'
        }
    ]

    componentWillMount()
    {
        this.fetch({});
    }


    componentWillReceiveProps(nextProps)
    {
        if(nextProps.reloadState&&nextProps.reloadState!==this.props.reloadState)
        {
            const params = {
                pagination:{
                    pageIndex: 0
                }
            };
            this.fetch(params);
        }
    }
 
    fetch=(params = {})=>{
        this.setState({loading:true});
        let {pagination}=this.state.data;
        let pageIndex=pagination.pageIndex;
        let pageSize=pagination.pageSize;
        let keyword=this.state.searchInitValue.keyword;
        if(params)
        {
            if(params.pagination)
            {
                pageIndex=params.pagination.pageIndex||0;
                pageSize=params.pagination.pageSize||pageSize;
            }
            if(!params.keyword)
            {
                keyword='';
            }
            else
            {
                keyword=params.keyword
            }
        }
        let queryParams={
            pageIndex,
            pageSize,
            keyword
        };
        get({
            url:apiConfig.rui.basic.role.getAllRoles,
            params:queryParams
        }).then((res)=>{
            if(res)
            {
                let treeData=convertListToTreeJson(res.list,'0','subRole','roleId','levelBy');
                this.setState({
                    loading:false,
                    data:{
                        list:treeData,
                        pagination:{
                            pageIndex: queryParams.pageIndex,
                            pageSize: queryParams.pageSize,
                            total:res.total
                        }
                    },
                    searchInitValue:{
                        keyword:queryParams.keyword
                    }
                });
            }
            else
            {
                this.setState({ loading:false});
            }
        }).catch((error)=>{
            this.setState({ loading:false});
        });
    }
    
    
    handleSetPermission=(role)=>{
        /*let { selectedMenuKey }=this.props;
        let paneKey="set-rolepermision-pane";
        let SetPermissions=AllComponents["SetPermissions"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"设置角色功能权限",
            content: <SetPermissions role={role} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        //this.props.updateTabPane(addPane);*/
        pcService.relativePath('/app/setPermissions?roleId='+role.roleId);
    }

    handleSelectRows = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRows,
            selectedRowKeys
        });
    };

    handleStandardTableChange = pagination => {
        const params = {
            pagination:{
                pageIndex: pagination.current-1,
                pageSize: pagination.pageSize
            }
        };
        this.fetch(params);
    };

    handleSearch=(seacheValues)=>{
        let params;
        if(seacheValues)
        {
           params = {
               pagination:{
                   pageIndex: 0
               },
               keyword:seacheValues.keyword
           };
        }
        else
        {
           params = {
               pagination:{
                   pageIndex: 0
               },
               keyword:''
           };
        }
        this.fetch(params);
    }

    handleDelete=(role)=>{
        this.setState({loading:true});
        delHttp({
            url:apiConfig.rui.basic.role.deleteRole,
            params:{
                roleId:role.roleId
            }
        }).then((res)=>{
            const params = {
                pagination:{
                    pageIndex: 0
                }
            };
            message.success(contantConfig.DEL_SUCCESS);
            this.fetch(params);
        }).catch((error)=>{
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading:false});
         });
    }

    handleDeleteAll=()=>{
        let {selectedRowKeys}=this.state;
        let roleIds='';
        if(selectedRowKeys.length===0)
        {
            message.info('请选择要删除的角色');
            return;
        }
        for(let selectedRowKey of selectedRowKeys)
        {
            roleIds+=selectedRowKey+',';
        }
        if(roleIds)
        {
            roleIds=roleIds.substr(roleIds,roleIds.length-1);
        }
        this.setState({loading:true});
        delHttp({
            url:apiConfig.rui.basic.role.deleteAll,
            params:{
                roleIds:roleIds
            }
        }).then((res)=>{
            this.setState({
                selectedRows:[],
                selectedRowKeys:[]
            },()=>{
                const params = {
                    pagination:{
                        pageIndex: 0
                    }
                };
                message.success(contantConfig.DEL_SUCCESS);
                this.fetch(params);
            });
        }).catch((error)=>{
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading:false});
         });
    }

    handleAdd=()=>{
        /*let { selectedMenuKey }=this.props;
        let paneKey="add-role-pane";
        let CreateRoleForm=AllComponents["CreateRoleForm"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"新增角色",
            content: <CreateRoleForm paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
       // this.props.updateTabPane(addPane);*/
       pcService.relativePath('/app/roleManage');
    }

    handleEdit=(role)=>{
       /* let { selectedMenuKey }=this.props;
        let CreateRoleForm=AllComponents["CreateRoleForm"];
        let paneKey="edit-role-pane";
        let addPane=getTabPaneData({
            key: paneKey,
            title:"编辑角色",
            content: <CreateRoleForm editId={role.roleId} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        this.props.updateTabPane(addPane);*/
        pcService.relativePath('/app/roleManage?editId='+role.roleId);
    }

    render() {
        const {selectedRows,loading, data,searchInitValue} = this.state;
        return (
           <div className="mainDiv">
               <Card bordered={false}>
                   <div className='tableSearchForm'>
                            <SearchForm
                                handleSearch={this.handleSearch}
                                searchInitValue={searchInitValue}
                            />
                   </div>
                   <div className='tableAction'>
                        <ButtonAuth
                                icon="plus"
                                type="primary"
                                dataaction="create"
                                onClick={() => this.handleAdd()}
                                authCode={'5B3831D8CD614294B49EFC6685A33F70'}
                            >
                                新增
                        </ButtonAuth>
                        <Popconfirm title="您确定要删除选中的角色吗?" onConfirm={()=>this.handleDeleteAll()} okText="是" cancelText="否">
                                        <ButtonAuth
                                                icon="delete"
                                                type="primary"
                                                dataaction="delete"
                                                loading={loading}
                                                authCode={'644FA8256C7643F9AD86BD05F952B8E4'}
                                            >
                                                批量删除
                                        </ButtonAuth>
                       </Popconfirm>
                   </div>
                   <StandardTable
                            selectedRows={selectedRows}
                            disableSelect={false}
                            loading={loading}
                            rowKey="roleId"
                            data={data}
                            columns={this.columns}
                            childrenColumnName="subRole"
                            isNeedeSelectedKeys={true}
                            isTreeTable={true}
                            onSelectRow={this.handleSelectRows}
                            onChange={this.handleStandardTableChange}
                        />
               </Card>
           </div>
         )
      }
}


const mapStateToProps = state => {
    const { menuTab } = state;
    return {
        selectedMenuKey:menuTab.selectedMenuKey
    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(RoleList);