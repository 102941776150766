import React, { Component } from 'react';
import { Spin, Button, Input, DatePicker, Icon, message, Tree } from 'antd';
import PcService from './../../other/pc.service';
import TreeList from './../../common/components/tree/TreeList';
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import { convertListToTreeJson } from './../../common/utils'
import ReactToPrint from 'react-to-print';
import './organizationDetail.css';

export class OrganizationDetail extends Component {

    state = {
        loading: false,
        treeList: [],
        arr: ['orgMaster', 'orgMasterVice'],
        infoTitle: '',
    }
    /* 修改表单 列项*/
    fieldsList = [{
        name: 'feeUrl',
        displayName: '收款二维码',
        value: '',
    }, {
        name: 'superPartyName',
        displayName: '上级党组织',
        value: this.props.location ? this.props.location.query.superPartyName : '无',
    }, {
        name: 'partyType',
        displayName: '组织类别',
        value: '',
    }, {
        name: 'partyName',
        displayName: '党组织名称',
        value: '',
    }, {
        name: 'foundingTime',
        displayName: '成立时间',
        value: null,
    }, {
        name: 'unitsName',
        displayName: '所属单位',
        value: '',
    }, {
        name: 'partyAddress',
        displayName: '组织地址',
        value: '',
    }, {
        name: 'feeMemberName',
        displayName: '指定收款人',
        value: '',
    }];
    fieldsListLeaders = [];
    fieldsListOtherInfo = [{
        name: 'linkedName',
        displayName: '党组织联系人',
        value: '',
    }, {
        name: 'telphone',
        displayName: '联系电话',
        value: '',
    }, {
        name: 'wechatUrl',
        displayName: '微信公众号链接',
        value: '',
    }, {
        name: 'email',
        displayName: '邮箱',
        value: '',
    }, {
        name: 'thumbnail',
        displayName: '缩略图',
        value: '',
    }, {
        name: 'abstractInfo',
        displayName: '简介',
        value: '',
    }];
    positionClass = [
        { id: "0", name: "无" },
        { id: "1", name: "组长" },
        { id: "2", name: "副组长" }
    ]

    initData = (id) => {
        let _params = {
            structureId: id
        }
        this.setState({ loading: true });
        http.request({
            method: 'get',
            url: urls.app.organization.selectByStructureId,
            params: _params,
        }).then(res => {
            //console.log(res)
            if (res.success === true) {
                let _record = res.data.rows[0];
                let _leaderMember = res.data.rows[0].leaderMember;
                this.bindFieldsListData(_record, this.fieldsList);
                this.bindFieldsListData(_record, this.fieldsListOtherInfo);
                let arr = [];
                if (_leaderMember && _leaderMember.length) {
                    _leaderMember.map(k => {
                        arr.push({
                            name: k.partyMember,
                            displayName: PcService.numberToText(k.position, this.props.location && this.props.location.query.partyType !== "PARTY_CLASS" ?
                                PcService.getDataDictionary("POSITION") : this.positionClass),
                            value: k.name,
                        })
                    });
                }
                this.fieldsListLeaders = arr;
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({
                loading: false
            });
        });
    }

    //批量赋值fieldsList数据
    bindFieldsListData(record, fieldsList) {
        //console.log(record)
        let arr = [];
        fieldsList.map(v => {
            for (const key in record) {
                let recKey = record[key] ? record[key] + "" : "无";
                if (v.name === key) {
                    v.value = recKey;
                    //时间
                    if (v.name === 'foundingTime' && v.value !== "无") {
                        v.value = PcService.dateFormat(record[key], 'yyyy-MM-dd')
                    }
                    if (v.name === 'partyType') {
                        v.value = PcService.numberToText(record[key], PcService.getDataDictionary("ORG_TYPE"))
                    }
                    /* //党组织联系人
                    if (v.name === 'linkedName') {
                        v.value = PcService.dateFormat(record[key], 'yyyy-mm-dd')
                    } */
                    //判断部分人得到的参数：id，name，截取name
                    if (recKey.indexOf(',') > -1 && v.name !== 'abstractInfo') {
                        v.value = recKey.substring(recKey.indexOf(','));
                    }
                    if (v.name === 'thumbnail' && recKey && recKey !== "无") {
                        let url = urls.file.viewImg + '/' + recKey;
                        v.value = PcService.getImgFromUrl(url);
                    }
                    if (v.name === 'feeUrl' && recKey && recKey !== "无") {
                        let url = urls.file.viewImg + '/' + recKey;
                        v.value = PcService.getImgFromUrl(url);
                    }
                }
                if (v.name === "leaderMember") {

                }

            }
        });
        this.fieldsListLeaders.push = arr;
    };

    partyClass = () => {
        let isPartyClass = this.props.location && this.props.location.query.partyType === "PARTY_CLASS";
        if (isPartyClass) {
            this.fieldsList.map(v => {
                if (v.name === "partyType" || v.name === "partyAddress") {
                    v.editor = "hidden";
                    v.rules = [];
                }
                if (v.name === "partyName") {
                    v.displayName = "党小组名称";
                }
            })
        }
    }
    back = () => {
        let panelId = PcService.getIdByPath("/app/organizationDetail");
        PcService.removePanel(panelId);
    }
    print = () => {
        document.getElementById('bindptdetail').click();
    }


    /* 钩子函数，组件载入时 */
    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.id : '';
        this.initData(id);
        //党小组处理
        this.partyClass();

    }
    render() {
        const { infoTitle } = this.state;
        const { back, print } = this;
        return (
            <Spin spinning={this.state.loading} className="">
                <div className="org-box-detail">
                    <ReactToPrint
                        trigger={() => <a href="javascript:void(0);" ref={'bindptdetail'} id={'bindptdetail'}></a>}
                        content={() => this.componentRef}
                    />
                    <div className="org-main" ref={el => (this.componentRef = el)}  >
                        <div className="padetail-button">
                            <Button type="default" onClick={back}>返回</Button>
                            {/* <Button type="primary" onClick={print}>打印</Button> */}
                        </div>
                        <div className="org-main-inner">
                            <div className="org-detail">
                                <label className="org-main-lab">{infoTitle}</label>
                                <div className="org-marin-tipinfo">
                                    <span className="org-main-tip" />
                                    {this.props.location && this.props.location.query.partyType === "PARTY_CLASS" ? "党小组基本信息" : "党组织基本信息"}
                                </div>
                                <div className="org-orginfo-form-base">
                                    {
                                        this.fieldsList.map((item) => {
                                            return (
                                                <div className="org-detail-list" key={item.name} style={{ display: item.editor === "hidden" ? "none" : null }} >
                                                    {
                                                        item.name !== "feeUrl" ? <div className="org-detail-item" value={item.value}>{item.displayName}：{item.value}</div>
                                                            : <div className="org-detail-item feeUrl" >{item.value}
                                                                <div className="urlname">{item.displayName}</div>
                                                            </div>
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="org-marin-tipinfo">
                                    <span className="org-main-tip" />
                                    {this.props.location && this.props.location.query.partyType === "PARTY_CLASS" ? "党小组成员" : "班子成员"}
                                </div>
                                <div className="org-orginfo-form-leader">
                                    {
                                        this.fieldsListLeaders.length !== 0 ? this.fieldsListLeaders.map((item) => {
                                            return (
                                                <div className="org-detail-list" key={item.name} >
                                                    <div className="org-detail-item" value={item.value}>{item.displayName}：{item.value}</div>
                                                </div>
                                            )
                                        }) : "暂无人员！"
                                    }
                                </div>
                                <div style={{ display: this.props.location && this.props.location.query.partyType === "PARTY_CLASS" ? "none" : null }}>
                                    <div className="org-marin-tipinfo">
                                        <span className="org-main-tip" /> 附加信息 </div>
                                    <div className="org-detail-other" style={{ clear: 'both' }}>
                                        {
                                            this.fieldsListOtherInfo.map((item) => {
                                                return (
                                                    <div className="org-detail-list" key={item.name} >
                                                        {
                                                            item.name === "abstractInfo" ? <div className="org-detail-item" dangerouslySetInnerHTML={{ __html: "简介：" + item.value }}></div> :
                                                                <div className="org-detail-item" value={item.value}>{item.displayName}：{item.value}</div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}

export default OrganizationDetail;
