import React, { Component } from 'react';
import { Row, Spin, Button, Icon, Modal, Divider, message, Popconfirm } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import pcService from './../../other/pc.service';
import { getLocal, convertListToTreeJson, setJsonArray } from './../../common/utils';
import PcModal from '../../common/components/modal/Modals';
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import './partyFeeManagement.css';
let thisYear = new Date().getFullYear() - 2;

export class partyFeeRecord extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        lists: [],
        modalOptions: {
            title: "缴纳记录",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        modalDetailOperation: {
            title: "缴纳记录明细",
            visible: false,
            cancelText: "返回",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        partyMemberList: [],
        fieldsListDetailList: [],
        communistInfoNames: "",
        communistInfoIds: "",
        totalValue: 0,
        parytFeeManagementData: [],
        month1: 0,
        month2: 0,
        month3: 0,
        month4: 0,
        month5: 0,
        month6: 0,
        month7: 0,
        month8: 0,
        month9: 0,
        month10: 0,
        month11: 0,
        month12: 0,
    }

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (text, record, index) => index + 1
        }, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 120,
            align: 'center',
            render: (text, record, index) => <a onClick={this.detailItem.bind(this, record)} ><Icon type="menu-unfold" /> 缴纳明细</a>
            /* (text, record, index) => !record.status ?
               (<span>
                   <Popconfirm title="确认收款吗？" onConfirm={this.editItem.bind(this, record)} okText="确定"
                       cancelText="取消">
                       <a ><Icon type="notification" /> 确认</a>
                   </Popconfirm>
                   <Divider type="vertical" />
                   <a onClick={this.detailItem.bind(this, record)} ><Icon type="search" /> 缴纳明细</a>
               </span>)
               : (<span>
                   <a onClick={this.detailItem.bind(this, record)} ><Icon type="search" /> 缴纳明细</a>
               </span>) */
        }, {
            title: '姓名',
            dataIndex: 'communistInfoName'
        }, {
            title: '年度',
            dataIndex: 'years',
            key: 'years'
        }, {
            title: '1月',
            dataIndex: 'month1',
            key: 'month1',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '2月',
            dataIndex: 'month2',
            key: 'month2',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '3月',
            dataIndex: 'month3',
            key: 'month3',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '4月',
            dataIndex: 'month4',
            key: 'month4',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '5月',
            dataIndex: 'month5',
            key: 'month5',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '6月',
            dataIndex: 'month6',
            key: 'month6',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '7月',
            dataIndex: 'month7',
            key: 'month7',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '8月',
            dataIndex: 'month8',
            key: 'month8',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '9月',
            dataIndex: 'month9',
            key: 'month9',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '10月',
            dataIndex: 'month10',
            key: 'month10',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '11月',
            dataIndex: 'month11',
            key: 'month11',
            render: (text) => text ? text.toFixed(2) : ""
        }, {
            title: '12月',
            dataIndex: 'month12',
            key: 'month12',
            render: (text) => text ? text.toFixed(2) : ""
        }/* , {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => text ? "已确认" : "未确认"
        } */
    ];
    /* 新增、修改 表单列项 */
    fieldsList = [
        /* {
            name: "paymentBase",
            editor: "hidden",
            value: "",
        }, */ {
            name: "id",
            editor: "hidden",
            displayName: "id",
            value: "",
        }, {
            name: "communistInfoNames",
            editor: "hidden",
            value: "",
        }, {
            name: "communistInfoIds",
            editor: "addOn",
            value: [],
            originValue: [],
            displayName: "姓名",
            rules: [{
                message: "请选择姓名",
                required: true
            }],
            opts: [],
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            onChange: (item, form, val, title, option) => {
                let communistInfoIds = val.join(",");
                let communistInfoNames = title.join(",");
                form.setFieldsValue({ communistInfoNames: title });
                this.setState({ communistInfoIds, communistInfoNames });
                this.getRuleBycommunistInfoIds(form);
                // let param = {
                //     id: valStructId,
                //     year: "",
                // }
                // this.getRuleBycommunistInfoIds(param, form);//根据党员id获取缴纳说明 
                //处理小组，则挂载在支部
                // let jsonArr = setJsonArray(option, "children");
                // let filterJsonArr = jsonArr.filter(v => v.id === valStructId);
                // if (filterJsonArr[0].partyType === "PARTY_CLASS") {
                //     let parentJsonArr = jsonArr.filter(v => v.id === filterJsonArr[0].parentId);
                //     form.setFieldsValue({
                //         "communistInfoIds": val,
                //         //"structureId": parentJsonArr[0].structureId,
                //         "years": ""
                //     })
                // } else {
                //     form.setFieldsValue({
                //         "communistInfoIds": val,
                //         //"structureId": valStructId,
                //         "years": ""
                //     });
                // }
                // //------- 
                // this.setState({ communistInfoNames: title[0] })
                // let param = {
                //     id: val,
                //     structureId: val.split("_")[1],
                //     year: "",
                // }
                // this.setState({ communistInfoIds: val });
                // this.getRuleBycommunistInfoIds(param, form);//根据党员id获取缴纳说明 
            },
            addOnOptions: {
                editor: "node-select",
                nodeOptions: {
                    treeCheckable: true
                },
                addOn: {
                    attrs: { disabled: false },
                    AddOn: (props) => <Button onClick={this.reset} {...props}>重置</Button>
                }
            }
        }, /* {
            name: "structureId",
            editor: "node-select",
            value: null,
            originValue: null,
            displayName: "所属党组织",
            unavailable: true,
            opts: [],
            nodeOptions: {
                multiple: true
            },
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
        }, */ {
            name: "years",
            editor: "select",
            value: "",
            displayName: "年份",
            //unavailable: true,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            rules: ["required"],
            opts: [],
            onChange: (item, form, val, option) => {
                this.getRuleBycommunistInfoIds(form);
            },
        }, {
            name: "month1",
            editor: "checkbox",
            displayName: "1月",
            value: [],
            opts: [
                { id: 1, name: '1月' }
            ],
            hideDisplayName: true,
            //unavailable: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            },
        }, {
            name: "month2",
            editor: "checkbox",
            displayName: "2月",
            value: [],
            opts: [
                { id: 1, name: '2月' }
            ],
            hideDisplayName: true,
            //unavailable: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month3",
            editor: "checkbox",
            displayName: "3月",
            value: [],
            opts: [
                { id: 1, name: '3月' }
            ],
            hideDisplayName: true,
            //unavailable: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month4",
            editor: "checkbox",
            displayName: "4月",
            value: [],
            opts: [
                { id: 1, name: '4月' }
            ],
            hideDisplayName: true,
            //unavailable: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month5",
            editor: "checkbox",
            displayName: "5月",
            value: [],
            opts: [
                { id: 1, name: '5月' }
            ],
            hideDisplayName: true,
            //unavailable: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month6",
            editor: "checkbox",
            displayName: "6月",
            //unavailable: true,
            value: [],
            opts: [
                { id: 1, name: '6月' }
            ],
            hideDisplayName: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month7",
            editor: "checkbox",
            displayName: "7月",
            value: [],
            opts: [
                { id: 1, name: '7月' }
            ],
            //unavailable: true,
            hideDisplayName: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month8",
            editor: "checkbox",
            displayName: "8月",
            value: [],
            opts: [
                { id: 1, name: '8月' }
            ],
            //unavailable: true,
            hideDisplayName: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month9",
            editor: "checkbox",
            displayName: "9月",
            value: [],
            opts: [
                { id: 1, name: '9月' }
            ],
            //unavailable: true,
            hideDisplayName: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month10",
            editor: "checkbox",
            displayName: "10月",
            value: [],
            opts: [
                { id: 1, name: '10月' }
            ],
            hideDisplayName: true,
            //unavailable: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month11",
            editor: "checkbox",
            displayName: "11月",
            value: [],
            opts: [
                { id: 1, name: '11月' }
            ],
            //unavailable: true,
            hideDisplayName: true,
            columns: 3,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "month12",
            editor: "checkbox",
            displayName: "12月",
            value: [],
            opts: [
                { id: 1, name: '12月' }
            ],
            //unavailable: true,
            hideDisplayName: true,
            columns: 3,
            formItemLayout: this.formItemLayoutStyle,
            onChange: (val, form, item, option) => {
                this.getRuleBycommunistInfoIds(form);
            }
        }, {
            name: "total",
            displayName: "总额",
            editor: "number",
            value: "",
            originValue: "",
            rules: ["required"],
            //unavailable: true,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            }
        }/* , {
            name: 'feeUrl',
            displayName: '收款二维码',
            editor: 'pictureText',
            value: '',
            originValue: '',
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            }
        } */
    ];
    /* 增加党员 */
    reset = () => {
        this.refs.modelForm.refs.forms.resetFields();
        this.fieldsList.map(v => {
            /*  if (v.name.indexOf("month") !== -1) {
                 v.unavailable = true;
                 v.opts[0].name = v.opts[0].name.split("：")[0];
             } */
            /*  if (v.name === "communistInfoNames") {
                 v.unavailable = false;
             } */
            /*  if (v.name === "years") {
                 v.unavailable = true;
             } */
            if (v.name === "total") {
                v.value = "";
            }
            if (v.name === "feeUrl") {
                v.value = "";
            }
        })
        this.setState({ totalValue: 0 })
    }
    seachFieldsList = [
        {
            name: "communistInfoIdsTreeShow",
            editor: "node-select",
            nodeOptions: {
                multiple: false
            },
            displayName: "姓名",
            opts: [],
        }, {
            name: "structureId",
            editor: "node-select",
            value: "",
            originValue: "",
            opts: [],
            displayName: "党组织",
        }, {
            name: "years",
            editor: "select",
            value: "",
            displayName: "年份",
            opts: [
                { id: "", name: "全部" },
                { id: thisYear - 8, name: thisYear - 8 },
                { id: thisYear - 7, name: thisYear - 7 },
                { id: thisYear - 6, name: thisYear - 6 },
                { id: thisYear - 5, name: thisYear - 5 },
                { id: thisYear - 4, name: thisYear - 4 },
                { id: thisYear - 3, name: thisYear - 3 },
                { id: thisYear - 2, name: thisYear - 2 },
                { id: thisYear - 1, name: thisYear - 1 },
                { id: thisYear, name: thisYear },
                { id: thisYear + 1, name: thisYear + 1 },
                { id: thisYear + 2, name: thisYear + 2 },
                { id: thisYear + 3, name: thisYear + 3 },
                { id: thisYear + 4, name: thisYear + 4 },
                { id: thisYear + 5, name: thisYear + 5 },
                { id: thisYear + 6, name: thisYear + 6 },
            ]
        }, {
            name: "isCompleted",
            editor: "select",
            value: "",
            displayName: "缴纳情况",
            opts: [
                { id: "", name: "全部" },
                { id: 1, name: "缴纳" },
                { id: 0, name: "未缴纳" },
            ]
        }
    ];
    /* 详情表单列项 */
    fieldsListDetail = [
        {
            name: "dueType",
            value: "",
            displayName: "缴纳类型",
            originValue: "",
        }, {
            name: "paymentBase",
            value: "",
            displayName: "月收入",
            originValue: "",
        }, {
            name: "ratio",
            value: "",
            displayName: "缴纳比例",
            originValue: "",
        }, {
            name: "amountDue",
            value: "",
            originValue: "",
            displayName: "月缴纳金额",
        }, {
            name: "memo",
            displayName: "备注",
            originValue: "",
            value: "",
        }
    ];
    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.parytFeeManagement.pageRecord, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            });
        });
    }
    /* modal缴纳说明变更说明 */
    onDetailOk = (forms) => {
        let { modalDetailOperation } = this.state;
        let that = this;
        this.setState({
            modalDetailOperation: {
                ...modalDetailOperation,
                visible: false
            }
        });
    }
    onDetailCancel = () => {
        let { modalDetailOperation } = this.state;
        this.setState({
            modalDetailOperation: {
                ...modalDetailOperation,
                visible: false
            }
        });
    }
    detailItem = (item) => {
        let { modalDetailOperation } = this.state;
        this.setState({ loading: true });
        let _params = { partyDuesRecordId: item.id, };
        http.request({
            method: 'get',
            url: urls.app.parytFeeManagement.findByPartyDuesRecordId,
            params: _params
        }).then(res => {
            if (res.success) {
                let successData = res.data;
                this.setState({
                    fieldsListDetailList: successData,
                    loading: false,
                    modalDetailOperation: {
                        ...modalDetailOperation,
                        visible: true
                    }
                });
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        });
    }
    /* 根据党员id获取缴纳说明 */
    getRuleBycommunistInfoIds = (form) => {
        const { modalOptions } = this.state;
        const { communistInfoIds = "",
            years = "",
            month1 = 0,
            month2 = 0,
            month3 = 0,
            month4 = 0,
            month5 = 0,
            month6 = 0,
            month7 = 0,
            month8 = 0,
            month9 = 0,
            month10 = 0,
            month11 = 0,
            month12 } = form.getFieldsValue();
        const ids = communistInfoIds.join(",");
        const monthsArr = [month1, month2, month3, month4, month5, month6, month7, month8, month9, month10, month11, month12];
        if (communistInfoIds.length === 0 || !years || monthsArr.every(v => !v || (!!v && v.length == 0))) {
            form.setFieldsValue({ total: 0 });
            return;
        };
        let months = [];
        monthsArr.map((v, n) => {
            if (!!v && v.length && v.length > 0) {
                months.push(n + 1);
            }
        })
        this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
        http.request({
            url: urls.app.parytFeeManagement.getTotalByCommunistInfoIdAndYearMonth,
            params: { ids, year: years, months: months.join(",") },
            method: "get"
        }).then(res => {
            if (res.success) {
                const total = res.data || 0;
                form.setFieldsValue({ total });
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: false } });
            } else {
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: false } });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ modalOptions: { ...modalOptions, modalsLoading: false } });
        })
        // pcService.fetchData(urls.app.parytFeeManagement.getTotalByCommunistInfoIdAndYearMonth, params, null, (data) => {
        //     if (!params.year) {
        //         let years = [];
        //         data.partyDuesRuleList.length && data.partyDuesRuleList.map(v => {
        //             years.push({
        //                 id: v.years,
        //                 name: v.years
        //             });
        //         })
        //         this.fieldsList.map(v => {
        //             /*  if (v.name === "years") {
        //                  v.opts = years;
        //                  v.unavailable = false;
        //              } */
        //             if (v.name === "communistInfoNames") {
        //                 // v.unavailable = true;
        //             }
        //             if (v.name.indexOf("month") !== -1) {
        //                 v.unavailable = true;
        //             }
        //             if (v.name === "feeUrl") {
        //                 v.value = data.feeUrl;
        //             }
        //         })
        //         //console.log(this.fieldsList)
        //     } else {
        //         this.setState({
        //             parytFeeManagementData: data.partyDuesRuleList
        //         })
        //         this.fieldsList.map(v => {
        //             if (v.name.includes("month")) {
        //                 v.unavailable = false;
        //             }
        //             /* if (v.name === "communistInfoNames") {
        //                 v.unavailable = true;
        //             } */
        //             /*  if (v.name === "years") {
        //                  v.unavailable = true;
        //              } */
        //             if (v.name === "feeUrl") {
        //                 v.value = data.feeUrl;
        //             }
        //             //拼接每个月的党费：1月——200
        //             /*  data.partyDuesRuleList.length && data.partyDuesRuleList.map(s => {
        //                  if (v.name.includes("month")) {
        //                      if (s[v.name] && s[v.name] !== 0) {
        //                          v.opts[0].name += "：" + s[v.name];
        //                      } else {
        //                          v.unavailable = true;
        //                      }
        //                  }
        //              }) */
        //         })
        //     }
        //     //清除monthvalue及mongth CheckBox属性
        //     this.setState({
        //         month1: 0,
        //         month2: 0,
        //         month3: 0,
        //         month4: 0,
        //         month5: 0,
        //         month6: 0,
        //         month7: 0,
        //         month8: 0,
        //         month9: 0,
        //         month10: 0,
        //         month11: 0,
        //         month12: 0,
        //         loading: false
        //     });
        // });
    }
    /* 计数总数 */
    countTotal = (checkedValue) => {
        const { totalValue, parytFeeManagementData } = this.state;
        let monthValue = this.monthToMonthValue(checkedValue, parytFeeManagementData);
        this.setState({
            totalValue: totalValue + monthValue
        })
        this.fieldsList.map(v => {
            if (v.name === "total") {
                v.value = totalValue + monthValue
            }
        })
    }
    monthToMonthValue = (monthEach, parytFeeManagementData) => {
        for (const key in parytFeeManagementData[0]) {
            let month = "month" + monthEach;
            if (key === month) {
                this.saveMonth(month, parytFeeManagementData[0][key]);
                return parytFeeManagementData[0][key];
            }
            if ((key + "del") === month) {
                this.saveMonth(month, -parytFeeManagementData[0][key]);
                return -parytFeeManagementData[0][key];
            }
        }
    }
    saveMonth = (month, parytFeeValue) => {
        switch (month) {
            case "month1":
                this.setState({ month1: parytFeeValue })
                break;
            case "month2":
                this.setState({ month2: parytFeeValue })
                break;
            case "month3":
                this.setState({ month3: parytFeeValue })
                break;
            case "month4":
                this.setState({ month4: parytFeeValue })
                break;
            case "month5":
                this.setState({ month5: parytFeeValue })
                break;
            case "month6":
                this.setState({ month6: parytFeeValue })
                break;
            case "month7":
                this.setState({ month7: parytFeeValue })
                break;
            case "month8":
                this.setState({ month8: parytFeeValue })
                break;
            case "month9":
                this.setState({ month9: parytFeeValue })
                break;
            case "month10":
                this.setState({ month10: parytFeeValue })
                break;
            case "month11":
                this.setState({ month11: parytFeeValue })
                break;
            case "month12":
                this.setState({ month12: parytFeeValue })
                break;
            default:
                break;
        }

    }
    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) this.fetchData(values);
        });
    }
    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions } = this.state;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                let { month1, month2, month3, month4, month5, month6, month7, month8, month9, month10, month11, month12 } = values;
                let months = [month1, month2, month3, month4, month5, month6, month7, month8, month9, month10, month11, month12];
                months = months.map(v => !!v && v.length > 0 ? 1 : 0);
                [month1, month2, month3, month4, month5, month6, month7, month8, month9, month10, month11, month12] = months;
                values.communistInfoIdTreeShows = values.communistInfoIds.join(",");
                values.communistInfoNames = values.communistInfoNames.join(",");
                delete values.communistInfoIds;
                values.isCompleted = months.every(v => v === 1) ? 1 : 0;
                values.number = months.filter(v => v === 1).length;
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
                http.request({
                    method: 'post',
                    url: urls.app.parytFeeManagement.addEntityRecord,
                    data: { ...values, month1, month2, month3, month4, month5, month6, month7, month8, month9, month10, month11, month12 }
                }).then(res => {
                    if (res.success === true) {
                        this.setState({ modalOptions: { ...modalOptions, modalsLoading: false, visible: false } }, () => {
                            message.success("缴费成功！");
                            let forms = this.refs.dyForms;
                            this.seachOnSubmit(forms);
                        });
                    } else {
                        message.error(res.message);
                        this.setState({ modalOptions: { ...modalOptions, modalsLoading: false, visible: true } });
                    }
                }).catch(err => {
                    message.error(err.message);
                    this.setState({ modalOptions: { ...modalOptions, modalsLoading: false, visible: true } });
                });
            }
        });
    }
    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }
    editItem = (item) => {
        let params = {
            id: item.id
        }
        this.setState({ loading: true });
        http.request({
            method: 'post',
            url: urls.app.parytFeeManagement.confirm,
            params
        }).then(res => {
            if (res.success === true) {
                //this.fetchData();
                let forms = this.refs.dyForms;
                this.seachOnSubmit(forms);
                this.setState({ loading: false });
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    editOne = (item, create) => {
        let { modalOptions } = this.state;
        pcService.initFormList(this.fieldsList);
        this.fieldsList.map(v => {
            if (v.name.indexOf("month") !== -1) {
                v.opts[0].name = v.opts[0].name.split("：")[0];
            }
            /* if (v.name === "years") {
                v.unavailable = true;
            } */
        })
        let _create = false;
        if (create === false) {
            pcService.bindFormdData(item, this.fieldsList);
        } else {
            _create = true;
            this.fieldsList.map(v => {
                /*  if (v.name === 'communistInfoNames') {
                     v.unavailable = false;
                 } */
                if (v.name === "total") {
                    v.value = "";
                }
            })
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            totalValue: 0,
            create: _create
        });
    }

    getPartyInfo = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: { flag: true, type: 0, injob: "0" },
        }).then(res => {
            if (res.success) {
                if (res.data.length) {
                    let treeData = res.data || [];
                    let listsOrg = pcService.renderOrgAndUserNodes({ data: treeData, type: 1 });
                    let lists = pcService.renderOrgAndUserNodes({ data: treeData, type: 0, renderAll: true });
                    this.fieldsList.map(v => {
                        if (v.name === "communistInfoIds") {
                            v.opts = lists;
                        }
                        if (v.name === "structureId") {
                            v.opts = listsOrg;
                        }
                    })
                    this.seachFieldsList.map(v => {
                        if (v.name === "communistInfoIdsTreeShow") {
                            v.opts = lists;
                        }
                    })
                    this.setState({ loading: false });
                }
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });

    };
    // 所属党组织
    getOrganizationTree = () => {
        let _params = { flag: false };
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                let lists = treeData ? treeData : [];
                this.seachFieldsList.map(v => {
                    if (v.name === "structureId") {
                        v.opts = [{ id: "", name: "全部" }].concat(lists);
                    }
                })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    componentWillMount() {
        //years
        let nowYear = new Date().getFullYear();
        let yearsOpts = [];
        for (let i = -2; i < 4; i++) {
            yearsOpts.push({
                name: nowYear + i,
                id: nowYear + i
            })
        }
        this.fieldsList.map(v => {
            if (v.name === "years") {
                v.opts = yearsOpts;
                v.originValue = yearsOpts[0].id
            }
        })
        pcService.initFormList(this.seachFieldsList);
        //获取党组织
        this.getOrganizationTree();
    }
    componentDidMount() {
        let forms = this.refs.dyForms;
        this.seachOnSubmit(forms);
        //this.fetchData();
        //获取党员信息
        this.getPartyInfo();
    }

    render() {
        const { loading, selectedRowKeys, pagination, lists, modalOptions, modalDetailOperation, fieldsListDetailList } = this.state;
        const { onSelectChange, columns, fetchData, seachFieldsList, seachOnSubmit, editOne, fieldsList, onOk, onCancel, onDetailOk, onDetailCancel, fieldsListDetail } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: "党费" + modalOptions.title };
        const modalDetail = { ...modalDetailOperation, onCancel: onDetailCancel, onOk: onDetailOk, title: modalDetailOperation.title };
        //const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button onClick={editOne} type="primary">
                                    <Icon type="form" /> 党费缴纳
                                    </Button>
                            </div>
                            <div className="sp-forms">
                                <PcForm ref="dyForms" layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset fieldsList={seachFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                // rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                <PcModal ref={"modelForm"} formClassName={"partyFeeRecord"} fieldsList={fieldsList} modal={modal} formItemColumns="1" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                {/* 缴纳明细 */}
                <Modal {...modalDetail} className={"partyFeeBaseChanging"}>
                    <div className={"partyFeeBaseChanging-item"}>
                        {/* 缴纳明细 */}
                        {
                            fieldsListDetailList.map((item, index) => {
                                return (
                                    <div className="baseChanging-list" key={index} >
                                        <div className={"baseChanging-time"}>
                                            <div className={"baseChanging-item"}>  缴纳时间:</div>
                                            {item.payTime ? item.payTime : ""}</div>
                                        <div>
                                            <div className={"baseChanging-item"}>姓名:</div>
                                            {item.name}
                                        </div>
                                        <div>
                                            <div className={"baseChanging-item"}>缴纳类型:</div>
                                            {item.type}
                                        </div>
                                        <div>
                                            <div className={"baseChanging-item"}>缴纳金额:</div>
                                            {item.number}
                                        </div>
                                        <div>
                                            <div className={"baseChanging-item"}>备注:</div>
                                            {item.memo}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal >
            </Spin >
        )
    }
}

export default partyFeeRecord;