import React, { Component } from 'react';
import { Row, Spin, Button, Divider, Icon, Popconfirm, message, Popover } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import http from '../../common/axios/request';
import './MenuManagement.less';

class MenuManagementList extends Component {

    state = {
        loading: false,
        pagination: {
            current: 1,
            pageSize: 100,
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "菜单",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        lists: []
    }

    /* 表格列项 */
    columns = [

        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1,
            width: 60

        }, {
            title: '操作',
            width: 200,
            key: 'action',
            align: 'center',
            render: (text, record, event) => {
                return <span>
                    <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" theme="outlined" /> 修改</a>
                    <Divider type="vertical" />
                    <Popconfirm title="确定要删除此项吗？" onConfirm={this.deleteItem.bind(this, record.id)} okText="确定"
                        cancelText="取消">
                        <a><Icon type="delete" theme="outlined" /> 删除</a>
                    </Popconfirm>
                </span>
            }
        },
        {
            title: '菜单名字',
            dataIndex: 'name',
            key: 'name',
            //width: 100
        }, {
            title: '菜单logo',
            dataIndex: 'logo',
            key: 'logo',
            render: text => <img alt={text} className="table-logo" src={`${urls.file.viewImg}/${text}`} />,
            //width: 150
        }, {
            title: '栏目路径',
            dataIndex: 'urlDict',
            key: 'urlDict',
            render: text => pcService.numberToText(text, pcService.getDataDictionary("MENU_PATH"))
        },
        {
            title: '菜单描述',
            dataIndex: 'description',
            key: 'description',
            render: value => value.length > 20 ? (
                <Popover content={value} placement="topRight"
                    overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                    {value.slice(0, 20) + '...'}
                </Popover>) : value
            // width: 300
        },
        {
            title: '排序',
            dataIndex: 'sortNum',
            key: 'sortNum',
            sorter: (a, b) => a.sortNum - b.sortNum,
            width: 80,
            align: "center"
        }

    ];

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "name",
        editor: "normal",
        value: "",
        originValue: "",
        hideDisplayName: true,
        displayName: "菜单名称"
    }];

    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'name',
        displayName: '菜单名字',
        editor: 'normal',
        value: '',
        originValue: '',
        rules: [
            { message: "请输入菜单名字", required: true },
            { message: "最大长度为10个字符", max: 10 },
            { whitespace: true, message: "不能输入空格" }
        ]
    }, {
        name: 'urlDict',
        displayName: '栏目路径',
        editor: 'select',
        value: '',
        originValue: pcService.getDataDictionary("MENU_PATH")[0] ? pcService.getDataDictionary("MENU_PATH")[0].id : 0,
        opts: pcService.getDataDictionary("MENU_PATH"),
        rules: [{ message: "请选择栏目路径", required: true }]
    }, {
        name: 'sortNum',
        displayName: '排序',
        editor: 'number',
        value: '',
        range: [0, 9999],
        originValue: 0,
    }, {
        name: 'description',
        displayName: '菜单描述',
        editor: 'textarea',
        value: '',
        originValue: '',
        rules: [
            { message: "请输入菜单描述", required: false },
            { message: "最大长度为200个字", max: 200 }
        ]
    }, {
        name: 'logo',
        displayName: '菜单logo',
        editor: 'pictureWall',
        value: '',
        originValue: '',
        fileUpLoadOption: {
            action: urls.file.uploadGetId,
            imageUrl: "",
            loading: false,
            listType: "picture-card",
            showUploadList: false,
            accept: "image/*",
            beforeUpload: (file) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('图片必须小于2MB!');
                }
                return isLt2M;
            },
            onChange: (info, form, _onchange) => {
                let _value = info.fileList.map(v => {
                    return v.response;
                });
                _onchange(_value.join(","));
            }
        }
    }];

    /* 获取列表数据 */
    fetchData = (params = {}) => {
        let { pagination } = this.state;
        let { pageSize, current } = this.state.pagination;
        let _params = Object.assign({}, { pageSize, current, sortField: "sortNum", sortType: "ASC" }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.menuManage.page, _params, null,
                (data) => {
                    let _pagination = pcService.getPagination(data, pagination);
                    this.setState({
                        loading: false,
                        lists: data.rows,
                        pagination: _pagination
                    });
                },
                () => {
                    this.setState({
                        loading: false
                    })
                }
            );
        });
    }

    /* 搜索 */
    seachOnSubmit = (form) => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }

    /* 新增修改横幅 */
    createOne = (item, create = true) => {
        let { modalOptions } = this.state;
        let that = this;
        if (!!create) {
            this.fieldsList.map(v => {
                if (v.editor === "file-upload") {
                    v.fileUpLoadOption.imageUrl = "";
                    v.fileUpLoadOption.loading = false;
                }
                return;
            })
        }
        let _create = false;
        if (create === false) {
            pcService.bindFormdData(item, that.fieldsList);
        } else {
            _create = true;
            pcService.initFormList(that.fieldsList);
        }
        that.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = "", method = "post", msg = "";
                if (create) {
                    url = urls.app.menuManage.addEntity;
                    msg = "新增";
                } else {
                    url = urls.app.menuManage.updateEntity;
                    msg = "修改";
                }
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                });
                http.request({
                    method,
                    url,
                    data: values
                }).then(res => {
                    if (res.success) {
                        message.success(msg + "操作成功!");
                    }
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    }, this.fetchData);
                }).catch(err => {
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    });
                })
            }
        });
    }

    /* 删除 */
    deleteItem = (ids) => {
        let that = this;
        this.setState({ loading: true });
        pcService.deleteItem(urls.app.menuManage.deleteLogic, {}, ids, (data) => {
            message.success("删除成功!");
            setTimeout(function () {
                that.componentDidMount();
            }, 500);
        }, (err) => {
            this.setState({
                loading: false
            });
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 获取banner位置 */
    getBannerPosiitons = (resolve = null) => {
        let bannerLocations = [];
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.app.bannerLocation.page
            }).then(res => {
                if (res.success) {
                    bannerLocations = res.data.rows || [];
                    this.fieldsList.map(v => {
                        if (v.name === "bannerLocationId") {
                            v.opts = bannerLocations;
                            v.originValue = bannerLocations.length > 0 ? bannerLocations[0].id : "";
                            resolve();
                            return;
                        }
                    });
                }
                this.setState({ loading: false, hasGetPositions: true });
            }).catch(err => {
                this.setState({ loading: false });
            });
        })

    }

    componentDidMount() {
        let form = this.refs.search.getForm();
        this.seachOnSubmit(form);
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
    }

    render() {
        const { loading, pagination, lists, modalOptions, create } = this.state;
        const { columns, fetchData, searchFieldsList, seachOnSubmit, createOne, fieldsList, onOk, onCancel } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        return (
            <div className="menusli-magage">
                <Spin spinning={loading}>
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                            </div>
                            <div className="sp-forms">
                                <PcForm ref="search" layout="inline" submitText="搜索" onSubmit={seachOnSubmit} fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                    <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                </Spin >
            </div>
        )
    }
}

export default MenuManagementList;
