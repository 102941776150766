import React, { Component } from 'react';
import { Tabs } from 'antd';
import PartyFeeManagement from './PartyFeeManagement';
import PartyFeeRecord from './PartyFeeRecord';
import './partyFeeManagement.css';

const { TabPane } = Tabs;

class PartyFeeManage extends Component {

    render() {
        /* 导航菜单 */
        const TabsMenus = [{
            tab: "缴纳规则",
            component: PartyFeeManagement
        },
        {
            tab: "缴纳记录",
            component: PartyFeeRecord
        }];
        return (
            <div className="democratic-review">
                <Tabs defaultActiveKey="0">
                    {TabsMenus.map((v, n) => (
                        <TabPane tab={v.tab} key={n}>
                            <v.component />
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        )
    }
}

export default PartyFeeManage;
