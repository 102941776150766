import React from 'react';
import { Row, Col,Card,Tree,Button,Spin,message } from 'antd';
// import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import { get,post } from './../../../common/axios';
import appConfig from './../../../configs/app.config';
import apiConfig from './../../../configs/api.config';
import {isNotEmpty/*,convertListToTreeJson*/} from '../../../common/utils';
import pcService from './../../../other/pc.service';
import constantConfig from './../../../configs/constant.config';

const { TreeNode } = Tree;

class SetSystemTenants extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading:false,
            systemSet:{},
            treeData:[],
            checkedKeys:[]
       };
    }

    componentWillMount()
    {
        if (this.props.location.query.settingId) {
            this.init(this.props.location.query.settingId);
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps.systemSet&&nextProps.systemSet.settingId!==this.props.systemSet.settingId)
        {
            this.setState({
                systemSet:nextProps.systemSet
            },()=>{
                this.initSystemSetTenants();
            })
        }
    }

    init=(settingId)=> {
        this.initSettingData(settingId);
     }
     initSettingData =(settingId) =>{
         get({
             url: apiConfig.rui.setting.findById,
             params: {
                 settingId: settingId
             }
         }).then((res) => {
             if (res) {
                 this.setState({
                     loading: false,
                     systemSet: {
                         ...res
                     }
                 },this.initTreeData);
             }
         })
     }
 
    initTreeData=()=>{
        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.tenant.list,
            params:{
                pageIndex:0,
                pageSize:appConfig.maxPageSize
            }
        }).then((res)=>{
             let tenants=[];
             if(res&&res.list&&res.list.length>0)
             {
                tenants=[
                    ...res.list
                ];
             }
             //let treeData=convertListToTreeJson(tenants,'0','subTenant','tenantId','pLevel');
            let treeData=tenants;
             this.setState({
                 loading:false,
                 treeData
             },()=>{
                 this.initSystemSetTenants();
             });
        }).catch((error)=>{
            this.setState({ loading:false});
         });
    }

    initSystemSetTenants=()=>
    {
        this.setState({loading:true});
        get({
            url:apiConfig.rui.setting.findById,
            params:{
                settingId:this.state.systemSet.settingId
            }
        }).then((res)=>{
            let checkedKeys=[];
            if(res&&res.tenant){
                for(let resItem of res.tenant)
                {
                    if(resItem)
                    {
                        checkedKeys.push(resItem.tenantId);
                    }
                }
            }
            this.setState({
                loading:false,
                checkedKeys
            });
        }).catch((error)=>{
            this.setState({loading:false});
        })
    }

    renderTreeNodes = data => data.map((item) => {
        // if (item.subTenant) {
        //   return (
        //     <TreeNode title={item.tenantName} key={item.tenantId} value={item.tenantId} dataRef={item}>
        //       {this.renderTreeNodes(item.subTenant)}
        //     </TreeNode>
        //   );
        // }
        // return <TreeNode title={item.tenantName} key={item.tenantId} value={item.tenantId} dataRef={item} />;
        if (item) {
            return <TreeNode title={item.tenantName} key={item.tenantId} value={item.tenantId} dataRef={item} />;
        }
    });


    handleTreeCheck= (checkedKeys, info) => {
         this.setState({
            checkedKeys:checkedKeys
         });
    }

    handelCancel=()=>{
        this.goBack();
    }

    handelSave=()=>{
        
        let tenantIds='';
        for(let checkKey of this.state.checkedKeys)
        {
            tenantIds+=checkKey+','
        }

        if(isNotEmpty(tenantIds))
        {
            tenantIds=tenantIds.substr(0,tenantIds.length-1);
        }        
        this.setState({loading:true});
        post({
            url:apiConfig.rui.setting.resetTenants,
            data:{
                settingId:this.state.systemSet.settingId,
                tenantIds
            }
        }).then((res)=>{
            this.setState({
                loading:false
            },()=>{
                message.success(constantConfig.SAVE_SUCCESS);
                this.goBack();
            });
        }).catch((error)=>{
            message.error(constantConfig.SAVE_FAIL);
            this.setState({loading:false});
        })
    }


    goBack=()=>{
        let panelId = pcService.getIdByPath("/app/setSystemTenants");
        pcService.removePanel(panelId,true,'/app/systemSetList');
        //this.props.removeTabPane(this.props.paneKey);
    }

    render() {
        let { loading, treeData,checkedKeys,systemSet}=this.state;
      return (
          <Spin spinning={loading}>
                <Card bordered={false}>
                    <div className="operationTitle" style={{justifyContent:'center'}}>
                            <h1>
                            {`设置【${systemSet.sysName}】的租户`}
                            </h1>
                    </div>
                    <Row gutter={24}>
                        <Col span={6} offset={4}>
                        {/* <Scrollbars style={{ width: 800, height: 400}}> */}
                                <Tree 
                                        checkable
                                        checkedKeys={checkedKeys}
                                        onCheck={this.handleTreeCheck} 
                                >
                                    {this.renderTreeNodes(treeData)}
                                </Tree>
                        {/* </Scrollbars> */}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                            <Col span={24} className="operationArea">
                                <Button onClick={this.handelCancel}>取消</Button>
                                <Button type="primary" loading={loading} onClick={this.handelSave}>保存</Button>
                            </Col>
                    </Row>
                </Card>
            </Spin>
         )
      }
}


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(SetSystemTenants);