export function insertBefore(element, before) {
    let parent = before.parentNode;
    parent.insertBefore(element, before);
}

export function extend(defaults, options) {
    let extended = {};
    let prop;
    for (prop in defaults) {
        if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
            extended[prop] = defaults[prop];
        }
    }
    for (prop in options) {
        if (Object.prototype.hasOwnProperty.call(options, prop)) {
            extended[prop] = options[prop];
        }
    }
    return extended;
};

export function hasClass(element, classname) {
    let className = " " + classname + " ";
    if ((" " + element.className + " ").replace(/[\n\t]/g, " ").indexOf(" " + classname + " ") > -1) {
        return true;
    }
    return false;
}

export function removeClass(node, className) {
    node.className = node.className.replace(
        new RegExp('(^|\\s+)' + className + '(\\s+|$)', 'g'),
        '$1'
    ).replace(/ +(?= )/g, '').trim();
}

export function addClass(element, className) {
    if (!hasClass(element, className)) {
        element.className += ' ' + className;
        element.className = element.className.replace(/ +(?= )/g, '').trim()
    }
}


