import React, { Component } from 'react';
import G2 from '@antv/g2';
import './organizationG2.css';

export class OrganizationG2 extends Component {

    state = {
        ageData: [],
        sexData: [],
        baseData: [],
    }
    renderCharts1 = (id, propData) => {
        let total = '', data = [];
        if (propData) {
            total = propData.memberNumber + propData.activistNumber + propData.probationaryNumber
                + propData.applicationNumber + propData.progressNumber;
            total = total ? total + '人' : '';
            data = [{
                type: '党员',
                value: propData.memberNumber
            }, {
                type: '积极分子',
                value: propData.activistNumber
            }, {
                type: '预备党员',
                value: propData.probationaryNumber
            }, {
                type: '入党申请',
                value: propData.applicationNumber
            }, {
                type: '发展对象',
                value: propData.progressNumber
            }];
            data = this.checkNotList(data);
        } else {
            total = '';
            data = [];
        }

        document.getElementById(id).innerHTML = data.length === 0 ? "暂无统计数据！" : "";
        let chart = new G2.Chart({
            container: id,
            forceFit: true,
            height: 352
        });
        chart.source(data);
        chart.coord('theta', {
            innerRadius: 0.75
        });
        chart.tooltip({
            showTitle: false
        });
        chart.intervalStack().position('value').color('type').label('percent', {
            formatter: (val, item) => item.point.type + ' : ' + item.point.value
        }).shape('sliceShape');

        // 辅助文本
        chart.guide().html({
            position: ['50%', '50%'],
            html: '<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;"><span style="color:#8c8c8c;font-size:20px">' + total + '</span></div>',
            alignX: 'middle',
            alignY: 'middle'
        });
        chart.render();

    }
    checkNotList = (data) => {
        let _data = [];
        data.forEach(element => {
            if (element.value && element.value !== 0) {
                _data.push({
                    type: element.type,
                    value: element.value
                })
            }
        });
        return _data;
    }
    isYearNull = (data) => {
        let flag = true;
        data.map(v => {
            if (v.人数 !== 0 && v.人数) {
                flag = false;
            }
        })
        return flag;
    }
    isAgeNull = (data) => {
        let _data = [];
        data.map(v => {
            if (v.numbers !== 0 && v.numbers) {
                _data.push({
                    item: v.scaleName,
                    count: v.numbers,
                    percent: v.scales,
                })
            }
        })
        return _data;
    }
    renderCharts2 = (id, propData) => {
        let data = propData ? propData : [];
        data = data.sort((a, b) => a.scaleName > b.scaleName);
        let _data = data.map((v, n) => ({
            人数: v.numbers,
            scaleName: v.scaleName,
        }));
        _data = this.isYearNull(_data) ? [] : _data;
        document.getElementById(id).innerHTML = _data.length === 0 ? "暂无统计数据！" : "";
        let chart = new G2.Chart({
            container: id,
            forceFit: true,
            height: 352
        });
        chart.source(_data);
        chart.interval().position('scaleName*人数');
        chart.render();
    }
    renderCharts3 = (id, propData) => {
        let data = propData ? propData : [];
        data.map(v => {
            v.item = v.scaleName;
            v.count = v.numbers;
            v.percent = v.scales;
        });
        data = this.isAgeNull(data);
        document.getElementById(id).innerHTML = data.length === 0 ? "暂无统计数据！" : "";
        let chart = new G2.Chart({
            container: id,
            forceFit: true,
            height: 352
        });
        chart.source(data, {
            percent: {
                formatter: function formatter(val) {
                    val = val + '%';
                    return val;
                }
            }
        });
        chart.coord('theta');
        chart.tooltip({
            showTitle: false
        });
        chart.intervalStack().position('percent').color('item').label('percent', {
            offset: -40,
            textStyle: {
                textAlign: 'center',
                shadowBlur: 2,
                shadowColor: 'rgba(0, 0, 0, .45)'
            }
        }).tooltip('item*count', function (item, count) {
            // percent = percent + '%';
            return {
                name: item,
                value: count
            };
        }).style({
            lineWidth: 1,
            stroke: '#fff'
        });
        chart.render();
    }
    componentWillReceiveProps(nextProps) {
        const { baseData, ageData, sexData } = this.state;
        /* 阻止重复渲染 */
        if (nextProps.baseData === baseData && nextProps.ageData === ageData && nextProps.sexData === sexData ) {
            return;
        }
        this.renderCharts1("mountNodeBase", nextProps.baseData);
        this.renderCharts2("mountNodeAge", nextProps.ageData);
        this.renderCharts3("mountNodeSex", nextProps.sexData);
        this.setState({ ...nextProps })
    }
    componentDidMount() { 
    }

    render() { 
        return (
            <div className="orgG2">
                <div className="home-statistics">
                    <div className="item left">
                        <div className="inner"> 
                        <h3>
                            {
                                this.props && this.props.selectPartyType === "PARTY_CLASS" ? "党小组人员基本情况" : "党组织人员基本情况"
                            }
                        </h3>
                            <div className="inner-content-item">
                                <div id="mountNodeBase" className={"inner-words"} />
                            </div>
                        </div>
                    </div>
                    <div className="item middle">
                        <div className="inner">
                            <h3>党员年龄构成</h3>
                            <div className="inner-content-item">
                                <div id="mountNodeAge" className={"inner-words"} />
                            </div>
                        </div>
                    </div>
                    <div className="item right">
                        <div className="inner">
                            <h3>党员性别</h3>
                            <div className="inner-content-item">
                                <div id="mountNodeSex" className={"inner-words"} />
                            </div>
                        </div>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        )
    }
}

export default OrganizationG2;
