import React, { Component } from 'react';
import { Form, Button, Icon } from 'antd';
import FormsItem from './../../common/components/form/FormsItem';

const FormRecordsUpdate = Form.create()(
    class extends Component {
        render() {
            const { form, fieldsList = [], formStyle = {}, onSubmit = null } = this.props;
            const { getFieldDecorator } = form;
            const FormItem = Form.Item;
            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 },
                },
            }
            const _onSubmit = ($event) => {
                $event.preventDefault();
                onSubmit(form);
            }
            return (
                <Form onSubmit={_onSubmit.bind(this, this.props.form)}>
                    {
                        fieldsList.map((item, index) => {
                            let FormItemStyle = item.editor === "hidden" ? { display: "none" } : null;
                            return (
                                <FormItem style={FormItemStyle} {...formItemLayout} label={item.displayName} key={index}>
                                    {
                                        getFieldDecorator(item.name,
                                            {
                                                initialValue: item.value,
                                                rules: item.rules,
                                                validateFirst: true
                                            })(<FormsItem item={item} form={form} />)
                                    }
                                </FormItem>
                            )
                        })
                    }
                </Form>
            )
        }
    }
)

export default FormRecordsUpdate;

