import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import { Button, Divider, Icon, message, Row, Spin, Popconfirm } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcService from './../../other/pc.service';
import { connect } from 'react-redux';
import urls from './../../configs/api.config';
import './AssistPlanList.css';
import http from "../../common/axios/request";
import ButtonGroup from "antd/es/button/button-group";


/**
 * 困难帮扶活动计划
 */
export class AssistPlanList extends Component {

    state = {
        loading: false,
        visible: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        dataList: [],
        displayTip: 'none',
    }

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        }, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.editItem.bind(this, record)}><Icon type="edit" /> 编辑</a>
                        <Divider type="vertical" />
                        <a onClick={this.detailItem.bind(this, record)}><Icon type="search" /> 详情</a>
                    </span>
                )
            }
        }, {
            title: '帮扶主题',
            dataIndex: 'assistTheme',
            key: 'assistTheme'
        }, {
            title: '帮扶组织',
            dataIndex: 'assistOrgName'
        }, {
            title: '帮扶对象',
            dataIndex: 'difficultList',
            render: (text, record, index) => {
                let diffList = "";
                text.map(v => {
                    diffList += v.name + ",";
                })
                return diffList;
            }
        }, {
            title: '帮扶类型',
            dataIndex: 'assistType',
            render: (text, record, index) => PcService.numberToText(text, PcService.getDataDictionary("ASSIST_RECORD_TYPE"))
        }, {
            title: '帮扶时间',
            dataIndex: 'assistTime',
            render: (text, record, index) => text ? text.substring(0, 10) : ""
        }
    ];

    seachFieldsList = [
        {
            name: "assistTheme",
            editor: "normal",
            value: "",
            displayName: "帮扶主题",
            originValue: ""
        }, {
            name: "assistTime",
            editor: "datePicker",
            value: "",
            originValue: null,
            displayName: "帮扶时间",
            timeOptions: {
                format: "YYYY-MM-DD",
                showTime: false
            }
        }];


    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };


    /* 获取列表数据 */
    fetchData = (params) => {
        let that = this;
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.assistRecord.page, _params, null, (data) => {
                let _pagination = PcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                that.setState({
                    loading: false,
                    dataList: lists,
                    pagination: _pagination
                });
            }, () => {
                this.setState({ loading: false })
            });
        });
    }

    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                values.assistTime = values.assistTime ? PcService.dateFormat(new Date(values.assistTime), "yyyy-MM-dd") : null;
                this.fetchData(values);
            }
        });
    }

    newPage = () => {
        PcService.relativePath("/app/assistPlanAdd");
    }
    editItem = (item) => {
        let id = item.id;
        PcService.relativePath(`/app/assistPlanAdd?recordId=${id}`);
    }
    detailItem = (item) => {
        let id = item.id;
        PcService.relativePath(`/app/assistPlanDetail?recordId=${id}`);
    }
    /* 删除 */
    deleteItem = (item = [], form, $event) => {
        let ids;
        if (form === 'button') {
            ids = item.join(",");
        }
        let that = this;
        this.setState({ loading: true });
        PcService.deleteItem(urls.app.assistRecord.logic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                this.setState({ loading: false });
            }, err => {
                this.setState({ loading: false });
            }
        );
    }
    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentDidMount() {
        this.fetchData();
        PcService.initFormList(this.seachFieldsList);
    }

    render() {

        const { loading, selectedRowKeys, pagination, dataList, visible } = this.state;
        const { handleVisibleChange, cancel, onSelectChange, columns, fetchData, seachFieldsList, seachOnSubmit, newPage, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const allowDel = selectedRowKeys.length > 0;

        return (
            <Spin spinning={loading}>
                <div className="party-activ-page" >
                    <Row>
                        <div className="party-activ-bars">
                            <div className="party-activ-btns">
                                <Button onClick={newPage} icon="file-add" style={{ marginRight: '8px' }} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys, 'button')}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                </Popconfirm>
                                {/* <Button disabled={!allowDel} icon="delete" onClick={deleteItem.bind(this, selectedRowKeys, 'button')}>删除</Button> */}
                            </div>
                            <div className="party-activ-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset
                                    fieldsList={seachFieldsList} />
                            </div>
                        </div>
                        <div className="party-activ-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={dataList}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
            </Spin>
        )
    }
}

export default connect()(AssistPlanList);
