import React, { Component, Fragment } from 'react';
import { Card, Popconfirm, message } from 'antd';
import { StandardTable } from '../../../sharedCommponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane, removeTabPane } from '../../../action/menuTab.action';
import { get, post } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SearchForm from './SearchForm';
import { ButtonAuth, ALinkAuth } from '../../../sharedCommponents/AuthWidget';
import { getCurrentUser } from "../../../configs/user.service";

class OnlineList extends Component {

    state = {
        loading: false,
        selectedRows: [],
        data:
        {
            list: [],
            pagination: {
                pageIndex: 0,
                pageSize: 10,
                total: 0
            }
        },
        searchInitValue: {
            keyword: ''
        }
    }

    columns = [
        {
            title: '操作',
            width: 120,
            align: 'center',
            render: (text, record) => (
                <Fragment>
                    {
                        !record.disabled &&
                        <Fragment>
                            <Popconfirm title="您确定要注销吗?" onConfirm={() => this.handleLogout(record)} okText="是" cancelText="否">
                                <ALinkAuth
                                    // href="javascript:;"
                                    authCode={'E229D8D6F5D04BF7A55136D3F74F5C12'}
                                >
                                    注销
                                </ALinkAuth>
                            </Popconfirm>
                        </Fragment>
                    }

                </Fragment>
            ),
        },
        {
            title: '名称',
            dataIndex: 'name'
        },
        {
            title: '登录名',
            dataIndex: 'userName'
        },
        {
            title: '编号',
            dataIndex: 'no'
        },
        {
            title: '手机',
            dataIndex: 'phone'
        },
        {
            title: '邮箱',
            dataIndex: 'email'
        },
        {
            title: '登录IP',
            dataIndex: 'ip'
        },
        {
            title: '最近登录',
            dataIndex: 'lastLogin'
        }
    ]

    componentWillMount() {
        this.fetch({});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.reloadState && nextProps.reloadState !== this.props.reloadState) {
            const params = {
                pagination: {
                    pageIndex: 0
                }
            };
            this.fetch(params);
        }
    }

    fetch = (params = {}) => {
        this.setState({ loading: true });
        let { pagination } = this.state.data;
        let pageIndex = pagination.pageIndex;
        let pageSize = pagination.pageSize;
        let keyword = this.state.searchInitValue.keyword;
        if (params) {
            if (params.pagination) {
                pageIndex = params.pagination.pageIndex || 0;
                pageSize = params.pagination.pageSize || pageSize;
            }
            if (!params.keyword) {
                keyword = '';
            }
            else {
                keyword = params.keyword
            }
        }
        let queryParams = {
            pageIndex,
            pageSize,
            keyword
        };
        get({
            url: apiConfig.rui.basic.online.list,
            params: queryParams
        }).then((res) => {
            if (res) {
                const currentUser = getCurrentUser();
                res.list.map((item, index) => {
                    if (item.token === currentUser.login_user.token) {
                        item.disabled = true;
                    }
                    else {
                        item.disabled = false;
                    }
                });
                this.setState({
                    loading: false,
                    data: {
                        list: res.list,
                        pagination: {
                            pageIndex: queryParams.pageIndex,
                            pageSize: queryParams.pageSize,
                            total: res.total
                        }
                    },
                    searchInitValue: {
                        keyword: queryParams.keyword
                    }
                });
            }
            else {
                this.setState({ loading: false });
            }
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    handleSelectRows = rows => {
        this.setState({
            selectedRows: rows,
        });
    };

    handleStandardTableChange = pagination => {
        const params = {
            pagination: {
                pageIndex: pagination.current - 1,
                pageSize: pagination.pageSize
            }
        };
        this.fetch(params);
    };

    handleSearch = (seacheValues) => {
        let params;
        if (seacheValues) {
            params = {
                pagination: {
                    pageIndex: 0
                },
                keyword: seacheValues.keyword
            };
        }
        else {
            params = {
                pagination: {
                    pageIndex: 0
                },
                keyword: ''
            };
        }
        this.fetch(params);
    }

    handleLogout = (online) => {
        this.setState({ loading: true });
        post({
            url: apiConfig.rui.basic.online.logout,
            params: {
                tokens: online.token
            }
        }).then((res) => {
            const params = {
                pagination: {
                    pageIndex: 0
                }
            };
            this.fetch(params);
        })
    }

    handleLogoutAll = () => {
        let { selectedRows } = this.state;
        let tokens = '';
        if (selectedRows.length === 0) {
            message.info('请选择要注销的用户');
            return;
        }
        for (let selectedRow of selectedRows) {
            tokens += selectedRow.token + ',';
        }
        if (tokens) {
            tokens = tokens.substr(tokens, tokens.length - 1);
        }
        this.setState({ loading: true });
        post({
            url: apiConfig.rui.basic.online.logout,
            params: {
                tokens
            }
        }).then((res) => {
            this.setState({
                selectedRows: []
            }, () => {
                const params = {
                    pagination: {
                        pageIndex: 0
                    }
                };
                this.fetch(params);
            });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { selectedRows, loading, data, searchInitValue } = this.state;
        return (
            <div className="mainDiv">
                <Card bordered={false}>
                    <div className='tableSearchForm'>
                        <SearchForm
                            handleSearch={this.handleSearch}
                            searchInitValue={searchInitValue}
                        />
                    </div>
                    <div className='tableAction'>
                        <Popconfirm title="您确定要注销选中的用户吗?" onConfirm={() => this.handleLogoutAll()} okText="是" cancelText="否">
                            <ButtonAuth
                                icon="logout"
                                type="primary"
                                dataaction="create"
                                loading={loading}
                                authCode={'71AED0FE01EE48218B3991EFC117DC3C'}
                            >
                                批量注销
                                        </ButtonAuth>
                        </Popconfirm>
                    </div>
                    <StandardTable
                        selectedRows={selectedRows}
                        disableSelect={false}
                        loading={loading}
                        rowKey="token"
                        data={data}
                        columns={this.columns}
                        onSelectRow={this.handleSelectRows}
                        onChange={this.handleStandardTableChange}
                    />
                </Card>
            </div>
        )
    }
}


const mapStateToProps = state => {
    const { menuTab } = state;
    return {
        selectedMenuKey: menuTab.selectedMenuKey
    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(OnlineList);