import React, { Component } from 'react';
import { TreeSelect } from 'antd';
import './nodeSelect.less';


export class NodeSelect extends Component {

    render() {
        const { value = "", onChange = null, item = {}, form = {} } = this.props;
        const { opts = [], unavailable = false, nodeOptions = {}, displayName = "" } = item;
        const nodeOnChange = (_item, _values, _titles, $event) => {
            onChange(_values);
            _item.onChange && _item.onChange(_item, form, _values, _titles, opts, $event);
        }
        const renderTreeData = (opts = [], isTreeData) => {
            if (isTreeData) return opts.map(v => ({
                ...v,
                title: v.name,
                value: v.id,
                key: v.id,
                children: v.children ? renderTreeData(v.children, isTreeData) : []
            }));
            return opts.map(v => ({
                ...v,
                title: v.name,
                value: v.id,
                key: v.id,
                communistInfoList: v.communistInfoList && v.communistInfoList.length > 0 ? v.communistInfoList.map(v => ({
                    ...v,
                    title: v.name,
                    value: v.id,
                    key: v.id
                })) : [],
                children: renderTreeData(v.children),
            })
            )
        };
        return (
            <TreeSelect getPopupContainer={triggerNode => triggerNode.parentElement} disabled={unavailable} value={value} treeData={renderTreeData(opts, nodeOptions.isTreeData)}
                onChange={nodeOnChange.bind(this, item)} showSearch allowClear
                placeholder={'请选择' + displayName} treeNodeFilterProp="title" {...nodeOptions} >
            </TreeSelect>
        )
    }
}

export default NodeSelect;
