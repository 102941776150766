import React, { Component } from 'react';
import { Button, message, Icon, Divider, Modal, Spin } from 'antd';
import { hashHistory } from 'react-router';
import TimeLine from '../../common/components/timeLine/TimeLine';
import Forms from '../../common/components/form/Forms';
import urls from './../../configs/api.config';
import http from './../../common/axios/request';
import { convertListToTreeJson } from "../../common/utils";
import PcForm from '../../common/components/form/Forms';
import pcService from '../../other/pc.service';
import PcTable from './../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import { getLocal } from "../../common/utils";
import moment from 'moment';
import "./partyIntegralSettlement.less";

export class PartyIntegralSettlement extends Component {
    state = {
        numberStamp: 1,
        timeList: ["党员积分数据检查", "修改党员积分", "计算党员年度积分", "归档党员年度积分"],
        lists: [],
        selectedRowKeys: [],
        exceptionList: -1,
        loading: false,
        isClose: false,
        tipInfo: "",
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        modalOptions: {
            title: "积分信息编辑",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
    }

    /**
     * 显示列
     * @type {Array}
     */
    columns = [{
        title: '序号 ',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record, index) => {
            return <span>
                <a onClick={this.editItem.bind(this, record)} ><Icon type="edit" theme="outlined" /> 编辑</a>
            </span>
        }
    }, {
        title: '党员姓名',
        dataIndex: 'communistName'
    }, {
        title: '人员类型',
        dataIndex: 'communistType',
    }, {
        title: '开始月份',
        dataIndex: 'startMonth',
        render: (text, record, index) => text ? pcService.dateFormat(text, "yyyy年MM月dd日") : ""
    }, {
        title: '停止月份',
        dataIndex: 'stopMonth',
        render: (text, record, index) => text ? pcService.dateFormat(text, "yyyy年MM月dd日") : ""
    }, {
        title: '动态积分',
        dataIndex: 'dymanicPoint',
    }, {
        title: '动态积分异常',
        dataIndex: 'dymanicPointStatus',
        render: (text, record, index) => text ? <span >正常</span> : <span style={{ color: "red" }} > 异常 </span>
    }, {
        title: '民主评议积分',
        dataIndex: 'commentPoint',
    }, {
        title: '民主评议积分异常',
        dataIndex: 'commentPointStatus',
        render: (text, record, index) => text ? <span >正常</span> : <span style={{ color: "red" }} > 异常 </span>
    }, {
        title: '业绩考核积分',
        dataIndex: 'performPoint',
    }, {
        title: '业绩考核积分异常',
        dataIndex: 'performPointStatus',
        render: (text, record, index) => text ? <span >正常</span> : <span style={{ color: "red" }} > 异常 </span>
    }, {
        title: '积分',
        dataIndex: 'personalPoint',
    }, {
        title: '状态',
        dataIndex: 'pointStatus',
        width: 100,
        render: (text, record, index) => text ? <span >正常</span> : <span style={{ color: "red" }} > 异常 </span>
    }];

    /* 数据筛选字段 */
    searchFieldsList = [{
        name: "communistType",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "人员类型",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("POINT_MEMBER_TYPE"))
    }, {
        name: "communistName",
        editor: "normal",
        value: "",
        displayName: "党员姓名",
        originValue: ""
    }];

    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "id",
            editor: "hidden",
            displayName: "id",
            value: "",
        }, {
            name: "orgId",
            editor: "hidden",
            displayName: "党员id",
            value: "",
        }, {
            name: "communistName",
            editor: "normal",
            value: "",
            displayName: "姓名",
            unavailable: true,
            rules: [
                {
                    required: true,
                    message: "请输入姓名"
                },
            ],
        }, {
            name: "orgName",
            displayName: "所属党支部",
            editor: "normal",
            value: "",
            originValue: "",
            unavailable: true,
        }, {
            name: "communistType",
            editor: "select",
            value: "",
            displayName: "人员类型",
            unavailable: true,
            opts: pcService.getDataDictionary("POSITION"),
        }, {
            name: "performPoint",
            editor: "normal",
            displayName: "业绩考核积分",
            value: "",
            rules: [
                { required: true, message: "请输入业绩考核积分" },
                { pattern: /^(\d|[1-9]\d|100)(\.\d{1,2})?$/, message: '请输入0到100的数' },
            ],
        }, {
            name: "commentPoint",
            editor: "normal",
            displayName: "民主评议积分",
            value: "",
            rules: [
                { required: true, message: "请输入民主评议积分" },
                { pattern: /^(\d|[1-9]\d|100)(\.\d{1,2})?$/, message: '请输入0到100的数' },
            ],
        }
    ];

    editItem = (item) => {
        let { modalOptions } = this.state;
        pcService.bindFormdData(item, this.fieldsList);
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
        });
    }

    checkData = (isLast) => {
        let that = this;
        this.setState({ loading: true, tipInfo: "积分数据检查中..." })
        setTimeout(function () {
            that.checkFetchData(isLast);
        }, 3000);

    }

    nextStamp = (isLast) => {
        const { numberStamp, exceptionList } = this.state;

        //页面跳转操作
        let newStamp = numberStamp + 1;

        if (isLast === "close") {
            this.setState({
                isClose: true,
                numberStamp: 4,
            })
        } else {
            this.setState({
                numberStamp: newStamp,
            })
        }
        //数据校验
        if (isLast === "next") {
            if (newStamp === 1) {
                this.fetchData();
            }
            if (newStamp === 2) {
                this.fetchData(null, true);
            }
            if (newStamp === 3) {
                if (exceptionList === 0) {
                    this.setState({}, () => {
                        this.fetchData();
                    })
                }
                else {
                    this.setState({ numberStamp: 2 })
                    return message.warning("请处理完异常数据");
                }
            }
            if (newStamp === 4) {
                this.setState({}, () => {
                    this.fetchData();
                })
            }
        }


    }

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.fetchData(values);
            }
        });
    }
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    checkFetchData = (isLast) => {
        let year = this.props.location ? parseInt(this.props.location.query.year) : "";
        pcService.formSubmit(urls.app.pointPartyYearly.memberPointCheck, false, {}, { year }, (data) => {
            this.nextStamp(isLast)
        }, (err) => {
            message.error(err)
            this.setState({ loading: false, tipInfo: "" })
        });
    }

    /* 获取列表数据 */
    fetchData = (params, poitSta) => {
        let { pagination, numberStamp } = this.state;
        //console.log(numberStamp)
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params,
            { yearly: this.props.location ? parseInt(this.props.location.query.year) : "" },
            { pointStatus: (poitSta === true || numberStamp === 2) ? false : null });
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.pointPartyYearly.page, _params, null, (data) => {
                //sconsole.log(data)
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                if (lists.length === 0 && numberStamp === 2) {
                    this.setState({ exceptionList: 0 });
                } else {
                    this.setState({ exceptionList: 1 })
                }
                if (lists.length && lists.length !== 0 && lists[0].personPointSettlement === true) {
                    this.nextStamp("close");
                }
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination,
                });
            });
        });

    }
    handleSave = () => {
        let that = this;
        let url = urls.app.pointPartyYearly.settlePersonPointByYear;
        let year = this.props.location ? parseInt(this.props.location.query.year) : "";
        this.setState({ loading: true });
        Modal.confirm({
            title: '党员积分调整完毕',
            content: '是否结算党员积分并归档？',
            centered: true,
            maskClosable: true,
            onOk() {
                pcService.formSubmit(url, true, null, { year: year }, () => {
                    message.success("党员年度积分归档完成!");
                    that.setState({ loading: false }, that.componentDidMount);
                    let panelId = pcService.getIdByPath("/app/partyIntegralSettlement");
                    pcService.removePanel(panelId);
                    pcService.relativePath(`/app/orgIntegralSettlement?year=${year}`);
                }, (err) => {
                    that.setState({ loading: false });
                });
            },
            width: '660px',
            okText: "积分归档",
            onCancel() {
                that.setState({ loading: false });
            },
        });
    }
    handleCancel = () => {
        pcService.relativePath("/app/orgIntegralRecords");
    }
    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                let updateurl = urls.app.pointPartyYearly.editCommunistPoints;
                this.setState({ loading: true });
                http.request({
                    method: 'post',
                    url: updateurl,
                    data: values
                }).then(res => {
                    if (res.success === true) {
                        this.fetchData();
                        this.setState({ loading: false });
                    } else {
                        message.error(res.message);
                        this.setState({ loading: false });
                    }
                }).catch(err => {
                    message.error(err.message);
                    this.setState({ loading: false });
                });

                that.setState({
                    modalOptions: {
                        ...modalOptions,
                        visible: false
                    }
                })
            }
        });
    }
    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
    }

    componentDidMount() {
        // this.fetchData();
        pcService.initFormList(this.searchFieldsList);
    }

    render() {
        const { loading, numberStamp, timeList, lists, selectedRowKeys, modalOptions, pagination, isClose, exceptionList, tipInfo } = this.state;
        const { nextStamp, checkData, searchFieldsList, searchOnSubmit, columns, fetchData, fieldsList, onSelectChange, handleSave, handleCancel, onOk, onCancel } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 660, title: modalOptions.title };
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <Spin spinning={loading} className="" tip={tipInfo}>
                <div className={"pir-integral-record"}>
                    <TimeLine total={2} progress={numberStamp} timeList={timeList} />
                    <div className="pir-search">
                        <div className="sp-btns">
                        </div>
                        <div className="sp-forms">
                            {
                                numberStamp !== 1 ?
                                    <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset fieldsList={searchFieldsList} />
                                    : null
                            }
                        </div>
                    </div>
                    <div className="pir-list">
                        {
                            numberStamp !== 1 ? (exceptionList === 0 ? <p className={"err"}>无异常数据</p> : <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />) : null
                        }
                    </div>
                    <div className="pir-panels">
                        <div className={"add-btn"}>
                            {/* {
                            numberStamp === 1 ? <Button onClick={handleCancel}>取消</Button> : null
                        } */}
                            {
                                numberStamp === 4 ? <Button onClick={handleSave} disabled={isClose ? true : false} type="primary" >归档</Button> : <Button onClick={numberStamp === 1 ? checkData.bind(this, "next") : nextStamp.bind(this, "next")} type="primary">下一步</Button>
                            }

                        </div>
                    </div>
                    <PcModal fieldsList={fieldsList} modal={modal} formItemColumns="1" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                </div>
            </Spin>
        )
    }
}

export default PartyIntegralSettlement;
