import React, {Component} from 'react';
import {Tabs} from 'antd';
import AssistPlanList from './AssistPlanList';
import HardPartyMembers from './HardPartyMembers';
import './assistRecordReview.css';

const {TabPane} = Tabs;

class AssistRecordReview extends Component {

    state = {loading: true};

    render() {
        /* 导航菜单 */
        const tabsMenus = [
            {
                tab: "困难帮扶活动",
                component: AssistPlanList
            }, {
                tab: "困难党员库",
                component: HardPartyMembers
            }];

        return (
            <div className="assistRecord_review">
                <Tabs defaultActiveKey="0">
                    {
                        tabsMenus.map((v, n) => (
                            <TabPane tab={v.tab} key={n}>
                                <v.component/>
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        )
    }
}

export default AssistRecordReview;
