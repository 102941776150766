import React, { Component } from 'react';
import PcTable from './../../common/components/table/tableComponent';
import { Row, Spin, Button, Icon, Divider, Popconfirm, message } from 'antd';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import http from '../../common/axios/request';
import './integralRuleEdit.less';

const removeTreeAttr = (data = []) => {
    data.map(v => {
        if (!v.children || v.children.length === 0) {
            v.children = undefined;
        } else removeTreeAttr(v.children)
    });
    return data;
}

const addIndex = (data = [], eIndex) => data.map((v, n) => ({
    ...v,
    eIndex: eIndex ? eIndex + "-" + (n + 1) : n + 1,
    children: v.children ? addIndex(v.children, eIndex ? eIndex + "-" + (n + 1) : n + 1) : undefined
}))

class IntegralRuleDetail extends Component {

    state = {
        loading: false,
        pagination: {
            current: 1,
            pageSize: 200,
            total: 0,
            pageSizeOptions: ['200', '500', '1000']
        },
        create: true,
        modalOptions: {
            title: "积分规则类别",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        dModalOptions: {
            title: "积分规则",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        editItem: {},
        dEditItem: {},
        pointConfigType: {},
        lists: [],
        dLists: [],
        onlyOneLists: [],
        allList: []
    }

    objTypes = [
        { id: "", name: "全部" },
        { id: "0", name: "党员" },
        { id: "1", name: "党支部" }
    ]

    dColumns = [{
        title: '序号',
        dataIndex: 'key1',
        render: (text, record, index) => index + 1

    },/*  {
        title: '操作',
        key: 'action',
        align: 'center',
        render: (text, record, event) => (
            <span>
                <a onClick={this.dEditItem.bind(this, record)}>编辑</a>
                <Divider type="vertical" />
                <Popconfirm title={`确定要${record.enabled ? "停用" : "启用"}此项吗？`} onConfirm={this.dEnalbedItem.bind(this, record)} okText="确定"
                    cancelText="取消">
                    <a><Icon type={record.enabled ? "unlock" : "lock"} theme="outlined" />{record.enabled ? " 停用" : " 启用"}</a>
                </Popconfirm>
            </span>
        )
    }, */
   /*  {
        title: '分类',
        dataIndex: 'ruleName',
        key: 'ruleName'
    }, */ {
        title: '事件',
        dataIndex: 'eventName',
        key: 'eventName'
    }, {
        title: '分值',
        dataIndex: 'eventPoint',
        key: 'eventPoint'
    }, {
        title: '状态',
        dataIndex: 'enabled',
        key: 'enabled',
        render: text => !!text ? "启用" : "停用"
    }];

    columns = [{
        title: '序号',
        dataIndex: 'eIndex',
        key: "eIndex"

    },/*  {
        title: '操作',
        key: 'action',
        align: 'center',
        render: (text, record, event) => (
            <span>
                <a onClick={this.editItem.bind(this, record)}>编辑</a>
                <Divider type="vertical" />
                <Popconfirm title={`确定要${record.enabled ? "停用" : "启用"}此项吗？`} onConfirm={this.enalbedItem.bind(this, record)} okText="确定"
                    cancelText="取消">
                    <a><Icon type={record.enabled ? "unlock" : "lock"} theme="outlined" />{record.enabled ? " 停用" : " 启用"}</a>
                </Popconfirm>
            </span>
        )
    }, */
    {
        title: '适用对象',
        dataIndex: 'objType',
        key: 'objType',
        render: (text) => pcService.numberToText(text, this.objTypes)
    }, {
        title: '积分类型',
        dataIndex: 'ruleName',
        key: 'ruleName',
        render: (text, record) => <a onClick={this.detailItem.bind(this, record)}>{text}</a>
    }, {
        title: '季度上限',
        dataIndex: 'limitQuarterly',
        key: 'limitQuarterly'
    },
    {
        title: '年度上限',
        dataIndex: 'limitYearly',
        key: 'limitYearly'
    }, {
        title: '状态',
        dataIndex: 'enabled',
        key: 'enabled',
        render: text => !!text ? "启用" : "停用"
    }];

    /* 搜索表单项 */
    seachFieldsList = [{
        name: "objType",
        editor: "select",
        value: "",
        originValue: "",
        opts: this.objTypes,
        displayName: "适用对象类型"
    }, {
        name: "ruleType",
        editor: "select",
        value: "",
        originValue: "",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("POINT")),
        displayName: "积分类型"
    }, {
        name: "onlyOne",
        editor: "checkbox",
        value: "",
        originValue: [],
        opts: [{ id: true, name: "是" }],
        onChange: (values = []) => {
            this.chnageOnlyOne(values)
        },
        displayName: "仅显示一级分类"
    }];

    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: 'limitQuarterly',
        displayName: '季度上限',
        editor: 'number',
        value: '',
        originValue: '0',
        range: [0, 99999999],
        rules: []
    }, {
        name: 'limitYearly',
        displayName: '年度上限',
        editor: 'number',
        value: '',
        originValue: '0',
        range: [0, 99999999],
        rules: [{
            message: "请输入季度上限",
            required: true
        }]
    }];

    /* 新增、修改 表单列项 */
    dFieldsList = [{
        name: 'eventPoint',
        displayName: '分值',
        editor: 'number',
        value: '',
        originValue: '0',
        range: [-999999, 999999],
        rules: [{
            message: "请输入季度上限",
            required: true
        }]
    }];

    /* 切换一级规则 */
    chnageOnlyOne = (values) => {
        let { onlyOneLists, allList } = this.state;
        this.setState({
            lists: values.length > 0 ? onlyOneLists : allList
        })
    }

    /* 编辑 */
    editItem = item => {
        let { modalOptions } = this.state;
        pcService.bindFormdData(item, this.fieldsList);
        this.setState({ editItem: { ...item }, modalOptions: { ...modalOptions, visible: true } });
    }

    /* 编辑 */
    dEditItem = item => {
        let { dModalOptions } = this.state;
        pcService.bindFormdData(item, this.dFieldsList);
        this.setState({ dEditItem: { ...item }, dModalOptions: { ...dModalOptions, visible: true } });
    }
    /* 启用停用 积分规则主数据*/
    enalbedItem = (item) => {
        let url = urls.app.pointConfigType.enabled;
        let params = {
            pointConfigTypeId: item.id,
            enabled: !item.enabled
        };
        this.setState({ loading: true });
        pcService.formSubmit(url, true, null, params, () => {
            message.success((!item.enabled ? "启用" : "停用") + "成功!");
            this.setState({
                loading: false
            }, this.componentDidMount);
        }, () => {
            this.setState({ loading: false });
        });
    }

    /* 启用停用 积分规则主数据*/
    dEnalbedItem = (item) => {
        let url = urls.app.pointConfigTypeEvent.enabled;
        let { pointConfigType } = this.state;
        let params = {
            pointConfigTypeEventId: item.id,
            enabled: !item.enabled
        };
        this.setState({ loading: true });
        pcService.formSubmit(url, true, null, params, () => {
            message.success((!item.enabled ? "启用" : "停用") + "成功!");
            this.setState({
                loading: false
            }, () => {
                this.detailItem(pointConfigType);
            });
        }, () => {
            this.setState({ loading: false });
        });
    }

    /* 详情 */
    detailItem = (item) => {
        let pointConfigTypeId = item.id;
        this.setState({ loading: true, pointConfigType: item });
        http.request({
            method: "get",
            url: urls.app.pointConfigTypeEvent.page,
            params: { pointConfigTypeId }
        }).then(res => {
            if (res.success) {
                let datas = res.data.rows;
                /*  datas.map(v => {
                     v.ruleName = item.ruleName;
                 }); */
                this.setState({
                    dLists: datas,
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }


    /* 选中 onChange事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    /* 获取列表数据 */
    fetchData = (_params = {}) => {
        let { pointConfigId = 0 } = this.props.location.query;
        let { pageSize, current } = this.state.pagination;
        if (!!pointConfigId) {
            let params = Object.assign({}, _params, { pointConfigId, onlyOne: undefined, pageSize, current });
            this.setState({ loading: true });
            pcService.fetchData(urls.app.pointConfigType.page, params, null, data => {
                let _data = removeTreeAttr(data);
                _data = addIndex(_data);
                this.setState({
                    loading: false,
                    lists: _data,
                    onlyOneLists: JSON.parse(JSON.stringify(_data.map(v => ({ ...v, children: undefined })))),
                    allList: _data
                });
            }, () => {
                this.setState({ loading: false })
            });
        }
    }

    /* 返回规则页面 */
    goBack = () => {
        let panelId = pcService.getIdByPath("/app/integralRuleEdit");
        pcService.removePanel(panelId, true, '/app/integralRule');
    }

    /* 搜索提交 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) this.fetchData(values);
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, editItem } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = urls.app.pointConfigType.updateEntity;
                this.setState({
                    modalOptions: { ...modalOptions, modalsLoading: true }
                });
                pcService.formSubmit(url, true, { ...editItem, ...values, children: undefined }, null, () => {
                    message.success("修改积分规则类别成功!");
                    this.setState({
                        modalOptions: {
                            selectedRowKeys: [],
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    }, this.componentDidMount);
                }, (err) => {
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    });
                });
            }
        });
    }

    /* modal确定回调函数 */
    dOnOk = (forms) => {
        let { dModalOptions, dEditItem, pointConfigType } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = urls.app.pointConfigTypeEvent.update;
                this.setState({
                    dModalOptions: { ...dModalOptions, modalsLoading: true }
                });
                pcService.formSubmit(url, true, { ...dEditItem, ...values, children: undefined }, null, () => {
                    message.success("修改积分规则成功!");
                    this.setState({
                        dModalOptions: {
                            selectedRowKeys: [],
                            ...dModalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    }, () => {
                        this.detailItem(pointConfigType)
                    });
                }, () => {
                    this.setState({
                        dModalOptions: {
                            ...dModalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    });
                });
            }
        });
    }

    /* 模态框关闭事件 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 模态框关闭事件 */
    dOnCancel = () => {
        let { dModalOptions } = this.state;
        this.setState({
            dModalOptions: {
                ...dModalOptions,
                visible: false
            }
        });
    }

    componentWillMount() {
        pcService.initFormList(this.seachFieldsList);
    }

    componentDidMount() {
        let form = this.refs.search.getForm();
        this.seachOnSubmit(form);
    }

    render() {
        const { loading, lists, modalOptions, dModalOptions, dLists } = this.state;
        const { columns, fetchData, dFieldsList, goBack, dOnOk, onOk, onCancel, dOnCancel, seachOnSubmit, seachFieldsList, dColumns, fieldsList } = this;
        const modal = { ...modalOptions, onOk, dOnOk, onCancel, dOnCancel, title: "编辑" + modalOptions.title };
        const dModal = { ...dModalOptions, onOk: dOnOk, onCancel: dOnCancel, title: "编辑" + dModalOptions.title };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="ire-container">
                    <div className="ire-item">
                        <h3>积分规则类别</h3>
                        <Row>
                            <div className="sp-bars">
                                <div className="sp-forms">
                                    <PcForm submitText="搜索" ref="search" layout="inline" showReset onSubmit={seachOnSubmit} fieldsList={seachFieldsList} />
                                </div>
                            </div>
                            <div className="sp-content">
                                <PcTable
                                    columns={columns}
                                    dataSource={lists}
                                    fetchData={fetchData}
                                    bordered={false}
                                />
                            </div>
                        </Row>
                    </div>
                    <div className="ire-item">
                        <div className="ire-inner">
                            <div className="irer-btn">
                                <Button onClick={goBack} icon="rollback">返回</Button>
                            </div>
                            <div className="irer-content">
                                <h3>积分规则详细</h3>
                                <div className="">
                                    <PcTable
                                        columns={dColumns}
                                        dataSource={dLists}
                                        //rowSelection={rowSelection}
                                        bordered={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading}
                    formItemLayout={formItemLayout} />
                <PcModal fieldsList={dFieldsList} modal={dModal} modalsLoading={dModal.modalsLoading}
                    formItemLayout={formItemLayout} />
            </Spin>
        )
    }
}

export default IntegralRuleDetail;
