import React from 'react';
import { Spin,Row, Col,Card,Tree,Button,message } from 'antd';
// import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import { get,post } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SetAuthoritiesForRole from './SetAuthoritiesForRole';
import {setJsonArray,isNotEmpty} from '../../../common/utils';
import pcService from './../../../other/pc.service';
import constantConfig from './../../../configs/constant.config';
const { TreeNode } = Tree;

class SetPermissions extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading:false,
            role:{},
            roleId:this.props.location.query.roleId,
            treeData:[],
            menuArray:[],
            checkedMenuKeys:[],
            checkedSumitMenuKeys:[],
            selectMenu:null
       };
    }

    componentWillMount()
    {
        this.init();
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps.role&&nextProps.role.roleId!==this.props.role.roleId)
        {
            this.setState({
                role:nextProps.role
            },()=>{
                this.init();
            })
        }
    }

    init=()=> {
       this.initRoleData();
    }
    initRoleData=()=>{
        get({
            url:apiConfig.rui.basic.role.getRoleById,
            params:{
                roleId:this.props.location.query.roleId,
            }
        }).then((res)=>{
            this.setState({
              role:{...res}                  
            },this.initTreeData)
        }).catch((error)=>{
            
        });
    }

    initTreeData=()=>{
        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.role.getRightsAndAuthorities,
            params:{
                roleId:this.state.roleId
            }
        }).then((res)=>{
             let menus=[];
             let menuArray=[];
             let checkedMenuKeys=[];
             let checkedSumitMenuKeys=[];
             if(res)
             {
                //console.log("res",res);
                menus=res;
                menuArray=setJsonArray(menus);
                let hasMenuArray=menuArray.filter(item=>item.hasMenu);
                for(let menu of hasMenuArray)
                {
                    checkedSumitMenuKeys.push(menu.menuId);
                    let isSubChecked=this.isAllSubChecked(menu);
                    if(isSubChecked)
                    {
                        checkedMenuKeys.push(menu.menuId);
                    }
                }
             }
             //console.log("checkedMenuKeys",checkedMenuKeys);
             this.setState({
                 loading:false,
                 treeData:menus,
                 checkedMenuKeys,
                 checkedSumitMenuKeys,
                 menuArray
             });
        }).catch((error)=>{
            this.setState({ loading:false});
         });
    }

    isAllSubChecked=(menu)=>{
        let isSubChecked=true;
        if(menu.subMenu&&menu.subMenu.length>0)
        {
          
            for(let childMenu of menu.subMenu)
            {
                if(!childMenu.hasMenu)
                {
                    isSubChecked=false;
                    break;
                }
                else
                {
                    isSubChecked=this.isAllSubChecked(childMenu);
                    if(!isSubChecked)
                    {
                        break;
                    }
                }
            }
        }
        return isSubChecked;
    }

    renderTreeNodes = data => data.map((item) => {
        if (item.subMenu) {
          return (
            <TreeNode title={item.menuName} key={item.menuId} value={item.menuId} dataRef={item}>
              {this.renderTreeNodes(item.subMenu)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.menuName} key={item.menuId} value={item.menuId} dataRef={item} />;
    });

    handleTreeSelect = (value,node, extra) => {
        if(!value)
        {
            return;
        }
        else
        {
            let selectMenuId=value[0];
            let selectMenu=this.state.menuArray.find(item=>item.menuId===selectMenuId);
            if(selectMenu&&selectMenu.subMenu&&selectMenu.subMenu.length>0)
            {
                selectMenu=null;
            }

            this.setState({
                selectMenu
            });
        }
    }

    handleTreeCheck= (checkedKeys, info) => {
        let checkedSumitMenuKeys=[...checkedKeys,...info.halfCheckedKeys];
         this.setState({
            checkedMenuKeys:checkedKeys,
            checkedSumitMenuKeys
         });
    }

    handleSetAuthorities=(checkedAuthorities)=>{
        let { selectMenu,menuArray }=this.state;
        let newMenuArray=menuArray.map((menu,index)=>{
            if(menu.menuId===selectMenu.menuId)
            {
                if(menu.authorities)
                {
                    for(let auth of menu.authorities)
                    {
                        if(!checkedAuthorities||checkedAuthorities.length===0)
                        {
                            auth.hasAuthority=false;
                        }
                        else
                        {
                            let authExist=checkedAuthorities.find(item=>item.authorityId===auth.authorityId);
                            if(authExist)
                            {
                                auth.hasAuthority=true;
                            }
                            else
                            {
                                auth.hasAuthority=false;
                            }
                        }
                    }
                }
                return menu;
            }
            else
            {
                return menu;
            }
        })
        this.setState({
            menuArray:newMenuArray
         });
    }

    handelCancel=()=>{
        this.goBack();
    }

    handelSave=()=>{
        let menuIds='';
        let authorityIds='';
        for(let checkMenuKey of this.state.checkedSumitMenuKeys)
        {
            menuIds+=checkMenuKey+',';
            let menu=this.state.menuArray.find(item=>item.menuId===checkMenuKey);
            if(menu&&menu.authorities)
            {
                let authorityArrayByMenu=menu.authorities.filter(item=>item.hasAuthority);
                if(authorityArrayByMenu&&authorityArrayByMenu.length>0)
                {
                    let authorityIdsByMenu='';
                    for(let auth of authorityArrayByMenu)
                    {
                        authorityIdsByMenu+=auth.authorityId+'&';
                    }
                    if(isNotEmpty(authorityIdsByMenu))
                    {
                        authorityIdsByMenu=authorityIdsByMenu.substr(0,authorityIdsByMenu.length-1);
                        authorityIds+=authorityIdsByMenu+',';
                    }
                    else
                    {
                        authorityIds+=',';
                    }
                }
                else
                {
                    authorityIds+=',';
                }
            }
            else
            {
                authorityIds+=',';
            }
        }
        if(isNotEmpty(menuIds))
        {
            menuIds=menuIds.substr(0,menuIds.length-1);
        }
        if(isNotEmpty(authorityIds))
        {
            authorityIds=authorityIds.substr(0,authorityIds.length-1);
        }
        this.setState({loading:true});
        post({
            url:apiConfig.rui.basic.role.setRightsAndAuthorities,
            data:{
                roleId:this.state.role.roleId,
                menuIds,
                authorityIds
            }
        }).then((res)=>{
            message.success(constantConfig.SAVE_SUCCESS);
            this.setState({loading:false});
            this.goBack();
        }).catch((error)=>{
            message.success(constantConfig.SAVE_FAIL);
            this.setState({loading:false});
        });
    }


    goBack=()=>{
        let panelId = pcService.getIdByPath("/app/setPermissions");
        pcService.removePanel(panelId,true,'/app/roleList');
        //this.props.removeTabPane(this.props.paneKey);
    }


    render() {
        let { loading, treeData,checkedMenuKeys,role,selectMenu }=this.state;
      return (
           <Spin spinning={loading}>
            <Card bordered={false}>
                <div className="operationTitle" style={{justifyContent:'center'}}>
                        <h1>
                           {`设置【${role.roleName}】的功能权限`}
                        </h1>
                </div>
                <Row gutter={12}>
                    <Col span={8}>
                       <Card bordered={false} title='菜单权限'>
                            {/* <Scrollbars style={{ width: 390, height: 400 }}> */}
                                    <Tree 
                                            checkable
                                            checkedKeys={checkedMenuKeys}
                                            onSelect={this.handleTreeSelect}
                                            onCheck={this.handleTreeCheck} 
                                    >
                                        {this.renderTreeNodes(treeData)}
                                    </Tree>
                            {/* </Scrollbars> */}
                       </Card>
                    </Col>
                    <Col span={16}>
                        <Card bordered={false} title='功能权限'>
                           <SetAuthoritiesForRole selectMenu={selectMenu} handleSetAuthorities={this.handleSetAuthorities}/>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={12}>
                        <Col span={24} className="operationArea">
                            <Button onClick={this.handelCancel}>取消</Button>
                            <Button type="primary" loading={loading} onClick={this.handelSave}>保存</Button>
                        </Col>
                </Row>
            </Card>
            </Spin>
         )
      }
}


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(SetPermissions);