/**
 * 上传附件组件
 * @uploadUrl:上传接口（必传）
 * @downloadUrl:下载接口（必传）
 * @disabled:是否禁用（默认false)
 */
import React, { Component } from 'react';
import { Button, Upload, message, Tooltip } from 'antd';
import appConfig from './../../../configs/app.config';
import { getLocal } from '../../utils';
//这是一个Modal组件的二次封装，加了一些默认值而已，你可以直接用Modal组件代替
//import ModalWrapper from '@/components/modalWrapper';

//接受的上传文件类型、大小
const allowedFileTypeList = [
  //.xls
  'application/vnd.ms-excel',
  //.xlsx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //.doc
  'application/msword',
  //.docx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'application/pdf',
  //.xml
  'text/xml',
  'application/xml',
  //
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed'
];
//附件限制大小
const allowedFileSize = 20 * 1024 * 1024; //单位：字节
//匹配文件图标所用对象
const matchIconObj = {
  xls: 'file-excel',
  xlsx: 'file-excel',
  doc: 'file-word',
  docx: 'file-word',
  jpg: 'file-image',
  jpeg: 'file-image',
  png: 'file-image',
  pdf: 'file-pdf',
  xml: 'file-text'
};

class UploadAttachments extends Component {

  state = {
    //已上传的文件列表
    fileList: this.props.value || [],
    //visible: false,
    loading: false,
    //动态存储附件预览地址
    previewUrl: ''
  }

  /* getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        fileList: [...(nextProps.value || [])]
      };
    }
    return null;
  } */

  //限制附件上传的大小
  handleBeforeUpload = file => {
    const { fileUpLoadOption } = this.props.item;
    const { maxSize = 0 } = fileUpLoadOption;
    this.setState({ loading: true });
    let totalMaxSize = maxSize > allowedFileSize ? maxSize : allowedFileSize;
    if (file.size > totalMaxSize * 1024 * 1024) {
      message.warning(`文件大小不能超过${totalMaxSize}M！`);
      this.setState({ loading: false, fileList: [] });
      return false;
    }
    this.setState({ loading: false });
    return true;
  };

  //上传后的回调
  handleChange = info => {
    const { onChange, form } = this.props;
    const { fileUpLoadOption } = this.props.item;
    if (info.file.status === 'done') {
      if (info.file.response) {
        if (!!fileUpLoadOption.onChange) {
          //传入url地址
          info.fileList.map(item => {
            item.url = appConfig.pocAddress + 'views/' + item.response;
          })
          fileUpLoadOption.onChange(info.fileList, form, onChange);
        } else {
          onChange([...info.fileList]);
        }
      } else {
        message.error(`${info.file.name}上传失败！`);
        info.fileList.pop();
        onChange([...info.fileList]);
      }
      this.setState({ loading: false });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name}上传失败！`);
      this.setState({ loading: false }, () => {
        info.fileList.pop();
        onChange([...info.fileList]);
      });
    } else if (info.file.status === 'removed') {
      if (!!fileUpLoadOption.onChange) {
        fileUpLoadOption.onChange(info.fileList, form, onChange);
      } else {
        onChange([...info.fileList]);
      }
    } else if (info.file.status === undefined) {
      return;
    };
    this.setState({ fileList: [...info.fileList] });
  };

  render() {
    const { disabled = false, item = {} } = this.props;
    const { fileUpLoadOption = {} } = item;
    let { action = "", accept, maxSize = "20", showUploadList } = fileUpLoadOption;
    showUploadList = showUploadList ? showUploadList : { showRemoveIcon: !disabled }
    const { loading, fileList, previewUrl } = this.state;
    const headers = getLocal("poc_headers");
    //上传组件所需的属性值
    const uploadProp = {
      multiple: true,
      name: 'file',
      action,
      accept: accept ? accept : allowedFileTypeList.join(','),
      beforeUpload: this.handleBeforeUpload,
      onChange: this.handleChange,
      fileList,
      showUploadList,
      headers
    };

    return (
      <div className="ms-uploadAttachments">
        <Upload {...uploadProp} openFileDialogOnClick={!disabled} >
          {disabled ? <span>附件列表:</span> :
            /* 上传文件支持: xls、xlsx、doc、docx、jpg、jpeg、png、pdf、xml、zip等格式 title={`文件大小不超过${maxSize}M`} */
            <Tooltip>
              <Button icon='upload' loading={loading}>选择文件</Button>
            </Tooltip>
          }
          {/* <span style={{ display: disabled ? 'none' : 'block', color: '#f60', marginTop: 8, lineHeight: '20px' }}>
            上传文件仅支持: xls、xlsx、doc、docx、jpg、jpeg、png、pdf、xml格式，文件大小不超过20M
          </span> */}
        </Upload>
      </div>
    );
  }

}

export default UploadAttachments;
