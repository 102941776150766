import React, { Component } from 'react';
import './formText.less';
import pcService from '../../../other/pc.service';
import urls from '../../../configs/api.config';
import { Icon } from 'antd';

class FormText extends Component {

    render() {
        const valueToTitleValue = (item = {}) => {
            switch (item.editor) {
                case "select":
                    item.value = pcService.numberToText(item.value, item.opts);
                    break;
                case "node-select":
                    item.value = pcService.getNodeTitleByValue(item.value, item.opts);
                    break;
                case "radio":
                    item.value = pcService.numberToText(item.value, item.opts);
                    break;
                case "checkbox":
                    item.value = item.value instanceof Array ? item.value.map(v => pcService.numberToText(v, item.opts)).join(",") : "";
                    break;
                default:
                    break;
            }
            return { ...item };
        }
        const { fieldsList = [], formItemLayout = {} } = this.props;
        const _fieldsList = fieldsList.map(v => valueToTitleValue(v))
        const { labelCol = {}, wrapperCol = {} } = formItemLayout;
        return (
            <div className="form-texts">
                <div className="ant-form ant-form-horizontal">
                    {_fieldsList.map((v, n) => v.editor !== "hidden" ? (<div className="ant-row ant-form-item" key={n}>
                        <div className={`ant-col ant-form-item-label ant-col-xs-${labelCol.xs.span} ant-col-sm-${labelCol.sm.span}`}>{v.displayName}：</div>
                        <div className={`ant-col ant-form-item-control-wrapper ant-col-xs-${wrapperCol.xs.span} ant-col-sm-${wrapperCol.sm.span}`}>
                            <div className="ant-form-item-control"> {v.editor !== "attachments" ? v.value : (
                                <div className="ant-upload-list ant-upload-list-text">
                                    {v.value.map((v, n) => (<div className="ant-upload-list-item ant-upload-list-item-done">
                                        <div className="ant-upload-list-item-info">
                                            <span>
                                                <Icon type="paper-clip" />
                                                <a target="_blank" className="ant-upload-list-item-name" title={v.fileName} href={`${urls.file.viewImg}/${v.fileId}`}>{v.fileName}</a>
                                            </span>
                                        </div>
                                    </div>))}
                                </div>
                            )}</div>
                        </div>
                    </div>) : "")}
                </div>
            </div>
        )
    }
}

export default FormText;
