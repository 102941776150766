import React, { Component } from 'react';
import { Button, Divider, Icon, message, Popconfirm, Row, Spin, Modal } from "antd";
import PcTable from "../../common/components/table/tableComponent";
import PcModal from "../../common/components/modal/Modals";
import PcForm from "../../common/components/form/Forms";
import pcService from "../../other/pc.service";
import http from './../../common/axios/request';
import urls from './../../configs/api.config';

export class IntegralDetail extends Component {
    state = {
        hasUpdate: false,
        loading: false,
        detailVisible: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        /* modalOptions: {
            title: "民主评议",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        }, */
        lists: []
    };

    /**
     * 显示列
     * @type {Array}
     */
    columns = [{
        title: '序号 ',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    }, {
        title: '党组织名称',
        dataIndex: 'orgName'
    }, {
        title: '统计节点',
        dataIndex: 'statisticNode',
        render: (text, record, index) => text && text.length !== 0 ? "第" + text + "季度" : text
    }, {
        title: '人月',
        dataIndex: 'manMonth',
    }, {
        title: '在线学习',
        dataIndex: 'learnOnline',
    }, {
        title: '党员考试',
        dataIndex: 'exam',
    }, {
        title: '支部活动',
        dataIndex: 'branchActivity',
    }, {
        title: '组织生活',
        dataIndex: 'orgLife',
    }, {
        title: '缴纳党费',
        dataIndex: 'payment',
    }, {
        title: '党员扣分',
        dataIndex: 'memberPointDeduct',
    }, {
        title: '党员加分',
        dataIndex: 'memberPointAdd',
    }, {
        title: '动态积分',
        dataIndex: 'dynamicPoint',
    }, {
        title: '党支部加分',
        dataIndex: 'pointBranchAdd',
    }];
    /**
     * 搜索框内容
     */
    searchFieldsList = [{
        name: "orgId",
        editor: "node-select",
        value: "",
        originValue: "",
        opts: [],
        displayName: "所属党组织",
    }, {
        name: "yearly",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "统计节点",
        opts: [
            { id: "", name: "全部" },
            { id: 1, name: "第一季度" },
            { id: 2, name: "第二季度" },
            { id: 3, name: "第三季度" },
            { id: 4, name: "第四季度" },
            { id: 5, name: "年度结算" },
        ]
    }];

    /**
    * 查询
    */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    };
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /**
     * 加载数据的方法
     * @param params
     */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params, { yearly: this.props.location ? parseInt(this.props.location.query.yearly) : "" });
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.pointOrgQuarterly.page, _params, null, (data) => {
                //console.log(data)
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    };
    // 所属党组织
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                this.setState({
                    treeList: treeData,
                });
                let lists = treeData ? treeData : [];
                this.searchFieldsList.map(v => {
                    if (v.name === "orgId") {
                        v.opts = [{ id: "", name: "全部" }].concat(lists);
                    }
                })
                this.setState({})
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    componentDidMount() {
        this.fetchData();
        pcService.initFormList(this.searchFieldsList);
        //获取党组织
        this.getOrganizationTree();
    }

    render() {
        const { loading, selectedRowKeys, pagination, lists, modalOptions, create } = this.state;
        const { createOne, searchOnSubmit, searchFieldsList, onSelectChange, columns, fetchData, fieldsList, detailList, onOk, onCancel, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };

        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset
                                    fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
            </Spin>
        )
    }
}

export default IntegralDetail;
