import React from 'react'
import BraftEditor from 'braft-editor'
import PropTypes from 'prop-types'
import 'braft-editor/dist/index.css'
import axois from 'axios';
import urls from './../../../configs/api.config';

export default class BEditor extends React.Component {

    componentWillReceiveProps(nextProps, nextContext) {
        const data = nextProps.value;
        if (data && data !== '' && data !== '<p></p>' && !this.updated) {
            this.editorInstance.setValue(BraftEditor.createEditorState(nextProps.value));
            this.updated = true;
        }
    }

    handleChange = (editorState) => {
        const { onChange } = this.props;
        this.setState({
            htmlContent: editorState.toHTML(),
            updated: true,
            editorState: editorState
        });
        if (onChange) {
            onChange(editorState.toHTML())
        }
    };

    /**
     * 处理上传文件和视频媒体
     * @param param
     */
    handleUpload = (param) => {
        const file = param.file;
        const formData = new FormData();
        formData.append("file", file, file.name);
        axois.request({
            method: "post",
            url: urls.file.uploadGetId,
            data: formData
        }).then((res) => {
            if (res.success) {
                param.success({ url: `${urls.file.viewImg}/${res.data}` })
            } else param.error({ msg: '上传图片失败!' });
        }).catch((err) => {
            //console.log(err);
        });
    };
    //初始化内容
    componentDidMount() {
        const htmlContent = this.props.value;
        this.editorInstance.setValue(BraftEditor.createEditorState(htmlContent));
        this.updated = true;
    }

    render() {

        const { placeholder, readOnly } = this.props;
        const editorProps = {
            readOnly,
            placeholder,
            height: 0,
            lineHeights: [
                '0', '1', '1.2', '1.5', '1.75',
                '2', '2.5', '3', '4',
            ],
            contentFormat: 'html',
            onSave: this.onSave,
            controls: [
                'headings', 'undo', 'redo', 'split', 'text-color', 'bold', 'italic', 'underline', 'strike-through',
                'superscript', 'subscript', 'remove-styles', 'text-align', 'split', 'list_ul',
                'list_ol', 'blockquote', 'code', 'media', 'split', 'link', 'split', 'hr',
            ],
            media: {
                allowPasteImage: true, // 是否允许直接粘贴剪贴板图片（例如QQ截图等）到编辑器
                image: true, // 开启图片插入功能
                video: false, // 关闭视频插入功能
                audio: false, // 关闭音频插入功能
                accepts: {
                    image: 'image/png,image/jpeg,image/jpg'
                },// 指定接受的类型
                validateFn: null, // 指定本地校验函数，说明见下文
                uploadFn: this.handleUpload, // 指定上传函数，说明见下文
                removeConfirmFn: null, // 指定删除前的确认函数，说明见下文
                onRemove: null, // 指定媒体库文件被删除时的回调，参数为被删除的媒体文件列表(数组)
                onChange: null, // 指定媒体库文件列表发生变化时的回调，参数为媒体库文件列表(数组)
                onInsert: null, // 指定从媒体库插入文件到编辑器时的回调，参数为被插入的媒体文件列表(数组)
                externalMedias: {
                    image: true,
                    audio: false,
                    video: false,
                    embed: false
                },
            },
        };

        return <BraftEditor ref={(instance) => { this.editorInstance = instance }} {...editorProps} onChange={this.handleChange} />

    }
}

BEditor.propTypes = {
    content: PropTypes.string,
    height: PropTypes.string,
    handleChange: PropTypes.func,
}

