import React, { Component } from 'react';
import { Row, Col, Spin, Select, Input, Button } from 'antd';
import http from '../../common/axios/request';
import urls from '../../configs/api.config';
import { getLocal, setJsonArray } from '../../common/utils';
import pcService from '../../other/pc.service';
import {
    renderBar,
    renderOrgActivities,
    renderStudyes,
    renderCommPointAttribute,
    rednerPointPartyStatisticQuarterlyDetail
} from './table.service';
import PercentBar from './../../common/components/percentBar/PercentBar';
import './table.less';

const { Option } = Select;

class Tables extends Component {

    state = {
        loading: false,
        noticeLoading: false,
        ppsLoading: false,
        commPointAttributeLoading: false,
        rankDataLoading: false,
        rootStructureId: 0,
        structureId: 0,
        orgList: [
            { id: 0, name: "请选择党组织" }
        ],
        rankData: [],//党员积分排行
        orgActivities: [],//党组织活动积分
        releseNumDistribution: [],//阅读数，发布数
        studyes: [], //学习情况
        studyRadio: [],//学习率
        commPointAttribute: [],//党员动态积分
        examRadio: [],//考试
        pointPartyStatisticQuarterlyDetail: [],//党组织动态积分
        activeCommunistId: "",
        activeYear: new Date().getFullYear(),
        searchValue: ""
    }

    /* 排名表格title項 */
    rankColumns = [
        { title: "姓名", key: "communistName", dataIndex: "communistName" },
        { title: "分数", key: "annualPoint", dataIndex: "annualPoint" }
    ];

    topNavs = [
        { name: "commOrgaNumber", span: 6, className: "org-no", title: "党支部数", counts: 0, url: "/app/organization" },
        { name: "communistNum", span: 6, className: "com-no", title: "党员数", counts: 0, url: "/app/partyMember" },
        { name: "activityNumber", span: 6, className: "huo-no", title: "党组织活动数", counts: 0, url: "/app/partyActivity" },
        {
            name: "releaseNumber",
            span: 6,
            className: "bkk-no",
            title: "资讯文章发布数",
            counts: 0,
            url: "/app/informationDelivery"
        }
    ];

    noticeNavs = [
        { id: 0, name: "阅读数", active: true },
        { id: 1, name: "发布数", active: false }
    ]

    yearNav = [
        { name: (new Date()).getFullYear() - 2, id: (new Date()).getFullYear() - 2, active: false },
        { name: (new Date()).getFullYear() - 1, id: (new Date()).getFullYear() - 1, active: false },
        { name: "今年", id: (new Date()).getFullYear(), active: true }
    ]

    dynamicNavs = [
        { name: "第一季度", id: "1", active: false },
        { name: "第二季度", id: "2", active: false },
        { name: "第三季度", id: "3", active: false },
        //{ name: "第四季度", id: "4", active: false },
        { name: "本年", id: "4", active: true }
    ];

    /* 获取党组织动态积分*/
    getpointPartyStatisticQuarterlyDetail = (statisticNode = "1") => {
        this.dynamicNavs = this.dynamicNavs.map(v => ({ ...v, active: v.id === statisticNode }));
        this.setState({ ppsLoading: true });
        http.request({
            method: "get",
            url: urls.app.index.pointPartyStatisticQuarterlyDetail,
            params: { statisticNode, yearly: (new Date()).getFullYear() }
        }).then(res => {
            if (res.success) {
                let pointPartyStatisticQuarterlyDetail = res.data ? res.data.rows : [];
                pointPartyStatisticQuarterlyDetail = pointPartyStatisticQuarterlyDetail.map(v => ({ ...v, dynamicPoint: parseFloat(v.dynamicPoint) }));
                this.setState({ pointPartyStatisticQuarterlyDetail, ppsLoading: false });
                rednerPointPartyStatisticQuarterlyDetail("hgb-pointPartyStatisticQuarterlyDetail", pointPartyStatisticQuarterlyDetail);
            } else {
                this.setState({ ppsLoading: false });
            }
        }).catch(err => {
            this.setState({ ppsLoading: false });
        })
    }

    //获取党组织活动积分
    getOrgActivities = (structureList = "") => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.index.getPointDistributeByStructureId,
            params: { structureList }
        }).then(res => {
            if (res.success) {
                let orgActivities = res.data || [];
                this.setState({ orgActivities, loading: false });
                renderOrgActivities("hgb-orgActivities", orgActivities);
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    }

    /*  */
    getCommunistByYear = (item) => {
        this.yearNav = this.yearNav.map(v => ({ ...v, active: v.id === item.id }));
        this.setState({
            activeYear: item.id
        }, () => {
            this.getCommunist();
        });
    }

    /*  */
    getCommunistByCommu = (item) => {
        this.setState({
            activeCommunistId: item.communistId
        }, () => {
            this.getCommunist();
        });
    }

    /* 获取党员动态积分 */
    getCommunist = () => {
        let { rankData, activeCommunistId, activeYear } = this.state;
        let _rankData = rankData.map(v => ({ ...v, active: v.id === activeCommunistId }))
        this.setState({ commPointAttributeLoading: true, rankData: _rankData });
        http.request({
            method: "get",
            url: urls.app.index.getCommPointAttribute,
            params: { communistId: activeCommunistId, year: activeYear }
        }).then(res => {
            if (res.success) {
                let commPointAttribute = res.data || [];
                commPointAttribute = commPointAttribute.map(v => ({ ...v, value: parseFloat(v.value) }));
                this.setState({ commPointAttribute, commPointAttributeLoading: false });
                renderCommPointAttribute("hgb-commPointAttribute", commPointAttribute);
            } else {
                this.setState({ commPointAttributeLoading: false });
            }
        }).catch(err => {
            this.setState({ noticeLoading: false });
        })
    }

    // 获取积分排行
    getRankData = (searchValue = "") => {
        this.setState({ rankDataLoading: true });
        let params = {};
        if (!!searchValue) {
            params["communistName"] = searchValue;
        }
        http.request({
            method: "get",
            url: urls.app.index.pointRank,
            params: {
                ...params,
                yearly: (new Date()).getFullYear()
            }
        }).then(res => {
            if (res.success) {
                let rankData = res.data || [];
                rankData = rankData.slice(0, 10);
                this.setState({
                    rankData,
                    rankDataLoading: false,
                    activeCommunistId: rankData.length > 0 ? rankData[0].communistId : 0
                }, () => {
                    if (rankData.length > 0) {
                        this.getCommunist();
                    }
                });
            } else {
                this.setState({ rankDataLoading: false });
            }
        }).catch(err => {
            this.setState({ rankDataLoading: false });
        })
    }

    /* 获取党建资讯 阅读数，发布数 */
    getReleseNumDistribution = ({ numType = 0, structureId = "" }) => {
        this.setState({ noticeLoading: true });
        http.request({
            method: "get",
            url: urls.app.index.getReleseNumDistribution,
            params: { numType/*, structureId */ }
        }).then(res => {
            if (res.success) {
                let releseNumDistribution = res.data || [];
                this.setState({ releseNumDistribution, noticeLoading: false });
                renderBar("hgb-noticeCont", releseNumDistribution, numType);
            } else {
                this.setState({ noticeLoading: false });
            }
        }).catch(err => {
            this.setState({ noticeLoading: false });
        })
    }

    /* 获取统计数量 */
    getCounts = (structureId) => {
        let { rootStructureId } = this.state;
        this.setState({ loading: true }, () => {
            http.all([
                http.request({
                    method: "get",
                    url: urls.app.index.getCommOrgaNum,
                    params: { structureId: rootStructureId }
                }),
                //党员积分排行
                http.request({
                    method: "get",
                    url: urls.app.index.pointRank,
                    params: {
                        yearly: (new Date()).getFullYear() + ""
                    }
                }),
                //党组织活动积分
                http.request({
                    method: "get",
                    url: urls.app.index.getPointDistributeByStructureId,
                    params: { structureId }
                }),
                //获取党组织机构列表
                http.request({
                    method: "get",
                    url: urls.app.organization.selectStructureTreeById,
                    params: { type: 0, flag: false }
                }),
                //获取党建资讯
                http.request({
                    method: "get",
                    url: urls.app.index.getReleseNumDistribution,
                    params: { structureId, numType: 0 }
                }),
                //学习情况
                http.request({
                    method: "get",
                    url: urls.app.index.getSchduleDistribute
                }),
                //学习率
                http.request({
                    method: "get",
                    url: urls.app.index.getStudyRadio
                }),
                //考试
                http.request({
                    method: "get",
                    url: urls.app.index.getExamRadio
                }),
                //党组织动态积分
                http.request({
                    method: "get",
                    url: urls.app.index.pointPartyStatisticQuarterlyDetail,
                    params: { statisticNode: 4, yearly: (new Date()).getFullYear() }
                }),
            ]).then(reses => {
                let data1 = reses[0].data || [];
                let rankData = reses[1].data || [];
                rankData = rankData.slice(0, 10);
                let orgActivities = reses[2].data || [];
                let orgList = setJsonArray(pcService.renderOrgAndUserNodes({ data: (reses[3].data || []), type: 1 }), "children");
                let releseNumDistribution = reses[4].data || [];
                let studyes = reses[5].data || [];
                let studyRadio = reses[6].data || [];
                let examRadio = reses[7].data || [];
                let pointPartyStatisticQuarterlyDetail = reses[8].data ? reses[8].data.rows : [];
                this.topNavs.map(v => {
                    v.counts = data1[v.name] ? data1[v.name] : 0;
                });
                if (rankData.length > 1) {
                    this.setState({ activeCommunistId: rankData[0].communistId }, () => {
                        this.getCommunist();
                    });
                }
                this.setState({
                    loading: false,
                    rankData,
                    studyRadio,
                    pointPartyStatisticQuarterlyDetail,
                    examRadio,
                    orgActivities,
                    orgList,
                    releseNumDistribution,
                    studyes
                }, () => {
                    renderBar("hgb-noticeCont", releseNumDistribution);
                    renderStudyes("hgb-study", studyes);
                    pointPartyStatisticQuarterlyDetail = pointPartyStatisticQuarterlyDetail.map(v => ({ ...v, dynamicPoint: parseFloat(v.dynamicPoint) }));
                    rednerPointPartyStatisticQuarterlyDetail("hgb-pointPartyStatisticQuarterlyDetail", pointPartyStatisticQuarterlyDetail);
                    renderOrgActivities("hgb-orgActivities", orgActivities);
                });
            }).catch(erres => {
                this.setState({ loading: false });
            })
        });
    }

    /* 搜索input onChnage */
    inputOnChange = ($event) => {
        let searchValue = $event.target.value;
        this.setState({ searchValue });
    }

    /* 搜索提交 */
    searchSubmit = () => {
        let { searchValue } = this.state;
        this.getRankData(searchValue);
    }


    /* 阅读，发布切换 */
    changeReadAndPublish = ({ name = "", id = "" }) => {
        let { structureId } = this.state;
        this.noticeNavs = this.noticeNavs.map(v => ({ ...v, active: v.id === id }))
        this.getReleseNumDistribution({ numType: id, structureId });
    }

    /* 切换组织机构 */
    orgOnChange = (structureId) => {
        this.setState({ structureId }, () => {
            this.getCounts(structureId);
        });
    }

    /* 跳转到向相应的页面 */
    gotoBlank = (item) => {
        pcService.relativePath(item.url);
    }

    componentDidMount() {
        let poc_headers = getLocal("poc_headers");
        let structureId = poc_headers.root_structure_id ? poc_headers.root_structure_id : 0;
        this.setState({ rootStructureId: structureId, structureId }, () => {
            setTimeout(() => {
                this.getCounts(structureId);
            }, 500);
        })

    }

    render() {
        const { gotoBlank, topNavs, searchSubmit, inputOnChange, getCommunistByYear, getCommunistByCommu, dynamicNavs, rankColumns, getpointPartyStatisticQuarterlyDetail, noticeNavs, changeReadAndPublish, yearNav, orgOnChange } = this;
        const {
            loading, structureId, orgList, rankData, noticeLoading, commPointAttributeLoading, ppsLoading, searchValue,
            orgActivities,//党组织活动积分
            releseNumDistribution,
            studyes,//学习情况
            examRadio,//考试统计
            commPointAttribute,//党员动态积分
            pointPartyStatisticQuarterlyDetail,//党组织动态积分
            studyRadio,//学习统计
            rankDataLoading, activeCommunistId
        } = this.state;
        const { videoPercentNum = 0, picturePercentNum = 0 } = studyRadio;
        const { joinExamRadio = 0, passExamRadio = 0 } = examRadio;
        return (
            <div className="home-pages">
                <Spin spinning={loading}>
                    <div className="hg-headers">
                        <Row>
                            {
                                topNavs.map((v, n) => <Col key={n} span={v.span}>
                                    <div className={v.className} title={v.title} onClick={gotoBlank.bind(this, v)}>
                                        <h3>
                                            <div className="hgh-icon"></div>
                                            {v.title}</h3>
                                        <p>{v.counts}</p>
                                    </div>
                                </Col>)
                            }
                        </Row>
                    </div>

                    <div className="hg-body">
                        {/* 党员动态积分 */}
                        <div className="hgb-panel">
                            <h3>
                                <div className="hgb-navs">
                                    <ul className="yearNav">
                                        {
                                            yearNav.map((v, n) => <li onClick={getCommunistByYear.bind(this, v)} key={n}
                                                className={v.active ? "li active" : "li"}>{v.name}</li>)
                                        }
                                    </ul>
                                    <div className="addOn">
                                        <Input value={searchValue} onChange={inputOnChange} placeholder="请输入姓名" />
                                        <Button onClick={searchSubmit} type="primary">搜索</Button>
                                    </div>
                                </div>
                                党员积分
                            </h3>
                            <div className="hgb-content flex-ct">
                                <div className="hgbcc-left">
                                    <Spin spinning={commPointAttributeLoading}>
                                        <div className="hgb-commPointAttribute" id="hgb-commPointAttribute">
                                            <p className="no-data">暂无数据</p>
                                        </div>
                                    </Spin>
                                </div>
                                <div className="hgbcc-right">
                                    <Spin spinning={rankDataLoading}>
                                        <h4>积分排名</h4>
                                        <div className="mt-table">
                                            <table>
                                                <tbody>
                                                    {rankData.length === 0 || !rankData ? (
                                                        <tr className="noData">
                                                            <td colSpan="4">暂无数据</td>
                                                        </tr>
                                                    ) : (
                                                            rankData.map((v, n) => <tr
                                                                className={v.communistId === activeCommunistId ? "active" : ""}
                                                                onClick={getCommunistByCommu.bind(this, v)} key={n}>
                                                                <td key="-1"><span className="index">{n + 1}</span></td>
                                                                {rankColumns.map((_v, _n) => <td
                                                                    key={_n}>{v[_v.dataIndex]}</td>)}
                                                            </tr>)
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                        <div className="hgb-panel">
                            <div className="hgb-col-2">
                                <h3>
                                    <div className="hgb-navs hgb-select">
                                        <Select getPopupContainer={triggerNode => triggerNode.parentElement} value={structureId} onChange={orgOnChange}>
                                            {
                                                orgList.map((v, n) => <Option key={n} value={v.id}>{v.name}</Option>)
                                            }
                                        </Select>
                                    </div>
                                    党组织活动积分
                                </h3>
                                <div className="hgb-content">
                                    <div className="hgb-noticeCont" id="hgb-orgActivities"></div>
                                </div>
                            </div>
                            <div className="hgb-col-2">
                                <h3>党建资讯</h3>
                                <div className="hgb-content">
                                    <Spin spinning={noticeLoading}>
                                        <ul className="hgb-noticeNav">
                                            {noticeNavs.map((v, n) => <li title={v.name}
                                                onClick={changeReadAndPublish.bind(this, v)}
                                                className={v.active ? "li active" : "li"}
                                                key={n}>{v.name}</li>)}
                                        </ul>
                                        <div className="hgb-noticeCont" id="hgb-noticeCont"></div>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                        {/* 党组织动态积分 */}
                        <div className="hgb-panel">
                            <h3>
                                <ul className="hgb-navs">
                                    {dynamicNavs.map((v, n) => <li title={v.name} className={v.active ? "li active" : "li"}
                                        onClick={getpointPartyStatisticQuarterlyDetail.bind(this, v.id)}
                                        key={n}>{v.name}</li>)}
                                </ul>
                                党组织动态积分
                            </h3>
                            <div className="hgb-content">
                                <Spin spinning={ppsLoading}>
                                    <div className="hgb-pointPartyStatisticQuarterlyDetail" id="hgb-pointPartyStatisticQuarterlyDetail"></div>
                                </Spin>
                            </div>
                        </div>
                        {/* 学习情况统计 */}
                        <div className="hgb-panel study-panel">
                            <div className="hgb-col-6">
                                <h3>学习情况</h3>
                                <div className="hgb-content">
                                    <h4>学习次数(人次)</h4>
                                    <div className="hgb-study" id="hgb-study"></div>
                                </div>
                            </div>
                            <div className="hgb-col-3">
                                <div className="hgb-col-top">
                                    <h3>学习率</h3>
                                    <div className="hgb-content">
                                        <div className="hgb-studyRadio">
                                            <div className="hgbs-td">
                                                <PercentBar percent={videoPercentNum} title="视频学习率" bgColor="#e6e6e6"
                                                    fgColor="#D75959" />
                                            </div>
                                            <div className="hgbs-td">
                                                <PercentBar percent={picturePercentNum} title="图文学习率" bgColor="#e6e6e6"
                                                    fgColor="#FB840F" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hgb-col-top">
                                    <h3>在线考试</h3>
                                    <div className="hgb-content">
                                        <div className="hgb-studyRadio">
                                            <div className="hgbs-td">
                                                <PercentBar percent={joinExamRadio} title="参考率" bgColor="#e6e6e6"
                                                    fgColor="#D75959" />
                                            </div>
                                            <div className="hgbs-td">
                                                <PercentBar percent={passExamRadio} title="通过率" bgColor="#e6e6e6"
                                                    fgColor="#FB840F" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default Tables;
