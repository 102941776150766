import React, { Component } from 'react';
import { Row, Spin, Button, Divider, Icon, Popconfirm, message } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import http from '../../common/axios/request';

class BannerList extends Component {

    state = {
        loading: false,
        pagination: {
            current: 1,
            pageSize: 100,
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "横幅",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        lists: [],
        hasGetPositions: false
    }

    /* 表格列项 */
    columns = [

        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1

        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, event) => {
                return <span>
                    <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 修改</a>
                    <Divider type="vertical" />
                    <Popconfirm title="确定要删除此项吗？" onConfirm={this.deleteItem.bind(this, record.id)} okText="确定"
                        cancelText="取消">
                        <a><Icon type="delete" theme="outlined" /> 删除</a>
                    </Popconfirm>
                </span>
            }
        },
        {
            title: '横幅位置',
            dataIndex: 'bannerLocationName',
            key: 'bannerLocationName'
        }, {
            title: '类别',
            dataIndex: 'type',
            key: 'type',
            render: text => text === 0 ? "新闻" : "投票"
        }, {
            title: '图片',
            dataIndex: 'picId',
            key: 'picId',
            render: text => !!text ? <div><img src={`${urls.file.viewImg}/${text}`} width="60" height="40" /></div> : ""
        }, {
            title: '跳转目标',
            dataIndex: 'targetName',
            key: 'targetName'
        },
        {
            title: '有效时间',
            dataIndex: 'effectiveTime',
            key: 'effectiveTime',
            render: text => pcService.dateFormat(text, "yyyy-MM-dd hh:mm")
        },
        {
            title: '排序',
            dataIndex: 'disorder',
            key: 'disorder',
            sorter: (a, b) => a.disorder - b.disorder
        }
    ];

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "targetName",
        editor: "normal",
        value: "",
        originValue: "",
        hideDisplayName: true,
        displayName: "跳转目标"
    }];

    /* 根据type获取banner*/
    getBannerByType = (item, form, value) => {
        form.resetFields(["targetId"]);
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                modalsLoading: true
            }
        });
        http.request({
            method: "get",
            params: { typeId: value },
            url: urls.app.bannerManage.getBannerType
        }).then(res => {
            if (res.success) {
                let data = res.data.rows ? res.data.rows : [];
                this.fieldsList.map(v => {
                    if (v.name === "targetId") {
                        v.opts = data;
                        return;
                    }
                })
                this.setState({
                    modalOptions: { ...modalOptions, modalsLoading: false }
                });
            } else {
                message.error(res.message);
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: false
                    }
                });
            }
        }).catch(err => {
            this.setState({
                modalOptions: {
                    ...modalOptions,
                    modalsLoading: false
                }
            });
        })
    }

    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: "minDate",
        value: new Date(),
        originValue: new Date(),
        editor: "hidden"
    }, {
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'bannerLocationId',
        displayName: '横幅位置',
        editor: 'select',
        value: '',
        originValue: '',
        opts: [],
        rules: [{
            message: "请选择横幅位置",
            required: true
        }]
    }, {
        name: 'type',
        displayName: '类型',
        editor: 'select',
        value: '',
        originValue: '',
        opts: [
            { id: 0, name: "新闻" },
            { id: 1, name: "投票" }
        ],
        onChange: this.getBannerByType,
        rules: [{
            message: "请选择端类型",
            required: true
        }]
    }, {
        name: 'targetName',
        value: '',
        editor: 'hidden'
    }, {
        name: 'targetId',
        displayName: '跳转目标',
        editor: 'select',
        value: '',
        originValue: '',
        opts: [],
        rules: [{
            message: "请选择跳转目标",
            required: true
        }],
        onChange: (item, form, value, opts = []) => {
            form.setFieldsValue({ "targetName": opts.filter(v => v.id === value)[0] ? opts.filter(v => v.id === value)[0].name : "" })
        }
    }, {
        name: 'effectiveTime',
        displayName: '有效时间',
        editor: 'datePicker',
        value: '',
        minDate: "minDate",
        originValue: null,
        rules: [{
            message: "请选择有效时间",
            required: true
        }]
    }, {
        name: 'disorder',
        displayName: '排序',
        editor: 'number',
        value: '',
        originValue: 0,
    }, {
        name: 'picId',
        displayName: '图片',
        editor: 'pictureWall',
        value: '',
        originValue: '',
        fileUpLoadOption: {
            action: urls.file.uploadGetId,
            listType: "picture-card",
            accept: "image/*",
            beforeUpload: (file) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('图片必须小于2MB!');
                }
                return isLt2M;
            },
            onChange: (info, form, _onchange) => {
                if (info.file.status === 'done') {
                    let _value = info.fileList.map(v => v.response);
                    _onchange(_value.join(","));
                }
            }
        }
    }];

    /* 获取列表数据 */
    fetchData = (params = {}) => {
        let { pagination } = this.state;
        let { pageSize, current } = this.state.pagination;
        let _params = Object.assign({}, { pageSize, current, sortField: "disorder", sortType: "ASC" }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.bannerManage.page, _params, null,
                (data) => {
                    let _pagination = pcService.getPagination(data, pagination);
                    this.setState({
                        loading: false,
                        lists: data.rows,
                        pagination: _pagination
                    });
                },
                () => {
                    this.setState({
                        loading: false
                    })
                }
            );
        });
    }

    /* 搜索 */
    seachOnSubmit = (form) => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }

    /* 新增修改横幅 */
    createOne = (item, create = true) => {
        let { modalOptions, hasGetPositions } = this.state;
        let that = this;
        if (!!create) {
            this.fieldsList.map(v => {
                if (v.editor === "file-upload") {
                    v.fileUpLoadOption.imageUrl = "";
                    v.fileUpLoadOption.loading = false;
                }
                return;
            })
        }
        let myPromise = new Promise((resolve) => {
            if (create && !hasGetPositions) {
                this.getBannerPosiitons(resolve);
            } else if (!create) {
                this.getBannerPosiitons(resolve, item.type);
            } else resolve();
        });
        myPromise.then(function () {
            let _create = false;
            if (create === false) {
                pcService.bindFormdData(item, that.fieldsList);
                that.fieldsList.map(v => {
                    if (v.name === "minDate") {
                        v.value = new Date();
                        return;
                    }
                })
            } else {
                _create = true;
                pcService.initFormList(that.fieldsList);
            }
            that.setState({
                modalOptions: {
                    ...modalOptions,
                    visible: true,
                },
                create: _create
            });
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        forms.validateFields((errors, values) => {
            delete values["minDate"];
            if (!errors) {
                let url = "", method = "post", msg = "";
                if (create) {
                    url = urls.app.bannerManage.addEntity;
                    msg = "新增";
                } else {
                    url = urls.app.bannerManage.updateEntity;
                    msg = "修改";
                }
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                });
                http.request({
                    method,
                    url,
                    data: values
                }).then(res => {
                    if (res.success) {
                        message.success(msg + "操作成功!");
                        this.setState({
                            modalOptions: {
                                ...modalOptions,
                                modalsLoading: false,
                                visible: false
                            }
                        }, this.fetchData);
                    } else {
                        message.error(res.message);
                        this.setState({
                            modalOptions: {
                                ...modalOptions,
                                modalsLoading: false
                            }
                        }, this.fetchData);
                    }

                }).catch(err => {
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    });
                })
            }
        });
    }

    /* 删除 */
    deleteItem = (ids) => {
        let that = this;
        this.setState({ loading: true });
        pcService.deleteItem(urls.app.bannerManage.deleteLogic, {}, ids, (data) => {
            message.success("删除成功!");
            setTimeout(function () {
                that.componentDidMount();
            }, 500);
        }, (err) => {
            this.setState({
                loading: false
            });
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 获取banner位置 */
    getBannerPosiitons = (resolve = null, type) => {
        this.setState({ loading: true }, () => {
            let requests = [http.request({
                method: "get",
                url: urls.app.bannerLocation.page
            })];
            if (type !== undefined) {
                requests.push(http.request({
                    method: "get",
                    params: { typeId: type },
                    url: urls.app.bannerManage.getBannerType
                }))
            }
            http.all(requests).then(reses => {
                let bannerLocations = reses[0].data.rows || [];
                this.fieldsList.map(v => {
                    if (v.name === "bannerLocationId") {
                        v.opts = bannerLocations;
                        v.originValue = bannerLocations.length > 0 ? bannerLocations[0].id : "";
                        return;
                    }
                });
                if (reses[1] && reses[1].data && reses[1].data.rows) {
                    let types = reses[1].data.rows || [];
                    this.fieldsList.map(v => {
                        if (v.name === "targetId") {
                            v.opts = types;
                            return;
                        }
                    });
                }
                this.setState({ loading: false, hasGetPositions: true });
                resolve();
            }).catch(err => {
                this.setState({ loading: false });
                resolve();
            });
        })

    }

    componentDidMount() {
        let form = this.refs.search.getForm();
        this.seachOnSubmit(form);
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
    }

    render() {
        const { loading, pagination, lists, modalOptions, create } = this.state;
        const { columns, fetchData, searchFieldsList, seachOnSubmit, createOne, fieldsList, onOk, onCancel } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <Spin spinning={loading}>
                <Row>
                    <div className="sp-bars">
                        <div className="sp-btns">
                            <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                        </div>
                        <div className="sp-forms">
                            <PcForm ref="search" layout="inline" submitText="搜索" onSubmit={seachOnSubmit}
                                fieldsList={searchFieldsList} />
                        </div>
                    </div>
                    <div className="sp-content">
                        <PcTable
                            columns={columns}
                            pagination={pagination}
                            dataSource={lists}
                            fetchData={fetchData}
                            bordered={false}
                        />
                    </div>
                </Row>
                <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading}
                    formItemLayout={formItemLayout} />
            </Spin>
        )
    }
}

export default BannerList;
