import React from 'react';
import { Input, Icon } from 'antd';
import { getCurrentMenus, getCurrentActiveMenu, setCurrentActiveMenu } from "./menu.service";
import { getNodeHandle, getIframeHeight, isEmpty, setJsonArray } from "../../utils";
import { connect } from 'react-redux';
import './switchCustom.less';
import { bindActionCreators } from 'redux';
import { addNavPanelAction, closeNavPanelAction } from '../../../action/NavpanelAction'
import pcService from '../../../other/pc.service';
const { Search } = Input;

class SwitchCustom extends React.Component {
    defaultFilterMenuLength = 10;
    state = {
        filterMenu: [],
        showFilterMenu: false,
        showShortcutMenu: true,
        moduleHeight: getIframeHeight(),
    };

    onClickMenu = (item) => {
        pcService.relativePath(item.path, item)
        this.closeComponent(); // 操作完成后直接关闭当前组件
    };

    /* 菜单item */
    renderMenuItem = (item, showIcon = true, showPath = false, index) => (
        <li key={index}>
            <a onClick={this.onClickMenu.bind(this, item)} title={item.title}>
                {showIcon && item.icon && <Icon type={item.icon} />}
                <span>{showPath ? item.path : item.title}</span>
            </a>
        </li>
    );

    //查找所有的根节点菜单
    findAllMenu = (menus) => {
        let allMenu = [];
        menus.map(item => {
            item.menus.map(v => {
                allMenu.push(v);
            });
        });
        return allMenu;
    };

    /* 菜单搜索 */
    searchMenu = (value) => {
        if (isEmpty(value)) {
            this.setState({ showShortcutMenu: true, showFilterMenu: false, filterMenu: [] });
            return;
        }
        let allMenuList = getCurrentMenus();
        let menusAll = setJsonArray(allMenuList, "menus");
        let filterMenu = menusAll.filter(item => (item.title.includes(value) && !!item.path));
        //let filterMenuList = this.findMenuPath(filterMenu);
        this.setState({
            filterMenu: filterMenu,
            showShortcutMenu: false,
            showFilterMenu: true
        });
    };

    /* 搜索onchange事件 */
    changeMenu = (e) => {
        let value = e.target.value;
        this.searchMenu(value);
    };

    //查找对应菜单的所有父节点路径
    findMenuPath = (data) => {
        return data.map(item => {
            return getNodeHandle()(getCurrentMenus(), item.menuId);
        })
    };

    //生成搜索的菜单
    renderFilterMenu = () => {
        let { filterMenu = [] } = this.state;
        return filterMenu.length === 0 ? <p>未查询到匹配的数据</p> : filterMenu.map((item, index) => this.renderMenuItem(item, false, false, index));
    };

    //鼠标点击其它关闭该组件
    closeComponent = () => {
        let { handleIcon } = this.props;
        if (handleIcon) handleIcon();
    };

    render() {
        const { showShortcutMenu, showFilterMenu } = this.state;
        const allMenu = this.findAllMenu(getCurrentMenus());
        const currentActiveMenu = getCurrentActiveMenu() ? getCurrentActiveMenu().slice(0, this.defaultFilterMenuLength) : [];
        const filterMenuDom = this.renderFilterMenu();

        return (
            <div className='switchCustomer'>
                <div className='switchCustomMask' onClick={this.closeComponent} />
                <div className="SwitchCustom">
                    <div className='searchWrapper'>
                        <Search
                            allowClear
                            placeholder="请输入菜单关键字查询"
                            onSearch={this.searchMenu}
                            onChange={this.changeMenu}
                        />
                    </div>
                    {showShortcutMenu ?
                        <section className='menuWrapper'>
                            <div className='parentMenu'>
                                <h2>平台应用</h2>
                                <ul>
                                    {allMenu && allMenu.map((item, index) => this.renderMenuItem(item, true, false, index))}
                                </ul>
                            </div>
                            <div className='recentMenu'>
                                <h2>最近使用</h2>
                                <ul>
                                    {currentActiveMenu && currentActiveMenu.map((item, index) => this.renderMenuItem(item, false, false, index))}
                                </ul>
                            </div>
                        </section> : null}
                    {showFilterMenu ?
                        <section className='menuWrapper'>
                            <div className="menuWrapper">
                                <div className="parentMenu">
                                    <h2>搜索结果:</h2>
                                    <ul>{filterMenuDom}</ul>
                                </div>
                            </div>
                        </section> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let { NavPanelReducer } = state;
    return {
        ...NavPanelReducer
    }
};

const mapDispatchToProps = dispatch => ({
    addNavPanel: bindActionCreators(addNavPanelAction, dispatch),
    closeNavPanel: bindActionCreators(closeNavPanelAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitchCustom);

