import React, { Component } from 'react';
import { Form, Input, InputNumber, Button } from 'antd';

const FormItem = Form.Item;

const TreeNewForm = Form.create()(
    class extends Component {


        render() {
            const { form, formSubmit, fieldsList = [] } = this.props;
            const { getFieldDecorator } = form;
            const formItemLayout = {
                labelCol: {
                    xs: { span: 5 },
                    sm: { span: 5 }

                },
                wrapperCol: {
                    xs: { span: 14 },
                    sm: { span: 14 }
                }
            }

            return (
                <Form layout="horizontal"   >
                    <FormItem
                        {...formItemLayout}
                        label="文件夹名称">
                        {getFieldDecorator('treeName', {
                            initialValue: fieldsList.name || '',
                            rules: [
                                { required: true, message: '请输入文件夹名称' },
                                { max: 20, message: '最多可以输入20个字符' },
                                { whitespace: true, message: '文件夹名称不能全为空格' }
                            ],
                            validateFirst: true
                        })(<Input />)
                        }
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="排序">
                        {getFieldDecorator('treeBzOrder', {
                            initialValue:  fieldsList.bzOrder ,
                            rules: [
                                { 
                                    pattern: new RegExp(/^\d+(\.{0,1}\d+){0,1}$/), message: '只能输入非负数字'
                             },
                            ],
                            validateFirst: true
                        })(<InputNumber min={0}  />)
                        }
                    </FormItem>
                </Form>
            )
        }
    }
);



export default TreeNewForm;
