import React, { Component } from 'react';
import Forms from './../../common/components/form/Forms';
import PcTitle from '../../common/components/title/Titles';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import pcService from '../../other/pc.service';
import { getLocal } from '../../common/utils';
import { loginOut } from '../../common/components/userNav/userNav.service';
import { message } from 'antd';
import './userInfoSetting.css';
import { Spin } from 'antd';

class UserInfoSetting extends Component {

    state = {
        loading: false,
        userInfo: {}
    };

    /* 个人信息字段 */
    fieldsList = [
        {
            name: 'userId',
            value: "",
            originValue: '',
            editor: 'hidden'
        },
        {
            name: 'name',
            displayName: '昵称',
            editor: 'normal',
            value: '',
            originValue: '',
            rules: [
                {
                    required: true,
                    message: "请输入昵称"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        },
        {
            name: 'userName',
            displayName: '账号',
            editor: 'normal',
            unavailable: true,
            value: '',
            originValue: '',
            rules: [
                {
                    required: true,
                    message: "请输入昵称"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        },
        {
            name: 'email',
            displayName: '邮箱',
            editor: 'normal',
            unavailable: true,
            value: '',
            originValue: '',
            rules: [
                {
                    required: true,
                    message: "请输入昵称"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        },
        {
            name: 'phone',
            displayName: '手机',
            editor: 'normal',
            unavailable: true,
            value: '',
            originValue: '',
            rules: [
                {
                    required: true,
                    message: "请输入昵称"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        },
        {
            name: 'password',
            displayName: '密码',
            editor: 'password',
            value: '',
            originValue: '',
            rules: [
                { required: true, message: "密码不能为空" },
                { min: 6, message: "密码最小长度为6个字符" },
                { max: 20, message: "密码最大长度为20个字符" },
                { whitespace: true, message: "不能输入空格" }
            ]
        },
        {
            name: 'confirm',
            displayName: '确认密码',
            editor: 'password',
            value: '',
            originValue: '',
            rules: [
                { required: true, message: "密码不能为空" },
                { min: 6, message: "密码最小长度为6个字符" },
                { max: 20, message: "密码最大长度为20个字符" },
                { whitespace: true, message: "不能输入空格" }
            ]
        },
        {
            name: 'no',
            displayName: '编号',
            editor: 'normal',
            value: '',
            originValue: ''
        },
        {
            name: 'remark',
            displayName: '备注',
            editor: 'textarea',
            value: '',
            originValue: ''
        },
        {
            name: 'headImg',
            displayName: '头像',
            columns: 1,
            editor: 'pictureWall',
            value: '',
            originValue: '',
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                imageUrl: "",
                loading: false,
                listType: "picture-card",
                showUploadList: false,
                accept: "image/*",
                beforeUpload: (file) => {
                    const isLt2M = file.size / 1024 / 1024 < 2;
                    if (!isLt2M) {
                        message.error('图片必须小于2MB!');
                    }
                    return isLt2M;
                },
                onChange: (info, form, _onchange) => {
                    if (info.file.status === 'done') {
                        let _value = info.fileList.map(v => v.response);
                        _onchange(_value.join(","));
                    }
                }
            },
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            // 
            render: (value) => (
                <div className="ant-upload ant-upload-select-picture-card">
                    <span className="ant-upload">
                        <img src={`${urls.file.viewImg}/${value}`} />
                    </span>
                </div>
            )


        }
    ];

    /* 表单额外操作项 */
    addOnActions = [
        {
            text: "返回",
            htmlType: "button",
            icon: "rollback",
            onClick: () => {
                window.history.go(-1);
            },
            type: "default"
        }
    ];

    /* 获取用户基本信息 */
    getUserInfo = () => {
        let userInfo = getLocal("user_info");
        if (!!userInfo) {
            pcService.bindFormdData(userInfo, this.fieldsList);
            this.fieldsList.map(v => {
                if ((v.name === "phone" || v.name === "email") && !v.value) {
                    v.unavailable = false;
                }
            });
            this.setState({ userInfo });
        } else {
            loginOut();
        }

    }

    /* 表单提交 */
    onSubmit = (form) => {
        //let that = this;
        form.validateFields((errors, values) => {
            if (!((values.password && values.confirm && values.password == values.confirm) || (!values.password && !values.confirm))) {
                message.error("两次密码输入不一致！");
                return;
            };
            if (!errors) {
                let _values = {};
                for (let v in values) {
                    _values[v] = values[v] || "";
                }
                this.setState({ loading: true }, () => {
                    http.request({
                        method: "post",
                        url: urls.rui.basic.user.editUser,
                        data: pcService.toFormData(_values)
                    }).then(res => {
                        if (res.success) {
                            message.success("修改成功,请重新登录！");
                            setTimeout(loginOut, 500);
                        } else {
                            message.error("修改失败!");
                        }
                        this.setState({
                            loading: false
                        });
                    }).catch(err => {
                        this.setState({
                            loading: false
                        });
                    })
                });
            }
        });
    }

    componentWillMount() {
        this.getUserInfo();
    }

    render() {
        const { loading } = this.state;
        const { fieldsList, onSubmit, addOnActions = [] } = this;
        const { title = "个人信息设置", hideFooter = false, editor = null } = this.props;
        if (!!editor) {
            fieldsList.map(v => {
                if (v.editor === "password") v.editor = "hidden";
                if (v.editor !== "hidden") v.editor = editor;
                v.rules = null;
            })
        }
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };

        return (
            <Spin spinning={loading}>
                <PcTitle icon="setting">{title}</PcTitle>
                <div className="usform-content">
                    <Forms fieldsList={fieldsList} submitIcon="check" addOnActions={addOnActions} onSubmit={onSubmit} hideFooter={hideFooter} columns="2" formItemLayout={formItemLayout} />
                </div>
            </Spin>
        )
    }
}

export default UserInfoSetting;
