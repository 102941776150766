import React, { Component } from 'react';
import UserInfoSetting from '../userInfoSetting/UserInfoSetting';

export class UserInfo extends Component {
    render() {

        const setting = {
            title: "个人信息",
            hideFooter: true,
            editor: "text"
        }
        return (
            <UserInfoSetting  {...setting} />
        )
    }
}

export default UserInfo;
