import React, { Component } from 'react';
import { Button, Divider, Icon, message, Popconfirm, Row, Spin, Modal, Input } from "antd";
import PcTable from "../../common/components/table/tableComponent";
//import PcModal from "../../common/components/modal/Modals";
import PcForm from "../../common/components/form/Forms";
import FormRecordsUpdate from "./FormRecordsUpdate";
import pcService from "../../other/pc.service";
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import { getLocal, setJsonArray } from '../../common/utils';
import { hashHistory } from 'react-router';

import "./orgIntegralRecordsList.less";

const thisYear = new Date().getFullYear();

export class OrgIntegralRecords extends Component {
    state = {
        hasUpdate: false,
        loading: false,
        detailVisible: false,
        isButtonAnnualClosed: true,
        isButtonPersonClosed: true,
        selectedRowKeys: [],
        orgs: [],
        inAnnualSettlementList: [],
        year: thisYear,
        pointAnnualBootId: "",
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        modalOptions: {
            title: "积分结算",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: true,
            centered: true,
            modalsLoading: false,
            footer: null,
            width: 800
        },
        modalOptionsConfig: {
            title: "修改积分配置",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: []
    };

    /**
     * 显示列
     * @type {Array}
     */
    columns = [{
        title: '序号 ',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record, index) => {
            return <span>
                <a onClick={this.partyIntegral.bind(this, record, false)} ><Icon type="search" theme="outlined" /> 党员积分</a>
            </span>
        }
    }, {
        title: '党组织名称',
        dataIndex: 'orgName'
    }, {
        title: '党委成员数',
        dataIndex: 'partyCommitteeCount',
    }, {
        title: '正式党员数',
        dataIndex: 'partyMemberCount',
    }, {
        title: '预备党员数',
        dataIndex: 'probationaryPartyMemberCount',
    }, {
        title: '总人数',
        dataIndex: 'peopleCount',
    }, {
        title: '人月合计',
        dataIndex: 'manMonth',
    }, {
        title: '第一季度积分',
        dataIndex: 'pointFirst',
    }, {
        title: '第二季度积分',
        dataIndex: 'pointSecond',
    }, {
        title: '第三季度积分',
        dataIndex: 'pointThird',
    }, {
        title: '党支部加分',
        dataIndex: 'pointBranchAdd',
    }, {
        title: '业务考核平均分',
        dataIndex: 'pointAvgBusiness',
    }, {
        title: '年度积分',
        dataIndex: 'pointFourth',
    }];
    /**
     * 搜索框内容
     */
    searchFieldsList = [{
        name: "yearly",
        editor: "select",
        value: "",
        originValue: thisYear,
        displayName: "积分年度",
        opts: [],
        onChange: (item, form, val, option) => {
            this.setState({ year: val });
        }
    }];

    /* 修改积分基础信息 */
    baseLists = [
        { name: "orgName", displayName: "所属机构", value: "" },
        { name: "yearly", displayName: "积分年度", value: "" },
        { name: "configName", displayName: "积分规则", value: "" },
        { name: "period", displayName: "积分周期", value: "" },
        { name: "beginTime", displayName: "积分开始日期", value: "" },
        { name: "stopTime", displayName: "积分结束日期", value: "" },
    ];

    /**
    * 查询
    */
    searchOnSubmit = (form) => {
        let { inAnnualSettlementList } = this.state;
        form.validateFields((errors, values) => {
            if (!errors) {
                //隐藏结算、积分配置、录入成绩
                inAnnualSettlementList && inAnnualSettlementList.map(v => {
                    if (values.yearly === v.yearly) {
                        this.setState({
                            isButtonAnnualClosed: v.inAnnualSettlement,
                            isButtonPersonClosed: v.inPersonSettlement,
                        })
                    }
                })
                this.fetchData(values);
            }
        });
    };
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    partyIntegral = (item, create) => {
        pcService.relativePath(`/app/integralRecords?year=${item.yearly}&pointAnnualBootId=${item.pointAnnualBootId}&orgId=${item.orgId}&orgName=${item.orgName}`);
    }
    /**
     * 加载数据的方法
     * @param params
     */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current, sortField: "orderBy", sortType: "ASC" }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.pointOrgYearly.page, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination,
                    pointAnnualBootId: lists.length !== 0 ? lists[0].pointAnnualBootId : ""
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    };
    intSettlement = () => {
        const { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            loading: false
        });
    }
    /* 结算确定 */
    intSettlementOnOk = (forms) => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }
    /* 结算取消 */
    intSettlementOnCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }
    /* 录入考核成绩 */
    enterExaminationAchievement = () => {
        const { year, pointAnnualBootId } = this.state;
        pcService.relativePath(`/app/enterExaminationAchievement?year=${year}&pointAnnualBootId=${pointAnnualBootId}`);
    }
    /* 积分明细 */
    getIntergraDetail = () => {
        const { year, pointAnnualBootId } = this.state;
        pcService.relativePath(`/app/integralDetail?yearly=${year}`);
    }
    /* 查询积分年度 */
    selectYearsAndSettlements = () => {
        let poc_headers = getLocal("poc_headers");
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.app.startYearPoint.selectYearsAndSettlements,
                params: {
                    root_structure_id: poc_headers.root_structure_id,
                    branch_structure_id: poc_headers.branch_structure_id,
                    is_root: poc_headers.is_root,
                }
            }).then(res => {
                if (res.success) {
                    if (res.data.length > 0) {
                        let resData = res.data;
                        this.searchFieldsList.map(v => {
                            if (v.name === "yearly") {
                                v.opts = resData.map(v => ({
                                    name: v.yearly,
                                    id: v.yearly
                                }))
                            }
                        })

                    }
                }
                let inAnnualSettlementList = res.data.length > 0 && res.data.map(v => ({
                    yearly: v.yearly,
                    inAnnualSettlement: v.inAnnualSettlement,
                    inPersonSettlement: v.inPersonSettlement,
                }));
                this.setState({
                    loading: false,
                    inAnnualSettlementList
                }, () => {
                    let forms = this.refs.pcForm.getForm();
                    !!forms && this.searchOnSubmit(forms, inAnnualSettlementList);
                });
            }).catch(err => {
                message.error(err.message);
                this.setState({ loading: false });
            })
        });
    }
    /* 修改积分配置 */
    detailConfig = () => {
        const { modalOptionsConfig, year, orgs } = this.state;
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.app.startYearPoint.selectStartDataByYear,
                params: {
                    year: year
                }
            }).then(res => {
                if (res.success) {
                    let data = res.data;
                    pcService.bindFormdData(data, this.fieldsList);
                    pcService.bindFormdData(data, this.baseLists);

                    if (data.applyOrgsIds) {
                        let _orgs = orgs.map(v => ({ ...v, disabled: data.applyOrgsIds.includes(v.id) }));
                        this.setState({ orgs: _orgs });
                    }
                }
                this.setState({ loading: false });
            }).catch(err => {
                message.error(err.message);
                this.setState({ loading: false });
            })
        });

        this.setState({
            modalOptionsConfig: {
                ...modalOptionsConfig,
                visible: true,
            },
            loading: false
        });
    }
    detailOnOk = (args) => {
        let { modalOptionsConfig, orgs } = this.state;
        let forms = this.refs.formUpdate;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = urls.app.startYearPoint.updateEntity;
                let applyOrgsIds = (orgs.filter(v => v.active && !v.disabled)).map(v => v.id);
                values.applyOrgsIds = applyOrgsIds ? applyOrgsIds.join(",") : "";
                this.setState({ loading: true }, () => {
                    pcService.formSubmit(url, false, values, null, (data) => {
                        message.success("修改积分配置成功!");
                        this.componentDidMount();
                        this.setState({ loading: false });
                    }, (err) => {
                        this.setState({ loading: false });
                    });
                });
                this.setState({
                    modalOptionsConfig: {
                        ...modalOptionsConfig,
                        visible: false
                    }
                });
            }
        });



    }
    detailOnCancel = () => {
        let { modalOptionsConfig } = this.state;
        this.setState({
            modalOptionsConfig: {
                ...modalOptionsConfig,
                visible: false
            }
        });
    }
    //党员积分结算
    settlementParty = () => {
        let { modalOptions, year } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
        pcService.relativePath(`/app/partyIntegralSettlement?year=${year}`);
    }
    //党支部积分结算
    settlementOrg = () => {
        let { modalOptions, year } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
        pcService.relativePath(`/app/orgIntegralSettlement?year=${year}`);
    }

    fieldsList = [{
        name: "id",
        editor: "hidden",
        displayName: "id",
        value: ""
    }, {
        name: "communistPartyWorkWeight",
        editor: "normal",
        value: "",
        displayName: "党员党建",
        options: {
            prefix: "积分权重",
            suffix: "%"
        },
        rules: [
            { required: true, message: '请输入积分权重' },
            { pattern: /^(?:0|[1-9][0-9]?|100)$/, message: '请输入0~100的整数' }
        ],
        onChange: (_item, form, _value, option) => {
            form.setFieldsValue({
                "communistBusinessWeight": 100 - _value.target.value
            });
        }
    }, {
        name: "communistBusinessWeight",
        editor: "normal",
        value: "",
        displayName: "党员业务",
        unavailable: true,
        options: {
            prefix: "积分权重",
            suffix: "%"
        },
        rules: [
            { required: true, message: '请输入积分权重' },
            { pattern: /^(?:0|[1-9][0-9]?|100)$/, message: '请输入0~100的整数' }
        ]
    }, {
        name: "leaderPersonalWeight",
        editor: "normal",
        value: "",
        displayName: "领导个人",
        options: {
            prefix: "积分权重",
            suffix: "%"
        },
        rules: [
            { required: true, message: '请输入积分权重' },
            { pattern: /^(?:0|[1-9][0-9]?|100)$/, message: '请输入0~100的整数' }
        ],
        onChange: (_item, form, _value, option) => {
            form.setFieldsValue({
                "leaderAvgWeight": 100 - _value.target.value
            });
        }
    }, {
        name: "leaderAvgWeight",
        editor: "normal",
        value: "",
        displayName: "领导平均",
        unavailable: true,
        options: {
            prefix: "积分权重",
            suffix: "%"
        },
        rules: [
            { required: true, message: '请输入积分权重' },
            { pattern: /^(?:0|[1-9][0-9]?|100)$/, message: '请输入0~100的整数' }
        ]
    },
    { name: "yearly", editor: "hidden", displayName: "积分年度", value: "" },
    { name: "configName", editor: "hidden", displayName: "积分规则", value: "" },
    { name: "period", editor: "hidden", displayName: "积分周期", value: "" },
    { name: "beginTime", editor: "hidden", displayName: "积分开始日期", value: "" },
    { name: "stopTime", editor: "hidden", displayName: "积分结束日期", value: "" },
    ];

    /* 全选 */
    allCheck = () => {
        let { orgs } = this.state;
        let isAllCheck = orgs.every(v => v.active);
        let _orgs = orgs.map(v => ({ ...v, active: isAllCheck ? false : true }));
        this.setState({ orgs: _orgs });
    }
    /* 选择组织 */
    checkOrg = (item = {}) => {
        let { orgs } = this.state;
        let _orgs = orgs.map(v => ({ ...v, active: v.id === item.id ? !v.active : !!v.active }));
        this.setState({ orgs: _orgs });
    }
    /* 获取党组织下面的党员信息 */
    getpartyMember = () => {
        let poc_headers = getLocal("poc_headers");
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                params: { flag: false, type: 0 },
                url: urls.app.organization.selectStructureTreeById
            }).then(res => {
                if (res.success) {
                    let opts = res.data || [];
                    let orgs = pcService.renderOrgAndUserNodes({ data: opts, type: 1, renderAll: true });

                    let oneArr = setJsonArray(orgs, "children");
                    oneArr = oneArr.filter(v => v.id !== poc_headers.root_structure_id && v.pLevel < 2);
                    this.setState({ loading: false, orgs: oneArr });
                } else {
                    this.setState({ loading: false });
                }
            }).catch(() => {
                this.setState({ loading: false });
            })
        })
    }

    /* 启动年度积分规则 */
    doActionButton = () => {
        pcService.relativePath("/app/startupIntegral");
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        /* 查询积分年度 */
        this.selectYearsAndSettlements();
    }
    componentDidMount() {
        let forms = this.refs.pcForm.getForm();
        !!forms && this.searchOnSubmit(forms);
        this.getpartyMember();
    }

    render() {
        const { loading, selectedRowKeys, pagination, lists, modalOptions, modalOptionsConfig, create, orgs, isButtonAnnualClosed, isButtonPersonClosed } = this.state;
        const { createOne, searchOnSubmit, searchFieldsList, onSelectChange, columns, intSettlementOnOk, intSettlementOnCancel, detailOnOk, detailOnCancel,
            fetchData, fieldsList, detailList, onOk, onCancel, deleteItem, intSettlement, detailConfig, enterExaminationAchievement, getIntergraDetail,
            settlementParty, settlementOrg, baseLists, allCheck, checkOrg, doActionButton } = this;
        const modalOptionsModals = { ...modalOptions, onOk: intSettlementOnOk, onCancel: intSettlementOnCancel }
        const modalOptionsConfigModals = { ...modalOptionsConfig, onOk: detailOnOk, onCancel: detailOnCancel }
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const doAction = lists.length === 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };

        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                {
                                    !doAction ?
                                        (<span>
                                            {
                                                !isButtonAnnualClosed ? (<span>
                                                    <Button onClick={intSettlement} type="primary">年度积分结算</Button>
                                                    <Divider type="vertical" />
                                                    <Button onClick={detailConfig}>修改积分配置</Button>
                                                    <Divider type="vertical" />
                                                    <Button onClick={enterExaminationAchievement}>录入考核成绩</Button>
                                                    <Divider type="vertical" />
                                                </span>) : ""
                                            }
                                            <Button onClick={getIntergraDetail}>党支部季度积分统计明细</Button>
                                        </span>) : (<span>
                                            <Button onClick={doActionButton}>启动积分配置</Button>
                                        </span>)
                                }
                            </div>
                            <div className="sp-forms">
                                <PcForm ref="pcForm" layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset={false}
                                    fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-orgintegralrecord-tips" style={{ display: isButtonAnnualClosed ? "" : "none" }}>
                            <p>说明：该年度积分结算已完成归档，只能进行积分查看!</p>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                {/* <PcModal modal={modalOptionsModals} fieldsList={fieldsList} formItemLayout={formItemLayout} modalsLoading={modalOptionsModals.modalsLoading} /> */}
                <Modal
                    {...modalOptionsModals}
                    onOk={intSettlementOnOk}
                    onCancel={intSettlementOnCancel}
                >
                    <div className={"org-record-list-settlement"}>
                        <div className={`org-record-list-settlement-party${isButtonPersonClosed ? " disable" : ""}`} onClick={!isButtonPersonClosed ? settlementParty : null}>第一步：党员积分结算</div>
                        <div className={`org-record-list-settlement-org${isButtonAnnualClosed || !isButtonPersonClosed ? " disable" : ""}`} onClick={!isButtonAnnualClosed && isButtonPersonClosed ? settlementOrg : null}>第二步：支部、领导积分结算</div>
                    </div>
                </Modal>
                {/* <PcModal modal={modalOptionsConfigModals} fieldsList={fieldsList} formItemLayout={formItemLayout} modalsLoading={modalOptionsConfig.modalsLoading} /> */}
                <Modal
                    {...modalOptionsConfigModals}
                    onOk={detailOnOk}
                    onCancel={detailOnCancel}
                >
                    <div className={"org-record-list-detail"}>
                        <div className={"org-record-list-detail-title"} >
                            <span className="tip" />
                            <label >基础信息</label>
                        </div>
                        <div className={"org-record-list-detail-list"} >
                            {
                                baseLists.map((v, i) => <p key={i}><span>{v.displayName} ：</span>{v.value}</p>)
                            }
                        </div>
                        <div className={"org-record-list-rule-title"} >
                            <span className="tip" />
                            <label >规则设置</label>
                        </div>
                        <div className={"org-record-list-rule-list"}>
                            <FormRecordsUpdate ref="formUpdate" fieldsList={fieldsList} />
                            {/* {
                                ruleLists.map((v, i) => <p key={i}>
                                    <span>{v.displayName + ' ：'}</span>
                                    <Input placeholder={'请输入积分权重' } value={v.value} onChange={inputOnChange.bind(this, item, form)} prefix="积分权重" suffix="%" style={{width: "600px"}} />
                                </p>)
                            } */}
                        </div>
                        <div className={"org-record-list-entity-title"} >
                            <span className="tip" />
                            <label >实施范围</label>
                        </div>
                        <div className={"org-record-list-entity-list"}>
                            <div className="content">
                                <div className="btns">
                                    <Button onClick={allCheck}>全选</Button>
                                </div>
                                <div className="opts">
                                    <ul>
                                        {orgs.map((v, n) => (
                                            <li key={n}>
                                                {
                                                    v.disabled ? <span className={v.disabled ? "disabled" : ""} >{v.name}</span>
                                                        : <span className={v.active ? "active" : ""} onClick={checkOrg.bind(this, v)}>{v.name}</span>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Spin>
        )
    }
}

export default OrgIntegralRecords;
