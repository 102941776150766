import React, { Component } from 'react'
import { Icon } from 'antd';

export class NavTabBar extends Component {
    handleDragEnter = (e, item) => {
        e.preventDefault();
        //console.log("2323", this.props.activeKey);
        //console.log("2324", this.props.data);
        return;

    }
    handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        //console.log(2222);
        return;
    }
    handleDrop = (e) => {
        e.preventDefault();
        //console.log(333);
        return;
    }

    render() {
        const { data = [], activeKey = "0", onChange = null, onEdit = null, onContextMenu = null } = this.props;
        return (
            <div className="ant-tabs-bar ant-tabs-top-bar ant-tabs-card-bar" onContextMenu={onContextMenu}>
                <div className="ant-tabs-nav-container">
                    <div className="ant-tabs-nav-wrap">
                        <div className="ant-tabs-nav-scroll">
                            <div className="ant-tabs-nav ant-tabs-nav-animated">
                                {
                                    data.map(item => (<div draggable="true" onDragEnter={this.handleDragEnter}
                                        onDragLeave={this.handleDragLeave}
                                        onDrop={this.handleDrop}
                                        onClick={onChange.bind(this, item.key)} className={item.key === activeKey ? 'ant-tabs-tab-active ant-tabs-tab draggable' : 'ant-tabs-tab draggable'} key={item.key}>
                                        <div>
                                            {
                                                item.title
                                            }
                                            {
                                                item.closable ? <Icon onClick={onEdit.bind(this, item.key, "remove")} className="ant-tabs-close-x" type="close" /> : null
                                            }
                                        </div>
                                    </div>))
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default NavTabBar
