import React from 'react';

/**
 * iframe自定义高级组件
 */
const IframeWidget=({url,title,moduleKey,height,onIframeload,customerStyle})=>{

    const defaultStyle={
        padding:0,
        margin:0
    };

    let styleClass;
    if(customerStyle)
    {
        styleClass=customerStyle;
    }
    else
    {
        styleClass=defaultStyle
    }

    return (
        <iframe
            style={styleClass}
            src={url}
            title={title}
            name={moduleKey}
            id={moduleKey}
            height={height}
            width="100%"
            scrolling="auto"
            frameBorder="0"
            onLoad={onIframeload}
        />
    )
};

export default IframeWidget;