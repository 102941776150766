/**
 * 设置父页面域名
 */
const SourceDomain='*';

/**
 * 新增TabPane
 * @param {*} key  Key值，全局唯一
 * @param {*} title 名称
 * @param {*} url  url
 * @param {*} isMenu 是否是菜单，默认是否
 * @param {*} domainUrl 指定域名，默认*
 */
export const addTabPane=(key,title,url,isMenu=false,domainUrl=SourceDomain)=>{
    const json={
        type:'addTabPane',
        data:{
            key,
            title,
            url,
            isMenu
        }
    };
    try {
        window.parent.postMessage(json, domainUrl);
    } catch (e) {
        window.parent.postMessage(json, SourceDomain);
    }
};

/**
 * 关闭TabPane
 * @param {*} removeKey 关闭TabPane的key
 * @param {*} isRefreshParent 是否需要刷新父页面，默认否
 * @param {*} domainUrl 指定域名，默认*
 */
export const removeTabPane=(removeKey,isRefreshParent=false,domainUrl=SourceDomain)=>{
    const json={
        type:'removeTabPane',
        data:{
            removeKey,
            isRefreshParent
        }
    };
    try {
        window.parent.postMessage(json, domainUrl);
    } catch (e) {
        window.parent.postMessage(json, SourceDomain);
    }
};

/**
 * 当请求token过期通知框架注销当前用户
 * @param {*} domainUrl 指定域名，默认*
 */
export const logout=(domainUrl=SourceDomain)=>{
    const json={
        type:'logout'
    };
    try {
        window.parent.postMessage(json, domainUrl);
    } catch (e) {
        window.parent.postMessage(json, SourceDomain);
    }
};

/**
 * 登录后传递当前用户
 * @param {*} currentUser
 * @param {*} domainUrl 指定域名，默认*
 * @param {*} frameId 指定Iframe的锚点id
 */
export const loginPost=(currentUser,domainUrl=SourceDomain,frameId)=>{
    const json={
        type:'login',
        data:{
            currentUser
        }
    };
    if (window.frames) {
        let frameWindow = window.frames[0]; //默认首页iframe对象
        if (frameId) {
            frameWindow = document.getElementById(frameId).contentWindow;
        }
        try {
            frameWindow.postMessage(json, domainUrl);
        } catch (e) {
            frameWindow.postMessage(json, SourceDomain);
        }
    }
};

/**
 * 注册PostMessage事件
 * @param {*} receivePostMessageFunc 接受数据方法
 */
export const createPostMessageEvent=(receivePostMessageFunc)=>{
    const receiveMessageFromIndex = function ( event ) {
        if(event!==undefined&&event.data&&event.data.type){
            //console.log(receivePostMessageFunc,event.data);
            receivePostMessageFunc(event);
        }
    };

    //监听message事件
    if (window.addEventListener) {
        window.addEventListener("message", receiveMessageFromIndex, false);
    } else if (window.attachEvent) {
        window.attachEvent('onmessage', receiveMessageFromIndex);
    }
};
