import React, { Component } from 'react';
import { Row, Spin, Button, Divider, message, Icon, Popconfirm, Modal } from 'antd';
import PcTable from '../../common/components/table/tableComponent';
import PcForm from '../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import urls from '../../configs/api.config';
import pcService from '../../other/pc.service';
import http from '../../common/axios/request';
import { getLocal, setJsonArray, isNotEmpty } from '../../common/utils';
import './flowManage.less';

class FlowManage extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        PartyMemerStructures: [],
        structureList: [],
        nodeLists: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        visible: false,
        create: true,
        modalOptions: {
            title: "流动党员",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        modalDetailOperation: {
            title: "流动党员详情",
            visible: false,
            cancelText: "返回",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: [],
        getpartyMember: false
    }

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        {/*   <a onClick={this.detailItem.bind(this, record)} ><Icon type="info" /> 详情</a>
                        <Divider type="vertical" /> */}
                        <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" /> 编辑</a>
                        <Divider type="vertical" />
                        <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                    </span>
                )
            }
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '流动状态',
            dataIndex: 'flowStatus',
            key: 'flowStatus',
            render: (text, record, index) => {
                if (text === "1") {
                    return "流入";
                }
                if (text === "-1") {
                    return "流出";
                }
            }
        },
        {
            title: '流出党组织',
            dataIndex: 'exportParty',
            key: 'exportParty',
            render: (text, record, index) => {
                if (record.flowStatus === "1") {
                    let exp = this.getSuperPartyById(record.idTreeShow);
                    record.exportParty = exp;
                    return exp;
                } else {
                    return text;
                }
            }
        },
        {
            title: '流入党组织',
            dataIndex: 'importParty',
            key: 'importParty',
            render: (text, record, index) => {
                if (record.flowStatus === "-1") {
                    let imp = this.getSuperPartyById(record.idTreeShow);
                    record.importParty = imp;
                    return imp;
                } else {
                    return text;
                }
            }
        }
    ];

    seachFieldsList = [{
        name: "name",
        editor: "normal",
        value: "",
        displayName: "输入姓名",
        hideDisplayName: true,
        originValue: ""
    }];

    /* 增加党员 */
    addPartyMember = () => {
        let { modalOptions } = this.state;
        pcService.relativePath("/app/partyMember");
        this.setState({
            modalOptions: { ...modalOptions, visible: false }
        });
    }

    addOnOptions = {
        editor: "node-select",
        addOn: {
            attrs: { disabled: true },
            AddOn: (props) => <Button onClick={this.addPartyMember} {...props}>新增党员</Button>
        }
    }

    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: "flowStatus",
        editor: "radio",
        value: "",
        originValue: "-1",
        displayName: "流入/流出",
        rules: [
            { required: true, message: "请选择流入/流出类型" }
        ],
        opts: [
            { id: "-1", name: "流出" },
            { id: "1", name: "流入" }
        ],
        onChange: ($event, form, value) => {
            form.resetFields(["exportParty", "importParty", "idTreeShow"]);
            if (value === "-1") {
                this.fieldsList.map(v => {
                    if (v.name === "importParty") {
                        v.unavailable = true;
                        v.displayName = "原组织";
                    }
                    if (v.name === "exportParty") {
                        v.unavailable = undefined;
                        v.displayName = "流出组织";
                    }
                    if (v.name === "importDate") {
                        v.name = "exportDate";
                    }
                    if (v.name === "importPartyAddress") {
                        v.name = "exportPartyAddress";
                    }
                    if (v.name === "importRemark") {
                        v.name = "exportRemark";
                    }
                    if (v.name === "idTreeShow") {
                        v.addOnOptions.addOn.attrs.disabled = true;
                    }
                });
            } else if (value === "1") {
                this.fieldsList.map(v => {
                    if (v.name === "importParty") {
                        v.unavailable = undefined;
                        v.displayName = "原组织";
                    }
                    if (v.name === "exportParty") {
                        v.unavailable = true;
                        v.displayName = "流入组织";
                    }
                    if (v.name === "exportDate") {
                        v.name = "importDate";
                    }
                    if (v.name === "exportPartyAddress") {
                        v.name = "importPartyAddress";
                    }
                    if (v.name === "exportRemark") {
                        v.name = "importRemark";
                    }
                    if (v.name === "idTreeShow") {
                        v.addOnOptions.addOn.attrs.disabled = false;
                    }
                });
            }
            this.setState({});
        }
    },
    {
        name: "idTreeShow",
        editor: "addOn",
        value: "",
        originValue: "",
        displayName: "参与党员",
        rules: [
            { required: true, message: "请选择参与党员" }
        ],
        opts: [],
        onChange: (item, forms, value) => {
            let { PartyMemerStructures } = this.state;
            //console.log(PartyMemerStructures)
            let jsonArr = setJsonArray(PartyMemerStructures, "children");
            let allowedName;
            jsonArr.map(v => {
                if (v.id === value.split("_")[1]) {
                    allowedName = v.name;
                }
            })
            let chaStatu = forms.getFieldsValue().flowStatus;
            if (chaStatu === "-1") {
                forms.setFieldsValue({
                    ["importParty"]: allowedName ? allowedName : ""
                });
            } else if (chaStatu === "1") {
                forms.setFieldsValue({
                    ["exportParty"]: allowedName ? allowedName : ""
                });
            }
        },
        addOnOptions: this.addOnOptions
    },
    {
        name: "name",
        editor: "hidden",
        value: undefined,
        originValue: undefined,
        displayName: "参与党员",
        rules: []
    }, {
        name: "importParty",
        editor: "normal",
        value: "",
        originValue: "",
        displayName: "原组织",
        unavailable: true,
        rules: [
            {
                required: true,
                message: "请输入流出组织"
            }
        ]
    }, {
        name: "exportParty",
        editor: "normal",
        value: "",
        originValue: "",
        displayName: "流出组织",
        rules: [
            { required: true, message: "请输入流出组织" },
            { max: 40, message: "最长为40个字符" },
            { whitespace: true, message: "不能输入空格" }
        ]
    }, {
        name: "exportDate",
        editor: "datePicker",
        value: "",
        originValue: null,
        displayName: "流动时间",
        rules: [
            {
                required: true,
                message: "请选择流动时间"
            }
        ]
    }, {
        name: "exportPartyAddress",
        editor: "normal",
        value: "",
        originValue: "",
        displayName: "基层党委通讯地址",
        rules: [
            { required: true, message: "请输入基层党委通讯地址" },
            { max: 60, message: "最长为60个字符" },
            { whitespace: true, message: "不能输入空格" }
        ]
    }, {
        name: "exportRemark",
        editor: "textarea",
        value: "",
        originValue: "",
        displayName: "流动原因",
        rules: [
            { max: 500, message: "內容最大长度为500个字符" },
        ]
    }];
    fieldsListDetailList = [{
        name: "flowStatus",
        value: "",
        displayName: "流入/流出",
        enumsArr: [{ id: "-1", name: "流出" },
        { id: "1", name: "流入" }]
    },
    {
        name: "name",
        value: "",
        displayName: "参与党员",
    },
    {
        name: "importParty",
        value: "",
        displayName: "原组织",
    }, {
        name: "exportParty",
        value: "",
        displayName: "流出组织",
    }, {
        name: "exportDate",
        value: "",
        displayName: "流动时间",
    }, {
        name: "exportPartyAddress",
        value: "",
        displayName: "基层党委通讯地址",
    }, {
        name: "exportRemark",
        value: "",
        displayName: "流动原因"
    }]

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 获取列表数据 */
    fetchData = (_params) => {
        let params = { ..._params, isFlow: true,commmunistInfoSourceId:"1" };
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.organization.selectFlowDataByPage, params, null, (data) => {
                this.setState({
                    loading: false,
                    lists: data.rows
                });
            }, () => {
                this.setState({
                    loading: false
                })
            });
        });
    }

    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) this.fetchData(values);
        });
    }

    /* 获取党组织下面的党员信息 */
    getpartyMember = (resolve) => {
        let _params = {
            flag: true,
            type: 0,
            isFlow: true,
            injob: "0"
        }
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.app.organization.selectStructureTreeById,
                params: _params,
            }).then(res => {
                if (res.success) {
                    let treeData = res.data;
                    let lists = pcService.renderOrgAndUserNodes({ data: treeData, type: 0, renderAll: true });
                    this.fieldsList.map(v => {
                        if (v.name === "idTreeShow") {
                            v.opts = lists
                        }
                    })
                    this.setState({ loading: false, getpartyMember: true, PartyMemerStructures: treeData, nodeLists:lists });
                } else {
                    message.info("未查询到党员信息");
                    this.setState({ loading: false });
                }
                resolve();
            }).catch(() => {
                !!resolve && resolve();
                this.setState({ loading: false });
            })
        })
    }

    /* 根据组织id获取组织名称 */
    getSuperPartyById = (id) => {
        let { nodeLists } = this.state;
        if (isNotEmpty(nodeLists)) {
            let _treeList = setJsonArray(nodeLists, "children");
            let treeList = _treeList.filter(v => v.structureId === id.split("_")[1]);
            if (treeList.length > 0) {
                return treeList[0].structureName;
            } else {
                return "";
            }
        }

    }

    /* 新增党组织关系 */
    createOne = (item, create) => {
        let { modalOptions, getpartyMember } = this.state;
        let msPromise = new Promise((resolve) => {
            if (!getpartyMember) {
                this.getpartyMember(resolve);
            } else resolve();
        });
        msPromise.then(() => {
            let _create = false;
            if (create === false) {
                this.fieldsList.map(v => {
                    if (v.name === "name") {
                        v.editor = "normal"
                    }
                    if (v.name === "idTreeShow") {
                        v.editor = "hidden";
                    }
                    if (v.name === "flowStatus" || v.name === "name") {
                        v.unavailable = true;
                    }
                })
                /* 编辑时，需改变原组织与流入流出组织关系 */
                //console.log(item)
                if (item.flowStatus === "-1") {
                    this.fieldsList.map(v => {
                        if (v.name === "importParty") {
                            v.unavailable = true;
                            v.displayName = "原组织";
                        }
                        if (v.name === "exportParty") {//
                            v.unavailable = undefined;
                            v.displayName = "流出组织";
                        }
                        if (v.name === "importDate") {
                            v.name = "exportDate";
                        }
                        if (v.name === "importPartyAddress") {
                            v.name = "exportPartyAddress";
                        }
                        if (v.name === "importRemark") {
                            v.name = "exportRemark";
                        }
                        if (v.name === "idTreeShow") {
                            v.addOnOptions.addOn.attrs.disabled = true;
                        }
                    });
                } else if (item.flowStatus === "1") {
                    this.fieldsList.map(v => {
                        if (v.name === "importParty") {//
                            v.unavailable = undefined;
                            v.displayName = "原组织";
                        }
                        if (v.name === "exportParty") {
                            v.unavailable = true;
                            v.displayName = "流入组织";
                        }
                        if (v.name === "exportDate") {
                            v.name = "importDate";
                        }
                        if (v.name === "exportPartyAddress") {
                            v.name = "importPartyAddress";
                        }
                        if (v.name === "exportRemark") {
                            v.name = "importRemark";
                        }
                        if (v.name === "idTreeShow") {
                            v.addOnOptions.addOn.attrs.disabled = false;
                        }
                    });
                }
                
                pcService.bindFormdData(item, this.fieldsList);
            } else {
                _create = true;
                this.fieldsList.map(v => {
                    if (v.name === "name") {
                        v.editor = "hidden"
                    }
                    if (v.name === "idTreeShow") {
                        v.editor = "addOn";
                        v.addOnOptions.addOn.attrs.disabled = true;
                    }
                    if (v.name === "flowStatus") {
                        v.unavailable = false;
                    }
                    //
                    if (v.name === "importParty") {
                        v.unavailable = true;
                        v.displayName = "原组织";
                    }
                    if (v.name === "exportParty") {//
                        v.unavailable = undefined;
                        v.displayName = "流出组织";
                    }
                    if (v.name === "importDate") {
                        v.name = "exportDate";
                    }
                    if (v.name === "importPartyAddress") {
                        v.name = "exportPartyAddress";
                    }
                    if (v.name === "importRemark") {
                        v.name = "exportRemark";
                    }
                })
                pcService.initFormList(this.fieldsList);
            }
            this.setState({
                modalOptions: {
                    ...modalOptions,
                    visible: true,
                },
                create: _create
            });
        });
    }

    

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        let that = this;
        let url = "";
        if(create){
            url = urls.app.partyMember.addFlowEntity; 
        }else{
            url = urls.app.partyMember.updateFlowData; 
        }
        forms.validateFields((errors, values) => {
            if (!errors) {
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                })
                values.isFlow = true;
                values.name = undefined;
                if (values.flowStatus === "-1") {
                    values.importParty = undefined;
                } else if (values.flowStatus === "1") {
                    values.exportParty = undefined;
                }
                pcService.formSubmit(url, false, values, {}, () => {
                    message.success("操作成功!");
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            visible: false,
                            modalsLoading: false
                        }
                    })
                    that.fetchData();
                    this.getpartyMember();
                }, (errMess) => {
                    message.error(errMess);
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false
                        }
                    })
                });
            }
        });

    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 删除列表项 */
    deleteItem = (idsArr = [], $event) => {
        let url = urls.app.organization.deleteFlowEntity;
        let addStrng = idsArr.join(",");
        let that = this;
        that.setState({ loading: true });
        pcService.deleteItem(url, {}, addStrng, {}, () => {
            message.success("删除成功!");
            setTimeout(function () {
                that.fetchData();
            }, 500);
            that.setState({
                loading: false,
                selectedRowKeys: []
            });
        }, () => {
            that.setState({
                loading: false
            });
        })
    };

    /* modal缴纳说明变更说明 */
    onDetailOk = (forms) => {
        let { modalDetailOperation } = this.state;
        let that = this;
        this.setState({
            modalDetailOperation: {
                ...modalDetailOperation,
                visible: false
            }
        });
    }
    onDetailCancel = () => {
        let { modalDetailOperation } = this.state;
        this.setState({
            modalDetailOperation: {
                ...modalDetailOperation,
                visible: false
            }
        });
    }

    /* 详情 */
    detailItem = (item) => {
        let { modalDetailOperation } = this.state;
        /* 详情时，需改变原组织与流入流出组织关系 */
        //console.log(item)
        if (item.flowStatus === "-1") {
            this.fieldsListDetailList.map(v => {
                if (v.name === "importParty") {
                    v.displayName = "原组织";
                }
                if (v.name === "exportParty") {//
                    v.displayName = "流出组织";
                }
                if (v.name === "importDate") {
                    v.name = "exportDate";
                } 
                if (v.name === "importPartyAddress") {
                    v.name = "exportPartyAddress";
                } 
                if (v.name === "importRemark") {
                    v.name = "exportRemark";
                } 
            });
        } else if (item.flowStatus === "1") {
            this.fieldsListDetailList.map(v => {
                if (v.name === "importParty") {//
                    v.displayName = "原组织";
                }
                if (v.name === "exportParty") {
                    v.displayName = "流入组织";
                }
                if (v.name === "exportDate") {
                    v.name = "importDate";
                } 
                if (v.name === "exportPartyAddress") {
                    v.name = "importPartyAddress";
                } 
                if (v.name === "exportRemark") {
                    v.name = "importRemark";
                } 
            });
        }

        pcService.bindFormdData(item, this.fieldsListDetailList);
        this.setState({
            detailVisible: true,
        });

        this.setState({
            modalDetailOperation: {
                ...modalDetailOperation,
                visible: true,
            },
        });
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentWillMount() {
        this.getpartyMember();
        pcService.initFormList(this.seachFieldsList);
        let structureList = getLocal("structureList");
        this.setState({ structureList });
    }

    componentDidMount() {
        let forms = this.refs.searchForm.getForm();
        this.seachOnSubmit(forms);
    }

    render() {

        const { loading, selectedRowKeys, pagination, lists, modalOptions, create, visible, modalDetailOperation, } = this.state;
        const { handleVisibleChange, cancel, onSelectChange, columns, fetchData, seachFieldsList, seachOnSubmit,
            createOne, fieldsList, onOk, onCancel, deleteItem, onDetailOk, onDetailCancel, fieldsListDetailList } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const modalDetail = { ...modalDetailOperation, onCancel: onDetailCancel, onOk: onDetailOk, width: 660, title: modalDetailOperation.title };
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="orgla-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                </Popconfirm>
                            </div>
                            <div className="sp-forms">
                                <PcForm ref="searchForm" layout="inline" submitText="搜索" onSubmit={seachOnSubmit} fieldsList={seachFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                <PcModal fieldsList={fieldsList} modal={modal} columns="2" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                <Modal {...modalDetail} className={"partyFeeBaseChanging"}>
                    <div className={"partyFeeBaseChanging-item"}>
                        {
                            fieldsListDetailList.map((v, i) => v.name === "importRemark" || v.name ==="exportRemark" ? <p dangerouslySetInnerHTML={{ __html: v.displayName + "：" + (v.value ? v.value : "") }}></p>
                                : <p key={i}><span>{v.displayName} ：</span>{v.enumsArr ? pcService.numberToText(v.value, v.enumsArr) : v.value}</p>)
                        }
                    </div>
                </Modal >
            </Spin >
        )
    }
}

export default FlowManage;
