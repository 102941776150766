import React, { Component } from 'react';
import { Tabs } from 'antd';
import PartyRap from './PartyRap';
import OrgRap from './OrgRap';

const { TabPane } = Tabs;

export class RewardsAndPunishments extends Component {

    render() {
        /* 导航菜单 */
        const TabsMenus = [{
            tab: "党员奖惩",
            component: PartyRap
        },
        {
            tab: "党支部奖惩",
            component: OrgRap
        }];

        return (
            <div className="democratic-review">
                <Tabs defaultActiveKey="0">
                    {
                        TabsMenus.map((v, n) => (
                            <TabPane tab={v.tab} key={n}>
                                <v.component />
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        )
    }
}

export default RewardsAndPunishments
