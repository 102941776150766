import React, { Component } from 'react';
import { Modal } from 'antd';
import Forms from '../form/Forms';
import { Spin } from 'antd';

class Modals extends Component {

    render() {
        const { fieldsList = [], modal = {}, style = {}, formItemLayout = {}, formItemColumns = 1, modalsLoading = false, formClassName } = this.props;
        const onOkFun = (...args) => {
            let forms = this.refs.forms;
            modal.onOk(forms, ...args);
        }
        return (
            <Modal {...modal} style={style} okButtonProps={{ loading: modal.modalsLoading }} onOk={onOkFun}>
                <Spin spinning={modalsLoading}>
                    <Forms ref="forms" columns={formItemColumns} fieldsList={fieldsList} hideFooter formItemLayout={formItemLayout} formClassName={formClassName} />
                </Spin>
            </Modal >
        )
    }
}

export default Modals;
