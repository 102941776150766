import React, { Component } from 'react';
import { Spin, message, Button } from 'antd';
import PcService from './../../other/pc.service';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import { hashHistory } from 'react-router';
import ReactToPrint from 'react-to-print';
import './targetedPovertyReduction.css';
import AttachList from './AttachList';

export class TargetedPovertyReductionDetail extends Component {
    state = {
        loading: false,
        isMode: "",
    }

    isAble = [
        { id: true, name: '是' },
        { id: false, name: '否' }
    ]

    fieldsList = [
        {
            name: "activityTitle",
            editor: "normal",
            displayName: "活动名称", 
        }, {
            name: "publishStatus",
            displayName: "发布状态",
            value: "",
            enumsArr: PcService.getDataDictionary("PUBLISH_STATUS")
        }, {
            name: "activityStatus",
            displayName: "活动状态",
            value: "",
            enumsArr: PcService.getDataDictionary("ACTIVITY_STATUS")
        }, {
            name: "signPlace",
            displayName: "签到地址",
            value: "",
            editor: "hidden",
        }, {
            name: "activityPlace",
            displayName: "活动地点",
            value: "",
        }, {
            name: "dataProcessorName",
            displayName: "活动资料员",
            value: "",
        }, {
            name: "publisherName",
            displayName: "负责人",
            value: "", 
        }, {
            name: "orgUnitName",
            displayName: "活动组织单位",
            value: "",
        }, {
            name: "activityBeginTime",
            displayName: "活动开始时间",
            value: "",
        }, {
            name: "activityEndTime",
            displayName: "活动结束时间",
            value: "", 
        }, {
            name: "signBeginTime",
            displayName: "签到开始时间",
            value: "",
            editor: "hidden",
        }, {
            name: "signEndTime",
            displayName: "签到结束时间",
            value: "",
            editor: "hidden",
        }, {
            name: "userIds",
            displayName: "活动人员",
            value: "",
        }
    ];
    fieldsListPlan = [
        {
            name: "activityDescription",
            displayName: "活动说明",
            value: "",
        },
        {
            name: "activityAttachments",
            displayName: "活动材料",
            value: "",
        }
    ];
    fieldsListRecord = [
        {
            name: "record",
            value: "",
            displayName: "活动记录",
        },
        {
            name: "recordAttach",
            value: "",
            displayName: "记录附件",

        }
    ];
    back = () => { 
        let panelId = PcService.getIdByPath("/app/targetedPovertyReductionDetail");
        PcService.removePanel(panelId); 
    }
    print = () => {
        document.getElementById('bindpadetail').click();
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        let that = this;
        this.setState({ loading: true, });
        http.request({
            method: 'get',
            url: urls.app.partyActivity.findById,
            params: { id },
        }).then(res => {
            //console.log(res)
            if (res.success === true) {
                let record = res.data;
                //处理参会人员
                let userID = "";
                record.userIds.map(v => userID += v.name + ",");
                record.userIds = userID;
                //处理活动类型
                record.activityType = record.activityType.split(",");
                PcService.bindFormdData(record, this.fieldsList);
                PcService.bindFormdData(record, this.fieldsListPlan);
                PcService.bindFormdData(record, this.fieldsListRecord);

                that.setState({
                    loading: false,
                    isMode: record.mode
                });
            } else {
                message.error(res.message);
                that.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({
                loading: false
            });
        });
    }

    render() {
        const { loading, isMode } = this.state;
        const { back, print } = this;
        return (
            <Spin spinning={loading} className="">
                <div>
                    <div className="padetail-button">
                        <Button type="default" onClick={back}>返回</Button>
                        <Button type="primary" onClick={print}>打印</Button>
                    </div>
                    <ReactToPrint
                        trigger={() => <a href="javascript:void(0);" id={'bindpadetail'}></a>}
                        content={() => this.componentRef}
                    />
                    <div ref={el => (this.componentRef = el)} >
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label > 活动基础信息({isMode === "add_new" ? "新增" : "补录"})</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsList.map((item) => {
                                            return (
                                                <div className="padetail-detail-list" key={item.name} >
                                                {
                                                        isMode !== "append" || item.editor !== "hidden" ? <div className="padetail-detail-item-base" value={item.value}>{item.displayName}：{item.enumsArr ? PcService.numberToText(item.value, item.enumsArr) : (item.name.includes("Time") && item.value ? item.value.substring(0, 16) : item.value)}</div> :
                                                            null
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label > 活动安排</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsListPlan.map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    {
                                                        item.name !== "activityAttachments" ?
                                                            <div className="padetail-detail-list" key={item.name} >
                                                                {
                                                                    item.name === "activityDescription" || item.name === "record" ? <div dangerouslySetInnerHTML={{ __html: item.displayName + "：" + (item.value ? item.value : "") }}></div>
                                                                        : <div className="padetail-detail-item-file" value={item.value}>{item.displayName}：<div className="meetingDesctrib">{item.value}</div></div>
                                                                }
                                                            </div> :
                                                            <AttachList itemAttach={item.value} itemName={"材料附件"} />
                                                    }
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label > 活动记录</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsListRecord.map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    {
                                                        item.name !== "recordAttach" ? <div className="padetail-detail-list" key={item.name} >
                                                            {
                                                                item.name === "activityDescription" || item.name === "record" ? <div dangerouslySetInnerHTML={{ __html: item.displayName + "：" + (item.value ? item.value : "") }}></div>
                                                                    : <div className="padetail-detail-item-file" value={item.value}>{item.displayName}：<div className="meetingDesctrib">{item.value}</div></div>
                                                            }
                                                        </div> :
                                                            <AttachList itemAttach={item.value} itemName={"记录附件"} />
                                                    }
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}

export default TargetedPovertyReductionDetail
