import React, { Component } from 'react';
import { Tabs } from 'antd';
import ExamPaper from './ExamPaper';
import ExamQuestion from './ExamQuestion';
import ExamTask from './ExamTask';
import './examPaper.css';

const { TabPane } = Tabs;

class Examination extends Component {


    state = {};

    render() {
        /* 导航菜单 */
        const TabsMenus = [{
            tab: "试题管理",
            component: ExamQuestion
        }, {
            tab: "试卷管理",
            component: ExamPaper
        }, {
            tab: "考试任务管理",
            component: ExamTask
        }];

        return (
            <div className="democratic-review">
                <Tabs defaultActiveKey="0">
                    {
                        TabsMenus.map((v, n) => (
                            <TabPane tab={v.tab} key={n}>
                                <v.component />
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        )
    }
}

export default Examination;
