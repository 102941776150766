import React, { Component } from 'react';
import { Button, message, Spin } from 'antd';
import PcService from './../../other/pc.service';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import { hashHistory } from 'react-router';
import ReactToPrint from 'react-to-print';
import '../partyActivity/partyActivity.css';
import AttachList from '../partyActivity/AttachList';

export class AssistPlanDetail extends Component {
    state = {
        loading: false,
    }

    fieldsList = [
        {
            name: "assistTheme",
            editor: "normal",
            displayName: "帮扶主题",
        }, {
            name: "assistOrgName",
            displayName: "组织单位",
            value: "",
        }, {
            name: "assistTime",
            editor: "normal",
            displayName: "帮扶时间",
        }, {
            name: "assistType",
            editor: "normal",
            displayName: "帮扶方式",
            enumsArr: PcService.getDataDictionary("ASSIST_RECORD_TYPE")
        }, {
            name: "difficultList",
            displayName: "帮扶对象",
            value: "",
        }
    ];
    fieldsListPlan = [
        {
            name: "remark",
            displayName: "活动安排说明",
            value: "",
        },
        {
            name: "attachment",
            displayName: "活动材料",
            value: "",
        }
    ];
    back = () => { 
        let panelId = PcService.getIdByPath("/app/assistPlanAdd");
        PcService.removePanel(panelId );
    }
    print = () => {
        document.getElementById('bindpadetail').click();
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        let that = this;
        this.setState({ loading: true, });
        http.request({
            method: 'get',
            url: urls.app.assistRecord.findById,
            params: { id },
        }).then(res => {
            //console.log(res)
            if (res.success === true) {
                let record = res.data;
                //处理参会人员
                let userID = "";
                if (record.difficultList) record.difficultList.map(v => userID += v.name + ",");
                record.difficultList = userID;
                PcService.bindFormdData(record, this.fieldsList);
                PcService.bindFormdData(record, this.fieldsListPlan);
                that.setState({
                    loading: false
                });
            } else {
                message.error(res.message);
                that.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({
                loading: false
            });
        });
    }

    render() {
        const { loading } = this.state;
        const { back, print } = this;
        return (
            <Spin spinning={loading} className="">
                <div>
                    <div className="padetail-button">
                        <Button type="default" onClick={back}>返回</Button>
                        <Button type="primary" onClick={print}>打印</Button>
                    </div>
                    <ReactToPrint
                        trigger={() => <a href="javascript:void(0);" id={'bindpadetail'}></a>}
                        content={() => this.componentRef}
                    />
                    <div ref={el => (this.componentRef = el)}>
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label> 帮扶活动基础信息</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsList.map((item) => {
                                            return (
                                                <div className="padetail-detail-list" key={item.name}>
                                                    <div className="padetail-detail-item-base"
                                                        value={item.value}>{item.displayName}：{item.enumsArr ? PcService.numberToText(item.value, item.enumsArr) : item.value}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label> 活动安排说明</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsListPlan.map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    {
                                                        item.name !== "attachment" ?
                                                            <div className="padetail-detail-list" key={item.name}>
                                                                {
                                                                    item.name === "remark" ? <div dangerouslySetInnerHTML={{ __html: item.displayName + "：" + item.value }}></div>
                                                                        : <div className="padetail-detail-item-file" value={item.value}>{item.displayName}：<div className="meetingDesctrib">{item.value}</div></div>
                                                                } 
                                                            </div> :
                                                            <AttachList itemAttach={item.value} itemName={"材料附件"} />
                                                    }
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Spin>
        )
    }
}

export default AssistPlanDetail;
