import React, { Component } from 'react';
import { Input, Checkbox, Button, Form } from 'antd';
import './login.css';

const LoginForm = Form.create()(
  class extends Component {

    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.loginFormInfo(values);
        }
      });
    };

    render() {
      const { loading = false, initValue = {}, connectUs } = this.props;
      const { getFieldDecorator } = this.props.form;
      return (
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item className="login-user">
            {
              getFieldDecorator('username', {
                rules: [{ required: true, message: '用户不能为空！' }],
                initialValue: initValue.username
              })(<Input disabled={loading} placeholder="用户" />)
            }
          </Form.Item>
          <Form.Item className="login-pwd">
            {
              getFieldDecorator('password', {
                rules: [
                  { required: true, message: '密码不能为空！' },
                  { min: 6, message: "密码最小长度为6个字符" },
                  { max: 20, message: "密码最大长度为20个字符" },
                  { whitespace: true, message: "不能输入空格" }
                ],
                initialValue: initValue.password
              })(<Input disabled={loading} type="password" placeholder="密码" />)}
          </Form.Item>
          <Form.Item className="do-action">
            {
              getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: initValue.remember,
              })(<Checkbox disabled={loading} className="login-rem">记住密码</Checkbox>)
            }
            <a className="login-supp" onClick={connectUs} href="javascript:void(0)">
              技术支持
          </a>
            <Button loading={loading} type="primary" htmlType="submit" className="login-submit-button">
              登录
          </Button>
          </Form.Item>
        </Form>
      );
    }
  }
);

export default LoginForm;
