import React, { Component } from 'react';
import { Tabs, Layout, Menu } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeNavPanelAction, addNavPanelAction, updatePanelAction, closeOtherPanelAction, closeAllPanelAction, reloadCurrentPanelAction, setOpenMenuUnActiveAction, clickMenuActiveAction } from '../../action/NavpanelAction';
import pcService from '../../other/pc.service';
import Table from '../table/Table';
import NavTabBar from './NavTabBar';
const { TabPane } = Tabs;
const { Content } = Layout;

class NavPanel extends Component {

    newTabIndex = 0;

    state = {
        initPanel: [
            { active: false, title: '首页', closable: false, key: '0', component: Table, path: "/app" }
        ],
        contextMenu: {
            visible: false,
            menuX: 0,
            menuY: 0
        }
    }

    /* 删除标签 */
    remove = targetKey => {
        let { removePanel } = this.props;
        removePanel(targetKey);
    };

    /* 编辑 */
    onEdit = (targetKey, action) => {
        if (action === "remove") {
            let { closeNavPanel, setParentSelectedKeys } = this.props;
            closeNavPanel({ targetKey, setParentSelectedKeys });
        }
    };

    /* 标签onChang事件 */
    onChange = (activeKey) => {
        //clickMenuActive,
        let { updatePanel, setParentSelectedKeys } = this.props;
        let { pathname } = this.props.location;
        let currentKey = pcService.getIdByPath(pathname);
        if (currentKey === activeKey) return;
        let selectedKey = pcService.getPathById(activeKey);
        let selectedKeys = selectedKey ? [selectedKey] : [];
        //clickMenuActive();
        setParentSelectedKeys(selectedKeys)
        updatePanel(activeKey);
    };

    /* 右键操作 */
    onContextMenu = (e) => {
        e.preventDefault(); // 取消默认右键
        let contextMenu = {
            visible: true,
            menuX: e.clientX,
            menuY: e.clientY
        }
        this.setState({ contextMenu });
    };

    /* 取消右键操作 */
    cancelAction = () => {
        let { contextMenu } = this.state;
        this.setState({
            contextMenu: {
                ...contextMenu,
                visible: false
            }
        });
    }

    /* 点击蒙版关闭右键菜单 */
    maskToggle = () => {
        let { contextMenu } = this.state;
        this.setState({
            contextMenu: {
                ...contextMenu,
                visible: false
            }
        })
    };

    /* 右键菜点击事件 */
    onMenuClick = (e) => {
        let { contextMenu } = this.state;
        const initHomeState = { active: false, title: '首页', closable: false, key: '0', component: Table, path: "/app" };
        const { closeNavPanel, closeOtherPanel, closeAllPanel, location, reloadCurrentPanel, setParentSelectedKeys } = this.props;
        let currentPath = location.pathname;
        let targetKey = pcService.getIdByPath(currentPath);
        switch (e.key) {
            case 'reloadCurrentPane':
                if (targetKey === "0") {
                    this.setState({
                        initPanel: [
                            { ...initHomeState, unMount: true }
                        ]
                    }, () => {
                        this.setState({
                            initPanel: [
                                { ...initHomeState }
                            ]
                        });
                    });
                }
                else reloadCurrentPanel(targetKey);
                break;
            case 'closedCurrentPane':
                closeNavPanel({ targetKey, setParentSelectedKeys });
                break;
            case 'closedOtherPanes':
                closeOtherPanel(targetKey);
                break;
            case 'closedAllPanes':
                setParentSelectedKeys([]);
                closeAllPanel(targetKey);
                break;
            case 'cancelAction':
                this.cancelAction();
                break;
            default:
                this.cancelAction();
                break;
        }
        //initHomeState = null;
        this.setState({
            contextMenu: {
                ...contextMenu,
                visible: false
            }
        });
    };

    componentDidUpdate() {
        const { setOpenMenuUnActive } = this.props;
        setOpenMenuUnActive();
    }

    render() {
        const { location = {} } = this.props;
        const { contextMenu } = this.state;
        const { onChange, onMenuClick, onEdit, onContextMenu, maskToggle } = this;
        const { visible, menuX, menuY } = contextMenu;
        const rightTopMenuStyle = visible ? { left: menuX, top: menuY, display: 'block' } : { display: 'none' };
        const { currentOpenMenuArr = [], activeKey = "0" } = pcService.getpanels(this);
        const RenderTabBar = () => <NavTabBar onChange={onChange} data={currentOpenMenuArr} onEdit={onEdit} activeKey={activeKey} onContextMenu={onContextMenu} />;
        return (
            <div className="nav-panels">
                <Tabs hideAdd renderTabBar={RenderTabBar} animated onChange={onChange} activeKey={activeKey} type="editable-card" onEdit={onEdit}>
                    {currentOpenMenuArr.map(pane => (
                        <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
                            <Content>
                                <div className="inner-content">
                                    {
                                        !pane.unMount ? <pane.component location={location} /> : ""
                                    }
                                </div>
                            </Content>
                        </TabPane>
                    ))}
                </Tabs>
                {/* 右键菜单 */}
                <div className="ReuseTabMenu-mask" onClick={maskToggle} style={{ display: visible ? "block" : "none" }}>
                    <Menu className="ReuseTabMenu" style={rightTopMenuStyle} onClick={onMenuClick}>
                        <Menu.Item key="reloadCurrentPane">刷新当前</Menu.Item>
                        <Menu.Item key="closedCurrentPane">关闭当前</Menu.Item>
                        <Menu.Item key="closedOtherPanes">关闭其它</Menu.Item>
                        <Menu.Item key="closedAllPanes">全部关闭</Menu.Item>
                        <Menu.Item key="cancelAction">取消操作</Menu.Item>
                    </Menu>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({ ...state });
const mapDispatchToProps = dispatch => ({
    closeNavPanel: bindActionCreators(closeNavPanelAction, dispatch),
    addNavPanel: bindActionCreators(addNavPanelAction, dispatch),
    updatePanel: bindActionCreators(updatePanelAction, dispatch),
    closeOtherPanel: bindActionCreators(closeOtherPanelAction, dispatch),
    closeAllPanel: bindActionCreators(closeAllPanelAction, dispatch),
    reloadCurrentPanel: bindActionCreators(reloadCurrentPanelAction, dispatch),
    setOpenMenuUnActive: bindActionCreators(setOpenMenuUnActiveAction, dispatch),
    clickMenuActive: bindActionCreators(clickMenuActiveAction, dispatch)
});

export default (connect(mapStateToProps, mapDispatchToProps)(NavPanel));
