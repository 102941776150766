import React from 'react';
import { Form, Input, Row, Col, Card, Button, Checkbox, Spin,message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane, removeTabPane } from '../../../action/menuTab.action';
import { get, post } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SysLogoUpload from './SysLogoUpload';
import SysSkinCheck from './SysSkinCheck';
import {isNotEmpty} from "../../../common/utils";
import appConfig from "../../../configs/app.config";
import pcService from './../../../other/pc.service';
import constantConfig from './../../../configs/constant.config';

const FormItem = Form.Item;

class CreateSystemSetForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formValueInit: {}
        };
    }

    componentWillMount() {
        if (this.props.location.query.editId) {
            this.init(this.props.location.query.editId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.editSystemSet && nextProps.editSystemSet.settingId !== this.props.editSystemSet.settingId) {
            this.init(nextProps.editSystemSet);
        }
    }

    init = (editId) => {
        this.setState({ loading: true });
        get({
            url: apiConfig.rui.setting.findById,
            params: {
                settingId: editId
            }
        }).then((res) => {
            if (res) {
                let obj = {}; if (isNotEmpty(res.sysSkin)) { obj = JSON.parse(res.sysSkin); } //解析登录框居中字段
                Object.assign(res, {loginCenter: isNotEmpty(obj['@center'])?obj['@center']:appConfig.loginCenter});

                this.setState({
                    loading: false,
                    formValueInit: {
                        ...res
                    }
                });
            }
        }).catch((error) => {
            this.setState({ loading: false });
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data;
                let url;

                let obj = {}; if (isNotEmpty(values.sysSkin)) { obj = JSON.parse(values.sysSkin); }
                Object.assign(obj, {'@center': values.loginCenter}); values.sysSkin = JSON.stringify(obj);

                if (!this.props.location.query.editId) {
                    url = apiConfig.rui.setting.addByJson;
                    data = {
                        ...values
                    }
                }
                else {
                    url = apiConfig.rui.setting.editByJson;
                    data = {
                        settingId: this.props.location.query.editId,
                        ...values
                    }
                }
                this.setState({ loading: true });
                post({
                    url,
                    data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((res) => {
                    message.success(constantConfig.SAVE_SUCCESS);
                    this.setState({ loading: false });
                    this.goBack(true);
                }).catch((error) => {
                    message.error(constantConfig.SAVE_FAIL);
                    this.setState({ loading: false });
                });
            }
        });
    }

    handelCancel = () => {
        this.goBack(false);
    }

    goBack = (isRefreshParent = false) => {
        let panelId = pcService.getIdByPath("/app/SystemSetManage");
        pcService.removePanel(panelId,true,'/app/systemSetList');
        //this.props.removeTabPane(this.props.paneKey, isRefreshParent);
    }

    render() {
        const { form } = this.props;
        const { loading, formValueInit } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        }
        return (
            <Spin spinning={loading}>
                <Card bordered={false}>
                    <Form onSubmit={this.handleSubmit}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="系统名称">
                                    {form.getFieldDecorator('sysName', {
                                        initialValue: formValueInit.sysName,
                                        rules: [
                                            { required: true, message: '请输入系统名称' },
                                            { max: 30, message: '最多输入30个字' }
                                        ],
                                    })(<Input placeholder="请输入" />)}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="主题风格">
                                    {form.getFieldDecorator('sysSkin', {
                                        initialValue: formValueInit.sysSkin,
                                        rules: [
                                            { pattern: /^[^\s]*$/, message: '请不要输入空格' }
                                        ],
                                    })(<SysSkinCheck />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="版本号">
                                    {form.getFieldDecorator('sysVersion', {
                                        initialValue: formValueInit.sysVersion,
                                        rules: [
                                            { required: true, message: '请输入版本号' }
                                        ],
                                    })(<Input placeholder="请输入" />)}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="应用基地址">
                                    {form.getFieldDecorator('appBasic', {
                                        initialValue: formValueInit.appBasic,
                                        rules: [
                                            { pattern: /^[^\s]*$/, message: '请不要输入空格' }
                                        ]
                                    })(<Input placeholder="请输入" />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="首页地址">
                                    {form.getFieldDecorator('sysHome', {
                                        initialValue: formValueInit.sysHome,
                                        rules: [
                                            // {required: true, message: '请输入首页地址'},
                                            { pattern: /^[^\s]*$/, message: '请不要输入空格' }
                                        ],
                                    })(<Input placeholder="请输入" />)}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="是否登录框居中">
                                    {form.getFieldDecorator('loginCenter', {
                                        valuePropName: 'checked',
                                        initialValue: formValueInit.loginCenter || false,
                                    })(<Checkbox></Checkbox>)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="登录背景">
                                    {form.getFieldDecorator('sysLogin', {
                                        initialValue: formValueInit.sysLogin,
                                        rules: [
                                        ]
                                    })(<SysLogoUpload altValue='登录背景' isSyslogin={true} />)}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="是否限制单处登录">
                                    {form.getFieldDecorator('sysAlone', {
                                        valuePropName: 'checked',
                                        initialValue: formValueInit.sysAlone || false,
                                    })(<Checkbox></Checkbox>)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="logo图标">
                                    {form.getFieldDecorator('sysLogo', {
                                        initialValue: formValueInit.sysLogo,
                                        rules: [
                                            // {required: true, message: '请上传logo图标'}
                                        ],
                                    })(<SysLogoUpload altValue='logo图标' />)}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="是否默认全局设置">
                                    {form.getFieldDecorator('sysGlobal', {
                                        valuePropName: 'checked',
                                        initialValue: formValueInit.sysGlobal || false,
                                    })(<Checkbox></Checkbox>)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} className="operationArea">
                                <Button onClick={this.handelCancel}>取消</Button>
                                <Button type="primary" htmlType="submit" loading={loading}>保存</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Spin>
        )
    }
}

const mapStateToProps = state => {
    // const { menuTab } = state;
    return {

    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreateSystemSetForm));