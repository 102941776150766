import React, { Component } from 'react';
import { Input } from 'antd';
import PositionPickerModal from './positionPickerModal';

export class PositionContainer extends Component {

    state = {
        show: false,
        mapModal: {
            visible: false,
            width: 1100,
            centered: true,
            destroyOnClose: true,
            maskClosable: false
        }
    }

    /* 切换定位组件显示隐藏 */
    toggle = () => {
        let { mapModal, show } = this.state;
        let { visible } = mapModal;
        this.setState({
            show: !show,
            mapModal: {
                ...mapModal,
                visible: !visible
            }
        })
    }

    /* 确定事件 */
    onOk = (...args) => {
        const { onChange = null, item = {} } = this.props;
        item.onChange && item.onChange(onChange, ...args);
        let { mapModal } = this.state;
        this.setState({
            show: false,
            mapModal: {
                ...mapModal,
                visible: false
            }
        })
    }

    /* 取消事件 */
    onCancel = () => {
        let { mapModal, show } = this.state;
        this.setState({
            show: false,
            mapModal: {
                ...mapModal,
                visible: false
            }
        })
    }

    render() {
        const { toggle, onOk, onCancel } = this;
        const { value = "", onChange = null, disabled = false, placeholder } = this.props;
        const { show, mapModal } = this.state;
        return (
            <div className="psc-cont">
                <Input placeholder={placeholder} readOnly disabled={disabled} onClick={toggle} value={value} onChange={onChange} />
                {
                    show ? <PositionPickerModal value={value} mapModal={{ ...mapModal, onOk, onCancel }} /> : null
                }
            </div>
        )
    }
}

export default PositionContainer
