import React from 'react';
import {Form, Input, message, Row, Col,Card,Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import { get,post } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
// import SelectOrganizationsTree from './SelectOrganizationsTree';
// import SelectRolesTree from './SelectRolesTree';
import {logout} from '../../../configs/user.service';
import appConfig from "../../../configs/app.config";
import pcService from './../../../other/pc.service';
import constantConfig from './../../../configs/constant.config';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

class CreateUserForm extends React.Component {

   state = {
      loading: false,
      confirmDirty: false,
      formValueInit:{}
   };

   componentWillMount() {
       if(this.props.location.query.editId)
       {  
           this.init(this.props.location.query.editId);
       }
   }

   init=(userId)=>
   {
        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.user.findById,
            params:{
                userId:userId
            }
        }).then((res)=>{
            if(res)
            {
                let structureIds=[];
                let roleIds=[];
                if(res.structure)
                {
                    for(let structureItem of res.structure)
                    {
                        if(structureItem)
                        {
                            structureIds.push(structureItem.structureId);
                        }
                    }
                }

                if(res.role)
                {
                    for(let roleItem of res.role)
                    {
                        if(roleItem)
                        {
                            roleIds.push(roleItem.roleId);
                        }
                    }
                }
                
                this.setState({
                    loading:false,
                    formValueInit:{
                        ...res,
                        structureIds,
                        roleIds
                    }
                });
            }
        }).catch((error)=>{
            this.setState({loading:false});
        })
        
   };
   
   componentWillReceiveProps(nextProps)
   {
       if(nextProps.editUser&&nextProps.editUser.userId!==this.props.editUser.userId)
       {
           this.init(nextProps.editUser);
       }
   }

   handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    const password=form.getFieldValue('password');
    if(password)
    {
        if (!value || value !==password) {
            callback('2次密码不一致');
        } else {
            callback();
        }
    }
    else
    {
        callback();
    }
   
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    // if (value && this.state.confirmDirty) {
    //   form.validateFields(['confirm'], { force: true });
    // }
    form.validateFields(['confirm'], { force: true });
    callback();
  };

   handleSubmit=(e)=>{
        e.preventDefault();
            this.props.form.validateFields((err, values) => {
            if (!err) {
                let data;
                let url;
                if(!this.props.location.query.editId)
                {
                    url= apiConfig.rui.basic.user.addUser;
                    data={
                        ...values
                    }
                } 
                else
                {
                    url= apiConfig.rui.basic.user.editUser;
                    data={
                        userId:this.state.formValueInit.userId,
                        ...values
                    }
                }
                // let structure=values.structure;
                // let role=values.role;
                // let structureIds=convertArrayToStr(structure);
                // let roleIds=convertArrayToStr(role);
                this.setState({loading:true});
                post({
                    url:url,
                    data:data
                }).then((res)=>{
                   
                //    let userId;
                //    if(res)
                //    {
                //       userId=res.userId;
                //    }
                //    if(!userId)
                //    {
                //       userId=this.state.formValueInit.userId;
                //    }
                //    this.saveStructure(userId,structureIds,roleIds);
                    this.setState({loading:false});
                    let {isSet}=this.props;
                    if(!isSet)
                    {
                        this.goBack(true);
                    }
                    else
                    {
                        message.success(constantConfig.SAVE_SUCCESS);
                        logout(this.props.history);
                    }
                }).catch((error)=>{
                    message.error(constantConfig.SAVE_FAIL);
                    this.setState({loading:false});
                });
            }
        });
   };

   saveStructure=(userId,structureIds,roleIds)=>{
        post({
            url:apiConfig.rui.basic.user.resetStructures,
            params:{
                userId,
                structureIds
            }
        }).then((res)=>{
            this.saveRole(userId,roleIds);
        }).catch((error)=>{
            this.setState({loading:false});
        });
   };

   saveRole=(userId,roleIds)=>{
       post({
           url:apiConfig.rui.basic.user.resetRoles,
           params:{
               userId,
               roleIds
           }
       }).then((res)=>{
           message.success(constantConfig.SAVE_SUCCESS);
           this.setState({loading:false});
           this.goBack(true);
       }).catch((error)=>{
           message.error(constantConfig.SAVE_FAIL);
           this.setState({loading:false});
       });
   };

   handelCancel=()=>{
        this.goBack(false);
   };

   goBack=(isRefreshParent=false)=>{
    let panelId = pcService.getIdByPath("/app/userManage");
    pcService.removePanel(panelId,true,'/app/userList');
        //this.props.removeTabPane(this.props.paneKey,isRefreshParent);
   };

   render() {
      const { form,isSet } = this.props;
      const { loading,formValueInit }=this.state;
      const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 }

        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 }
        }
      };

      let passwordRule={required: false, message: '请输入密码'};

    //   if(formValueInit.userId)
    //   {
    //      passwordRule={required:false,message:''};
    //   }

      return (
           <Card bordered={false}>
              <Form  onSubmit={this.handleSubmit}>
                {/* <Row gutter={24}>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="组织机构">
                            {form.getFieldDecorator('structure', {
                                initialValue: formValueInit.structureIds,
                                rules: [
                                    {required: true, message: '请选择组织机构'},
                                ],
                            })(<SelectOrganizationsTree />)}
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="角色">
                                {form.getFieldDecorator('role', {
                                    initialValue: formValueInit.roleIds,
                                    rules: [
                                        {required: true, message: '请选择角色'},
                                    ],
                                })(<SelectRolesTree />)}
                        </FormItem>  
                    </Col>
                </Row> */}
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="名称">
                            {form.getFieldDecorator('name', {
                                initialValue: formValueInit.name,
                                rules: [
                                    {required: true, message: '请输入名称'},
                                    {pattern: /^[^\s]*$/, message: '请不要输入空格'}
                                ],
                            })(<Input placeholder="请输入" type="text"/>)}
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="登录名">
                            {form.getFieldDecorator('userName', {
                                initialValue: formValueInit.userName,
                                rules: [
                                    {required: true, message: '请输入登录名'},
                                    {pattern: /^[^\s]*$/, message: '请不要输入空格'}
                                ],
                            })(formValueInit.userName?<Input placeholder="请输入" type="text" disabled/>:<Input placeholder="请输入" type="text"/>)}
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="邮箱">
                            {form.getFieldDecorator('email', {
                                initialValue: formValueInit.email,
                                rules: [
                                    {required: appConfig.isUseForgot, message: '请输入邮箱'},
                                    {type: 'email', message: '请输入正确格式的邮箱'}
                                ],
                            })(formValueInit.email?<Input placeholder="请输入" type="text" disabled/>:<Input placeholder="请输入" type="text"/>)}
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="手机号">
                            {form.getFieldDecorator('phone', {
                                initialValue: formValueInit.phone,
                                rules: [
                                    {required: appConfig.isUseForgot, message: '请输入手机号'},
                                    {pattern: /^1[0-9]{10}$/, message: '请输入正确格式的手机号'}
                                ]
                            })(formValueInit.phone?<Input placeholder="请输入" type="text" disabled/>:<Input placeholder="请输入" type="text"/>)}
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="密码">
                            {form.getFieldDecorator('password', {
                                initialValue: null,
                                rules: [
                                    passwordRule,
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ],
                            })(<Input.Password placeholder="请输入" /*type="password"*/ autocomplete="new-password"/>)}
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="确认密码">
                            {form.getFieldDecorator('confirm', {
                                initialValue: null,
                                rules: [
                                    passwordRule,
                                    {validator: this.compareToFirstPassword}
                                ],
                            })(<Input.Password placeholder="请输入" /*type="password"*/ onBlur={this.handleConfirmBlur} autocomplete="new-password"/>)}
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="编号">
                            {form.getFieldDecorator('no', {
                                initialValue: formValueInit.no
                            })(formValueInit.no?<Input placeholder="请输入" type="text" disabled/>:<Input placeholder="请输入" type="text"/>)}
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="备注" className="formTextAreaLabel">
                            {form.getFieldDecorator('remark', {
                                initialValue: formValueInit.remark,
                                rules: [
                                    {
                                        pattern: /^[^\s]*$/, message: '请不要输入空格'
                                    }
                                 ]
                            })(<TextArea placeholder="请输入"/>)}
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                     <Col span={24} className="operationArea">
                          {
                              !isSet&&<Button onClick={this.handelCancel}>取消</Button>
                          }
                         <Button type="primary" htmlType="submit" loading={loading}>保存</Button>
                     </Col>
                </Row>
            </Form>
           </Card>
         )
      }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(CreateUserForm));