import React, { Component } from 'react';
import { Button, Divider, Icon, message, Modal, Row, Spin, Popover, Popconfirm } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import pcService from './../../other/pc.service';
import urls from './../../configs/api.config';
import ExamQuestionModal from './ExamQuestionModal';
import DetailModal from "../../common/components/detailModal/DetailModal";
import PcService from "../../other/pc.service";
import './examQuestion.css';

const limitDecimals = (value) => {
    const reg = /^(\-)*(\d+)\.(\d\d).*$/;
    if (typeof value === 'string') {
        return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : ''
    } else if (typeof value === 'number') {
        return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : ''
    } else {
        return ''
    }
};

class ExamQuestion extends Component {

    state = {
        visible: false,
        loading: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        detailVisible: false,
        type: 0,
        options: [],
        modalOptions: {
            title: "试题",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: []
    };
    searchFieldsList = [{
        name: "category",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "分类",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("QUESTION_CATEGORY"))
    }, {
        name: "createdTime",
        editor: "datePicker",
        originValue: null,
        timeOptions: {
            showToday: true,
            format: 'YYYY-MM-DD'
        },
        displayName: "创建时间"
    }, {
        name: "keyWord",
        editor: "normal",
        value: "",
        displayName: "名称",
        hideDisplayName: true,
        originValue: ""
    }];
    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: "id",
        editor: "hidden",
        value: ""
    }, {
        name: "category",
        editor: "select",
        value: "",
        originValue: pcService.getDataDictionary("QUESTION_CATEGORY")[0].id,
        displayName: "分类",
        opts: pcService.getDataDictionary("QUESTION_CATEGORY"),
        rules: ["required"]
    },
    {
        name: "title",
        editor: "normal",
        value: "",
        displayName: "题目",
        rules: [
            "required",
            { max: 200, message: "题目长度为200个字符" }
        ]
    },
    {
        name: "type",
        editor: "select",
        value: "",
        originValue: 0,
        displayName: "类型",
        opts: [{
            id: 0,
            name: "单选"
        }, {
            id: 1,
            name: "多选"
        }],
        rules: ["required"],
        onChange: (item, form, val, option) => {
            form.resetFields(["correctAnswer"]);
            this.setState({ type: val, options: [] });
        }
    }, {
        name: "score",
        editor: "number",
        value: "",
        originValue: "0.2",
        displayName: "试题分值",
        range: [0, 100],
        rules: [
            "required",
            {
                validator: (rule, value, callback) => callback(value > 100 ? rule.message : undefined),
                message: "试题分值不能大于100"
            }

        ],
        numberOptions: {
            formatter: limitDecimals,
            parser: limitDecimals
        }
    }, {
        name: "correctAnswer",
        editor: "",
        value: "",
        unavailable: true,
        originValue: "",
        displayName: "正确答案",
        //opts: pcService.getDataDictionary("EXAM_OPTION"),
        rules: [{ required: true, message: "请输入正确答案" }]
    }, {
        name: "analysis",
        editor: "textarea",
        value: "",
        displayName: "答案解析",
        rules: [
            { max: 200, message: "答案解析长度为200个字符" }
        ]
    }
    ];

    detailList = [...this.fieldsList];

    listColumns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '选项',
            dataIndex: 'examOption',
            key: 'clode',
            render: (text) => pcService.numberToText(text, pcService.getDataDictionary("EXAM_OPTION"))
        },
        {
            title: '选项内容',
            dataIndex: 'content',
            render: value => value.length > 35 ? (
                <Popover content={value} placement="topRight"
                    overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                    {value.slice(0, 35) + '...'}
                </Popover>) : value
        }
    ];
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.examQuestion.page, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.displayTip = 'none';
                });
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination,
                    //selectedRowKeys: []
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    };
    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                let createdTime = values["createdTime"] ? PcService.dateFormat(values["createdTime"], "yyyy-MM-dd hh:mm:ss") : values["createdTime"];
                let createdTimeStr = values["createdTime"] ? pcService.dateFormat(values["createdTime"], "yyyy-MM-dd") : values["createdTime"];
                let _values = {
                    ...values,
                    "createdTime": createdTime,
                    "createdTimeStr": createdTimeStr
                };
                this.fetchData(_values);
            }
        });
    };
    /* 新增试题 */
    createOne = (_item, create) => {
        let item = { ..._item };
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            this.fieldsList.map(v => {
                if (v.name === "type") {
                    v.unavailable = true;
                    return;
                }
            });
            pcService.bindFormdData(item, this.fieldsList);
            this.setState({
                options: [...item.examOptionList]
            });
        } else {
            _create = true;
            this.fieldsList.map(v => {
                if (v.name === "type") {
                    v.unavailable = false;
                    return;
                }
            });
            pcService.initFormList(this.fieldsList);
        }
        this.setState({
            modalOptions: { ...modalOptions, visible: true },
            create: _create
        });
    };
    /* modal确定回调函数 */
    onOk = ($event) => {
        let forms = this.refs.examQuestionModal.refs.forms.getForm();
        let { modalOptions, options, create } = this.state;
        if (options.length === 0) {
            message.warning("请至少添加一个选项！");
            return;
        }
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = create ? urls.app.examQuestion.addEntity : urls.app.examQuestion.updateEntity;
                options.map(item => delete item.id);
                values.options = options;
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
                pcService.formSubmit(url, true, values, {}, (data) => {
                    message.success("操作成功");
                    this.setState({ loading: false, modalOptions: { ...modalOptions, modalsLoading: false, visible: false }, options: [] });
                    this.fetchData();
                }, (errMsg) => {
                    message.error(errMsg);
                    this.setState({ loading: false, modalOptions: { ...modalOptions, modalsLoading: false, visible: true } });
                });
            }
        });
    };
    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            },
            options: []
        });
    };
    onDetailCancel = () => {
        this.setState({
            detailVisible: false
        });
    };
    /* 详情 */
    detailItem = (item) => {
        pcService.bindFormdData(item, this.detailList);
        this.setState({
            options: item.examOptionList,
            detailVisible: true
        });
    };

    /**
     * 设置父组件状态值
     * @param args
     */
    setParentState = (args = {}) => {
        let { options = [] } = args;
        this.fieldsList.map(v => {
            if (v.name === "type") {
                v.unavailable = options.length > 0
            }
        });
        let _correctAnswerArr = (options.filter(v => v.isCorrectAnswer)).map(v => v.examOption);
        let eqForms = this.refs.examQuestionModal.refs.forms.getForm();
        eqForms.setFieldsValue({ correctAnswer: _correctAnswerArr.sort().join(",") });
        this.setState({
            ...args
        });
    };

    /**
     * 删除数据操作
     * @param item
     * @param form
     * @param $event
     */
    deleteItem = (item = [], $event) => {
        let ids = item.join(",");
        let that = this;
        that.setState({ loading: true });
        pcService.deleteItem(urls.app.examQuestion.logic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                that.setState({ loading: false, selectedRowKeys: [] });
            }, err => {
                that.setState({ loading: false });
            }
        );
    };

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            //width: 200,
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                        <Divider type="vertical" />
                        <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                    </span>
                )
            }
        },
        {
            title: '题目',
            dataIndex: 'title',
            key: 'title',
            render: (value) => !!value && value.length > 20 ? (<Popover content={value} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {value.slice(0, 20) + '...'}
            </Popover>) : value
        },
        {
            title: '分类',
            dataIndex: 'category',
            key: 'category',
            //width: 100,
            render: (value, record, index) => {
                return (<span>
                    {pcService.numberToText(value, pcService.getDataDictionary("QUESTION_CATEGORY"))}
                </span>);
            }
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'clode',
            //width: 60,
            render: (value, record, index) => {
                switch (value) {
                    case 0:
                        return <span>单选</span>;
                    case 1:
                        return <span>多选</span>;
                    default:
                        return;
                }
            }
        },
        {
            title: '分值',
            dataIndex: 'score',
            key: 'score',
            //width: 80
        },
        {
            title: '选项',
            key: 'optionView',
            dataIndex: 'optionView',
            render: (value, index) => value.length > 20 ? (
                <Popover content={value} placement="topRight"
                    overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                    {value.slice(0, 20) + '...'}
                </Popover>) : value
        },
        {
            title: '创建时间',
            dataIndex: 'createdTime',
            //width: 120,
            render: (value) => pcService.dateFormat(value, "yyyy-MM-dd hh:mm:ss"),
            sorter: (a, b) => new Date(a.createdTime) - new Date(b.createdTime)
        }
    ];

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        this.fetchData();
    }

    render() {

        const { loading, detailVisible, selectedRowKeys, pagination, lists, modalOptions, create, options, visible } = this.state;
        const { handleVisibleChange, listColumns, onDetailCancel, cancel, onSelectChange, columns, fetchData, searchFieldsList, searchOnSubmit, createOne, fieldsList, detailList, onOk, onCancel, setParentState, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 960, title: (create ? "新增" : "编辑") + modalOptions.title };
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        const detailFormItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 }
            }
        }
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button icon="delete" disabled={!allowDel} type="default">删除</Button>
                                </Popconfirm>
                                {/* <Button icon="delete" disabled={!allowDel} onClick={deleteItem.bind(this, selectedRowKeys, 'button')}>删除</Button> */}
                            </div>
                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                <Modal {...modal}>
                    <ExamQuestionModal ref="examQuestionModal" lists={options} onOk={modal.onOk}
                        fieldsList={fieldsList}
                        modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout}
                        setParentState={setParentState} />
                </Modal>
                <Modal title="试题详情" width={800} centered visible={detailVisible} footer={null} onCancel={onDetailCancel}>
                    <DetailModal lists={options} fieldsList={detailList} formItemLayout={detailFormItemLayout} columns={listColumns} />
                </Modal>
            </Spin>
        )
    }
}

export default ExamQuestion;
