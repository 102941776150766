import React, { Component } from 'react';
import { Button, message } from 'antd';
import TimeLine from '../../common/components/timeLine/TimeLine';
import Forms from '../../common/components/form/Forms';
import urls from './../../configs/api.config';
import http from './../../common/axios/request';
import { convertListToTreeJson, isNotEmpty } from "../../common/utils";
import pcService from '../../other/pc.service';
import moment from 'moment';
import './partyMemberAdd.less';

export class PartyMemberAdd extends Component {
    state = {
        numberStamp: 1,
        timeList: ["基础信息", "党籍信息", "工作信息", "账号信息"],
        fieldsList: [],
        enclosureList: [],
        columns: "2",
        partyName: "",
        imgResponseId: "",
        rootStructureId: "",
        branchStructureId: "",
    }

    fieldsListAccount = [
        {
            name: "userName",
            editor: "normal",
            value: "",
            displayName: "账号",
            rules: [
                {
                    required: true,
                    message: "建议输入手机号码作为账号"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "password",
            editor: "password",
            value: "123456",
            displayName: "密码",
            rules: [
                { required: true, message: "密码不能为空" },
                { min: 6, message: "密码最小长度为6个字符" },
                { max: 20, message: "密码最大长度为20个字符" },
                { whitespace: true, message: "不能输入空格" }
            ],
            pwdOptions: {
                autoComplete: "new-password"
            },
        }, {
            name: 'roleId',
            displayName: '角色',
            editor: "tree-select",
            treeCheckable: false,
            opts: [],
            multiple: false,
            rules: [
                { required: true, message: '请选择角色' }
            ],
            onChange: (item, value, $event) => {
                this.fieldsListAccount.map(v => {
                    if (v.name === "roleId") {
                        v.value = value
                    }
                })
            },
        }
    ];
    fieldsListBase = [
        {
            name: "name",
            editor: "normal",
            value: "",
            displayName: "姓名",
            rules: [
                {
                    required: true,
                    message: "请输入姓名"
                },
                { max: 20, message: '最多可以输入20个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "idCard",
            editor: "normal",
            value: "",
            displayName: "身份证",
            rules: [
                {
                    required: true,
                    message: "请输入身份证"
                },
                { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码' }
            ],
            onChange: (item, form, value, $event) => {
                let idCard = value.target.value;
                if (idCard.length <= 17) return;
                form.setFieldsValue({
                    "sex": this.getSex(idCard),
                    "birthday": new moment(idCard.substr(6, 8))
                });
                /* this.fieldsListBase.map(v => {
                    if (v.name === "sex") {
                        v.value = this.getSex(idCard);
                    }
                    if (v.name === "birthday") {
                        v.value = new moment(idCard.substr(6, 8));
                    }
                }) */
            },
        }, {
            name: "sex",
            editor: "select",
            displayName: "性别",
            originValue: false,
            unavailable: true,
            value: "",
            originValue: pcService.getDataDictionary("SEX")[0].id,
            columns: 4,
            opts: pcService.getDataDictionary("SEX"),
            rules: [
                { required: true, message: "请选择性别" }
            ]
        }, {
            name: 'birthday',
            displayName: '出生日期',
            editor: 'datePicker',
            unavailable: true,
            value: null,
            originValue: null,
            timeOptions: {
                showToday: true,
                format: 'YYYY-MM-DD'
            },
            rules: [{ required: true, message: '请选择出生日期' }]
        }, {
            name: "residence",
            editor: "normal",
            value: "",
            displayName: "居住地",
            rules: [
                {
                    required: true,
                    message: "请输入居住地"
                },
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "nativePlace",
            editor: "normal",
            value: "",
            displayName: "籍贯",
            rules: [
                {
                    required: true,
                    message: "请输入籍贯"
                },
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "tel",
            editor: "normal",
            value: "",
            displayName: "手机",
            rules: [
                {
                    required: true,
                    message: "请输入手机"
                },
                { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号码' }
            ],
            onChange: (_item, form, _value, option) => {
                this.fieldsListAccount.map(v => {
                    if (v.name === "userName") {
                        v.value = _value.target.value
                    }
                })
            },
        }, {
            name: "nation",
            editor: "select",
            value: pcService.getDataDictionary("NATION")[0].id,
            displayName: "民族",
            originValue: pcService.getDataDictionary("NATION")[0].id,
            opts: pcService.getDataDictionary("NATION"),
        }, {
            name: "degree",
            editor: "select",
            value: 5,
            originValue: "",
            displayName: "学历",
            opts: [
                { id: 1, name: "小学" },
                { id: 2, name: "初中" },
                { id: 3, name: "高中（中专）" },
                { id: 4, name: "专科（大专）" },
                { id: 5, name: "本科" },
                { id: 6, name: "硕士" },
                { id: 7, name: "博士" },
            ]
        }, {
            name: "university",
            editor: "normal",
            value: "",
            displayName: "毕业院校",
            rules: [
                { max: 20, message: '最多可以输入20个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "specialty",
            editor: "normal",
            value: "",
            displayName: "所学专业",
            rules: [
                { max: 20, message: '最多可以输入20个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "specialSkill",
            editor: "normal",
            value: "",
            displayName: "有何专长",
            rules: [
                { max: 20, message: '最多可以输入20个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "maritalStatus",
            editor: "select",
            value: 2,
            originValue: "",
            displayName: "婚姻状况",
            opts: [
                { id: 1, name: "已婚" },
                { id: 2, name: "未婚" },
            ],
            /* }, {
                name: "familyAddress",
                editor: "normal",
                value: "",
                displayName: "家庭住址",
                rules: [
                    {
                        whitespace: true,
                        message: "不能输入空格"
                    }
                ] */
        }, {
            name: "email",
            editor: "normal",
            value: "",
            displayName: "电子邮箱",
            rules: [
                { pattern: /^[a-zA-Z0-9_-]+(\.*[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确电子邮箱' }
            ]
        }, {
            name: "qq",
            editor: "normal",
            value: "",
            displayName: "QQ",
            rules: [
                { pattern: /^\d+$/, message: '请输入非负整数' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "info",
            editor: "textarea",
            value: "",
            displayName: "个人简介",
            rules: [
                { max: 395, message: '最多可以输入400个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: 'photo',
            displayName: '个人照片',
            editor: 'pictureWall',
            value: '',
            originValue: '',
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                listType: "picture-card",
                accept: "image/*",
                beforeUpload: (file) => {
                    const isLt2M = file.size / 1024 / 1024 < 2;
                    if (!isLt2M) {
                        message.error('图片必须小于2MB!');
                    }
                    return isLt2M;
                },
                onChange: (info, form, _onchange) => {
                    if (info.file.status === 'done') {
                        let _value = info.fileList.map(v => v.response);
                        _onchange(_value.join(","));
                    }
                }
            }
        }
    ];
    fieldsListOrigin = [
        {
            name: 'structureId',
            displayName: '所属党组织',
            editor: "tree-select",
            treeCheckable: false,
            opts: [],
            multiple: false,
            rules: [
                { required: true, message: '请选择所属党组织' }
            ],
            onChange: (item, value, $event) => {
                let optsList = this.setJsonArray(item.opts, "children");
                let valueList = optsList.filter(v => v.structureId === value);
                let partyName = valueList[0].structureName;
                let branchStructureId;
                if (valueList.length && valueList[0].partyType === "PARTY_CLASS") {
                    let valueParentList = optsList.filter(v => v.structureId === valueList[0].parentId);
                    partyName = valueParentList[0].structureName;
                    branchStructureId = valueList[0].parentId;
                }
                if (valueList.length && valueList[0].partyType === "PARTY") {
                    branchStructureId = valueList[0].id;
                }
                this.setState({
                    partyName: partyName,
                    branchStructureId
                })
            },
        }, {
            name: "type",
            editor: "select",
            displayName: "人员类别",
            value: pcService.getDataDictionary("TYPE_NORMAL")[0].id,
            originValue: pcService.getDataDictionary("TYPE_NORMAL")[0].id,
            columns: 4,
            opts: pcService.getDataDictionary("TYPE_NORMAL"),
            rules: [{ required: true, message: '请选择人员类别' }],
            onChange: (_item, form, _value, option) => {
                if (pcService.getDataDictionary("TYPE_NORMAL")[0].id !== _value) {
                    this.fieldsListOrigin.map(v => {
                        if (v.name === "isOut" || v.name === "isFlow" || v.name === "isHard" ||
                            v.name === "isPartyCadre" || v.name === "isMissing" || v.name === "isRetire") {
                            v.editor = "hidden";
                        }
                        if (v.name === "conversionTime") {
                            v.name = "probationaryTime";
                        }
                    })
                } else {
                    this.fieldsListOrigin.map(v => {
                        if (v.name === "isOut" || v.name === "isFlow" || v.name === "isHard" ||
                            v.name === "isPartyCadre" || v.name === "isMissing" || v.name === "isRetire") {
                            v.editor = "radio";
                        }
                        if (v.name === "probationaryTime") {
                            v.name = "conversionTime";
                        }
                    })
                }
                this.setState();
            },
        }, {
            name: 'conversionTime',
            displayName: '入党时间',
            editor: 'datePicker',
            value: null,
            originValue: null,
            timeOptions: {
                showToday: true,
                format: 'YYYY-MM-DD'
            },
            rules: [{ required: true, message: '请选择入党时间' }]

            /* }, {
                name: "inJob",
                editor: "select",
                displayName: "党内职务",
                value: pcService.getDataDictionary("POSITION")[2].id,
                originValue: "",
                columns: 4,
                opts: pcService.getDataDictionary("POSITION"),
                rules: [{ required: true, message: '请选择党内职务' }], */
        }, {
            name: "memberShipStatus",
            editor: "select",
            value: pcService.getDataDictionary("MEMBER_SHIP_STATUS")[0].id,
            displayName: "党籍状态",
            originValue: pcService.getDataDictionary("MEMBER_SHIP_STATUS")[0].id,
            columns: 4,
            opts: pcService.getDataDictionary("MEMBER_SHIP_STATUS"),
            rules: [
                {
                    required: true,
                    message: "请选择党籍状态"
                }
            ]
        }, {
            name: "isOut",
            editor: "radio",
            displayName: "是否长期在外党员",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: [
                {
                    message: '请选择',
                    required: true
                }
            ]
        }, {
            name: "isFlow",
            editor: "radio",
            displayName: "是否流动党员",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: [
                {
                    message: '请选择',
                    required: true
                }
            ]
        }, {
            name: "isHard",
            editor: "radio",
            displayName: "是否困难党员",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: [
                {
                    message: '请选择',
                    required: true
                }
            ]
        }, {
            name: "isPartyCadre",
            editor: "radio",
            displayName: "是否专职党务干部",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: [
                {
                    message: '请选择',
                    required: true
                }
            ]
        }, {
            name: "isMissing",
            editor: "radio",
            displayName: "是否失联党员",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: [
                {
                    message: '请选择',
                    required: true
                }
            ]
        }, {
            name: "isRetire",
            editor: "radio",
            displayName: "是否退休",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: [
                {
                    message: '请选择',
                    required: true
                }
            ]
        }, {
            name: "remark",
            editor: "normal",
            value: "",
            displayName: "备注",
            rules: [
                { max: 395, message: '最多可以输入400个字符' },
            ],
            columns: 1,
        }
    ];
    fieldsListWork = [
        {
            name: "unitName",
            editor: "normal",
            value: "",
            displayName: "工作单位",
            rules: [
                {
                    required: true,
                    message: "请输入工作单位"
                },
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "duty",
            editor: "normal",
            value: "",
            displayName: "工作岗位",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                { whitespace: true, message: "不能输入空格" }
            ]
        }, /* {
            name: "title",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "技术职称",
            opts: [
                { id: "1", name: "初级职称" },
                { id: "2", name: "中级职称" },
                { id: "3", name: "高级职称" },
            ],
            rules: [
                {
                    required: true,
                    message: "请选择技术职称"
                }
            ]
        }, */ {
            name: "headship",
            editor: "normal",
            value: "",
            displayName: "行政职务",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]

        }
    ];

    nextStamp = (isLast) => {
        const { numberStamp } = this.state;
        const { fieldsListBase, fieldsListAccount, fieldsListOrigin, fieldsListWork } = this;
        //数据校验
        if (isLast === "next") {

            if (numberStamp === 1) {
                let forms = this.refs.developAddFormsBase.getForm();
                let submitBase = this.onSubmit(forms);
                if (submitBase === 'stop') return;
            }
            if (numberStamp === 2) {
                let forms = this.refs.developAddFormsOrigin.getForm();
                let submitBase = this.onSubmit(forms);
                if (submitBase === 'stop') return;
            }
            if (numberStamp === 3) {
                let forms = this.refs.developAddFormsWork.getForm();
                let submitBase = this.onSubmit(forms);
                if (submitBase === 'stop') return;
            }

        }

        //页面跳转操作
        let fieldsListTem = [];
        let newStamp = isLast === "last" ? numberStamp - 1 : numberStamp > 3 ? numberStamp : numberStamp + 1;
        switch (newStamp) {
            case 1:
                fieldsListTem = fieldsListBase;
                this.setState({ columns: "2" })
                break;
            case 2:
                fieldsListTem = fieldsListOrigin;
                this.setState({ columns: "1" })
                break;
            case 3:
                fieldsListTem = fieldsListWork;
                this.setState({ columns: "1" })
                break;
            case 4:
                fieldsListTem = fieldsListAccount;
                this.setState({ columns: "1" })
                break;
            default:
                break;
        }
        this.setState({
            fieldsList: fieldsListTem,
            numberStamp: newStamp,
        })

    }
    /* form校验 */
    onSubmit = (form, $event) => {
        let data = {}, errs = {}, isStop = false;
        form.validateFields((err, fieldsValue) => {
            data = fieldsValue;
            errs = err;
        });
        for (let v in errs) {
            if (errs[v]) {
                isStop = true;
            }
        }
        if (isStop) {
            return 'stop';
        } else {
            return data;
        }

    }
    // 嵌套JSON数组转平级 
    setJsonArray = (data, subMenu = "children") => {
        let result = [];
        data.forEach(json => {
            if (json) {
                if (isNotEmpty(json[subMenu])) {
                    result = result.concat(this.setJsonArray(json[subMenu]));
                }
                result.push(json);
            }

        });
        return result;
    };
    submitData = (url, data, params, successCallback = null) => {
        pcService.formSubmit(url, true, data, params, (data) => {
            message.success("操作成功!");
            //console.log(data)
            successCallback && successCallback(data)
            this.setState({ loading: false });

        }, (err) => {
            message.error(err);
            this.setState({ loading: false });
        });
    }
    initRoleData = () => {
        let _params = {
            pageIndex: 0,
            pageSize: 9999
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.partyMember.listRoles,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = convertListToTreeJson(res.data.list, '0', 'subRole', 'roleId', 'levelBy');
                let lists = treeData ? treeData : [];
                let firstValue, flag = true;
                lists.length && lists.map(v => {
                    v.id = v.roleId;
                    v.name = v.roleName;
                    v.structureId = v.roleId;
                    if (flag) {
                        firstValue = v.roleId;
                    }
                    v.structureName = v.roleName;
                    if (v.subRole) {
                        v.subRole && v.subRole.map(k => {
                            k.id = k.roleId;
                            if (flag) {
                                firstValue = k.roleId;
                            }
                            k.name = k.roleName;
                            k.structureId = k.roleId;
                            k.structureName = k.roleName;
                        })
                        v.subStructure = v.subRole;
                    }
                    flag = false;
                });
                this.fieldsListAccount.map(v => {
                    if (v.name === "roleId") {
                        v.opts = lists;
                        v.value = firstValue;
                    }
                })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    handleSave = () => {
        const { partyName, enclosureList, imgResponseId, rootStructureId, branchStructureId } = this.state;
        /* 数据统一提交 */
        let formsAccount = this.refs.developAddFormsAccount.getForm();
        let formsBase = this.refs.developAddFormsBase.getForm();
        let formsOrigin = this.refs.developAddFormsOrigin.getForm();
        let formsWork = this.refs.developAddFormsWork.getForm();
        let submitAccount = this.onSubmit(formsAccount);
        let submitBase = this.onSubmit(formsBase);
        let submitOrigin = this.onSubmit(formsOrigin);
        let submitWork = this.onSubmit(formsWork);
        if (formsAccount === 'stop') return;
        submitOrigin.partyName = partyName;
        submitBase.enclosureList = enclosureList;
        //workList 对象转数组，并拼接到params
        let arr = [];
        arr.push(submitWork)
        let workList = {};
        workList.jobInfoList = arr;
        //获取党组织根节点
        submitOrigin.rootStructureId = rootStructureId;
        submitOrigin.branchStructureId = branchStructureId;

        //新增   
        this.setState({ loading: true });
        let _params = Object.assign({}, submitAccount, submitBase, submitOrigin, workList)
        //console.log(_params)
        pcService.formSubmit(urls.app.partyMember.addEntity, true, _params, null, (data) => {
            //console.log(data)
            message.success("操作成功!");
            this.setState({ loading: false });
            let panelId = pcService.getIdByPath("/app/partyMemberAdd");
            pcService.removePanel(panelId, true, '/app/partyMember');
        }, (err) => {
            message.error(err);
            this.setState({ loading: false });
        });
    }
    handleCancel = () => {
        let panelId = pcService.getIdByPath("/app/partyMemberAdd");
        pcService.removePanel(panelId, true, '/app/partyMember');
    }
    getSex = (data) => {
        if (data.length < 17) return;
        let sex;
        if (parseInt(data.substr(16, 1)) % 2 == 1) {
            pcService.getDataDictionary("SEX").map(v => {
                if (v.name === "男") {
                    sex = v.id.toString();
                }
            })
        } else {
            pcService.getDataDictionary("SEX").map(v => {
                if (v.name === "女") {
                    sex = v.id.toString();
                }
            })
        }
        return sex;
    }
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                let lists = treeData ? treeData : [];
                //获取党组织根节点
                let rootStructureId;
                lists.length && lists.map(v => {
                    v.id = v.structureId;
                    v.name = v.structureName;
                    if (v.pLevel === "0") {
                        rootStructureId = v.id;
                    }
                });
                this.fieldsListOrigin.map(v => {
                    if (v.name === "structureId") {
                        v.opts = lists;
                    }
                })

                this.setState({ rootStructureId })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    componentDidMount() {
        //权限管理
        this.initRoleData();
        this.getOrganizationTree();
    }
    render() {
        const { numberStamp, fieldsList, timeList, columns } = this.state;
        const { nextStamp, fieldsListBase, fieldsListAccount, fieldsListOrigin, fieldsListWork, handleSave, handleCancel } = this;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };

        return (
            <div className="pm-add">
                <TimeLine total={4} progress={numberStamp} timeList={timeList} />
                <div className="pma-panels">

                    <div className="pma-panel" style={{ display: numberStamp === 1 ? "block" : "none" }}>
                        <Forms ref="developAddFormsBase" formClassName={"developAdd-form"} columns={columns} fieldsList={fieldsListBase} hideFooter formItemLayout={formItemLayout} />
                    </div>
                    <div className="pma-panel" style={{ display: numberStamp === 2 ? "block" : "none" }}>
                        <Forms ref="developAddFormsOrigin" formClassName={"developAdd-form"} columns={columns} fieldsList={fieldsListOrigin} hideFooter formItemLayout={formItemLayout} />
                    </div>
                    <div className="pma-panel" style={{ display: numberStamp === 3 ? "block" : "none" }}>
                        <Forms ref="developAddFormsWork" formClassName={"developAdd-form"} columns={columns} fieldsList={fieldsListWork} hideFooter formItemLayout={formItemLayout} />
                    </div>
                    <div className="pma-panel" style={{ display: numberStamp === 4 ? "block" : "none" }}>
                        <Forms ref="developAddFormsAccount" formClassName={"developAdd-form"} columns={columns} fieldsList={fieldsListAccount} hideFooter formItemLayout={formItemLayout} />
                    </div>
                    <div className={"developAdd-btn"}>
                        {
                            numberStamp === 1 ? <Button onClick={handleCancel} icon="redo">取消</Button> : <Button onClick={nextStamp.bind(this, "last")} type="primary">上一步</Button>
                        }
                        {
                            numberStamp === 4 ? <Button onClick={handleSave} type="primary" icon="check">确定</Button> : <Button onClick={nextStamp.bind(this, "next")} type="primary">下一步</Button>
                        }

                    </div>
                </div>


            </div>
        )
    }
}

export default PartyMemberAdd;
