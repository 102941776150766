import React from 'react';
import { Row, Col, Card, Tree, Button, Spin, message } from 'antd';
// import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane, removeTabPane } from '../../../action/menuTab.action';
import { get, post } from './../../../common/axios';
import appConfig from './../../../configs/app.config';
import apiConfig from './../../../configs/api.config';
import { isNotEmpty, convertListToTreeJson } from '../../../common/utils';
import pcService from './../../../other/pc.service';
import constantConfig from './../../../configs/constant.config';

const { TreeNode } = Tree;

class SetOrganizations extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            user: {},
            treeData: [],
            checkedKeys: []
        };
    }

    componentWillMount() {
        if (this.props.location.query.userId) {
            this.init(this.props.location.query.userId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user && nextProps.user.userId !== this.props.user.userId) {
            this.setState({
                user: nextProps.user
            }, () => {
                this.initUserOrgs();
            })
        }
    }

    init = (userId) => {
        this.initUserData(userId);
    }

    initUserData = (userId) => {
        get({
            url: apiConfig.rui.basic.user.findById,
            params: {
                userId: userId
            }
        }).then((res) => {
            if (res) {
                this.setState({
                    loading: false,
                    user: {
                        ...res
                    }
                }, this.initTreeData);
            }
        })
    }

    initTreeData = () => {
        this.setState({ loading: true });
        get({
            url: apiConfig.rui.basic.organization.getAllOrganizations,
            params: {
                pageIndex: 0,
                pageSize: appConfig.maxPageSize
            }
        }).then((res) => {
            let orgs = [];
            if (res && res.list && res.list.length > 0) {
                orgs = [
                    ...res.list
                ];
            }
            let treeData = convertListToTreeJson(orgs, '0', 'subStructure', 'structureId', 'pLevel');
            this.setState({
                loading: false,
                treeData
            }, () => {
                this.initUserOrgs();
            });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    initUserOrgs = () => {
        this.setState({ loading: true });
        get({
            url: apiConfig.rui.basic.user.findById,
            params: {
                userId: this.state.user.userId
            }
        }).then((res) => {
            let checkedKeys = [];
            if (res && res.structure) {
                for (let resItem of res.structure) {
                    if (resItem) {
                        checkedKeys.push(resItem.structureId);
                    }
                }
            }
            this.setState({
                loading: false,
                checkedKeys
            });
        }).catch((error) => {
            this.setState({ loading: false });
        })
    }

    renderTreeNodes = data => data.map((item) => {
        if (item.subStructure) {
            return (
                <TreeNode title={item.structureName} key={item.structureId} value={item.structureId} dataRef={item}>
                    {this.renderTreeNodes(item.subStructure)}
                </TreeNode>
            );
        }
        return <TreeNode title={item.structureName} key={item.structureId} value={item.structureId} dataRef={item} />;
    });


    handleTreeCheck = (checkedKeys, info) => {
        this.setState({
            checkedKeys: checkedKeys
        });
    }

    handelCancel = () => {
        this.goBack();
    }

    handelSave = () => {
       /* let structureIds = '';
        for (let checkKey of this.state.checkedKeys.checked) {
            structureIds += checkKey + ','
        }

        if (isNotEmpty(structureIds)) {
            structureIds = structureIds.substr(0, structureIds.length - 1);
        }
        this.setState({ loading: true });*/
        post({
            url: apiConfig.app.organization.resetStructuresForParty,
            params: {
                userId: this.state.user.userId,
                structureIds:this.state.checkedKeys.checked[0]
            }
        }).then((res) => {
            this.setState({
                loading: false
            }, () => {
                message.success(constantConfig.SAVE_SUCCESS);
                this.goBack();
            });
        }).catch((error) => {
            message.error(constantConfig.SAVE_FAIL);
            this.setState({ loading: false });
        })
    }


    goBack = () => {
        let panelId = pcService.getIdByPath("/app/setOrganizationsForParty");
        pcService.removePanel(panelId, true, '/app/userList');
        //this.props.removeTabPane(this.props.paneKey);
    }

    render() {
        let { loading, treeData, checkedKeys, user } = this.state;
        return (
            <Spin spinning={loading}>
                <Card bordered={false}>
                    <div className="operationTitle" style={{ justifyContent: 'center' }}>
                        <h1>
                            {`设置【${user.name}】的组织机构`}
                        </h1>
                    </div>
                    <Row gutter={24}>
                        <Col span={6} offset={4}>
                            {/* <Scrollbars style={{ width: 800, height: 400}}> */}
                            <Tree checkStrictly={true}
                                checkable
                                checkedKeys={checkedKeys}
                                onCheck={this.handleTreeCheck}
                            >
                                {this.renderTreeNodes(treeData)}
                            </Tree>
                            {/* </Scrollbars> */}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} className="operationArea">
                            <Button onClick={this.handelCancel}>取消</Button>
                            <Button type="primary" loading={loading} onClick={this.handelSave}>保存</Button>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        )
    }
}


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SetOrganizations);