import React, { Component } from 'react';
import { DatePicker } from 'antd';

class YearPicker extends Component {

    state = {
        isopen: false
    }

    render() {
        const { isopen } = this.state;
        const { value = {}, onChange, disabled, placeholder, item = {}, form } = this.props;
        const yearPickerOnchange = (_value) => {
            onChange(_value)
            item.onChange(item, form, _value);
        };
        const onPanelChange = (value) => {
            onChange(value);
            item.onChange(item, form, value);
            this.setState({ isopen: false })
        }

        const onOpenChange = (status) => {
            if (status) {
                this.setState({ isopen: true })
            } else {
                this.setState({ isopen: false })
            }
        }
        return (
            <DatePicker value={value} open={isopen} mode="year" disabled={disabled} placeholder={placeholder}
                format="YYYY" onOpenChange={onOpenChange} onPanelChange={onPanelChange} onChange={yearPickerOnchange}
            />
        )
    }
}

export default YearPicker;
