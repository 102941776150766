import React from 'react';
import { Button,Divider} from 'antd';
import {isNotEmpty} from '../common/utils';
import {getCurrentUser} from '../configs/user.service';
import BaseComponent from "./BaseComponent";

/**
 * a标签组件
 * @param props
 * @return {*}
 * @constructor
 */
const ALink=(props)=>{
    const {isneeddivider}=props;
    return (
        <>
            <a {...props}>{props.children}</a>
            {
                isneeddivider&&<Divider type="vertical"/>
            }
        </>
    ) 
};


/**
 * 判断功能权限高级组件
 * @param {*} OriComponent 原组件
 */
const AuthWidget=(OriComponent)=>
    class WrapComponent extends BaseComponent
    {

        checkAuth=()=>{
            let {authCode}=this.props;
            let isHasAuth=true;
            if(isNotEmpty(authCode))
            {
                let currentUser=getCurrentUser();
                //let authorities=currentUser.authorities;
                //console.log("authorities===>",authorities);
                /*if(authorities.indexOf(authCode)===-1)
                {
                    isHasAuth=false;
                }*/
            }
            return isHasAuth;
        };

        render()
        {
            const {authCode,...others}=this.props;
            if(this.checkAuth(authCode))
            {
               if(others.children)
               {
                   return <OriComponent { ...others} >{others.children}</OriComponent>
               }
               else
               {
                   return <OriComponent { ...others} />
               }
            }
            else
            {
                return null;
            }
        }
    };


/**
 * Button判断功能权限高级组件
 */
const ButtonAuth=AuthWidget(Button);

/**
 * a标签判断功能权限高级组件
 */
const ALinkAuth=AuthWidget(ALink);

export {AuthWidget,ButtonAuth,ALinkAuth}
