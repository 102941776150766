import React, { Component } from 'react';
import { Modal } from 'antd';
import PositionPicker from "./positionPicker";
import './positionPicker.less';

class PositionPickerModal extends Component {

    state = {
        point: {},
        errorRange: 0
    };

    useCallback = (point = {}, errorRange = 0) => {
        this.setState({
            point, errorRange
        })
    }

    render() {
        const { mapModal, value } = this.props;
        const { point, errorRange } = this.state;
        const { useCallback } = this;
        const msOnOk = (...args) => {
            mapModal.onOk(point, errorRange, ...args);
        }
        return (
            <Modal  {...mapModal} onOk={msOnOk}>
                <PositionPicker useCallback={useCallback} />
            </Modal>);
    };
}

export default PositionPickerModal;