import React, { Component } from 'react';
import { Input, Select, Radio, Checkbox, DatePicker, TreeSelect, InputNumber } from 'antd';
import TableSelect from '../table-select/TableSelect';
import FileUpLoad from './FileUpLoad';
import PicturesWall from './PicturesWall';
import BEditor from '../BEditor/BEditorOfSL';
import PositionContainer from '../position/PositionContainer';
import AddOnForm from '../addOnForm/AddOnForm';
import moment from 'moment';
import NodeSelect from '../nodeSelect/NodeSelect';
import UploadAttachments from './UploadAttachments';
import urls from './../../../configs/api.config';
import VideoWall from './VideoWall';
import YearPicker from '../yearPicker/YearPicker';
import ImageAndView from '../imageAndView/ImageAndView';

const { TreeNode } = TreeSelect;
const { RangePicker } = DatePicker;

class FormsItem extends Component {

    itemEdit = (item) => {
        let name = item.value;
        let list = [];
        if (item.opts) {
            list = item.opts;
            list.map(v => {
                if (v.id === item.value) {
                    name = v.name
                }
            })
        }
        return name;
    };

    render() {
        const { item, value, onChange, form } = this.props;
        const { opts = [], displayName, unavailable, numberOptions = {}, ptOption = {} } = item;
        const { viewClick = null } = ptOption;

        const inputOnChange = (_item, _form, _value, option) => {
            onChange(_value);
            _item.onChange && _item.onChange(_item, form, _value, option);
        };
        const selectOnchange = (_item, _form, _value) => {
            onChange(_value);
            _item.onChange && _item.onChange(_item, form, _value, opts);
        };
        const radioOnChange = (_item, $event) => {
            let value = $event.target.value;
            onChange(value);
            _item.onChange && _item.onChange($event, form, value);
        };
        const checkboxOnChange = (item, checkedValue) => {
            onChange(checkedValue);
            item.onChange && item.onChange(checkedValue, form, item, opts);
        };
        const treeOnChange = (_item, _value, $event) => {
            onChange(_value);
            _item.onChange && _item.onChange(_item, _value, opts, $event);
        };
        const pwdOnChange = ($event) => {
            let _value = $event.target.value;
            onChange(_value);
            item.onChange && item.onChange(onChange, form, _value);
        }

        /* 时间控件限制 */
        let dateDisabledDate = (item = {}, value) => {
            if (item.minDate) {
                let minDate = form.getFieldValue(item.minDate);
                if (!(minDate && value)) {
                    return false;
                }
                return minDate.valueOf() > value.valueOf();
            }
            if (item.maxDate) {
                let maxDate = form.getFieldValue(item.maxDate);
                if (!(maxDate && value)) {
                    return false;
                }
                return maxDate.valueOf() < value.valueOf();
            }
            if (item.minToday) {
                return value && value <= moment().endOf('day');
            }
            if (item.maxToday) {
                return value && value >= moment().endOf('day');
            }
        }
        let dateDisabledRange = (item, value) => {
            if (item.minDate) {
                let minDateField = item.minDate;
                let minDate = form.getFieldValue(minDateField);
                if (!minDate || !value) {
                    return false;
                } else return minDate[1].valueOf() > value.valueOf();
            }
            if (item.maxDate) {
                let maxDateField = item.maxDate;
                let maxDate = form.getFieldValue(maxDateField);
                if (!maxDate || !value) {
                    return false;
                } else return maxDate[1].valueOf() <= value.valueOf();

            }
        }
        const datePickerOnChange = (_date, _value) => {
            onChange(_date);
            item.onChange && item.onChange(item, form, _value);
        }
        let renderTreeNodes = data =>
            data.map((item) => {
                let child = item.subStructure || item.children;
                let key = item.key ? item.key : item.structureId;
                let title = item.structureName || item.title;
                if (child) {
                    return (
                        <TreeNode title={title} key={key} value={key} dataRef={item}>
                            {renderTreeNodes(child)}
                        </TreeNode>
                    );
                }
                return <TreeNode title={title} key={key} value={key} dataRef={item} isLeaf />;
            })

        /* 渲染节点 */
        // opts=[{ id:1, name:"张三", children:[] }];
        const renderNodeOpts = (opts = []) => opts.map((item, index) => (
            <TreeNode selectable={item.selectable} value={item.id} title={item.name} key={item.id}>
                {renderNodeOpts(item.children || [])}
            </TreeNode>
        ));

        const renderTreeData = (opts = []) => {
            return opts.map(v => ({
                ...v,
                title: v.name,
                value: v.id,
                key: v.id,
                communistInfoList: v.communistInfoList && v.communistInfoList.length > 0 ? v.communistInfoList.map(v => ({
                    ...v,
                    title: v.name,
                    value: v.id,
                    key: v.id
                })) : [],
                children: renderTreeData(v.children),
            })
            )
        };

        const numberItemOption = (range = []) => {
            let _prop = {};
            if (range[0] !== undefined) {
                _prop["min"] = range[0]
            }
            if (range[1] !== undefined) {
                _prop["max"] = range[1]
            }
            return _prop;
        }


        return ((item) => {
            switch (item.editor) {
                case "div":
                    return <div className="formDiv">{this.itemEdit(item)}</div>;
                case "normal":
                    return <Input disabled={unavailable} {...item.options} placeholder={'请输入' + displayName}
                        value={value} onChange={inputOnChange.bind(this, item, form)} />
                case "password":
                    return <Input.Password disabled={unavailable} {...item.pwdOptions} placeholder={'请输入' + displayName}
                        value={value} onChange={pwdOnChange.bind(this)} />
                case "number":
                    return <InputNumber disabled={unavailable} placeholder={'请输入' + displayName} value={value} onChange={onChange} {...numberOptions} {...numberItemOption(item.range)} />
                case "text":
                    return (
                        <div className="ant-form-item-control">
                            <span className="ant-form-item-children">
                                {item.render ? item.render(value) : <div className="ant-input pc-noborder">{value}</div>}
                            </span>
                        </div>
                    )
                case "select":
                    return (
                        <Select getPopupContainer={triggerNode => triggerNode.parentElement} allowClear {...item.selectOption} disabled={unavailable} placeholder={'请选择' + displayName} value={value} onChange={selectOnchange.bind(this, item, form)}>
                            {
                                opts.map((item, index) => <Select.Option disabled={item.disabled} key={index} value={item.id}>{item.name}</Select.Option>)
                            }
                        </Select>
                    )
                case "tree-select":
                    return (
                        <TreeSelect allowClear disabled={unavailable}   {...item} value={value} allowClear onChange={treeOnChange.bind(this, item)} showSearch
                            placeholder={'请选择' + displayName} treeNodeFilterProp='title'>
                            {renderTreeNodes(opts)}
                        </TreeSelect>
                    )
                case "node-select":
                    return (<div className="nodeSelect">
                        <NodeSelect item={item} form={form} onChange={onChange} value={value} />
                    </div>)
                case "radio":
                    return (
                        <Radio.Group disabled={unavailable} value={value} onChange={radioOnChange.bind(this, item)}>
                            {
                                opts.map((item, index) => <Radio key={index} value={item.id}>{item.name}</Radio>)
                            }
                        </Radio.Group>
                    )
                case "textarea":
                    return <Input.TextArea disabled={unavailable} placeholder={'请输入' + displayName} value={value}
                        onChange={onChange} />
                case "checkbox":
                    return (
                        <Checkbox.Group disabled={unavailable} value={value} onChange={checkboxOnChange.bind(this, item)}>
                            {
                                opts.map((item, index) => <Checkbox key={index} value={item.id}>{item.name}</Checkbox>)
                            }
                        </Checkbox.Group>
                    )
                case "table-select":
                    return <TableSelect disabled={unavailable} item={item} form={form} value={value} onChange={onChange} />
                case "file-upload":
                    return <FileUpLoad disabled={unavailable} item={item} form={form} value={value} onChange={onChange} />
                case "attachments":
                    return <UploadAttachments disabled={unavailable} item={item} form={form} value={value} onChange={onChange} />
                case "pictureWall":
                    return <PicturesWall disabled={unavailable} item={item} form={form} value={value} onChange={onChange} />
                case "videoWall":
                    return <VideoWall disabled={unavailable} item={item} form={form} value={value} onChange={onChange} />
                case "pictureText":
                    return <ImageAndView disabled={unavailable} item={item} form={form} value={value} onChange={onChange} />
                case "bEditor":
                    return <BEditor placeholder={'请输入' + displayName} disabled={unavailable} item={item} form={form}
                        value={value} onChange={onChange} />
                case "datePicker":
                    return <DatePicker disabled={unavailable} placeholder={'请选择' + displayName} form={form}
                        value={value} onChange={datePickerOnChange} {...item.timeOptions}
                        disabledDate={dateDisabledDate.bind(this, item)} />
                case "yearPicker":
                    return <YearPicker placeholder={'请选择' + displayName} disabled={unavailable} item={item} form={form} value={value} onChange={onChange} />
                case "rangePicker":
                    return <RangePicker disabled={unavailable} form={form} value={value}
                        onChange={onChange} {...item.timeOptions}
                        disabledDate={dateDisabledRange.bind(this, item)} />
                case "hidden":
                    return <Input value={value} onChange={onChange} />
                case "position":
                    return <PositionContainer placeholder={'请选择' + displayName} disabled={unavailable} value={value}
                        onChange={onChange} item={item} form={form} />
                case "addOn":
                    return <AddOnForm disabled={unavailable} value={value} placeholder={'请选择' + displayName}
                        onChange={onChange} item={item} form={form} />
                default:
                    return <Input disabled={unavailable} placeholder={'请输入' + displayName} value={value} onChange={onChange} />
            }
        })(item)
    }
}

export default FormsItem;
