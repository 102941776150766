import React, { Component } from 'react';
import { Breadcrumb, Button, Dropdown, Icon, Menu, message, Modal, Radio, Select, Spin, Table, Tree, Upload, Divider, Popconfirm } from 'antd';
import './sourceManage.css';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import pcService from "./../../other/pc.service";
import TreeNewForm from './TreeNewForm';
import SearchForms from './SearchForms';
import documentImg from '../../img/fileImg/icon/document.png';
import pngImg from '../../img/fileImg/icon/png.png';
import docImg from '../../img/fileImg/icon/doc.png';
import mp3Img from '../../img/fileImg/icon/mp3.png';
import mp4Img from '../../img/fileImg/icon/mp4.png';
import pdfImg from '../../img/fileImg/icon/pdf.png';
import pptImg from '../../img/fileImg/icon/ppt.png';
import rarImg from '../../img/fileImg/icon/rar.png';
import txtImg from '../../img/fileImg/icon/txt.png';
import xlsImg from '../../img/fileImg/icon/xls.png';
import pngImgIcon from '../../img/fileImg/icon/icon-png.png';
import docImgIcon from '../../img/fileImg/icon/icon-doc.png';
import mp3ImgIcon from '../../img/fileImg/icon/icon-mp3.png';
import mp4ImgIcon from '../../img/fileImg/icon/icon-mp4.png';
import pdfImgIcon from '../../img/fileImg/icon/icon-pdf.png';
import pptImgIcon from '../../img/fileImg/icon/icon-ppt.png';
import rarImgIcon from '../../img/fileImg/icon/icon-rar.png';
import txtImgIcon from '../../img/fileImg/icon/icon-txt.png';
import xlsImgIcon from '../../img/fileImg/icon/icon-xls.png';
import selectOff from '../../img/fileImg/select_off.png';
import selectOn from '../../img/fileImg/select_on.png'
import { getLocal } from "../../common/utils";

const Dragger = Upload.Dragger;
const DirectoryTree = Tree.DirectoryTree;
const { TreeNode } = Tree;
let bzBodyId = getLocal("structureList") ? (getLocal("structureList").length ? getLocal("structureList")[0].id : "") : "";
const icee_name = getLocal("user_info") ? getLocal("user_info").name : "iceename";

class SourceManage extends Component {

    state = {
        msg: '文档管理',
        treeList: [],
        tableData: [],
        tableToggleData: [],
        historyTableData: [],
        subTypeForSearch: [],
        filteredInfo: null,
        sortedInfo: null,
        bzBody: [],
        bzBodyDefault: '0',
        loading: false,
        indicatorId: "0", //树形选中指标id
        updateTree: '',
        currentTreeId: '',
        uploadDoneList: [],//上传成功返还id
        importFileList: [],
        uploading: true,//上传中
        treeformList: [],
        visible: false, //文件上传模态框
        visibleTree: false, //新增文件模态框
        titleTree: '修改指标',
        visibleHistory: false, //历史记录模态框
        radioValue: 'no',
        deleteFromWhere: '',
        deleteText: '',
        rememberHistoryTableId: '',
        treeRename: false, //树形修改
        visibleRename: false, //重命名模态框
        visibleMoveTo: false,//移动到
        visibleDelete: false,//删除确认按钮
        indicatorMoveToId: '',
        inputValue: "",
        rangePickerValue: "",
        breadCrumbList: [],//面包屑List
        treeFirstParentId: '',//树形最上层id
        toggleCurrentParentId: '',//切换视图当前父ID
        renameList: [], //重命名模态框
        renameName: '',
        displayTip: 'none',//控制点点点事件
        toggleView: 'none',//控制切换视图事件
        selectToggleAllFlag: 1,
        displayToggle: 'none',
        toggleTable: 'flex',
        totalTogglePage: 0,
        isDisabled: true,
        isDisabledUpload: true,
        isBatchDownload: 'none',
        selectedRowKeys: [],//table行选
        srcIndicatorId: "",//剪切复制文件ID
        srcFileIds: "",//剪切复制指标ID
        beCut: "",//是否剪切
        // selectToggleImg: selectOff,
        rightClickNodeTreeItem: {
            pageX: "",
            pageY: "",
            id: "",
            categoryName: "",
        },
        size: 1,
        pageSize: 10,
        total: 0,
        current: 0
    }

    /* 页码跳转回调函数 */
    handleChange = (pagination, filters, sorter) => {
        this.setState({
            current: pagination.current - 1,
            pageSize: pagination.pageSize,
            filteredInfo: filters,
            sortedInfo: sorter,
        });

    }
    //切换table行颜色
    setRowClassName = (record) => {
        return record.id === this.state.srcFileIds ? 'selectRowStyl' : '';
    }
    /* 获取指标列表 */
    getTree = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.sourceManage.getIndicatorTree
        }).then(res => {
            if (res.success == true) {
                this.setState({
                    treeList: res.data,
                    treeFirstParentId: res.data && res.data !== [] ? res.data[0].key : ""
                });
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            // message.error(err.message);
            this.setState({ loading: false });
        });

    }

    //判断bzBodyId是否存在
    existDirByBzBodyId = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.sourceManage.existDirByBzBodyId,
            params: {
                bzBodyId: bzBodyId
            }
        }).then(res => {
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ loading: false });
        });
    }

    //获取table
    getTable = (inputValue) => {
        let inputData = "";
        let beginDate = "";
        let endDate = "";
        let subType = "";

        if (inputValue) {
            if (inputValue.name) {
                inputData = inputValue.name;

            }
            if (inputValue.rangePicker) {
                beginDate = new Date(inputValue.rangePicker[0]._d);
                endDate = new Date(inputValue.rangePicker[1]._d);
                beginDate = new Date(beginDate)
                beginDate = beginDate.getFullYear() + '-' + (beginDate.getMonth() + 1) + '-' + beginDate.getDate();
                endDate = new Date(endDate)
                endDate = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
            }
            if (inputValue.subtype)
                subType = inputValue.subtype[0];
            if (inputValue.name)
                inputData = inputValue.name;
        }
        this.setState({ loading: true });
        //console.log(this.state.treeFirstParentId)
        http.request({
            method: "get",
            url: urls.app.infoFile.page,
            params: {
                bzBodyId: bzBodyId,
                name: inputData,
                beginDate: beginDate,
                endDate: endDate,
                suffix: subType,
                indicatorId: this.state.indicatorId && this.state.indicatorId !== "0" && this.state.treeFirstParentId !== this.state.indicatorId
                    ? this.state.indicatorId : undefined
            }
        }).then(res => {
            if (res.success == true) {
                const resData = res.data.rows;
                //渲染后台data
                let dataTemp = [];
                if (resData.length > 0) {
                    for (let i = 0; i < resData.length; i++) {
                        //判断图片类型
                        let iconAddress;
                        iconAddress = this.getSuffix(resData[i].suffix);

                        dataTemp.push(
                            {
                                displayTip: 'none',
                                key: i + 1,
                                number: i + 1,
                                id: resData[i].id,
                                name: resData[i].name,
                                suffix: resData[i].suffix,
                                size: resData[i].size,
                                sizeStr: resData[i].sizeStr,
                                infoFileMongoId: resData[i].infoFileMongoId,
                                creator: resData[i].creator,
                                createdTime: resData[i].createdTime,
                                updatedTime: resData[i].updatedTime,
                                id: resData[i].id,
                                iconAddress: iconAddress,
                            }
                        );
                    }

                    this.setState({
                        total: res.data.totalCount,
                        tableData: dataTemp
                    });
                } else {
                    this.setState({
                        total: 0,
                        tableData: dataTemp
                    });
                }

            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });

    }

    //判断图片类型
    getSuffix = (resSuffix) => {
        let iconAddress;
        switch (resSuffix) {
            case ".doc":
            case '.docx':
                iconAddress = docImgIcon;
                break;
            case ".jpg":
            case ".gif":
            case ".png":
            case ".bmp":
                iconAddress = pngImgIcon;
                break;
            case ".mp3":
                iconAddress = mp3ImgIcon;
                break;
            case ".mp4":
                iconAddress = mp4ImgIcon;
                break;
            case ".pdf":
                iconAddress = pdfImgIcon;
                break;
            case ".ppt":
            case ".pptx":
                iconAddress = pptImgIcon;
                break;
            case ".txt":
                iconAddress = txtImgIcon;
                break;
            case ".xls":
            case ".xlsx":
                iconAddress = xlsImgIcon;
                break;
            case ".zip":
            case ".rar":
                iconAddress = rarImgIcon;
                break;
            default:
                iconAddress = pngImgIcon;
        }
        return iconAddress;
    }


    //table行选
    onSelectChange = (selectedRowKeys) => {
        if (selectedRowKeys.length) {
            this.setState({
                isDisabled: false,
                isBatchDownload: 'inline-block',
                selectedRowKeys
            });
        } else {
            this.setState({
                isDisabled: true,
                isBatchDownload: 'none',
                selectedRowKeys
            });
        }
    }


    //新增model
    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    handleOk = (e) => {
        const { uploading, uploadDoneList = [], indicatorId = [] } = this.state;
        if (!uploadDoneList.length) {
            message.info("请选择上传文件");
            return;
        }
        if (uploading) return;

        let indicatId = indicatorId || indicatorId[0];
        if (this.state.toggleCurrentParentId) {
            indicatId = this.state.toggleCurrentParentId;
        }
        let dataTemp = [];
        let infoMongoIdList = [];
        for (let i = 0; i < uploadDoneList.length; i++) {
            if (!uploadDoneList[i].response) {
                message.info("请耐心等待文件上传");
                return;
            }
            dataTemp.push(
                {
                    structureId: bzBodyId,
                    indicatorId: indicatId,
                    infoFileMongoId: uploadDoneList[i].response,
                    name: uploadDoneList[i].name,
                    size: uploadDoneList[i].size,
                    suffix: uploadDoneList[i].name.substring(uploadDoneList[i].name.lastIndexOf('.'), uploadDoneList[i].name.length),
                    operator: icee_name,
                    creator: icee_name,
                }
            );
            infoMongoIdList.push(
                uploadDoneList[i].response
            )
        }
        this.setState({ loading: true });
        //文件上传
        http.request({
            method: "post",
            url: urls.app.infoFile.insertFileRecord,
            data: dataTemp
        }).then(res => {
            if (res.success == true) {
                message.success(`文件上传成功！`);
                this.setState({ uploading: true, });
                if (this.state.indicatorId != 0) {
                    this.onSelect(this.state.indicatorId);
                } else {
                    this.getTable();
                }
                if (this.state.toggleCurrentParentId) {
                    this.onToggleView(this.state.toggleCurrentParentId, 'freshToggle');
                }
            } else {
                message.error(res.message);
                this.setState({ uploading: true, });
                if (infoMongoIdList.length) {
                    infoMongoIdList.forEach(element => {
                        this.errUpload(element)
                    });
                }
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            if (infoMongoIdList.length) {
                infoMongoIdList.forEach(element => {
                    this.errUpload(element)
                });
            }
            this.setState({ loading: false });
        });
        this.setState({
            visible: false,
            uploadDoneList: []
        })
    }
    //文件上传取消，回调MongoDB，删除上传的文件
    handleCancel = (e) => {
        if (this.state.uploadDoneList.length) {
            this.state.uploadDoneList.forEach(element => {
                this.errUpload(element.response)
            });
        }

        this.setState({
            visible: false,
            uploadDoneList: [],
        });
    }
    //删除不上传的文件
    errUpload = (deleteMongoId) => {
        this.setState({ loading: true });
        http.request({
            method: "delete",
            url: urls.file.deleteMongo + '/' + deleteMongoId
        }).then(res => {
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    //捕捉文件上传事件
    handleUploadChange = (info) => {
        this.setState({
            uploadDoneList: info.fileList
        })

        const status = info.file.status;
        if (status !== 'uploading') {
        }
        if (status === 'done') {
            this.setState({
                uploading: false
            })


        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

    }

    //tree 刷新table
    onSelect = (keys, event) => {
        const myKey = typeof keys === "object" ? keys[0] : keys;
        // if(!myKey || myKey === this.state.indicatorId ) return;
        if (myKey) {
            this.setState({
                indicatorId: myKey,
                isDisabledUpload: false
            });
        }

        http.request({
            method: "get",
            url: urls.app.infoFile.findAllByIndicatorId,
            params: {
                indicatorId: myKey
            }
        }).then(res => {
            if (res.success == true) {
                const resData = res.data.rows;
                //渲染后台data
                let dataTemp = [];
                if (resData.length > 0) {
                    for (let i = 0; i < resData.length; i++) {
                        let iconAddress;
                        iconAddress = this.getSuffix(resData[i].suffix)

                        dataTemp.push(
                            {
                                displayTip: 'none',
                                key: i + 1,
                                number: i + 1,
                                name: resData[i].name,
                                id: resData[i].id,
                                suffix: resData[i].suffix,
                                size: resData[i].size,
                                sizeStr: resData[i].sizeStr,
                                infoFileMongoId: resData[i].infoFileMongoId,
                                creator: resData[i].creator,
                                createdTime: resData[i].createdTime,
                                updatedTime: resData[i].updatedTime,
                                id: resData[i].id,
                                iconAddress: iconAddress,
                            }
                        );
                    }
                    this.setState({
                        total: res.data.totalCount,
                        tableData: dataTemp,
                        sortedInfo: null,
                        selectedRowKeys: []
                    });
                } else {
                    this.setState({
                        total: 0,
                        tableData: dataTemp,
                        sortedInfo: null,
                        selectedRowKeys: []
                    });
                }

            } else {
                message.error(res.message);
            }
        }).catch(err => {
            message.error(err.message);
        });
    };
    //tree 刷洗tree
    onFreshTree = (parentId) => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.sourceManage.getAllByParent,
            params: {
                bzBodyId: bzBodyId,
                parent: parentId
            }
        }).then(res => {
            if (res.success == true) {
                this.getTree();

            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    //切换视图
    onToggleView = (parentId, numJudgeType) => {
        //清除选择状态
        this.setState({
            selectToggleAllFlag: 1
        })
        if (numJudgeType == 1) return;
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.sourceManage.getAllByParent,
            params: {
                bzBodyId: bzBodyId,
                parent: parentId
            }
        }).then(res => {
            if (res.success == true) {
                const resData = res.data;
                //渲染后台data
                let dataTemp = [];
                if (resData.length > 0) {
                    for (let i = 0; i < resData.length; i++) {
                        let imgAddress;
                        let selectToggleImg = selectOff;
                        let selectToggleKey = 1;
                        if (resData[i].type == '0') {
                            //指标
                            imgAddress = documentImg;
                            selectToggleImg = '';
                            selectToggleKey = 3;
                        }
                        if (resData[i].type == '1') {
                            switch (resData[i].data.suffix) {
                                case ".doc":
                                case '.docx':
                                    imgAddress = docImg;
                                    break;
                                case ".jpg":
                                case ".gif":
                                case ".png":
                                case ".bmp":
                                    imgAddress = pngImg;
                                    break;
                                case ".mp3":
                                    imgAddress = mp3Img;
                                    break;
                                case ".mp4":
                                    imgAddress = mp4Img;
                                    break;
                                case ".pdf":
                                    imgAddress = pdfImg;
                                    break;
                                case ".ppt":
                                case ".pptx":
                                    imgAddress = pptImg;
                                    break;
                                case ".txt":
                                    imgAddress = txtImg;
                                    break;
                                case ".xls":
                                case ".xlsx":
                                    imgAddress = xlsImg;
                                    break;
                                case ".zip":
                                case ".rar":
                                    imgAddress = rarImg;
                                    break;
                                default:
                                    imgAddress = pngImg;
                            }
                        }
                        let resName = resData[i].data.name;
                        dataTemp.push(
                            {
                                displayToggle: 'none',
                                selectToggleImg: selectToggleImg,
                                selectToggleKey: selectToggleKey,
                                key: i + 1,
                                number: i + 1,
                                name: resName,
                                title: resData[i].data.name,
                                id: resData[i].data.id,
                                suffix: resData[i].data.suffix,
                                size: resData[i].data.size,
                                num: resData[i].data.num,
                                level: resData[i].data.level,
                                docuType: resData[i].type,
                                infoFileMongoId: resData[i].data.infoFileMongoId,
                                hasHistory: resData[i].data.hasHistory,
                                updatedTime: resData[i].data.updatedTime,
                                id: resData[i].data.id,
                                imgAddress: imgAddress
                            }
                        );
                    }
                    //排序
                    if (numJudgeType == 'iName') {
                        dataTemp = dataTemp.sort(function (a, b) {
                            return a.title.localeCompare(b.title)
                        })
                    } else if (numJudgeType == 'iTime') {
                        dataTemp = dataTemp.sort(function (a, b) {
                            return a.updatedTime - b.updatedTime;
                        });
                    } else if (numJudgeType == 'iNum') {
                        dataTemp = dataTemp.sort((a, b) => {
                            let aSuffix = this.suffTurnTheSame(a.suffix);
                            let bSuffix = this.suffTurnTheSame(b.suffix);
                            return aSuffix.localeCompare(bSuffix);
                        });
                    }
                    this.setState({
                        tableToggleData: dataTemp,
                        totalTogglePage: resData.length
                    });
                } else {
                    if (this.state.toggleView == 'block') {
                        message.info('指标为空！');
                    }
                    this.setState({
                        tableToggleData: dataTemp,
                        totalTogglePage: 0
                    });
                }

            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
        //面包屑
        this.getBreadCrump(parentId);
    }

    //同一个图片返回同一个类型
    suffTurnTheSame = (getsuffix) => {
        let suffixType;
        switch (getsuffix) {
            case ".doc":
            case '.docx':
                suffixType = ".doc";
                break;
            case ".jpg":
            case ".gif":
            case ".png":
            case ".bmp":
                suffixType = ".jpg";
                break;
            case ".mp3":
                suffixType = ".mp3";
                break;
            case ".mp4":
                suffixType = ".mp4";
                break;
            case ".pdf":
                suffixType = ".pdf";
                break;
            case ".ppt":
            case ".pptx":
                suffixType = ".ppt";
                break;
            case ".txt":
                suffixType = ".txt";
                break;
            case ".xls":
            case ".xlsx":
                suffixType = ".xls";
                break;
            case ".zip":
            case ".rar":
                suffixType = ".zip";
                break;
            default:
                suffixType = getsuffix;
        }
        return suffixType;
    }

    //获取面包屑列表
    getBreadCrump = (parentId) => {
        this.setState({ loading: true });

        http.request({
            method: "get",
            url: urls.app.sourceManage.getParentTreeByIndicatorId,
            params: {
                bzBodyId: bzBodyId,
                indicatorId: parentId
            }
        }).then(res => {
            if (res.success == true) {
                const resData = res.data;
                if (resData.length > 0) {
                    this.setState({
                        breadCrumbList: resData,
                        toggleCurrentParentId: resData[resData.length - 1].id
                    });
                } else {
                    this.setState({
                        breadCrumbList: resData,
                        toggleCurrentParentId: 0
                    });
                }

            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    // tree列表上右键事件
    onRightClick = (e) => {
        this.setState({
            rightClickNodeTreeItem: {
                pageX: e.event.pageX - 140,
                pageY: e.event.pageY - 140,
                id: e.node.props.eventKey,
                categoryName: e.node.props.title,
                style: e.node.props.style
            }
        });
        // todo 目前无法执行左键效果
        this.onSelect(e.node.props.eventKey)
    };
    //初始化渲染右键事件，并隐藏
    getNodeTreeRightClickMenu = () => {
        const { pageX, pageY, id, categoryName } = { ...this.state.rightClickNodeTreeItem };
        const tmpStyle = {
            position: "absolute",
            left: `${pageX - 80}px`,
            top: `${pageY + 25}px`
        };
        const menu = (
            <ul style={tmpStyle} className="tree-right-menu">
                <li onClick={this.buttonTreeNew}>新增</li>
                {
                    this.state.treeFirstParentId == id ? '' : <li onClick={this.buttonTreeDelete}>删除</li>
                }
                {
                    this.state.treeFirstParentId == id ? '' : <li onClick={this.buttonTreeRename}>修改</li>
                }

            </ul>
        );

        return this.isPropsEmpty(this.state.rightClickNodeTreeItem) ? "" : menu;
    };
    isPropsEmpty = (param) => {
        let flag = true;
        for (const key in param) {
            if (param[key]) {
                flag = false;

            }
        }
        return flag;
    }
    //关闭右键按钮
    clearRightMenu = () => {
        let { tableData = [], tableToggleData = [] } = this.state;
        this.setState({
            rightClickNodeTreeItem: {
                pageX: "",
                pageY: "",
                id: "",
                categoryName: ""
            },
        })
        //关闭功能菜单
        tableData.map((v, n) => {
            v.displayTip = "none";
        })
        tableToggleData.map((v, n) => {
            v.displayToggle = "none";
        })
        this.setState({
            tableData,
            tableToggleData
        })
    }
    //渲染树形
    renderTreeNodes = treeList => treeList.map((item) => {
        if (item.children) {
            return (
                <TreeNode title={item.title} key={item.key} style={{ opacity: 1 }}
                    dataRef={item} data-key={item.id} data-title={item.categoryName}>
                    {this.renderTreeNodes(item.children)}
                </TreeNode>
            );
        }
        return <TreeNode {...item} dataRef={item}
            dataRef={item} data-key={item.id} data-title={item.categoryName} />;
    })

    //获取文件类型
    getTypeInfo = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.infoFile.getFileTypeList
        }).then(res => {
            if (res.success == true) {
                this.setState({
                    subTypeForSearch: res.data
                })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    onChangeRadio = (e) => {
        this.setState({
            radioValue: e.target.value,
        });
    }
    //table button
    buttonBatchDelete = () => {
        this.confirmBatchDelete();
    }
    confirmBatchDelete = () => {
        let ids = [];
        this.state.selectedRowKeys.forEach(element => {
            ids.push(element);
        });
        const url = urls.app.infoFile.deleteEntityPhysical;
        this.setState({ loading: true });
        http.request({
            method: "delete",
            url: url,
            data: ids.join(",")
        }).then(res => {
            if (res.success == true) {
                message.success(`删除文件成功！`);
                this.setState({
                    isDisabled: true,
                    selectedRowKeys: []
                })
                if (this.state.indicatorId != 0) {
                    this.onSelect(this.state.indicatorId);
                } else {
                    this.getTable();
                }

            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    //切换视图按钮
    buttonToggleView = () => {
        if (this.state.toggleView == 'none') {
            this.setState({
                toggleTable: 'none',
                toggleView: 'block'
            })
            this.onToggleView(this.state.indicatorId, 'num');
        }
        ;
        if (this.state.toggleView == 'block') {
            this.setState({
                toggleTable: 'flex',
                toggleView: 'none',
                toggleCurrentParentId: ''
            })

        }
    }
    //鼠标移入、移除事件
    onToggleMouseEnter = (id) => {
        let { tableToggleData = [] } = this.state;

        tableToggleData.map((v, n) => {
            if (v.id === id) {
                v.displayToggle = "block";
            } else {
                v.displayToggle = 'none';
            }
        })
        this.setState({
            tableToggleData,
        })

    }
    //指标视图选择事件
    selectToggle = (id) => {
        let { tableToggleData = [] } = this.state;
        tableToggleData.map((v, n) => {
            if (v.id === id && v.selectToggleKey == 1) {
                v.selectToggleImg = selectOn;
                v.selectToggleKey = 2;
            }
            else if (v.id === id && v.selectToggleKey == 2) {
                v.selectToggleImg = selectOff;
                v.selectToggleKey = 1;
            }
            this.setState({
                tableToggleData
            })

        })

    }
    //指标视图全选事件
    selectToggleAll = () => {
        let { tableToggleData = [] } = this.state;
        //selectToggleAllFlag 1全选 2全不选
        if (this.state.selectToggleAllFlag == 1) {
            tableToggleData.map((v, n) => {
                if (!v.num) {
                    v.selectToggleImg = selectOn;
                    v.selectToggleKey = 2;
                    this.setState({
                        selectToggleAllFlag: 2,
                        tableToggleData
                    })
                }
            })
        } else {
            tableToggleData.map((v, n) => {
                if (!v.num) {
                    v.selectToggleImg = selectOff;
                    v.selectToggleKey = 1;
                    this.setState({
                        selectToggleAllFlag: 1,
                        tableToggleData
                    })
                }

            })
        }
    }

    //table button
    buttonDelete = (text, record) => {
        this.confirmDelete(text, true);
    }
    confirmDelete = (text, isDeleteHistory) => {
        const url = urls.getUrl("delFile");
        let data = [];
        data.push({
            id: text.id,
            deleteHistory: isDeleteHistory,
            operator: icee_name
        })
        this.setState({ loading: true });
        //文件类型统计数据
        http.request({
            method: "delete",
            url: url,
            data: data
        }).then(res => {
            if (res.success == true) {
                message.success(`删除文件成功！`);
                if (this.state.indicatorId != 0) {
                    this.onSelect(this.state.indicatorId);
                } else {
                    this.getTable();
                }
                if (this.state.toggleCurrentParentId) {
                    this.onToggleView(this.state.toggleCurrentParentId, 'freshToggle');
                }

            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    buttonToggleBatchDelete = () => {
        let flag = false;
        this.state.tableToggleData.map((v, n) => {
            if (!v.num && v.selectToggleKey == 2) {
                flag = true;
            }
        })
        if (!flag) {
            message.info("未选中任何数据！")
            return;
        }
        this.confirmToggleBatchDelete(true);

    }
    //确认批量删除
    confirmToggleBatchDelete = (isDeleteHistory) => {
        let ids = [];
        this.state.tableToggleData.map((v, n) => {
            if (!v.num && v.selectToggleKey == 2) {
                ids.push(v.id);
            }
        })
        const url = urls.app.infoFile.deleteEntityPhysical;
        this.setState({ loading: true });
        http.request({
            method: "delete",
            url: url,
            data: ids.join(",")
        }).then(res => {
            if (res.success == true) {
                message.success(`删除文件成功！`);
                if (this.state.toggleCurrentParentId) {
                    this.onToggleView(this.state.toggleCurrentParentId, 'freshToggle');
                }

            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    //pageOffice
    buttonView = (text, record) => {
        const windowClient = 'width=1200px;height=700px;';
        if (record.historyFileMongoId) {
            // 历史文件;
            let suffixType = record.suffix;
            let param = '?idOrName=' + record.historyFileMongoId + '&allowEdit=false'
            if ('xlsxdocxpdfppt'.indexOf(suffixType.substring(1)) != -1) {
                let myurl = urls.getPageOfficeUrl("project")
                if (suffixType == '.xls' || suffixType == '.xlsx') {
                    myurl = urls.getPageOfficeUrl("excel")
                    window.openExcel(myurl + param, 'false');
                } else if (suffixType == '.doc' || suffixType == '.docx') {
                    myurl = urls.getPageOfficeUrl("word")
                    window.openWord(myurl + param, 'false');
                } else if (suffixType == '.pdf') {
                    myurl = urls.getPageOfficeUrl("pdf")
                    window.openPdf(myurl + param, 'false');
                } else if (suffixType == '.ppt') {
                    myurl = urls.getPageOfficeUrl("point")
                    window.openPoint(myurl + param, 'false');
                } else {
                    urls.getPageOfficeUrl("visio")
                    window.openVisio(myurl + param, 'false');
                }
            } else {
                window.open(urls.getPageOfficeUrl("views") + '/' + record.historyFileMongoId, 'false');
            }


        } else {
            if (!record.suffix) return;
            if ('xlsxdocxpdfppt'.indexOf(record.suffix.substring(1)) != -1) {

                let myurl = urls.getPageOfficeUrl("project");
                let param = '?idOrName=' + record.infoFileMongoId + '&allowEdit=false';
                if (record.suffix == '.xls' || record.suffix == '.xlsx') {
                    myurl = urls.getPageOfficeUrl("excel")
                    window.openExcel(myurl + param, 'false');
                } else if (record.suffix == '.doc' || record.suffix == '.docx') {
                    myurl = urls.getPageOfficeUrl("word")
                    window.openWord(myurl + param, 'false');
                } else if (record.suffix == '.pdf') {
                    myurl = urls.getPageOfficeUrl("pdf")
                    window.openPdf(myurl + param, 'false');
                } else if (record.suffix == '.ppt') {
                    myurl = urls.getPageOfficeUrl("point")
                    window.openPoint(myurl + param, 'false');
                } else {
                    urls.getPageOfficeUrl("visio")
                    window.openVisio(myurl + param, 'false');
                }
            } else {
                window.open(urls.file.viewImg + '/' + record.infoFileMongoId, 'false');
            }
        }

    }
    //重命名
    buttonRename = (text, record) => {
        this.setState({
            renameName: record.name,
            renameList: record,
            visibleRename: true
        })

    }
    onChangeRename = (event) => {
        this.setState({
            renameName: event.target.value
        })
    }
    renameOk = (e) => {
        let title = this.refs.title.value;

        if (title) {
            let data = {
                id: this.state.renameList.id,
                name: title,
                operator: icee_name
            }
            this.setState({ loading: true });
            http.request({
                method: "post",
                url: urls.app.infoFile.renameFile,
                data: data
            }).then(res => {
                if (res.success == true) {
                    message.success(`重命名文件成功！`);
                    if (this.state.indicatorId != 0) {
                        this.onSelect(this.state.indicatorId);
                    } else {
                        this.getTable();
                    }
                    if (this.state.toggleCurrentParentId) {
                        this.onToggleView(this.state.toggleCurrentParentId, 'freshToggle');
                    }

                } else {
                    message.error(res.message);
                }
                this.setState({ loading: false });
            }).catch(err => {
                message.error(err.message);
                this.setState({ loading: false });
            });

            //表单置为空
            this.refs.title.value = '';
        }
        this.setState({
            visibleRename: false,
        });
    }
    //重命名取消
    renameCancel = (e) => {
        this.setState({
            visibleRename: false,
        });
    }
    buttonDownload = (text, record) => {
        window.open(urls.file.downLoad + '/' + record.infoFileMongoId);
    }
    //tree button
    buttonTreeNew = () => {
        this.setState({
            treeformList: [],
            treeRename: false,
            visibleTree: true,
            titleTree: '新建文件夹',
            currentTreeId: this.state.rightClickNodeTreeItem.id
        })
    }
    treeOk = () => {
        let treeName = '';
        let treeRate = '';
        let treeNum = '';
        let treeBzOrder = '';
        let flag = true;
        this.refs.treeform.validateFields((err, fieldsValue) => {
            if (err) {
                return;
            }
            treeName = fieldsValue.treeName;
            treeRate = fieldsValue.treeRate;
            treeNum = fieldsValue.treeNum;
            treeBzOrder = fieldsValue.treeBzOrder;

            flag = false;

        });

        let treeParentId = this.state.currentTreeId;
        //修改
        if (typeof (this.state.updateTree) == 'string' && this.state.updateTree) {
            treeParentId = this.state.updateTree
        }
        //切换目录
        if (typeof (this.state.toggleCurrentParentId) == 'string' && this.state.toggleCurrentParentId) {
            treeParentId = this.state.toggleCurrentParentId;
        }
        if (!flag && treeParentId && treeParentId != '-1') {
            let data = {};
            if (!this.state.treeRename) {
                data = {
                    name: treeName,
                    bzOrder: treeBzOrder,
                    parentId: treeParentId,
                    structureId: bzBodyId,
                    operator: icee_name
                };
            }
            let treeUrl = urls.app.sourceManage.makeNewFolder;
            if (this.state.treeRename) {
                data = {
                    name: treeName,
                    bzOrder: treeBzOrder,
                    id: treeParentId,
                    structureId: bzBodyId,
                    operator: icee_name
                };
                treeUrl = urls.app.sourceManage.updateFolder;
            }
            this.setState({ loading: true });
            http.request({
                method: "post",
                url: treeUrl,
                data: data
            }).then(res => {
                if (res.success == true) {
                    if (this.state.treeRename) {
                        message.success(`修改指标成功！`);
                        this.setState({ updateTree: "" })
                    } else {
                        message.success(`新建指标成功`);
                    }
                    this.onFreshTree(treeParentId);
                    if (this.state.toggleCurrentParentId) {
                        this.onToggleView(this.state.toggleCurrentParentId, 'freshToggle');
                    }
                    this.setState({
                        visibleTree: false
                    })

                } else {
                    message.error(res.message);
                }
                this.setState({ loading: false });
            }).catch(err => {
                message.error(err.message);
                this.setState({ loading: false });
            });


            //表单置为空
            this.refs.treeform.resetFields();

        }

    }
    treeCancel = () => {
        //表单置为空
        this.refs.treeform.resetFields();

        this.setState({
            visibleTree: false
        })
    }
    buttonTreeDelete = (id) => {
        Modal.confirm({
            title: '删除',
            content: '是否确认删除？',
            onOk: () => {
                this.confirmTreeDelete(id);
            },
            okText: '确认',
            cancelText: '取消',
        });
        if (typeof (id) != 'string') {
            this.setState({
                currentTreeId: this.state.rightClickNodeTreeItem.id
            })
        }

    }
    //确认删除
    confirmTreeDelete = (id) => {
        let deleteId = this.state.currentTreeId;
        if (typeof (id) == 'string') {
            deleteId = id;
        }
        const url = urls.app.sourceManage.delFileIndi;
        let dateList = [{
            id: deleteId,
            operator: icee_name
        }]
        //文件类型统计数据
        this.setState({ loading: true });
        http.request({
            method: "delete",
            url: url,
            data: dateList
        }).then(res => {
            if (res.success == true) {
                message.success(`删除指标成功！`);
                this.setState({
                    indicatorId: 0
                })
                this.onFreshTree(this.state.rightClickNodeTreeItem.id);
                if (this.state.toggleCurrentParentId) {
                    this.onToggleView(this.state.toggleCurrentParentId, 'freshToggle');
                }

            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    buttonTreeRename = (id) => {
        let myid = this.state.rightClickNodeTreeItem.id;
        if (typeof (id) == 'string' && id) {
            myid = id;
        }
        this.setState({ loading: true });
        //获取当前指标属性,模态框赋值
        http.request({
            method: "get",
            url: urls.app.sourceManage.findById,
            params: {
                id: myid
            }
        }).then(res => {
            if (res.success == true) {
                this.setState({
                    treeformList: res.data
                })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });

        this.setState({
            updateTree: myid,
            visibleTree: true,
            treeRename: true,
        })
    }
    //排序
    handleMenuClick = (e) => {
        this.onToggleView(this.state.toggleCurrentParentId, e.key);
    }
    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visibleDelete) => {
        this.setState({ visibleDelete: allowDelete && visibleDelete });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visibleDelete: false });
    }

    componentDidMount() {
        this.getTree();
        this.getTable();
        this.getNodeTreeRightClickMenu();
        this.getTypeInfo();
    }

    render() {
        const { bzBody, loading, indicatorId, selectedRowKeys, tableData = [], tableToggleData = [], breadCrumbList = [], isBatchDownload, isDisabled, isDisabledUpload, importFileList = [] } = this.state;
        let { sortedInfo, filteredInfo, visibleDelete } = this.state;
        sortedInfo = sortedInfo || {};
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const Option = Select.Option;
        const columns = [
            {
                title: '序号',
                dataIndex: 'number',
                width: '80px',
                sorter: (a, b) => a.number - b.number,
                key: 'number',
                sortOrder: sortedInfo.columnKey === 'number' && sortedInfo.order,
                // fixed: 'left',
            }, {
                title: '操作',
                width: '300px',
                key: 'action',
                // fixed: 'left',
                render: (text, record) => {
                    return (
                        <span>
                            <a href="javascript:;" onClick={this.buttonDownload.bind(this, text, record)}><Icon type="download" title="下载" /> 下载 </a>
                            <Divider type="vertical" />
                            {
                                //.rar,.doc,.docx,.zip,.pdf,.txt,.swf,.xlsx,.gif,.png,.jpg,.jpeg,.bmp,.xls,.mp4,.flv,.ppt,.avi,.mpg,.wmv,.3gp,.mov,.asf,.asx,.vob,.wmv9,.rm,.rmvb,.mp3,.mp4,.3gp,.mpg,.avi,.wmv,.flv,.swf,.jpg,.bmp,.png,.tif,.ico,.gif,.tga,.mp3,.wma,.amr,.ogg,.aac,.wav
                                ".gif,.png,.jpg,.jpeg,.bmp,.mp4,.mp3".indexOf(record.suffix) !== -1 ? <a href="javascript:;" onClick={this.buttonView.bind(this, text, record)}><Icon type="eye" title="预览" /> 预览</a> : null
                            }
                            <Divider type="vertical" />
                            {/* <a href="javascript:;" onClick={this.buttonDelete.bind(this, text, record)}><Icon type="delete" title="删除" /> 删除</a>
                        <Divider type="vertical" /> */}
                            <a href="javascript:;" onClick={this.buttonRename.bind(this, text, record)}><Icon type="profile" title="重命名" /> 重命名</a>
                        </span>
                    )
                },
            }, {
                title: '名称',
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                key: 'name',
                render: (text, record, index) => {
                    if (text) {
                        if (record.suffix) {
                            return record.name + record.suffix;
                        } else {
                            return record.name;
                        }
                    }
                }
            }, {
                title: '大小',
                width: '100px',
                dataIndex: 'sizeStr',
                sorter: (a, b) => a.size - b.size,
                sortOrder: sortedInfo.columnKey === 'sizeStr' && sortedInfo.order,
                key: 'sizeStr',
            }, {
                title: '上传时间',
                width: '200px',
                dataIndex: 'createdTime',
                sorter: (a, b) => {
                    return parseInt(new Date(a.createdTime).getTime()) - parseInt(new Date(b.createdTime).getTime());
                },
                sortOrder: sortedInfo.columnKey === 'createdTime' && sortedInfo.order,
                key: 'createdTime',
                render: text => pcService.dateFormat(text, 'yyyy-MM-dd hh:mm')
            }, {
                title: '上传人',
                width: '100px',
                dataIndex: 'creator',
                sorter: (a, b) => a.creator.localeCompare(b.creator),
                sortOrder: sortedInfo.columnKey === 'creator' && sortedInfo.order,
                key: 'creator',
            }];
        const props = {
            name: 'file',
            action: urls.file.uploadGetId,
            multiple: true,
            showUploadList: true,
            onChange: this.handleUploadChange,
            accept: ".rar,.doc,.docx,.zip,.pdf,.txt,.swf,.xlsx,.gif,.png,.jpg,.jpeg,.bmp,.xls,.mp4,.flv,.ppt,.avi,.mpg,.wmv,.3gp,.mov,.asf,.asx,.vob,.wmv9,.rm,.rmvb,.mp3,.mp4,.3gp,.mpg,.avi,.wmv,.flv,.swf,.jpg,.bmp,.png,.tif,.ico,.gif,.tga,.mp3,.wma,.amr,.ogg,.aac,.wav"

        };

        const searchformSubmit = (form, $event) => {
            form.validateFields((err, fieldsValue) => {
                if (err) {
                    return;
                }
                fieldsValue.endTime = fieldsValue.endTime && fieldsValue.endTime.format('YYYY-MM-DD HH:mm:ss');
                this.getTable(fieldsValue)
            });

        };
        const menuOrder = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="iName">名称</Menu.Item>
                <Menu.Item key="iTime">时间</Menu.Item>
                <Menu.Item key="iNum">文件类型</Menu.Item>
            </Menu>
        );
        return (
            <Spin spinning={loading} className="files-container">
                <div className="my-table" onClick={this.clearRightMenu}>
                    <div className='my-top'>
                        <div>
                            <Button onClick={this.buttonToggleView} value="default">切换视图</Button>
                        </div>
                    </div>
                    <div className="my-toggle-line" />
                    <div className="my-box" style={{ display: this.state.toggleTable }}>
                        <div className="my-sidebar">
                            <div>
                                <DirectoryTree className="my-tree" onSelect={this.onSelect} selectedKeys={[this.state.indicatorId]}
                                    onRightClick={this.onRightClick}>
                                    {this.renderTreeNodes(this.state.treeList)}
                                </DirectoryTree>
                            </div>
                            {this.getNodeTreeRightClickMenu()}
                        </div>
                        <div className="my-content">
                            <div className="my-innercontent">
                                <div className="my-search">
                                    <div className="my-search-name">
                                        <div className="my-search-add">
                                            <Button type="primary" disabled={isDisabledUpload}
                                                icon="file-add" onClick={this.showModal}>上传</Button>
                                        </div>
                                        <Divider type="vertical" />
                                        <div className="my-search-delete">
                                            <Popconfirm
                                                title="确定要删除吗?"
                                                visible={visibleDelete}
                                                onVisibleChange={this.handleVisibleChange.bind(this, !isDisabled)}
                                                onConfirm={this.buttonBatchDelete}
                                                onCancel={this.cancel}
                                                okText="确定"
                                                cancelText="取消" >
                                                <Button disabled={isDisabled} icon="delete" type="default">删除</Button>
                                            </Popconfirm>
                                            {/* <Button type="primary" disabled={isDisabled}
                                                icon="delete" onClick={this.buttonBatchDelete}>删除</Button> */}
                                        </div>
                                        <div className="my-search-form">
                                            <SearchForms formSubmit={searchformSubmit}
                                                subTypeForSearch={this.state.subTypeForSearch}> </SearchForms>
                                        </div>
                                    </div>


                                    <Modal
                                        title="新增文件" visible={this.state.visible} destroyOnClose={true}
                                        onOk={this.handleOk} onCancel={this.handleCancel} className="my-model">
                                        <Dragger {...props}
                                            beforeUpload={file => {

                                            }}
                                            onRemove={file => {
                                                this.setState(({ importFileList }) => {
                                                    const index = importFileList.indexOf(file)
                                                    const newFileList = importFileList.slice()
                                                    newFileList.splice(index, 1)
                                                    return {
                                                        importFileList: newFileList,
                                                    }
                                                })
                                                if (file.response) {
                                                    this.errUpload(file.response);
                                                }
                                            }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <Icon type="inbox" />
                                            </p>
                                            <p className="ant-upload-text">点击或拖拽到此区域上传</p>
                                            <p className="ant-upload-hint">支持单个或批量上传</p>
                                        </Dragger>
                                    </Modal>

                                </div>

                                <div className="my-listTable">
                                    <Table rowSelection={rowSelection} rowKey={record => record.id} columns={columns}
                                        pagination={{
                                            showQuickJumper: true,
                                            total: this.state.total,
                                            showTotal: total => `共${this.state.total}条`,
                                        }} dataSource={this.state.tableData} onChange={this.handleChange}
                                        rowClassName={this.setRowClassName} />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* 切换视图 */}
                    <div className="my-toggleView" style={{ display: this.state.toggleView }}>
                        <div className="my-toggleButton">
                            <Breadcrumb>
                                {
                                    breadCrumbList.map((item) => (
                                        <Breadcrumb.Item className='ant-breadcrumb' key={item.id} value={item.name}><a
                                            href="javascript:;"
                                            onClick={this.onToggleView.bind(this, item.id, 'breadCrust')}> {item.name}</a></Breadcrumb.Item>)
                                    )}

                            </Breadcrumb>
                            <div className="my-toggleButton-add">
                                <Button type="primary" onClick={this.buttonTreeNew}> 新建文件夹</Button>
                                <Button type="default" onClick={this.showModal}> 上传文件</Button>
                                <Radio.Group>
                                    <Radio.Button value="all" onClick={this.selectToggleAll}>全选</Radio.Button>
                                    <Radio.Button value="delete"
                                        onClick={this.buttonToggleBatchDelete}>删除</Radio.Button>
                                </Radio.Group>

                            </div>
                            <div className="my-toggleButton-right">
                                <Dropdown overlay={menuOrder}>
                                    <Button> 排序 <Icon type="down" /> </Button>
                                </Dropdown>
                            </div>
                        </div>
                        <ul className="ul-border">
                            {
                                tableToggleData.map((value, key) => {
                                    return (
                                        <div className="files-list" key={key}>
                                            <li className="files-list-li">
                                                <div className="folder-li">
                                                    <div className="select-container"
                                                        onClick={this.selectToggle.bind(this, value.id)}>
                                                        <a href="javascript:;"> <img src={value.selectToggleImg}></img></a>
                                                    </div>

                                                    <div className="btn-container">
                                                        <a href="javascript:;"
                                                            onMouseEnter={this.onToggleMouseEnter.bind(this, value.id)}>
                                                            <img src={require('../../img/fileImg/more.png')}></img>
                                                            {
                                                                !value.num ?
                                                                    //文件
                                                                    <ul className="self-right-menu"
                                                                        style={{ display: value.displayToggle }}>
                                                                        <li onClick={this.buttonDownload.bind(this, value, value)}>下载</li>
                                                                        <li onClick={this.buttonDelete.bind(this, value, value)}>删除</li>
                                                                        <li onClick={this.buttonRename.bind(this, value, value)}>重命名</li>
                                                                    </ul> :
                                                                    //指标
                                                                    <ul className="self-right-menu"
                                                                        style={{ display: value.displayToggle }}>
                                                                        <li onClick={this.buttonTreeDelete.bind(this, value.id)}>删除</li>
                                                                        <li onClick={this.buttonTreeRename.bind(this, value.id)}>修改</li>
                                                                    </ul>
                                                            }
                                                        </a>

                                                    </div>
                                                    <img className="img" id={value.id}
                                                        onClick={this.onToggleView.bind(this, value.id, value.docuType)}
                                                        src={value.imgAddress} />
                                                    <div title={value.title}>
                                                        {
                                                            value.name.length > 8 ? value.name.substring(0, 8) + '...' : value.name
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    {/* 重命名模态框 */}
                    <Modal
                        title="重命名" visible={this.state.visibleRename} onOk={this.renameOk}
                        onCancel={this.renameCancel} className="my-model">
                        <div style={{width:"100%"}} >
                            <label className="my-label" style={{width:"20%"}}>名称：</label><input value={this.state.renameName}
                                onChange={this.onChangeRename}  style={{width:"80%"}}
                                className="ant-input my-input" ref="title" />
                        </div>
                    </Modal>

                    {/* 新增树模态框 */}
                    <Modal
                        //文件夹名称、分数、父类ID、应放文件数、bzBodyId、排序
                        title={this.state.titleTree} visible={this.state.visibleTree} onOk={this.treeOk}
                        onCancel={this.treeCancel} className="my-model">
                        <TreeNewForm ref="treeform" fieldsList={this.state.treeformList}>
                        </TreeNewForm>
                    </Modal>
                </div>
            </Spin>
        )
    }
}

export default SourceManage;
