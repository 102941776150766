import React, { PureComponent } from 'react';
import { Select, Modal } from 'antd';
import ColorPicker from "../colorPicker/ColorPicker";

const {Option} = Select;
class ColorSelect extends PureComponent {
    chooseValue = undefined; //记录modal上选择的值
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    /* 显示隐藏换肤功能面板 */
    colorClick = () => {
        let { visible } = this.state;
        if (!visible) {
            this.setState({
                visible: !visible
            })

        } else {
            this.setState({
                visible: !visible
            });
        }
    };

    handlePickColor = color => {
        const { onChange, varkey } = this.props;
        const obj = {};
        if (onChange&&varkey) {
            obj[varkey] = color;
            onChange(obj);
        }
    }

    /* 更改主题:自定义颜色方案 */
    colorOptions = {
        onUpdate: (rgbArr = [0, 0, 0]) => {
                this.chooseValue = `rgb(${rgbArr})`;
        }
    };

    // 设置颜色值
    setColor = () => {
        const {value} = this.state;
        this.setState({
            value: this.chooseValue,
        });
        this.handlePickColor(this.chooseValue);
        this.handleModal();
    };

    // 显示与隐藏modal
    handleModal = () => {
       this.setState((preState)=>({
           visible: !preState.visible
       }))
    };

    handleInput = value => {
        // 清空输入框的值
        if (!value) this.setState({value: undefined}, ()=> {
            this.handlePickColor(null);
        })
    };

    render() {
        const { placeholder="请选择颜色" } = this.props;
        const { visible, value } = this.state;
        return (
            <div className="pc-check-panel" id='pc-check-panel' >
                <Select
                    placeholder={placeholder}
                    value={value}
                    onChange={this.handleInput}
                    allowClear
                    open={false}
                    onDropdownVisibleChange={this.colorClick}
                >
                    <Option value={value}><span className='colorBlock' style={{backgroundColor: value}}/><label>{value}</label></Option>
                </Select>
                <Modal
                    title="自定义主题"
                    width='380px'
                    visible={visible}
                    onOk={this.setColor}
                    onCancel={this.handleModal}
                    destroyOnClose
                >
                    <div className="pc-drop-content-customize">
                        {/*<h3>自定义主题</h3>*/}
                        <div className="pc-color-content-customize">
                            <ColorPicker options={this.colorOptions} />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ColorSelect;