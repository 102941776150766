import React, { Component } from 'react';
import pcService from '../../other/pc.service';
import messageIcon from '../../img/msg.png';

class MessageItem extends Component {

    state = {
        msgType: []
    }

    componentWillMount() {
        let msgType = pcService.getDataDictionary("MSG_RESOURCE_TYPE");
        this.setState({ msgType });
    }

    render() {
        const { msgType } = this.state;
        const { type = 1, liObj = {} } = this.props;
        const LiObjContent = (type) => <div>消息演示</div>
        return (
            <li>
                <h4><img src={messageIcon} />
                    {liObj.title}
                    <span>{pcService.dateFormat(liObj.createdTime, "yyyy-MM-dd hh:mm")}</span></h4>
                <div className="p">{liObj.content || <LiObjContent type={type} />}</div>
                {
                    !!type ? <div className="addon">来源: {pcService.numberToText(liObj.typeEvent, msgType)}</div> : null
                }
            </li>
        )
    }
}

export default MessageItem;
