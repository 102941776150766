import G2 from '@antv/g2';
import DataSet from '@antv/data-set';

const noData = (id = "", data = []) => {
    //console.log("dom...", document.getElementById(id));
    if (data.length === 0 || !document.getElementById(id)) {
        document.getElementById(id).innerHTML = `<p className="no-data">暂无数据</p>`;
        return true;
    } else {
        return false;
    }

};

export const renderBar = (id, data = [], type = 0) => {
    if (noData(id, data)) return;
    document.getElementById(id).innerHTML = "";
    let totalArr = data.map(v => v.value);
    let total = 0;
    totalArr.map(v => {
        total += v;
    });
    let chart = new G2.Chart({
        container: id,
        forceFit: true,
        height: 352,
        animate: true
    });
    chart.source(data);
    chart.coord('theta', {
        radius: 1,
        innerRadius: 0.6
    });
    chart.tooltip({
        showTitle: false
    });
    // 辅助文本
    chart.guide().html({
        position: ['50%', '50%'],
        html: `<div style="color:#8c8c8c;font-size: 14px;line-height:24px;text-align: center;width: 10em;">${type === 0 ? "阅读总数" : "发布总数"}<br><span style="color:#000;font-size:24px;line-height:24px;">${total}</span></div>`,
        alignX: 'middle',
        alignY: 'middle'
    });
    chart.intervalStack().position('value').color('name').label('value').style({
        lineWidth: 2,
        stroke: '#fff'
    });
    chart.render();
}

export const renderOrgActivities = (id = "", data = []) => {
    if (noData(id, data)) return;
    document.getElementById(id).innerHTML = "";
    let chart = new G2.Chart({
        container: id,
        forceFit: true,
        height: 352,
        animate: true
    });
    chart.source(data);
    /*  chart.scale('value', {
         tickInterval: 20
     }); */
    chart.tooltip({
        showTitle: false,
        itemTpl: `<span>{title} : {value}</span>`
    });
    chart.interval().position('name*value');
    chart.render();
}

export const renderStudyes = (id = "", data = []) => {
    if (noData(id, data)) return;
    document.getElementById(id).innerHTML = "";
    data = data.map(v => ({
        ...v,
        "视频学习": v.videoCount,
        "图文学习": v.pictureCount
    }));
    document.getElementById(id).innerHTML = "";
    var dv = new DataSet.View().source(data);
    dv.transform({
        type: 'fold',
        fields: ['视频学习', '图文学习'],
        key: 'type',
        value: 'value'
    });
    var chart = new G2.Chart({
        container: id,
        forceFit: true,
        height: 556,
        animate: true
    });
    chart.source(dv, {
        month: {
            range: [0, 1]
        }
    });
    chart.tooltip({
        crosshairs: true,
        showTitle: false
    });
    chart.area().position('month*value').color('type').shape('smooth');
    chart.line().position('month*value').color('type').size(2).shape('smooth');
    chart.render();
}

/* 党员动态积分 */
export const renderCommPointAttribute = (id = "", data = []) => {
    if (noData(id, data)) return;
    document.getElementById(id).innerHTML = "";
    var chart = new G2.Chart({
        container: id,
        forceFit: true,
        height: 357,
        animate: true
    });
    chart.source(data);
    chart.scale('value', {
        //min: 0
    });
    chart.scale('name', {
        range: [0, 1]
    });
    chart.tooltip({
        crosshairs: {
            type: 'line'
        },
        showTitle: false,
        itemTpl: `<span>{title} : {value}</span>`
    });
    chart.line().position('name*value');
    chart.point().position('name*value').size(4).shape('circle').style({
        stroke: '#fff',
        lineWidth: 1
    });
    chart.render();
}

/* 党组织动态积分 */
export const rednerPointPartyStatisticQuarterlyDetail = (id = "", data = []) => {
    if (noData(id, data)) return;
    let ele = document.getElementById(id);
    ele.innerHTML = "";
    var chart = new G2.Chart({
        container: id,
        forceFit: true,
        height: 330,
        animate: true
    });
    chart.source(data);
    chart.tooltip({
        showTitle: false,
        itemTpl: `<span>{title} : {value}</span>`
    });
    chart.coord().transpose();
    chart.interval().position('orgName*dynamicPoint');
    chart.render();


}