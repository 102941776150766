/**
 * 公用方法类
 */
import http from '../common/axios/request';
import React, { Component } from 'react';
import { message } from 'antd';
import { getLocal, setLocal, setJsonArray } from '../common/utils';
import mainRouterArrService from '../configs/mainRouterArr.service';
import { setCurrentActiveMenu } from '../common/components/switchCustom/menu.service';
import { addNavPanelAction, reloadCurrentPanelAction } from '../action/NavpanelAction';
import { hashHistory } from 'react-router';
import appConfig from './../configs/app.config';
import moment from 'moment';
import urls from '../configs/api.config';
import qs from "qs";

class PcService {

    /* 枚举映射 * id=>name */
    numberToText(id, array) {
        // 类型转换
        if (array[0]) {
            /* if (typeof array[0].id === "string") {
                id = id.toString();
            } */
            if (array[0] && typeof array[0].id === "number") {
                id = parseInt(id);
            }
            if (array[0] && typeof array[0].id === "boolean") {
                if (id === "true") {
                    id = true;
                }
                if (id === "false") {
                    id = false;
                }
            }
        }
        let select;
        //如果是多选状态值
        if (Array.isArray(id)) {
            select = array.filter(totalVal => id.some(val => val === totalVal.id)).map(val => val.name).join('，');
            return select;
        }
        select = array.filter(v => v.id === id);
        if (select.length) {
            return select[0].name;
        }
    }

    //正则表达式；判断车牌号是否正确
    isPlateNumber(vehicleNumber) {
        let result = false;
        if (vehicleNumber.length === 7) {
            let express = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
            result = express.test(vehicleNumber);
        }
        return result;
    }

    //正则表达式；判断身份证号码是否正确
    isIDNumber(idno) {
        let result = false;
        let IDNOString = new RegExp(/^\d{17}(\d|X|x)$/);
        if (IDNOString.test(idno.toString())) {
            result = true;
        }
        return result;
    }

    //正则表达式；判断是否为手机号码
    isPhoneNumber(phoneno) {
        let result = false;
        let PhoneString = new RegExp(/^(((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\\d{8})|((\d{3,4}-?|\s)?\d{7,14})$/);
        if (PhoneString.test(phoneno.toString())) {
            result = true;
        }
        return result;
    }

    //正则表达式；判断是否为电子邮箱
    isEmail(phoneno) {
        let result = false;
        let PhoneString = new RegExp(/^[a-zA-Z0-9_-]+(\.*[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/);
        if (PhoneString.test(phoneno.toString())) {
            result = true;
        }
        return result;
    }

    //获取列表数据分页
    fetchData(url = '', params = {}, headers = null, successCallBack = null, errorCallBack = null) {
        let pagination = {
            page: params.current ? params.current - 1 : 0,
            size: params.pageSize ? params.pageSize : 20
        };
        if (params.pageSize) delete params.pageSize;
        if (params.current) delete params.current;
        let _params = Object.assign({}, params, pagination);
        http.request({
            url: url,
            method: 'get',
            params: _params,
            headers: headers
        }).then(res => {
            if (res.success) {
                successCallBack && successCallBack(res.data);
            } else {
                message.error(res.message);
                errorCallBack && errorCallBack(res.message);
            }
        }).catch(function (err) {
            //message.error(err.message);
            errorCallBack && errorCallBack(err);

        });
    };

    //刪除列表项（单个删除和多个删除）
    deleteItem(url = '', params = {}, data, successCallBack = null, errorCallBack = null) {
        http.request({
            method: "delete",
            url,
            params,
            data,
        }).then(res => {
            if (res.success) {
                successCallBack && successCallBack(res.data);
            } else {
                message.error(res.message);
                errorCallBack && errorCallBack(res.message);
            }
        }).catch(err => {
            errorCallBack && errorCallBack(err);
        });
    };

    //新增，修改表单提交
    formSubmit(url = '', create = true, data = {}, params = {}, successCallback = null, errorCallBack = null) {
        let method = "post";
        http.request({
            method,
            url,
            data,
            params
        }).then(res => {
            if (res.success) {
                successCallback && successCallback(res.data)
            } else {
                errorCallBack && errorCallBack(res.message);
            }
        }).catch(err => {
            errorCallBack && errorCallBack(err.message);
        });
    }

    //根据fieldsList获取提交data
    getFormData(fieldsList) {
        let data = {};
        fieldsList.forEach(function (item, index) {
            if (!item.hidden) {
                switch (item.editor) {
                    case 'double-timepicker':
                        data[item.names[0]] = item.value[0];
                        data[item.names[1]] = item.value[1];
                        break;
                    default:
                        data[item.name] = item.value;
                        break;
                }
            }
        });
        return data;
    };

    //绑定fieldsList数据
    bindFormdData(x, fieldsList) {
        fieldsList.map(v => {
            if (v.name.indexOf('.') > -1) {
                let names = v.name.split('.');
                let _name = this.extend(x, {});
                names.forEach((_v, _n) => {
                    _name = _name[_v];
                });
                v.value = _name;
            } else {
                v.value = x[v.name];
            }
            if (v.editor === "attachments") {
                v.value = !!x[v.name] ? x[v.name].map(v => ({
                    ...v,
                    uid: v.fileId,
                    name: v.fileName,
                    size: v.fileSize,
                    status: 'done',
                    url: appConfig.pocAddress + 'views/' + v.fileId,
                })) : ""
            }
            if (v.editor === 'file-upload') {
                /* let imgId = x[v.name];
                v.value = imgId;
                if (typeof imgId === "string") {
                    v.fileUpLoadOption.imageUrl = urls.file.viewImg + "/" + imgId
                } else {
                    let fileStrList = typeof x[v.name] === "object" && !!x[v.name] && x[v.name].length ? x[v.name].map(v =>
                        ({
                            key: v.fileId,
                            uid: v.id,
                            name: v.fileName,
                            status: 'done',
                            url: appConfig.pocAddress + 'views/' + v.fileId,
                        })
                    ) : [];
                    v.fileUpLoadOption.fileList = fileStrList;
                } */
                let logoId = x[v.name];
                v.value = logoId;
                // if (v.fileUpLoadOption.fileList !== undefined) {
                let fileStrList = typeof x[v.name] === "object" && !!x[v.name] && x[v.name].length ? x[v.name].map(v =>
                    ({
                        key: v.fileId,
                        uid: v.id,
                        name: v.fileName,
                        status: 'done',
                        url: appConfig.pocAddress + 'views/' + v.fileId,
                    })
                ) : undefined;
                if (x[v.name] && x[v.name].length !== 0) {
                    v.fileUpLoadOption.fileList = fileStrList;
                } else {
                    delete v.fileUpLoadOption.fileList
                }
                // }
                if (!!logoId) {
                    v.fileUpLoadOption.imageUrl = urls.file.viewImg + "/" + logoId
                }
            }

            if (v.editor === "datePicker") {
                v.value = x[v.name] ? (new moment(x[v.name])) : null;
            }
        });
    };

    //初始化fieldsList数据
    initFormList(fieldsList) {
        fieldsList.map(v => v.value = v.originValue);
        return fieldsList;
    };

    /**
     * 时间格式化
     * @param inputTime
     * @param fmt
     * @returns {*}
     */
    dateFormat(inputTime, fmt) {
        let date = new Date(inputTime);
        let o = {
            'M+': date.getMonth() + 1,                   //月份
            'd+': date.getDate(),                        //日
            'h+': date.getHours(),                       //小时
            'm+': date.getMinutes(),                     //分
            's+': date.getSeconds(),                     //秒
            'q+': Math.floor((date.getMonth() + 3) / 3), //季度
            'S': date.getMilliseconds()                  //毫秒
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        for (let k in o)
            if (new RegExp('(' + k + ')').test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        return fmt;
    }

    /* 转换为formData */
    toFormData(data = {}) {
        let fd = new FormData();
        for (let v in data) {
            fd.append(v, data[v]);
        }
        return fd;
    }

    /* 页面传url获取图片 */
    getImgFromUrl(url) {
        return (
            <div>
                <img src={url} alt="logo" />
            </div>
        )
    }

    /* 转换为formData */
    dataToFormData(data = {}) {
        let fd = new FormData();
        for (let v in data) {
            fd.append(v, data[v]);
        }
        return fd;
    }

    /* 设置分页信息 */
    getPagination(data = {}, pagination = {}) {
        return {
            ...pagination,
            current: data.pageIndex + 1,
            pageSize: data.pageSize,
            total: data.totalCount
        }
    }

    /* 获取数据字典 */
    getDataDictionary(key = "") {
        let dataDictionary = getLocal("dataDictionary");
        if (!dataDictionary) {
            hashHistory.push("/login");
            return;
        }
        return dataDictionary[key] || [];
    }

    //通过path获取对应的组件名称
    getChildContentByPathKey(pathKey) {
        let mainRouterArr = mainRouterArrService;
        let pathName = this.getRelativePath(pathKey);
        let panelComponent = null;
        if (mainRouterArr && mainRouterArr.length > 0) {
            for (let k = 0; k < mainRouterArr.length; k++) {
                let item = mainRouterArr[k];
                if (item.path === pathName) {
                    panelComponent = item.component
                }
            }
            return panelComponent;
        }
    }

    /* 根据绝对路径获取相对路径 */
    getRelativePath(pathUrl = "") {
        if (!(pathUrl && pathUrl.indexOf("?") > -1)) {
            return pathUrl;
        }
        let _pathUrl = String(pathUrl);
        _pathUrl = _pathUrl.split("?")[0];
        _pathUrl = _pathUrl.split("#")[1];
        return _pathUrl;
    }

    /*在导航中添加详情页面的tab页打开方法*/
    addDeteilPanel(item, dispatch) {
        if (item) {
            dispatch(addNavPanelAction(item))
        }
    }

    /* 根据path获取panel */
    getPanelByPath = (path = "") => {
        if (path.indexOf("?")) {
            path = path.split("?")[0];
        }
        let activeRouterArr = mainRouterArrService.filter(v => v.path === path);
        return activeRouterArr.length > 0 ? activeRouterArr[0] : null;
    }

    /*绝对url转相对url*/
    httpUrlToPathName = (pathUrl) => {
        if (!(pathUrl && pathUrl.indexOf("?") > -1)) {
            return pathUrl;
        }
        let _pathUrl = String(pathUrl);
        _pathUrl = _pathUrl.split("?")[0];
        _pathUrl = _pathUrl.split("#")[1];
        return _pathUrl;
    }

    /* 通过id获取path*/
    getPathById = (id) => {
        let activeRouterArr = mainRouterArrService.filter(v => v.id === id);
        return activeRouterArr.length > 0 ? activeRouterArr[0].path : null;
    }

    /* 通过path获取id*/
    getIdByPath = (path) => {
        let activeRouterArr = mainRouterArrService.filter(v => v.path === path);
        return activeRouterArr.length > 0 ? activeRouterArr[0].id : null;
    }

    /* 路由跳转 */
    relativePath = (pathname, menu = null, openerId = null) => {
        if (!pathname) return;
        let prePath = pathname;
        pathname = String(pathname).includes("?") ? pathname.split("?")[0] : pathname;
        let params = prePath.split("?")[1];
        //需要把菜单加入最近使用是传入menu即可
        if (!!menu && !!menu.path) {
            setCurrentActiveMenu(menu);
        }
        if (!pathname) return;
        let activePanel = this.getPanelByPath(pathname);
        if (!!activePanel) {
            let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
            if (!currentOpenMenuArr.some(v => v.path === pathname)) {
                currentOpenMenuArr.push({
                    ...activePanel,
                    params: params ? qs.parse(params) : undefined
                });
            }
            currentOpenMenuArr.map(v => {
                if (v.path === pathname) {
                    v.active = true;
                    if (!!params) {
                        v.params = qs.parse(params);
                        v.unMount = true
                    };
                } else {
                    v.active = false;
                }
            });
            setLocal("current_open_menu_arr", currentOpenMenuArr);
            hashHistory.push(prePath);
        }
    }

    /* 关闭panel */
    removePanel = (targetKey, isRefreshParent = false, parentPanel = null) => {
        let lastIndex = 0;
        let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
        let targetPath = "/app";
        if (currentOpenMenuArr.length > 0) {
            if (currentOpenMenuArr.some(v => v.id === targetKey)) {
                currentOpenMenuArr.map((v, n) => {
                    if (v.id === targetKey) {
                        lastIndex = n - 1 > 0 ? n - 1 : 0;
                    }
                });
            } else {
                currentOpenMenuArr.map((v, n) => {
                    if (v.active) {
                        lastIndex = n;
                    }
                });
            }
            currentOpenMenuArr[lastIndex].active = true;
            currentOpenMenuArr = currentOpenMenuArr.filter(v => v.id !== targetKey);
            setLocal("current_open_menu_arr", currentOpenMenuArr);
            let activePanel = currentOpenMenuArr.filter(v => v.active);
            if (activePanel.length > 0) {
                targetPath = activePanel[0].path;
            }
        }
        hashHistory.push(targetPath);
        if (isRefreshParent && parentPanel) {
            let panelId = this.getIdByPath(parentPanel)
            this.reloadCurrent(panelId)
        }
    };

    /* 刷新当前 */
    reloadCurrent = (targetKey) => {
        let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
        currentOpenMenuArr.map(v => {
            if (v.id === targetKey) {
                v.unMount = true;
                return;
            }
        });
        setLocal("current_open_menu_arr", currentOpenMenuArr);
    }
    /* 获取当前标签页数组 */
    getpanels = (that) => {
        let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
        let { location } = that.props;
        let activeKey = "0";
        let currentPath = location.pathname;
        let { initPanel } = that.state;
        let currentPanel = this.getPanelByPath(currentPath);
        if (!!currentPanel) {
            activeKey = currentPanel.id;
        } else {
            activeKey = "100";
            currentPanel = this.getPanelByPath("/app/404");
        }
        if (currentPath !== "/app" && !currentOpenMenuArr.some(v => v.path === currentPath)) {
            currentOpenMenuArr.push(currentPanel);
        }
        currentOpenMenuArr = initPanel.concat(currentOpenMenuArr.map(v => {
            return v.path === currentPath ? {
                unMount: !!v.unMount,
                active: true,
                title: v.title,
                closable: true,
                key: v.id,
                component: (this.getPanelByPath(currentPath)).component
            } : {
                    unMount: !!v.unMount,
                    active: false,
                    title: v.title,
                    closable: true,
                    key: v.id,
                    component: (this.getPanelByPath(v.path)).component
                }

        }));
        return {
            currentOpenMenuArr,
            activeKey
        }
    }

    /* 图片转化为base64 */
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    /*普通tree转换为treeSelect需要的格式 */
    dataToTreeData = (data = [], name = "", id = "", childrenName = "") => data.map(v => ({
        name: v[name],
        id: v[id],
        children: this.dataToTreeData(v[childrenName], name, id, childrenName)
    }));
    /* 获取treeData中符合要求的节点 */
    getActiveItemById = (arr = [], id) => {
        let newArr = setJsonArray(arr, "childrenList");
        let filterArr = newArr.filter(v => v.id === id);
        return filterArr.length > 0 ? filterArr[0] : null;
    }

    /* 深拷贝 (简单数据类型适用)*/
    deepCopy = (obj) => {
        let result = Array.isArray(obj) ? [] : {};
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    result[key] = this.deepCopy(obj[key]);   //递归复制
                } else {
                    result[key] = obj[key];
                }
            }
        }
        return result;
    }

    /* 组装党组织与党员  */
    /* type:
    0:获取所有组织和所有党员
    1:获取所有组织
    */
    renderOrgAndUserNodes = ({ data = [], type = 0, orgCField = "children", comuCField = "communistInfoList", orgId = "id", orgName = "name", comuId = "id_structureId", comuName = "name", renderAll = false, isRoot = true, isHideClass = true }) => data.map(v => {
        if (!!isRoot) {
            v.id = v[orgId];
            v.name = v[orgName];
            v.disableCheckbox = (v.children && v.children.every(v => v.disableCheckbox)) || false
        }
        let childrens = [];
        if (!!v[comuCField] && type !== 1) {
            v[comuCField].map(_v => {
                childrens.push(renderAll ? {
                    ..._v,
                    id: comuId.includes("_") ? `${_v[comuId.split("_")[0]]}_${v[comuId.split("_")[1]]}` : _v[comuId],
                    name: _v[comuName],
                    selectable: true,
                    disableCheckbox: false,
                    isLeaf: true
                } : {
                        id: comuId.includes("_") ? `${_v[comuId.split("_")[0]]}_${v[comuId.split("_")[1]]}` : _v[comuId],
                        name: _v[comuName],
                        selectable: true,
                        disableCheckbox: false,
                        isLeaf: true
                    });
            });
        }
        if (!!v[orgCField]) {
            v[orgCField].map(_v => {
                childrens.push({
                    ..._v,
                    id: _v[orgId],
                    name: _v[orgName],
                    selectable: type === 1,
                    disableCheckbox: !_v[comuCField] || _v[comuCField].length === 0
                })
            })
        }
        v.children = childrens;
        let vChildren = this.renderOrgAndUserNodes({ data: v.children, comuCField, orgCField, type, orgId, orgName, comuId, comuName, renderAll, isRoot: false });
        let vDisabled = (vChildren.length > 0 && vChildren.some(a => !a.disableCheckbox)) ? false : (v.disableCheckbox === undefined ? true : v.disableCheckbox);
        //(vChildren.length > 0 && vChildren.every(a => a.disableCheckbox)) && !!v.disableCheckbox;
        return renderAll ? {
            ...v,
            disableCheckbox: vDisabled,
            selectable: type === 1 || (v.selectable === undefined ? !v[orgCField] === undefined : v.selectable),
            children: vChildren
        } : {
                id: v.id,
                name: v.name,
                disableCheckbox: vDisabled,
                selectable: type === 1 || (v.selectable === undefined ? !v[orgCField] === undefined : v.selectable),
                //选择组织，过滤掉小组
                children: isHideClass && type === 1 && v.partyType === "PARTY" ? [] : vChildren
            }
    });
    /* treeData=[{
        id:1,
        name:"张三",
        children:[]
    }] */
    getNodeTitleByValue = (value = [], treeData = []) => {
        let _treeData = setJsonArray(treeData, "children");
        let filterArr = _treeData.filter(v => value instanceof Array ? value.some(_v => _v === v.id) : value === v.id);
        return filterArr.map(v => v.name).join(",");
    }

}

export default new PcService();
