import React, { Component } from 'react';
import PcForm from '../../common/components/form/Forms';
import urls from './../../configs/api.config';
import { Spin, Row, Divider, Button, Icon, message, Popconfirm, Popover, Upload } from 'antd';
import PcTable from '../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import pcService from '../../other/pc.service';
import { getLocal, isEmpty, isNotEmpty } from '../../common/utils';
import { convertListToTreeJson } from './../../common/utils'
import http from '../../common/axios/request';
import axios from "axios";
import moment from 'moment';
import './partyMember.less';

class PartyMember extends Component {

    state = {
        updateData: {},
        showResult: false,
        isSuperAdmin: false,//是否是管理员
        userInfo: {},
        lists: [],
        loading: false,
        uploadLoading: false,
        visible: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        structureList: getLocal("structureList") || [],
        exportValue: "",
        partyName: "",
        roleList: [],//角色列表
        acountModalOptions: {
            title: "账号设置",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false
        },
        resetPwdModalOptions: {
            title: "密码重置",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false
        },
        modalOptions: {
            title: "党员",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 960
        },
        transforOptions: {
            title: "正式党员",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        imgResponseId: "",
    };


    /*  */
    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
            width: 60
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 300,
            render: (text, record, index) => {
                return (
                    <div style={{ wordWrap: 'break-word', width: 340, wordBreak: 'break-all' }}>
                        <a onClick={this.acountSetting.bind(this, record)} ><Icon type="user" /> 账号</a>
                        <Divider type="vertical" />
                        <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                        <Divider type="vertical" />
                        <a onClick={this.resetPassword.bind(this, record)} ><Icon type="key" /> 密码重置</a>
                        <Divider type="vertical" />
                        <a onClick={this.editOne.bind(this, record)} ><Icon type="edit" /> 编辑</a>
                        {
                            record.type === "1" ? (
                                <span>
                                    <Divider type="vertical" />
                                    <a onClick={this.transforInto.bind(this, record)} ><Icon type="swap" /> 党员转正</a>
                                </span>) : null
                        }
                    </div>
                )
            }
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '年龄',
            dataIndex: 'age',
            key: 'age'
        },
        {
            title: '民族',
            dataIndex: 'nation',
            key: 'nation',
            render: (text, record, index) => {
                return pcService.numberToText(text, pcService.getDataDictionary("NATION"));
            }
        },
        {
            title: '所属党支部',
            dataIndex: 'partyName',
            key: 'partyName'
        },
        /* {
            title: '加入党组织时间',
            dataIndex: 'importDate',
            key: 'importDate'
        }, */
        {
            title: '党内职务',
            dataIndex: 'inJob',
            key: 'inJob',
            render: value => value.length > 12 ? <Popover content={value} placement="topRight"
                overlayStyle={{ width: 160, wordBreak: 'break-all' }}>
                {value.slice(0, 12) + '...'}
            </Popover> : value
        },
        /* {
            title: '籍贯',
            dataIndex: 'nativePlace',
            key: 'nativePlace'
        }, */
        {
            title: '工作单位',
            dataIndex: 'unitName',
            key: 'unitName'
        }
    ];

    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "name",
            editor: "normal",
            value: "",
            displayName: "姓名",
            rules: [
                {
                    required: true,
                    message: "请输入姓名"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "sex",
            editor: "radio",
            displayName: "性别",
            unavailable: true,
            columns: 4,
            opts: pcService.getDataDictionary("SEX"),
            rules: [
                {
                    message: '请选择性别',
                    required: true
                }
            ]
        }, {
            name: 'birthday',
            displayName: '出生日期',
            editor: 'datePicker',
            unavailable: true,
            value: null,
            originValue: null,
            timeOptions: {
                showToday: true,
                format: 'YYYY-MM-DD'
            },
            rules: [{ required: true, message: '请选择出生日期' }]
        }, {
            name: "residence",
            editor: "normal",
            value: "",
            displayName: "居住地",
            rules: [
                {
                    required: true,
                    message: "请输入居住地"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "nation",
            editor: "select",
            value: "",
            displayName: "民族",
            originValue: "",
            opts: pcService.getDataDictionary("NATION"),
        }, {
            name: "nativePlace",
            editor: "normal",
            value: "",
            displayName: "籍贯",
            rules: [
                {
                    required: true,
                    message: "请输入籍贯"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "maritalStatus",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "婚姻状况",
            opts: [
                { id: "1", name: "已婚" },
                { id: "2", name: "未婚" },
            ],
        }, {
            name: "tel",
            editor: "normal",
            value: "",
            displayName: "手机",
            rules: [
                {
                    required: true,
                    message: "请输入手机"
                },
                { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号码' }
            ]
        }, {
            name: "idCard",
            editor: "normal",
            value: "",
            displayName: "身份证",
            rules: [
                {
                    required: true,
                    message: "请输入身份证"
                },
                { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码' }
            ],
            onChange: (item, form, value, $event) => {
                let idCard = value.target.value;
                if (idCard.length <= 17)
                    return;
                this.fieldsList.map(v => {
                    if (v.name === "sex") {
                        v.value = this.getSex(idCard);
                    }
                    if (v.name === "birthday") {
                        v.value = new moment(idCard.substr(6, 8));
                    }
                })
            },
        }, {
            name: "degree",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "学历",
            opts: [
                { id: "1", name: "小学" },
                { id: "2", name: "初中" },
                { id: "3", name: "高中（中专）" },
                { id: "4", name: "专科（大专）" },
                { id: "5", name: "本科" },
                { id: "6", name: "硕士" },
                { id: "7", name: "博士" },
            ],
            rules: [
                {
                    required: true,
                    message: "请选择学历"
                }
            ]
        }, {
            name: "university",
            editor: "normal",
            value: "",
            displayName: "毕业院校",
        }, {
            name: "specialty",
            editor: "normal",
            value: "",
            displayName: "所学专业",
            rules: [
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "specialSkill",
            editor: "normal",
            value: "",
            displayName: "有何专长",
            rules: [
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "email",
            editor: "normal",
            value: "",
            displayName: "电子邮箱",
            rules: [
                { pattern: /^[a-zA-Z0-9_-]+(\.*[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确电子邮箱' }
            ]
        }, {
            name: "qq",
            editor: "normal",
            value: "",
            displayName: "QQ",
            rules: [
                { pattern: /^\d+$/, message: '请输入非负整数' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        },
        { name: "applicationTime", editor: "datePicker", displayName: "申请入党时间", value: null, originValue: null, timeOptions: { showToday: true, format: 'YYYY-MM-DD' } },
        { name: "activistTime", editor: "datePicker", displayName: "转为积极分子日期", value: null, originValue: null, timeOptions: { showToday: true, format: 'YYYY-MM-DD' } },
        { name: "progressTime", editor: "datePicker", displayName: "转为发展对象日期", value: null, originValue: null, timeOptions: { showToday: true, format: 'YYYY-MM-DD' } },
        { name: "probationaryTime", editor: "datePicker", displayName: "转为预备党员日期", value: null, originValue: null, timeOptions: { showToday: true, format: 'YYYY-MM-DD' } },
        //{ name: "conversionTime", editor: "hidden", displayName: "转为正式党员日期", value: null, originValue: null },
        {
            name: 'conversionTime',
            displayName: '转为正式党员日期',
            editor: 'datePicker',
            value: null,
            originValue: null,
            timeOptions: {
                showToday: true,
                format: 'YYYY-MM-DD'
            },
            rules: []
        }, {
            name: "structureId",
            editor: "node-select",
            value: "",
            originValue: "",
            opts: [],
            displayName: "所属党组织",
            rules: [
                { required: true, message: "请选择所属党组织" }
            ],
            onChange: (_item, form, _value, $event) => {
                let optsList = this.setJsonArray(_item.opts, "children");
                let valueList = optsList.filter(v => v.structureId === _value);
                let partyName = valueList[0].structureName;
                if (valueList.length && valueList[0].partyType === "PARTY_CLASS") {
                    let valueParentList = optsList.filter(v => v.structureId === valueList[0].parentId);
                    partyName = valueParentList[0].structureName;
                }
                this.setState({
                    partyName: partyName
                })
            },
            /* }, {
                name: "inJob",
                editor: "select",
                displayName: "党内职务",
                value: "",
                originValue: "",
                opts: pcService.getDataDictionary("POSITION"), */
        }, {
            name: "type",
            editor: "select",
            displayName: "人员类别",
            value: "",
            originValue: pcService.getDataDictionary("TYPE_NORMAL")[0].id,
            columns: 4,
            unavailable: true,
            opts: pcService.getDataDictionary("TYPE_NORMAL").concat(pcService.getDataDictionary("TYPE")),
            rules: [{ required: true, message: '请选择人员类别' }],
            /* onChange: (_item, form, _value, option) => {
                if (pcService.getDataDictionary("TYPE")[0].id !== _value) {
                    this.fieldsList.map(v => {
                        if (v.name === "isOut" || v.name === "isFlow" || v.name === "isHard" ||
                            v.name === "isPartyCadre" || v.name === "isMissing" || v.name === "isRetire") {
                            v.editor = "hidden";
                        }
                    })
                } else {
                    this.fieldsList.map(v => {
                        if (v.name === "isOut" || v.name === "isFlow" || v.name === "isHard" ||
                            v.name === "isPartyCadre" || v.name === "isMissing" || v.name === "isRetire") {
                            v.editor = "radio";
                        }
                        if (v.name === "type") {
                            v.unavailable = true;
                        }
                    })
                }
                this.setState();
            }, */
        }, {
            name: "memberShipStatus",
            editor: "select",
            value: pcService.getDataDictionary("MEMBER_SHIP_STATUS")[1].id,
            displayName: "党籍状态",
            originValue: pcService.getDataDictionary("MEMBER_SHIP_STATUS")[1].id,
            columns: 4,
            opts: pcService.getDataDictionary("MEMBER_SHIP_STATUS"),
            rules: [
                {
                    required: true,
                    message: "请选择党籍状态"
                }
            ]
            // 工作信息
        }, {
            name: "unitName",
            editor: "normal",
            value: "",
            displayName: "工作单位",
            rules: [
                {
                    required: true,
                    message: "请输入工作单位"
                },
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "duty",
            editor: "normal",
            value: "",
            displayName: "工作岗位",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                { whitespace: true, message: "不能输入空格" }
            ]
        },/*  {
            name: "title",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "技术职称",
            opts: [
                { id: "1", name: "初级职称" },
                { id: "2", name: "中级职称" },
                { id: "3", name: "高级职称" },
            ],
            rules: [
                {
                    required: true,
                    message: "请选择技术职称"
                }
            ]
        },  */{
            name: "headship",
            editor: "normal",
            value: "",
            displayName: "行政职务",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
            //工作信息end
        }, {
            name: "isRetire",
            editor: "hidden",
            displayName: "是否退休",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: []
        }, {
            name: "isOut",
            editor: "hidden",
            displayName: "是否长期在外党员",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: []
        }, {
            name: "isFlow",
            editor: "hidden",
            displayName: "是否流动党员",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: []
        }, {
            name: "isHard",
            editor: "hidden",
            displayName: "是否困难党员",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: []
        }, {
            name: "isPartyCadre",
            editor: "hidden",
            displayName: "是否专职党务干部",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: []
        }, {
            name: "isMissing",
            editor: "hidden",
            displayName: "是否失联党员",
            originValue: false,
            value: false,
            columns: 4,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: []
        }, {
            name: "info",
            editor: "textarea",
            value: "",
            displayName: "个人简介",
            columns: 1,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            rules: [
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "remark",
            editor: "textarea",
            value: "",
            displayName: "备注",
            rules: [],
            columns: 1,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            }
        }, {
            name: 'photo',
            displayName: '个人照片',
            editor: 'pictureWall',
            value: '',
            originValue: '',
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                listType: "picture-card",
                accept: "image/*",
                beforeUpload: (file) => {
                    const isLt2M = file.size / 1024 / 1024 < 2;
                    if (!isLt2M) {
                        message.error('图片必须小于2MB!');
                    }
                    return isLt2M;
                },
                onChange: (info, form, _onchange) => {
                    if (info.file.status === 'done') {
                        let _value = info.fileList.map(v => v.response);
                        _onchange(_value.join(","));
                    }
                }
            }
        }, {
            name: 'id',
            displayName: 'id',
            editor: 'hidden',
            value: ""
        }
    ];
    fieldsListWork = [
        {
            name: "unitName",
            editor: "normal",
            value: "",
            displayName: "工作单位",
            rules: [
                {
                    required: true,
                    message: "请输入工作单位"
                },
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "duty",
            editor: "normal",
            value: "",
            displayName: "工作岗位",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                { whitespace: true, message: "不能输入空格" }
            ]
        },/*  {
            name: "title",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "技术职称",
            opts: [
                { id: "1", name: "初级职称" },
                { id: "2", name: "中级职称" },
                { id: "3", name: "高级职称" },
            ],
            rules: [
                {
                    required: true,
                    message: "请选择技术职称"
                }
            ]
        }, */ {
            name: "headship",
            editor: "normal",
            value: "",
            displayName: "行政职务",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }
    ];

    fieldsListTransfor = [
        {
            name: "recommender",
            editor: "normal",
            value: "",
            displayName: "推荐人",
            rules: [
                {
                    required: true,
                    message: "请输入推荐人"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: 'conversionTime',
            displayName: '日期',
            editor: 'datePicker',
            value: null,
            originValue: null,
            timeOptions: {
                showToday: true,
                format: 'YYYY-MM-DD'
            },
            rules: [{ required: true, message: '请选择时间' }]
        }, {
            name: "conversionRemark",
            editor: "normal",
            value: "",
            displayName: "备注",
            rules: [],
        }, {
            name: "conversionEnclosureList",
            editor: "attachments",
            value: [],
            originValue: [],
            displayName: "资料附件",
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            }
        }, {
            name: 'id',
            displayName: 'id',
            editor: 'hidden',
            value: ""
        }, {
            name: 'type',
            displayName: 'type',
            editor: 'hidden',
            value: ""
        }
    ];

    /* 数据筛选字段 */
    searchFieldsList = [{
        name: "sex",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "性别",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("SEX"))
    }, {
        name: "nation",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "民族",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("NATION"))
    }, {
        name: "ageGroup",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "年龄",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("AGE_GROUP"))
    }, {
        name: "structureId",
        editor: "node-select",
        value: "",
        originValue: "",
        opts: [],
        displayName: "所属党组织",
    }, {
        name: "type",
        editor: "select",
        value: "",
        originValue: "0,1",
        displayName: "人员类别",
        opts: [{ id: "0,1", name: "全部" }].concat(pcService.getDataDictionary("TYPE_NORMAL"))
    }, {
        name: "name",
        editor: "normal",
        value: "",
        displayName: "姓名",
        originValue: ""
    }];

    /* 账号信息fieldsList */
    acountFieldsList = [
        {
            name: "userId",
            editor: "hidden",
            value: ""
        },
        {
            name: "userName",
            editor: "text",
            value: "",
            displayName: "账号",
            originValue: ""
        },
        {
            name: "roleId",
            editor: "node-select",
            value: "",
            originValue: "",
            displayName: "角色",
            opts: [],
            rules: [
                { required: true, message: "请选择授权操作" }
            ]
        },
        /*  {
             name: "status",
             editor: "select",
             value: "",
             displayName: "账号状态",
             originValue: 1,
             opts: [
                 { id: 1, name: "启用" },
                 { id: 2, name: "停用" }
             ],
             rules: [
                 { required: true, message: "请选择账号状态" }
             ]
         } */
    ];

    /* 密码重置fieldsList */
    resetPwdFieldsList = [
        {
            name: "userId",
            editor: "hidden",
            value: "",
            originValue: ""
        },
        {
            name: "userName",
            editor: "text",
            value: "",
            displayName: "账号",
            originValue: ""
        },
        {
            name: "password",
            editor: "password",
            value: "",
            displayName: "新密码",
            originValue: "",
            rules: [
                { required: true, message: "请输入新密码！" },
                { min: 6, message: "密码最小长度为6个字符" },
                { max: 20, message: "密码最大长度为20个字符" },
                { whitespace: true, message: "不能输入空格" }
            ]
        },
        {
            name: "comfirmPwd",
            editor: "password",
            value: "",
            displayName: "确认密码",
            originValue: "",
            rules: [
                { required: true, message: "请输入确认密码！" },
                { min: 6, message: "密码最小长度为6个字符" },
                { max: 20, message: "密码最大长度为20个字符" },
                { whitespace: true, message: "不能输入空格" }
            ]
        }
    ];

    // 嵌套JSON数组转平级 
    setJsonArray = (data, subMenu = "children") => {
        let result = [];
        data.forEach(json => {
            if (json) {
                if (isNotEmpty(json[subMenu])) {
                    result = result.concat(this.setJsonArray(json[subMenu]));
                }
                result.push(json);
            }

        });
        return result;
    };

    getSex = (data) => {
        if (data.length < 17) return;
        let sex;
        if (parseInt(data.substr(16, 1)) % 2 == 1) {
            pcService.getDataDictionary("SEX").map(v => {
                if (v.name === "男") {
                    sex = v.id.toString();
                }
            })
        } else {
            pcService.getDataDictionary("SEX").map(v => {
                if (v.name === "女") {
                    sex = v.id.toString();
                }
            })
        }
        return sex;
    }

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current, commmunistInfoSourceId: "0" }, params);
        _params.type = _params.type ? _params.type : "0,1";
        this.setState({ loading: true });
        pcService.fetchData(urls.app.partyMember.partyMemberListOrgan, _params, null,
            (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                data.rows.map(v => {
                    v.unitName = v.jobInfoList[0] ? v.jobInfoList[0].unitName : "";
                });
                this.setState({
                    loading: false,
                    lists: data.rows,
                    pagination: _pagination
                });
            },
            () => {
                this.setState({
                    loading: false
                })
            }
        );
    }

    /* 获取角色列表 */
    getRoleList = (resolve) => {
        let _params = {
            pageIndex: 0,
            pageSize: 9999
        }
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.app.partyMember.listRoles,
                params: _params,
            }).then(res => {
                if (res.success) {
                    let treeData = convertListToTreeJson(res.data.list, '0', 'subRole', 'roleId', 'levelBy');
                    let roleList = treeData ? treeData : [];
                    roleList.length && roleList.map(v => {
                        v.id = v.roleId;
                        v.name = v.roleName;
                        v.structureId = v.roleId;
                        v.structureName = v.roleName;
                        if (v.subRole) {
                            v.subRole && v.subRole.map(k => {
                                k.id = k.roleId;
                                k.name = k.roleName;
                                k.structureId = k.roleId;
                                k.structureName = k.roleName;
                            })
                            v.children = v.subRole;
                        }
                    });
                    this.setState({
                        roleList,
                        loading: false
                    });
                    //console.log(roleList)
                    this.acountFieldsList.map(v => {
                        if (v.name === "roleId") {
                            v.opts = roleList
                        }
                    });
                    resolve();
                } else this.setState({ loading: false })
            }).catch(err => {
                this.setState({ loading: false });
            })
        });
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    /* 获取个人信息 */
    getInfoMationByUserId = (userId, resolve) => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.rui.basic.user.findById,
                params: { userId }
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                resolve(null);
            })
        });
    }

    /* 账号设置 */
    acountSetting = (item, $event) => {
        let that = this;
        let { acountModalOptions, roleList } = this.state;
        let { userId, userName, roleId } = item;
        if (!userId) {
            message.info("当前用户尚未绑定账号信息！");
            return;
        }
        let _item = { userId, userName, roleId };
        //console.log(_item)
        let getInfoPromise = new Promise((gResolve) => {
            this.getInfoMationByUserId(userId, gResolve);
        });
        getInfoPromise.then(function (data) {
            //待完善 
            let msPromise = new Promise((resolve) => {
                if (roleList.length === 0) {
                    that.getRoleList(resolve);
                }
                else resolve();
            });
            msPromise.then(function () {
                pcService.bindFormdData(_item, that.acountFieldsList);
                that.setState({
                    acountModalOptions: {
                        ...acountModalOptions,
                        visible: true,
                    },
                    loading: false
                });
            });
        })
    }

    /* 账号设置确定 */
    acountOnOk = (forms) => {
        let { acountModalOptions } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = urls.app.partyMember.resetRoles
                this.setState({
                    acountModalOptions: {
                        ...acountModalOptions,
                        modalsLoading: true
                    }
                }, () => {
                    values['roleIds'] = values['roleId'];
                    delete values['roleId']
                    delete values["userName"];
                    pcService.formSubmit(url, false, null, values, (data) => {
                        message.success("分配角色成功!");
                        this.setState({
                            acountModalOptions: {
                                ...acountModalOptions,
                                modalsLoading: false,
                                visible: false
                            }
                        });
                        this.fetchData();
                    }, (err) => {
                        this.setState({
                            acountModalOptions: {
                                ...acountModalOptions,
                                modalsLoading: false,
                                visible: false
                            }
                        });
                    });
                });
            }
        });
    }

    /* 账号设置取消 */
    acountOnCancel = () => {
        let { acountModalOptions } = this.state;
        this.setState({
            acountModalOptions: {
                ...acountModalOptions,
                visible: false
            }
        });
    }

    /* 党员信息 */
    detailItem = (item, $event) => {
        let { id } = item;
        pcService.relativePath(`/app/partyMemberDetail?id=${id}`);
        // pcService.relativePath('/app/partyMemberDetail');
    }

    /* 密码重置 */
    resetPassword = (item, $event) => {
        let { resetPwdModalOptions } = this.state;
        let { userId, userName, password = "" } = item;
        if (!!userId) {
            let _item = { userId, userName, password };
            pcService.bindFormdData(_item, this.resetPwdFieldsList);
            this.setState({
                resetPwdModalOptions: {
                    ...resetPwdModalOptions,
                    visible: true
                }
            });
        } else {
            message.info("当前用户尚未绑定账号信息！");
        }
    }

    /* 密码重置确定 */
    resetPwdOnOk = (forms) => {
        let { resetPwdModalOptions } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let _values = { ...values };
                if (_values["password"] !== _values["comfirmPwd"]) {
                    message.info("两次密码输入不一致！");
                    return;
                }
                delete _values["comfirmPwd"];
                delete _values["userName"];
                this.setState({
                    resetPwdModalOptions: {
                        ...resetPwdModalOptions,
                        modalsLoading: true
                    }
                });
                pcService.formSubmit(urls.rui.basic.user.editUser, false, pcService.dataToFormData(_values), null, () => {
                    message.success("密码重置成功！");
                    this.setState({ resetPwdModalOptions: { ...resetPwdModalOptions, modalsLoading: false, visible: false } });
                }, (errMsg) => {
                    message.error(errMsg);
                    this.setState({ resetPwdModalOptions: { ...resetPwdModalOptions, modalsLoading: false, visible: true } });
                })
            }
        });
    }

    /* 密码重置取消 */
    resetPwdOnCancel = (form) => {
        let { resetPwdModalOptions } = this.state;
        this.setState({
            resetPwdModalOptions: {
                ...resetPwdModalOptions,
                visible: false
            }
        });
    }

    /* 修改申请人 */
    editOne = (item) => {
        /* //清除新增显示缓存的图片
        this.fieldsList.map(v => {
            if (v.name === 'photo') {
                v.fileUpLoadOption.imageUrl = "";
            }
        }) */
        let { modalOptions } = this.state;
        pcService.bindFormdData(item, this.fieldsList);
        //工作信息
        let { jobInfoList } = item;
        if (jobInfoList && jobInfoList.length > 0) {
            pcService.bindFormdData(jobInfoList[0], this.fieldsListWork);
        }
        this.copyListFromWork(this.fieldsList, this.fieldsListWork);
        //如果是正式党员，禁止选择
        this.fieldsList.map(k => {
            //如果是正式党员，禁止选择
            if (k.name === "type" && (k.value === "0" || k.value === "1")) {
                k.unavailable = true;
                this.fieldsList.map(v => {
                    if (v.name === "isOut" || v.name === "isFlow" || v.name === "isHard" ||
                        v.name === "isPartyCadre" || v.name === "isMissing" || v.name === "isRetire") {
                        v.editor = "radio";
                    }
                })
            }
            //发展到党员管理，给入党时间、党籍状态赋值
            /*  if (k.name === "conversionTime") {
                 k.value = !k.value ? new moment(item.probationaryTime) : k.value;
             } */
            if (k.name === "memberShipStatus") {
                k.value = !k.value ? pcService.getDataDictionary("MEMBER_SHIP_STATUS")[0].id : k.value;
            }
        })
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
        });
    }
    copyListFromWork = (list, work) => {
        work.map(v => {
            list.map(d => {
                if (v.name === d.name) {
                    d.value = v.value;
                }
            })
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, partyName } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                //joblistwork
                values.jobInfoList = [];
                values.jobInfoList.push({
                    duty: values.duty,
                    headship: values.headship,
                    title: values.title,
                    unitName: values.unitName
                })
                values.partyName = partyName ? partyName : undefined;

                let url = urls.app.partyMember.updateMember;
                let _params = Object.assign({}, values);
                //console.log(_params)
                this.setState({ loading: true });
                pcService.formSubmit(url, true, _params, null, (data) => {
                    message.success("操作成功!");
                    this.fetchData();
                    this.setState({ loading: false });
                }, (err) => {
                    message.error(err);
                    this.setState({ loading: false });
                });

                setTimeout(function () {
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            visible: false
                        }
                    })
                }, 1000);
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.fetchData(values);
                let valuesStr = "";
                for (const key in values) {
                    valuesStr += key + "=" + values[key] + "&"
                }
                valuesStr = valuesStr.indexOf("&") !== -1 ? valuesStr.substring(0, valuesStr.length - 1) : valuesStr;
                this.setState({ exportValue: valuesStr })
            }
        });
    }

    /* 删除 */
    deleteItem = (selectedRowKeys) => {
        let that = this;
        this.setState({ loading: true }, () => {
            pcService.deleteItem(urls.app.partyMember.partyMemberLogicDeleteOrgan, {}, selectedRowKeys.join(","),
                (data) => {
                    message.success("删除成功!");
                    setTimeout(function () {
                        that.componentDidMount();
                    }, 500);
                }, (err) => {
                    this.setState({ loading: false });
                }
            );
        });
    }

    createOne = () => {
        pcService.relativePath('/app/partyMemberAdd');
    }

    /* 导出 */
    exitForm = () => {
        const { exportValue } = this.state;
        /* const a = document.createElement("a");
        a.target = "_blank";
        a.href = urls.app.partyMember.export + "?" + exportValue;
        a.click(); */
        window.open(urls.app.partyMember.export + "?" + exportValue, "_blank")
        /* const { exportValue } = this.state;
        this.setState({ loading: true });
        axios.request({
            method: "get",
            url: urls.app.partyMember.export,
            params: exportValue,
        }).then(res => {
            //console.log(res)
            message.success("导出成功!");
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        }); */
    }
    /* 转为积极分子 */
    transforInto = (item = [], $event) => {
        let ids = item.id;
        let { transforOptions } = this.state;
        pcService.bindFormdData(item, this.fieldsListTransfor);
        this.fieldsListTransfor.map(v => {
            if (v.name === "type") {
                v.value = "0";
            }
        })
        this.setState({
            transforOptions: {
                ...transforOptions,
                visible: true,
            },
        });

    }
    transforOnOk = (forms) => {
        let { transforOptions } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                let url = urls.app.partyMember.updateMember;
                let _params = Object.assign({}, values);
                this.setState({ loading: true });
                pcService.formSubmit(url, true, _params, null, (data) => {
                    message.success("操作成功!");
                    this.fetchData();
                    this.setState({ loading: false });
                }, (err) => {
                    message.error(err);
                    this.setState({ loading: false });
                });

                setTimeout(function () {
                    that.setState({
                        transforOptions: {
                            ...transforOptions,
                            visible: false
                        }
                    })
                }, 1000);
            }
        });

    }
    transforOnCancel = () => {
        let { transforOptions } = this.state;
        this.setState({
            transforOptions: {
                ...transforOptions,
                visible: false
            }
        });
    }
    // 所属党组织
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        }
        //this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let lists = res.data || [];
                this.fieldsList.map(v => {
                    if (v.name === "structureId") {
                        v.opts = lists;
                    }
                })
                this.searchFieldsList.map(v => {
                    if (v.name === "structureId") {
                        v.opts = lists;
                        //v.originValue = v.value = lists[0] ? lists[0].id : "";
                    }
                })
                this.setState({ treeList: lists });
            } else {
                message.error(res.message);
            }
            //this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            //this.setState({ loading: false });
        });
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        let roleLists = getLocal("roleLists") || [];
        let isSuperAdmin = roleLists.some(v => v.roleName === "党委管理员" || v.roleName === "党支部管理员");
        this.setState({ isSuperAdmin });
    }

    componentDidMount() {
        //获取党组织
        this.getOrganizationTree();
        let forms = this.refs.pcForm;
        this.searchOnSubmit(forms);
    }

    render() {
        const { loading, showResult, updateData, visible, uploadLoading, selectedRowKeys, pagination, lists, acountModalOptions, resetPwdModalOptions,
            create, isSuperAdmin, transforOptions, modalOptions, exportValue } = this.state;
        const { onSelectChange, columns, fetchData, searchFieldsList, searchOnSubmit, createOne, acountFieldsList, acountOnOk, acountOnCancel,
            resetPwdFieldsList, resetPwdOnOk, resetPwdOnCancel, deleteItem, exitForm, onCancel, onOk, fieldsList, transforOnOk,
            cancel, handleVisibleChange, transforOnCancel, fieldsListTransfor } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const acountModals = { ...acountModalOptions, onOk: acountOnOk, onCancel: acountOnCancel }
        const resetPwdModals = { ...resetPwdModalOptions, onOk: resetPwdOnOk, onCancel: resetPwdOnCancel }
        const modal = { ...modalOptions, onOk, onCancel, width: 900, title: ("编辑") + modalOptions.title }
        const modalTransfor = { ...transforOptions, onOk: transforOnOk, onCancel: transforOnCancel, title: "转为" + transforOptions.title };
        const allowDel = selectedRowKeys.length > 0;
        const { total = 0, successCount = 0, failCount = 0, failMsg = "无" } = updateData;
        const UploadProps = {
            name: 'file',
            showUploadList: false,
            action: urls.app.partyMember.uploadsuserdata,
            headers: getLocal("poc_headers"),
            onChange: (info) => {
                /* if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                } */
                if (info.file.status === 'done') {
                    let updateData = info.file.response.data;
                    message.success(`${info.file.name}导入成功!`);
                    this.setState({ uploadLoading: false, updateData, showResult: true });
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name}导入失败！`);
                    this.setState({ uploadLoading: false });
                }
            },
            beforeUpload: () => {
                this.setState({ uploadLoading: true, showResult: false });
                return true;
            },
        };
        const acountFormItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 }
            }
        };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        return (
            <Spin spinning={loading}>
                <Row>
                    <div className="sp-bars spb-block">
                        <div className="sp-forms">
                            <PcForm ref="pcForm" layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset fieldsList={searchFieldsList} />
                        </div>
                        <div className="partymember-btns">
                            <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要删除吗?"
                                visible={visible}
                                onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                onCancel={cancel}
                                okText="确定"
                                cancelText="取消" >
                                <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                            </Popconfirm>
                            <Divider type="vertical" />
                            <Button icon="export" onClick={exitForm}>导出</Button>
                            {/*  <Button icon="export" ><a className="pt-a" target="_blank" href={urls.app.partyMember.export + "?" + exportValue} >导出</a></Button> */}
                            {isSuperAdmin ?
                                <span>
                                    <Divider type="vertical" />
                                    <Upload {...UploadProps}>
                                        <Button loading={uploadLoading} icon="import" >导入</Button>
                                    </Upload>
                                    {
                                        showResult ? <span className="import-msg ">{`总共：${total} 条，成功：${successCount} 条，失败：${failCount} 条，失败信息：${failMsg}。`}</span> : ""

                                    }
                                </span> : ""}
                        </div>
                    </div>
                </Row>
                <div className="sp-content">
                    <PcTable
                        rowSelection={rowSelection}
                        columns={columns}
                        pagination={pagination}
                        dataSource={lists}
                        fetchData={fetchData}
                        bordered={false}
                    />
                </div>
                <PcModal modal={acountModals} fieldsList={acountFieldsList} formItemLayout={acountFormItemLayout} modalsLoading={acountModals.modalsLoading} />
                <PcModal modal={resetPwdModals} fieldsList={resetPwdFieldsList} formItemLayout={acountFormItemLayout} modalsLoading={resetPwdModals.modalsLoading} />
                <PcModal formClassName={"party-applicat"} fieldsList={fieldsList} modal={modal} formItemColumns="2" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                <PcModal fieldsList={fieldsListTransfor} modal={modalTransfor} modalsLoading={modalTransfor.modalsLoading} formItemLayout={formItemLayout} />
            </Spin>
        )
    }
}

export default PartyMember;