import axios from "axios";
import { hashHistory } from 'react-router';
import { message } from 'antd';
import { getLocal } from './../utils';
import appConfig from './../../configs/app.config';

const handleSuccess = (res) => {
    if (res instanceof Array) {
        return res;
    } else {
        let body = res['data'];
        if (body) { // 有数据返回           
            if (body.status === 407) {
                message.info("登录过期，请重新登录!");
                hashHistory.push("/login");
                return;
            }
            return {
                data: body.data || null, // 返回内容  
                message: body.message || "", // 返回信息
                success: body.success
            };
        } else { // 无数据返回  
            return {
                data: body.data || null, // 返回内容  
                message: body.message || '', // 返回信息
                success: body.success
            };
        }
    }
};

const handleError = (error) => {
    let message = '错误:';
    if (error.response) {
        switch (error.response.status) {
            case 400:
                message += '请求参数不正确!';
                break;
            case 404:
                message += '请检查路径是否正确!';
                break;
            case 500:
                message += '请求的服务器错误!';
            case 407:
                message.info("登录过期，请重新登录!");
                hashHistory.push("/login");
                return;
            case 0:
                message += '网络连接失败!';
                break;
            default:
                message += "请求服务器错误！";
                break;
        }
        message.error(message);
        return Promise.reject({ 'success': false, message });
    } else {
        return Promise.reject({ 'success': false, 'message': '错误:服务器连接失败！' });
    }
};

/****** 创建axios实例 ******/
const httpService = axios.create({
    baseURL: process.env.BASE_URL,  // api的base_url
    timeout: appConfig.httpClientTimeout  // 请求超时时间
});

httpService.all = axios.all;

/****** request拦截器==>对请求参数做处理 ******/
httpService.interceptors.request.use(config => {
    let poc_headers = getLocal("poc_headers");
    if (!!poc_headers) {
        config["headers"] = {
            ...poc_headers,
            ...config.headers
        };
    }
    return config;
}, error => {
    //请求错误处理
    let _error = Promise.reject(error);
    return _error;
});

/****** respone拦截器==>对响应做处理 ******/
httpService.interceptors.response.use(handleSuccess, handleError);

export default httpService;


