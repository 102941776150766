import React, { Component } from 'react';
import './userNav.css';
import { loginOut, collection, setUserInfo, clearCache, fullScreen, userInfomation } from './userNav.service';

class UserNav extends Component {

    /* 个人信息菜单 */
    userNavMenus = [
        /*{
            title: "用户中心",
            isMenu: false
        },*/
        {
            title: "个人信息",
            isMenu: true,
            doAction: userInfomation
        },
        /*{
            title: "设置个人信息",
            isMenu: true,
            doAction: setUserInfo
        },*/
        {
            title: "退出登录",
            isMenu: true,
            doAction: loginOut
        },
        /* {
            title: "设置中心",
            isMenu: false
        },
        {
            title: "全屏",
            isMenu: true,
            doAction: fullScreen
        },
        {
            title: "清理缓存",
            isMenu: true,
            doAction: clearCache
        },
        {
            title: "设置个人信息",
            isMenu: true,
            doAction: setUserInfo
        },
        //建设中
        {
            title: "个人收藏",
            isMenu: true,
            doAction: collection
        } */
    ];

    render() {
        const { userNavMenus } = this;
        return (
            <div className="userNav">
                <div className="userNav-inner">
                    <div className="userNav-menu">
                        <ul>
                            {
                                userNavMenus.map((v, n) => <li className={v.isMenu ? "is-menu" : ""} key={n}>
                                    {
                                        v.isMenu ? <a onClick={v.doAction}>{v.title}</a> : v.title
                                    }
                                </li>)
                            }
                        </ul>

                    </div>
                </div>
            </div>

        )
    }
}

export default UserNav;





