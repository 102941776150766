import React, { Component } from 'react';
import PcForm from '../../common/components/form/Forms';
import pcService from '../../other/pc.service';
import PcTable from '../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import DetailModals from '../../common/components/modal/DetailModals';
import urls from '../../configs/api.config';
import { Row, Button, Divider, Spin, Icon, message, Popconfirm, Popover } from 'antd';
import http from '../../common/axios/request';
import { getLocal, setJsonArray } from '../../common/utils';
import './informationReview.less';

class InformationReview extends Component {

    state = {
        hasFinish: false,
        visible: false,
        loading: false,
        selectedRowKeys: [],
        partyMembers: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "信息公告",
            visible: false,
            destroyOnClose: true,
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        dtModalOptions: {
            title: "信息公告详情",
            visible: false,
            destroyOnClose: true,
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: []
    }

    /* 表格列项 */
    columns = [{
        title: '序号',
        dataIndex: 'key1',
        align: 'center',
        width: 60,
        fixed: "left",
        render: (text, record, index) => (
            <div>
                {index + 1}
            </div>
        )
    }, {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 260,
        fixed: "left",
        render: (text, record, event) => {
            return <span>
                <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 修改</a>
                <Divider type="vertical" />
                <a onClick={this.detailItem.bind(this, record)}><Icon type="info-circle" theme="outlined" /> 详情</a>
            </span>
        }
    }, {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        render: (text) => text.length > 15 ? <Popover content={text} placement="topRight"
            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
            {text.slice(0, 15) + '...'}
        </Popover> : text
    }, {
        title: '内容',
        dataIndex: 'content',
        key: 'content',
        render: (text) => text.length > 25 ? <Popover content={text} placement="topRight"
            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
            {text.slice(0, 25) + '...'}
        </Popover> : text
    },
    /* {
    title: '发布组织',
    dataIndex: 'publisherName',
    key: 'publisherName'
    }, */
    {
        title: '发布状态',
        dataIndex: 'publishStatus',
        key: 'publishStatus',
        render: text => pcService.numberToText(text, pcService.getDataDictionary("PUBLISH_STATUS"))
    }];

    /* 新增修改表单字段 */
    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'version',
        value: 0,
        originValue: 0,
        editor: 'hidden'
    }, {
        name: 'type',
        value: 0,
        originValue: 0,
        editor: 'hidden'
    }, {
        name: 'title',
        displayName: '标题',
        editor: 'normal',
        value: '',
        originValue: '',
        rules: [
            { required: true, message: "请输入标题" },
            { max: 50, message: "最长为50个字符" },
            { whitespace: true, message: "不能输入空格" }
        ]
    }, {
        name: 'typeEvent',
        displayName: '消息来源',
        editor: 'hidden',
        value: 'msg_001',
        originValue: 'msg_001'
    }, {
        name: 'publishStatus',
        displayName: '发布状态',
        editor: 'select',
        value: '',
        originValue: pcService.getDataDictionary("PUBLISH_STATUS")[0] ? pcService.getDataDictionary("PUBLISH_STATUS")[1].id : 0,
        opts: pcService.getDataDictionary("PUBLISH_STATUS"),
        rules: [{ required: true, message: "请选择发布状态" }]

    }, {
        name: 'messagesAdd',
        displayName: '接收用户',
        editor: 'node-select',
        value: '',
        originValue: [],
        opts: [],
        nodeOptions: {
            treeCheckable: true
        },
        rules: [{
            required: true,
            message: "请选择接收用户"
        }]
    },
    {
        name: 'publisherId',
        editor: 'hidden',
        value: "",
    },
    {
        name: 'publisherName',
        editor: 'hidden',
        value: ""
    },
    {
        name: 'type',
        displayName: '公告类型',
        editor: 'hidden',
        value: 0,
        originValue: 0
    }, {
        name: 'enabled',
        editor: 'hidden',
        value: true,
        originValue: true
    }, {
        name: 'content',
        displayName: '內容',
        editor: 'textarea',
        value: '',
        originValue: '',
        rules: [
            { required: true, message: "请填写內容" },
            { max: 500, message: "內容最大长度为500个字符" }
        ]

    }];

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "title",
        editor: "normal",
        value: "",
        originValue: "",
        hideDisplayName: true,
        displayName: "标题"
    }];

    /* radio onChange事件 */
    onChangeCallBack = (from, value) => {
        if (value === 2) {
            this.fieldsList.map(v => {
                if (v.name === "date") {
                    v.editor = "hidden";
                }
            });
        } else {
            this.fieldsList.map(v => {
                if (v.name === "date") {
                    v.editor = "normal";
                }
            });
        }
        this.setState({});
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.notice.page, _params, null,
                (data) => {
                    let _pagination = pcService.getPagination(data, pagination);
                    this.setState({
                        loading: false,
                        lists: data.rows,
                        pagination: _pagination
                    });
                },
                () => {
                    this.setState({
                        loading: false
                    })
                }
            );
        });

    }

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.fetchData(values);
            }
        });
    }

    /* 获取党组织下面的党员信息 */
    getpartyMember = (resolve) => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                params: { flag: true, type: 0 },
                url: urls.app.organization.selectStructureTreeById
            }).then(res => {
                if (res.success) {
                    let opts = res.data || [];
                    let orgAndUsers = pcService.renderOrgAndUserNodes({ data: opts });
                    this.fieldsList.map(v => {
                        if (v.name === "messagesAdd") {
                            v.opts = orgAndUsers;
                        }
                    });
                    let partyMembers = setJsonArray(orgAndUsers, "children", []);
                    this.setState({ loading: false, hasFinish: true, partyMembers });
                    resolve();
                } else {
                    this.setState({ loading: false });
                    resolve();
                }
            }).catch(() => {
                this.setState({ loading: false });
                resolve();
            })
        })
    }

    /* 详情 */
    detailItem = (_item, $event) => {
        let item = { ..._item };
        let { dtModalOptions, hasFinish } = this.state;
        let msPromise = new Promise((resolve) => {
            if (!hasFinish) {
                this.getpartyMember(resolve);
            } else resolve();
        })
        msPromise.then(() => {
            item["messagesAdd"] = item["messagesAdd"] ? item["messagesAdd"].map(v => v.userIdTreeShow) : [];
            pcService.bindFormdData(item, this.fieldsList);
            this.setState({
                dtModalOptions: {
                    ...dtModalOptions,
                    modalsLoading: false,
                    visible: true
                }
            })
        })

    }

    /* 新增信息公告 */
    createOne = (_item, create) => {
        let item = { ..._item };
        let { modalOptions, hasFinish } = this.state;
        let _create = false;
        let that = this;
        let msPromise = new Promise((resolve) => {
            if (!hasFinish) {
                this.getpartyMember(resolve);
            } else resolve();
        });
        msPromise.then(() => {
            if (create === false) {
                if (typeof item.attachment === "string" && !!item.attachment) {
                    item.attachment = item.attachment.split(",");
                }
                item["messagesAdd"] = item["messagesAdd"] ? item["messagesAdd"].map(v => v.userIdTreeShow) : [];
                pcService.bindFormdData(item, that.fieldsList);
            } else {
                _create = true;
                pcService.initFormList(that.fieldsList);
            }
            that.setState({
                modalOptions: {
                    ...modalOptions,
                    visible: true,
                },
                create: _create
            });
        })

    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create, partyMembers } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                values["messagesAdd"] = partyMembers.map(v => values["messagesAdd"].some(_v => _v === v.id) ? {
                    userIdTreeShow: v.id,
                    userName: v.name
                } : undefined);
                values["messagesAdd"] = values["messagesAdd"].filter(v => !!v);
                let url = "", msg = "";
                if (create) {
                    url = urls.app.notice.addEntity;
                    msg = "新增";
                } else {
                    url = urls.app.notice.updateEntity;
                    msg = "修改";
                }
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                });
                pcService.formSubmit(url, create, values, null, (data) => {
                    message.success(msg + "操作成功!");
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    }, this.fetchData);
                }, (message) => {
                    message.error(message);
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: true
                        }
                    });
                });
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 详情modal ok回调 */
    dtOnOk = () => {
        let { dtModalOptions } = this.state;
        this.setState({
            dtModalOptions: {
                ...dtModalOptions,
                visible: false
            }
        });
    }

    /* 删除 */
    deleteItem = (selectedRowKeys = []) => {
        let ids = selectedRowKeys.join(",");
        let that = this;
        this.setState({ loading: true });
        pcService.deleteItem(urls.app.notice.deleteLogic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.componentDidMount();
                }, 500);
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        let structureList = getLocal("structureList");
        if (structureList.length > 0) {
            let values = { publisherId: structureList[0].id, publisherName: structureList[0].name }
            pcService.bindFormdData(values, this.fieldsList);
        }
    }

    componentDidMount() {
        let forms = this.refs.pcForm;
        forms && this.searchOnSubmit(forms);
    }

    render() {
        const { loading, selectedRowKeys, dtModalOptions, pagination, lists, modalOptions, create, visible } = this.state;
        const { handleVisibleChange, dtOnOk, cancel, onSelectChange, columns, fetchData, searchFieldsList, searchOnSubmit, createOne, fieldsList, onOk, onCancel, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, title: (create ? "新增" : "编辑") + modalOptions.title, onOk, onCancel };
        const dtModal = { ...dtModalOptions, onOk: dtOnOk, onCancel: dtOnOk }
        const allowDelete = selectedRowKeys.length > 0;
        const dtFieldsList = fieldsList.map(v => ({ ...v, unavailable: true }));
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        const dformItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 }
            }
        };
        return (
            <Spin spinning={loading}>
                <Row className="info-rew">
                    <div className="op-navs">
                        <div className="opn-left">
                            <Button onClick={createOne} icon="file-add" type="primary">新增</Button>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要删除吗?"
                                visible={visible}
                                onVisibleChange={handleVisibleChange.bind(this, allowDelete)}
                                onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                onCancel={cancel}
                                okText="确定"
                                cancelText="取消" >
                                <Button disabled={!allowDelete} icon="delete" type="default">删除</Button>
                            </Popconfirm>

                        </div>
                        <div className="opn-right">
                            <PcForm ref="pcForm" layout="inline" submitText="搜索" onSubmit={searchOnSubmit} fieldsList={searchFieldsList} />
                        </div>
                    </div>
                </Row>
                <div className="op-content">
                    <PcTable
                        rowSelection={rowSelection}
                        columns={columns}
                        pagination={pagination}
                        dataSource={lists}
                        fetchData={fetchData}
                        bordered={false}
                    />
                </div>
                <PcModal formClassName="infoModal" fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                {/* <PcModal formClassName="infoModal" fieldsList={dtFieldsList} modal={dtModal} modalsLoading={dtModal.modalsLoading} formItemLayout={formItemLayout} /> */}
                <DetailModals steped={false} fieldsList={dtFieldsList} modal={dtModal} formItemLayout={dformItemLayout} />
            </Spin>
        )
    }
}

export default InformationReview;
