import React, { Component } from 'react';
import { Button, Divider, message, Popover, Spin, Table, Popconfirm } from 'antd';
import Forms from './../../common/components/form/Forms';
import { TablePopModal } from './../../common/components/tablePop/tablePop';
import PcService from "../../other/pc.service";
import urls from "../../configs/api.config";
import PcModal from "../../common/components/modal/Modals";
import http from "../../common/axios/request";

export class ExamQuestionSelectModal extends Component {

    state = {
        create: true,
        visible: false,
        selectedRowKeys: [],
        tablePopSelectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        modalOptions: {
            title: "试题",
            visible: false,
            tablePopVisible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            lists: this.props.lists,
            tablePopLists: []
        }
    };
    detailItem = () => {

    };
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /************************************问题选项操作START*********************/

    /**
     * 行点击选中事件
     */
    selectRow = (record) => {
        const tablePopSelectedRowKeys = [...this.state.tablePopSelectedRowKeys];
        if (tablePopSelectedRowKeys.indexOf(record.key) >= 0) {
            tablePopSelectedRowKeys.splice(tablePopSelectedRowKeys.indexOf(record.key), 1);
        } else {
            tablePopSelectedRowKeys.push(record.key);
        }
        this.setState({ tablePopSelectedRowKeys });
    };
    /**
     * 问题列表选中事件
     * @param tablePopSelectedRowKeys
     */
    onTablePopSelectChange = (tablePopSelectedRowKeys) => {
        this.setState({ tablePopSelectedRowKeys });
    };

    /**
     * 加载弹出选择列表框数据
     * @param params
     */
    tablePopFetchData = (params) => {
        let { pagination, modalOptions } = this.state;
        let { pageSize, current } = pagination;
        let { setParentState, modals } = this.props;
        let _params = Object.assign({}, { pageSize, current }, params);
        _params.enabled = "true";
        setParentState({
            modalOptions: { ...modals, title: modals.title.substring(2), modalsLoading: true }
        });
        this.setState({
            loading: true
        }, () => {
            PcService.fetchData(urls.app.examQuestion.page, _params, null, (data) => {
                let _pagination = PcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.displayTip = 'none';
                });
                lists = lists.filter(v => modalOptions.lists.every(_v => _v.id !== v.id));
                setParentState({
                    modalOptions: { ...modals, title: modals.title.substring(2), modalsLoading: false }
                });
                this.setState({
                    pagination: _pagination,
                    modalOptions: {
                        ...modalOptions,
                        tablePopVisible: true,
                        tablePopLists: lists,
                        modalsLoading: false
                    },
                    tablePopSelectedRowKeys: [],
                    selectedRowKeys: []
                });
            }, () => {
                setParentState({
                    modalOptions: { ...modals, title: modals.title.substring(2), modalsLoading: false }
                });
            });
        });
    };

    /**
     * 点击选择按钮的事件
     */
    selectQuestion = () => {
        this.tablePopFetchData();
    };

    /**
     * 点击弹出选择问题框取消按钮操作
     */
    onTablePopCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                tablePopVisible: false
            }
        });
    };

    /**
     * 点击弹出框确定按钮操作
     */
    onTablePopOk = () => {
        const { setParentState } = this.props;
        let { tablePopSelectedRowKeys, modalOptions } = this.state;
        let selectedQuestion = modalOptions.lists;
        if (tablePopSelectedRowKeys.length === 0) {
            message.info("请至少选择一道题试题!");
            return;
        }
        selectedQuestion = [...selectedQuestion, ...modalOptions.tablePopLists.filter(v => tablePopSelectedRowKeys.some(_v => _v === v.id))];
        setParentState({ selectedQuestion });
        this.setState({
            modalOptions: {
                ...modalOptions,
                lists: selectedQuestion,
                tablePopVisible: false
            }
        });
    };

    /**
     * 弹出框搜索栏
     * @type {*[]}
     */
    searchFieldsList = [{
        name: "category",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "分类",
        opts: [{ id: "", name: "全部" }].concat(PcService.getDataDictionary("QUESTION_CATEGORY"))
    }, {
        name: "keyWord",
        editor: "normal",
        value: "",
        displayName: "题目或者描选项",
        hideDisplayName: true,
        originValue: ""
    }];
    /************************************问题选项操作END***********************/

    /************************************自动生成试题操作START*********************/
    /**
     * 点击自动生成按钮的操作
     */
    selectQuestionAuto = () => {
        let { modalOptions } = this.state;
        PcService.initFormList(this.fieldsListAuto);
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true
            }
        });
    };

    /**
     * 点击自动生成弹出框确定按钮
     * @param forms
     */
    onOk = (forms) => {
        let that = this;
        let { modalOptions } = this.state;
        let { setParentState } = this.props;
        let questionIds = modalOptions.lists.map(item => {
            return item.id
        });
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            }
            values.selectCategory = values.selectCategory.toString();
            values.questionIds = questionIds.toString();
            http.request({
                url: urls.app.examPaper.auto,
                method: 'get',
                params: values,
                headers: null
            }).then(res => {
                if (res.success) {
                    let lists = res.data ? res.data : [];
                    let existLists = modalOptions.lists;
                    let selectedQuestion = [...lists, ...existLists];
                    setParentState({ selectedQuestion });
                    this.setState({
                        loading: false,
                        modalOptions: {
                            ...modalOptions,
                            lists: selectedQuestion,
                            visible: false
                        }
                    });
                } else {
                    message.error(res.message);
                }
            }).catch(function (err) {
                message.error(err.message);
            });
        });
    };

    /**
     * 点击自动生成弹出框取消按钮
     */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    };
    /************************************自动生成试题操作END***********************/
    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        /*{
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.detailItem.bind(this, record)}>详情</a>
                    </span>
                )
            }
        },*/
        {
            title: '题目',
            dataIndex: 'title',
            key: "title",
            render: text => text.length > 20 ? <Popover content={text} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {text.slice(0, 20) + '...'}
            </Popover> : text
        },
        {
            title: '分类',
            dataIndex: 'category',
            render: (value, record, index) => {
                return (<span>
                    {PcService.numberToText(value, PcService.getDataDictionary("QUESTION_CATEGORY"))}
                </span>);
            }
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'clode',
            render: (value, record, index) => {
                switch (value) {
                    case 0:
                        return <span>单选</span>;
                    case 1:
                        return <span>多选</span>;
                    default:
                        return;
                }
            }
        },
        {
            title: '分值',
            key: "score",
            dataIndex: 'score'
        },
        {
            title: '选项',
            key: "optionView",
            dataIndex: 'optionView',
            align: 'center',
            render: text => text.length > 20 ? <Popover content={text} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {text.slice(0, 20) + '...'}
            </Popover> : text
        }
    ];

    tablePopColumns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '题目',
            dataIndex: 'title',
            render: (value, record, index) => {
                if (value.length > 20) {
                    return (
                        <Popover content={value} placement="topRight"
                            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                            {value.slice(0, 20) + '...'}
                        </Popover>)
                } else {
                    return value;
                }
            }
        },
        {
            title: '分类',
            dataIndex: 'category',
            render: (value, record, index) => {
                return (<span>
                    {PcService.numberToText(value, PcService.getDataDictionary("QUESTION_CATEGORY"))}
                </span>);
            }
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'clode',
            render: (value, record, index) => {
                switch (value) {
                    case 0:
                        return <span>单选</span>;
                    case 1:
                        return <span>多选</span>;
                    default:
                        return;
                }
            }
        },
        {
            title: '分值',
            dataIndex: 'score'
        },
        {
            title: '选项',
            dataIndex: 'optionView',
            render: (value, record, index) => {
                if (value.length > 20) {
                    return (
                        <Popover content={value} placement="topRight"
                            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                            {value.slice(0, 20) + '...'}
                        </Popover>)
                } else {
                    return value;
                }
            }
        }
    ];

    /*自动生成试题窗口字段*/
    fieldsListAuto = [{
        name: "selectCategory",
        editor: "select",
        value: [],
        originValue: [],
        displayName: "分类",
        opts: PcService.getDataDictionary("QUESTION_CATEGORY"),
        mode: "multiple",
        rules: ["required"]
    }, {
        name: "singleChoice",
        editor: "number",
        displayName: "单选题数量",
        value: "",
        originValue: 5,
        rules: ["required"]
    }, {
        name: "multipleChoice",
        editor: "number",
        displayName: "多选题数量",
        value: "",
        originValue: 5,
        rules: ["required"]
    }];

    /* 删除操作*/
    deleteItem = (selectedRowKeys) => {
        const { setParentState } = this.props;
        let { modalOptions } = this.state;
        let { lists } = modalOptions;
        let _lists = lists.filter(v => selectedRowKeys.every(_v => _v !== v.id));
        this.setState({
            modalOptions: { ...modalOptions, lists: _lists },
            selectedRowKeys: []
        });
        setParentState({ selectedQuestion: _lists });
    };


    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    render() {
        const { visible, selectedRowKeys, tablePopSelectedRowKeys, modalOptions, create } = this.state;
        const { fieldsList = [], modalsLoading = false, formItemLayout = {}, formItemColumns, lists = [] } = this.props;
        const { handleVisibleChange, cancel, columns, onTablePopSelectChange, onSelectChange, selectQuestion, selectQuestionAuto, onOk, onCancel,
            deleteItem, tablePopColumns, onTablePopCancel, onTablePopOk, selectRow, fieldsListAuto, searchFieldsList, tablePopFetchData
        } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title };
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const tablePopRowSelection = { selectedRowKeys: tablePopSelectedRowKeys, onChange: onTablePopSelectChange };
        const allowDel = selectedRowKeys.length > 0;
        return (
            <div className="itemBank-modal">
                <Spin spinning={modalsLoading}>
                    <div className="ibm-part">
                        <h2>基本信息</h2>
                        <Forms ref="forms" onSubmit={this.props.onOk} columns={formItemColumns} fieldsList={fieldsList}
                            hideFooter
                            formItemLayout={formItemLayout} />
                    </div>
                    <div className="ibm-part">
                        <h2>试题</h2>
                        <div className="ant-form ant-form-horizontal">
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-4"></div>
                                <div className="ant-col ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-20">
                                    <div className="sp-bars">
                                        <div className="sp-btns">
                                            <Button onClick={selectQuestion} type="primary">手动选择</Button>
                                            <Divider type="vertical" />
                                            <Button onClick={selectQuestionAuto}>自动生成</Button>
                                            <Divider type="vertical" />
                                            {/* <Button disabled={!allowDel} onClick={deleteItem}>删除</Button> */}
                                            <Popconfirm
                                                title="确定要删除吗?"
                                                visible={visible}
                                                onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                                onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                                onCancel={cancel}
                                                okText="确定"
                                                cancelText="取消" >
                                                <Button disabled={!allowDel} type="default">删 除</Button>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                    <Table size="small" rowKey="id" bordered rowSelection={rowSelection} columns={columns}
                                        dataSource={modalOptions.lists} />
                                    <TablePopModal title="选择试题" searchName="题目或者选项"
                                        visible={modalOptions.tablePopVisible}
                                        columns={tablePopColumns} dataSource={modalOptions.tablePopLists}
                                        rowSelection={tablePopRowSelection} onCancel={onTablePopCancel}
                                        onOk={onTablePopOk} searchFieldsList={searchFieldsList}
                                        noSearch={false}
                                        fetchData={tablePopFetchData}/*onRow={(record) => ({onClick: () => {selectRow(record)}})}*/ />
                                    <PcModal fieldsList={fieldsListAuto} modal={modal} formItemLayout={formItemLayout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default ExamQuestionSelectModal;
