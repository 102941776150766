import React, { Fragment } from 'react';
import { Card, Popconfirm, message } from 'antd';
import { StandardTable } from '../../../sharedCommponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane, removeTabPane } from '../../../action/menuTab.action';
import { getTabPaneData } from '../../../common/utils';
import AllComponents from '../../../components';
import { convertListToTreeJson } from '../../../common/utils';
import { ButtonAuth, ALinkAuth } from '../../../sharedCommponents/AuthWidget';
import { get, delHttp } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SearchForm from './SearchForm';
import appConfig from './../../../configs/app.config';
import pcService from './../../../other/pc.service';
import contantConfig from './../../../configs/constant.config'
class OranizationList extends React.Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        selectedRows: [],
        data:
        {
            list: [],
            pagination: {
                pageIndex: 0,
                pageSize: appConfig.maxPageSize,
                total: 0
            }
        },
        searchInitValue: {
            keyword: ''
        }
    }

    columns = [
        {
            title: '操作',
            width: 240,
            align: 'center',
            render: (text, record) => (
                <Fragment>
                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        onClick={() => this.handleEdit(record)}
                        authCode={'97553CCFA2D944D298B7EBC55F721954'}
                    >
                        编辑
                    </ALinkAuth>
                    <Popconfirm title="您确定要删除吗?" onConfirm={() => this.handleDelete(record)} okText="是" cancelText="否">
                        <ALinkAuth
                            // href="javascript:;"
                            authCode={'5A7B1FA22ACB4494AF55ECAA1762E8F8'}
                        >
                            删除
                        </ALinkAuth>
                    </Popconfirm>
                </Fragment>
            ),
        },
        {
            title: '名称',
            dataIndex: 'structureName'
        },
        {
            title: '编号',
            dataIndex: 'structureCode'
        },
        {
            title: '备注',
            dataIndex: 'remark'
        }
    ]

    componentWillMount() {
        this.fetch({});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.reloadState && nextProps.reloadState !== this.props.reloadState) {
            const params = {
                pagination: {
                    pageIndex: 0
                }
            };
            this.fetch(params);
        }
    }



    fetch = (params = {}) => {
        this.setState({ loading: true });
        let { pagination } = this.state.data;
        let pageIndex = pagination.pageIndex;
        let pageSize = pagination.pageSize;
        let keyword = this.state.searchInitValue.keyword;
        if (params) {
            if (params.pagination) {
                pageIndex = params.pagination.pageIndex || 0;
                pageSize = params.pagination.pageSize || pageSize;
            }
            if (!params.keyword) {
                keyword = '';
            }
            else {
                keyword = params.keyword
            }
        }
        let queryParams = {
            pageIndex,
            pageSize,
            keyword
        };
        get({
            url: apiConfig.rui.basic.organization.getAllOrganizations,
            params: queryParams
        }).then((res) => {
            if (res) {
                let treeData = convertListToTreeJson(res.list, '0', 'subStructure', 'structureId', 'pLevel');
                this.setState({
                    loading: false,
                    data: {
                        list: treeData,
                        pagination: {
                            pageIndex: queryParams.pageIndex,
                            pageSize: queryParams.pageSize,
                            total: res.total
                        }
                    },
                    searchInitValue: {
                        keyword: queryParams.keyword
                    }
                });
            }
            else {
                this.setState({ loading: false });
            }
        }).catch((error) => {
            this.setState({ loading: false });
        })
    }

    handleSelectRows = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRows,
            selectedRowKeys
        });
    };

    handleStandardTableChange = pagination => {
        const params = {
            pagination: {
                pageIndex: pagination.current - 1,
                pageSize: pagination.pageSize
            }
        };
        this.fetch(params);
    };

    handleSearch = (seacheValues) => {
        //console.log("seacheValues",seacheValues);
        let params;
        if (seacheValues) {
            params = {
                pagination: {
                    pageIndex: 0
                },
                keyword: seacheValues.keyword
            };
        }
        else {
            params = {
                pagination: {
                    pageIndex: 0
                },
                keyword: ''
            };
        }
        this.fetch(params);
    }

    handleDelete = (organization) => {
        //console.log("organization",organization);
        this.setState({ loading: true });
        delHttp({
            url: apiConfig.rui.basic.organization.deleteOrganization,
            params: {
                structureId: organization.structureId
            }
        }).then((res) => {
            const params = {
                pagination: {
                    pageIndex: 0
                }
            };
            message.success(contantConfig.DEL_SUCCESS);
            this.fetch(params);
        }).catch((error) => {
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading: false });
        })
    }

    handleDeleteAll = () => {
        let { selectedRowKeys } = this.state;
        let structureIds = '';
        if (selectedRowKeys.length === 0) {
            message.info('请选择要删除的组织机构');
            return;
        }
        for (let selectedRowKey of selectedRowKeys) {
            structureIds += selectedRowKey + ',';
        }
        if (structureIds) {
            structureIds = structureIds.substr(structureIds, structureIds.length - 1);
        }
        this.setState({ loading: true });
        delHttp({
            url: apiConfig.rui.basic.organization.deleteAll,
            params: {
                structureIds: structureIds
            }
        }).then((res) => {
            this.setState({
                selectedRows: [],
                selectedRowKeys: []
            }, () => {
                const params = {
                    pagination: {
                        pageIndex: 0
                    }
                };
                message.success(contantConfig.DEL_SUCCESS);
                this.fetch(params);
            });
        }).catch((error) => {
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading: false });
        });
    }

    handleAdd = () => {
        /*let { selectedMenuKey }=this.props;
        let paneKey="add-organization-pane";
        let CreateOrganizationForm=AllComponents["CreateOrganizationForm"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"新增组织机构",
            content: <CreateOrganizationForm paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });*/
        pcService.relativePath("/app/oranizationManageForParty");
    }

    handleEdit = (organization) => {
        /*let { selectedMenuKey }=this.props;
        let CreateOrganizationForm=AllComponents["CreateOrganizationForm"];
        let paneKey="edit-organization-pane";
        let addPane=getTabPaneData({
            key: paneKey,
            title:"编辑组织机构",
            content: <CreateOrganizationForm editId={organization.structureId} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        //this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/oranizationManageForParty?editId=" + organization.structureId);
    }

    render() {
        const {/*selectedRows,*/loading, data, searchInitValue } = this.state;
        return (
            <div className="mainDiv">
                <Card bordered={false}>
                    <div className='tableSearchForm'>
                        <SearchForm
                            handleSearch={this.handleSearch}
                            searchInitValue={searchInitValue}
                        />
                    </div>
                    <div className='tableAction'>
                        <ButtonAuth
                            icon="plus"
                            type="primary"
                            dataaction="create"
                            onClick={() => this.handleAdd()}
                            authCode={'74E82F926CBF4499B242A5CB87CF80C0'}
                        >
                            新增
                        </ButtonAuth>
                        <Popconfirm title="您确定要删除选中的组织机构吗?" onConfirm={() => this.handleDeleteAll()} okText="是" cancelText="否">
                            <ButtonAuth
                                icon="delete"
                                type="primary"
                                dataaction="delete"
                                loading={loading}
                                authCode={'177BFD80DFC14562B0BC78237FFA25DD'}
                            >
                                批量删除
                                        </ButtonAuth>
                        </Popconfirm>
                    </div>
                    <StandardTable
                        disableSelect={false}
                        loading={loading}
                        rowKey="structureId"
                        data={data}
                        columns={this.columns}
                        childrenColumnName="subStructure"
                        isNeedeSelectedKeys={true}
                        onSelectRow={this.handleSelectRows}
                        isTreeTable={true}
                        onChange={this.handleStandardTableChange}
                    />
                </Card>
            </div>
        )
    }
}


const mapStateToProps = state => {
    const { menuTab } = state;
    return {
        selectedMenuKey: menuTab.selectedMenuKey
    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(OranizationList);