import React, { Component } from 'react';
import { Upload, Icon, Modal } from 'antd';
import apiConfig from "../../../configs/api.config";
import urls from '../../../configs/api.config';
import { message } from 'antd';
import './picturesWall.less';
import { getLocal } from '../../utils';

/**
 * 照片墙上传图片;
 * 几个关键参数：limit 限制图片张数
 */
class VideoWall extends Component {

    state = {
        previewVisible: false,
        previewImage: '',
        fileList: [],
        updated: false,
        formValue: []
    }

    handleCancel = () => {
        this.setState({ previewVisible: false });
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true
        });
    }

    // 处理onchange事件，不知道上传失败,异常怎么处理
    handleChange = (info) => {
        const { value, onChange, item, form } = this.props;
        const { fileUpLoadOption = {} } = item;
        let file = info.file;
        let fileList = info.fileList;
        info.fileList.map(v => v.thumbUrl = `${urls.file.viewImg}/${v.response}`);
        // 上传成功,将fileList里面的uid,以逗号分割开来上传给服务端
        let formData = this.state.formValue;
        if (file.status && (file.status === 'success' || file.status === 'done')) {
            let responseData = file.response;// 这个是从文件服务器返回的id
            if (responseData.success !== undefined && !responseData.success) {
                message.error(responseData.message);
                this.setState({ updated: true, fileList: [] });
                return;
            }
            let array = [];
            if (formData && formData !== '' && typeof formData === "string") {
                array = formData.split(',');
            }
            array.push(responseData);
            formData = array.join(',');
            if (fileUpLoadOption.onChange) {
                fileUpLoadOption.onChange(info, form, onChange, value)
            }
        }
        this.setState({
            fileList: fileList,
            updated: true,
            formValue: formData
        });
    }

    /* 删除图片 */
    handleRemove = (file) => {
        let formData = this.state.formValue;
        const { onChange } = this.props;
        if (formData && formData != '') {
            let array = formData.split(",");
            let removeChar = file.response ? file.response : file.uid;
            let index = array.indexOf(removeChar);
            if (index > -1) {
                array.splice(index, 1);
            } else {
                array = [];
            }
            formData = array.join(",");
            this.setState({ formValue: formData, updated: true });
            onChange && onChange(formData);
        }
    }
    /* 上传前回调 */
    beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 200;
        if (!isLt2M) {
            message.error('视频文件最大 200MB!');
            return false;
        }
        return isLt2M;
    }

    /* 图片预览 */
    previewFile = (file) => {
        console.log("flie", file);
        return new Promise(a => {
            console.log(a);
        });
    }

    componentDidMount() {
        let { item } = this.props;
        let formValue = item.value;
        let fileList = [];
        // 取到url,构造fileList,这里的值是从form传过来的。传过来的value最好是一个图片对象，包含{uid,name,url}三个关键参数
        if (formValue) {
            // 判断是否为多图,多图以对象返回
            let imageIdArray = formValue.split(",");
            imageIdArray.forEach((imageId, index) => {
                const file = {
                    uid: imageId,
                    name: "视频文件",
                    status: "done",
                    url: apiConfig.file.viewImg + '/' + imageId
                };
                // 判断是否存在
                fileList.push(file);
            });
        }
        this.setState({ fileList });
    }

    render() {
        const { previewVisible, previewImage, fileList = [] } = this.state;
        const { handlePreview, handleChange, handleRemove, handleCancel, beforeUpload } = this;
        const { fileUpLoadOption = {} } = this.props.item;
        const { listType = "picture-card", accept = "video/*", limit = 1 } = fileUpLoadOption;
        const headers = getLocal("poc_headers");
        const uploadButton = (
            <div className="ms-uploadButton" title="选择图片上传">
                <Icon type="plus" />
            </div>
        );
        return (
            <div className="ms-pcitureWall video-wall clearfix">
                <Upload
                    action={apiConfig.file.uploadGetId} //图片服务器接口
                    accept={accept}
                    listType={listType}
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    onPreview={handlePreview}//点击预览事件
                    onChange={handleChange}
                    onRemove={handleRemove}
                    multiple={true}
                    headers={headers}
                //previewFile={this.previewFile}
                >
                    {fileList.length >= limit ? null : uploadButton}
                </Upload>
                <Modal{...{
                    visible: previewVisible,
                    width: 800,
                    centered: true,
                    footer: null,
                    onCancel: handleCancel
                }}>
                    <div className="mspmodal-body">
                        <video className="modal-img" controls alt="example" src={previewImage} ></video>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default VideoWall;