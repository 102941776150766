import React, { Component } from 'react';
import http from '../../common/axios/request';
import { message, Spin, Icon } from 'antd';
import urls from './../../configs/api.config';
import './info-ddetail.less';
import pcService from '../../other/pc.service';
import comments from '../../img/comment.png';
import look from '../../img/look.png';
import corrnect from '../../img/corrnect.png';


export class informationDeliveryDetail extends Component {

    state = {
        loading: false,
        info: {},
        comment: []
    }

    /* 获取列表数据 */
    fetchData = () => {
        let { query = {} } = this.props.location;
        this.setState({ loading: true }, () => {
            http.all([
                http.request({
                    method: "get",
                    url: urls.app.informationRelease.findById,
                    params: {
                        id: query.id
                    }
                }),
                http.request({
                    method: "get",
                    url: urls.app.comment.page,
                    params: {
                        resourceId: query.id
                    }
                })
            ]).then(reses => {
                if (reses.every(v => v.success)) {
                    this.setState({
                        loading: false,
                        info: reses[0].data,
                        comment: reses[1].data.rows || []
                    });
                } else {
                    this.setState({ loading: false });
                }
            }).catch(erres => {
                this.setState({ loading: false });
            })
        });
    }

    /* 删除评论 */
    deleteItem = (ids) => {
        let that = this;
        this.setState({ loading: true });
        pcService.deleteItem(urls.app.comment.logic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.componentWillMount();
                }, 500);
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    /*  */
    componentWillMount() {
        this.fetchData();
    }

    render() {
        const { loading, info, comment } = this.state;
        const { deleteItem } = this;
        return (
            <div className="info-ddetail">
                <Spin spinning={loading}>
                    <h1>{info.title}</h1>
                    <div className="author-date">发布时间：{pcService.dateFormat(info.releaseTime, "yyyy-MM-dd")} 作者：{info.author}</div>
                    <div className="info-ddetail-cont">
                        {info.videoFileMongdbId ? <div>
                            <video controls="controls" src={`${urls.file.viewImg}/${info.videoFileMongdbId}`} ></video>
                        </div> : null}
                        <div dangerouslySetInnerHTML={{ __html: info.content }}></div>
                    </div>
                    <div className="info-comment">
                        <h3>
                            <div className="total">
                                {/* <span title={`点赞数:${info.praiseCount}`}><Icon type="like" /> : {info.praiseCount}</span> */}
                                <span title={`评论数:${info.commentCount}`}><img src={comments} /> : {info.commentCount}</span>
                                <span title={`浏览数:${info.browseCount}`}><img src={look} />: {info.browseCount}</span>
                                <span title={`收藏数:${info.collectionCount}`}><img src={corrnect} /> : {info.collectionCount}</span>
                            </div>
                            党员评论
                            </h3>
                        <div className="info-comment-cont">
                            {comment.length > 0 ? comment.map((v, n) => (
                                <div key={n} className="icc-item">
                                    <div className="header">
                                        <div className="header-icon">
                                            <img height="42" width="42" src={`${urls.file.viewImg}/${v.headImg}`} alt={v.publisherName} />
                                        </div>
                                        {v.publisherName}
                                        <span title="删除" onClick={deleteItem.bind(this, v.id)} className="del">
                                            <Icon type="delete" />
                                        </span>
                                    </div>
                                    <div className="content">
                                        {v.content || "无"}
                                    </div>
                                </div>
                            )) : <p>
                                    暂无评论!
                            </p>}
                        </div>
                    </div>
                </Spin>
            </div >
        )
    }
}

export default informationDeliveryDetail;
