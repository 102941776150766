import React from 'react';
import {TreeSelect} from 'antd';
import appConfig from './../../../configs/app.config';
import { get } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import {convertListToTreeJson} from '../../../common/utils';
const { TreeNode } = TreeSelect;

export default class SelectMenuTree extends React.Component {

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
          const newState= {
             value: nextProps.value || '',
          };
          return newState;
        }
        return null;
   }

   constructor(props)
   {
       super(props);

       const value = props.value ||'';
       this.state = {
            loading:false,
            value,
            treeDefaultExpandedKeys:[],
            treeData:[]
       };
   }

   componentDidMount()
   {
       this.handleFocus();
   }

    handleFocus=()=>{
         if(this.state.treeData.length===0)
         {
            let roles=[];
            let rootRole;
            rootRole=appConfig.initRole;
            let that=this;
            that.setState({loading:true});
            get({
                url:apiConfig.rui.basic.role.getAllRoles,
                params:{
                    pageIndex:0,
                    pageSize:appConfig.maxPageSize
                }
            }).then((res)=>{
                 if(res&&res.list)
                 {
                    let treeData=convertListToTreeJson(res.list,'0','subRole','roleId','levelBy');
                    if(rootRole)
                    {
                        rootRole.subRole=treeData;
                        roles=[
                           {
                               ...rootRole
                           }
                        ];
                    }
                    else
                    {
                        roles=[
                             ...treeData
                        ];
                    }
                 }
                 else
                 {
                    if(rootRole)
                    {
                        roles=[
                            {
                                ...rootRole
                            }
                         ];
                    }
                 }
                 that.setState({
                     loading:false,
                     treeData:roles
                 });
            }).catch((error)=>{
                that.setState({ loading:false});
             });
         }
    }

    handleLoadData = treeNode=>new Promise((resolve) => {
            if (treeNode.props.dataRef.subRole) {
              resolve();
              return;
            }
            get({
                url:apiConfig.rui.basic.role.getSubRoles,
                params:{
                    pageIndex:0,
                    pageSize:appConfig.maxPageSize,
                    roleId:treeNode.props.dataRef.roleId
                }
            }).then((res)=>{
                if(res&&res.list&&res.list.length>0)
                {
                    treeNode.props.dataRef.subRole=res.list;
                    this.setState({
                        treeData: [...this.state.treeData],
                    });
                }
                resolve();
            }).catch((error)=>{
                this.setState({ loading:false});
                 resolve();
            });
    });

    handleSelect = (value,node, extra) => {
        if(!value)
        {
            return;
        }
        else
        {
            if (!('value' in this.props)) {
                this.setState({ value });
            }
            const selectTreeData=node.props.dataRef;
            const setSelectTreeData = this.props.setSelectTreeData;
            if(setSelectTreeData)
            {
                setSelectTreeData(selectTreeData);
            }
            this.triggerChange(value);
        }
    }

    triggerChange = (changedValue) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    };

    renderTreeNodes = data => data.map((item) => {
        if (item.subRole) {
          return (
            <TreeNode title={item.roleName} key={item.roleId} value={item.roleId} dataRef={item}>
              {this.renderTreeNodes(item.subRole)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.roleName} key={item.roleId} value={item.roleId} dataRef={item} isLeaf />;
    })

    render() {
      let { /*loading,*/ treeData,value/*,treeDefaultExpandedKeys*/ }=this.state;
      return (
                 <TreeSelect 
                          showSearch
                          treeNodeFilterProp='title' 
                          placeholder="请选择父角色"
                          value={value}
                          onSelect={this.handleSelect} 
                          onFocus={this.handleFocus}
                 >
                    {this.renderTreeNodes(treeData)}
                 </TreeSelect>
         )
      }
}