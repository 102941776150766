import React, { Component } from 'react';
import { Input, Select, InputNumber, Button, TreeSelect } from 'antd';
import './addon-form.less';

export class AddOnForm extends Component {

    render() {
        const { placeholder = "", disabled = false, value = "", onChange, item, form } = this.props;
        const { addOnOptions = {}, opts = [] } = item;
        const { addOn = {} } = addOnOptions;
        const { attrs = {}, AddOn = () => <Button>确定</Button> } = addOn;
        const selectOnchange = (_item, _form, _value, _title) => {
            onChange(_value);
            _item.onChange && _item.onChange(_item, form, _value, opts);
        };
        const inputOnchange = (_value) => {
            onChange(_value);
            item.onChange && item.onChange(item, form, _value);
        }
        const renderTreeData = (opts = []) => opts.map(v => ({
            ...v,
            title: v.name,
            value: v.id,
            key: v.id,
            children: renderTreeData(v.children)
        })
        );
        //nodeSelect onChnage事件
        const nodeOnChange = (_item, form, _values, _titles, $event) => {
            onChange(_values);
            _item.onChange && _item.onChange(_item, form, _values, _titles, opts, $event);
        }

        return (
            <div className="addon-forms">
                <div className="af-lefts">
                    {((cell) => {
                        let othersProps = { placeholder, disabled, value, onChange }
                        switch (cell.editor) {
                            case "normal":
                                return <Input {...othersProps} onChange={inputOnchange} />;
                            case "number":
                                return <InputNumber min={item.range ? item.range[0] : 0} max={item.range ? item.range[1] : 0}  {...othersProps} onChange={inputOnchange} />;
                            case "select":
                                return <Select {...othersProps} allowClear onChange={selectOnchange.bind(this, item, form)} >
                                    {
                                        opts.map((ite, inde) => <Select.Option key={inde} value={ite.id}>{ite.name}</Select.Option>)
                                    }
                                </Select>;
                            case "textarea":
                                return <Input.TextArea {...othersProps} onChange={inputOnchange} />
                            case "node-select":
                                return (
                                    <TreeSelect disabled={item.unavailable} value={value} treeData={renderTreeData(opts)} onChange={nodeOnChange.bind(this, item, form)} showSearch
                                        placeholder={'请选择' + item.displayName} treeNodeFilterProp="title" {...addOnOptions.nodeOptions} >
                                    </TreeSelect>
                                )
                            default:
                                return <Input {...othersProps} onChange={inputOnchange} />;
                        }
                    })(addOnOptions)}
                </div>
                <div className="af-right">
                    <AddOn {...attrs} />
                </div>
            </div>
        )
    }
}

export default AddOnForm
