import {getLocal,setLocal,removeLocal, isNotEmpty} from '../common/utils';
import { get } from '../common/axios';
import apiConfig from './api.config';
import appConfig, {getTenantSetCurrentCache} from "./app.config";

/**
 * 应用本地存储名称
 */
const CurrentMenusCacheKey="current_menus"; //当前菜单
const CurrentActiveMenu="current_histories"; //最近使用
const AuthoritiesCacheKey="current_authorities"; //当前功能

const getCurrentMenus=()=>{
    let menus=[];
    let menusStr=getLocal(CurrentMenusCacheKey);
    if(menusStr&&menusStr!=='')
    {
        menus=JSON.parse(menusStr);
    }
    return menus;
};

export const removeCurrentMenus=()=>{
    removeLocal(CurrentMenusCacheKey);
    removetAuthoritiesCache();
};

export const SetCurrentMenusCache=(menus)=>{
    setLocal(CurrentMenusCacheKey,JSON.stringify(menus));
    setAuthoritiesCache(menus); // 设置所有功能到本地存储
};

export const menuInit=()=>{
     let url=apiConfig.rui.login.getMenus;
     return get(!appConfig.isUseTenant&&appConfig.isSelectTenant ? {
         url: url,
         params: {
             tenantId: getTenantSetCurrentCache(),
             settingId: appConfig.isUseSystemSet?'enable_login_setting':''
         }
     }:{
         url: url,
         params: {
             settingId: appConfig.isUseSystemSet?'enable_login_setting':''
         }
     });
};

export const getAuthoritiesCache=()=>{
    let authorities=[];
    let authoritiesStr=getLocal(AuthoritiesCacheKey);
    if(isNotEmpty(authoritiesStr))
    {
        authorities=JSON.parse(authoritiesStr);
    }
    return authorities;
};

const setAuthoritiesCache=(menus)=>{
     const authorities=getAuthorities(menus);
     setLocal(AuthoritiesCacheKey,JSON.stringify(authorities));
};

const removetAuthoritiesCache=()=>{
    removeLocal(AuthoritiesCacheKey);
};

/**
 * 解析抽取菜单对象中所有功能
 */
const getAuthorities=(menus)=>{
    let authorities=[];
    if(menus)
    {
        for(let menu of menus)
        {
            if(isNotEmpty(menu.authorities))
            {
                for(let authority of menu.authorities)
                {
                    if(authorities.indexOf(authority.authorityCode)===-1)
                    {
                        authorities.push(authority.authorityCode);
                    }
                }
            }
            if(isNotEmpty(menu.subMenu))
            {
               let auths =getAuthorities(menu.subMenu);
               for(let auth of auths)
               {
                    if(authorities.indexOf(auth)===-1)
                    {
                        authorities.push(auth);
                    }
               }
            }
        }
    }
    return authorities;
};

// 获取常用菜单数据
export const getCurrentActiveMenu=()=>{
    let activeMenus = [];
    let activeMenusStr = getLocal(CurrentActiveMenu);
    if(activeMenusStr&&activeMenusStr!==''){
        activeMenus=JSON.parse(activeMenusStr);
    }
    return activeMenus;
};

// 存储当前点击的菜单
export const setCurrentActiveMenu=(menu)=>{
    if(menu)
    {
        let currentMenu = getCurrentActiveMenu();
        let targetMenu = currentMenu.filter(item => item && item.menuId !== menu.menuId);
        targetMenu.unshift(menu); // 从开头位置添加进去，最新记录在前
        setLocal(CurrentActiveMenu, JSON.stringify(targetMenu));
    }
};

export const removeCurrentActiveMenu=()=>{
    removeLocal(CurrentActiveMenu);
};

export default getCurrentMenus;
