import React, { Component } from 'react';
import PcService from '../../other/pc.service';
import AttachList from './AttachList';
import './showList.less';

class ShowList extends Component {

    render() {

        const { lists = [], columns = 1 } = this.props;
        return (
            <div className={`pmd-show columns-col${columns}`}>
                {lists.map((v, i) => <p key={i} className={v.columns === 1 ? 'clear-fix' : ''}><span>{v.displayName} ：</span>{v.enumsArr ? PcService.numberToText(v.value ? v.value.toString()
                    : v.value, v.enumsArr) : v.name.indexOf("EnclosureList") !== -1 && v.value && v.value.length ? <AttachList itemAttach={v.value} /> : v.value}</p>)}
            </div>
        )
    }
}

export default ShowList
