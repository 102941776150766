import React, { Component } from 'react';
import { Row, Spin, Button, Divider, Icon, Popconfirm, message } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import http from '../../common/axios/request';
import './votingManagement.less';

class VotingManagement extends Component {

    state = {
        visible: false,
        type: 1,//1为投人,2为投事
        title: "民主投票",
        loading: false,
        selectedRowKeys: [],
        selectedRecords: [],
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: this.props.title,
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        lists: [],
        orgAndUsers: []
    }

    /* 表格列项 */
    columns = [

        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1

        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, event) => {
                return <span>
                    {record.publishStatus === "10000" ? (
                        <span>
                            <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" theme="outlined" /> 编辑</a>
                            <Divider type="vertical" />
                            <Popconfirm title="确定要发布吗？" onConfirm={this.publishItem.bind(this, record)} okText="确定"
                                cancelText="取消">
                                <a><Icon type="check-circle" /> 发布</a>
                            </Popconfirm>
                        </span>
                    ) : (record.publishStatus === "10001" ? (
                        <span>
                            <Popconfirm title="确定要撤销吗？" onConfirm={this.publishItem.bind(this, record)} okText="确定"
                                cancelText="取消">
                                <a><Icon type="close-circle" /> 撤销</a>
                            </Popconfirm>
                            <Divider type="vertical" />
                            <a onClick={this.detailItem.bind(this, record)}><Icon type="info-circle" /> 详情</a>
                        </span>
                    ) : (
                            <span>
                                <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" theme="outlined" /> 编辑</a>
                            </span>
                        ))}
                </span>
            }
        }, {
            title: '标题',
            dataIndex: 'title',
            key: 'title'
        }, {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text) => pcService.numberToText(text, pcService.getDataDictionary("ACTIVITY_STATUS"))
        }, {
            title: '开展组织',
            dataIndex: 'orgName',
            key: 'orgName'
        }, {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text) => pcService.dateFormat(text, "yyyy-MM-dd hh:mm")
        }, {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (text) => pcService.dateFormat(text, "yyyy-MM-dd hh:mm")
        }, {
            title: '每人可投票数',
            dataIndex: 'maxVotes',
            key: 'maxVotes'
        },
        {
            title: '是否可重复投票',
            dataIndex: 'ableRepeatVote',
            key: 'ableRepeatVote',
            render: (text) => text ? "是" : "否"
        },
        {
            title: "发布状态",
            key: "publishStatus",
            dataIndex: "publishStatus",
            render: (text) => pcService.numberToText(text, pcService.getDataDictionary("PUBLISH_STATUS"))
        }
    ];

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "type",
        editor: "select",
        value: "",
        opts: [
            { id: "", name: "全部" },
            { id: "1", name: "投人" },
            { id: "2", name: "投事" }
        ],
        originValue: "",
        displayName: "投票类型"
    }, {
        name: "status",
        editor: "select",
        value: "",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("ACTIVITY_STATUS")),
        originValue: "",
        displayName: "当前状态"
    }, {
        name: "orgId",
        editor: "node-select",
        value: '',
        originValue: "",
        opts: [],
        displayName: "开展组织"
    }, {
        name: "startTimeStr",
        editor: "datePicker",
        value: "",
        originValue: null,
        displayName: "开始时间",
        timeOptions: {
            format: "YYYY-MM-DD HH:mm",
            showTime: true
        }
    }, {
        name: "endTimeStr",
        editor: "datePicker",
        value: "",
        originValue: null,
        displayName: "结束时间",
        timeOptions: {
            format: "YYYY-MM-DD HH:mm",
            showTime: true
        }
    }, {
        name: "value",
        editor: "normal",
        value: "",
        originValue: "",
        hideDisplayName: true,
        displayName: "标题"
    }];

    /* 投票详情 */
    detailItem = (item) => {
        pcService.relativePath(`/app/votingDetail?type=${item.type}&id=${item.id}`);
    }

    /* 发布撤销操作 */
    publishItem = (item) => {
        let msg = item.publishStatus === "10000" ? "发布" : "撤销";
        let votingId = item.id;
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.app.voting.publishStatus,
                params: { votingId }
            }).then((res) => {
                if (res.success) {
                    message.success(msg + "操作成功！");
                    this.componentDidMount();
                    return;
                }
                this.setState({ loading: false })
            }).catch((err) => {
                this.setState({ loading: false })
            })
        });
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys, selectedRecords) => {
        this.setState({ selectedRowKeys, selectedRecords });
    };

    /* 获取列表数据 */
    fetchData = (params = {}) => {
        let { pagination, type } = this.state;
        params["endTimeStr"] = params["endTimeStr"] ? pcService.dateFormat(params["endTimeStr"], "yyyy-MM-dd hh:mm:ss") : params["endTimeStr"];
        params["startTimeStr"] = params["startTimeStr"] ? pcService.dateFormat(params["startTimeStr"], "yyyy-MM-dd hh:mm:ss") : params["startTimeStr"];
        let { pageSize, current } = this.state.pagination;
        let _params = Object.assign({}, { pageSize, current, type, fields: "title" }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.voting.page, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                this.setState({ loading: false, lists: data.rows, pagination: _pagination });
            }, () => {
                this.setState({ loading: false })
            });
        });
    }

    /* 搜索 */
    seachOnSubmit = (form) => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }

    /* 新增修改民主评议 */
    createOne = (item, create = true) => {
        if (!!create) {
            pcService.relativePath(`/app/votingAdd`);
        } else {
            if (item.publishStatus !== "10001") {
                pcService.relativePath(`/app/votingUpdate?id=${item.id}`);
            };

        }
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = "", method = "post", msg = "";
                if (create) {
                    url = urls.app.bannerLocation.addEntity;
                    msg = "新增";
                } else {
                    url = urls.app.bannerLocation.updateEntity;
                    msg = "编辑";
                }
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                });
                http.request({
                    method,
                    url,
                    data: { ...values }
                }).then(res => {
                    if (res.success) {
                        message.success(msg + "操作成功!");
                    }
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    }, this.fetchData);
                }).catch(err => {
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    });
                })
            }
        });
    }

    /* 删除 */
    deleteItem = (selectedRowKeys = [], selectedRecords = []) => {
        let ids = "";
        if (typeof selectedRowKeys === "string") {
            ids = selectedRowKeys;
        } else {
            ids = selectedRowKeys.join(",");
        }
        let that = this;
        this.setState({ loading: true });
        pcService.deleteItem(urls.app.voting.logicDelete, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.componentDidMount();
                }, 500);
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }
    /* 获取党组织下面的党员信息 */
    getpartyMember = () => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                params: { flag: true, type: 0 },
                url: urls.app.organization.selectStructureTreeById
            }).then(res => {
                if (res.success) {
                    let opts = res.data || [];
                    let orgAndUsers = pcService.renderOrgAndUserNodes({ data: opts, type: 1 });
                    this.searchFieldsList.map(v => {
                        if (v.name === "orgId") {
                            v.opts = [{ id: "", name: "全部" }].concat(orgAndUsers);
                            return;
                        }

                    });
                    this.setState({ loading: false, orgAndUsers });
                } else {
                    this.setState({ loading: false });
                }
            }).catch(() => {
                this.setState({ loading: false });
            })
        })
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentDidMount() {
        let form = this.refs.search.getForm();
        this.seachOnSubmit(form);
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        this.getpartyMember();
    }

    render() {
        const { loading, visible, selectedRowKeys, selectedRecords, pagination, lists, modalOptions, create } = this.state;
        const { onSelectChange, cancel, handleVisibleChange, columns, fetchData, searchFieldsList, seachOnSubmit, deleteItem, createOne, fieldsList, onOk, onCancel } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const allowDel = selectedRowKeys.length > 0 && selectedRecords.every(v => v.publishStatus !== "10001");
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <div className="voting">
                <Spin spinning={loading}>
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-forms">
                                <PcForm showReset ref="search" layout="inline" submitText="搜索" onSubmit={seachOnSubmit} fieldsList={searchFieldsList} />
                            </div>
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys, selectedRecords)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                </Popconfirm>
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                    <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                </Spin>
            </div>
        )
    }
}

export default VotingManagement;
