import React from 'react'
import AllComponents from '../components';
import IframeContainer from './IframeContainer';
import BaseComponent from './BaseComponent';

/**
 * tabPane自定义组件
 */
class TabPaneComponent extends BaseComponent {

    componentDidMount() {}

    render() {
      const { selectMenu,isExternal }=this.props;     
      let Component;
      if(!isExternal)
      {
        //工程本地路由组件
        Component=AllComponents[selectMenu.menuUrl];
        if(!Component)
        {
          //Component=NotFound;
        }
      }
      else
      {
        //外网业务功能组件
        Component=IframeContainer;
      }
      return (
            <div>
                <Component {...this.props}/>
            </div>
         )
      }
}

const getTabPaneComponent=(selectMenu,isExternal,reloadState=0,...props)=>{
     return <TabPaneComponent selectMenu={selectMenu} isExternal={isExternal} reloadState={reloadState} {...props}/>
};

export default getTabPaneComponent;