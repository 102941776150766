import React, { Component } from 'react';
import { Player } from 'video-react';
import { message } from 'antd';
import appConfig from './../../../configs/app.config';

export class VideoReact extends Component {

    render() {
        const { dataInfo } = this.props;
        let url;
        if (dataInfo.videoMongdbId) {
            url = dataInfo.videoMongdbId.length ? appConfig.pocAddress + 'views/' + dataInfo.videoMongdbId : message.info("未加载到视频文件");
        }
        return (
            <Player ref="player" videoId="video-1">
                <source src={url} />
            </Player>
        )
    }
}

export default VideoReact
