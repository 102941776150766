import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import moment from 'moment';
import { Row, Spin, Button, Divider, message, Icon, Popconfirm } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import http from './../../common/axios/request';
import PcService from './../../other/pc.service';
import { connect } from 'react-redux';
import urls from './../../configs/api.config';
import './targetedPovertyReduction.css';
const ButtonGroup = Button.Group;

export class TargetedPovertyReduction extends Component {
    state = {
        loading: false,
        visible: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        dataList: [],
        displayTip: 'none',
    }

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        }, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <Divider type="vertical" />
                        <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" theme="outlined" /> 详情</a>
                        {
                            record.publishStatus === "10001" && record.mode !== "append" ? null : <a onClick={this.editItem.bind(this, record)} ><Divider type="vertical" />
                                <Icon type="edit" theme="outlined" /> 编辑</a>
                        }
                        {
                            record.mode === "append" ? null : (
                                record.activityStatus === "20002" && record.publishStatus === "10001" ? <a onClick={this.uploadItem.bind(this, record)} ><Divider type="vertical" />
                                    <Icon type="edit" theme="outlined" /> 上传资料</a> :
                                    record.publishStatus !== PcService.getDataDictionary("PUBLISH_STATUS")[1].id ?
                                        <Popconfirm title="确定要发布吗？" onConfirm={this.sendItem.bind(this, record, PcService.getDataDictionary("PUBLISH_STATUS")[1].id)} okText="确定"
                                            cancelText="取消">
                                            <a ><Divider type="vertical" /><Icon type="notification" /> 发布</a>
                                        </Popconfirm>
                                        : <Popconfirm title="确定要撤回吗？" onConfirm={this.sendItem.bind(this, record, PcService.getDataDictionary("PUBLISH_STATUS")[2].id)} okText="确定"
                                            cancelText="取消">
                                            <a ><Divider type="vertical" /><Icon type="notification" /> 撤回</a>
                                        </Popconfirm>
                            )
                        }
                        <Divider type="vertical" />
                        <a onClick={this.signItem.bind(this, record)} ><Icon type="highlight" theme="outlined" /> 签到情况</a>
                    </span>
                )
            }
        }, {
            title: '活动名称',
            dataIndex: 'activityTitle',
            key: 'clode'
        }, {
            title: '贫困人员',
            dataIndex: 'difficultUserName',
        }, {
            title: '开始时间',
            dataIndex: 'activityBeginTime',
            render: (text, record, index) => text ? text.substring(0, 16) : text
        }, {
            title: '结束时间',
            dataIndex: 'activityEndTime',
            render: (text, record, index) => text ? text.substring(0, 16) : text
        }, {
            title: '活动地点',
            dataIndex: 'activityPlace'
        }, {
            title: '签到开始时间',
            dataIndex: 'signBeginTime',
            render: (text, record, index) => text ? text.substring(0, 16) : text
        }, {
            title: '签到结束时间',
            dataIndex: 'signEndTime',
            render: (text, record, index) => text ? text.substring(0, 16) : text
        }, {
            title: '发布状态',
            dataIndex: 'publishStatus',
            render: (text, record, index) => PcService.numberToText(text, PcService.getDataDictionary("PUBLISH_STATUS"))
        }, {
            title: '活动状态',
            dataIndex: 'activityStatus',
            render: (text, record, index) => {
                return PcService.numberToText(text, PcService.getDataDictionary("ACTIVITY_STATUS"))
            }
        }
    ];

    seachFieldsList = [{
        name: "activityStatus",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "活动状态",
        opts: [{ id: "", name: "全部" }].concat(PcService.getDataDictionary("ACTIVITY_STATUS"))
    }, {
        name: "activityTime",
        editor: "rangePicker",
        originValue: null,
        value: '',
        timeOptions: {
            format: 'YYYY-MM-DD'
        },
        displayName: "活动时间"
    },
    {
        name: "activityTitle",
        editor: "normal",
        value: "",
        displayName: "活动名称",
        originValue: ""
    }];

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params, { category: PcService.getDataDictionary("ACTIVITY_CATEGORY")[2].id, activityType: "orglife006" });
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.partyActivity.page, _params, null, (data) => {
                let _pagination = PcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.displayTip = 'none';
                });
                this.setState({
                    loading: false,
                    dataList: lists,
                    pagination: _pagination
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    }

    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                let activityBeginTime = values.activityTime && values.activityTime.length ? values.activityTime[0]._d : '';
                let activityEndTime = values.activityTime && values.activityTime.length ? values.activityTime[1]._d : '';
                values.activityBeginTime = activityBeginTime;
                values.activityEndTime = activityEndTime;
                delete values.activityTime;

                this.fetchData(values);
            }
        });
    }

    /* table功能 */
    sendItem = (item, status) => {
        let data = {
            id: item.id,
            publishStatus: status
        }
        this.setState({ loading: true });
        http.request({
            method: 'post',
            url: urls.app.partyActivity.updatePublishStatus,
            params: data
        }).then(res => {
            if (res.success === true) {
                message.success(PcService.numberToText(status, PcService.getDataDictionary("PUBLISH_STATUS")) + "成功");
                this.fetchData();
                this.setState({ loading: false });
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    newPage = () => {
        PcService.relativePath("/app/targetedPovertyReductionNew");
    }
    supplementPage = () => {
        PcService.relativePath("/app/targetedPovertyReductionSupplement");
    }
    editItem = (item) => {
        let id = item.id;
        PcService.relativePath(`/app/targetedPovertyReductionUpdate?recordId=${id}`);
    }
    uploadItem = (item) => {
        let id = item.id;
        PcService.relativePath(`/app/targetedPovertyReductionUpload?recordId=${id}`);
    }
    signItem = (item) => {
        let id = item.id;
        PcService.relativePath(`/app/targetedPovertyReductionSign?recordId=${id}`);
    }
    detailItem = (item) => {
        let id = item.id;
        PcService.relativePath(`/app/targetedPovertyReductionDetail?recordId=${id}`);
    }
    /* 删除 */
    deleteItem = (item = [], form, $event) => {
        //已发布：不可编辑、删除
        const { dataList } = this.state;
        let deleteList = dataList.filter(v => item.includes(v.id) && v.publishStatus === "10001")
        if (deleteList && deleteList.length !== 0) {
            let infoTitle = [];
            deleteList.map(v => infoTitle.push(v.activityTitle))
            message.info(`${infoTitle.join(",")}已发布状态，不可删除！`);
            return;
        }

        let ids;
        if (form === 'button') {
            ids = item.join(",");
        }
        let that = this;
        this.setState({ loading: true });
        PcService.deleteItem(urls.app.partyActivity.logic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                this.setState({ loading: false });
            }, err => {
                this.setState({ loading: false });
            }
        );
    }
    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }
    //鼠标移入事件
    onMouseEnter = (record) => {
        let { dataList = [] } = this.state;
        dataList.map((v, n) => {
            if (v.id === record.id) {
                v.displayTip = "block";
            } else {
                v.displayTip = "none";
            }
        })
        this.setState({
            dataList,
        })
    }
    onMouseLeave = () => {
        let { dataList = [] } = this.state;
        dataList.map((v, n) => {
            v.displayTip = "none";
        })
        this.setState({
            dataList,
        })

    }

    componentDidMount() {
        this.fetchData();
        PcService.initFormList(this.seachFieldsList);
    }
    render() {

        const { loading, selectedRowKeys, pagination, visible, dataList } = this.state;
        const { onSelectChange, columns, fetchData, seachFieldsList, seachOnSubmit, newPage, supplementPage, deleteItem, cancel, handleVisibleChange, onMouseLeave } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const allowDel = selectedRowKeys.length > 0;

        return (
            <Spin spinning={loading}>
                <div className="party-activ-page" onClick={onMouseLeave} >
                    <Row>
                        <div className="party-activ-bars">
                            <div className="party-activ-btns">
                                <Button onClick={newPage} icon="file-add" type="primary">新增</Button>
                                <Divider type="vertical" />
                                <ButtonGroup>
                                    <Button onClick={supplementPage} icon="snippets">补录</Button>
                                    <Divider type="vertical" />
                                    <Popconfirm
                                        title="确定要删除吗?"
                                        visible={visible}
                                        onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                        onConfirm={deleteItem.bind(this, selectedRowKeys, 'button')}
                                        onCancel={cancel}
                                        okText="确定"
                                        cancelText="取消" >
                                        <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                    </Popconfirm>
                                </ButtonGroup>
                            </div>
                            <div className="party-activ-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset fieldsList={seachFieldsList} />
                            </div>
                        </div>
                        <div className="party-activ-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={dataList}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
            </Spin >
        )
    }
}

export default TargetedPovertyReduction;
