import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import moment from 'moment';
import { getLocal } from './../../common/utils';
import Forms from './../../common/components/form/Forms';
import PcService from './../../other/pc.service';
import axios from "axios";
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import { convertListToTreeJson, setJsonArray } from "../../common/utils";
import { message, Button, Modal, Spin } from 'antd';

let userId = getLocal("poc_headers") ? getLocal("poc_headers").user_id : "";

export class OrganizationLifeSupplement extends Component {
    state = {
        isEdit: false, // 新增或编辑 
        mapModalVisible: false,
        locationItemId: "",
        locationItemX: "",
        locationItemY: "",
        publisherName: "",
        dataProcessorName: "",
        orgUnitName: "",
        signInfo: {
            lat: "0",
            lng: "0",
            radius: 200
        },
    }
    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "activityTitle",
            editor: "normal",
            value: "",
            displayName: "活动名称",
            rules: [
                {
                    required: true,
                    message: "请输入活动标题"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "activityPlace",
            editor: "normal",
            value: "",
            originValue: '',
            displayName: "活动地点",
            rules: [
                {
                    required: true,
                    message: "请输入活动地点"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: 'activityBeginTime',
            editor: 'datePicker',
            displayName: '活动开始时间',
            maxDate: 'activityEndTime',
            timeOptions: {
                format: "YYYY-MM-DD HH:mm",
                showTime: {
                    format: "YYYY-MM-DD HH:mm",
                    defaultValue: moment(PcService.dateFormat(null, "yyyy-MM-dd 00:00"))
                }
            },
            value: '',
            rules: ["required"]
        }, {
            name: 'activityEndTime',
            editor: 'datePicker',
            displayName: '活动结束时间',
            minDate: 'activityBeginTime',
            timeOptions: {
                format: "YYYY-MM-DD HH:mm",
                showTime: {
                    format: "YYYY-MM-DD HH:mm",
                    defaultValue: moment(PcService.dateFormat(null, "yyyy-MM-dd 00:00"))
                }
            },
            value: '',
            rules: ["required"]
            /* }, {
                name: 'signBeginTime',
                editor: 'datePicker',
                displayName: '签到开始时间',
                maxDate: 'signEndTime',
                timeOptions: {
                    format: "YYYY-MM-DD HH:mm",
                    showTime: {
                        format: "YYYY-MM-DD HH:mm",
                        defaultValue: moment(PcService.dateFormat(null, "yyyy-MM-dd 00:00"))
                    }
                },
                value: '',
                rules: ["required"]
            }, {
                name: 'signEndTime',
                editor: 'datePicker',
                displayName: '签到结束时间',
                minDate: 'signBeginTime',
                timeOptions: {
                    format: "YYYY-MM-DD HH:mm",
                    showTime: {
                        format: "YYYY-MM-DD HH:mm",
                        defaultValue: moment(PcService.dateFormat(null, "yyyy-MM-dd 00:00"))
                    }
                },
                value: '',
                rules: ["required"]
            }, {
                //签到地点
                name: "signPlace",
                editor: "position",
                value: "",
                displayName: "签到地址",
                originValue: "",
                rules: [
                    {
                        required: true,
                        message: "请输入签到地址"
                    }
                ],
                onChange: (onchange, point, errRange) => {
                    let positionArr = point.position.lng + "," + point.position.lat;
                    onchange("经纬度(" + positionArr + "), 误差" + errRange);
                    this.setState({
                        signInfo: {
                            lat: point.position.lat,
                            lng: point.position.lng,
                            radius: errRange
                        }
                    });
                }, */
        }, {
            name: "orgUnitId",
            editor: "node-select",
            treeCheckable: false,
            displayName: "活动组织单位",
            opts: [],
            nodeOptions: {
                multiple: false
            },
            onChange: (_item, form, _value, _tital, opts) => {
                this.setState({
                    orgUnitName: _tital[0]
                })
            },
            rules: [
                { message: '请选择活动组织单位', required: true }
            ],
        }, {
            name: "dataProcessorIdTreeShow",
            editor: "node-select",
            treeCheckable: false,
            displayName: "活动资料员",
            opts: [],
            nodeOptions: {
                multiple: false
            },
            onChange: (_item, form, _value, _tital, opts) => {
                this.setState({
                    dataProcessorName: _tital[0]
                })
            },
            rules: [
                { message: '请选择活动资料员', required: true }
            ],
        }, {
            name: "publisherIdTreeShow",
            editor: "node-select",
            treeCheckable: false,
            displayName: "活动负责人",
            opts: [],
            nodeOptions: {
                multiple: false
            },
            onChange: (_item, form, _value, _tital, opts) => {
                this.setState({
                    publisherName: _tital[0]
                })
            },
            rules: [
                { message: '请选择活动负责人', required: true }
            ],
        }, {
            name: "activityType",
            editor: "select",
            opts: PcService.getDataDictionary("ORG_LIFE_TYPE"),
            value: "",
            originValue: "",
            displayName: "活动类型",
            rules: [
                {
                    required: true,
                    message: "请选择活动类型"
                }
            ]
        }, {
            name: "withSecret",
            editor: "radio",
            displayName: "是否涉密",
            originValue: false,
            value: false,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: [
                {
                    message: '请选择是否涉密',
                    required: true
                }
            ],
        }, {
            name: "userIds",
            editor: "node-select",
            displayName: "活动人员",
            opts: [],
            columns: 1,
            value: null,
            nodeOptions: {
                treeCheckable: true
            },
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            rules: [
                { message: '请选择活动人员', required: true }
            ],
        }
    ];
    fieldsListPlan = [
        {
            name: "activityDescription",
            editor: "bEditor",
            value: "",
            columns: 1,
            displayName: "活动说明",
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
        },
        {
            name: "activityAttachments",
            editor: "attachments",
            value: [],
            columns: 1,
            displayName: "活动材料",
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            }
        }
    ];
    fieldsListRecord = [
        {
            name: "record",
            editor: "bEditor",
            value: "",
            columns: 1,
            displayName: "活动记录",
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
        },
        {
            name: "recordAttachments",
            editor: "attachments",
            value: [],
            columns: 1,
            displayName: "记录附件",
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            }
        }
    ];
    //删除Mongo文件  
    errUpload = (deleteMongoId) => {
        this.setState({ loading: true });
        http.request({
            method: "delete",
            url: urls.file.deleteMongo + '/' + deleteMongoId
        }).then(res => {
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    handleCancel = () => {
        let panelId = PcService.getIdByPath("/app/organizationLifeSupplement");
        PcService.removePanel(panelId);
    }
    /* 获取form参数*/
    onBaseSubmit = (form, $event) => {
        let data = {}, errs = {}, isStop = false;
        $event.preventDefault();
        form.validateFields((err, fieldsValue) => {
            data = fieldsValue;
            errs = err;
        });
        for (let v in errs) {
            if (errs[v]) {
                isStop = true;
            }
        }
        if (isStop) {
            return 'stop';
        } else {
            return data;
        }
    };

    handleSave = ($event) => {
        const { isEdit, publisherName, dataProcessorName, orgUnitName, } = this.state;
        const { lat, lng, radius } = { ...this.state.signInfo };
        let that = this;
        const newForms = this.refs.newForms.getForm();
        const newPlanForms = this.refs.newPlanForms.getForm();
        const newRecordForms = this.refs.newRecordForms.getForm();
        let submitBase = this.onBaseSubmit(newForms, $event);
        let submitPlan = this.onBaseSubmit(newPlanForms, $event);
        let submitRecord = this.onBaseSubmit(newRecordForms, $event);

        if (submitBase === 'stop' || submitPlan === 'stop' || submitRecord === 'stop') return;

        /* //时间类型转换 
        submitBase.activityBeginTime = submitBase.activityTime.length ? submitBase.activityTime[0]._d : '';
        submitBase.activityEndTime = submitBase.activityTime.length ? submitBase.activityTime[1]._d : '';
        delete submitBase.activityTime;
        submitBase.signBeginTime = submitBase.signTime.length ? submitBase.signTime[0]._d : '';
        submitBase.signEndTime = submitBase.signTime.length ? submitBase.signTime[1]._d : '';
        delete submitBase.signTime; */
        submitBase.mode = 'append';  //活动安排类型 append（补录记录）
        submitBase.activityStatus = '20002';  //活动状态
        submitBase.enabled = true; //是否可用
        submitBase.operator = userId && userId.length > 0 ? userId : 'string';//userId 
        //submitBase.activityType = PcService.getDataDictionary("ACTIVITY_CATEGORY")[2].id;
        //活动负责人
        submitBase.publisherIdTreeShow = typeof submitBase.publisherIdTreeShow === "object" ? submitBase.publisherIdTreeShow[0] : submitBase.publisherIdTreeShow;
        submitBase.publisherName = publisherName;
        submitBase.dataProcessorName = dataProcessorName;
        submitBase.orgUnitName = orgUnitName;
        submitBase.userIds = this.getUserIdInfo(submitBase.userIds);
        submitBase.id = isEdit ? this.props.location.query.recordId : "";
        submitBase.category = PcService.getDataDictionary("ACTIVITY_CATEGORY")[2].id;//活动类别（大类类别：三会一课/困难帮扶/党组织生活）
        submitBase.ableMakeUp = false;

        submitBase.publishStatus = PcService.getDataDictionary("PUBLISH_STATUS")[1].id; //活动信息发布状态 发布1/草稿0/撤销2
        submitBase.lat = lat; //经纬度
        submitBase.lng = lng;
        submitBase.radius = radius;
        //补录签到复制会议时间地址复制会议地点
        submitBase.signBeginTime = submitBase.activityBeginTime;
        submitBase.signEndTime = submitBase.activityEndTime;
        submitBase.signPlace = submitBase.activityPlace;
        if (submitBase) {
            //判断新增或修改
            let url = isEdit ? urls.app.partyActivity.update : urls.app.partyActivity.insert;
            let _params = Object.assign({}, submitBase, submitPlan, submitRecord);
            this.setState({ loading: true });
            PcService.formSubmit(url, true, _params, null, (data) => {
                message.success("操作成功!");
                this.setState({ loading: false });
                let panelId = PcService.getIdByPath("/app/organizationLifeSupplement");
                PcService.removePanel(panelId, true, '/app/organizationLife');
            }, (err) => {
                message.error(err);
                this.setState({ loading: false });
            });
        }

    }
    //根据用户的userid，返还用户全部信息
    getUserIdInfo = (filterUserIds) => {
        let userIds = [];
        let jsonArr = setJsonArray(this.state.lists, "children");
        jsonArr.map(v => {
            if (filterUserIds.indexOf(v.id) !== -1) {
                userIds.push(v);
            }
        })
        userIds && userIds.map(v => {
            v.partyMemberIdTreeShow = v.id;
        })
        return userIds;
    }

    selectOrgAndUsers = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: {
                flag: true,
                type: 0,
            },
        }).then(res => {
            if (res.success === true) {
                let treeData = res.data || [];
                let listsOrg = PcService.renderOrgAndUserNodes({ data: treeData, type: 1 });
                let lists = PcService.renderOrgAndUserNodes({ data: treeData, type: 0, renderAll: true });
                this.fieldsList.map(v => {
                    if (v.name === "userIds" || v.name === "publisherIdTreeShow" || v.name === "dataProcessorIdTreeShow") {
                        v.opts = lists;
                    }
                    if (v.name === "orgUnitId") {
                        v.opts = listsOrg;
                    }
                })
                this.setState({
                    lists,
                    loading: false
                });
            } else {
                message.error(res.message);
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({
                loading: false
            });
        });
    }


    componentDidMount() {
        //活动人员选择
        this.selectOrgAndUsers();
    }

    render() {
        const { fieldsList = [], fieldsListPlan = [], fieldsListRecord = [], handleCancel, handleSave, onBaseSubmit } = this;
        const { mapModalVisible, loading } = this.state;
        const formItemLayout = {
            labelCol: { xs: { span: 24 }, sm: { span: 6 } },
            wrapperCol: { xs: { span: 24 }, sm: { span: 18 } }
        };
        return (
            <Spin spinning={loading} className="">
                <div>
                    <div>
                        <div className="panew-marin-tipinfo">
                            <span className="panew-main-tip" />
                            <label > 党组织生活基础信息</label>
                        </div>
                        <div className="panew-form newForms">
                            <Forms ref="newForms" onSubmit={onBaseSubmit} columns="2" fieldsList={fieldsList} hideFooter formItemLayout={formItemLayout} />
                        </div>
                    </div>
                    <div>
                        <div className="panew-marin-tipinfo">
                            <span className="panew-main-tip" />
                            <label > 活动安排</label>
                        </div>
                        <div className="panew-form newPlanForms">
                            <Forms ref="newPlanForms" onSubmit={onBaseSubmit} columns="1" fieldsList={fieldsListPlan} hideFooter formItemLayout={formItemLayout} />
                        </div>
                    </div>
                    <div>
                        <div className="panew-marin-tipinfo">
                            <span className="panew-main-tip" />
                            <label > 活动记录</label>
                        </div>
                        <div className="panew-form">
                            <Forms ref="newRecordForms" onSubmit={onBaseSubmit} columns="1" fieldsList={fieldsListRecord} hideFooter formItemLayout={formItemLayout} />
                        </div>
                    </div>
                    <div className="panew-footer-button" >
                        <Button type="default" style={{ marginRight: '8px' }} onClick={handleCancel} icon="redo">取消</Button>
                        <Button type="primary" onClick={handleSave} icon="check">确定</Button>
                    </div>

                </div>
            </Spin>
        )
    }
}

export default OrganizationLifeSupplement
