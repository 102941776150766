import React, { Component } from "react";
import { Form, Modal, Row, Spin } from "antd";
import PcForm from '../form/Forms';
import MyTable from "../table/tableComponent";
import './tablePop.css';

export const TablePopModal = Form.create()(
    class extends Component {
        searchOnSubmit = form => {
            form.validateFields((errors, values) => {
                if (errors) {
                    return;
                } else {
                    this.props.fetchData(values);
                }
            });
        };
        state = { keyword: '' };

        constructor(props) {
            super(props);
        }

        render() {
            const {
                destroyOnClose = true,
                title = "操作",
                okText = "确定",
                cancelText = "取消",
                width = 800,
                visible = false,
                columns,
                dataSource,
                rowSelection,
                onOk,
                onCancel,
                pagination,
                footer = '',
                searchFieldsList
            } = this.props;
            const { searchOnSubmit } = this;
            return (
                <Modal
                    className='form-component'
                    maskClosable={false} centered={true}
                    width={width}
                    title={title}
                    destroyOnClose={destroyOnClose}
                    okText={okText}
                    cancelText={cancelText}
                    visible={visible}
                    onOk={onOk ? onOk.bind(this, this.props.form) : ""}
                    onCancel={onCancel}
                    {...footer}
                >
                    <div className="simple-page">
                        {!this.props.noSearch ?
                            <div className="sp-formss">
                                <div className="sp-bars">
                                    <div className="sp-forms">
                                        <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit}
                                            showReset={false} fieldsList={searchFieldsList} />
                                    </div>
                                </div>
                            </div> : ''}
                        <MyTable
                            columns={columns}
                            dataSource={dataSource}
                            fetchData={this.fetchData}
                            rowSelection={rowSelection}
                            pagination={pagination}
                        />
                    </div>
                </Modal>
            )
        }
    }
);
