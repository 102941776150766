/**
 * 页面的title组件
 */
import React, { Component } from 'react';
import { Icon } from 'antd';
import './titles.css';

export default class Titles extends Component {

    /*  
   * 标题组件
   * @param size 大小
   * @param icon:按钮图标
   * @param children:内容（无需传递）
*/

    render() {
        const { size = 2, icon = "table", children = "" } = this.props;
        return ((size, content, icon) => {
            let className = "";
            switch (size) {
                case 1:
                    className = "my-h1";
                    break;
                case 2:
                    className = "my-h2";
                    break;
                case 3:
                    className = "my-h3";
                    break;
                default:
                    className = "my-h2";
                    break;
            }
            return <div className={className}><Icon type={icon} />{content}</div>;
        })(size, children, icon)
    }
}