import React, { Component } from 'react';
import { Modal } from 'antd';
import urls from '../../../configs/api.config';

class ImageAndView extends Component {

    state = {
        pvModalOptions: {
            title: "图片预览",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        }
    };

    pvOnCancel = () => {
        let { pvModalOptions } = this.state;
        this.setState({
            pvModalOptions: {
                ...pvModalOptions,
                visible: false
            }
        });
    }

    render() {
        const { disabled = false, item = {}, form = {}, value = "", onChange = null } = this.props;
        const { pvModalOptions } = this.state;
        const { pvOnCancel } = this;
        const preViewAttr = { ...pvModalOptions, onCancel: pvOnCancel, onOk: pvOnCancel };
        const imageView = (value) => {
            if (!disabled && !!value) {
                this.setState({
                    pvModalOptions: { ...pvModalOptions, visible: true }
                })
            }
        }
        return (
            <div>
                <div title="点击预览图片" className="pv-formitem">
                    {value ? <img onClick={imageView} src={urls.file.viewImg + '/' + value} alt="logo" /> : null}
                </div>
                <Modal {...preViewAttr} >
                    <div className="pvModal">
                        <img src={`${urls.file.viewImg}/${value}`} alt="" />
                    </div>
                </Modal>
            </div>

        )
    }
}

export default ImageAndView;
