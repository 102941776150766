import React, { Fragment } from 'react';
import { Card, Popconfirm, message } from 'antd';
import { StandardTable } from '../../../sharedCommponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane, removeTabPane } from '../../../action/menuTab.action';
import { getTabPaneData } from '../../../common/utils';
import AllComponents from '../../../components';
import { get, delHttp } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SearchForm from './SearchForm';
import { ButtonAuth, ALinkAuth } from '../../../sharedCommponents/AuthWidget';
import pcService from './../../../other/pc.service';
import contantConfig from './../../../configs/constant.config';

class UserList extends React.Component {

    state = {
        loading: false,
        selectedRows: [],
        data:
        {
            list: [],
            pagination: {
                pageIndex: 0,
                pageSize: 10,
                total: 0
            }
        },
        searchInitValue: {
            keyword: ''
        }
    }

    columns = [
        {
            title: '操作',
            width: 300,
            align: 'center',
            render: (text, record) => (
                <Fragment>
                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        onClick={() => this.handleSetOrg(record)}
                        authCode={'6EA4148EF2A64024A01EDD2ED10B720B'}
                    >
                        组织机构
                    </ALinkAuth>

                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        onClick={() => this.handleSetRole(record)}
                        authCode={'1B94FF12B48E48C4864688A2AF28D54B'}
                    >
                        角色
                    </ALinkAuth>

                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        onClick={() => this.handleSetPost(record)}
                        authCode={'2CA4FF12B48E48C4864688C2AF28D54A'}
                    >
                        岗位
                    </ALinkAuth>

                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        onClick={() => this.handleEdit(record)}
                        authCode={'F64E917D88374312B8D696534B2AFF39'}
                    >
                        编辑
                    </ALinkAuth>

                    <Popconfirm title="您确定要删除吗?" onConfirm={() => this.handleDelete(record)} okText="是" cancelText="否">
                        <ALinkAuth
                            // href="javascript:;"
                            authCode={'632890450BB94B9FBD416515E151E415'}
                        >
                            删除
                        </ALinkAuth>
                    </Popconfirm>
                </Fragment>
            ),
        },
        {
            title: '名称',
            dataIndex: 'name'
        },
        {
            title: '登录名',
            dataIndex: 'userName'
        },
        {
            title: '编号',
            dataIndex: 'no'
        },
        {
            title: '手机',
            dataIndex: 'phone'
        },
        {
            title: '邮箱',
            dataIndex: 'email'
        }
    ]

    componentWillMount() {
        this.fetch({});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.reloadState && nextProps.reloadState !== this.props.reloadState) {
            const params = {
                pagination: {
                    pageIndex: 0
                }
            };
            this.fetch(params);
        }
    }

    fetch = (params = {}) => {
        this.setState({ loading: true });
        let { pagination } = this.state.data;
        let pageIndex = pagination.pageIndex;
        let pageSize = pagination.pageSize;
        let keyword = this.state.searchInitValue.keyword;
        if (params) {
            if (params.pagination) {
                pageIndex = params.pagination.pageIndex || 0;
                pageSize = params.pagination.pageSize || pageSize;
            }
            if (!params.keyword) {
                keyword = '';
            }
            else {
                keyword = params.keyword
            }
        }
        let queryParams = {
            pageIndex,
            pageSize,
            keyword
        };
        get({
            url: apiConfig.rui.basic.user.getAllUsers,
            params: queryParams
        }).then((res) => {
            if (res) {
                this.setState({
                    loading: false,
                    data: {
                        list: res.list,
                        pagination: {
                            pageIndex: queryParams.pageIndex,
                            pageSize: queryParams.pageSize,
                            total: res.total
                        }
                    },
                    searchInitValue: {
                        keyword: queryParams.keyword
                    }
                });
            }
            else {
                this.setState({ loading: false });
            }
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    handleSetOrg = (user) => {
        /*let { selectedMenuKey }=this.props;
        let paneKey="ser-userorgs-pane";
        let SetOrganizations=AllComponents["SetOrganizations"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"设置用户组织机构",
            content: <SetOrganizations user={user} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/setOrganizationsForParty?userId=" + user.userId);
    }

    handleSetRole = (user) => {
        let { selectedMenuKey } = this.props;
        let paneKey = "ser-userroles-pane";
        let SetRoles = AllComponents["SetRoles"];
        let addPane = getTabPaneData({
            key: paneKey,
            title: "设置用户角色",
            content: <SetRoles user={user} paneKey={paneKey} />,
            isWebDefault: false,
            closable: true,
            isMenu: false,
            refKey: selectedMenuKey,
            isExternal: false
        });
        this.props.updateTabPane(addPane);
        pcService.relativePath("/app/setRoles?userId=" + user.userId);
    }

    handleSetPost = (user) => {
        /*let { selectedMenuKey }=this.props;
        let paneKey="ser-userposts-pane";
        let SetPosts=AllComponents["SetPosts"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"设置用户岗位",
            content: <SetPosts user={user} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
        });
        this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/setPosts?userId=" + user.userId);
    }

    handleSelectRows = rows => {
        this.setState({
            selectedRows: rows,
        });
    };

    handleStandardTableChange = pagination => {
        const params = {
            pagination: {
                pageIndex: pagination.current - 1,
                pageSize: pagination.pageSize
            }
        };
        this.fetch(params);
    };

    handleSearch = (seacheValues) => {
        let params;
        if (seacheValues) {
            params = {
                pagination: {
                    pageIndex: 0
                },
                keyword: seacheValues.keyword
            };
        }
        else {
            params = {
                pagination: {
                    pageIndex: 0
                },
                keyword: ''
            };
        }
        this.fetch(params);
    }

    handleDelete = (user) => {
        this.setState({ loading: true });
        delHttp({
            url: apiConfig.rui.basic.user.deleteUser,
            params: {
                userId: user.userId
            }
        }).then((res) => {
            const params = {
                pagination: {
                    pageIndex: 0
                }
            };
            message.success(contantConfig.DEL_SUCCESS);
            this.fetch(params);
        }).catch((error) => {
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading: false });
        });
    }


    handleDeleteAll = () => {
        let { selectedRows } = this.state;
        let userIds = '';
        if (selectedRows.length === 0) {
            message.info('请选择要删除的用户');
            return;
        }
        for (let selectedRow of selectedRows) {
            userIds += selectedRow.userId + ',';
        }
        if (userIds) {
            userIds = userIds.substr(userIds, userIds.length - 1);
        }
        this.setState({ loading: true });
        delHttp({
            url: apiConfig.rui.basic.user.deleteAll,
            params: {
                userIds
            }
        }).then((res) => {
            this.setState({
                selectedRows: []
            }, () => {
                const params = {
                    pagination: {
                        pageIndex: 0
                    }
                };
                message.success(contantConfig.DEL_SUCCESS);
                this.fetch(params);
            });
        }).catch((error) => {
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading: false });
        });
    }

    handleAdd = () => {
        /*let { selectedMenuKey }=this.props;
        let paneKey="add-user-pane";
        let CreateUserForm=AllComponents["CreateUserForm"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"新增用户",
            content: <CreateUserForm paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        //this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/userManage");
    }

    handleEdit = (user) => {
        /*let { selectedMenuKey }=this.props;
        let CreateUserForm=AllComponents["CreateUserForm"];
        let paneKey="edit-user-pane";
        let addPane=getTabPaneData({
            key: paneKey,
            title:"编辑用户",
            content: <CreateUserForm editUser={user} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
         this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/userManage?editId=" + user.userId);
    }

    render() {
        const { selectedRows, loading, data, searchInitValue } = this.state;
        return (
            <div className="mainDiv">
                <Card bordered={false}>
                    <div className='tableSearchForm'>
                        <SearchForm
                            handleSearch={this.handleSearch}
                            searchInitValue={searchInitValue}
                        />
                    </div>
                    <div className='tableAction'>
                        <ButtonAuth
                            icon="plus"
                            type="primary"
                            dataaction="create"
                            onClick={() => this.handleAdd()}
                            authCode={'3263CB60128041369710F96AA1E19255'}
                        >
                            新增
                        </ButtonAuth>
                        <Popconfirm title="您确定要删除选中的用户吗?" onConfirm={() => this.handleDeleteAll()} okText="是" cancelText="否">
                            <ButtonAuth
                                icon="delete"
                                type="primary"
                                dataaction="create"
                                loading={loading}
                                authCode={'19186B8284284985AB42ADB83CD4609E'}
                            >
                                批量删除
                                        </ButtonAuth>
                        </Popconfirm>
                    </div>
                    <StandardTable
                        selectedRows={selectedRows}
                        disableSelect={false}
                        loading={loading}
                        rowKey="userId"
                        data={data}
                        columns={this.columns}
                        onSelectRow={this.handleSelectRows}
                        onChange={this.handleStandardTableChange}
                    />
                </Card>
            </div>
        )
    }
}


const mapStateToProps = state => {
    const { menuTab } = state;
    return {
        selectedMenuKey: menuTab.selectedMenuKey
    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(UserList);