import React, { Component } from 'react';
import { getLocal } from './../../common/utils';
import Dashboard from './../../common/components/dashboard/Dashboard';
import HomeHeaderBar from './../../common/components/homeHeaderBar/HomeHeaderBar';
import './home.css';
import {
    getSystemSetUserCache
} from './../../configs/app.config'
export class Home extends Component {

    state = {
        dashboardData: []
    }

    /* 获取初始用户信息 */
    getInitInfo = () => {
        let dashboardData = getLocal("userMenus") || [];
        let currentHistories = getLocal("current_histories") || [];
        let currentHistoriesMenu = {
            href: "#",
            icon: "tag",
            id: 0,
            menus: currentHistories,
            title: "最近使用"
        }
        dashboardData.unshift(currentHistoriesMenu);
        this.setState({ dashboardData });
    }

    componentDidMount() {
        this.getInitInfo();
    }

    componentWillUnmount() {

    }

    render() {
        const { dashboardData = [] } = this.state;
        const sysName =getSystemSetUserCache().sysName;
        return (
            <div className="home">
                <div className="home-header">
                    <div className="logo"></div>
                    <HomeHeaderBar />
                    <span className="title">{sysName}</span>
                </div>
                <Dashboard dashboardData={dashboardData} />
            </div>
        )
    }
}

export default Home;
