import React, { Component } from 'react';

class IntegralRanking extends Component {

    render() {
        return (
            <div>
                积分排行
        </div>
        )
    }
}

export default IntegralRanking;
