import React, { Component } from 'react';
import { Row, Spin, Button, Divider, Icon, message, Popconfirm, Popover } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import './votingManagement.less';

class PersonVoting extends Component {

    state = {
        detail: false,
        visible: false,
        updated: false,
        loading: false,
        selectedRowKeys: [],
        options: [],
        //questions: this.props.questions,
        pagination: null,
        create: true,
        modalOptions: {
            title: "投票选项（人）",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        lists: []
    }

    /* 表格列项 */
    columns = [

        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1,

        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, event) => {
                return <span>
                    <a onClick={this.detailItem.bind(this, record, false)} ><Icon type="info-circle" theme="outlined" /> 详情</a>
                    <Divider type="vertical" />
                    <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" theme="outlined" /> 修改</a>
                </span>
            }
        }, {
            title: '姓名',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: '先进事迹',
            dataIndex: 'items',
            key: 'items',
            render: text => text.length > 20 ? <Popover content={text} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {text.slice(0, 20) + '...'}
            </Popover> : text
        },
        {
            title: '排序',
            dataIndex: 'disorder',
            key: "disorder",
            //sorter: (a, b) => a.disorder - b.disorder
        }
    ];

    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: 'name',
        value: '',
        editor: 'hidden'
    }, {
        //name: 'candidateId',
        name: "candidateIdTreeShow",
        displayName: '候选人',
        editor: 'node-select',
        value: '',
        originValue: [],
        onChange: (item, forms, values, titles, opts) => {
            forms.setFieldsValue({
                "name": titles[0]
            });
        },
        nodeOptions: { allowClear: false },
        opts: this.props.orgAndUsers || [],
        rules: [{
            message: "请选择候选人",
            required: true
        }]
    }, {
        name: 'disorder',
        displayName: '排序',
        editor: 'number',
        value: '',
        originValue: "0",
        range: [0, 99999],
        rules: []
    }, {
        name: 'items',
        displayName: '先进事迹',
        editor: 'textarea',
        value: '',
        originValue: '',
        rules: [
            { message: "请书写先进事迹", required: true },
            { max: 2000, message: "字数过长" }
        ]
    }, {
        name: "imgs",
        editor: "pictureWall",
        value: "",
        originValue: "",
        displayName: "事迹照片",
        fileUpLoadOption: {
            action: urls.file.uploadGetId,
            listType: "picture-card",
            accept: "image/*",
            beforeUpload: (file) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('图片必须小于2MB!');
                }
                return isLt2M;
            },
            onChange: (info, form, _onchange) => {
                if (info.file.status === 'done') {
                    let _value = info.fileList.map(v => v.response);
                    _onchange(_value.join(","));
                }
            }
        }
    }];


    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 详情 */
    detailItem = (item) => {
        let { modalOptions, updated } = this.state;
        let { orgAndUsers = [] } = this.props;
        let that = this;
        let msPromise = new Promise((resolve, reject) => {
            if (!updated) {
                that.fieldsList.map(v => {
                    if (v.name === "candidateIdTreeShow") {
                        v.opts = orgAndUsers;
                        return;
                    }
                });
                that.setState({ updated: true }, resolve);
            } else resolve();
        });
        msPromise.then(function () {
            /* that.fieldsList.map(v => {
                if (v.name === "imgs") {
                    v.editor = "hidden"
                }
            }); */
            that.fieldsList.map(v => {
                if (v.editor !== "hidden") {
                    v.unavailable = true
                }
            });
            pcService.bindFormdData(item, that.fieldsList);
            that.setState({
                modalOptions: {
                    ...modalOptions,
                    visible: true,
                    modalsLoading: false
                },
                detail: true
            });
        });
    }

    /* 新增投票选项 */
    createOne = (item, create) => {
        let { modalOptions, updated } = this.state;
        let { orgAndUsers = [] } = this.props;
        let that = this;
        let msPromise = new Promise((resolve, reject) => {
            if (!updated) {
                that.fieldsList.map(v => {
                    if (v.name === "candidateIdTreeShow") {
                        v.opts = orgAndUsers;
                        return;
                    }
                });
                that.setState({ updated: true }, resolve);
            } else resolve();
        });
        msPromise.then(function () {
            let _create = false;
            /*  that.fieldsList.map(v => {
                 if (v.name === "imgs") {
                     v.editor = create !== undefined ? "hidden" : "pictureWall"
                 }
             }); */
            that.fieldsList.map(v => {
                if (v.editor !== "hidden") {
                    v.unavailable = false
                }
            })
            if (create === false) {
                pcService.bindFormdData(item, that.fieldsList);
            } else {
                _create = true;
                pcService.initFormList(that.fieldsList);
            }
            that.setState({
                modalOptions: {
                    ...modalOptions,
                    visible: true,
                    modalsLoading: false
                },
                create: _create,
                detail: false
            });
        });
    };

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create, options } = this.state;
        let { setParentQuestionsState } = this.props;
        let that = this;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let data = { ...values }
                if (options.some(v => v.candidateIdTreeShow.split("_")[0] === data.candidateIdTreeShow.split("_")[0]) && !!create) {
                    message.info("已经添加了此成员，请勿重复添加！");
                    return;
                }
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
                setTimeout(() => {
                    that.setState({ modalOptions: { ...modalOptions, modalsLoading: false, visible: false } },
                        () => {
                            if (options.some(v => v.candidateIdTreeShow === data.candidateIdTreeShow)) {
                                options = options.map(v => {
                                    if (v.candidateIdTreeShow === data.candidateIdTreeShow) return data;
                                    else return v;
                                })
                            } else options.push(data);
                            options = options.sort((a, b) => a.disorder - b.disorder);
                            setParentQuestionsState({ questions: [{ disorder: 0, name: "", questionTypes: "1", options }] });
                        }
                    );
                }, 500)

            }
        });
    }

    /* 删除 */
    deleteItem = (selectedRowKeys = []) => {
        let { setParentQuestionsState } = this.props;
        let { options = [] } = this.state;
        let _options = [];
        options.map(v => {
            if (selectedRowKeys.every(_v => v.candidateIdTreeShow !== _v)) {
                _options.push(v);
            }
        });
        this.setState({ options: _options, selectedRowKeys: [] });
        setParentQuestionsState({
            questions: [
                { disorder: 0, name: "", questionTypes: "1", options: _options }
            ]
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentWillMount() {
        pcService.initFormList(this.fieldsList);
        this.setState({ options: this.props.questions[0] ? this.props.questions[0].options : [] })
    }

    // 父组件重传props时
    componentWillReceiveProps(nextProps) {
        this.setState({ options: nextProps.questions[0] ? nextProps.questions[0].options : [] });
    }

    render() {
        const { loading, selectedRowKeys, pagination, modalOptions, create, visible, detail } = this.state;
        const { questions = [] } = this.props;
        //console.log("questions", questions);
        const options = questions[0] ? questions[0].options : [];
        const { onSelectChange, columns, fetchData, deleteItem, createOne, fieldsList, onOk, onCancel, cancel, handleVisibleChange } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, title: (detail ? "查看" : (create ? "新增" : "编辑")) + modalOptions.title }
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <div className="voting">
                <Spin spinning={loading}>
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                </Popconfirm>
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable rowKey="candidateIdTreeShow"
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={options}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                    <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                </Spin>
            </div>
        )
    }
}

export default PersonVoting;
