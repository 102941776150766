import React from 'react';
import Table from '../components/table/Table';
import Organization from '../components/organization/Organization';
import OrganizationDetail from '../components/organization/OrganizationDetail';
import OrganizationNew from '../components/organization/OrganizationNew';
import PartyActivity from '../components/partyActivity/PartyActivity';
import PartyActivityDetail from '../components/partyActivity/PartyActivityDetail';
import PartyActivityNew from '../components/partyActivity/PartyActivityNew';
import PartyActivityUpdate from '../components/partyActivity/PartyActivityUpdate';
import PartyActivitySign from '../components/partyActivity/PartyActivitySign';
import PartyActivitySupplement from '../components/partyActivity/PartyActivitySupplement';
import PartyActivityUpload from '../components/partyActivity/PartyActivityUpload';
import DemocraticReview from '../components/democraticReview/DemocraticReview';
import UserInfoSetting from '../components/userInfoSetting/UserInfoSetting';
import UserInfo from '../components/userInfo/UserInfo';
import OrganizationRelation from '../components/organizationalRelation/OrganizationRelation';
import Talk from '../components/talk/Talk'
import MsMessage from '../components/msMessage/MsMessage';
import OrganizationLife from '../components/organizationLife/OrganizationLife';
import OrganizationLifeDetail from '../components/organizationLife/OrganizationLifeDetail';
import OrganizationLifeNew from '../components/organizationLife/OrganizationLifeNew';
import OrganizationLifeUpdate from '../components/organizationLife/OrganizationLifeUpdate';
import OrganizationLifeSign from '../components/organizationLife/OrganizationLifeSign';
import OrganizationLifeSupplement from '../components/organizationLife/OrganizationLifeSupplement';
import OrganizationLifeUpload from '../components/organizationLife/OrganizationLifeUpload';
import PartyMember from '../components/partyMember/PartyMember';
import PartyMemberEducation from '../components/partyMemberEducation/PartyMemberEducation';
import InformationDelivery from '../components/informationDelivery/InformationDelivery';
import InformationReview from '../components/informationReview/InformationReview';
import PartyMemberDevelopment from '../components/partyMemberDevelopment/PartyMemberDevelopment';
import PartyMemberDevelopmentAdd from '../components/partyMemberDevelopment/PartyMemberDevelopmentAdd';
import PartyFeeManage from '../components/partyFeeManagement/PartyFeeManage';
import IntegralRule from '../components/integralRule/IntegralRule';
import IntegralRecords from '../components/integralRecords/IntegralRecords';
import IntegralRanking from '../components/integralRanking/IntegralRanking';
import FlowManage from '../components/flowManage/FlowManage';
import AssistRecordReview from '../components/assistRecord/AssistRecordReview';
import DataDictionary from '../components/dataDictionary/DataDictionary';
import DataDictionaryDetial from '../components/dataDictionary/DataDictionaryDetial';
import PartyMemberDetail from '../components/partyMemberDetail/PartyMemberDetail';
import SourceManage from '../components/sourceManage/SourceManage';
import PartyMemberEducationViewDetail from '../components/partyMemberEducation/PartyMemberEducationViewDetail';
import PartyMemberEducationDetail from '../components/partyMemberEducation/PartyMemberEducationDetail';
import InformationDeliveryDetail from '../components/informationDeliveryDetail/informationDeliveryDetail';
import AssistPlanList from "../components/assistRecord/AssistPlanList";
import AssistPlanAdd from "../components/assistRecord/AssistPlanAdd";
import AssistPlanDetail from "../components/assistRecord/AssistPlanDetail";
import CreateMenuForm from '../components/basic/menu/CreateMenuForm';
import SetAuthorities from '../components/basic/menu/SetAuthorities';
import OranizationList from '../components/basic/organization/OranizationList';
import CreateOrganizationForm from '../components/basic/organization/CreateOrganizationForm';
import RoleList from '../components/basic/role/RoleList';
import CreateRoleForm from '../components/basic/role/CreateRoleForm';
import SetPermissions from '../components/basic/role/SetPermissions';
import PostList from '../components/basic/post/PostList';
import CreatePostForm from '../components/basic/post/CreatePostForm';
import UserList from '../components/basic/user/UserList';
import CreateUserForm from '../components/basic/user/CreateUserForm';
import SetOrganizations from '../components/basic/user/SetOrganizations';
import SetRoles from '../components/basic/user/SetRoles';
import SetPosts from '../components/basic/user/SetPosts';
import TenantList from '../components/basic/tenant/TenantList';
import CreateTenantForm from '../components/basic/tenant/CreateTenantForm';
import SetTenantMenus from '../components/basic/tenant/SetTenantMenus';
import SystemSetList from '../components/setting/systemSet/SystemSetList';
import SetSystemOrganizations from '../components/setting/systemSet/SetSystemOrganizations';
import SetSystemTenants from '../components/setting/systemSet/SetSystemTenants';
import CreateSystemSetForm from '../components/setting/systemSet/CreateSystemSetForm';
import MenuList from '../components/basic/menu/MenuList';
import OnlineList from '../components/basic/online/OnlineList';
// import AssistUpload from "../components/assistRecord/AssistUpload";
import Examination from '../components/examination/Examination';
import TargetedPovertyReduction from '../components/targetedPovertyReduction/TargetedPovertyReduction';
import TargetedPovertyReductionDetail from '../components/targetedPovertyReduction/TargetedPovertyReductionDetail';
import TargetedPovertyReductionNew from '../components/targetedPovertyReduction/TargetedPovertyReductionNew';
import TargetedPovertyReductionUpdate from '../components/targetedPovertyReduction/TargetedPovertyReductionUpdate';
import TargetedPovertyReductionSign from '../components/targetedPovertyReduction/TargetedPovertyReductionSign';
import TargetedPovertyReductionSupplement from '../components/targetedPovertyReduction/TargetedPovertyReductionSupplement';
import TargetedPovertyReductionUpload from '../components/targetedPovertyReduction/TargetedPovertyReductionUpload';
import BannerManagement from '../components/bannerManagement/BannerManagement';
import VotingManagement from '../components/votingManagement/VotingManagement';
import PartyMemberAdd from '../components/partyMember/PartyMemberAdd';
import MenuManagementList from "../components/menuManagement/MenuManagementList";
import CreateOrganizationFormForParty from '../components/basic/organization/CreateOrganizationFormForParty';
import TalkDetail from '../components/talk/TalkDetail';
import FlowManageDetail from '../components/flowManage/FlowManageDetail';
import RewardsAndPunishments from '../components/rewardsAndPunishments/RewardsAndPunishments';
import SetOrganizationsForParty from '../components/basic/user/SetOrganizationsForParty';
import VotingAdd from '../components/votingManagement/VotingAdd';
import VotingUpdate from '../components/votingManagement/VotingUpdate';
import IntegralRuleEdit from '../components/integralRule/IntegralRuleEdit';
import OrgIntegralRecords from '../components/orgIntegralRecords/OrgIntegralRecords';
import StartupIntegral from '../components/startupIntegral/StartupIntegral';
import OrgIntegralSettlement from '../components/orgIntegralRecords/OrgIntegralSettlement';
import PartyIntegralSettlement from '../components/orgIntegralRecords/PartyIntegralSettlement';
import EnterExaminationAchievement from '../components/orgIntegralRecords/EnterExaminationAchievement';
import IntegralDetail from '../components/orgIntegralRecords/IntegralDetail';
import VotingDetail from '../components/votingManagement/VotingDetail';
import democraticReviewDetail from '../components/democraticReview/democraticReviewDetail';
import IntegralRuleDetail from '../components/integralRule/IntegralRuleDetail';
import BookstoreSection from '../components/bookstoreSection';
import BooksManagement from '../components/booksManagement';
import BookImport from '../components/bookImport';
import BookConnect from '../components/bookConnect';

const Page404 = () => <div className="page-404"><h1>404！</h1><h1>Not found!</h1></div>;
const mainRouterArrService = [
    { path: "/app", component: Table, title: "首页", id: "0" },//首页
    { path: "/app/table", component: Table, title: "表格", id: "1" },//表格页面
    { path: "/app/organization", component: Organization, title: "党组织机构", id: "2" },
    { path: "/app/organizationDetail", component: OrganizationDetail, title: "党组织机构详情", id: "3" },//党组织机构
    { path: "/app/organizationNew", component: OrganizationNew, title: "党组织机构编辑", id: "4" },//党组织机构
    { path: "/app/partyActivity", component: PartyActivity, title: "三会一课", id: "5" },//三会一课
    { path: "/app/partyActivityDetail", component: PartyActivityDetail, title: "三会一课详情", id: "6" },//三会一课 },
    { path: "/app/partyActivityNew", component: PartyActivityNew, title: "三会一课新增", id: "7" },
    { path: "/app/partyActivitySign", component: PartyActivitySign, title: "三会一课签到", id: "8" },
    { path: "/app/partyActivitySupplement", component: PartyActivitySupplement, title: "三会一课补录", id: "9" },
    { path: "/app/partyActivityUpload", component: PartyActivityUpload, title: "三会一课上传", id: "10" },
    { path: "/app/democraticReview", component: DemocraticReview, title: "民主评议", id: "11" },//民主评议
    { path: "/app/userInfoSetting", component: UserInfoSetting, title: "设置个人信息", id: "12" },//设置个人信息
    { path: "/app/userInfo", component: UserInfo, title: "个人信息", id: "13" },//个人信息
    { path: "/app/organizationRelation", component: OrganizationRelation, title: "组织关系转接", id: "14" },//组织关系转接
    { path: "/app/talk", component: Talk, title: "谈心谈话", id: "15" },//谈心谈话
    { path: "/app/msMessage", component: MsMessage, title: "全部通知", id: "16" },//全部通知
    { path: "/app/organizationLife", component: OrganizationLife, title: "党组织生活", id: "17" },//党组织生活
    { path: "/app/partyMember", component: PartyMember, title: "党员管理", id: "18" },//党员管理
    { path: "/app/flowManage", component: FlowManage, title: "流动党员", id: "19" },//流动党员
    { path: "/app/assistRecordReview", component: AssistRecordReview, title: "困难帮扶", id: "20" },//困难帮扶
    { path: "/app/partyMemberEducation", component: PartyMemberEducation, title: "党员教育", id: "21" },//党员教育
    { path: "/app/menuManagementList", component: MenuManagementList, title: "APP菜单管理", id: "22" },//App栏目管理
    { path: "/app/informationDelivery", component: InformationDelivery, title: "资讯发布", id: "23" },//资讯发布
    { path: "/app/informationReview", component: InformationReview, title: "信息公告", id: "24" },//信息公告
    { path: "/app/partyMemberDevelopment", component: PartyMemberDevelopment, title: "党员发展", id: "25" },//党员发展
    { path: "/app/partyFeeManage", component: PartyFeeManage, title: "党费管理", id: "26" },//党费管理
    { path: "/app/integralRule", component: IntegralRule, title: "积分规则", id: "27" },//积分规则
    { path: "/app/integralRecords", component: IntegralRecords, title: "党员积分管理", id: "28" },//党员积分管理
    { path: "/app/integralRanking", component: IntegralRanking, title: "积分排行", id: "29" },//积分排行
    { path: "/app/dataDictionary", component: DataDictionary, title: "数据字典", id: "30" },//数据字典
    { path: "/app/dataDictionaryDetail", component: DataDictionaryDetial, title: "数据字典详情", id: "31" },//数据字典详情
    { path: "/app/partyMemberDetail", component: PartyMemberDetail, title: "党员详情", id: "32" },//党员详情
    { path: "/app/sourceManage", component: SourceManage, title: "资源管理", id: "33" },//资源管理
    { path: "/app/partyMemberEducationViewDetail", component: PartyMemberEducationViewDetail, title: "党员教育视听", id: "34" },//党员教育视听
    { path: "/app/partyMemberEducationDetail", component: PartyMemberEducationDetail, title: "党员教育图文详情", id: "35" },//党员教育图文
    { path: "/app/partyMemberDevelopmentAdd", component: PartyMemberDevelopmentAdd, title: "党员发展新增", id: "36" },//党员发展
    { path: "/app/assistPlanList", component: AssistPlanList, title: "帮扶计划列表", id: "37" },
    { path: "/app/assistPlanAdd", component: AssistPlanAdd, title: "帮扶计划新增", id: "38" },
    { path: "/app/assistPlanDetail", component: AssistPlanDetail, title: "帮扶计划详情", id: "40" },
    // { path: "/app/assistUpload", component: AssistUpload, title: "帮扶活动签到", id: "42" },//帮扶活动签到
    { path: "/app/examination", component: Examination, title: "考试管理", id: "43" },//考试管理
    { path: "/app/bannerManagement", component: BannerManagement, title: "横幅管理", id: "44" },//横幅管理
    { path: "/app/targetedPovertyReduction", component: TargetedPovertyReduction, title: "精准扶贫", id: "45" },//精准扶贫
    { path: "/app/informationDeliveryDetail", component: InformationDeliveryDetail, title: "消息发布详情", id: "46" },//消息发布详情
    { path: "/app/votingManagement", component: VotingManagement, title: "民主投票", id: "47" },//民主投票
    { path: "/app/partyMemberAdd", component: PartyMemberAdd, title: "党员管理新增", id: "48" },//党员管理新增
    { path: "/app/menuManagementList", component: MenuManagementList, title: "app菜单管理", id: "49" },//app菜单管理
    { path: "/app/talkDetail", component: TalkDetail, title: "谈心谈话详情", id: "50" },//谈心谈话详情
    { path: "/app/flowManageDetail", component: FlowManageDetail, title: "流动党员详情", id: "51" },//流动党员详情
    { path: "/app/rewardsAndPunishments", component: RewardsAndPunishments, title: "奖惩管理", id: "52" },//流动党员详情
    { path: "/app/votingAdd", component: VotingAdd, title: "民主投票新增", id: "53" },//民主投票新增
    { path: "/app/votingUpdate", component: VotingUpdate, title: "民主投票编辑", id: "54" },//民主投票修改
    { path: "/app/partyActivityUpdate", component: PartyActivityUpdate, title: "三会一课修改", id: "55" },
    { path: "/app/organizationLifeDetail", component: OrganizationLifeDetail, title: "党组织生活详情", id: "56" },//党组织生活 },
    { path: "/app/organizationLifeNew", component: OrganizationLifeNew, title: "党组织生活新增", id: "57" },
    { path: "/app/organizationLifeSign", component: OrganizationLifeSign, title: "党组织生活签到", id: "58" },
    { path: "/app/organizationLifeSupplement", component: OrganizationLifeSupplement, title: "党组织生活补录", id: "59" },
    { path: "/app/organizationLifeUpload", component: OrganizationLifeUpload, title: "党组织生活上传", id: "60" },
    { path: "/app/organizationLifeUpdate", component: OrganizationLifeUpdate, title: "党组织生活修改", id: "61" },
    { path: "/app/targetedPovertyReductionDetail", component: TargetedPovertyReductionDetail, title: "精准扶贫详情", id: "62" },//精准扶贫 },
    { path: "/app/targetedPovertyReductionNew", component: TargetedPovertyReductionNew, title: "精准扶贫新增", id: "63" },
    { path: "/app/targetedPovertyReductionSign", component: TargetedPovertyReductionSign, title: "精准扶贫签到", id: "64" },
    { path: "/app/targetedPovertyReductionSupplement", component: TargetedPovertyReductionSupplement, title: "精准扶贫补录", id: "65" },
    { path: "/app/targetedPovertyReductionUpload", component: TargetedPovertyReductionUpload, title: "精准扶贫上传", id: "66" },
    { path: "/app/targetedPovertyReductionUpdate", component: TargetedPovertyReductionUpdate, title: "精准扶贫修改", id: "67" },
    { path: "/app/integralRuleEdit", component: IntegralRuleEdit, title: "积分规则配置", id: "68" },//积分规则配置
    { path: "/app/orgIntegralRecords", component: OrgIntegralRecords, title: "党组织积分管理", id: "69" }, //党组织积分管理
    { path: "/app/startupIntegral", component: StartupIntegral, title: "启动年度积分", id: "70" }, //启动年度积分
    { path: "/app/orgIntegralSettlement", component: OrgIntegralSettlement, title: "支部、领导积分结算", id: "71" }, //积分结算
    { path: "/app/partyIntegralSettlement", component: PartyIntegralSettlement, title: "党员积分结算", id: "72" },
    { path: "/app/enterExaminationAchievement", component: EnterExaminationAchievement, title: "录入考核成绩", id: "73" },
    { path: "/app/integralDetail", component: IntegralDetail, title: "积分明细", id: "74" },//积分明细
    { path: "/app/votingDetail", component: VotingDetail, title: "投票详情", id: "75" },//投票详情
    { path: "/app/democraticReviewDetail", component: democraticReviewDetail, title: "评议详情", id: "76" },//评议详情
    { path: "/app/integralRuleDetail", component: IntegralRuleDetail, title: "规则详情", id: "77" },//积分规则详情
    { path: "/app/bookstoreSection", component: BookstoreSection, title: "书屋栏目", id: "78" },//书屋目录
    { path: "/app/booksManagement", component: BooksManagement, title: "图书管理", id: "79" },//图书管理 
    { path: "/app/bookImport", component: BookImport, title: "图书导入", id: "80" },//图书管理
    { path: "/app/bookConnect", component: BookConnect, title: "书评管理", id: "81" },//书评管理


    //系统菜单路由
    { path: "/app/menuList", component: MenuList, title: "菜单列表", id: "10001" }, //菜单管理
    { path: "/app/menuManage", component: CreateMenuForm, title: "菜单管理", id: "10002" }, //菜单管理
    { path: "/app/menuSetAuthorities", component: SetAuthorities, title: "菜单所属功能", id: "20001" }, //菜单管理设置功能权限
    { path: "/app/oranizationList", component: OranizationList, title: "组织机构列表", id: "10003" }, //组织管理
    { path: "/app/oranizationManage", component: CreateOrganizationForm, title: "组织机构管理", id: "10004" }, //组织管理
    { path: "/app/oranizationManageForParty", component: CreateOrganizationFormForParty, title: "组织机构管理", id: "100041" }, //党建系统专属组织管理
    { path: "/app/roleList", component: RoleList, title: '角色列表', id: '10005' },
    { path: "/app/roleManage", component: CreateRoleForm, title: '角色管理', id: '10006' },
    { path: "/app/setPermissions", component: SetPermissions, title: '设置角色功能权限', id: '20002' },
    { path: "/app/postList", component: PostList, title: '岗位列表', id: '10007' },
    { path: "/app/postManage", component: CreatePostForm, title: '岗位管理', id: '10008' },
    { path: "/app/userList", component: UserList, title: '用户列表', id: '10009' },
    { path: "/app/userManage", component: CreateUserForm, title: '用户管理', id: '10010' },
    { path: "/app/setOrganizations", component: SetOrganizations, title: '用户组织机构设置', id: '20008' },
    { path: "/app/setRoles", component: SetRoles, title: '用户角色设置', id: '20009' },
    { path: "/app/setPosts", component: SetPosts, title: '用户岗位设置', id: '20010' },
    { path: "/app/tenantList", component: TenantList, title: '租户列表', id: '10011' },
    { path: "/app/tenantManage", component: CreateTenantForm, title: '租户管理', id: '10012' },
    { path: "/app/setTenantMenus", component: SetTenantMenus, title: '租户功能模块', id: '20003' },
    { path: "/app/systemSetList", component: SystemSetList, title: '系统设置列表', id: '10013' },
    { path: "/app/SystemSetManage", component: CreateSystemSetForm, title: '系统设置管理', id: '10014' },
    { path: "/app/setSystemOrganizations", component: SetSystemOrganizations, title: '系统设置组织机构', id: '20016' },
    { path: "/app/setSystemTenants", component: SetSystemTenants, title: '系统设置组织租户', id: '20018' },
    { path: "/app/menuList", component: MenuList, title: "菜单列表", id: "10001" }, //菜单管理
    { path: "/app/menuManage", component: CreateMenuForm, title: "菜单管理", id: "10002" }, //菜单管理
    { path: "/app/menuSetAuthorities", component: SetAuthorities, title: "菜单所属功能", id: "20001" }, //菜单管理设置功能权限
    { path: "/app/oranizationList", component: OranizationList, title: "组织机构列表", id: "10003" }, //组织管理
    { path: "/app/oranizationManage", component: CreateOrganizationForm, title: "组织机构管理", id: "10004" }, //组织管理
    { path: "/app/roleList", component: RoleList, title: '角色列表', id: '10005' },
    { path: "/app/roleManage", component: CreateRoleForm, title: '角色管理', id: '10006' },
    { path: "/app/setPermissions", component: SetPermissions, title: '设置角色功能权限', id: '20002' },
    { path: "/app/postList", component: PostList, title: '岗位列表', id: '10007' },
    { path: "/app/postManage", component: CreatePostForm, title: '岗位管理', id: '10008' },
    { path: "/app/userList", component: UserList, title: '用户列表', id: '10009' },
    { path: "/app/userManage", component: CreateUserForm, title: '用户管理', id: '10010' },
    { path: "/app/setOrganizations", component: SetOrganizations, title: '用户组织机构设置', id: '20008' },
    { path: "/app/setOrganizationsForParty", component: SetOrganizationsForParty, title: '党建用户组织机构设置', id: '200081' },
    { path: "/app/setRoles", component: SetRoles, title: '用户角色设置', id: '20009' },
    { path: "/app/setPosts", component: SetPosts, title: '用户岗位设置', id: '20010' },
    { path: "/app/tenantList", component: TenantList, title: '租户列表', id: '10011' },
    { path: "/app/tenantManage", component: CreateTenantForm, title: '租户管理', id: '10012' },
    { path: "/app/setTenantMenus", component: SetTenantMenus, title: '租户功能模块', id: '20003' },
    { path: "/app/systemSetList", component: SystemSetList, title: '系统设置列表', id: '10013' },
    { path: "/app/SystemSetManage", component: CreateSystemSetForm, title: '系统设置管理', id: '10014' },
    { path: "/app/onlineList", component: OnlineList, title: '在线用户', id: '10023' },
    { path: "/app/setSystemOrganizations", component: SetSystemOrganizations, title: '系统设置组织机构', id: '20016' },
    { path: "/app/setSystemTenants", component: SetSystemTenants, title: '系统设置组织租户', id: '20018' },

    { path: "/app/404", component: Page404, title: "404页面", id: "100" }, //404页面
    { path: "/app/*", component: Table, title: "默认页面", id: "101" }, //默认页面
];

export default mainRouterArrService;