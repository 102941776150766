import React from 'react';
import { getTabPaneData } from '../common/utils';
import appConfig from '../configs/app.config';
import components from '../components';
import {isEmpty} from '../common/utils';
const {Dashboard}=components;

/**
 * 刷新Iframe
 * @param {*} menuKey
 * @param {*} url
 */
export const refreshIframe=(menuKey,url)=>{
    if(isEmpty(url))
    {
        return;
    }
    const moduleKey=`model_${menuKey}`;
    const iframeObj=document.getElementById(moduleKey);
    if(iframeObj)
    {
        if(url.indexOf('?')!==-1)
        {
            url=url+"&r="+new Date().getTime();
        }
        else
        {
            url=url+"?r="+new Date().getTime();
        }
        if(url.includes('#'))
        {
            iframeObj.src="";
            setTimeout(()=>{
                iframeObj.src=url;
            },100);
        }
        else
        {
            iframeObj.src=url;
        }
    }
   
};

/**
 * 默认缺省标签首页内容
 */
export const defaultTabPane=getTabPaneData({
    title: appConfig.webDefaultTabTitle,
    key: appConfig.webDefaultTabPaneKey,
    //content: <Dashboard />,
    isWebDefault: true,
    url: appConfig.sysHome,
    closable: false,
    isExternal: true,
    isMenu: false
});
