import React from 'react';
import {Form, Input, Row, Col,Button } from 'antd';
import { post } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';

const FormItem = Form.Item;

class AddAuthorityForm extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            loading: false
        };
    }

    handleSubmit=(e)=>{
            e.preventDefault();
            this.props.form.validateFields((err, values) => {
            if (!err) {
                let data;
                let url;
                url= apiConfig.rui.basic.menu.addAuthorities;
                data={
                    ...values,
                    menuId:this.props.setMenuId
                }
                this.setState({loading:true});
                post({
                    url:url,
                    data:data
                }).then((res)=>{
                   
                    this.setState({loading:false});
                    this.props.form.resetFields();
                    this.props.onSeach();
                }).catch((error)=>{
                    this.setState({ loading:false});
                });
            }
        });
   }

    
    render() {
        const { form } = this.props;
        const { loading }=this.state;
        const formItemLayout = {
             labelCol: {span: 6},
             wrapperCol: {span: 18}
          }
      return (
                    <Form  onSubmit={this.handleSubmit} layout='inline'>
                        <Row>
                            <Col span={4}>
                                <FormItem label="名称" {...formItemLayout}>
                                    {form.getFieldDecorator('authorityNames', {
                                        initialValue: null,
                                        rules: [
                                            {required: true, message: '请输入功能名称！'},
                                            {pattern: /^[^\s]*$/, message: '请不要输入空格'}
                                        ],
                                    })(<Input placeholder="请输入" style={{width:200}} />)}
                                </FormItem>
                            </Col>
                            <Col span={2} style={{marginLeft:15,paddingTop:3}}>
                                <Button type="primary" htmlType="submit" loading={loading}>保存</Button>
                            </Col>
                        </Row>
                </Form>
         )
      }
}

export default Form.create()(AddAuthorityForm);