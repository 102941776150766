import React, { Component } from 'react';
import { Button, Divider, Icon, message, Popconfirm, Row, Spin, Modal } from "antd";
import PcTable from "../../common/components/table/tableComponent";
import PcModal from "../../common/components/modal/Modals";
import PcForm from "../../common/components/form/Forms";
import pcService from "../../other/pc.service";
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import DetailModal from "../../common/components/detailModal/DetailModal";

class ExamTask extends Component {

    /**
     * 数据集
     */
    state = {
        loading: false,
        visible: false,
        selectedRowKeys: [],
        selectedRecords: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        detailVisible: false,
        modalOptions: {
            title: "考试任务",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 1000
        },
        lists: []
    };
    /**
     * 搜索框内容
     */
    searchFieldsList = [{
        name: "organizerId",
        editor: "node-select",
        value: "",
        originValue: "",
        displayName: "开展组织",
        opts: []
    }, {
        name: "examStatus",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "状态",
        opts: [{
            id: "",
            name: "全部"
        }, {
            id: 0,
            name: "暂存"
        }, {
            id: 1,
            name: "已发布"
        }, {
            id: 2,
            name: "进行中"
        }, {
            id: 3,
            name: "已结束"
        }]
    }, {
        name: "startTime",
        editor: "datePicker",
        originValue: null,
        timeOptions: {
            showToday: true,
            format: 'YYYY-MM-DD'
        },
        displayName: "考试时间"
    }, {
        name: "keyWord",
        editor: "normal",
        value: "",
        displayName: "任务名称",
        hideDisplayName: true,
        originValue: ""
    }];

    /**
     * 新增、修改弹出框内容
     */
    fieldsList = [{
        name: 'id',
        editor: 'hidden',
        value: ''
    }, {
        name: 'taskName',
        editor: 'normal',
        displayName: '考试任务',
        value: '',
        rules: [
            "required",
            { max: 50, message: "考试任务长度为50个字符" }
        ]
    },
    {
        name: 'organizerName',
        editor: 'hidden',
        value: ''
    }, {
        name: 'organizerId',
        editor: 'node-select',
        displayName: '开展组织',
        value: '',
        opts: [],
        rules: ["required"],
        onChange: (_item, _form, _value, titles, option) => {
            _form.setFieldsValue({
                organizerName: titles[0]
            });
        }
    }, {
        name: 'examPaperId',
        editor: 'select',
        displayName: '考试试卷',
        showSearch: true,
        value: '',
        opts: [],
        rules: [{
            required: true, message: "请选择考试试卷"
        }],
        filterOption: (input, option) => option.props.children.indexOf(input) >= 0,
        onChange: (_item, _form, _value, option) => {
            let selectedOption = option.find(item => item.id === _value);
            _form.setFieldsValue({
                totalScore: selectedOption.totalScore,
                passingScore: (selectedOption.totalScore * 0.6).toFixed(2)
            });
        }
    }, {
        name: 'totalScore',
        editor: 'normal',
        displayName: '试卷总分',
        value: '',
        originValue: 0,
        unavailable: true,
        rules: []
    }, {
        name: 'passingScore',
        editor: 'normal',
        displayName: '达标分',
        value: '',
        originValue: 0,
        unavailable: true,
        rules: []
    }, {
        name: 'duration',
        editor: 'number',
        displayName: '考试时长(分钟)',
        value: '',
        originValue: 0,
        rules: ["required"]
    }, {
        name: 'startTime',
        editor: 'datePicker',
        displayName: '考试开始时间',
        value: '',
        maxDate: "endTime",
        rules: ["required"]
    }, {
        name: 'endTime',
        editor: 'datePicker',
        displayName: '考试结束时间',
        minDate: "startTime",
        value: '',
        rules: ["required"]
    }, {
        name: 'participantIdsTreeShow',
        editor: 'node-select',
        displayName: '参加考试人员',
        value: '',
        columns: 1,
        opts: [],
        nodeOptions: {
            treeCheckable: true,
            searchPlaceholder: "请选择考试人员",
            allowClear: false
        },
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        },
        originValue: [],
        rules: [{
            required: true, message: "请选择考试人员"
        }]
    }, {
        name: 'taskCategory',
        editor: 'hidden',
        value: '',
        originValue: 0
    }];

    detailList = [{
        name: 'taskName',
        editor: 'div',
        displayName: '考试任务',
        value: '',
    }, {
        name: 'organizerName',
        editor: 'div',
        displayName: '开展组织',
        value: '',
        opts: [],
    }, {
        name: 'examPaperId',
        editor: 'select',
        displayName: '考试试卷',
        showSearch: true,
        value: '',
        opts: []
    }, {
        name: 'totalScore',
        editor: 'div',
        displayName: '试卷总分',
        value: '',
        originValue: 0,
        unavailable: true,
    }, {
        name: 'passingScore',
        editor: 'div',
        displayName: '达标分',
        value: '',
        originValue: 0,
        unavailable: true,
    }, {
        name: 'duration',
        editor: 'div',
        displayName: '考试时长(分钟)',
        value: '',
        originValue: 0,
    }, {
        name: 'startTime',
        editor: 'div',
        displayName: '任务开始时间',
        timeOptions: {
            max: 'endTime'
        },
        value: '',
    }, {
        name: 'endTime',
        editor: 'div',
        displayName: '任务结束时间',
        timeOptions: {
            min: 'startTime'
        },
        value: '',
    }, {
        name: 'participantIdsTreeShow',
        editor: 'node-select',
        displayName: '参加考试人员',
        value: '',
        columns: 1,
        opts: [],
        unavailable: true,
        nodeOptions: {
            treeCheckable: true
        },
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        },
    }];
    /***********************************页面初始数据START***************************/
    /**
     * 设置下拉选择考试人员
     */
    selectOrgAndUsers = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: {
                flag: true,
                type: 0,
            },
        }).then(res => {
            let treeData = res.data;
            let lists = pcService.renderOrgAndUserNodes({ data: treeData, type: 0 });
            if (res.success === true) {
                if (res.data.length > 0) {
                    this.fieldsList.map(v => {
                        if (v.name === "participantIdsTreeShow") {
                            v.opts = lists
                        }
                    });
                    this.detailList.map(v => {
                        if (v.name === "participantIdsTreeShow") {
                            v.opts = lists
                        }
                    })
                }
                this.setState({
                    loading: false
                });
            } else {
                message.error(res.message);
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({
                loading: false
            });
        });
    };

    // 所属党组织
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        };
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                let lists = treeData ? treeData : [];
                let orgAndUsers1 = pcService.renderOrgAndUserNodes({ data: treeData, type: 1 });
                this.setState({
                    treeList: treeData,
                });
                this.fieldsList.map(v => {
                    if (v.name === "organizerId") {
                        v.opts = orgAndUsers1;
                    }
                });
                this.searchFieldsList.map(v => {
                    if (v.name === "organizerId") {
                        //lists.splice(0, 0, { id: "", name: "全部" });
                        v.opts = orgAndUsers1;
                    }
                });
                this.setState({});
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    };

    /**
     * 渲染下拉树节点
     * @param data
     * @returns {{name: string, id: (string), selectable: boolean, children: *}[]}
     */
    renderNodes = (data = []) => data.map((item, index) => ({
        name: item.structure.structureName,
        id: item.structure.structureId,
        selectable: false,
        children: item.communistInfoList.map((v, i) => ({
            name: v.name,
            id: v.id,
        }))
    }));

    /**
     * 设置下拉选择试卷
     */
    selectExamPaper = () => {
        let _params = {
            pageIndex: 0,
            pageSize: 9999,
            paperCategory: 0,
            enabled: "true"
        };
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.examPaper.page,
            params: _params,
        }).then(res => {
            if (res.success) {
                let lists = res.data.rows ? res.data.rows : [];
                lists.length && lists.map(v => {
                    v.name = v.paperName;
                });
                this.fieldsList[4].opts = lists;
                this.detailList[2].opts = lists;
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    };
    /***********************************页面初始数据END*****************************/

    /***********************************页面操作START*******************************/
    /**
     * 查询
     */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                let startTime = values["startTime"] ? pcService.dateFormat(values["startTime"], "yyyy-MM-dd hh:mm:ss") : values["startTime"];
                let startTimeStr = values["startTime"] ? pcService.dateFormat(values["startTime"], "yyyy-MM-dd") : values["startTime"];
                let _values = {
                    ...values,
                    "startTime": startTime,
                    "startTimeStr": startTimeStr
                };
                this.fetchData(_values);
            }
        });
    };
    /**
     * 新增、修改操作
     * @param item
     * @param create
     */
    createOne = (item, create) => {
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            pcService.bindFormdData(item, this.fieldsList);
        } else {
            _create = true;
            pcService.initFormList(this.fieldsList);
        }
        this.getOrganizationTree();
        this.selectOrgAndUsers();
        this.selectExamPaper();
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    };

    /**
     * 新增、修改点击确定按钮操作
     * @param forms
     */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = "";
                if (create) {
                    url = urls.app.examTask.addEntity;
                } else {
                    url = urls.app.examTask.updateEntity;
                }
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
                pcService.formSubmit(url, true, values, {}, data => {
                    message.success((create ? "新增" : "修改") + "操作成功");
                    this.setState({
                        loading: false,
                        modalOptions: { ...modalOptions, visible: false, modalsLoading: false }
                    });
                    this.fetchData();
                }, errMsg => {
                    message.error(errMsg);
                    this.setState({ loading: false, modalOptions: { ...modalOptions, modalsLoading: false } });
                });
            }
        });
    };

    /**
     * 取消新增、修改操作
     */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({ modalOptions: { ...modalOptions, visible: false } });
    };

    onDetailCancel = () => {
        this.setState({ detailVisible: false });
    };
    /* 详情 */
    detailItem = (item) => {
        this.setState({ loading: true });
        http.all([
            http.request({
                method: "get",
                url: urls.app.organization.selectStructureTreeById,
                params: {
                    flag: true,
                    type: 0
                }
            }),
            http.request({
                method: "get",
                url: urls.app.examPaper.page,
                params: {
                    pageIndex: 0,
                    pageSize: 9999,
                    paperCategory: 0,
                    enabled: "true"
                }
            })
        ]).then(reses => {
            let treeDatas = reses[0] ? reses[0].data : [];
            let users = pcService.renderOrgAndUserNodes({
                type: 0,
                data: treeDatas
            });
            let papeDatas = reses[1] ? reses[1].data.rows : [];
            papeDatas = papeDatas.map(v => ({ ...v, name: v.paperName }))
            this.detailList.map(v => {
                if (v.name === "examPaperId") v.opts = papeDatas;
                if (v.name === "participantIdsTreeShow") v.opts = users;
            })
            pcService.bindFormdData(item, this.detailList);
            this.setState({ loading: false, detailVisible: true });
        }).catch(err => {
            this.setState({ loading: false });
        })
        //this.getOrganizationTree();
        //this.selectOrgAndUsers();
        //this.selectExamPaper();
        // pcService.bindFormdData(item, this.detailList);
        // this.setState({
        //     detailVisible: true,
        // });
    };

    /**
     * 加载数据的方法
     * @param params
     */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        //固定查询参数，此参数代表查询的是考试任务
        _params.taskCategory = 0;
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.examTask.examPage, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.displayTip = 'none';
                });
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    };

    /**
     * 发布考试任务
     * @param item
     */
    publish = (item) => {
        let _params = { taskId: item.id };
        this.setState({ loading: true });
        http.request({
            method: "post",
            url: urls.app.examTask.publish,
            params: _params,
        }).then(res => {
            //console.log("success");
            if (res.success) {
                message.success("操作成功");
                this.setState({
                    loading: false
                });
                this.fetchData();
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            //console.log("error");
            message.error(err.message);
            this.setState({ loading: false });
        });
    };

    /**
     * 开始或者结束考试
     * @param item
     * @param flag
     */
    startOrFinish = (item, flag) => {
        let _params = { taskId: item.id, flag: flag };
        this.setState({ loading: true });
        http.request({
            method: "post",
            url: urls.app.examTask.startOrFinish,
            params: _params,
        }).then(res => {
            if (res.success) {
                message.success("操作成功");
                this.setState({
                    loading: false
                });
                this.fetchData();
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    };

    /**
     * 删除数据操作
     * @param item
     * @param form
     * @param $event
     */
    deleteItem = (selectedRecords = [], $event) => {
        if (selectedRecords.some(v => v.examStatus !== 0)) {
            message.warning("不允许删除进行中的考试任务！");
            return;
        }
        let that = this;
        let ids = selectedRecords.map(v => v.id).join(",");
        that.setState({ loading: true });
        pcService.deleteItem(urls.app.examTask.logic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                that.setState({ loading: false, selectedRowKeys: [] });
            }, err => {
                that.setState({ loading: false });
            }
        );
    };
    /***********************************页面操作END*******************************/
    /**
     * 显示列
     * @type {Array}
     */
    columns = [{
        title: '序号 ',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: "center",
        render: (text, record, index) => {
            return <span>
                <a onClick={this.detailItem.bind(this, record)}><Icon type="info-circle" /> 详情</a>
                {record.examStatus === 0 ? (<span>
                    <Divider type="vertical" />
                    <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm title="确定要发布吗？" onConfirm={this.publish.bind(this, record)} okText="确定"
                        cancelText="取消">
                        <a href="javascript:;"><Icon type="share-alt" theme="outlined" /> 发布</a>
                    </Popconfirm>
                </span>) : null}
                {record.examStatus === 1 ? (<span>
                    <Divider type="vertical" />
                    <Popconfirm title="确定要开始吗？" onConfirm={this.startOrFinish.bind(this, record, "start")} okText="确定"
                        cancelText="取消">
                        <a href="javascript:;"><Icon type="play-circle" theme="outlined" /> 开始考试</a>
                    </Popconfirm>
                </span>) : null}
                {record.examStatus === 2 ? (<span>
                    <Divider type="vertical" />
                    <Popconfirm title="确定要结束吗？" onConfirm={this.startOrFinish.bind(this, record, "finish")} okText="确定"
                        cancelText="取消">
                        <a href="javascript:;"><Icon type="poweroff" theme="outlined" /> 结束考试</a>
                    </Popconfirm>
                </span>) : null}
            </span>;
        }
    }, {
        title: '考试任务',
        dataIndex: 'taskName',
        key: 'taskName',
    }, {
        title: '开展组织',
        dataIndex: 'organizerName',
        key: 'organizerName',
        //width: 100
    }, {
        title: '开始时间',
        dataIndex: 'startTime',
        key: 'startTime',
        render: (value) => pcService.dateFormat(value, "yyyy-MM-dd"),
        sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime)
    }, {
        title: '结束时间',
        dataIndex: 'endTime',
        key: 'endTime',
        render: (value) => pcService.dateFormat(value, "yyyy-MM-dd"),
        sorter: (a, b) => new Date(a.endTime) - new Date(b.endTime)
    }, {
        title: '总分',
        //width: 60,
        dataIndex: 'totalScore',
        key: 'totalScore'
    }, {
        title: '达标分',
        dataIndex: 'passingScore',
        key: 'passingScore',
        //width: 90
    }, {
        title: '应考',
        dataIndex: 'totalParticipant',
        key: 'totalParticipant',
        //width: 60
    }, {
        title: '实考',
        dataIndex: 'actualParticipant',
        key: 'actualParticipant',
        //width: 60
    }, {
        title: '合格',
        dataIndex: 'passingParticipant',
        key: 'passingParticipant',
        width: 60
    }, {
        title: '状态',
        dataIndex: 'examStatus',
        key: 'examStatus',
        //width: 80,
        render: (value, record, index) => {
            switch (value) {
                case 0:
                    return <span>暂存</span>;
                case 1:
                    return <span>已发布</span>;
                case 2:
                    return <span>进行中</span>;
                case 3:
                    return <span>已结束</span>;
            }
        }
    }];

    /* 选中事件 */
    onSelectChange = (selectedRowKeys, selectedRecords) => {
        this.setState({ selectedRowKeys, selectedRecords });
    };

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    /**
     * 钩子函数
     */
    componentDidMount() {
        this.getOrganizationTree();
        this.fetchData();
    };

    render() {
        const { loading, selectedRowKeys, selectedRecords, pagination, lists, modalOptions, visible, create } = this.state;
        const { handleVisibleChange, cancel, createOne, searchOnSubmit, searchFieldsList, onSelectChange, columns, fetchData, fieldsList, detailList, onOk, onCancel, deleteItem } = this;
        const allowDel = selectedRowKeys.length > 0 && selectedRecords.every(v => v.examStatus === 0);
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 960, title: (create ? "新增" : "编辑") + modalOptions.title };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const detailFormItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        }
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRecords)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消">
                                    <Button icon="delete" disabled={!allowDel} type="default">删除</Button>
                                </Popconfirm></div>
                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset
                                    fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading}
                    formItemLayout={formItemLayout} formItemColumns={2} />
                {/* <Modal
                    title={"详情"}
                    width={"960px"}
                    visible={this.state.detailVisible}
                    footer={null}
                    onCancel={this.onDetailCancel}>
                    <DetailModal
                        fieldsList={detailList}
                        formItemLayout={formItemLayout}
                        columns={this.listColumns}
                    />
                </Modal> */}
                <Modal centered title="考试任务详情" width={800} visible={this.state.detailVisible} footer={null} onCancel={this.onDetailCancel}>
                    <DetailModal fieldsList={detailList} formItemLayout={detailFormItemLayout} columns={this.listColumns} name={"基本信息"} />
                </Modal>
            </Spin>
        );
    }
}

export default ExamTask;
