import React from 'react'
import { Input, Icon } from 'antd';
import './SysSkinCheck.less';
import { setThemes } from '../../../common/utils/themeUtils';
import CustomizeTheme from "./CustomizeTheme";

export class SysSkinCheck extends React.Component {

    state = {
        loading: false,
        show: false,
        themes: [
            {
                name: "红",
                className: "red",
                active: false,
                colorMap: {
                    '@head-back_color': '#ee3828',
                    '@sider-back_color': '#f9f9f9',
                    '@sider-select_color': '#7a7a7a',
                    '@select-color': '#ee3828',
                    '@table_header_color': 'rgba(238,56,40,0.12)'
                }
            }, {
                name: "蓝",
                className: "blue",
                active: false,
	            colorMap: {
		            '@head-back_color': '#4bb4ff',
		            '@sider-back_color': '#f9f9f9',
                    '@sider-select_color': '#7a7a7a',
		            '@select-color': '#4bb4ff',
                    '@table_header_color': 'rgba(75,180,255,0.12)'
	            }
            }, {
                name: "青",
                className: "cyan",
                active: false,
		        colorMap: {
			        '@head-back_color': '#02bcab',
			        '@sider-back_color': '#f9f9f9',
                    '@sider-select_color': '#7a7a7a',
			        '@select-color': '#02bcab',
                    '@table_header_color': 'rgba(2,188,171,0.12)'
		        }
            }, {
                name: "咖啡",
                className: "coffee",
                active: false,
		        colorMap: {
			        '@head-back_color': '#ac9d86',
			        '@sider-back_color': '#f9f9f9',
                    '@sider-select_color': '#7a7a7a',
			        '@select-color': '#ac9d86',
                    '@table_header_color': 'rgba(172,157,134,0.12)'
		        }
            }, {
                name: "蔚蓝",
                className: "skyBlue",
                active: false,
		        colorMap: {
			        '@head-back_color': '#57c8da',
			        '@sider-back_color': '#f9f9f9',
                    '@sider-select_color': '#7a7a7a',
			        '@select-color': '#57c8da',
                    '@table_header_color': 'rgba(87,200,218,0.12)'
		        }
            }, {
                name: "墨绿",
                className: "blackish",
                active: false,
                colorMap: {
                    '@head-back_color': '#90b48a',
                    '@sider-back_color': '#f9f9f9',
                    '@sider-select_color': '#7a7a7a',
                    '@select-color': '#90b48a',
                    '@table_header_color': 'rgba(144,180,138,0.12)'
                }
            }, {
                name: "深蓝",
                className: "darkBlue",
                active: false,
		        colorMap: {
			        '@head-back_color': '#364760',
			        '@sider-back_color': '#364760',
                    '@sider-select_color': '#b0bcda',
			        '@select-color': '#4498ff',
                    '@table_header_color': 'rgba(39,174,255,0.12)'
		        }
            }
        ]
    };

    /* 显示隐藏换肤功能面板 */
    colorClick = () => {
        let { value = "" } = this.props;
        let { show, themes = [] } = this.state;
        if (!show) {
            themes.map(v => {
                if (v.className === value) {
                    v.active = true;
                } else {
                    v.active = false;
                }
            });
            this.setState({
                themes,
                show: !show
            })

        } else {
            this.setState({
                show: !show
            });
        }
    };

    // 获取自定义主题
    getCustonizeTheme = (item) => {
        const { onChange } = this.props;
        // 过滤为空的属性+table属性浅色替换
        for (let key in item) {
            if (!item[key]) delete item[key];
            else {
                if (key==='@table_header_color') {
                    if (!item[key].includes('rgba(')) item[key] = item[key].replace('rgb(','rgba(').replace(')',',0.12)');
                }
            }
        }
        setThemes(item, () => {
            // 自定义主题名称统一为：自定义
            if (onChange) {
                onChange(
                    Object.keys(item).length>0 ?
                        JSON.stringify(item)
                            .replace('@head-back_color','@header')
                            .replace('@sider-back_color','@sider')
                            .replace('@sider-select_color','@font')
                            .replace('@select-color','@button')
                            .replace('@table_header_color','@table')
                        : null
                );
            }
            // 设置完后关闭选框
            // this.setState({show: false});
        });
    }

    render() {
        const { value, onChange } = this.props;
        const { colorClick } = this;
        const { show, themes } = this.state;
        const colorOnChange = (_value) => {
            //do somethings
            onChange(_value);
        };

        /* 更改主题:自定义颜色方案 */
        const colorOptions = {
            onUpdate: (rgbArr = [0, 0, 0]) => {
                themes.map(v => {
                    v.active = false;
                });
	            setThemes({
	                '@head-back_color': `rgb(${rgbArr})`,
	                '@sider-back_color': `rgb(${rgbArr})`,
                });
                this.setState({themes});
                onChange(`rgb(${rgbArr})`);
            }
        };

        /* 更改主题方法 */
        const checkTheme = (item) => {
	        setThemes(item.colorMap, () => {
                if (onChange) {
                    //console.log('---------------',item.colorMap);
                    onChange(
                        JSON.stringify(item.colorMap)
                            .replace('@head-back_color','@header')
                            .replace('@sider-back_color','@sider')
                            .replace('@sider-select_color','@font')
                            .replace('@select-color','@button')
                            .replace('@table_header_color','@table')
                    );
                }
            });
        };
        return (
            <div className="pc-check-panel" >
                <Input placeholder="请选择" onClick={colorClick} value={value} onChange={colorOnChange} allowClear />
                {show ?
                    <div className="pc-drop-menu">
                        <div className="pc-drop-title">
                            <span onClick={colorClick} title="关闭">
                                <Icon type="close" />
                            </span>
                            请选择主题
                        </div>
                        <div className="pc-drop-content">
                            <h3>已有主题</h3>
                            <ul>
                                {
                                    themes.map((v, n) => <li onClick={checkTheme.bind(this, v)} key={n} className={`pc-${v.className}` + (v.active ? ' active' : '')}>{v.name}</li>)
                                }
                            </ul>
                        </div>
                        <div className="pc-drop-content">
                            <h3>自定义主题</h3>
                            <div className="pc-color-content">
                                <CustomizeTheme onChange={this.getCustonizeTheme} />
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        )
    }
}

export default SysSkinCheck;
