import React, { Component } from 'react';
import { message, Spin, Button, Input, Select } from 'antd';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import './partyActivity.css';
import defaultPic from './../../img/commuImg.png';
import pcService from './../../other/pc.service';
const { Search } = Input;
const { Option } = Select;


export class PartyActivitySign extends Component {
    state = {
        loading: false,
        none: 0,
        dataList: [],
        commentLists: [],
        signSuccess: 0,
        signUnDone: 0,
        signSelect: '',
    }

    listClick = (record) => {
        let { dataList = [] } = this.state;
        dataList.map((v, n) => {
            if (v.id === record.id) {
                v.none = 1;
            } else {
                v.none = 0;
            }
        })
        this.setState({
            dataList,
        })
        this.fetchCommentData({ publisher: record.partyMemberId });
    }
    /* 获取列表数据 */
    fetchCommentData = (params = {}, needsState = {}) => {
        let _params = Object.assign({}, { resourceType: pcService.getDataDictionary("ACTIVITY_CATEGORY")[0].id, resourceId: this.props.location.query.recordId }, params);
        this.setState({ loading: true });
        pcService.fetchData(urls.app.comment.page, _params, null, (data) => {
            let lists = data.rows ? data.rows : [];
            lists.length && lists.map(v => {
                v.active = "";
            });
            this.setState({
                commentLists: lists,
                ...needsState,
                loading: false
            });
        });

    }
    onMouseLeave = () => {
        let { dataList = [] } = this.state;
        dataList.map((v, n) => {
            v.none = 0;
        })
        this.setState({
            dataList,
        })
    }

    handleChange = (value) => {
        this.setState({ signSelect: value })
    }
    /* 搜索 */
    seachOnSubmit = value => {
        const { signSelect } = this.state;
        let _params = {
            name: value,
            signStatus: signSelect
        }
        this.fetchData(_params);
    }

    fetchData = (params) => {
        this.setState({ loading: true });
        const that = this;
        //获取签到信息
        let id = this.props.location ? this.props.location.query.recordId : '';
        let _params = Object.assign({ activityId: id }, params)
        http.request({
            method: 'get',
            url: urls.app.partyActivity.signPage,
            params: _params
        }).then(res => {
            if (res.success) {
                let record = res.data.rows || [];
                record.map(v => v.none = 0);
                record.map(v => v.abs = false);
                that.setState({
                    signSuccess: record.length ? record[0].signSuccess : 0,
                    signUnDone: record.length ? record[0].signUnDone : 0,
                    dataList: record,
                    loading: false
                });

            } else {
                message.error(res.message);
                that.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({ loading: false });
        });
    }

    componentDidMount() {
        this.fetchData();
    }


    /* 鼠标move事件 */
    liOnMouseMove = (item) => {
        let { dataList } = this.state;
        dataList = dataList.map(v => ({ ...v, abs: v.id === item.id ? !v.abs : false }));
        if (item.abs) {
            this.setState({ dataList });
        } else {
            this.fetchCommentData({ publisher: item.partyMemberId }, { dataList });
        }

    }

    /* 鼠标leave事件 */
    liOnMouseLeave = (item) => {
        // let { dataList } = this.state;
        // dataList = dataList.map(v => ({ ...v, abs: false }));
        // this.setState({ dataList });
    }


    render() {
        const { loading, dataList, signUnDone, signSuccess, commentLists } = this.state;
        const { seachFieldsList, seachOnSubmit, handleChange, liOnMouseMove, liOnMouseLeave } = this;

        return (
            <Spin spinning={loading}>
                <div className="partysign-activ-page">
                    <div className="panew-marin-tipinfo">
                        <span className="panew-main-tip" />
                        <label > 会议安排</label>
                    </div>
                    {/* 搜索框 */}
                    <div className="party-activ-bars">
                        <div className="party-activ-btns">
                            三会一课，已签到{signSuccess}人，未签到{signUnDone}人
                            </div>
                        <div className="party-activ-sign">
                            <label >&emsp;签到情况: &nbsp;</label>
                            <Select ref={"signSelect"} defaultValue="" style={{ width: 200 }} onChange={handleChange}>
                                <Option value={""}>{"全部"}</Option>
                                {
                                    pcService.getDataDictionary("ACTIVITY_SIGN_STATUS").map((item) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                            <label >&emsp;姓名:&nbsp; </label>
                            <Search style={{ width: '250px' }} placeholder="请输入姓名" onSearch={value => this.seachOnSubmit(value)} enterButton />
                        </div>
                    </div>
                    {/* 列表 */}
                    {/* <div className="partysign-list">
                        <div>
                            <ul>
                                {
                                    dataList.map((item, key) => {
                                        let signUrl = item.photo ? urls.file.viewImg + '/' + item.photo : defaultPic;
                                        return (
                                            <li key={key}>
                                                <div className="sign-list-border" onClick={this.listClick.bind(this, item)} onMouseLeave={this.onMouseLeave} >
                                                    <div className={item.none === 1 && commentLists && commentLists.length > 0 ? 'sign-list-child' : null} >
                                                        <div className="sign-inner-pic">
                                                            <div className="pic-img">
                                                                <img src={signUrl}></img>
                                                                <div className="pic-name">
                                                                    <span style={{ float: "left", fontWeight: "normal", fontSize: 12 }}>{item.name}</span>
                                                                </div>
                                                                <div className="sign-inner-in">{item.signStatus ? pcService.numberToText(item.signStatus, pcService.getDataDictionary("ACTIVITY_SIGN_STATUS")) : " "}</div>
                                                            </div>
                                                            <div className="sign-inner-time">{item.signTime ? item.signTime.substring(0, 16) : ""}</div>
                                                        </div>
                                                        <div className="sign-info" style={{ display: item.none === 1 ? 'block' : 'none' }}>
                                                            <div className={"sign-inner-pic"}>
                                                                <ul className={"sign-com"}>
                                                                    {
                                                                        commentLists.map((comItem, k) => {
                                                                            let signComUrl = comItem.headImg ? urls.file.viewImg + '/' + comItem.headImg : defaultPic;
                                                                            return (
                                                                                <li key={k}>
                                                                                    <div className="pic-img" >
                                                                                        <img src={signComUrl}></img>
                                                                                        <div className="pic-name">{comItem.publisherName ? comItem.publisherName : ""}</div>
                                                                                    </div>
                                                                                    <div>
                                                                                        {comItem.content}
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div> */}
                    <div className="partysign-list-others">
                        <ul>
                            {dataList.map((v, n) => {
                                let signUrl = v.photo ? urls.file.viewImg + '/' + v.photo : defaultPic;
                                return (<li key={n}>
                                    <div className={`item a-${v.signStatus}`} onClick={liOnMouseMove.bind(this, v)} onMouseLeave={liOnMouseLeave.bind(this, v)}>
                                        <div className="item-l">
                                            <span className="sign-success">{v.signStatus ? pcService.numberToText(v.signStatus, pcService.getDataDictionary("ACTIVITY_SIGN_STATUS")) : ""}</span>
                                            <img src={signUrl} alt="" />
                                            <span className="name">{v.name}</span>
                                        </div>
                                        <div className="item-r">
                                            <span className="sign-success">{v.signTime}</span>
                                        </div>
                                        <div className="item-avs" style={{ display: !v.abs ? "none" : "block" }} >
                                            <div className="item-l">
                                                <span className="sign-success">{v.signStatus ? pcService.numberToText(v.signStatus, pcService.getDataDictionary("ACTIVITY_SIGN_STATUS")) : ""}</span>
                                                <img src={signUrl} alt="" />
                                                <span className="name">{v.name}</span>
                                            </div>
                                            {commentLists.map((_v, n) => (
                                                <div className="item-r">
                                                    <span className="sign-success">{pcService.dateFormat(_v.updatedTime, "yyyy-MM-dd hh:mm")}</span>
                                                    {_v.content}
                                                </div>)
                                            )}
                                        </div>
                                    </div>
                                </li>)
                            })}
                        </ul>
                    </div>

                </div>
            </Spin >
        )
    }
}

export default PartyActivitySign;
