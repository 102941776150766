import React, { Component } from 'react';
import { Row, Spin, Button, Divider, Icon, Popconfirm, message } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import http from '../../common/axios/request';
import moment from 'moment';
import './integralRule.less';

/* 使用状态:1-启用 2 - 待用 3 - 新增， 4 - 停用，可做排序用 */
const userStatus = [
    { id: 1, name: "启用" },
    { id: 2, name: "待用" },
    { id: 3, name: "新增" },
    { id: 4, name: "停用" }
];

class IntegralRule extends Component {

    state = {
        hasFinish: false,
        loading: false,
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "积分规则",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 700
        },
        lists: [],
        selectedRowKeys: []
    }

    renderActionDom = (text, record, index) => {
        switch (record.useStatus) {
            case 1:
                return <a onClick={this.detailItem.bind(this, record)}><Icon type='info-circle' theme="outlined" /> 详情</a>;
            case 2:
                return (<span>
                    <Popconfirm title='确定要启用此项吗？' onConfirm={this.enalbedItem.bind(this, record)} okText="确定"
                        cancelText="取消">
                        <a><Icon type='lock' theme="outlined" /> 启用</a>
                    </Popconfirm>
                    <Divider type="vertical" />
                    <a onClick={this.detailItem.bind(this, record)}><Icon type='info-circle' theme="outlined" /> 详情</a>
                </span>)
            case 3:
                return (<span>
                    <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm title='确定要启用此项吗？' onConfirm={this.enalbedItem.bind(this, record)} okText="确定"
                        cancelText="取消">
                        <a><Icon type='lock' theme="outlined" /> 启用</a>
                    </Popconfirm>
                    <Divider type="vertical" />
                    <a onClick={this.detailItem.bind(this, record)}><Icon type='info-circle' theme="outlined" /> 详情</a>
                </span>)
            case 4:
                return (<span>{/* <Popconfirm title='确定要启用此项吗？' onConfirm={this.enalbedItem.bind(this, record)} okText="确定"
                    cancelText="取消">
                    <a><Icon type='lock' theme="outlined" /> 启用</a>
                </Popconfirm>
                    <Divider type="vertical" /> */}
                    <a onClick={this.detailItem.bind(this, record)}><Icon type='info-circle' theme="outlined" /> 详情</a>
                </span>)

            default:
                return <a onClick={this.detailItem.bind(this, record)}><Icon type='info-circle' theme="outlined" /> 详情</a>
        }
    }

    detailItem = (item) => {
        pcService.relativePath(`/app/integralRuleDetail?pointConfigId=${item.id}`);
    }

    /* 表格列项 */
    columns = [

        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1

        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: this.renderActionDom
        },
        {
            title: '规则名称',
            dataIndex: 'configName',
            key: 'configName'
        }, {
            title: '适用的组织',
            dataIndex: 'orgName',
            key: 'orgName'
        }, {
            title: '积分总分',
            dataIndex: 'pointTotal',
            key: 'pointTotal'
        },
        /* {
            title: '党员党建积分权重',
            dataIndex: 'communistPartyWorkWeight',
            key: 'communistPartyWorkWeight',
            render: text => text + "%"
        },
        {
            title: '党员业务积分权重',
            dataIndex: 'communistBusinessWeight',
            key: 'communistBusinessWeight',
            render: text => text + "%"
        },
        {
            title: '领导平均积分权重',
            dataIndex: 'leaderAvgWeight',
            key: 'leaderAvgWeight',
            render: text => text + "%"
        },
        {
            title: '领导个人积分权重',
            dataIndex: 'leaderPersonalWeight',
            key: 'leaderPersonalWeight',
            render: text => text + "%"
        }, */
        {
            title: '发布日期',
            dataIndex: 'publishTime',
            key: 'publishTime',
            render: text => pcService.dateFormat(text, "yyyy-MM-dd")
        },
        {
            title: '使用状态',
            dataIndex: 'useStatus',
            key: 'useStatus',
            render: text => pcService.numberToText(text, userStatus)
        }
    ];


    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: 'configId',
        value: '',
        originValue: "",
        editor: 'hidden'
    }, {
        name: 'enabled',
        value: true,
        originValue: true,
        editor: 'hidden'
    }, {
        name: "configName",
        displayName: '规则名称',
        value: "",
        originValue: '',
        editor: "normal",
        rules: [
            { message: "请输入规则名称", required: true },
            { message: "长度超出限制", max: 50 }
        ]
    }, {
        name: 'orgName',
        value: "",
        originValue: "",
        editor: 'hidden'
    }, {
        name: "orgId",
        editor: "node-select",
        value: "",
        originValue: "",
        displayName: "适用组织",
        opts: [],
        onChange: (item, form, value, titles) => {
            form.setFieldsValue({ orgName: titles[0] })
        }
    }, {
        name: 'pointTotal',
        displayName: '积分总分',
        editor: 'number',
        value: '',
        originValue: '0',
        range: [0, 99999999],
        rules: [{
            message: "请输入积分总分",
            required: true
        }]
    }, {
        name: 'publishTime',
        value: null,
        originValue: moment(new Date()),
        editor: '',
        displayName: '发布日期',
        editor: "datePicker",
        timeOptions: {
            format: 'YYYY-MM-DD'
        },
        rules: [
            { message: "请选择发布日期", required: true }
        ]
    }, /* {
        name: 'communistPartyWorkWeight',
        displayName: '党员党建积分权重',
        editor: 'addOn',
        addOnOptions: {
            editor: "number",
            addOn: {
                attrs: { disabled: true },
                AddOn: (props) => <span> &nbsp;%</span>
            }
        },
        onChange: (item, forms, value) => {
            forms.setFieldsValue({
                communistBusinessWeight: value < 0 ? 0 : 100 - value
            });
        },
        value: '',
        originValue: '0',
        range: [0, 100],
        rules: []
    }, {
        name: 'communistBusinessWeight',
        displayName: '党员业务积分权重',
        editor: 'addOn',
        addOnOptions: {
            editor: "number",
            addOn: {
                attrs: { disabled: true },
                AddOn: (props) => <span> &nbsp;%</span>
            }
        },
        value: '',
        unavailable: true,
        originValue: '0',
        range: [0, 100],
        rules: []
    }, {
        name: 'leaderPersonalWeight',
        displayName: '领导个人积分权重',
        editor: 'addOn',
        addOnOptions: {
            editor: "number",
            addOn: {
                attrs: { disabled: true },
                AddOn: (props) => <span> &nbsp;%</span>
            }
        },
        onChange: (item, forms, value) => {
            forms.setFieldsValue({
                leaderAvgWeight: value < 0 ? 0 : 100 - value
            });
        },
        value: '',
        originValue: '0',
        range: [0, 100],
        rules: []
    }, {
        name: 'leaderAvgWeight',
        displayName: '领导平均积分权重',
        editor: 'addOn',
        addOnOptions: {
            editor: "number",
            addOn: {
                attrs: { disabled: true },
                AddOn: (props) => <span> &nbsp;%</span>
            }
        },
        value: '',
        originValue: '0',
        range: [0, 100],
        unavailable: true,
        rules: []
    }, */ {
        name: 'remark',
        displayName: '规则说明',
        editor: 'textarea',
        value: '',
        originValue: '',
        rules: [
            { message: "长度超出限制", max: 200 }
        ]
    }];



    /* 获取列表数据 */
    fetchData = (params = {}) => {
        let { pagination } = this.state;
        let { pageSize, current } = this.state.pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.pointConfig.page, _params, null,
                (data) => {
                    let _pagination = pcService.getPagination(data, pagination);
                    this.setState({
                        loading: false,
                        lists: data.rows,
                        pagination: _pagination
                    });
                },
                () => {
                    this.setState({
                        loading: false
                    })
                }
            );
        });
    }

    /* 获取组织机构列表 */
    getOrg = (resolve) => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                params: { flag: false, type: 0 },
                url: urls.app.organization.selectStructureTreeById
            }).then(res => {
                if (res.success) {
                    let opts = res.data || [];
                    let orgAndUsers = pcService.renderOrgAndUserNodes({ data: opts, type: 1 });
                    this.fieldsList.map(v => {
                        if (v.name === "orgId") {
                            v.opts = orgAndUsers;
                        }
                    });
                    //let partyMembers = setJsonArray(orgAndUsers, "children", []);
                    this.setState({ loading: false, hasFinish: true });
                    resolve();
                } else {
                    this.setState({ loading: false });
                    resolve();
                }
            }).catch(() => {
                this.setState({ loading: false });
                resolve();
            })
        })
    }

    /* 新增修改横幅 */
    createOne = (item, create = true) => {
        let { modalOptions, hasFinish, selectedRowKeys } = this.state;
        let that = this;
        let _create = false;
        if (create === false) {
            if (!!item) {
                pcService.relativePath(`/app/integralRuleEdit?pointConfigId=${item.id}`);
            }
            return;
        }
        let msPromise = new Promise((resolve) => {
            if (hasFinish) resolve();
            else this.getOrg(resolve);
        });
        msPromise.then(function () {
            if (selectedRowKeys.length > 0) that.fieldsList.map(v => {
                if (v.name === "configId") {
                    v.originValue = selectedRowKeys[0];
                    v.value = selectedRowKeys[0];
                    return;
                }
            })
            _create = true;
            pcService.initFormList(that.fieldsList);
            that.setState({
                modalOptions: { ...modalOptions, visible: true },
                create: _create
            });
        });

    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = urls.app.pointConfig.addEntity, msg = "新增";
                this.setState({
                    modalOptions: { ...modalOptions, modalsLoading: true }
                });
                pcService.formSubmit(url, true, values, null, () => {
                    message.success(msg + "操作成功!");
                    this.setState({
                        modalOptions: {
                            selectedRowKeys: [],
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    }, this.fetchData);
                }, () => {
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    });
                });
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 启用停用 */
    enalbedItem = (item) => {
        let url = urls.app.pointConfig.updatePointConfigStatusData;
        let data = { pointConfigId: item.id, useStatus: 1 };
        this.setState({ loading: true });
        pcService.formSubmit(url, true, null, data, () => {
            message.success("启用成功!");
            this.setState({
                loading: true
            }, this.fetchData);
        }, (msg) => {
            message.error(msg);
            this.setState({ loading: false });
        })
    }

    /* 选中 onChange事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { loading, pagination, lists, modalOptions, create, selectedRowKeys } = this.state;
        const { columns, fetchData, createOne, fieldsList, onOk, onCancel, onSelectChange } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        const allowCopy = selectedRowKeys.length > 1;
        return (
            <div className="interal-rules">
                <Spin spinning={loading}>
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button disabled={allowCopy} icon="file-add" onClick={createOne} type="primary">复制新增</Button>
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                rowSelection={rowSelection}
                                bordered={false}
                            />
                        </div>
                    </Row>
                    <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading}
                        formItemLayout={formItemLayout} />
                </Spin>
            </div>
        )
    }
}

export default IntegralRule;
