import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { hashHistory } from 'react-router';
import HomeHeaderBar from '../../common/components/homeHeaderBar/HomeHeaderBar';
import mainRouterArr from '../../configs/mainRouterArr.service';
import SwitchCustom from '../../common/components/switchCustom/SwitchCustom';
import SiderSearch from '../../common/components/switchCustom/SiderSearch';
import appConfig from "../../configs/app.config";
import { getCurrentMenus } from "./../../common/components/switchCustom/menu.service";
import { isNotEmpty, setJsonArray, getLocal } from "./../../common/utils";
import NavPanel from './NavPanel';
import pcService from '../../other/pc.service';
import './main.css';
import logoicon from './../../img/login-logo.png';

const { Header, Sider } = Layout;
const { SubMenu } = Menu;

class Main extends Component {

    state = {
        collapsed: false,
        userMenus: [],
        showShortcutMenu: false,
        showFilterMenu: false,
        menuList: [],
        selectedKeys: [],
        openKeys: []
    };

    /* 收起/展开侧边栏 */
    toggle = () => {
        let { collapsed } = this.state;
        this.setState({
            collapsed: !collapsed,
            openKeys: [],
            showFilterMenu: false
        });
    };

    /* 通过pathName获取子组件 */
    getChildByPathName = () => {
        let childPathName = this.props.location.pathname;
        let childComponentArr = mainRouterArr.filter(v => v.path === childPathName);
        let Page404 = mainRouterArr.filter(v => v.path === "/app/404")[0].component;
        return childComponentArr.length > 0 ? childComponentArr[0].component : Page404;
    }

    /* 点击跳转相应的路由 */
    onClickMenu = (item) => {
        pcService.relativePath(item.path, item)
    };

    /* 菜单item */
    renderMenuItem = (item, showIcon = true, showPath = false, index) => (
        <li onClick={this.onClickMenu.bind(this, item)} title={item.title} key={index} className="ant-menu-submenu ant-menu-submenu-inline" role="menuitem">
            <div className="ant-menu-submenu-title" aria-expanded="false" aria-haspopup="true" style={{ paddingLeft: 24 }}>
                <div className="pc-title">
                    <Icon type={item.icon} />
                    <span>{item.title}</span>
                </div>
            </div>
        </li>);

    //生成搜索的菜单
    renderFilterMenu = (filterMenu) => {
        return filterMenu.length === 0 ? <p style={{ padding: 15 }}>未查询到匹配的数据</p> : filterMenu.map((item, index) => this.renderMenuItem(item, false, false, index));
    };

    /* 应用模块显示隐藏 */
    handleIcon = () => {
        const { showShortcutMenu } = this.state;
        this.setState({ showShortcutMenu: !showShortcutMenu });
    };

    /* 菜单搜索 */
    handleSearch = (value) => {
        let allMenuList = getCurrentMenus();
        if (allMenuList.length > 0) {
            if (isNotEmpty(value)) {
                let menusAll = setJsonArray(allMenuList, "menus");
                let filterMenus = menusAll.filter(item => (item.title.includes(value) && !!item.path));
                //filterMenus = convertListToTreeJson(filterMenus, "menus");
                this.setState({
                    menuList: filterMenus,
                    showFilterMenu: true
                });
            } else {
                this.setState({
                    showFilterMenu: false
                });
            }
        }
    };

    /* 返回首页 */
    goHome = () => {
        hashHistory.push("/home");
    }

    /* 组件加载完成:获取初始化用户信息 */
    componentWillMount() {
        const { location = {} } = this.props;
        let userMenus = getLocal("userMenus") || [];
        this.setState({ userMenus });
        const { pathname } = location;
        let concatArr = userMenus.filter(v => v.menus.some(_v => _v.path === pathname))
        this.setState({
            selectedKeys: [pathname],
            userMenus,
            openKeys: concatArr.map(v => v.id)
        })
    }

    /* 设置父组件左侧菜单选择项 */
    setParentSelectedKeys = (selectedKeys) => {
        let { userMenus, collapsed } = this.state;
        let concatArr = userMenus.filter(v => v.menus.some(_v => _v.path === selectedKeys[0]))
        this.setState({ selectedKeys, openKeys: (selectedKeys.length > 0 && !collapsed) ? concatArr.map(v => v.id) : [] });

    }

    /* 菜单被选中方法 */
    menuOnSelect = ({ item, key, keyPath, selectedKeys }) => {
        this.setState({ selectedKeys });
    }

    /* 菜单折叠展开方法 */
    onOpenChange = (openKeys = []) => {
        let rsArr = openKeys.length > 0 ? [openKeys[openKeys.length - 1]] : []
        this.setState({ openKeys: rsArr });
    }

    render() {
        const { collapsed, userMenus, showShortcutMenu, selectedKeys, openKeys, showFilterMenu, menuList } = this.state;
        const { toggle, handleIcon, handleSearch, goHome, renderFilterMenu, setParentSelectedKeys, menuOnSelect, onOpenChange } = this;
        const { location = {} } = this.props;
        return (
            <div className="poc-main" >
                <Layout>
                    <Sider trigger={null} collapsible collapsed={collapsed}>
                        <div className="goHome" title={appConfig.sysName}>
                            <div className="logo" onClick={goHome}>
                                <img src={logoicon} alt={appConfig.sysName} />
                            </div>
                            <div className="system-name" onClick={goHome}>{appConfig.sysName}</div>
                        </div>
                        <div className="SiderCustom">
                            {appConfig.isUseMenuSearch ?
                                <div className={!collapsed ? "SiderSearch" : "SiderIcon"} onClick={!collapsed ? undefined : handleIcon} title={!collapsed ? "" : "应用模块"}>
                                    {!collapsed ? <SiderSearch collapsed={collapsed} handleSearch={handleSearch} /> : <Icon type="windows" className="SiderIconTxt" />}
                                </div> : null
                            }
                        </div>
                        {showFilterMenu ?
                            <section className='menuWrapper'>
                                <ul className="ant-menu ant-menu-dark ant-menu-root ant-menu-inline" role="menu">
                                    {renderFilterMenu(menuList)}
                                </ul>
                            </section> : (
                                <Menu onOpenChange={onOpenChange} selectedKeys={selectedKeys} openKeys={openKeys} onSelect={menuOnSelect} theme="dark" mode="inline">
                                    {
                                        userMenus.map((v, n) => (
                                            <SubMenu title={
                                                <div className="pc-title">
                                                    <Icon type={v.icon} />
                                                    <span>{v.title}</span>
                                                </div>
                                            } key={v.id}>
                                                {v.menus.map((_v, _n) => (
                                                    <Menu.Item key={_v.path} onClick={pcService.relativePath.bind(this, _v.path, _v)}>
                                                        <Icon type={_v.icon} />
                                                        <span>{_v.title}</span>
                                                    </Menu.Item>))
                                                }
                                            </SubMenu>
                                        ))
                                    }
                                </Menu>
                            )}
                        {showShortcutMenu ? <SwitchCustom handleIcon={handleIcon} /> : null}
                    </Sider>
                    <Layout className="content-layout">
                        <Header className="headCustom">
                            <Icon className="trigger" type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={toggle} />
                            <div className="app-nav">
                                <HomeHeaderBar />
                            </div>
                        </Header>
                        <NavPanel setParentSelectedKeys={setParentSelectedKeys} location={location} />
                    </Layout>
                </Layout>
            </div >
        )
    }
}

export default Main;
