import React, { Component } from 'react';
import { Row, Spin, Button, Divider, message, Icon, Popconfirm, Popover, Modal } from 'antd';
import PcTable from '../../common/components/table/tableComponent';
import PcForm from '../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import PcService from '../../other/pc.service';
import urls from '../../configs/api.config';
import http from './../../common/axios/request';
import pcService from '../../other/pc.service';
import AttachList from './AttachList';
import moment from 'moment';
import './talk.css';


class Talk extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        talkMemberOpts: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        visible: false,
        modalOptions: {
            title: "谈心谈话",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        modalDetailOperation: {
            title: "谈心谈话详情",
            visible: false,
            cancelText: "返回",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: [],
        talkMemberTreeShow: '',
        talkLeaderName: '',
        talkMemberName: '',
    }

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                        <Divider type="vertical" />
                        <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" /> 编辑</a>
                    </span>
                )
            }
        },
        {
            title: '谈话对象',
            dataIndex: 'talkMemberName',
            key: "talkMemberName",
            render: value => value.length > 20 ? <Popover content={value} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {value.slice(0, 20) + '...'}
            </Popover> : value
        },
        {
            title: '所属党组织',
            dataIndex: 'partyName',
            key: "partyName",
            render: value => value.length > 20 ? <Popover content={value} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {value.slice(0, 20) + '...'}
            </Popover> : value
        },
        {
            title: '谈话时间',
            dataIndex: 'talkTime',
            key: "talkTime",
            render: (text) => !!text ? text.substring(0, 10) : text
        }
    ];

    seachFieldsList = [{
        name: 'partyCode',
        displayName: '所属党组织',
        editor: "node-select",
        treeCheckable: false,
        value: "",
        originValue: "",
        opts: [],
        multiple: false,
        onChange: (item, value, $event) => {
        },
    }, {
        name: "talkMemberName",
        editor: "normal",
        value: "",
        displayName: "谈话对象",
        originValue: ""
    }];

    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "id",
            editor: "hidden",
            displayName: "id",
            value: "",
            originValue: "",
        },
        {
            name: "talkMemberTreeShow",
            editor: "node-select",
            displayName: "谈话对象",
            value: "",
            opts: [],
            originValue: [],
            nodeOptions: {
                treeCheckable: true
            },
            onChange: (_item, form, _value, _tital, opts) => {
                let arr = new Map();
                let codevalue = _value.map(v => v.split("_")[1]).filter((a) => !arr.has(a) && arr.set(a, 1))
                form.setFieldsValue({
                    "partyCode": codevalue
                });
                this.setState({
                    talkMemberName: _tital ? _tital.join(",") : _tital,
                })
            },
            rules: [
                { message: '请选择谈话对象', required: true }
            ],
        },
        {
            name: "talkLeaderTreeShow",
            displayName: "谈话参与人员",
            editor: 'node-select',
            value: '',
            originValue: [],
            opts: [],
            nodeOptions: {
                treeCheckable: true
            },
            rules: [{
                required: true,
                message: "请选择谈话参与人员"
            }],
            onChange: (_item, form, _value, _tital, opts) => {
                this.setState({
                    talkLeaderName: _tital ? _tital.join(",") : _tital,
                })
            },
        },
        {
            name: "partyCode",
            editor: "node-select",
            value: null,
            originValue: null,
            displayName: "所属党组织",
            unavailable: true,
            opts: [],
            nodeOptions: {
                multiple: true
            },
            rules: [
                { required: true, message: "请选择所属党组织" }
            ],
            onChange: (_item, form, _value, _tital, opts) => {
            },
        },
        {
            name: "talkTime",
            editor: "datePicker",
            value: "",
            originValue: null,
            displayName: "谈话时间",
            rules: [
                {
                    required: true,
                    message: "请选择谈话时间"
                }
            ]
        },
        {
            name: "talkAddress",
            editor: "normal",
            value: "",
            displayName: "谈话地点",
            rules: [
                {
                    required: true,
                    message: "请输入谈话地点"
                },
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        },
        {
            name: "talkRecord",
            editor: "textarea",
            value: "",
            displayName: "备注",
            rules: [
                { max: 500, message: '最多可以输入500个字符' }
            ]
        },
        {
            name: "enclosureList",
            editor: "attachments",
            value: [],
            originValue: [],
            displayName: "上传附件",
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            }
        }
    ];
    fieldsListDetailList = [{
        name: "talkMemberName",
        value: "",
        displayName: "谈话对象",
    },
    {
        name: "talkLeaderName",
        value: "",
        displayName: "谈话参与人员",
    },
    {
        name: "partyName",
        value: "",
        displayName: "所属党组织",
    }, {
        name: "talkTime",
        value: "",
        displayName: "谈话时间",
    }, {
        name: "talkAddress",
        value: "",
        displayName: "谈话地点",
    }, {
        name: "talkRecord",
        value: "",
        displayName: "备注",
    }, {
        name: "enclosureList",
        value: "",
        displayName: "附件"
    }]
    //删除Mongo文件  
    errUpload = (deleteMongoId) => {
        this.setState({ loading: true });
        http.request({
            method: "delete",
            url: urls.file.deleteMongo + '/' + deleteMongoId
        }).then(res => {
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    /* 钩子函数 */
    componentDidMount() {
        this.fetchData();
    }
    selectOrgAndUsers = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: {
                flag: true,
                type: 0,
                injob: "0"
            },
        }).then(res => {
            let treeData = res.data;
            let lists = PcService.renderOrgAndUserNodes({ data: treeData, type: 0 });
            if (res.success === true) {
                if (res.data.length > 0) {
                    this.fieldsList.map(v => {
                        if (v.name === "talkMemberTreeShow" || v.name === "talkLeaderTreeShow") {
                            v.opts = lists
                        }
                    })
                }
                this.setState({
                    talkMemberOpts: lists,
                    loading: false
                });
            } else {
                message.error(res.message);
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({
                loading: false
            });
        });
    }
    // 所属党组织
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                let lists = treeData ? treeData : [];

                this.seachFieldsList.map(v => {
                    if (v.name === "partyCode") {
                        v.opts = [{ id: "", name: "全部" }].concat(lists);
                    }
                })
                this.fieldsList.map(v => {
                    if (v.name === "partyCode") {
                        v.opts = lists;
                    }
                })
                this.setState({
                    treeList: treeData,
                });
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.talk.page, _params, null, (data) => {
                let _pagination = PcService.getPagination(data, pagination);
                this.setState({
                    loading: false,
                    lists: data.rows,
                    pagination: _pagination
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    }


    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
                //console.log(values);
            }
        });
    }

    /* 新增党组织关系 */
    createOne = (item, create) => {
        let _item = { ...item };
        _item.talkTime = moment(_item.talkTime)
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            // _item["talkLeaderTreeShow"] = _item["talkLeaderTreeShow"] ? _item["talkLeaderTreeShow"].split(",") : [];
            // _item["talkMemberTreeShow"] = _item["talkMemberTreeShow"] ? _item["talkMemberTreeShow"].split(",") : [];
            _item["partyCode"] = _item["partyCode"] ? _item["partyCode"].split(",") : [];
            PcService.bindFormdData(_item, this.fieldsList);
            this.setState({
                talkMemberName: _item.talkMemberName,
                talkLeaderName: _item.talkLeaderName,
            })
        } else {
            _create = true;
            this.fieldsList.map(v => {
                if (v.name === "enclosureList") {
                    v.fileUpLoadOption.fileList = undefined;
                }
            })
            PcService.initFormList(this.fieldsList);
        }

        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create, talkLeaderName, talkMemberName } = this.state;
        let that = this;
        forms.validateFields((errors, value) => {
            if (errors) {
                return;
            } else {
                value.talkLeaderName = talkLeaderName;
                value.talkMemberName = talkMemberName;
                value.partyCode = value.partyCode.join(",")
                // value.talkMemberTreeShow = value['talkMemberTreeShow'] ? value['talkMemberTreeShow'].join(",") : "";
                // value.talkLeaderTreeShow = value['talkLeaderTreeShow'] ? value['talkLeaderTreeShow'].join(",") : "";
                let url = create ? urls.app.talk.addtalk : urls.app.talk.updateEntity;
                this.setState({ loading: true });
                PcService.formSubmit(url, true, value, null, (data) => {
                    message.success("操作成功!");
                    this.fetchData();
                    this.setState({ loading: false });
                }, (err) => {
                    message.error(err);
                    this.setState({ loading: false });
                });
                setTimeout(function () {
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            visible: false
                        }
                    })
                }, 1000);
            }
        });

    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 详情 */
    detailItem = (item) => {
        let id = item.id;
        // pcService.relativePath(`/app/talkDetail?recordId=${id}`);

        let { modalDetailOperation } = this.state;
        pcService.bindFormdData(item, this.fieldsListDetailList);

        this.setState({
            modalDetailOperation: {
                ...modalDetailOperation,
                visible: true,
            },
        });
    }

    /* modal detail */
    onDetailOk = (forms) => {
        let { modalDetailOperation } = this.state;
        let that = this;
        this.setState({
            modalDetailOperation: {
                ...modalDetailOperation,
                visible: false
            }
        });
    }
    onDetailCancel = () => {
        let { modalDetailOperation } = this.state;
        this.setState({
            modalDetailOperation: {
                ...modalDetailOperation,
                visible: false
            }
        });
    }

    /* 删除列表项 */
    deleteItem = (item, $event) => {
        let url = urls.app.talk.deleteLogic;
        let addStrng = '';
        item.map(v => {
            addStrng += v + ',';
        });
        let that = this;
        pcService.deleteItem(url, {}, addStrng,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                this.setState({ loading: false });
            }, err => {
                this.setState({
                    loading: false
                });
            });
    };

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentWillMount() {
        PcService.initFormList(this.seachFieldsList);
        //获取党组织
        this.getOrganizationTree();
        //参会人员选择
        this.selectOrgAndUsers();
    }

    render() {

        const { visible, loading, selectedRowKeys, pagination, lists, modalOptions, create, modalDetailOperation } = this.state;
        const { onSelectChange, columns, fetchData, seachFieldsList, seachOnSubmit, createOne, fieldsList, onOk, onCancel, deleteItem, cancel, handleVisibleChange, onDetailOk, onDetailCancel, fieldsListDetailList } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const modalDetail = { ...modalDetailOperation, onCancel: onDetailCancel, onOk: onDetailOk, width: 660, title: modalDetailOperation.title };
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button onClick={createOne} type="primary" icon="file-add">新增</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                </Popconfirm>
                            </div>
                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset fieldsList={seachFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                <PcModal fieldsList={fieldsList} modal={modal} columns="2" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                <Modal {...modalDetail} className={"partyFeeBaseChanging"}>
                    <div className={"partyFeeBaseChanging-item"}>
                        {
                            fieldsListDetailList.map((v, i) => v.name === "enclosureList" ? <AttachList itemAttach={v.value} itemName={"附件"} />
                                : <p key={i}><span>{v.displayName} ：</span>{v.name === "talkTime" && v.value ? v.value.substring(0, 10) : v.value}</p>)
                        }
                    </div>
                </Modal >
            </Spin >
        )
    }
}

export default Talk;
