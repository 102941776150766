import React, { Component } from 'react';
import { Table, Modal } from 'antd';
import FormText from '../formText/FormText';

class DetailModals extends Component {

    render() {
        const { fieldsList = [], modal = {}, steped = true, formItemLayout = {}, lists = null, columns = [] } = this.props;
        return (
            <Modal  {...modal} footer={null}>
                <div className="itemBank-modal">
                    <div className="ibm-part">
                        {steped ? <h2>基本信息</h2> : ""}
                        <FormText fieldsList={fieldsList} formItemLayout={formItemLayout} />
                    </div>
                    {lists ? <div className="ibm-part">
                        {steped ? <h2>选项</h2> : ""}
                        <Table pagination={false} size="small" bordered columns={columns} dataSource={lists} />
                    </div> : ''}
                </div>
            </Modal>
        )
    }
}

export default DetailModals;
