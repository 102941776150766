import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import moment from 'moment';
import { getLocal } from './../../common/utils';
import Forms from './../../common/components/form/Forms';
import PcService from './../../other/pc.service';
import axios from "axios";
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import { Button, message, Spin } from 'antd';
import { convertListToTreeJson, setJsonArray } from "../../common/utils";

export class AssistPlanAdd extends Component {
    state = {
        isEdit: false, // 新增或编辑  
        lists: [],
        difficultList: [],
        finish: false,
    }
    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "assistTheme",
            editor: "normal",
            value: "",
            displayName: "帮扶主题",
            rules: [
                {
                    required: true,
                    message: "请输入帮扶主题"
                },
                { max: 40, message: '最多可以输入40个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "assistOrgId",
            editor: "node-select",
            treeCheckable: false,
            displayName: "活动组织单位",
            opts: [],
            nodeOptions: {
                multiple: false
            },
            onChange: (_item, form, _value, _tital, opts) => {
                form.setFieldsValue({ "assistOrgName": _tital[0] });
            },
            rules: [
                { message: '请选择活动组织单位', required: true }
            ],
        }, {
            name: "assistOrgName",
            editor: "hidden",
            value: "",
            originValue: '',
            displayName: "帮扶活动组织单位名字",
        }, {
            name: "id",
            editor: "hidden",
            value: "",
            originValue: '',
            displayName: "id",
        }, {
            name: "assistTime",
            displayName: "帮扶时间",
            editor: "datePicker",
            timeOptions: {
                format: "YYYY-MM-DD",
                showTime: false,
            },
            maxToday: true,
            value: null,
            originValue: null,
            rules: [
                {
                    required: true,
                    message: "请选择帮扶时间"
                }
            ]
        }, {
            name: "assistType",
            editor: "select",
            opts: PcService.getDataDictionary("ASSIST_RECORD_TYPE"),
            value: "",
            originValue: "",
            displayName: "帮扶类型",
            rules: [
                {
                    required: true,
                    message: "请选择帮扶类型"
                }
            ]
        }, {
            name: "difficultList",
            editor: "node-select",
            displayName: "帮扶对象",
            opts: [],
            columns: 1,
            value: null,
            originValue: null,
            nodeOptions: {
                treeCheckable: true
            },
            onChange: (_item, form, _value, _tital, opts) => {
                this.setState({ difficultList: _value })
            },
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            rules: [
                { message: '请选择帮扶对象', required: true }
            ],
        }
    ];
    fieldsListPlan = [
        {
            name: "remark",
            editor: "bEditor",
            value: "",
            columns: 1,
            displayName: "活动安排说明",
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
        },
        {
            name: "attachment",
            editor: "attachments",
            value: [],
            originValue: [],
            displayName: "附件",
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            }
        }
    ];
    handleCancel = () => {
        let panelId = PcService.getIdByPath("/app/assistPlanAdd");
        PcService.removePanel(panelId );
    }
    /* 获取form参数*/
    onBaseSubmit = (form, $event) => {
        let data = {}, errs = {}, isStop = false;
        $event.preventDefault();
        form.validateFields((err, fieldsValue) => {
            data = fieldsValue;
            //console.log(data)
            errs = err;
        });
        for (let v in errs) {
            if (errs[v]) {
                isStop = true;
            }
        }
        if (isStop) {
            return 'stop';
        } else {
            return data;
        }
    };

    handleSave = ($event) => {
        const { isEdit, difficultList } = this.state;
        let that = this;
        const newForms = this.refs.newForms.getForm();
        const newPlanForms = this.refs.newPlanForms.getForm();
        let submitBase = this.onBaseSubmit(newForms, $event);
        let submitPlan = this.onBaseSubmit(newPlanForms, $event);

        if (submitBase === 'stop' || submitPlan === 'stop') return;
        //活动负责人
        submitBase.difficultList = this.getUserIdInfo(difficultList);
        submitBase.assistTime = PcService.dateFormat(new Date(submitBase.assistTime), "yyyy-MM-dd");
        //console.log(submitBase)

        if (submitBase) {
            //判断新增或修改
            let url = isEdit ? urls.app.assistRecord.update : urls.app.assistRecord.add;

            let _params = Object.assign({}, submitBase, submitPlan);
            this.setState({ loading: true });
            PcService.formSubmit(url, true, _params, null, (data) => {
                message.success("操作成功!");
                this.setState({ loading: false });
                let panelId = PcService.getIdByPath("/app/assistPlanAdd");
                PcService.removePanel(panelId, true, '/app/assistRecordReview');
            }, (err) => {
                message.error(err);
                this.setState({ loading: false });
            });

        }

    }
    //根据用户的userid，返还用户全部信息
    getUserIdInfo = (filterUserIds) => {
        let userIds = [];
        let jsonArr = setJsonArray(this.state.lists, "children");
        jsonArr.map(v => {
            if (filterUserIds.indexOf(v.id) !== -1) {
                userIds.push(v);
            }

        })
        userIds && userIds.map(v => {
            v.idTreeShow = v.id;
        })

        return userIds;
    }

    selectOrgAndUsers = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: {
                isHard: 1,
                flag: true,
                type: 0,
            },
        }).then(res => {
            if (res.success === true) {
                let treeData = res.data;
                let listsOrg = PcService.renderOrgAndUserNodes({ data: treeData, type: 1 });
                let lists = PcService.renderOrgAndUserNodes({ data: treeData, type: 0, renderAll: true });
                this.fieldsList.map(v => {
                    if (v.name === "difficultList") v.opts = lists;
                    if (v.name === "assistOrgId") v.opts = listsOrg;
                })
                this.setState({ lists, loading: false });
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    componentDidMount() {
        PcService.initFormList(this.fieldsList)
        PcService.initFormList(this.fieldsListPlan)
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        //参会人员选择
        this.selectOrgAndUsers();

        let that = this;
        this.setState({
            loading: true,
            isEdit: id ? true : false
        });
        //判断新增或修改
        if (!id) {
            this.setState({ finish: true })
            return;
        }
        http.request({
            method: 'get',
            url: urls.app.assistRecord.findById,
            params: { id },
        }).then(res => {
            if (res.success === true) {
                let record = res.data;
                //usersId 过滤
                let difficultList = [];
                if (record.difficultList) record.difficultList.map(v => difficultList.push(v.idTreeShow));
                record.difficultList = difficultList;
                PcService.bindFormdData(record, this.fieldsList);
                PcService.bindFormdData(record, this.fieldsListPlan);
                that.setState({
                    difficultList: record.difficultList, loading: false
                });
            } else {
                message.error(res.message);
                that.setState({ loading: false });
            }
            that.setState({
                finish: true,
            });
        }).catch(err => {
            message.error(err.message);
            that.setState({ loading: false });
        });

    }


    render() {

        const { fieldsList = [], fieldsListPlan = [], handleCancel, handleSave, onBaseSubmit } = this;
        const { loading, finish } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };

        return (
            <Spin spinning={loading} className="">
                <div>
                    <div>
                        <div className="panew-marin-tipinfo">
                            <span className="panew-main-tip" />
                            <label> 帮扶活动基础信息</label>
                        </div>
                        <div className="panew-form newForms">
                            <Forms ref="newForms" onSubmit={onBaseSubmit} columns="2" fieldsList={fieldsList} hideFooter
                                formItemLayout={formItemLayout} />
                        </div>
                    </div>
                    <div>
                        <div className="panew-marin-tipinfo">
                            <span className="panew-main-tip" />
                            <label> 活动安排</label>
                        </div>
                        <div className="panew-form newPlanForms">
                            {finish ? <Forms ref="newPlanForms" onSubmit={onBaseSubmit} columns="1" fieldsList={fieldsListPlan}
                                hideFooter formItemLayout={formItemLayout} /> : null}
                        </div>
                    </div>
                    <div className="panew-footer-button">
                        <Button type="default" style={{ marginRight: '8px' }} onClick={handleCancel} icon="redo">取消</Button>
                        <Button type="primary" onClick={handleSave} icon="check">确定</Button>
                    </div>

                </div>
            </Spin>
        )
    }
}

export default AssistPlanAdd;
