import React, { Component } from 'react';
import { Row, Spin, Button, Divider, Icon, Popconfirm, message } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import http from '../../common/axios/request';

class BannerPosition extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 100,
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "横幅位置",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        lists: []
    }

    /* 表格列项 */
    columns = [

        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1

        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, event) => {
                return <span>
                    <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" theme="outlined" /> 修改</a>
                    <Divider type="vertical" />
                    <Popconfirm title="确定要删除此项吗？" onConfirm={this.deleteItem.bind(this, record.id)} okText="确定"
                        cancelText="取消">
                        <a><Icon type="delete" theme="outlined" /> 删除</a>
                    </Popconfirm>
                </span>
            }
        }, {
            title: '名称',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: '位置',
            dataIndex: 'locationDict',
            key: 'locationDict',
            render: text => pcService.numberToText(text, pcService.getDataDictionary("BANNER_POSITION"))
        }, {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark'
        }, {
            title: '端类型',
            dataIndex: 'typeDict',
            key: 'typeDict',
            render: text => pcService.numberToText(text, pcService.getDataDictionary("BANNER_POSITION_TYPE"))
        }
    ];

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "name",
        editor: "normal",
        value: "",
        originValue: "",
        hideDisplayName: true,
        displayName: "名称"
    }];

    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'name',
        displayName: '名称',
        editor: 'normal',
        value: '',
        originValue: '',
        rules: [
            { message: "请输入名称", required: true },
            { message: "最多为20个字符", max: 20 }
        ]
    }, {
        name: 'typeDict',
        displayName: '端类型',
        editor: 'select',
        value: '',
        originValue: pcService.getDataDictionary("BANNER_POSITION_TYPE")[0] ? pcService.getDataDictionary("BANNER_POSITION_TYPE")[0].id : 0,
        opts: pcService.getDataDictionary("BANNER_POSITION_TYPE"),
        rules: [{
            message: "请选择端类型",
            required: true
        }]
    }, {
        name: 'locationDict',
        displayName: '位置',
        editor: 'select',
        value: '',
        originValue: pcService.getDataDictionary("BANNER_POSITION")[0] ? pcService.getDataDictionary("BANNER_POSITION")[0].id : 0,
        opts: pcService.getDataDictionary("BANNER_POSITION"),
        rules: [{
            message: "请选择位置",
            required: true
        }]
    }, {
        name: 'remark',
        displayName: '备注',
        editor: 'textarea',
        value: '',
        originValue: '',
        rules: [
            { message: "最多为200个字符", max: 200 }
        ]
    }];

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 获取列表数据 */
    fetchData = (params = {}) => {
        let { pagination } = this.state;
        let { pageSize, current } = this.state.pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.bannerLocation.page, _params, null,
                (data) => {
                    let _pagination = pcService.getPagination(data, pagination);
                    this.setState({
                        loading: false,
                        lists: data.rows,
                        pagination: _pagination
                    });
                },
                () => {
                    this.setState({
                        loading: false
                    })
                }
            );
        });
    }

    /* 搜索 */
    seachOnSubmit = (form) => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }

    /* 新增修改民主评议 */
    createOne = (item, create = true) => {
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            pcService.bindFormdData(item, this.fieldsList);
        } else {
            _create = true;
            pcService.initFormList(this.fieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = "", method = "post", msg = "";
                if (create) {
                    url = urls.app.bannerLocation.addEntity;
                    msg = "新增";
                } else {
                    url = urls.app.bannerLocation.updateEntity;
                    msg = "修改";
                }
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                });
                http.request({
                    method,
                    url,
                    data: values
                }).then(res => {
                    if (res.success) {
                        message.success(msg + "操作成功!");
                    }
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    }, this.fetchData);
                }).catch(err => {
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    });
                })
            }
        });
    }

    /* 删除 */
    deleteItem = (ids) => {
        let that = this;
        this.setState({ loading: true });
        pcService.deleteItem(urls.app.bannerLocation.deleteLogic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.componentDidMount();
                }, 500);
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    componentDidMount() {
        let form = this.refs.search.getForm();
        this.seachOnSubmit(form);
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
    }

    render() {
        const { loading, selectedRowKeys, pagination, lists, modalOptions, create } = this.state;
        const { onSelectChange, columns, fetchData, searchFieldsList, seachOnSubmit, deleteItem, createOne, fieldsList, onOk, onCancel } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        //const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <Spin spinning={loading}>
                <Row>
                    <div className="sp-bars">
                        <div className="sp-btns">
                            <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                            {/*  
                                <Divider type="vertical" />
                                <Button disabled={!allowDel} onClick={deleteItem.bind(this, selectedRowKeys)} icon="delete" disabled={!allowDel}>删除</Button>
                            */}
                        </div>
                        <div className="sp-forms">
                            <PcForm ref="search" layout="inline" submitText="搜索" onSubmit={seachOnSubmit} fieldsList={searchFieldsList} />
                        </div>
                    </div>
                    <div className="sp-content">
                        <PcTable
                            //rowSelection={rowSelection}
                            columns={columns}
                            pagination={pagination}
                            dataSource={lists}
                            fetchData={fetchData}
                            bordered={false}
                        />
                    </div>
                </Row>
                <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
            </Spin >
        )
    }
}

export default BannerPosition;
