import React, { Component } from 'react';
import { Spin, Button, Input, DatePicker, Icon, message, Tree } from 'antd';
import OrgInfoForm from './OrgInfoForm';
import OrgInfoFormLeader from './OrgInfoFormLeader';
import OrgInfoFormOther from './OrgInfoFormOther';
import PcService from './../../other/pc.service';
import { hashHistory } from 'react-router';
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import appConfig from './../../configs/app.config';
import { convertListToTreeJson } from './../../common/utils'
import './organization.css';

export class OrganizationNew extends Component {
  state = {
    loading: false,
    id: "",
    leaderInfo: [],
    treeList: [],
    member: [],
    newPosition: [],
    howManyCommitte: 1,
    finish: false,
    rightClickNodeTreeItem: {
      pageX: "",
      pageY: "",
      id: "",
      categoryName: "",
    },
    linkedName: "",
    feeMemberName: "",
  }

  /* 修改表单 列项*/
  fieldsListInfo = [{
    name: 'superPartyName',
    displayName: '上级党组织',
    editor: 'normal',
    value: "",
    unavailable: true,
    originValue: "",
  }, {
    name: 'partyType',
    displayName: '组织类别',
    editor: 'select',
    value: '',
    unavailable: true,
    originValue: "",
    opts: PcService.getDataDictionary("ORG_TYPE"),
    rules: [
      { required: true, message: '请选择组织类别' }
    ]

  }, {
    name: 'partyName',
    displayName: '党组织名称',
    editor: 'normal',
    value: '',
    originValue: '',
    rules: [
      { required: true, message: '请输入党组织名称' },
      { max: 20, message: '最多可以输入20个字符' },
      { whitespace: true, message: '不能全为空格' }
    ]
  }, {
    name: 'foundingTime',
    displayName: '成立时间',
    editor: 'datePicker',
    value: null,
    originValue: null,
    timeOptions: {
      showToday: true,
      format: 'YYYY-MM-DD'
    },
    rules: [{ required: true, message: '请选择成立日期' }]
  }, {
    name: 'unitsName',
    displayName: '所属单位',
    editor: 'normal',
    value: '',
    originValue: '',
    rules: [
      { required: true, message: '请输入所属单位' },
      { whitespace: true, message: '不能全为空格' }
    ]
  }, {
    name: 'partyAddress',
    displayName: '组织地址',
    editor: 'normal',
    value: '',
    originValue: '',
    rules: [
      { required: false, message: '请输入组织地址' },
      { whitespace: true, message: '不能全为空格' }
    ]
  }, {
    name: 'feeMemberTreeShow',
    displayName: '指定收款人',
    editor: "node-select",
    treeCheckable: false,
    value: "",
    opts: [],
    nodeOptions: {
      multiple: false
    },
    onChange: (_item, form, _value, _tital, opts) => {
      this.setState({
        feeMemberName: _tital[0]
      })
    },
  }, {
    name: 'feeUrl',
    displayName: '收款二维码',
    editor: 'pictureWall',
    value: '',
    originValue: '',
    fileUpLoadOption: {
      listType: 'picture-card',
      className: 'avatar-uploader',
      showUploadList: false,
      action: urls.file.uploadGetId,
      accept: "image/*",
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('图片必须小于2MB!');
        }
        return isLt2M;
      },
      onChange: (info, form, _onchange) => {
        if (info.file.status === 'done') {
          let _value = info.fileList.map(v => v.response);
          _onchange(_value.join(","));
        }
      }
    }
  }];

  fieldsListLeaders = [{
    name: 'orgMaster',
    editor: "node-select",
    treeCheckable: false,
    displayName: "书记",
    value: "",
    opts: [],
    nodeOptions: {
      multiple: false
    },
    onChange: (_item, form, _value, _tital, opts) => {
    },
  }, {
    name: 'orgMasterVice',
    editor: "node-select",
    treeCheckable: false,
    displayName: "副书记",
    value: "",
    opts: [],
    nodeOptions: {
      multiple: false
    },
    onChange: (_item, form, _value, _tital, opts) => {
    },
  }, {
    name: 'committeDuty0',
    displayName: '委员职务',
    editor: 'select',
    value: '',
    originValue: "",
    opts: [],
    onChange: (_item, form, _value, option) => {
    },
  }, {
    name: 'committeName0',
    displayName: '委员姓名',
    editor: "node-select",
    treeCheckable: false,
    value: "",
    opts: [],
    nodeOptions: {
      multiple: false
    },
    onChange: (_item, form, _value, _tital, opts) => {
    },
    isIcon: true
  }];

  fieldsListOtherInfo = [{
    name: 'linkedIdTreeShow',
    displayName: '党组织联系人',
    editor: "node-select",
    treeCheckable: false,
    value: "",
    opts: [],
    nodeOptions: {
      multiple: false
    },
    onChange: (_item, form, _value, _tital, opts) => {
      this.setState({
        linkedName: _tital[0]
      })
    },
  }, {
    name: 'telphone',
    displayName: '联系电话',
    editor: 'normal',
    value: '',
    originValue: '',
    rules: [
      { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号码' }
    ]
  }, {
    name: 'wechatUrl',
    displayName: '官网地址',
    editor: 'normal',
    value: '',
    originValue: '',
    rules: [
      { max: 50, message: '最多可以输入50个字符' },
      { whitespace: true, message: '不能全为空格' }
    ]
  }, {
    name: 'email',
    displayName: '邮箱',
    editor: 'normal',
    value: '',
    originValue: '',
    rules: [
      { pattern: /^[a-zA-Z0-9_-]+(\.*[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确电子邮箱' }
    ]
  }, {
    name: 'thumbnail',
    displayName: '缩略图',
    editor: 'pictureWall',
    value: '',
    originValue: '',
    fileUpLoadOption: {
      listType: 'picture-card',
      className: 'avatar-uploader',
      showUploadList: false,
      action: urls.file.uploadGetId,
      accept: "image/*",
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('图片必须小于2MB!');
        }
        return isLt2M;
      },
      onChange: (info, form, _onchange) => {
        if (info.file.status === 'done') {
          let _value = info.fileList.map(v => v.response);
          _onchange(_value.join(","));
        }
      }
    }
  }, {
    name: 'abstractInfo',
    displayName: '简介',
    editor: 'bEditor',
    value: "",
  }];

  handleAddCommitte = (position = "", id = "") => {
    let { howManyCommitte, member, newPosition } = this.state;
    this.fieldsListLeaders.push(
      {
        name: 'committeDuty' + howManyCommitte,
        displayName: '委员职务',
        editor: 'select',
        value: position ? position : "",
        unavailable: false,
        originValue: "",
        opts: newPosition,
      }, {
        name: 'committeName' + howManyCommitte,
        displayName: '委员姓名',
        value: id ? id : "",
        editor: "node-select",
        treeCheckable: false,
        opts: member,
        nodeOptions: {
          multiple: false
        },
        onChange: (_item, form, _value, _tital, opts) => {
        },
        isIcon: true
      }
    )
    //记录委员数
    this.setState({
      howManyCommitte: howManyCommitte + 1
    })
  }
  handleDelCommitte = (e) => {
    this.fieldsListLeaders.splice(e - 1, 2);
    this.setState({})
  }
  handleCancel = () => {
    let panelId = PcService.getIdByPath("/app/organizationNew");
    PcService.removePanel(panelId);
  }
  handleSave = ($event) => {
    const formBase = this.refs.orgInfoFormBase.getForm();
    const formLeader = this.refs.orgInfoFormLeader.getForm();
    const formOther = this.refs.orgInfoFormOther.getForm();
    let submitBase = this.onBaseSubmit(formBase, $event);
    let submitLeader = this.onBaseSubmit(formLeader, $event);
    let submitOther = this.onBaseSubmit(formOther, $event);
    if (submitBase === 'stop' || submitLeader === 'stop' || submitOther === 'stop') return;

    //判断领导班子人员
    let leaderMember = { leaderMember: this.getNotEmptyArr(submitLeader) };
    let _data = Object.assign({}, submitBase, leaderMember, submitOther);
    _data.id = this.state.id;
    _data.linkedName = this.state.linkedName;
    _data.feeMemberName = this.state.feeMemberName;
    _data.superParty = this.props.location ? this.props.location.query.superPartyName : '无';
    _data.structureId = this.props.location ? this.props.location.query.id : '';

    this.setState({ loading: true });
    http.request({
      method: "post",
      url: urls.app.organization.updateEntity,
      data: _data,
    }).then(res => {
      if (res.success) {
        let panelId = PcService.getIdByPath("/app/organizationNew");
        PcService.removePanel(panelId, true, '/app/organization');
      } else {
        message.error(res.message);
      }
      this.setState({ loading: false });
    }).catch(err => {
      this.setState({ loading: false });
      message.error(err.message);
    });

  }

  /* 根据接口返回数据和用户选择，拼接领导班子保存 */
  getNotEmptyArr = (submitLeader) => {
    let _submitLeader = [];
    for (const key in submitLeader) {
      if (key === "orgMaster" && submitLeader[key]) {
        _submitLeader.push({
          communistOrganizationId: submitLeader[key],
          position: "1",
          positionName: PcService.numberToText("1", PcService.getDataDictionary("POSITION"))
        })
      }
      if (key === "orgMasterVice" && submitLeader[key]) {
        _submitLeader.push({
          communistOrganizationId: submitLeader[key],
          position: "2",
          positionName: PcService.numberToText("2", PcService.getDataDictionary("POSITION"))
        })
      }
      if (key.indexOf("committeName") !== -1) {
        for (const k in submitLeader) {
          if (k.indexOf("Duty") !== -1) {
            let replaceK = k.replace("Duty", "Name");
            if (key === replaceK && submitLeader[key]) {
              _submitLeader.push({
                communistOrganizationId: submitLeader[key],
                position: submitLeader[k],
                positionName: PcService.numberToText(submitLeader[k], PcService.getDataDictionary("POSITION"))
              })
            }
          }
        }
      }
    }
    return _submitLeader;
  }
  /* getNotEmptyArr = (submitLeader) => {
    let { member } = this.state;
    //console.log(member, submitLeader)
    let _submitLeader = [];
    let memberList = this.renderCommunistInfoList(member);
    //console.log(memberList);
    for (const key in submitLeader) { 
      member.map(f => {
        f.children.length && f.children.map(v => {
          if (key === "orgMaster" && v.id === submitLeader[key]) {
            _submitLeader.push({
              id: v.id,
              name: v.name,
              partyMember: v.id,
              photo: v.photo,
              structureId: v.structureId,
              position: "1",
              positionName: PcService.numberToText("1", PcService.getDataDictionary("POSITION"))
            })
          }
          if (key === "orgMasterVice" && v.id === submitLeader[key]) {
            _submitLeader.push({
              id: v.id,
              name: v.name,
              partyMember: v.id,
              photo: v.photo,
              structureId: v.structureId,
              position: "2",
              positionName: PcService.numberToText("2", PcService.getDataDictionary("POSITION"))
            })
          }
          if (key.indexOf("committeName") !== -1 && v.id === submitLeader[key]) {
            for (const k in submitLeader) {
              if (k.indexOf("Duty") !== -1) {
                let replaceK = k.replace("Duty", "Name");
                if (key === replaceK) {
                  _submitLeader.push({
                    id: v.id,
                    name: v.name,
                    partyMember: v.id,
                    photo: v.photo,
                    structureId: v.structureId,
                    position: submitLeader[k],
                    positionName: PcService.numberToText(submitLeader[k], PcService.getDataDictionary("POSITION"))
                  })
                }
              }
            }
          }

        })
      })
    }

    return _submitLeader;
  } */

  /* 获取form参数*/
  onBaseSubmit = (form, $event) => {
    let data = {}, errs = {}, isStop = false;
    $event.preventDefault();
    form.validateFields((err, fieldsValue) => {
      data = fieldsValue;
      errs = err;
    });
    for (let v in errs) {
      if (errs[v]) {
        isStop = true;
      }
    }
    if (isStop) {
      return 'stop';
    } else {
      return data;
    }
  };

  getOrganizationTree = () => {
    let _params = {
      flag: false,
    }
    this.setState({ loading: true });
    http.request({
      method: "get",
      url: urls.app.organization.selectStructureTreeById,
      params: _params,
    }).then(res => {
      if (res.success) {
        let treeData = res.data;
        let lists = treeData ? treeData : [];
        lists.length && lists.map(v => {
          v.id = v.structureId;
          v.name = v.structureName;
        });
        this.fieldsListInfo.map(v => {
          if (v.name === "superParty") {
            v.opts = lists;
          }
        })
      } else {
        message.error(res.message);
      }
      this.setState({ loading: false });
    }).catch(err => {
      message.error(err.message);
      this.setState({ loading: false });
    });
  }

  fetchData = (id) => {
    this.setState({ loading: true });
    http.request({
      method: "get",
      url: urls.app.organization.selectByStructureId,
      params: {
        structureId: id
      },
    }).then(res => {
      if (res.success) {
        if (res.data.rows[0]) {
          let data = res.data.rows[0];
          PcService.bindFormdData(data, this.fieldsListInfo);
          PcService.bindFormdData(data, this.fieldsListOtherInfo);
          // 部分数据手动渲染
          this.fieldsListInfo.map(v => {
            if (v.name === "superPartyName") {
              v.value = this.props.location ? this.props.location.query.superPartyName : '无';
            }
          })
          /* this.fieldsListOtherInfo.map(v => {
            if (v.name === 'thumbnail') {
              let url = data.thumbnail ? appConfig.pocAddress + 'views/' + data.thumbnail : "";
              v.fileUpLoadOption.imageUrl = url;
            }
          }) */
          let leaderMember = res.data.rows[0].leaderMember;
          //动态加载委员
          let flag = 0;
          let arr = [];
          leaderMember && leaderMember.map(v => {
            //加载成员，动态新增委员并批量赋值
            v.id = v.communistOrganizationId;
            //给成员赋值
            this.fieldsListLeaders.map(k => {
              if (k.name === "orgMaster" && v.position === "1") {
                k.value = v.id;
              }
              if (k.name === "orgMasterVice" && v.position === "2") {
                k.value = v.id;
              }
              if (v.position !== "1"
                && v.position !== "2"
                && k.name !== "orgMaster" && k.name !== "orgMasterVice") {
                if (flag < 2) {
                  if (k.name === "committeDuty0") {
                    k.value = v.position;
                    flag += 1;
                  } else if (k.name === "committeName0") {
                    k.value = v.id;
                    flag += 1;
                  }
                } else {
                  if (arr.indexOf(v.id) === -1) {
                    this.handleAddCommitte(v.position, v.id);
                    arr.push(v.id);
                  }
                }
              }
            })
          });
          this.setState({
            id: data.id,
          })
        }
      } else {
        message.error(res.message);
      }
      this.setState({
        finish: true,
        loading: false
      });
    }).catch(err => {
      message.error(err.message);
      this.setState({ loading: false });
    });
  }

  getPartyList = (newPosition) => {
    this.setState({ loading: true });
    http.request({
      method: "get",
      url: urls.app.organization.selectStructureTreeById,
      params: {
        flag: true,
        type: 0,
      },
    }).then(res => {
      if (res.success) {
        if (res.data.length) {
          let treeData = res.data || [];
          let opts = PcService.renderOrgAndUserNodes({ data: treeData, type: 0 });
          this.fieldsListLeaders.map(v => {
            if (v.name.indexOf("committeName") !== -1 || v.name === "orgMaster" || v.name === "orgMasterVice") {
              v.opts = opts;
            }
            if (v.name.indexOf("committeDuty") !== -1) {
              v.opts = newPosition;
            }
          })
          this.fieldsListOtherInfo[0].opts = opts;
          this.fieldsListInfo.map(v => {
            if (v.name === "feeMemberTreeShow") {
              v.opts = opts;
            }
          })
          this.setState({ member: opts })

        }
      } else {
        message.error(res.message);
      }
      this.setState({ loading: false });
    }).catch(err => {
      message.error(err.message);
      this.setState({ loading: false });
    });
  }

  partyClass = () => {
    let isPartyClass = this.props.location && this.props.location.query.partyType === "PARTY_CLASS";
    if (isPartyClass) {
      this.fieldsListInfo.map(v => {
        if (v.name === "partyType" || v.name === "partyAddress" || v.name === "feeMemberTreeShow" || v.name === "feeUrl") {
          v.editor = "hidden";
          v.rules = [];
        }
        if (v.name === "partyName") {
          v.displayName = "党小组名称";
        }
      })
      this.fieldsListLeaders.map(v => {
        if (v.name === "orgMasterVice") {
          v.displayName = "副组长";
        }
        if (v.name === "orgMaster") {
          v.displayName = "组长";
        }
        if (v.name === "committeDuty0" || v.name === "committeName0") {
          v.editor = "hidden";
        }
      })
    }
  }

  componentWillMount() {
    this.getOrganizationTree();
    //获取传参
    let id = this.props.location ? this.props.location.query.id : '';
    this.fetchData(id);
    //过滤掉字典里的书记副书记
    let newPosition = PcService.getDataDictionary("POSITION").filter(v => ["1", "2", "15", "16"].indexOf(v.id) === -1);
    this.setState({ newPosition })
    //获取人员
    this.getPartyList(newPosition);
    //党小组处理
    this.partyClass();
  }

  render() {
    const { fieldsListInfo, fieldsListLeaders, fieldsListOtherInfo, items } = this;
    const { finish } = this.state;

    return (
      <Spin spinning={this.state.loading} className="">
        <div className="org-box" onClick={this.clearRightMenu} >
          <div className="org-main">
            <div className="org-main-inner">
              <label className="org-main-lab">{this.props.location && this.props.location.query.partyType === "PARTY_CLASS" ? "完善党小组信息" : "完善组织信息"}</label>
              <div className="org-marin-tipinfo">
                <span className="org-main-tip" />
                {this.props.location && this.props.location.query.partyType === "PARTY_CLASS" ? "党小组基本信息" : "党组织基本信息"}
              </div>
              <div className="org-orginfo-form-base">
                {finish ? <OrgInfoForm ref={"orgInfoFormBase"} onSubmit={this.onBaseSubmit} fieldsList={fieldsListInfo} /> : null}
              </div>
              <div className="org-marin-tipinfo">
                <span className="org-main-tip" />
                {this.props.location && this.props.location.query.partyType === "PARTY_CLASS" ? "党小组成员" : "班子成员"}
              </div>
              <div className="org-orginfo-form-leader">
                <OrgInfoFormLeader ref={"orgInfoFormLeader"} onSubmit={this.onBaseSubmit} fieldsList={fieldsListLeaders} isHide={this.props.location && this.props.location.query.partyType === "PARTY_CLASS"} addCommitte={this.handleAddCommitte.bind(this, "", "")} delCommitte={(e) => this.handleDelCommitte(e)} />
              </div>
              <div className="org-marin-tipinfo" style={{ display: this.props.location && this.props.location.query.partyType === "PARTY_CLASS" ? "none" : null }}>
                <span className="org-main-tip" />
                附加信息
              </div>
              <div className="org-orginfo-form-other" style={{ clear: 'both', display: this.props.location && this.props.location.query.partyType === "PARTY_CLASS" ? "none" : null }}>
                {finish ? <OrgInfoFormOther ref={"orgInfoFormOther"} onSubmit={this.onBaseSubmit} className="org-otherinfo" fieldsList={fieldsListOtherInfo} /> : null}
              </div>
            </div>
            <div style={{ float: 'right' }}>
              <Button type="default" className="org-button" onClick={this.handleCancel} icon="redo">取消</Button>
              <Button type="primary" className="org-button" onClick={this.handleSave} icon="check">确定</Button>
            </div>
          </div>
        </div>
      </Spin>
    )
  }
}

export default OrganizationNew;
