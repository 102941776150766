import React, { Component } from 'react';
import VotingForm from './VotingForm';
import { message, Spin } from 'antd';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import http from '../../common/axios/request';
import moment from 'moment';

class VotingAdd extends Component {

    state = {
        type: 1,
        create: true,
        orgAndUsers: [],
        loading: true,
        itemData: {
            questions: []
        }
    }

    /*新增修改表单项 */
    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'title',
        displayName: '标题',
        editor: 'normal',
        value: '',
        originValue: '',
        columns: 1,
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 }
            }
        },
        rules: [
            { message: "请输入标题", required: true },
            { message: "标题最大长度为60个字符", max: 60 }
        ]
    }, {
        name: "orgName",
        editor: "hidden",
        value: "",
        originValue: ""
    }, {
        name: 'orgId',
        displayName: '开展组织',
        editor: 'node-select',
        value: '',
        originValue: "",
        opts: [],
        rules: [{
            message: "请选择开展组织",
            required: true
        }],
        onChange: (item, form, value, titles) => {
            form.setFieldsValue({ "orgName": titles[0] });
        }
    }, {
        name: 'type',
        displayName: '投票类型',
        editor: 'select',
        value: '',
        originValue: 1,
        onChange: (item, form, value, opts) => {
            this.setState({ type: value }, () => {
                if (value === 2) {
                    form.setFieldsValue({
                        "maxVotes": 1,
                        "ableRepeatVote": false
                    });
                }
                this.fieldsList.map(v => {
                    if (v.name === "maxVotes" || v.name === "ableRepeatVote") {
                        v.unavailable = value === 2;
                    }
                });

                let VotingForm = this.refs.VotingForm;
                VotingForm.setState({ questions: [] });
            });
        },
        selectOption: {
            allowClear: false
        },
        opts: [
            { id: 1, name: "投票(人)" },
            { id: 2, name: "投票(事)" }
        ],
        rules: [{
            message: "请选择开展组织",
            required: true
        }]
    }, {
        name: 'startTime',
        displayName: '开始投票时间',
        editor: 'datePicker',
        value: null,
        maxDate: "endTime",
        timeOptions: {
            format: "YYYY-MM-DD HH:mm",
            showTime: {
                format: "HH:mm",
                defaultValue: moment(pcService.dateFormat(null, "yyyy-MM-dd 00:00"))
            }
        },
        originValue: null,
        rules: [{
            message: "请选择开始投票时间",
            required: true
        }]
    }, {
        name: 'endTime',
        displayName: '结束投票时间',
        editor: 'datePicker',
        value: null,
        minDate: "startTime",
        originValue: null,
        timeOptions: {
            format: "YYYY-MM-DD HH:mm",
            showTime: {
                format: "HH:mm",
                defaultValue: moment(pcService.dateFormat(null, "yyyy-MM-dd 00:00"))
            }
        },
        rules: [{
            message: "请选择结束投票时间",
            required: true
        }]
    }, {
        name: 'maxVotes',
        displayName: '每人可投票数',
        editor: 'number',
        value: '',
        originValue: "1",
        range: [1, 99999],
        rules: [
            { message: "请输入每人最多可投票数", required: true }
        ]
    }, {
        name: 'ableRepeatVote',
        displayName: '是否可重复投票',
        editor: 'radio',
        selectOption: {
            allowClear: false
        },
        value: '',
        originValue: false,
        opts: [
            { id: true, name: "是" },
            { id: false, name: "否" }
        ],
        rules: [{
            message: "请选择开展组织",
            required: true
        }]
    }, {
        name: 'publishStatus',
        displayName: '发布状态',
        editor: 'hidden',
        value: "10000",
        originValue: "10000"
    }, {
        name: 'votingRangeIdTreeShow',
        displayName: '投票人员',
        editor: 'node-select',
        value: [],
        originValue: [],
        opts: [],
        rules: [{
            message: "请选择投票人员",
            required: true
        }],
        nodeOptions: {
            treeCheckable: true
        },
        columns: 1,
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 }
            }
        }
    }, {
        name: 'description',
        displayName: '投票说明',
        editor: 'textarea',
        value: '',
        originValue: '',
        rules: [{
            message: "投票说明最大长度为200个字符",
            max: 200
        }],
        columns: 1,
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 }
            }
        }
    }];

    /* 表单提交 */
    onSubmit = (forms, questions = []) => {
        let that = this;
        let { type, create } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                questions = questions.map(v => {
                    v.options = v.options.map(_v => ({ ..._v, id: undefined }));
                    if (type === 1) {
                        v["name"] = values["title"];
                    }
                    return { ...v, id: undefined }
                });
                let data = { ...values, questions, publishStatus: "10000" };
                let url = urls.app.voting.addEntity;
                let action = "新增";
                let panelId = pcService.getIdByPath("/app/votingAdd");
                that.setState({ loading: true });
                if (!create) {
                    url = url = urls.app.voting.updateEntity;
                    action = "修改";
                    panelId = pcService.getIdByPath("/app/votingUpdate");
                }
                pcService.formSubmit(url, false, data, null, (data) => {
                    message.success(action + "操作成功！");
                    that.setState({ loading: false });
                    pcService.removePanel(panelId, true, '/app/votingManagement');
                }, err => {
                    message.error(err.message);
                    that.setState({ loading: false });
                })
            }
        })
    };

    /* 获取党组织下面的党员信息 */
    getpartyMember = (updateId) => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                params: { flag: true, type: 0, injob: "0" },
                url: urls.app.organization.selectStructureTreeById
            }).then(res => {
                if (res.success) {
                    let opts = res.data || [];
                    let orgAndUsers1 = pcService.renderOrgAndUserNodes({ data: opts, type: 1 });
                    let orgAndUsers2 = pcService.renderOrgAndUserNodes({ data: opts });
                    this.fieldsList.map(v => {
                        if (v.name === "orgId") v.opts = orgAndUsers1;
                        if (v.name === "votingRangeIdTreeShow") v.opts = orgAndUsers2;
                    });
                    this.setState({ loading: false, orgAndUsers: orgAndUsers2 });
                    !!updateId && this.getVoting(updateId);
                } else {
                    this.setState({ loading: false });
                }
            }).catch(() => {
                this.setState({ loading: false });
            })
        })
    }

    /* 获取投票详情 */
    getVoting = (votingId) => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            params: { votingId },
            url: urls.app.voting.findById
        }).then(res => {
            if (res.success) {
                let data = res.data;
                this.fieldsList.map(v => {
                    if (v.name === "type") v.unavailable = true;
                    if (v.name === "maxVotes") v.unavailable = (data.type === 2 && data.publishStatus === "10002");
                    if (v.name === "ableRepeatVote") v.unavailable = (data.type === 2 && data.publishStatus === "10002");
                });
                pcService.bindFormdData(data, this.fieldsList);
                this.setState({ loading: false, create: false, type: data.type, itemData: data });
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    }

    componentWillMount() {
        let { updateId } = this.props;
        if (!updateId) {
            this.fieldsList.map(v => {
                if (v.name === "type") {
                    v.unavailable = false;
                    return;
                }
            });
            pcService.initFormList(this.fieldsList);
        }
        this.getpartyMember(updateId);

    }

    setParentQuestionsState = (args) => {
        let { itemData } = this.state;
        this.setState({ itemData: { ...itemData, ...args } });
    }

    render() {
        const { fieldsList, onSubmit, setParentQuestionsState } = this;
        const { location } = this.props;
        const { type, orgAndUsers, loading, itemData } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        const childProps = { type, location, fieldsList, orgAndUsers, onSubmit, formItemLayout, itemData, setParentQuestionsState }
        return (
            <Spin spinning={loading}>
                <VotingForm ref="VotingForm" {...childProps} />
            </Spin>
        )
    }
}

export default VotingAdd;
