import React, { Component } from 'react';
import Routers from './configs/Routers';
import { hashHistory } from 'react-router';
import { getLocal } from './common/utils';
import './App.css';

class App extends Component {

  /* 检查是否登录用户 */
  checkUserInfo() {
    let poc_headers = getLocal("poc_headers");
    let isLoginPath = window.location.hash === "#/login";
    if (!(poc_headers || isLoginPath)) {
      hashHistory.push("/login");
    }
  }

  componentDidMount() {
    this.checkUserInfo();
  }

  render() {

    return (
      <Routers />
    );
  }
}

export default App;