import React, { Component } from 'react';
import { Button, Divider, Icon, message, Popconfirm, Row, Spin, Modal } from "antd";
import PcTable from "../../common/components/table/tableComponent";
import PcModal from "../../common/components/modal/Modals";
import PcForm from "../../common/components/form/Forms";
import pcService from "../../other/pc.service";
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import DetailModal from "../../common/components/detailModal/DetailModal";
import './democratic-review.css';
import moment from 'moment';

const { confirm } = Modal;
const examStatus = [
    { id: "", name: "全部" },
    { id: 0, name: "暂存" },
    { id: 1, name: "已发布" },
    { id: 2, name: "进行中" },
    { id: 3, name: "已结束" }
];

class DemocraticReviewList extends Component {

    /**
     * 数据集
     */
    state = {
        hasUpdate: false,
        hasFinished: false,
        loading: false,
        detailVisible: false,
        partyMemberVisible: false,
        selectedRowKeys: [],
        partyMembers: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "民主评议",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: []
    };

    /**
     * 搜索框内容
     */
    searchFieldsList = [{
        name: "organizerId",
        editor: "node-select",
        value: "",
        originValue: "",
        displayName: "开展组织",
        opts: []
    }, {
        name: "examStatus",
        editor: "select",
        value: "",
        originValue: examStatus[0].id,
        displayName: "状态",
        opts: examStatus
    }, {
        name: "startTime",
        editor: "datePicker",
        value: null,
        originValue: null,
        timeOptions: {
            showToday: true,
            format: 'YYYY-MM-DD'
        },
        displayName: "任务时间"
    }, {
        name: "keyWord",
        editor: "normal",
        value: "",
        displayName: "任务名称",
        hideDisplayName: true,
        originValue: ""
    }];

    /* 查看该年度*/
    addPartyMember = () => {
        let form = this.refs.addModal.refs.forms.getForm();
        let year = form.getFieldValue("year");
        if (!year) {
            message.info("请先选择评议年份!");
            return;
        } else {
            year = year.year();
        }
        let { modalOptions } = this.state;
        this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
        http.request({
            method: "get",
            url: urls.app.examTask.getAlreadyAppraisalListByYear,
            params: { year }
        }).then(res => {
            if (res.success) {
                //console.log("partyMembers", res.data);
                this.setState({
                    modalOptions: { ...modalOptions, modalsLoading: false },
                    partyMemberVisible: true,
                    partyMembers: res.data
                })
            } else {
                message.error(res.message);
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: false } });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ modalOptions: { ...modalOptions, modalsLoading: false } });
        })
    }

    addOnOptions = {
        nodeOptions: {
            treeCheckable: true,
            allowClear: true
        },
        editor: "node-select",
        addOn: {
            attrs: { disabled: false },
            AddOn: (props) => <Button onClick={this.addPartyMember} {...props}>该年度已参评人员</Button>
        }
    };

    /* 获取年度可评议人员列表 */
    getPartymembers = (item, form, year) => {
        if (!year) return;
        let { modalOptions } = this.state;
        this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
        let yearly = year.year();
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: { flag: true, type: 0, injob: "0", democraticStatus: "1", yearly }
        }).then(res => {
            if (res.success) {
                let opts = res.data;
                let orgAndUsers2 = pcService.renderOrgAndUserNodes({ data: opts });
                this.fieldsList.map(v => {
                    if (v.name === "participantIdsTreeShow") {
                        v.opts = orgAndUsers2;
                        return;
                    }
                });
            } else {
                message.error(res.message);
            }
            this.setState({ modalOptions: { ...modalOptions, modalsLoading: false } });
        }).catch(err => {
            message.error(err.message);
            this.setState({ modalOptions: { ...modalOptions, modalsLoading: false } });
        })
    }
    /**
     * 新增、修改弹出框内容
     */
    fieldsList = [{
        name: 'id',
        editor: 'hidden',
        value: ''
    }, {
        name: 'year',
        value: null,
        originValue: null,
        displayName: '评议年份',
        editor: "yearPicker",
        onChange: this.getPartymembers,
        rules: [
            { message: "请选择积分年度", required: true }
        ]
    }, {
        name: 'taskName',
        editor: 'normal',
        displayName: '评议任务',
        value: '',
        rules: ["required"]
    }, {
        name: 'organizerName',
        editor: 'hidden',
        value: ''
    }, {
        name: 'organizerId',
        editor: 'node-select',
        displayName: '开展组织',
        value: '',
        opts: [],
        rules: ["required"],
        onChange: (_item, _form, _value, titles, option) => {
            _form.setFieldsValue({
                organizerName: titles[0]
            });
        }
    }, {
        name: 'examPaperId',
        editor: 'select',
        displayName: '评议模板',
        showSearch: true,
        value: '',
        opts: [],
        rules: ["required"],
        filterOption: (input, option) => option.props.children.indexOf(input) >= 0,
        onChange: (_item, _form, _value, option) => {
            let selectedOption = option.find(item => item.id === _value);
            _form.setFieldsValue({
                totalScore: selectedOption.totalScore,
                passingScore: Math.ceil(selectedOption.totalScore * 0.6)
            });
        }
    }, {
        name: 'startTime',
        editor: 'datePicker',
        displayName: '评议开始时间',
        maxDate: "endTime",
        value: null,
        originValue: null,
        timeOptions: {
            showToday: true,
            format: 'YYYY-MM-DD'
        },
        rules: ["required"]
    }, {
        name: 'endTime',
        editor: 'datePicker',
        minDate: "startTime",
        displayName: '评议结束时间',
        value: null,
        originValue: null,
        timeOptions: {
            showToday: true,
            format: 'YYYY-MM-DD'
        },
        rules: ["required"]
    }, {
        name: "participantIdsTreeShow",
        editor: "addOn",
        value: [],
        originValue: [],
        displayName: "年度可评议对象",
        rules: ["required"],
        opts: [],
        columns: 1,
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        },
        onChange: (item, forms, value) => { },
        addOnOptions: this.addOnOptions
    }, {
        name: 'taskCategory',
        editor: 'hidden',
        value: '',
        originValue: 1
    }];
    detailList = [{
        name: 'taskName',
        editor: 'div',
        displayName: '评议任务',
        value: '',
    }, {
        name: 'organizerName',
        editor: 'div',
        displayName: '开展组织',
        value: '',
        opts: [],
    }, {
        name: 'examPaperId',
        editor: 'select',
        displayName: '评议模板',
        showSearch: true,
        value: '',
        opts: [],
        filterOption: (input, option) => option.props.children.indexOf(input) >= 0,
    }, {
        name: 'startTime',
        editor: 'div',
        displayName: '评议开始时间',
        value: '',
    }, {
        name: 'endTime',
        editor: 'div',
        displayName: '评议结束时间',
        value: '',
    }, {
        name: 'participantIdsTreeShow',
        editor: 'node-select',
        unavailable: true,
        displayName: '评议对象',
        value: [],
        originValue: [],
        type: 'tree',
        columns: 1,
        opts: [],
        nodeOptions: {
            treeCheckable: true
        },
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        },
    }];

    /***********************************页面初始数据START***************************/
    /**
     * 设置下拉选择考试人员
     */
    getSelectOrgAndUsersAndExamPaper = (resolve) => {
        this.setState({ loading: true }, () => {
            let _params = {
                pageIndex: 0,
                pageSize: 9999,
                paperCategory: 1,
                enabled: true
            };
            http.all([
                http.request({
                    method: "get",
                    url: urls.app.examPaper.page,
                    params: _params,
                }),
                http.request({
                    method: "get",
                    url: urls.app.organization.selectStructureTreeById,
                    params: { flag: true, type: 0, injob: "0" }
                })
            ]).then(reses => {
                let opts = reses[1] ? reses[1].data : [];
                let orgAndUsers1 = pcService.renderOrgAndUserNodes({ data: opts, type: 1 });
                let orgAndUsers2 = pcService.renderOrgAndUserNodes({ data: opts });
                this.fieldsList.map(v => {
                    /*  if (v.name === "participantIdsTreeShow") {
                         v.opts = orgAndUsers2 
                     } */
                    if (v.name === "organizerId") {
                        v.opts = orgAndUsers1;
                    }
                });
                this.detailList.map(v => {
                    if (v.name === "participantIdsTreeShow") {
                        v.opts = orgAndUsers2
                    }
                    if (v.name === "organizerId") {
                        v.opts = orgAndUsers1;
                    }
                });
                this.searchFieldsList.map(v => {
                    if (v.name === "organizerId") {
                        v.opts = orgAndUsers1;
                    }
                });
                let papers = reses[0] ? (reses[0].data.rows ? reses[0].data.rows : []) : [];
                papers.length && papers.map(v => {
                    v.name = v.paperName;
                });
                this.fieldsList.map(v => {
                    if (v.name === "examPaperId") {
                        v.opts = papers
                    }
                });
                this.detailList[2].opts = papers;
                this.setState({
                    loading: false,
                    hasFinished: true
                }, () => {
                    !!resolve && resolve();
                });
            }).catch(err => {
                message.error(err.message);
                this.setState({
                    loading: false
                });
            });
        });
    };

    /**
     * 设置下拉选择试卷
     */
    selectExamPaper = () => {
        let _params = {
            pageIndex: 0,
            pageSize: 9999,
            paperCategory: 1,
            enabled: true
        };
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.examPaper.page,
            params: _params,
        }).then(res => {
            if (res.success) {
                let lists = res.data.rows ? res.data.rows : [];
                lists.length && lists.map(v => {
                    v.name = v.paperName;
                });
                this.fieldsList[4].opts = lists;
                this.detailList[2].opts = lists;
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    };
    /***********************************页面初始数据END*****************************/

    /***********************************页面操作START*******************************/
    /**
     * 新增、修改操作
     * @param item
     * @param create
     */
    createOne = (item, create) => {
        let { modalOptions, hasFinished } = this.state;
        let msPromise = new Promise((resolve) => {
            if (!!hasFinished) resolve();
            else this.getSelectOrgAndUsersAndExamPaper(resolve);
        });

        msPromise.then(() => {
            let _create = false;
            if (create === false) {
                //console.log(typeof item.year);
                let _item = { ...item, year: new moment(item.year + "") };
                pcService.bindFormdData(_item, this.fieldsList);
            } else {
                _create = true;
                pcService.initFormList(this.fieldsList);
            }
            //this.getSelectOrgAndUsersAndExamPaper();
            //this.selectExamPaper();
            this.setState({
                modalOptions: {
                    ...modalOptions,
                    visible: true,
                    modalsLoading: false
                },
                create: _create
            });
        })

    };

    /**
     * 新增、修改点击确定按钮操作
     * @param forms
     */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                let url = "";
                if (create) {
                    url = urls.app.examTask.addEntity;
                } else {
                    url = urls.app.examTask.updateEntity;
                }
                values.year = !!values.year ? values.year.year() : values.year;
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } })
                //values.participantIdsTreeShow = values.participantIdsTreeShow.toString();
                pcService.formSubmit(url, true, values, {}, data => {
                    message.success("操作成功");
                    this.setState({
                        loading: false,
                        modalOptions: {
                            ...modalOptions,
                            visible: false,
                            modalsLoading: false
                        }
                    });
                    this.fetchData();
                }, err => {
                    message.error(err);
                    this.setState({ loading: false, modalOptions: { ...modalOptions, visible: true, modalsLoading: false } });
                });
            }
        });
    };

    /**
     * 取消新增、修改操作
     */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    };

    onDetailCancel = () => {
        this.setState({
            detailVisible: false
        });
    };
    /* 详情 */
    detailItem = (item) => {
        pcService.relativePath(`/app/democraticReviewDetail?taskId=${item.id}`)
    };

    /**
     * 加载数据的方法
     * @param params
     */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        ///固定查询参数，此参数代表查询的是民主评议
        _params.taskCategory = 1;
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.examTask.page, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.displayTip = 'none';
                });
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    };

    /**
     * 发布考试任务
     * @param item
     */
    publish = (item) => {
        let _params = { taskId: item.id };
        this.setState({ loading: true });
        http.request({
            method: "post",
            url: urls.app.examTask.publish,
            params: _params,
        }).then(res => {
            if (res.success) {
                message.success("操作成功");
                this.setState({
                    loading: false
                });
                this.fetchData();
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    };

    /**
     * 开始或者结束考试
     * @param item
     * @param flag
     */
    startOrFinish = (item, flag) => {
        let _params = { taskId: item.id, flag: flag };
        this.setState({ loading: true });
        http.request({
            method: "post",
            url: urls.app.examTask.startOrFinish,
            params: _params,
        }).then(res => {
            if (res.success) {
                message.success("操作成功");
                this.setState({
                    loading: false
                });
                this.fetchData();
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    };

    /**
     * 查询
     */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                let startTime = values["startTime"] ? pcService.dateFormat(values["startTime"], "yyyy-MM-dd hh:mm:ss") : values["startTime"];
                let startTimeStr = values["startTime"] ? pcService.dateFormat(values["startTime"], "yyyy-MM-dd") : values["startTime"];
                let _values = {
                    ...values,
                    "startTime": startTime,
                    "startTimeStr": startTimeStr
                };
                this.fetchData(_values);
            }
        });
    };

    /**
     * 删除操作
     * @param item
     * @param form
     * @param $event
     */
    deleteItem = (item = [], form, $event) => {
        let that = this;
        confirm({
            title: '提示信息',
            content: '确认删除所选中的' + item.length + "项数据？",
            onOk() {
                let ids;
                if (form === 'button') {
                    ids = item.join(",");
                }
                that.setState({ loading: true });
                pcService.deleteItem(urls.app.examTask.logic, {}, ids,
                    (data) => {
                        message.success("删除成功!");
                        setTimeout(function () {
                            that.fetchData();
                        }, 500);
                        that.setState({ loading: false });
                    }, err => {
                        that.setState({ loading: false });
                    }
                );
            },
            onCancel() {
            }
        });
    };
    /***********************************页面操作END*******************************/
    /**
     * 显示列
     * @type {Array}
     */
    columns = [{
        title: '序号 ',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        //width: 240,
        render: (text, record, index) => {
            return <span>
                {record.examStatus === 0 ? (<span>
                    <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                    {/*  <Divider type="vertical" />
                    <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a> */}
                    <Divider type="vertical" />
                    <Popconfirm title="确定要发布吗？" onConfirm={this.publish.bind(this, record)} okText="确定"
                        cancelText="取消">
                        <a href="javascript:;"><Icon type="share-alt" theme="outlined" /> 发布</a>
                    </Popconfirm>
                </span>) : <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>}
                {record.examStatus === 1 ? (<span>
                    <Divider type="vertical" />
                    <Popconfirm title="确定要开始吗？" onConfirm={this.startOrFinish.bind(this, record, "start")} okText="确定"
                        cancelText="取消">
                        <a href="javascript:;"><Icon type="play-circle" theme="outlined" /> 开始评议</a>
                    </Popconfirm>
                </span>) : null}
                {record.examStatus === 2 ? (<span>
                    <Divider type="vertical" />
                    <Popconfirm title="确定要结束吗？" onConfirm={this.startOrFinish.bind(this, record, "finish")} okText="确定"
                        cancelText="取消">
                        <a href="javascript:;"><Icon type="poweroff" theme="outlined" /> 结束评议</a>
                    </Popconfirm>
                </span>) : null}
            </span>;
        }
    }, {
        title: '评议任务',
        dataIndex: 'taskName'
    }, {
        title: '开展组织',
        dataIndex: 'organizerName',
        //width: 100
    }, {
        title: '评议对象',
        dataIndex: 'totalParticipant',
        //width: 100
    }, {
        title: '应参评',
        dataIndex: 'shouldParticipant',
        //width: 90
    }, {
        title: '开始时间',
        dataIndex: 'startTime',
        align: 'center',
        //width: 120,
        render: value => pcService.dateFormat(value, "yyyy-MM-dd"),
        sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime)
    }, {
        title: '结束时间',
        dataIndex: 'endTime',
        align: 'center',
        //width: 120,
        render: value => pcService.dateFormat(value, "yyyy-MM-dd"),
        sorter: (a, b) => new Date(a.endTime) - new Date(b.endTime)
    }, {
        title: '状态',
        dataIndex: 'examStatus',
        render: (value) => pcService.numberToText(value, examStatus)
    }];

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    pmCancel = () => this.setState({ partyMemberVisible: false })

    /**
     * 钩子函数
     */
    componentDidMount() {
        let msPromise = new Promise((resolve) => {
            this.getSelectOrgAndUsersAndExamPaper(resolve);
        });
        msPromise.then(this.fetchData)

    };

    render() {
        const { loading, selectedRowKeys, pagination, lists, modalOptions, create, partyMembers } = this.state;
        const { createOne, searchOnSubmit, searchFieldsList, onSelectChange, columns, fetchData, fieldsList, detailList, onOk, onCancel, deleteItem } = this;
        const allowDel = selectedRowKeys.length > 0;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 960, title: (create ? "新增" : "编辑") + modalOptions.title };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        }, dformItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Button icon="delete" disabled={!allowDel} onClick={deleteItem.bind(this, selectedRowKeys, 'button')}>删除</Button>
                            </div>
                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset
                                    fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                <PcModal ref="addModal" fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading}
                    formItemLayout={formItemLayout} formItemColumns={2} />
                <Modal centered title="评议任务详情" width={"960px"} visible={this.state.detailVisible} footer={null} onCancel={this.onDetailCancel}>
                    <DetailModal fieldsList={detailList} formItemLayout={dformItemLayout} />
                </Modal>
                <Modal maskClosable={false} centered title="年度已参评人员" width={600} visible={this.state.partyMemberVisible} footer={null} onCancel={this.pmCancel} onOk={this.pmCancel}>
                    <div className="smb-list">
                        <ul>
                            <li>{(partyMembers.map((v, n) => v.name)).join("，")}</li>
                        </ul>
                    </div>
                </Modal>
            </Spin>
        );
    }
}

export default DemocraticReviewList;
