import React, { Component } from 'react';
import { Spin, message, Button, Input, Icon, Popconfirm } from 'antd';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import PcService from './../../other/pc.service';
import { getLocal } from './../../common/utils';
import appConfig from './../../configs/app.config';
import "./partyMemberEducationDetail.less";
import commuImg from './../../img/commuImg.png'

let userId = getLocal("poc_headers") ? getLocal("poc_headers").user_id : "";

export class PartyMemberEducationDetail extends Component {
    state = {
        loading: false,
        dataInfo: [],
        lists: [],
        box: "",
        totalCount: "",
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        this.fetchData()
        let that = this;
        this.setState({ loading: true, });
        http.request({
            method: 'get',
            url: urls.app.partyMemberEducation.findById,
            params: { id },
        }).then(res => {
            if (res.success === true) {
                that.setState({
                    dataInfo: res.data,
                    loading: false
                });
            } else {
                message.error(res.message);
                that.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({
                loading: false
            });
        });

    }
    /* 获取列表数据 */
    fetchData = (params) => {
        let _params = Object.assign({}, /* { commentType: 'COURSESCHEDULE' }, */ { resourceId: this.props.location.query.recordId }, params);
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.comment.page, _params, null, (data) => {
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.active = "";
                })
                this.setState({
                    loading: false,
                    lists: lists,
                    totalCount: data.totalCount
                });
            }, (err) => {
                this.setState({ loading: false });
            });
        });

    }
    send = () => {
        //console.log(getLocal("poc_headers"))
        let sendParam = {
            resourceId: this.props.location.query.recordId,
            content: this.refs.inputvalue.state.value,
            publishStatus: 1,
            operator: userId ? userId : 'string',
            publisher: userId ? userId : 'string',
            commentType: "COURSESCHEDULE"
        }
        this.setState({ loading: true, });
        http.request({
            method: 'post',
            url: urls.app.comment.insertEntity,
            data: sendParam,
        }).then(res => {
            if (res.success === true) {
                this.fetchData();
                this.refs.inputvalue.state.value = "";
                this.setState({ loading: false });
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    click = (id, $event) => {
        this.setState({
            currentId: id,
            box: '1'
        });
    }
    deleteItem = (item, $event) => {
        let url = urls.app.comment.logic;
        let that = this;
        PcService.deleteItem(url, {}, item.id,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData()
                }, 500);
                this.setState({ loading: false });
            }, err => {
                this.setState({ loading: false });
            }
        );
    };

    render() {
        const { loading, dataInfo, lists, currentId } = this.state;
        const { studyFileList } = dataInfo;
        return (
            <Spin spinning={loading} className="">
                <div className={"pme-detail"}>
                    <div className={"tital"}>
                        {dataInfo.courseName}
                    </div>
                    <div className={"sm-tital"}>
                        {dataInfo.createdTime ? PcService.dateFormat(dataInfo.createdTime, 'yyyy年MM月dd日') : ""}
                        {/* &emsp;
                        {dataInfo.editor} */}
                    </div>
                    <div className={"content"} dangerouslySetInnerHTML={{ __html: dataInfo.desp }} >
                    </div>
                    <div className={"fileslist"}>
                        附件列表：
                        {
                            studyFileList && studyFileList.map((v, n) => {
                                return (
                                    <div key={n}>
                                        <a target="_blank" className={"ant-upload-list-item-name"} title={v.fileName}
                                            href={appConfig.pocAddress + 'views/' + v.fileId} >{v.fileName}</a>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={"comment"}>
                        <span className={"span1"}> 党员评论 </span>
                        <span className={"span2"}>{this.state.totalCount}条评论</span>
                        <Input ref={"inputvalue"}></Input>
                        <Button onClick={this.send}>发表</Button>
                        <div className={"li"}>
                            {
                                lists.map((v, n) => {
                                    return (
                                        <li key={n}>
                                            <div onClick={this.click.bind(this, v.id)} className={`comment-list-border${v.id === currentId ? " active" : ""}`} >
                                                <div className="comment-inner-pic">
                                                    <div className="pic-img">
                                                        <img src={v.headImg ? urls.file.viewImg + "/" + v.headImg : commuImg}></img>
                                                        <div className="pic-name">{v.publisherName}</div>
                                                    </div>
                                                    <Popconfirm
                                                            title="确定要删除吗?" 
                                                            onConfirm={this.deleteItem.bind(this, v)}
                                                            onCancel={this.cancel}
                                                            okText="确定"
                                                            cancelText="取消" >
                                                            <div className="comment-inner-in"><Icon type="delete" /></div>
                                                        </Popconfirm>
                                                </div>
                                                <div className="comment-info" >
                                                    {v.content}</div>
                                                <div className="comment-date">{v.createdTime ? v.createdTime : ''}</div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}

export default PartyMemberEducationDetail;
