import React, { Component } from 'react';
import appConfig from './../../configs/app.config';

export class AttachList extends Component { 
    render() {
        const { itemAttach = [] } = this.props;
        return (
            <span className={"fileslist"}> 
            {
                    itemAttach && itemAttach.map((v, n) => {
                        return (
                            <span key={n}>
                                <a target="_blank" title={v.fileName}
                                    href={appConfig.pocAddress + 'views/' + v.fileId} >{v.fileName}</a>
                            </span>
                        )
                    })
                }
            </span>
        )
    }
}

export default AttachList;
