/**
 * 获取地址域名
 * @param url
 * @return {*}
 */
export const domainString = (url) => {
    let _domainString = url;
    const httpPro = 'http://', httpsPro = 'https://';
    if (url.indexOf(httpPro) !== -1) {
        _domainString = hostString(url, httpPro);
    } else if (url.indexOf(httpsPro) !== -1) {
        _domainString = hostString(url, httpsPro);
    }
    return _domainString;
};

/**
 * 获取地址域名
 * @param url
 * @param pro
 * @return {string}
 */
export const hostString = (url, pro) => {
    let _hostString = url;
    const httpUrl = url.split(pro)[1];
    if (httpUrl) {
        const httpDomain = httpUrl.split('/')[0];
        if (httpDomain) {
            _hostString = pro + httpDomain;
        }
    }
    return _hostString;
};

/**
 * 获取url的参数
 */
export const queryString = () => {
    let _queryString = {};
    const _query = window.location.search.substr(1);
    const _vars = _query.split('&');
    _vars.forEach((v, i) => {
        const _pair = v.split('=');
        if (!_queryString.hasOwnProperty(_pair[0])) {
            _queryString[_pair[0]] = decodeURIComponent(_pair[1]);
        } else if (typeof _queryString[_pair[0]] === 'string') {
            const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])];
            _queryString[_pair[0]] = _arr;
        } else {
            _queryString[_pair[0]].push(decodeURIComponent(_pair[1]));
        }
    });
    return _queryString;
};

/**
 * 嵌套JSON数组转平级
 */
export const setJsonArray = (_data = [], subMenu = "subMenu", result = []) => {
    let data = JSON.parse(JSON.stringify(_data));
    result = result.concat(data.map(v => ({ ...v, [subMenu]: [] })));
    if (!(data.some(v => v[subMenu] && v[subMenu].length > 0))) {
        return result;
    } else {
        data.forEach(item => {
            if (!!item[subMenu]) {
                result = result.concat(item[subMenu]);
            }
            item[subMenu] = [];
        });
        return setJsonArray(result, subMenu, []);
    }
}


/**
 * 平级JSON数组转嵌套
 */
export const setJsonArrayNest = (data, parentId = '0') => {
    let result = [], temp;
    data.forEach(json => {
        if (json.parentId === parentId) {
            temp = setJsonArrayNest(data, json.menuId);
            if (isNotEmpty(temp)) { json.subMenu = temp; }
            result.push(json);
        }
    });
    return result;
};

/**
 * 通用将平级JSON数组转嵌套
 * @param {*} data 
 * @param {*} parentId 
 * @param {*} subParam 
 * @param {*} dataId 
 */
export const setCommonJsonArrayNest = (data, parentId = '0', subParam = 'subMenu', dataId = 'menuId') => {
    let result = [], temp;
    if (data) {
        data.forEach(json => {
            if (json.parentId === parentId) {
                temp = setCommonJsonArrayNest(data, json[dataId], subParam, dataId);
                if (isNotEmpty(temp)) {
                    json[subParam] = temp;
                }
                result.push(json);
            }
        });
    }
    return result;
};

export const convertListToTreeJson = (data, parentId = '0', subParam = 'subMenu', dataId = 'menuId', leveParam = 'menuLevel') => {
    let result = [];
    if (data) {
        let rootParentIds = findRootParentIds(data, leveParam);
        if (rootParentIds.length > 0) {
            for (let rootParentId of rootParentIds) {
                let tempResultArray = setCommonJsonArrayNest(data, rootParentId, subParam, dataId);
                for (let tempResult of tempResultArray) {
                    result.push(tempResult);
                }

            }
        }
    }
    return result;
};

const findRootParentIds = (data, leveParam) => {
    let rootParentIds = [];
    let minLevel;
    let isFirst = true;
    for (let item of data) {
        let levelData = parseInt(item[leveParam]);
        if (isNaN(levelData)) {
            continue;
        }
        else {
            if (isFirst) {
                minLevel = levelData;
                isFirst = false;
            }
            else if (minLevel > levelData) {
                minLevel = levelData;
            }
        }

    }
    if (isNotEmpty(minLevel)) {
        let dataByMinLevel = data.filter(item => item[leveParam] && parseInt(item[leveParam]) === minLevel);
        for (let dataItem of dataByMinLevel) {
            let parentId = dataItem.parentId;
            if (parentId && !rootParentIds.includes(parentId)) {
                rootParentIds.push(parentId);
            }
        }
    }
    return rootParentIds;
};



export const convertJsonToChildren = (data, parentId = '0') => {
    let jsonArray = setJsonArray(data);
    let result = convertJsonToChildrenNest(jsonArray, parentId);
    return result;
};

const convertJsonToChildrenNest = (data, parentId = '0') => {
    let result = [], temp;
    data.forEach(json => {
        if (json.parentId === parentId) {
            temp = convertJsonToChildrenNest(data, json.menuId);
            if (isNotEmpty(temp)) {
                json.children = temp;
            }
            result.push(json);
        }
    });
    return result;
};

/**
 * 获取对应的menu信息
 * @param {*} menuId 
 * @param {*} menus 
 */
export const getSelectMenu = (menuId, menus) => {
    let menusForArray = setJsonArray(menus);
    let selectMenu = menusForArray.find(item => item.menuId === menuId);

    return selectMenu;
};

/**
 * 获取所有父级菜单对应的kay
 * @param {*} menuId 
 * @param {*} menus 
 */
export const setOpenKeys = (menuId, menus, openKeys = []) => {
    let menusForArray = setJsonArray(menus);
    menusForArray.forEach(menu => {
        if (menu.menuId === menuId) {
            openKeys.push(menu.parentId);
            setOpenKeys(menu.parentId, menus, openKeys);
        }
    });
};

/**
 * 获取本地存储
 */
export const getLocal = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

/**
 * 设置本地存储
 */
export const setLocal = (key, value, check = false) => {
    let _value = "";
    if (typeof value === "object") {
        _value = JSON.stringify(value);
    } else {
        _value = value;
    }
    if (check) {
        // 判断是否重复存在
        if (isEmpty(getLocal(key))) {
            localStorage.setItem(key, _value);
        }
    } else {
        localStorage.setItem(key, _value);
    }
};

/**
 * 删除本地存储
 * @param {*} key 
 */
export const removeLocal = (key) => {
    return localStorage.removeItem(key);
};

/**
 * 清空本地存储
 */
export const clearLocal = () => {
    return localStorage.clear();
};

/**
 * 获取会话存储
 */
export const getSession = (key) => {
    return sessionStorage.getItem(key);
};

/**
 * 设置会话存储
 */
export const setSession = (key, value, check = false) => {
    if (check) {
        // 判断是否重复存在
        if (isEmpty(getSession(key))) {
            sessionStorage.setItem(key, value);
        }
    } else {
        sessionStorage.setItem(key, value);
    }
};

/**
 * 删除会话存储
 * @param key
 */
export const removeSession = (key) => {
    return sessionStorage.removeItem(key);
};

/**
 * 清空会话存储
 */
export const clearSession = () => {
    return sessionStorage.clear();
};

/**
 * 判断为空
 */
export const isEmpty = (value) => {
    return (value === null || value === undefined || value === '' || value == {} || value === [] || value.length === 0);
};

/**
 * 判断不为空
 */
export const isNotEmpty = (value) => {
    return !isEmpty(value);
};


/**
 * 获取Pane包含的数据
 * @param {*} key 编号，一般对应Menu的Key
 * @param {*} title 标题，一般对应Menu的名称
 * @param {*} content 内容
 * @param {*} isWebDefault 是否网站首页
 * @param {*} url 菜单链接地址
 * @param {*} closable 是否可关闭
 * @param {*} isMenu 是否菜单页面
 * @param {*} refKey 来源Key值
 * @param {*} isExternal 是否为外部http|https地址
 */
export const getTabPaneData = ({ key, title, content, url = '', isWebDefault = false, closable = false, isMenu = true, refKey = '', isExternal = false }) => {
    return {
        key,
        title,
        content,
        url,
        isWebDefault,
        closable,
        isMenu,
        refKey,
        isExternal
    }
};


/**
 * 转换json数组为字符串
 * @param {*} array json数组
 * @param {*} item 如果是对象则输入需要的项名称,否则传入null
 * @param {*} separator 分隔符，默认是，号
 */
export const convertArrayToStr = (array, item = null, separator = ',') => {
    let str = '';
    if (array && array.length > 0) {
        for (let arrayItem of array) {
            if (item) {
                str += arrayItem.item + separator;
            }
            else {
                str += arrayItem + separator;
            }
        }
    }
    if (isNotEmpty(str)) {
        str = str.substr(0, str.length - 1);
    }
    return str;
};

/**
 * 获取IframeHeight的高度
 */
export const getIframeHeight = () => {
    return document.documentElement.clientHeight - 95;
    //return document.documentElement.clientHeight-100;
};


/**
 * 设置网站的Title
 * @param {*} title 
 */
export const setDocumentTitle = (title) => {
    document.title = title;
};

/**
 * 设置页面的Title
 * @param sysName
 * @param sysVersion
 */
export const setAppTitle = (sysName, sysVersion) => {
    let title;
    let whitespace = ' ';
    if (sysName.includes('V') || sysName.includes('v')) {
        title = sysName;
    }
    else if (sysVersion.includes('V') || sysVersion.includes('v')) {
        title = `${sysName}${whitespace}${sysVersion}`;
    }
    else {
        title = `${sysName}${whitespace}v${sysVersion}`;
    }
    setDocumentTitle(title);
};

//parentNode:所有父节点；node: 节点本身，返回值：带有path路径（层级关系）的node对象
export const getNodeHandle = () => {
    let parentNode = [];
    let node = null;
    const getNode = (json, nodeId) => {
        //1.第一层 root 深度遍历整个JSON
        for (let i = 0; i < json.length; i++) {
            if (node) {
                break;
            }
            let obj = json[i];
            //没有就下一个
            if (!obj || !obj.menuId) {
                continue;
            }
            //2.有节点就开始找，一直递归下去
            if (obj.menuId === nodeId) {
                //找到了与nodeId匹配的节点，结束递归
                node = obj;
                break;
            } else {
                //3.如果有子节点就开始找
                if (obj.subMenu) {
                    //4.递归前，记录当前节点，作为parent 父亲
                    parentNode.push(obj);
                    //递归往下找
                    getNode(obj.subMenu, nodeId);
                } else {
                    //跳出当前递归，返回上层递归
                    continue;
                }
            }
        }
        //5.如果木有找到父节点，置为null，因为没有父亲
        if (!node) {
            parentNode = [];
        }
        let path = '';
        parentNode.map(item => {
            path += `${item.menuName}/`
        });
        if (node) {
            path += `${node.menuName}`;
        }
        //6.返回结果obj
        return {
            ...node,
            path: path
        };
    };
    return getNode;
};

