import React, { Component } from 'react';
import { Button, Form } from 'antd';
import FormsItem from './FormsItem';
import { FormValidate } from "./FormValidate";
import './forms.less';
import './bEditor.css';


class Forms extends Component {

    /* 表单验证 */
    validateRules = (item) => {
        const returnRule = [];
        item.rules.forEach(v => {
            let rule = {};
            if (typeof v === 'string') {
                if (v === "validator") {
                    rule = { validator: item.validate };
                } else if (v === "required") {
                    if (item.editor === "datePicker" || item.editor === "select" || item.editor === "node-select" || item.editor === "tree-select") {
                        rule = { required: true, message: "请选择" + item.displayName };
                    } else if (item.editor === "attachments") {
                        rule = { required: true, message: "请上传" + item.displayName };
                    } else {
                        rule = { required: true, message: "请输入" + item.displayName };
                    }
                } else {
                    rule = FormValidate[v];
                }
            } else {
                rule = v;
            }
            returnRule.push(rule);
        });
        return returnRule;
    };

    render() {
        const { form, formClassName, fieldsList = [], formItemLayout = {}, layout = "horizontal", columns = false, onSubmit = null, submitText = "确定", submitIcon = "search", showSubmit = true, hideFooter = false, showReset = false, addOnActions = [] } = this.props;
        const { getFieldDecorator } = form;
        const FormItem = Form.Item;
        const _onSubmit = ($event) => {
            $event.preventDefault();
            onSubmit(form);
        }
        /* 重置表单 */
        const formReset = () => {
            form.resetFields();
        };
        return (
            <div className={formClassName ? formClassName : "pc-forms"}>
                <Form layout={layout} onSubmit={_onSubmit.bind(this, form)} className={columns ? `forms-columns${columns}` : ""}>
                    {
                        fieldsList.map((item, index) => {
                            let FormItemStyle = item.editor === "hidden" ? { display: "none" } : null;
                            let { hideDisplayName = false, columns } = item;
                            const rules = item.rules ? this.validateRules(item) : [];
                            return (
                                <FormItem style={FormItemStyle} className={columns ? `formItem-columus${columns}` : ""}
                                    {...(item.formItemLayout ? item.formItemLayout : formItemLayout)}
                                    label={!hideDisplayName ? item.displayName : ""} key={index}>
                                    {
                                        getFieldDecorator(item.name,
                                            {
                                                initialValue: item.value,
                                                rules: rules,
                                                validateFirst: true
                                            })(<FormsItem item={item} form={form} />)
                                    }
                                </FormItem>
                            )
                        })
                    }
                    {
                        !hideFooter ? <FormItem className="formItem-submit">
                            {showSubmit ? <Button icon={submitIcon} onClick={_onSubmit} type="primary" htmlType="submit">{submitText}</Button> : null}
                            {showReset ? <Button icon="redo" onClick={formReset} className="forms-resetBtn" htmlType="reset">重置</Button> : null}
                            {
                                (addOnActions && addOnActions.length) > 0 ? addOnActions.map((v, n) => <Button key={n}
                                    onClick={v.onClick && v.onClick.bind(this, form)} className="forms-resetBtn"
                                    type={v.type || "default"} icon={v.icon} htmlType={v.htmlType || "button"}>{v.text || "确定"}</Button>) : null
                            }
                        </FormItem> : null
                    }
                </Form>
            </div >
        )
    }
}

export default Form.create()(Forms);
