import React, { Component } from 'react';
import { Steps, Spin, Button, Divider, Icon, message } from 'antd';
import PcForm from '../../common/components/form/Forms';
import PcTable from '../../common/components/table/tableComponent';
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import './index.less';
import pcService from '../../other/pc.service';

const { Step } = Steps;

export class index extends Component {

    state = {
        loading: false,
        hasgetColumns: false,
        current: 0,
        lists: [],
        importRecordId: '',
        columnIds: [],
        resultData: {},//导入结果
    }

    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'columnIds',
        displayName: '所属栏目',
        editor: 'node-select',
        nodeOptions: {
            isTreeData: true,
            treeCheckable: true
        },
        value: [],
        originValue: [],
        opts: [],
        rules: ["required"]
    }, {
        name: "book",
        editor: "attachments",
        value: "",
        displayName: "电子书压缩包",
        originValue: "",
        rules: ["required"],
        fileUpLoadOption: {
            action: urls.app.ebook.import,
            imageUrl: "",
            loading: false,
            listType: "picture-card",
            showUploadList: false,
            onPreview: (...args) => {
                //console.log(args);
            },
            maxSize: 99999999,
            accept: "application/zip,application/x-zip,application/x-zip-compressed",
            beforeUpload: (file) => {
                const isLt2M = file.size / 1024 / 1024 < 200;
                if (!isLt2M) {
                    message.error('图片必须小于200MB!');
                }
                return isLt2M;
            },
            onChange: (fileList = [], form, _onchange) => {
                let lists = fileList.map(v => v.response.data)[0] || [];
                form.setFieldsValue({ book: JSON.stringify(lists) });
            }
        }
    } /* {
        name: 'name',
        displayName: '数据xsl文件',
        editor: 'normal',
        value: '',
        originValue: '',
        rules: ["required"]
    }, {
        name: 'photo',
        displayName: '封面图文件夹',
        editor: 'normal',
        value: '',
        originValue: ''
    }, {
        name: 'sortNum',
        displayName: '电子书文件夹',
        editor: 'normal',
        value: '',
        originValue: "",
        rules: []
    } */];

    /* 表格列项 */
    columns = [{
        title: '序号',
        dataIndex: 'key1',
        align: 'center',
        render: (text, record, index) => index + 1
    }, /* {
        title: '操作',
        key: 'action',
        align: 'center',
        render: (text, record, event) => {
            return <span>
                <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                <Divider type="vertical" />
                <a onClick={this.offTheShelf.bind(this, record)}><Icon type="info-circle" theme="outlined" /> 下架</a>
            </span>
        }
    },  */{
        title: '书名',
        dataIndex: 'name'
    }, /* {
        title: '栏目名称',
        dataIndex: 'columnName'
    }, */ {
        title: '作者',
        dataIndex: 'author'
    }, {
        title: '出版社',
        dataIndex: 'publisher'
    }, {
        title: '图书平面图',
        dataIndex: 'hasLogo',
        render: text => text === "true" ? "是" : "否"
    }, {
        title: '电子书文件',
        dataIndex: 'hasData',
        render: text => text === "true" ? "是" : "否"
    }, {
        title: '数据验证',
        dataIndex: 'haschecked',
        render: (text, record) => record.hasData === "true" ? "合格" : "不合格"
    }];

    /* 上一步 */
    goPreStep = (step) => {
        this.setState({ current: step - 1 });
    }

    submitForm = (form, updateProps = {}) => {
        form.validateFields((errs, values) => {
            if (!errs) {
                const { list, id } = JSON.parse(values.book);
                this.setState({
                    lists: list,
                    importRecordId: id,
                    columnIds: values.columnIds,
                    ...updateProps
                });
            }
        })
    }

    /* submitColumnForm */
    submitColumnForm = (updateProps = {}) => {
        const { columnIds, importRecordId } = this.state;
        this.setState({ loading: true });
        if (!(importRecordId || columnIds)) {
            message.warn("文件id和栏目id不能为空！");
            return;
        }
        this.setState({ loading: true });
        http.request({
            method: "post",
            url: urls.app.ebook.confirm,
            params: { columnIds: columnIds.join(","), importRecordId }
        }).then(res => {
            if (res.success) {
                const resultData = res.data || {};
                //console.log("resultData", resultData);
                this.setState({ loading: false, resultData, ...updateProps });
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    }

    /* 下一步 */
    nextStep = (step) => {
        switch (step) {
            case 0:
                const forms = this.refs.pcForm1;
                this.submitForm(forms, { current: step + 1 });
                break;
            case 1:
                this.submitColumnForm({ current: step + 1 });
                break;
            case 2:
                const activtityPanel = pcService.getPanelByPath("/app/bookImport");
                pcService.removePanel(activtityPanel.id, true, "/app/booksManagement");
                break;
            default:
                break;
        }
    }

    /* 获取栏目树形结构数据 */
    getColumnFindTree = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.ebook.columnFindTree
        }).then(res => {
            if (res.success) {
                const opts = res.data || [];
                this.fieldsList.map(v => {
                    if (v.name === "columnIds") {
                        v.opts = opts;
                    }
                })
                this.setState({ loading: false, hasgetColumns: true }, () => {
                    pcService.initFormList(this.fieldsList);
                });
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    }

    componentWillMount() {
        const { hasgetColumns } = this.state;
        if (hasgetColumns) {
            pcService.initFormList(this.fieldsList);
        } else {
            this.getColumnFindTree()
        }
    }

    render() {
        const { current, loading, lists = [], pagination, resultData } = this.state;
        const getStyles = (activtityKey) => ({ display: current === activtityKey ? "block" : "none" });
        const { fieldsList = [], goPreStep, fetchData, columns = [], nextStep } = this;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 }
            }
        };
        return (
            <div className="booksImport">
                <Spin spinning={loading}>
                    <div className="navs">
                        <Steps current={current}>
                            <Step title="导入设置" />
                            <Step title="数据检查" />
                            <Step title="导入结果" />
                        </Steps>
                    </div>
                    <div className="panels">
                        <div className="panel" style={getStyles(0)}>
                            <PcForm ref="pcForm1" formItemLayout={formItemLayout} hideFooter submitText="搜索" fieldsList={fieldsList} />
                            <div className="doActions">
                                <Button type="primary" onClick={() => { nextStep(0) }}>下一步</Button>
                            </div>
                        </div>
                        <div className="panel" style={getStyles(1)}>
                            <PcTable rowKey="name" columns={columns} pagination={pagination} dataSource={lists} fetchData={fetchData} bordered={false} />
                            <div className="doActions">
                                <Button onClick={() => goPreStep(1)} >上一步</Button>
                                <Divider type="vertical" />
                                <Button type="primary" onClick={() => { nextStep(1) }}>下一步</Button>
                            </div>
                        </div>
                        <div className="panel" style={getStyles(2)}>
                            <div className="result">
                                <h3>导入结果</h3>
                                <p>1、成功导入电子文档数据{resultData.success || 0}条</p>
                                <p>2、不符合导入规范数据{resultData.error || 0}条</p>
                                {/* <h4>错误数据：</h4>
                                <p>第13行，电子名称：毛泽东文选</p>
                                <p>第19行，电子名称：邓小平理</p> */}
                            </div>
                            <div className="doActions">
                                <Button type="primary" onClick={() => { nextStep(2) }}>结束</Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div >
        )
    }
}

export default index
