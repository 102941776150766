export const Select_MenuItem="Select_MenuItem";

export const Select_TabPane="Select_TabPane";

export const Update_TabPane="Update_TabPane";

export const Remove_TabPane="Remove_TabPane";

export const RemoveOthers_TabPane="RemoveOthers_TabPane";

export const RemoveAll_TabPane="RemoveAll_TabPane";

export const Refresh_TabPane="Refresh_TabPane";

export const Reset_TabPane="Reset_TabPane";
