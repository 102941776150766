import { setLocal, getLocal } from '../common/utils';
import pcService from '../other/pc.service';
import { hashHistory } from 'react-router';
import qs from "qs";
import { CLOSE_ALL_NAV_PANEL, ADD_NAV_PANEL, UPDATE_NAV_PANEL, CLOSE_OTHER_NAV_PANEL, CLOSE_NAV_PANEL, RELOAD_CURRENTPANEL, SET_OPEN_MENU_UNACTIVE } from '../action/NavpanelAction';

/* 更新panel */
const updatePanel = (activeKey) => {
  let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
  let targetPath = "/app";
  let targetParams = null;
  currentOpenMenuArr.map(v => {
    if (v.id === activeKey) {
      v.active = true;
      targetPath = v.path;
      targetParams = v.params ? v.params : targetParams;
    } else {
      v.active = false;
    }
  });
  setLocal("current_open_menu_arr", currentOpenMenuArr);
  hashHistory.push(targetParams ? (targetPath + "?" + qs.stringify(targetParams)) : targetPath);
}

/* 新增panel */
const addPanel = (path) => {
  let activePanel = pcService.getPanelByPath(path);
  if (!!activePanel) {
    let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
    if (!currentOpenMenuArr.some(v => v.path === path)) {
      currentOpenMenuArr.push(activePanel);
    }
    currentOpenMenuArr.map(v => {
      if (v.path === path) {
        v.active = true;
      } else {
        v.active = false;
      }
    });
    setLocal("current_open_menu_arr", currentOpenMenuArr);
  }
}

/* 关闭panel */
const removePanel = ({ targetKey, setParentSelectedKeys }) => {
  let lastIndex = 0;
  let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
  let targetPath = "/app";
  if (currentOpenMenuArr.length > 0) {
    if (currentOpenMenuArr.some(v => v.id === targetKey)) {
      currentOpenMenuArr.map((v, n) => {
        if (v.id === targetKey) {
          lastIndex = n - 1 > 0 ? n - 1 : 0;
        }
      });
    } else {
      currentOpenMenuArr.map((v, n) => {
        if (v.active) {
          lastIndex = n;
        }
      });
    }
    currentOpenMenuArr[lastIndex].active = true;
    currentOpenMenuArr = currentOpenMenuArr.filter(v => v.id !== targetKey);
    setLocal("current_open_menu_arr", currentOpenMenuArr);
    let activePanel = currentOpenMenuArr.filter(v => v.active);
    if (activePanel.length > 0) {
      targetPath = activePanel[0].path;
    }
  }
  setParentSelectedKeys([targetPath]);
  hashHistory.push(targetPath);
};
/* 关闭其他 */
const closeOther = (targetKey) => {
  //console.log(targetKey);
  if (targetKey !== "0") {
    let currentPanel = pcService.getPanelByPath(pcService.getPathById(targetKey));
    setLocal("current_open_menu_arr", [currentPanel]);
  } else setLocal("current_open_menu_arr", []);
}

/* 全部关闭 */
const closeall = () => {
  let currentOpenMenuArr = [];
  let targetPath = "/app";
  setLocal("current_open_menu_arr", currentOpenMenuArr);
  hashHistory.push(targetPath);
}

/* 刷新当前 */
const reloadCurrent = (targetKey) => {
  let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
  currentOpenMenuArr.map(v => {
    if (v.id === targetKey) {
      v.unMount = true;
      return;
    }
  });
  setLocal("current_open_menu_arr", currentOpenMenuArr);
}

/* 设置菜单unMount =false */
const setOpenUnActive = () => {
  let currentOpenMenuArr = getLocal("current_open_menu_arr") || [];
  if (currentOpenMenuArr.some(v => v.unMount)) {
    let _currentOpenMenuArr = currentOpenMenuArr.map(v => {
      !!v.unMount && delete v.unMount;
      return v;
    });
    setLocal("current_open_menu_arr", _currentOpenMenuArr);
    return true;
  } else {
    return false;
  }
}

const NavPanelReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NAV_PANEL:
      let addData = action.data
      addPanel(addData);
      break;
    case CLOSE_NAV_PANEL:
      let removeData = action.data
      removePanel(removeData);
      break;
    case UPDATE_NAV_PANEL:
      let updateData = action.data
      updatePanel(updateData);
      break;
    case CLOSE_OTHER_NAV_PANEL:
      let closeData = action.data
      closeOther(closeData);
      break;
    case CLOSE_ALL_NAV_PANEL:
      let closeAllData = action.data
      closeall(closeAllData);
      break;
    case RELOAD_CURRENTPANEL:
      let reloadCurentData = action.data
      reloadCurrent(reloadCurentData);
      break;
    case SET_OPEN_MENU_UNACTIVE:
      let setOpenUnActiveData = action.data
      if (setOpenUnActive(setOpenUnActiveData)) {
        return {
          ...setOpenUnActiveData
        }
      };
    default:
      break;
  }
  return state;
};

export default NavPanelReducer;