import React, { Component } from 'react';
import { Table } from 'antd';
import FormText from '../formText/FormText';

export class DetailModal extends Component {

    render() {
        const { fieldsList = [], formItemLayout = {}, lists = null, name = "", columns = [] } = this.props;
        return (
            <div className="itemBank-modal">
                <div className="ibm-part">
                    <h2>基本信息</h2>
                    <FormText fieldsList={fieldsList} formItemLayout={formItemLayout} />
                </div>
                {lists ? <div className="ibm-part">
                    <h2>选项</h2>
                    <Table pagination={false} size="small" bordered columns={columns} dataSource={lists} />
                </div> : ''
                }

            </div>
        )
    }
}

export default DetailModal;
