import { hashHistory } from 'react-router';
import { message } from 'antd';
import http from './../../axios/request';
import urls from './../../../configs/api.config';
import { getLocal, clearLocal, setLocal, removeLocal } from './../../utils';
import screenfull from 'screenfull';
import pcService from '../../../other/pc.service';

const CurrentActiveMenu = "current_histories"; //最近使用

/* 退出登录 */
const loginOut = () => {
    let headers = getLocal("poc_headers");
    let login_info = getLocal("login_info");
    clearLocal();
    if (!!login_info) {
        setLocal("login_info", login_info);
    }
    http.request({
        method: "post",
        url: urls.rui.login.loginOut
    }).then(res => {
        if (res.success) {
            message.success("注销成功！");
            setTimeout(function () {
                hashHistory.push("/login");
            }, 500);
        } else {
            message.error(res.message);
        }
    }).catch(err => {
        message.error(err.message);
    });
}

/* 个人中心 */
const userInfomation = () => {
    const currentUserId = getLocal("poc_headers").user_id;
    pcService.relativePath("/app/partyMemberDetail?id="+currentUserId);
}

/* 全屏 */
const fullScreen = () => {
    if (screenfull.enabled) {
        screenfull.request();
    }
}

/* 清理缓存 */
const clearCache = () => {
    removeLocal(CurrentActiveMenu);
    message.success('清理缓存成功!');
}

/* 设置个人信息 */
const setUserInfo = () => {
    hashHistory.push("/app/userInfoSetting");
    //pcService.relativePath("/app/userInfoSetting");
}

/* 个人收藏 */
const collection = () => {
    alert("建设中...,敬请期待!");
}

export {
    loginOut,
    collection,
    setUserInfo,
    clearCache,
    fullScreen,
    userInfomation
}