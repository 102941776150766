import React from 'react';
import { Row, Col,Card,Tree,Button,Spin,message } from 'antd';
// import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import { get,post } from './../../../common/axios';
import appConfig from './../../../configs/app.config';
import apiConfig from './../../../configs/api.config';
import constantConfig from './../../../configs/constant.config';
import pcService from './../../../other/pc.service';
import {isNotEmpty,convertListToTreeJson} from '../../../common/utils';

const { TreeNode } = Tree;

class SetTenantMenus extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading:false,
            tenant:{},
            treeData:[],
            checkedKeys:[]
       };
    }

    componentWillMount()
    {
        if(this.props.location.query.tenantId)
        {  
            this.init(this.props.location.query.tenantId);
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps.tenant&&nextProps.tenant.tenantId!==this.props.tenant.tenantId)
        {
            this.setState({
                tenant:nextProps.tenant
            },()=>{
                this.initTenantMenus();
            })
        }
    }
    initTenantInfo = (tenantId) =>{
        get({
            url:apiConfig.rui.basic.tenant.findById,
            params:{
                tenantId:tenantId
            }
        }).then((res)=>{
            if(res)
            {
                this.setState({
                    loading:false,
                    tenant:{
                        ...res
                    }
                }, this.initTreeData);
            }
        }).catch((error)=>{
            this.setState({loading:false});
        })
    }
    init=(tenantId)=> {
       this.initTenantInfo(tenantId);
    }

    initTreeData=()=>{
        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.menu.getAllMenus,
            params:{
                pageIndex:0,
                pageSize:appConfig.maxPageSize
            }
        }).then((res)=>{
             let menus=[];
             if(res&&res.list&&res.list.length>0)
             {
                 menus=[
                    ...res.list
                ];
             }
             let treeData=convertListToTreeJson(menus,'0','subMenu','menuId','menuLevel');
             this.setState({
                 loading:false,
                 treeData
             },()=>{
                 this.initTenantMenus();
             });
        }).catch((error)=>{
            this.setState({ loading:false});
         });
    }

    initTenantMenus=()=>
    {
        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.tenant.findById,
            params:{
                tenantId:this.state.tenant.tenantId
            }
        }).then((res)=>{
            let checkedKeys=[];
            if(res&&res.menu){
                for(let resItem of res.menu)
                {
                    if(resItem)
                    {
                        checkedKeys.push(resItem.menuId);
                    }
                }
            }
            this.setState({
                loading:false,
                checkedKeys
            });
        }).catch((error)=>{
            this.setState({loading:false});
        })
    }

    renderTreeNodes = data => data.map((item) => {
        if (item.subMenu) {
          return (
            <TreeNode title={item.menuName} key={item.menuId} value={item.menuId} dataRef={item}>
              {this.renderTreeNodes(item.subMenu)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.menuName} key={item.menuId} value={item.menuId} dataRef={item} />;
    });


    handleTreeCheck= (checkedKeys, info) => {
         this.setState({
            checkedKeys:checkedKeys
         });
    }

    handelCancel=()=>{
        this.goBack();
    }

    handelSave=()=>{
        
        let menuIds='';
        for(let checkKey of this.state.checkedKeys)
        {
            menuIds+=checkKey+','
        }

        if(isNotEmpty(menuIds))
        {
            menuIds=menuIds.substr(0,menuIds.length-1);
        }        
        this.setState({loading:true});
        post({
            url:apiConfig.rui.basic.tenant.resetMenus,
            data:{
                tenantId:this.state.tenant.tenantId,
                menuIds
            }
        }).then((res)=>{
            this.setState({
                loading:false
            },()=>{
                message.success(constantConfig.SAVE_SUCCESS);
                this.goBack();
            });
        }).catch((error)=>{
            message.error(constantConfig.SAVE_FAIL);
            this.setState({loading:false});
        })
    }


    goBack=()=>{
        let panelId = pcService.getIdByPath("/app/setTenantMenus");
        pcService.removePanel(panelId,true,'/app/tenantList');
        //this.props.removeTabPane(this.props.paneKey);
    }

    render() {
        let { loading, treeData,checkedKeys,tenant}=this.state;
      return (
          <Spin spinning={loading}>
                <Card bordered={false}>
                    <div className="operationTitle" style={{justifyContent:'center'}}>
                            <h1>
                            {`设置【${tenant.tenantName}】的功能模块`}
                            </h1>
                    </div>
                    <Row gutter={24}>
                        <Col span={6} offset={4}>
                        {/* <Scrollbars style={{ width: 800, height: 400}}> */}
                                <Tree 
                                        checkable
                                        checkedKeys={checkedKeys}
                                        onCheck={this.handleTreeCheck} 
                                >
                                    {this.renderTreeNodes(treeData)}
                                </Tree>
                        {/* </Scrollbars> */}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                            <Col span={24} className="operationArea">
                                <Button onClick={this.handelCancel}>取消</Button>
                                <Button type="primary" loading={loading} onClick={this.handelSave}>保存</Button>
                            </Col>
                    </Row>
                </Card>
            </Spin>
         )
      }
}


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(SetTenantMenus);