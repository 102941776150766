import {Select_MenuItem,Select_TabPane,Update_TabPane,Remove_TabPane,
    RemoveOthers_TabPane,RemoveAll_TabPane,Refresh_TabPane,Reset_TabPane
} from '../actionTypes/menuTab.actionType';

import appConfig, {getSystemSetUserCache} from '../configs/app.config'

import {isNotEmpty, setOpenKeys} from '../common/utils';

import getCurrentMenus from '../configs/menu.service';

import {defaultTabPane} from '../configs/menuTab.service';

import {refreshIframe} from '../configs/menuTab.service';

import {getTabPaneComponent} from '../sharedCommponents';

const initState={
    selectedMenuKey:'',
    openMenuKeys:[],
    selectedTabPaneKey: appConfig.webDefaultTabPaneKey,
    isWebDefault:true,
    tabPanes:[
        {
            ...defaultTabPane
        }
    ]
};

const getStateBySelectTabPane=(selectedTabPaneKey,tabPanes)=>{
    let pane=tabPanes.find(item=>item.key===selectedTabPaneKey);
    if(pane.isWebDefault)
    {
        return {
            selectedTabPaneKey,
            isWebDefault:true,
            selectedMenuKey:appConfig.webDefaultTabPaneKey,
            openMenuKeys:[]
        }
    }
    else if(pane.isMenu)
    {
        let openKeys=[];
        setOpenKeys(selectedTabPaneKey,getCurrentMenus(),openKeys);
        return {
            selectedMenuKey:selectedTabPaneKey,
            openMenuKeys:openKeys,
            selectedTabPaneKey:selectedTabPaneKey,
            isWebDefault:false
        }
    }
    else {
        return {
            selectedTabPaneKey,
            isWebDefault:false
        }
    }
};

const refreshTabPane=(activeKey,tabPanes)=>{
        let activePane=tabPanes.find(item=>item.key===activeKey);
        if(activePane)
        {
                if(activePane.isExternal)
                {
                    if (activePane.isWebDefault) { //避免刷新首页是上次页面
                        const userCacheHome = getSystemSetUserCache().sysHome;
                        const sysHome = isNotEmpty(userCacheHome)?userCacheHome:appConfig.sysHome;
                        refreshIframe(activeKey,sysHome);
                    } else {
                        refreshIframe(activeKey,activePane.url);
                    }
                }
                else if(activePane.isMenu)
                {
                    tabPanes=tabPanes.map((item,index)=>{
                        if(item.key===activeKey)
                        {  
                            let reloadState;
                            if(!item.reloadState)
                            {
                                item.reloadState=0;
                            }
                            reloadState=item.reloadState+1;
                            let selectMenu={
                                menuId:item.key,
                                menuUrl:item.url,
                                menuName:item.title
                            };
                            let newcontent=getTabPaneComponent(selectMenu,false,reloadState);
                            return {
                                ...item,
                                reloadState,
                                content:newcontent
                            }
                        }
                        else
                        {
                            return item;
                        }
                });
            }
        }
       return tabPanes;
};

const menuTab=(state=initState,action)=>{
    let tabPanes;
    let activeKey;
    switch(action.type)
    {
        case Select_MenuItem:
             return {...state,selectedMenuKey:action.selectedMenuKey};
        case Select_TabPane:
             return {
                 ...state,
                 ...getStateBySelectTabPane(action.selectedTabPaneKey,state.tabPanes)
             };
        case Update_TabPane:
             let updateTabPane=action.updateTabPane;
             let existTabPane=state.tabPanes.find(item=>item.key===updateTabPane.key);
             if(existTabPane)
             {
                return {
                    ...state,
                    tabPanes:state.tabPanes.map((item,index)=>{
                        if(item.key===updateTabPane.key)
                        {
                            return {
                                ...item,
                                ...updateTabPane
                            }
                        }
                        else
                        {
                            return item;
                        }
                    }),
                    ...getStateBySelectTabPane(updateTabPane.key,state.tabPanes)
                }
             }
             else
             {
                tabPanes=[
                    ...state.tabPanes,
                    {
                        ...updateTabPane
                    }
                ];
                return {
                    ...state,
                    tabPanes,
                    ...getStateBySelectTabPane(updateTabPane.key,tabPanes)
                }
             }
        case Remove_TabPane:
            let targetKey=action.removeTabPaneKey;
            let targetTabPane=state.tabPanes.find(item=>item.key===targetKey);
            if(!targetTabPane)
            {
                return state;
            }
            
            let needFindNext=true;
            if(targetTabPane.refKey)
            {
                activeKey=targetTabPane.refKey;
                let activeKeyPane=state.tabPanes.find(item=>item.key===activeKey);
                if(activeKeyPane)
                {
                    needFindNext=false;
                    tabPanes = state.tabPanes.filter(pane => pane.key !== targetKey);
                    if(action.isRefreshParent)
                    {
                        tabPanes=refreshTabPane(activeKey,tabPanes);
                    }
                }
            }
            if(needFindNext)
            {
                activeKey=state.selectedTabPaneKey;
                let lastIndex;
                state.tabPanes.forEach((pane, i) => {
                    if (pane.key === targetKey) { lastIndex = i - 1; }
                });
                tabPanes = state.tabPanes.filter(pane => pane.key !== targetKey);
                if (lastIndex >= 0 && activeKey === targetKey) { activeKey = tabPanes[lastIndex].key; }
            }
            return {
                ...state,
                tabPanes,
                ...getStateBySelectTabPane(activeKey,tabPanes)
            };
        case RemoveOthers_TabPane:
             tabPanes = state.tabPanes.filter(pane => pane.key === state.selectedTabPaneKey||pane.isWebDefault);
             return {
                 ...state,
                 tabPanes
             };
        case RemoveAll_TabPane:
             tabPanes = state.tabPanes.filter(pane =>pane.isWebDefault);
             activeKey=defaultTabPane.key;
             return {
                 ...state,
                 tabPanes,
                 ...getStateBySelectTabPane(activeKey,tabPanes)
             };
        case Refresh_TabPane:
             tabPanes=refreshTabPane(state.selectedTabPaneKey,state.tabPanes);
             return {
                ...state,
                tabPanes
             };
        case Reset_TabPane:
             return {
                 ...initState
             };
        default:
    }

    return state;
};

export default menuTab;
