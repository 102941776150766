import React, { Component } from 'react';
import { Button, message, Icon, Divider, Modal, InputNumber } from 'antd';
import { hashHistory } from 'react-router';
import TimeLine from '../../common/components/timeLine/TimeLine';
import Forms from '../../common/components/form/Forms';
import urls from './../../configs/api.config';
import http from './../../common/axios/request';
import { convertListToTreeJson } from "../../common/utils";
import PcForm from '../../common/components/form/Forms';
import pcService from '../../other/pc.service';
import PcTable from './../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import { getLocal } from "../../common/utils";
import moment from 'moment';

export class EnterExaminationAchievement extends Component {
    state = {
        lists: [],
        selectedRowKeys: [],
        performPointData: [],
        limitYearly: "",
        loading: false,
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        modalOptions: {
            title: "积分信息编辑",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
    }

    /**
     * 显示列
     * @type {Array}
     */
    columns = [{
        title: '序号 ',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    }, {
        title: '党员姓名',
        dataIndex: 'communistName'
    }, {
        title: '人员类型',
        dataIndex: 'communistType',
    }, {
        title: '联系电话',
        dataIndex: 'communistMobile',
    }, {
        title: '所属党支部',
        dataIndex: 'orgName',
    }, {
        title: '业绩考核积分',
        dataIndex: 'performPoint',
        render: (text, record) =>
            <InputNumber value={text} onChange={(e) => this.handleChange({ performPoint: e }, record)}
                title="需依据党员积分配置规则，检查录入值是否超出限制" min={0} max={this.state.limitYearly ? parseInt(this.state.limitYearly) : 100} />
    }];

    /* 数据筛选字段 */
    searchFieldsList = [{
        name: "communistType",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "人员类型",
        opts: [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("POSITION"))
    }, {
        name: "communistName",
        editor: "normal",
        value: "",
        displayName: "党员姓名",
        originValue: ""
    }];

    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "id",
            editor: "hidden",
            displayName: "id",
            value: "",
        }, {
            name: "orgId",
            editor: "hidden",
            displayName: "党员id",
            value: "",
        }, {
            name: "communistName",
            editor: "normal",
            value: "",
            displayName: "姓名",
            unavailable: true,
            rules: [
                {
                    required: true,
                    message: "请输入姓名"
                },
            ],
        }, {
            name: "orgName",
            displayName: "所属党支部",
            editor: "normal",
            value: "",
            originValue: "",
            unavailable: true,
        }, {
            name: "communistType",
            editor: "select",
            value: "",
            displayName: "人员类型",
            unavailable: true,
            opts: pcService.getDataDictionary("POSITION"),
        }, {
            name: "performPoint",
            editor: "normal",
            displayName: "业绩考核积分",
            value: "",
            rules: [
                {
                    required: true,
                    message: "请输入业绩考核积分"
                },
            ],
        }, {
            name: "commentPoint",
            editor: "normal",
            displayName: "民主评议积分",
            value: "",
            rules: [
                {
                    required: true,
                    message: "请输入民主评议积分"
                },
            ],
        }
    ];

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.fetchData(values);
            }
        });
    }

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params, { yearly: this.props.location ? parseInt(this.props.location.query.year) : "" });
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.pointPartyYearly.page, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows;
                /* if (lists.length === 0) {
                    this.nextStamp("next");
                } */
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination,
                });
            });
        });
    }

    handleChange = (value, record) => {
        let data = [];
        const { performPointData } = this.state;
        //判断修改的参数是否在performpointdata里面
        for (var i in value) {
            record[i] = value[i];
            if (performPointData.findIndex(v => record.id === v.id) !== -1) {
                performPointData.map(v => {
                    if (v.id === record.id) {
                        data.push({
                            id: record.id,
                            performPoint: value[i],
                            pointAnnualBootId: record.pointAnnualBootId
                        })
                    } else {
                        data.push({
                            id: v.id,
                            performPoint: v.performPoint,
                            pointAnnualBootId: v.pointAnnualBootId
                        });
                    }
                });
                this.setState({ performPointData: data })
            } else {
                data.push({
                    id: record.id,
                    performPoint: value[i],
                    pointAnnualBootId: record.pointAnnualBootId
                })
                this.setState({ performPointData: data.concat(performPointData) })
            }

        }
    }

    handleSave = () => {
        const { performPointData } = this.state;
        let url = urls.app.pointPartyYearly.batchEditPerformPoint;
        let year = this.props.location ? parseInt(this.props.location.query.year) : "";
        this.setState({ loading: true });
        pcService.formSubmit(url, true, performPointData, null, () => {
            message.success("保存成功!");
            this.setState({ loading: false });
        }, (err) => {
            message.error(err);
            this.setState({ loading: false });
        });
    }
    findMaxFromBootId = () => {
        let _params = {
            configType: "point_perform_point_root",
            pointAnnualBootId: this.props.location ? this.props.location.query.pointAnnualBootId : ""
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.pointConfigType.findConfigByTypeAndPointAnnualBootId,
            params: _params,
        }).then(res => {
            if (res.success) {
                this.setState({ limitYearly: res.data.limitYearly })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        this.findMaxFromBootId();
    }

    componentDidMount() {
        this.fetchData();
        pcService.initFormList(this.searchFieldsList);
    }

    render() {
        const { lists, pagination } = this.state;
        const { searchFieldsList, searchOnSubmit, columns, fetchData, handleSave } = this;

        return (
            <div className={"pir-integral-record"}>
                <div className="pir-search">
                    <div className="sp-btns">
                    </div>
                    <div className="sp-forms">
                        <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset fieldsList={searchFieldsList} />
                    </div>
                </div>
                <div className="pir-list">
                    <PcTable
                        columns={columns}
                        pagination={pagination}
                        dataSource={lists}
                        fetchData={fetchData}
                        bordered={false}
                    />
                </div>
                <div className="pir-panels">
                    <div className={"add-btn"}>
                        <Button type="primary" onClick={handleSave} icon="check">确定</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default EnterExaminationAchievement;
