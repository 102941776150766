import React, { Component } from 'react';
import { Button, Divider, Icon, message, Popover, Spin, Table, Popconfirm } from 'antd';
import Forms from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import PcService from './../../other/pc.service';

export class ExamQuestionModal extends Component {

    state = {
        create: true,
        selectedRowKeys: [],
        modalOptions: {
            title: "选项",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            lists: this.props.lists
        }
    };
    /* 新增、修改 表单列项 */
    itemFieldsList = [
        {
            name: "id",
            editor: "hidden",
            value: ""
        },
        {
            name: "examOption",
            editor: "select",
            value: "",
            originValue: PcService.getDataDictionary("EXAM_OPTION")[0].id,
            displayName: "选项",
            opts: PcService.getDataDictionary("EXAM_OPTION"),
            rules: [{ required: true, message: "请选择选项" }]
        },
        {
            name: "content",
            editor: "textarea",
            value: "",
            originValue: "",
            displayName: "选项内容",
            rules: ["required"]
        },
        {
            name: "isCorrectAnswer",
            editor: "radio",
            value: "",
            originValue: false,
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            displayName: "是否正确答案",
            rules: ["required"]
        }
    ];
    detailItem = () => {

    };
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /* 新增修改民主评议 */
    createOne = (item, create) => {
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            PcService.bindFormdData(item, this.itemFieldsList);
        } else {
            _create = true;
            let { lists } = this.props;
            this.itemFieldsList.map(v => {
                if (v.name === "examOption") {
                    let _opts = PcService.getDataDictionary("EXAM_OPTION").filter(v => !lists.some(_v => _v.examOption === v.name));
                    v.opts = _opts;
                    v.originValue = _opts[0] ? _opts[0].id : ""
                }
            })
            PcService.initFormList(this.itemFieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    };
    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        {/* <a onClick={this.detailItem.bind(this, record)}><Icon type="info-circle" theme="outlined" /> 详情</a>
                        <Divider type="vertical"/>*/}
                        <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                    </span>
                )
            }
        },
        {
            title: '选项',
            dataIndex: 'examOption',
            key: 'clode',
            render: (text) => PcService.numberToText(text, PcService.getDataDictionary("EXAM_OPTION"))
        },
        {
            title: '选项内容',
            dataIndex: 'content',
            render: value => value.length > 35 ? (
                <Popover content={value} placement="topRight"
                    overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                    {value.slice(0, 35) + '...'}
                </Popover>) : value
        }
    ];
    /* modal确定回调函数 */
    onOk = (forms) => {
        const { setParentState, lists = [] } = this.props;
        let parentForm = this.refs.forms.getForm();
        let type = parentForm.getFieldValue("type");
        let { modalOptions, create } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let newList = [...lists];
                setTimeout(() => {
                    //验证单选是否只有一个正确答案
                    if (!!create && type === 0 && values.isCorrectAnswer && lists.some(v => !!v.isCorrectAnswer)) {
                        message.info("单选题只允许一个正确答案，请勿重复添加！");
                        return;
                    }
                    if (create) {
                        //是否和其他选项重复
                        let flag = lists.some(v => v.examOption === values.examOption);
                        if (flag) {
                            message.info("已经存在该选项，请勿重复添加！");
                            return;
                        }
                        values.id = newList.length + 1;
                        newList.push(values);
                    } else {
                        newList = newList.map(v => v.id === values.id ? { ...values } : { ...v });
                    }
                    setParentState({ options: newList });
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            visible: false
                        }
                    })
                }, 500);
            }
        });
    };

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    };

    /* 删除操作*/
    deleteItem = () => {
        const { setParentState, lists } = this.props;
        let { selectedRowKeys } = this.state;
        let _lists = lists.filter(v => !selectedRowKeys.some(id => id === v.id));
        this.setState({
            selectedRowKeys: []
        });
        setParentState({ options: _lists });
    };

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    render() {
        const { selectedRowKeys, modalOptions, create, visible } = this.state;
        const { fieldsList = [], modalsLoading = false, formItemLayout = {}, formItemColumns, lists = [] } = this.props;
        const { columns, onSelectChange, createOne, onOk, onCancel, itemFieldsList, deleteItem, handleVisibleChange, cancel } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayoutItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <div className="itemBank-modal">
                <Spin spinning={modalsLoading}>
                    <div className="ibm-part">
                        <h2>基本信息</h2>
                        <Forms ref="forms" onSubmit={this.props.onOk} columns={formItemColumns} fieldsList={fieldsList}
                            hideFooter
                            formItemLayout={formItemLayout} />
                    </div>
                    <div className="ibm-part">
                        <h2>选项</h2>
                        <div className="ant-form ant-form-horizontal">
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-4">

                                </div>
                                <div className="ant-col ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-20">
                                    <div className="sp-bars">
                                        <div className="sp-btns">
                                            <Button onClick={createOne} type="primary">新 增</Button>
                                            <Divider type="vertical" />
                                            <Popconfirm
                                                title="确定要删除吗?"
                                                visible={visible}
                                                onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                                onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                                onCancel={cancel}
                                                okText="确定"
                                                cancelText="取消" >
                                                <Button disabled={!allowDel} type="default">删 除</Button>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                    <Table rowKey="id" size="small" bordered rowSelection={rowSelection} columns={columns}
                                        dataSource={lists} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <PcModal fieldsList={itemFieldsList} modal={modal} modalsLoading={modal.modalsLoading}
                        formItemLayout={formItemLayoutItem} />
                </Spin>
            </div>
        )
    }
}

export default ExamQuestionModal;
