import React, { Component } from 'react';
import { Row, Button, Divider, Spin, Icon, message, Popconfirm, Popover } from 'antd';
import PcForm from '../../common/components/form/Forms';
import pcService from '../../other/pc.service';
import PcTable from '../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import urls from '../../configs/api.config';
import http from '../../common/axios/request';
import './index.less';

export const columnTypes = [
    { id: 0, name: "专栏" },
    { id: 1, name: "一级栏目" },
    { id: 2, name: "二级栏目" }
]

class bookstoreSection extends Component {

    state = {
        firstColumns: [],//一级栏目
        loading: false,
        create: false,
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        lists: [],
        modalOptions: {
            title: "栏目",
            visible: false,
            destroyOnClose: true,
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 700
        },
    }

    /* 表格列项 */
    columns = [{
        title: '序号',
        dataIndex: 'key1',
        align: 'center',
        width: 120,
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 260,
        render: (text, record, event) => {
            return <span>
                <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                {record.ebookCount === 0 ? (<span>
                    <Divider type="vertical" />
                    <Popconfirm title="您确定要删除吗?" onConfirm={() => this.deleteItem(record)} okText="确定" cancelText="取消">
                        <a><Icon type="delete" theme="outlined" /> 删除</a>
                    </Popconfirm>
                </span>) : (<span>
                    <Divider type="vertical" />
                    <Popconfirm title={`您确定要${record.enabled ? "停用" : "启用"}吗?`} onConfirm={() => this.toggleItem(record)} okText="确定" cancelText="取消">
                        <a><Icon type={record.enabled ? "lock" : "unlock"} theme="outlined" /> {record.enabled ? "停用" : "启用"}</a>
                    </Popconfirm>

                </span>)
                }
            </span>
        }
    }, {
        title: '栏目名称',
        dataIndex: 'name'
    }, {
        title: '级别',
        dataIndex: 'type',
        render: text => pcService.numberToText(text, columnTypes)
    }, {
        title: '栏目图片',
        dataIndex: 'logo',
        render: text => !!text ? <img style={{ border: "solid 1px #ddd" }} width="40" height="30" src={`${urls.file.viewImg}/${text}`} /> : "无"
    }, {
        title: '上级栏目',
        dataIndex: 'parentName'
    }, /* {
        title: '显示区域',
        dataIndex: 'area'
    }, */ {
        title: '排序号',
        dataIndex: 'sort',
        sorter: (a, b) => a.sort - b.sort
    }, {
        title: '电子书数量',
        dataIndex: 'ebookCount'
    }, {
        title: '状态',
        dataIndex: 'enabled',
        render: (text) => text ? "启用" : "停用"
    }];

    /* 新增修改表单字段 */
    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'type',
        displayName: '栏目类型',
        editor: 'select',
        value: '',
        originValue: columnTypes[0].id,
        opts: columnTypes,
        rules: ["required"],
        onChange: (item, form, value) => {
            const { firstColumns } = this.state;
            if (value === 2) {
                form.setFieldsValue({
                    parentId: firstColumns[0] ? firstColumns[0].id : ""
                });
            } else {
                form.setFieldsValue({
                    parentId: "0"
                });
            }
            this.fieldsList.forEach(v => {
                if (v.name === "parentId") {
                    v.unavailable = value !== 2;
                    v.opts = v.opts.map(_v => ({ ..._v, disabled: _v.id === "0" }))
                }
            })
        }
    }, {
        name: 'parentId',
        displayName: '上级栏目',
        editor: 'select',
        value: '',
        originValue: "0",
        unavailable: true,
        opts: [{
            id: "0", name: "全部"
        }],
        rules: ["required"]
    }, {
        name: 'name',
        displayName: '栏目名称',
        editor: 'normal',
        value: '',
        originValue: '',
        rules: [
            { required: true, message: "请输入栏目名称" },
            { max: 50, message: "最长为50个字符" },
            { whitespace: true, message: "不能输入空格" }
        ]
    }, /* {
        name: 'preTitle',
        displayName: '栏目副标题',
        editor: '',
        value: '',
        originValue: ''
    }, */ {
        name: 'sort',
        displayName: '排序号',
        editor: 'number',
        value: '',
        originValue: 0,
        rules: []
    }, /* {
        name: 'posiiton',
        displayName: '显示位置',
        editor: 'select',
        value: '',
        originValue: "",
        opts: [],
        rules: []
    }, */ {
        name: 'enabled',
        displayName: '状态',
        editor: 'radio',
        value: '',
        originValue: true,
        opts: [
            { id: true, name: "启用" },
            { id: false, name: "停用" }
        ],
        rules: []
    },
    {
        name: 'logo',
        editor: 'pictureWall',
        value: "",
        displayName: '栏目图标',
        fileUpLoadOption: {
            action: urls.file.uploadGetId,
            imageUrl: "",
            loading: false,
            listType: "picture-card",
            showUploadList: false,
            onPreview: (...args) => {
                //console.log(args);
            },
            accept: "image/*",
            beforeUpload: (file) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('图片必须小于2MB!');
                }
                return isLt2M;
            },
            onChange: (info, form, _onchange) => {
                let _value = info.fileList.map(v => v.response);
                _onchange(_value.join(","));
            }
        },
    }, {
        name: 'description',
        displayName: '栏目简介',
        editor: 'textarea',
        value: '',
        originValue: "",
        rules: []
    }];

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "keyword",
        editor: "",
        value: "",
        originValue: "",
        opts: [{
            id: "0", name: "全部"
        }],
        displayName: "关键字"
    }, /* {
        name: "parentId",
        editor: "select",
        value: "",
        originValue: "0",
        opts: [{
            id: "0", name: "全部"
        }],
        displayName: "一级栏目"
    } */];


    /* 删除电子书 */
    deleteItem = item => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "delete",
                url: urls.app.ebook.columnDelete + item.id
            }).then(res => {
                if (res.success) {
                    message.success("删除成功！")
                    this.setState({ loading: false }, this.componentDidMount);
                } else {
                    this.setState({ loading: false });
                }
            }).catch(error => {
                message.error(error.message);
                this.setState({ loading: false });
            })
        });
    }

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pageSize, current } = this.state.pagination;
        //sortField: "sortNum", sortType: "ASC"
        let _params = Object.assign({}, { pageSize, current, sortField: "type", sortType: "ASC" }, params);
        this.setState({ loading: true }, () => {
            http.all([
                http.request({
                    method: "get",
                    url: urls.app.ebook.columnPage,
                    params: { type: 1 }
                }),
                http.request({
                    method: "get",
                    url: urls.app.ebook.columnFindByTree,
                    params: _params
                })
            ]).then(reses => {
                const { rows = [] } = reses[0].data;
                const opts = [{ id: "0", name: "全部" }].concat(rows);
                const lists = reses[1].data ? reses[1].data : [];
                this.searchFieldsList.map(v => {
                    if (v.name === "parentId") v.opts = opts;
                })
                this.fieldsList.map(v => {
                    if (v.name === "parentId") v.opts = [{ id: "0", name: "无" }].concat(rows);
                })
                this.setState({ loading: false, lists, firstColumns: rows })
            }).catch(errors => {
                this.setState({ loading: false });
            })
        });
    }

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.fetchData({ ...values, parentId: values.parentId === "0" ? "" : values.parentId });
            }
        });
    }

    /* 起停用 */
    toggleItem = (item) => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "post",
                url: urls.app.ebook.changeEnabled,
                params: { enabled: !item.enabled, id: item.id }
            }).then(res => {
                if (res.success) {
                    message.success((item.enabled ? "停用" : "启用") + "操作成功！")
                    this.setState({ loading: false }, this.componentDidMount);
                } else {
                    this.setState({ loading: false });
                }
            }).catch(error => {
                message.error(error.message);
                this.setState({ loading: false });
            })
        });
    }

    /* 新增信息公告 */
    createOne = (_item, create) => {
        const { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            pcService.bindFormdData({ ..._item, parentId: _item.parentId === "" ? "0" : _item.parentId }, this.fieldsList);
            this.fieldsList.map(v => {
                if (v.name === "parentId") v.unavailable = _item.type !== 2;
            })
        } else {
            _create = true;
            pcService.initFormList(this.fieldsList);
        }
        this.setState({ create: _create, modalOptions: { ...modalOptions, visible: true, modalsLoading: false } })
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        const url = create ? urls.app.ebook.columnAdd : urls.app.ebook.columnUpdate;
        forms.validateFields((errors, values) => {
            if (!errors) {
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
                http.request({
                    method: "post",
                    data: { ...values, parentId: values.parentId === "0" ? "" : values.parentId },
                    url,
                }).then(res => {
                    if (res.success) {
                        message.success((create ? "新增" : "修改") + "操作成功！");
                        this.setState({ modalOptions: { ...modalOptions, visible: false, modalsLoading: false } }, this.componentDidMount)
                    } else {
                        message.error(res.message);
                        this.setState({ modalOptions: { ...modalOptions, visible: true, modalsLoading: false } });
                    }
                }).catch(err => {
                    message.error(err.message);
                    this.setState({ modalOptions: { ...modalOptions, visible: true, modalsLoading: false } });
                })
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: { ...modalOptions, visible: false, modalsLoading: false }
        });
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
    }

    componentDidMount() {
        let forms = this.refs.pcForm;
        forms && this.searchOnSubmit(forms);
    }

    render() {
        const { pagination, loading, modalOptions, create, lists } = this.state;
        const { onOk, onCancel, columns = [], fieldsList = [], createOne, searchOnSubmit, fetchData, searchFieldsList } = this;
        const modal = { ...modalOptions, title: (create ? "新增" : "编辑") + modalOptions.title, onOk, onCancel };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        return (
            <Spin spinning={loading}>
                <Row className="bookstore-section">
                    <div className="op-navs">
                        <div className="opn-left">
                            <Button onClick={createOne} icon="file-add" type="primary">新增栏目</Button>
                        </div>
                        <div className="opn-right">
                            <PcForm ref="pcForm" layout="inline" submitText="搜索" onSubmit={searchOnSubmit} fieldsList={searchFieldsList} />
                        </div>
                    </div>
                </Row>
                <div className="op-content">
                    <PcTable
                        //rowSelection={rowSelection}
                        columns={columns}
                        pagination={pagination}
                        dataSource={lists}
                        fetchData={fetchData}
                        bordered={false}
                    />
                </div>
                <PcModal formClassName="infoModal" fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
            </Spin>
        )
    }
}

export default bookstoreSection;
