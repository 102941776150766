import React, { Component } from 'react';
import LoginForm from './LoginForm';
import http from './../../common/axios/request';
import urls from './../../configs/api.config';
import { getLocal, setLocal, removeLocal, clearLocal } from './../../common/utils';
import pcService from '../../other/pc.service';
import md5 from 'md5';
import { Base64 } from 'js-base64';
import { message, Modal } from 'antd';
import { hashHistory } from 'react-router';
import './login.css';
import login from './../../img/login-logo.png';
import { getSystemSetUserCache } from './../../configs/app.config';

export class Login extends Component {

  state = {
    RecordNumber: "",
    loading: false,
    initValue: {
      username: "",
      password: "",
      remember: true
    },
    modalOptions: {
      title: "联系我们",
      visible: false,
      destroyOnClose: true,
      okText: "确定",
      cancelText: "取消",
      maskClosable: false,
      centered: true,
      width: 600,
    }
  };

  /* 获取初始化用户信息 */
  getInitInfo = () => {
    let login_info = getLocal("login_info");
    if (!!login_info) {
      this.setState({
        initValue: {
          ...login_info,
          password: Base64.decode(login_info.password)
        }
      });
    }
  }

  getRecordNumber = () => {
    const publicAppConfig = getLocal("publicAppConfig");
    !!publicAppConfig && this.setState({ RecordNumber: publicAppConfig.RecordNumber })
  }

  /* 组件挂载完成钩子函数 */
  componentDidMount() {
    this.getInitInfo();
    this.getRecordNumber();
  }

  /* 保存登录信息 */
  saveLoginInfo = (res) => {
    let { ecmp_token = "", login_user = {} } = res.data;
    let { userId = '', structure = [], position = [], name, userName, email, phone, password, confirm, no, remark, headImg, role = [] } = login_user;
    let STRUCTURE_ID_LIST = structure ? structure.map(v => v.structureId).join(",") : "";
    let structureList = structure ? structure.map(v => ({ name: v.structureName, id: v.structureId })) : [];
    let POSITION_ID_LIST = position ? position.map(v => v.positionId).join(",") : "";
    let poc_headers = { ecmp_token, user_id: userId, POSITION_ID_LIST, STRUCTURE_ID_LIST };
    setLocal("structureList", structureList);
    setLocal("user_info", { name, userName, email, phone, password, confirm, no, remark, userId, headImg });
    setLocal("roleLists", role);
    this.getUserMenusAndDataDictionary(poc_headers, role.some(v => v.roleName === "党委管理员"));
  }

  /* 菜单数组转换 */
  menuToData = (homeMenus = []) => homeMenus.map(v => ({
    title: v.menuName,
    menus: v.subMenu ? this.menuToData(v.subMenu) : [],
    icon: v.menuIcon,
    href: v.menuUrl,
    id: v.menuId
  }));

  /* 获取用户菜单信息、数据字典和转换userId */
  getUserMenusAndDataDictionary = (poc_headers, isPAdmin = false) => {
    http.all([
      http.request({
        method: "get",
        url: urls.rui.login.getMenus
      }),
      http.request({
        method: "get",
        url: urls.app.dataDictionary.getAllDataDictionary
      }),
      http.request({
        method: "get",
        url: urls.app.partyMember.partyMemberList,
        params: { userId: poc_headers.user_id }
      })
    ]).then(reses => {
      if (reses.every(v => v.success)) {
        let home_menus = reses[0].data.home_menus;
        let dataDictionary = reses[1].data;
        let newUserDatas = reses[2].data.rows;
        if (newUserDatas.length > 0) {
          let newUserData = newUserDatas[newUserDatas.length - 1];
          let newUserId = newUserData.id;
          let root_structure_id = newUserData.rootStructureId;
          let branch_structure_id = newUserData.branchStructureId;
          let is_root = newUserData.isRoot;
          setLocal("poc_headers", { ...poc_headers, user_id: newUserId, root_structure_id, branch_structure_id, is_root: is_root || isPAdmin });
        } else {
          setLocal("poc_headers", poc_headers);
        }
        for (let v in dataDictionary) {
          dataDictionary[v] = (dataDictionary[v].sort((a, b) => a.sort - b.sort)).map(v => ({ id: v.Id, name: v.Name }));
        }
        let userMenus = this.menuToData(home_menus);
        userMenus = userMenus.map(menuItem => ({
          ...menuItem,
          menus: menuItem.menus.map(v => ({
            path: pcService.httpUrlToPathName(v.href),
            title: v.title,
            icon: v.icon
          }))
        }));
        setLocal("userMenus", userMenus);
        setLocal("dataDictionary", dataDictionary);
        this.setState({ loading: false });
        setTimeout(function () {
          hashHistory.push("/home");
        }, 500);
      } else {
        let errorMsg = ((reses.filter(v => !v.success)).map(v => v.message)).join(",");
        message.error(errorMsg);
        this.setState({ loading: false });
      }
    }, erres => {
      this.setState({ loading: false });
    });
  }

  //登录提交
  getLoginFormInfo = (fieldsValue) => {
    let cl = clearLocal();
    this.setState({
      loading: true,
      initValue: fieldsValue
    }, () => {
      let value = `qq848250365hej${fieldsValue.username},SP,${md5(md5(fieldsValue.password))}QQ848250365HEJ,SP,CODE:848250365@qq.com`;
      http.request({
        url: urls.rui.login.loginOn,
        method: "post",
        data: pcService.dataToFormData({
          KEYDATA: value, tenantId: ""
        })
      }).then(res => {
        if (res.success) {
          message.success("登录成功！");
          this.saveLoginInfo(res);
          //console.log("------登录信息--------", res);
          if (fieldsValue.remember) {
            setLocal("login_info", {
              ...fieldsValue,
              password: Base64.encode(fieldsValue.password)
            });
          } else {
            removeLocal("login_info");
          }
        } else {
          message.error(res.message);
          this.setState({ loading: false });
        }
      }).catch(err => {
        this.setState({ loading: false });
      });
    });
  }

  /* 联系我们 */
  connectUs = () => {
    let { modalOptions } = this.state;
    this.setState({ modalOptions: { ...modalOptions, visible: true } })
  }

  /* 关闭Modal */
  onCancel = () => {
    let { modalOptions } = this.state;
    this.setState({ modalOptions: { ...modalOptions, visible: false } })
  }

  render() {
    const { loading, initValue, modalOptions, RecordNumber } = this.state;
    const { getLoginFormInfo, onCancel, connectUs } = this;
    const sysName = getSystemSetUserCache().sysName;
    const modal = { ...modalOptions, onCancel };
    return (
      <div className="login-background">
        <div className="login-bgOpcity">
          <div className="login-box">
            <div className="login-logo">
              <img src={login} alt="" />
            </div>
            <div className="login-title">{sysName}登录</div>
            <LoginForm loginFormInfo={getLoginFormInfo} connectUs={connectUs} loading={loading} initValue={initValue} />
          </div>
          <div className="login-footer">
            {/* Copyright©2019虹信成都研发中心 <br /> */}
            <p>
              Copyright©2019 {RecordNumber} <br />
              <a target="_blank" href="http://www.beian.miit.gov.cn">信息产业部备案管理系统</a>
            </p>
          </div>
        </div>
        <Modal {...modal} footer={null} mask={false} >
          <div style={{ height: 160 }}>
            <p>联系人：赵伟</p>
            <p>QQ：563627125</p>
            <p>微信：15184478941</p>
            <p>联系电话：15184478941</p>
            <p>邮箱：wei8.zhao@changhong.com</p>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Login;
