import React, { Component } from 'react';
import { Tabs, message, Input, Spin, Button, Icon, Divider, Popconfirm } from 'antd';
import { hashHistory } from 'react-router';
import urls from './../../configs/api.config';
import http from './../../common/axios/request';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import PcService from './../../other/pc.service';
import PcTable from './../../common/components/table/tableComponent';
import { convertListToTreeJson } from './../../common/utils'
import moment from 'moment';
import './partyMemberDevelopment.css';

class PartyMemberDevelopment extends Component {

    state = {
        loading: false,
        memberId: '',
        memberNumber: "",
        progressedNumber: '',
        surplusNumber: '',
        inputFlag: false,
        columns: [],
        lists: [],
        selectedRowKeys: [],
        create: true,
        visible: false,
        modalOptions: {
            title: "申请人",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        transforOptions: {
            title: "积极分子",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        buttonAdd: 'none',
        currentType: '4',
        partyName: '',
        structureId: "",
    }

    /* 导航菜单 */
    tabsMenus = [
        {
            tab: "申请人",
            active: true
        }, {
            tab: "积极分子",
            active: false
        }, {
            tab: "发展对象",
            active: false
            /* }, {
                tab: "预备党员",
                active: false
            }, {
                tab: "正式党员",
                active: false */
        }
    ];

    columnsList = [
        [
            {
                title: '序号 ',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => index + 1
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (text, record, index) => {
                    return (
                        <span>
                            <a onClick={this.editOne.bind(this, record)} ><Icon type="edit" /> 编辑</a>
                            <Divider type="vertical" />
                            <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                            <Divider type="vertical" />
                            <a onClick={this.deleteItem.bind(this, record)} ><Icon type="delete" /> 删除</a>
                            <Divider type="vertical" />
                            <a onClick={this.transforInto.bind(this, record)} ><Icon type="swap" /> 转为积极分子</a>
                        </span>
                    )
                }
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, record, index) => {
                    return PcService.numberToText(text, PcService.getDataDictionary("SEX"));
                }
            },
            {
                title: '所属党支部',
                dataIndex: 'partyName',
                key: 'partyName'
            },
            {
                title: '申请入党日期',
                dataIndex: 'applicationTime',
                key: 'applicationTime'
            },
            {
                title: '联系电话',
                dataIndex: 'tel',
                key: 'tel'
            }
        ],
        [
            {
                title: '序号 ',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => index + 1
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (text, record, index) => {
                    return (
                        <span>
                            <a onClick={this.editOne.bind(this, record)} ><Icon type="edit" /> 编辑</a>
                            <Divider type="vertical" />
                            <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                            <Divider type="vertical" />
                            <a onClick={this.deleteItem.bind(this, record)} ><Icon type="delete" /> 删除</a>
                            <Divider type="vertical" />
                            <a onClick={this.transforInto.bind(this, record)} ><Icon type="swap" /> 转为发展对象</a>
                        </span>
                    )
                }
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, record, index) => {
                    return PcService.numberToText(text, PcService.getDataDictionary("SEX"));
                }
            },
            {
                title: '所属党支部',
                dataIndex: 'partyName',
                key: 'partyName'
            },
            {
                title: '转为积极分子日期',
                dataIndex: 'activistTime',
                key: 'activistTime'
            },
            {
                title: '联系电话',
                dataIndex: 'tel',
                key: 'tel'
            }
        ],
        [
            {
                title: '序号 ',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => index + 1
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (text, record, index) => {
                    return (
                        <span>
                            <a onClick={this.editOne.bind(this, record)} ><Icon type="edit" /> 编辑</a>
                            <Divider type="vertical" />
                            <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                            <Divider type="vertical" />
                            <a onClick={this.deleteItem.bind(this, record)} ><Icon type="delete" /> 删除</a>
                            <Divider type="vertical" />
                            <a onClick={this.transforInto.bind(this, record)} ><Icon type="swap" /> 转为预备党员</a>
                        </span>
                    )
                }
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, record, index) => {
                    return PcService.numberToText(text, PcService.getDataDictionary("SEX"));
                }
            },
            {
                title: '所属党支部',
                dataIndex: 'partyName',
                key: 'partyName'
            },
            {
                title: '转为发展对象日期',
                dataIndex: 'progressTime',
                key: 'progressTime'
            },
            {
                title: '联系电话',
                dataIndex: 'tel',
                key: 'tel'
            }
        ],
        [
            {
                title: '序号 ',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => index + 1
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (text, record, index) => {
                    return (
                        <span>
                            <a onClick={this.editOne.bind(this, record)} ><Icon type="edit" /> 编辑</a>
                            <Divider type="vertical" />
                            <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                            <Divider type="vertical" />
                            <a onClick={this.deleteItem.bind(this, record)} ><Icon type="delete" /> 删除</a>
                        </span>
                    )
                }
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, record, index) => {
                    return PcService.numberToText(text, PcService.getDataDictionary("SEX"));
                }
            },
            {
                title: '所属党支部',
                dataIndex: 'partyName',
                key: 'partyName'
            },
            {
                title: '转为预备党员日期',
                dataIndex: 'probationaryTime',
                key: 'probationaryTime'
            },
            {
                title: '联系电话',
                dataIndex: 'tel',
                key: 'tel'
            }
        ],
        [
            {
                title: '序号 ',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => index + 1
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (text, record, index) => {
                    return (
                        <span>
                            <a onClick={this.editOne.bind(this, record)} ><Icon type="edit" /> 编辑</a>
                            <Divider type="vertical" />
                            <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                            <Divider type="vertical" />
                            <a onClick={this.deleteItem.bind(this, record)} ><Icon type="delete" /> 删除</a>
                        </span>
                    )
                }
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, record, index) => {
                    return PcService.numberToText(text, PcService.getDataDictionary("SEX"));
                }
            },
            {
                title: '所属党支部',
                dataIndex: 'partyName',
                key: 'partyName'
            },
            {
                title: '转为正式党员日期',
                dataIndex: 'conversionTime',
                key: 'conversionTime'
            },
            {
                title: '联系电话',
                dataIndex: 'tel',
                key: 'tel'
            }
        ]
    ]
    seachFieldsList = [{
        name: "name",
        editor: "normal",
        value: "",
        displayName: "姓名",
        originValue: ""
    }, {
        name: "sex",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "性别",
        opts: [{ id: "", name: "全部" }].concat(PcService.getDataDictionary("SEX"))
    }, {
        name: "nation",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "民族",
        opts: [{ id: "", name: "全部" }].concat(PcService.getDataDictionary("NATION"))
    }];

    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "name",
            editor: "normal",
            value: "",
            displayName: "姓名",
            rules: [
                {
                    required: true,
                    message: "请输入姓名"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "sex",
            editor: "radio",
            displayName: "性别",
            unavailable: true,
            columns: 4,
            opts: PcService.getDataDictionary("SEX"),
            rules: [
                {
                    message: '请选择性别',
                    required: true
                }
            ]
        }, {
            name: 'birthday',
            displayName: '出生日期',
            editor: 'datePicker',
            unavailable: true,
            value: null,
            originValue: null,
            timeOptions: {
                showToday: true,
                format: 'YYYY-MM-DD'
            },
            rules: [{ required: true, message: '请选择出生日期' }]
        }, {
            name: "residence",
            editor: "normal",
            value: "",
            displayName: "居住地",
            rules: [
                {
                    required: true,
                    message: "请输入居住地"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "nation",
            editor: "select",
            value: "",
            displayName: "民族",
            originValue: "",
            opts: PcService.getDataDictionary("NATION"),
        }, {
            name: "nativePlace",
            editor: "normal",
            value: "",
            displayName: "籍贯",
            rules: [
                {
                    required: true,
                    message: "请输入籍贯"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "maritalStatus",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "婚姻状况",
            opts: [
                { id: "1", name: "已婚" },
                { id: "2", name: "未婚" },
            ],
        }, {
            name: "tel",
            editor: "normal",
            value: "",
            displayName: "手机",
            rules: [
                {
                    required: true,
                    message: "请输入手机"
                },
                { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号码' }
            ],
        }, {
            name: "idCard",
            editor: "normal",
            value: "",
            displayName: "身份证",
            rules: [
                {
                    required: true,
                    message: "请输入身份证"
                },
                { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码' }
            ],
            onChange: (item, form, value, $event) => {
                let idCard = value.target.value;
                if (idCard.length <= 17)
                    return;
                this.fieldsList.map(v => {
                    if (v.name === "sex") {
                        v.value = this.getSex(idCard);
                    }
                    if (v.name === "birthday") {
                        v.value = new moment(idCard.substr(6, 8));
                    }
                })
            },
        }, {
            name: "degree",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "学历",
            opts: [
                { id: "1", name: "小学" },
                { id: "2", name: "初中" },
                { id: "3", name: "高中（中专）" },
                { id: "4", name: "专科（大专）" },
                { id: "5", name: "本科" },
                { id: "6", name: "硕士" },
                { id: "7", name: "博士" },
            ],
            rules: [
                {
                    required: true,
                    message: "请选择学历"
                }
            ]
        }, {
            name: "university",
            editor: "normal",
            value: "",
            displayName: "毕业院校",
        }, {
            name: "specialty",
            editor: "normal",
            value: "",
            displayName: "所学专业",
            rules: [
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "specialSkill",
            editor: "normal",
            value: "",
            displayName: "有何专长",
            rules: [
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "email",
            editor: "normal",
            value: "",
            displayName: "电子邮箱",
            rules: [
                { pattern: /^[a-zA-Z0-9_-]+(\.*[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确电子邮箱' }
            ]
        }, {
            name: "qq",
            editor: "normal",
            value: "",
            displayName: "QQ",
            rules: [
                { pattern: /^\d+$/, message: '请输入非负整数' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: 'applicationTime',
            displayName: '申请入党时间',
            editor: 'datePicker',
            value: null,
            originValue: null,
            timeOptions: {
                showToday: true,
                format: 'YYYY-MM-DD'
            },
            rules: [{ required: true, message: '请选择申请入党时间' }]
        }, {
            name: "structureId",
            editor: "node-select",
            value: "",
            originValue: "",
            opts: [],
            displayName: "所属党组织",
            rules: [
                { required: true, message: "请选择所属党组织" }
            ],
            onChange: (_item, form, _value, $event) => {
                let optsList = this.setJsonArray(_item.opts, "children");
                let valueList = optsList.filter(v => v.structureId === _value);
                let partyName = valueList[0].structureName;
                if (valueList.length && valueList[0].partyType === "PARTY_CLASS") {
                    let valueParentList = optsList.filter(v => v.structureId === valueList[0].parentId);
                    partyName = valueParentList[0].structureName;
                }
                this.setState({
                    partyName: partyName
                })
            },
        }, {
            name: "type",
            editor: "select",
            displayName: "人员类别",
            value: "",
            originValue: PcService.getDataDictionary("TYPE")[0].id,
            columns: 4,
            opts: PcService.getDataDictionary("TYPE"),
            unavailable: true,
            rules: [{ required: true, message: '请选择人员类别' }],
            // 工作信息
        }, {
            name: "unitName",
            editor: "normal",
            value: "",
            displayName: "工作单位",
            rules: [
                {
                    required: true,
                    message: "请输入工作单位"
                },
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "duty",
            editor: "normal",
            value: "",
            displayName: "工作岗位",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                { whitespace: true, message: "不能输入空格" }
            ]
        }, /* {
            name: "title",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "技术职称",
            opts: [
                { id: "1", name: "初级职称" },
                { id: "2", name: "中级职称" },
                { id: "3", name: "高级职称" },
            ],
            rules: [
                {
                    required: true,
                    message: "请选择技术职称"
                }
            ]
        }, */ {
            name: "headship",
            editor: "normal",
            value: "",
            displayName: "行政职务",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
            //工作信息end
            /* onChange: (_item, form, _value, option) => {
                if (PcService.getDataDictionary("TYPE")[0].id !== _value) {
                    this.fieldsList.map(v => {
                        if (v.name === "isOut" || v.name === "isFlow" || v.name === "isHard" ||
                            v.name === "isPartyCadre" || v.name === "isMissing" || v.name === "isRetire") {
                            v.editor = "hidden";
                        }
                    })
                } else {
                    this.fieldsList.map(v => {
                        if (v.name === "isOut" || v.name === "isFlow" || v.name === "isHard" ||
                            v.name === "isPartyCadre" || v.name === "isMissing" || v.name === "isRetire") {
                            v.editor = "radio";
                        }
                        if (v.name === "type") {
                            v.unavailable = true;
                        }
                    })
                }
                this.setState();
            }, */
            /* }, {
                name: "memberShipStatus",
                editor: "select",
                value: PcService.getDataDictionary("MEMBER_SHIP_STATUS")[1].id,
                displayName: "党籍状态",
                originValue: PcService.getDataDictionary("MEMBER_SHIP_STATUS")[1].id,
                columns: 4,
                opts: PcService.getDataDictionary("MEMBER_SHIP_STATUS"),
                rules: [
                    {
                        required: true,
                        message: "请选择党籍状态"
                    }
                ] */
            /* }, {
                name: "isRetire",
                editor: "hidden",
                displayName: "是否退休",
                originValue: false,
                value: false,
                columns: 4,
                opts: [
                    { id: true, name: '是' },
                    { id: false, name: '否' }
                ],
                rules: [
                    {
                        message: '请选择',
                        required: true
                    }
                ]
            }, {
                name: "isOut",
                editor: "hidden",
                displayName: "是否长期在外党员",
                originValue: false,
                value: false,
                columns: 4,
                opts: [
                    { id: true, name: '是' },
                    { id: false, name: '否' }
                ],
                rules: [
                    {
                        message: '请选择',
                        required: true
                    }
                ]
            }, {
                name: "isFlow",
                editor: "hidden",
                displayName: "是否流动党员",
                originValue: false,
                value: false,
                columns: 4,
                opts: [
                    { id: true, name: '是' },
                    { id: false, name: '否' }
                ],
                rules: [
                    {
                        message: '请选择',
                        required: true
                    }
                ]
            }, {
                name: "isHard",
                editor: "hidden",
                displayName: "是否困难党员",
                originValue: false,
                value: false,
                columns: 4,
                opts: [
                    { id: true, name: '是' },
                    { id: false, name: '否' }
                ],
                rules: [
                    {
                        message: '请选择',
                        required: true
                    }
                ]
            }, {
                name: "isPartyCadre",
                editor: "hidden",
                displayName: "是否专职党务干部",
                originValue: false,
                value: false,
                columns: 4,
                opts: [
                    { id: true, name: '是' },
                    { id: false, name: '否' }
                ],
                rules: [
                    {
                        message: '请选择',
                        required: true
                    }
                ]
            }, {
                name: "isMissing",
                editor: "hidden",
                displayName: "是否失联党员",
                originValue: false,
                value: false,
                columns: 4,
                opts: [
                    { id: true, name: '是' },
                    { id: false, name: '否' }
                ],
                rules: [
                    {
                        message: '请选择',
                        required: true
                    }
                ] */
        }, {
            name: "info",
            editor: "textarea",
            value: "",
            displayName: "个人简介",
            columns: 1,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            rules: [
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "remark",
            editor: "textarea",
            value: "",
            displayName: "备注",
            rules: [],
            columns: 1,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            }
        }, {
            name: 'photo',
            displayName: '个人照片',
            editor: 'pictureWall',
            value: '',
            originValue: '',
            fileUpLoadOption: {
                listType: 'picture-card',
                className: 'avatar-uploader',
                showUploadList: false,
                action: urls.file.uploadGetId,
                accept: "image/*",
                beforeUpload: (file) => {
                    const isLt2M = file.size / 1024 / 1024 < 2;
                    if (!isLt2M) {
                        message.error('图片必须小于2MB!');
                    }
                    return isLt2M;
                },
                onChange: (info, form, _onchange) => {
                    if (info.file.status === 'done') {
                        let _value = info.fileList.map(v => v.response);
                        _onchange(_value.join(","));
                    }
                }
            }
        }, {
            name: "enclosureList",
            editor: "attachments",
            value: [],
            originValue: [],
            displayName: "附件",
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            }
        }, {
            name: 'id',
            displayName: 'id',
            editor: 'hidden',
            value: ""
        }
    ];
    fieldsListWork = [
        {
            name: "unitName",
            editor: "normal",
            value: "",
            displayName: "工作单位",
            rules: [
                {
                    required: true,
                    message: "请输入工作单位"
                },
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: "duty",
            editor: "normal",
            value: "",
            displayName: "工作岗位",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                { whitespace: true, message: "不能输入空格" }
            ]
        }, /* {
            name: "title",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "技术职称",
            opts: [
                { id: "1", name: "初级职称" },
                { id: "2", name: "中级职称" },
                { id: "3", name: "高级职称" },
            ],
            rules: [
                {
                    required: true,
                    message: "请选择技术职称"
                }
            ]
        }, */ {
            name: "headship",
            editor: "normal",
            value: "",
            displayName: "行政职务",
            rules: [
                { max: 50, message: '最多可以输入50个字符' },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }
    ];

    fieldsListTransfor = [
        {
            name: "activistRecommender",
            editor: "normal",
            value: "",
            displayName: "推荐人",
            rules: [
                {
                    required: true,
                    message: "请输入推荐人"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ]
        }, {
            name: 'activistTime',
            displayName: '日期',
            editor: 'datePicker',
            value: null,
            originValue: null,
            timeOptions: {
                showToday: true,
                format: 'YYYY-MM-DD'
            },
            rules: [{ required: true, message: '请选择时间' }]
        }, {
            name: "activistRemark",
            editor: "normal",
            value: "",
            displayName: "备注",
            rules: [],
        }, {
            name: "activistEnclosureList",
            editor: "attachments",
            value: [],
            originValue: [],
            displayName: "资料附件",
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            }
        }, {
            name: 'id',
            displayName: 'id',
            editor: 'hidden',
            value: ""
        }, {
            name: 'type',
            displayName: 'type',
            editor: 'hidden',
            value: ""
        }
    ];

    getSex = (data) => {
        if (data.length < 17) return;
        let sex;
        if (parseInt(data.substr(16, 1)) % 2 == 1) {
            PcService.getDataDictionary("SEX").map(v => {
                if (v.name === "男") {
                    sex = v.id.toString();
                }
            })
        } else {
            PcService.getDataDictionary("SEX").map(v => {
                if (v.name === "女") {
                    sex = v.id.toString();
                }
            })
        }
        return sex;
    }

    /* 获取列表数据 */
    fetchData = (params, _type) => {
        const { currentType } = this.state;
        let type = _type ? _type : currentType;
        let paramsType = Object.assign({}, { type: type, commmunistInfoSourceId: "0" }, params);
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, paramsType);
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.partyMember.partyMemberListOrgan, _params, null, (data) => {
                let _pagination = PcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            });
        });

    }
    edit = ($event) => {
        const { inputFlag, memberNumber, progressedNumber } = this.state;
        this.setState({
            inputFlag: !inputFlag
        });
        if (inputFlag) {
            this.setState({
                surplusNumber: memberNumber - progressedNumber
            })
            this.updateMember();
        }
    }
    updateMember = () => {
        const { memberId, memberNumber, progressedNumber, surplusNumber } = this.state;
        let _data = {
            id: memberId,
            memberNumber: memberNumber,
            progressedNumber: progressedNumber,
            surplusNumber: surplusNumber
        }
        this.setState({ loading: true });
        http.request({
            method: "post",
            url: urls.app.partyMemberDevelopment.updateEntity,
            data: _data,
        }).then(res => {
            if (!res.success) {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ loading: false });
            message.error(err.message);
        });
    }
    onChange = (event) => {
        this.setState({ memberNumber: event.target.value })
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /* 详情 */
    detailItem = (item, $event) => {
        let { id } = item;
        PcService.relativePath(`/app/partyMemberDetail?id=${id}&type=develop`);

    }
    /* 删除 */
    deleteItem = (item = [], form, $event) => {
        let ids;
        if (form === 'button') {
            ids = item.join(",");
        } else {
            ids = item.id;
        }
        let that = this;
        this.setState({ loading: true });
        PcService.deleteItem(urls.app.partyMember.partyMemberLogicDeleteOrgan, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                this.setState({ loading: false });
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    }
    /* 转为积极分子 */
    transforInto = (item = [], $event) => {
        let ids = item.id;
        let { transforOptions, currentType } = this.state;

        switch (currentType) {
            case "4":
                this.fieldsListTransfor.map(v => {
                    if (v.name === "type" && v.value) {
                        v.value = v.value - 1
                    }
                })
                break;
            case "3":
                this.fieldsListTransfor.map(v => {
                    if (v.name.indexOf("ecommender") !== -1) {
                        v.name = "progressRecommender"
                    }
                    if (v.editor === "datePicker") {
                        v.name = "progressTime"
                    }
                    if (v.displayName === "备注") {
                        v.name = "progressRemark"
                    }
                    if (v.displayName === "资料附件") {
                        v.name = "progressEnclosureList"
                    }
                    if (v.name === "type" && v.value) {
                        v.value = v.value - 1
                    }
                })
                break;
            case "2":
                this.fieldsListTransfor.map(v => {
                    if (v.name.indexOf("ecommender") !== -1) {
                        v.name = "probationaryRecommender"
                    }
                    if (v.editor === "datePicker") {
                        v.name = "probationaryTime"
                    }
                    if (v.displayName === "备注") {
                        v.name = "probationaryRemark"
                    }
                    if (v.displayName === "资料附件") {
                        v.name = "probationaryEnclosureList"
                    }
                    if (v.name === "type" && v.value) {
                        v.value = v.value - 1
                    }
                })
                break;
            case "1":
                //预备转正式
                this.fieldsListTransfor.map(v => {
                    if (v.name.indexOf("ecommender") !== -1) {
                        v.name = "recommender"
                    }
                    if (v.editor === "datePicker") {
                        v.name = "conversionTime"
                    }
                    if (v.displayName === "备注") {
                        v.name = "conversionRemark"
                    }
                    if (v.displayName === "资料附件") {
                        v.name = "conversionEnclosureList"
                    }
                    if (v.name === "type" && v.value) {
                        v.value = v.value - 1
                    }
                })
                break;
            case "0":
                break;
            default:
                break;
        }

        PcService.bindFormdData(item, this.fieldsListTransfor);
        this.fieldsListTransfor.map(v => {
            if (v.name === "type" && v.value) {
                v.value = v.value - 1
            }
        })
        
        this.setState({
            transforOptions: {
                ...transforOptions,
                visible: true,
            },
        });

    }
    transforOnOk = (forms) => {
        let { transforOptions } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                let url = urls.app.partyMember.updateMember;
                let _params = Object.assign({}, values);
                let flag = true;  
                this.setState({ loading: true });
                PcService.formSubmit(url, true, _params, null, (data) => {
                    message.success("操作成功!");
                    //menu跳转
                    this.tabsMenus.map((v, n) => {
                        if (flag && v.active && n < 2) {
                            this.navOnchange(null, n + 1);
                            flag = false;
                        }
                        if (flag && n === 2) {
                            this.fetchData(null, "2");
                        }
                    })
                    this.setState({ loading: false });
                }, (err) => {
                    message.error(err);
                    this.setState({ loading: false });
                });

                setTimeout(function () {
                    that.setState({
                        transforOptions: {
                            ...transforOptions,
                            visible: false
                        }
                    })
                }, 1000);
            }
        });

    }
    transforOnCancel = () => {
        let { transforOptions } = this.state;
        this.setState({
            transforOptions: {
                ...transforOptions,
                visible: false
            }
        });
    }
    // 所属党组织
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                let lists = treeData ? treeData : [];
                this.setState({
                    treeList: treeData,
                });

                this.fieldsList.map(v => {
                    if (v.name === "structureId") {
                        v.opts = lists;
                    }
                })
                this.setState({})
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }

    /* 新增申请人 */
    createOne = () => {
        PcService.relativePath('/app/partyMemberDevelopmentAdd');

    }
    /* 修改申请人 */
    editOne = (item) => {
        /* //清除新增显示缓存的图片
        this.fieldsList.map(v => {
            if (v.name === 'photo') {
                v.fileUpLoadOption.imageUrl = "";
            }
        }) */
        let { modalOptions } = this.state;
        PcService.bindFormdData(item, this.fieldsList);
        //工作信息
        let { jobInfoList } = item;
        PcService.bindFormdData(jobInfoList[0], this.fieldsListWork);
        this.copyListFromWork(this.fieldsList, this.fieldsListWork);
        //如果是正式党员，禁止选择
        this.fieldsList.map(k => {
            if (k.name === "type" && k.value === PcService.getDataDictionary("TYPE")[0].id) {
                k.unavailable = true;
                this.fieldsList.map(v => {
                    if (v.name === "isOut" || v.name === "isFlow" || v.name === "isHard" ||
                        v.name === "isPartyCadre" || v.name === "isMissing" || v.name === "isRetire") {
                        v.editor = "radio";
                    }
                })
            }
        })
        let title = "申请人";
        switch (item.type) {
            case "3":
                title = "积极份子";
                break;
            case "2":
                title = "发展对象";
                break;
            default:
                title = "申请人";
                break;
        }
        PcService.bindFormdData(item.jobInfoList[0], this.fieldsListWork);
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
                title,
            },
        });
    }
    copyListFromWork = (list, work) => {
        work.map(v => {
            list.map(d => {
                if (v.name === d.name) {
                    d.value = v.value;
                }
            })
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, partyName } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                //joblistwork
                values.jobInfoList = [];
                values.jobInfoList.push({
                    duty: values.duty,
                    headship: values.headship,
                    title: values.title,
                    unitName: values.unitName
                })
                values.partyName = partyName ? partyName : undefined;

                let url = urls.app.partyMember.updateMember;
                let _params = Object.assign({}, values);
                this.setState({ loading: true });
                PcService.formSubmit(url, true, _params, null, (data) => {
                    message.success("操作成功!");
                    //menu跳转
                    this.tabsMenus.map((v, n) => {
                        if (v.active) {
                            this.fetchData(null, 4 - n);
                        }
                    })

                    this.setState({ loading: false });
                }, (err) => {
                    message.error(err);
                    this.setState({ loading: false });
                });

                setTimeout(function () {
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            visible: false
                        }
                    })
                }, 1000);
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 切换 */
    navOnchange = (item, index) => {
        let { tabsMenus, columnsList } = this;
        const { transforOptions } = this.state;
        let columns = columnsList[index];
        if (index === 0) {
            this.setState({ buttonAdd: 'block' })
        } else {
            this.setState({ buttonAdd: 'none' })
        }
        tabsMenus.map((v, n) => {
            if (n === index) {
                v.active = true
            } else {
                v.active = false
            }
        });
        this.setState({ columns });
        //判断类型
        let type;
        switch (index) {
            case 0:
                type = '4';
                this.setState({
                    transforOptions: {
                        ...transforOptions,
                        title: "积极分子",
                    },
                });
                break;
            case 1:
                type = '3';
                this.setState({
                    transforOptions: {
                        ...transforOptions,
                        title: "发展对象",
                    },
                });
                break;
            case 2:
                type = '2';
                this.setState({
                    transforOptions: {
                        ...transforOptions,
                        title: "预备党员",
                    },
                });
                break;
            case 3:
                type = '1';
                this.setState({
                    transforOptions: {
                        ...transforOptions,
                        title: "正式党员",
                    },
                });
                break;
            case 4:
                type = '0';
                break;
            default:
                break;
        }

        this.setState({ currentType: type });
        this.fetchData(null, type);
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentDidMount() {
        //党员发展名额
        this.setState({ loading: true });
        http.request({
            method: 'get',
            url: urls.app.partyMemberDevelopment.page,
        }).then(res => {
            if (res.success === true) {
                this.setState({ loading: false });
                if (!res.data.rows.length > 0) return;
                this.setState({
                    memberId: res.data.rows[0].id,
                    memberNumber: res.data.rows[0].memberNumber,
                    progressedNumber: res.data.rows[0].progressedNumber,
                    surplusNumber: res.data.rows[0].surplusNumber,
                })
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({
                loading: false
            });
        });

        PcService.initFormList(this.seachFieldsList);
        this.fetchData(null, '4');
        this.setState({ buttonAdd: 'block' })

        //获取党组织
        this.getOrganizationTree();
    }

    render() {
        const { edit, onChange, tabsMenus, seachFieldsList, seachOnSubmit, onSelectChange, createOne, transforOnOk, transforOnCancel,
            navOnchange, fieldsList, fieldsListTransfor, onOk, columnsList, onCancel, deleteItem, cancel, handleVisibleChange } = this;
        const { memberNumber, progressedNumber, fetchData, pagination,
            visible, modalOptions, transforOptions, create, surplusNumber, inputFlag, loading, columns, lists, selectedRowKeys, buttonAdd } = this.state;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 900, title: (create ? "编辑" : "编辑") + modalOptions.title };
        const modalTransfor = { ...transforOptions, onOk: transforOnOk, onCancel: transforOnCancel, title: "转为" + transforOptions.title };
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const formItemLayoutTransfor = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 }
            }
        };
        return (
            <Spin spinning={loading}>

                <div className="partyMemberDevelopment">

                    <div className="partyNumberInfo">
                        <ul>
                            {tabsMenus.map((v, n) => <li key={n} onClick={navOnchange.bind(this, v, n)} className={v.active ? 'active' : ''}>{v.tab}</li>)}
                        </ul>
                        <div className="partyNumberInfo-count" style={{ display: progressedNumber ? "block" : "none" }}>
                            <div className="member-number">发展党员名额：
                                    {inputFlag ? <Input style={{ width: '56px' }} value={memberNumber} onChange={onChange} /> :
                                    <span >{memberNumber}</span>}
                                个<span onClick={progressedNumber ? edit : null} className={"edit"}>编辑</span></div>
                            <div className="member-number">已用发展党员名额：{progressedNumber}个</div>
                            <div className="member-number">剩余发展党员名额：{surplusNumber}个</div>
                        </div>
                    </div>

                    <div className="partyNumberInfo-search">
                        <div className="sp-btns">
                            <Button onClick={createOne} style={{ display: buttonAdd }} icon="file-add" type="primary">新增</Button>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要删除吗?"
                                visible={visible}
                                onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                onConfirm={deleteItem.bind(this, selectedRowKeys, 'button')}
                                onCancel={cancel}
                                okText="确定"
                                cancelText="取消" >
                                <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                            </Popconfirm>
                        </div>
                        <div className="sp-forms">
                            <PcForm layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset fieldsList={seachFieldsList} />
                        </div>
                    </div>
                    <div className="partyNumberContent">
                        <PcTable
                            rowSelection={rowSelection}
                            columns={columns.length === 0 ? columnsList[0] : columns}
                            pagination={pagination}
                            dataSource={lists}
                            fetchData={fetchData}
                            bordered={false}
                        />


                    </div>
                </div>
                <PcModal formClassName={"party-applicat"} fieldsList={fieldsList} modal={modal} formItemColumns="2" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                <PcModal fieldsList={fieldsListTransfor} modal={modalTransfor} modalsLoading={modalTransfor.modalsLoading} formItemLayout={formItemLayoutTransfor} />
            </Spin>
        )
    }
}

export default PartyMemberDevelopment;
