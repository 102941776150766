import React, { Component } from 'react';
import urls from '../../../configs/api.config';
import { Select, Icon, Spin, message } from 'antd';
import PcService from '../../../other/pc.service';
import pcService from '../../../other/pc.service';
import http from '../../axios/request';
import { getLocal } from '../../utils';
import './simpleMessage.less';
import messageIcon from '../../../img/msg.png';

const { Option } = Select;

export class SimpleMessage extends Component {

    state = {
        loading: false,
        type: 0,
        messageStatus: 0,
        navList: [
            { title: "公告", id: 0, active: true },
            { title: "通知", id: 1, active: false }
        ],
        userInfo: {}
    };

    /* 消息类型 */
    msgState = [
        { id: 2, name: "全部信息" },
        { id: 0, name: "未读信息" },
        { id: 1, name: "已读信息" }
    ];

    /* 获取列表数据 */
    fetchData = (params, isFresh = false) => {
        let { setParentState, msgList = [] } = this.props;
        let { userInfo } = this.state;
        if (msgList.length === 0 || isFresh) {
            this.setState({ loading: true }, () => {
                let _params = { ...params, userId: userInfo.userId }
                let msgCount = 0;
                PcService.fetchData(urls.app.message.messageList, _params, null, (data) => {
                    if (data.rows.length > 0) {
                        msgCount = data.rows[0].unReadNumber;
                    }
                    setParentState({ msgCount, msgList: data.rows || [] });
                    this.setState({ loading: false });
                }, () => {
                    this.setState({ loading: false });
                });
            });
        }
    }

    /* 切换type */
    toggleBar = (item, index) => {
        let { navList, type } = this.state;
        if (type === item.id) return;
        navList.map((v, n) => {
            if (v.id === item.id) {
                v.active = true;
            } else {
                v.active = false;
            }
        });
        this.setState({
            navList, type: item.id
        }, () => {
            let { type, messageStatus } = this.state;
            this.fetchData({ type, messageStatus }, true);
        })
    }

    /* 更改状态回调 */
    messageStatusOnChange = (value, $event) => {
        this.setState({ messageStatus: value }, () => {
            let { type } = this.state;
            this.fetchData({ messageStatus: value, type }, true);
        });
    }

    /* 标级为已读 */
    allRead = () => {
        //do somethings
        let { msgList = [] } = this.props;
        let data = msgList.map(v => ({ id: v.id }));
        if (data.length === 0) {
            message.info("当前没有未读消息!");
            return;
        }
        this.setState({ loading: true }, () => {
            http.request({
                method: "post",
                url: urls.app.message.readMessages,
                data,
            }).then(res => {
                if (res.success) {
                    message.success("标记成功！");
                    let { type, messageStatus } = this.state;
                    this.fetchData({ type, messageStatus }, true);
                } else {
                    message.error(res.message);
                }
                this.setState({ loading: false });
            }).catch(err => {
                this.setState({ loading: false });
            })
        });
    }

    /* 阻止默认冒泡事件 */
    stopToggleMask = ($event) => {
        $event.stopPropagation();
    }

    /* 查看全部消息 */
    seeAllMsg = () => {
        let { toggleMask } = this.props;
        pcService.relativePath("/app/msMessage");
        toggleMask();
    }

    componentWillMount() {
        let { type, messageStatus } = this.state;
        let userInfo = getLocal("user_info");
        this.setState({ userInfo }, () => {
            this.fetchData({ type, messageStatus });
        });
        setInterval(this.fetchData, 120 * 1000);
    }

    render() {
        const { toggleMask = null, msgList = [], smStyle = {} } = this.props;
        const { loading, navList, messageStatus } = this.state;
        const { toggleBar, msgState, messageStatusOnChange, allRead, stopToggleMask, seeAllMsg } = this;
        return (
            <div style={smStyle} className="sm-msg-mask" onClick={toggleMask}>
                <div className="sm-msg" onClick={stopToggleMask}>
                    <Spin spinning={loading}>
                        <header>
                            <ul>
                                {
                                    navList.map((v, n) => <li key={v.id} className={v.active ? "i-cell active" : "i-cell"} onClick={toggleBar.bind(this, v, n)}>{v.title}</li>)
                                }
                                <li className="i-checks">
                                    <Select size="small" value={messageStatus} onChange={messageStatusOnChange}>
                                        {
                                            msgState.map((v, n) => <Option key={n} value={v.id}>{v.name}</Option>)
                                        }
                                    </Select>
                                </li>
                            </ul>
                        </header>
                        <main>
                            <ul>
                                {(!msgList || msgList.length === 0) ?
                                    <p>暂无数据！</p> :
                                    msgList.map((v, n) => (<li key={n}>
                                        <h4 title={v.title}>
                                            <span className="fltRight">{pcService.dateFormat(v.createdTime, 'MM-dd hh:mm')}</span>
                                            <img src={messageIcon} />
                                            {v.title.length > 13 ? (v.title.slice(0, 13) + ".") : v.title}
                                        </h4>
                                        <div className="p" title={v.content}>{v.content.length > 42 ? v.content.substr(0, 42) + "..." : v.content}</div>
                                    </li>)
                                    )}
                            </ul>
                        </main>
                        <footer>
                            <div className="ft-left">
                                <a onClick={allRead}><Icon type="check-circle" /> 标记全部为已读</a>
                            </div>
                            <a onClick={seeAllMsg}>查看全部</a>
                        </footer>
                    </Spin>
                </div>
            </div>
        )
    }
}

export default SimpleMessage
