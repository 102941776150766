import React, { Component } from 'react';
import { Row, Spin, Button, Divider, Icon, message, Popconfirm } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import PartyMemberEducationView from "./PartyMemberEducationView";
import axios from "axios";
import moment from 'moment';
import { hashHistory } from 'react-router';
import PcService from './../../other/pc.service';
import urls from './../../configs/api.config';
import http from './../../common/axios/request';
import appConfig from './../../configs/app.config';
import "./partyMemberEducation.less";
import officeBanner from './officeBanner.png';


class PartyMemberEducation extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        visible: false,
        isFresh: false,
        toggleView: 'listView',
        imgResponseId: '',
        modalOptions: {
            title: "党员教育",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: [],
        viewDel: false,
        updateId: "",
    }

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.detailItem.bind(this, record)}><Icon type="info-circle" /> 详情</a>
                        <Divider type="vertical" />
                        <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" /> 编辑</a>
                    </span>
                )
            }
        },
        {
            title: '封面图',
            dataIndex: 'courseCoverMongdbId',
            key: 'courseCoverMongdbId',
            render: (text, record, index) => {
                return PcService.getImgFromUrl(text ? (urls.file.viewImg + '/' + text) : officeBanner);
            }
        },
        {
            title: '标题',
            dataIndex: 'courseName',
            key: 'courseName'
        },
        {
            title: '课程类别',
            dataIndex: 'catelogDictId',
            key: 'catelogDictId',
            render: (text, record, index) => {
                return PcService.numberToText(text, PcService.getDataDictionary("STKCLB"))
            }
        },
        {
            title: '是否必修',
            dataIndex: 'mustRequired ',
            key: 'mustRequired',
            render: (text, record, index) => {
                if (record.mustRequired) return "是";
                else return "否";
            }
        },
        {
            title: '发布时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            sorter: (a, b) => {
                return parseInt(new Date(a.createdTime).getTime()) - parseInt(new Date(b.createdTime).getTime());
            },
            render: (text, record, index) => record.createdTime ? record.createdTime.substring(0, 10) : ""
        }
    ];

    seachFieldsList = [{
        name: "mustRequired",
        editor: "select",
        value: "",
        originValue: "",
        displayName: "是否必修",
        opts: [
            { id: "", name: "全部" },
            { id: true, name: '是' },
            { id: false, name: '否' }
        ],
    }, {
        name: "courseName",
        editor: "normal",
        value: "",
        displayName: "课程名称",
        hideDisplayName: false,
        originValue: ""
    }];

    /* 导航菜单 */
    tabsMenus = [
        {
            tab: "全部",
            id: "",
            name: "全部",
            active: true
        }
    ];

    formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 }
        }
    };

    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "courseName",
            editor: "normal",
            value: "",
            displayName: "课程名称",
            columns: 1,
            rules: [
                { max: 40, message: '最多可以输入40个字符' },
                {
                    required: true,
                    message: "请输入课程名称"
                },
                {
                    whitespace: true,
                    message: "不能输入空格"
                }
            ],
            formItemLayout: this.formItemLayout,
        }, {
            name: "mustRequired",
            editor: "select",
            displayName: "是否必修",
            columns: 2,
            formItemLayout: this.formItemLayout,
            originValue: true,
            value: "",
            opts: [
                { id: true, name: '是' },
                { id: false, name: '否' }
            ],
            rules: [
                {
                    message: '请选择是否必修',
                    required: true
                }
            ]
        }, {
            name: "minStudyMinutes",
            editor: "hidden",
            displayName: "最少学习时间",
            value: "",
            columns: 2,
            options: {
                addonAfter: "分钟",
            },
            formItemLayout: this.formItemLayout,
            rules: [
                /* {
                    required: true,
                    message: "请输入最少学习时间"
                } */
            ]
        }, {
            name: "catelogDictId",
            editor: "select",
            displayName: "课程类别",
            columns: 2,
            value: "",
            formItemLayout: this.formItemLayout,
            opts: PcService.getDataDictionary("STKCLB"),
            rules: [
                {
                    required: true,
                    message: "请选择会议类型"
                }
            ],
            /* }, {
                name: "editor",
                editor: "normal",
                value: "",
                displayName: "作者",
                columns: 2,
                formItemLayout: this.formItemLayout,
                rules: [
                    {
                        required: true,
                        message: "请输入作者"
                    },
                    {
                        whitespace: true,
                        message: "不能输入空格"
                    }
                ] */
            /* }, {
                name: "beginEndTime",
                editor: "rangePicker",
                originValue: null,
                value: '',
                columns: 1,
                displayName: "课程起止时间",
                timeOptions: {
                    format: 'YYYY-MM-DD'
                },
                formItemLayout: this.formItemLayout,
                rules: [
                    // { required: true, message: "请输入起止时间" }
                ] */
        }, {
            name: "courseCoverMongdbId",
            editor: "pictureWall",
            displayName: "封面图",
            value: '',
            originValue: '',
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                listType: "picture-card",
                accept: "image/*",
                beforeUpload: (file) => {
                    const isLt2M = file.size / 1024 / 1024 < 2;
                    if (!isLt2M) {
                        message.error('图片必须小于2MB!');
                    }
                    return isLt2M;
                },
                onChange: (info, form, _onchange) => {
                    if (info.file.status === 'done') {
                        let _value = info.fileList.map(v => v.response);
                        _onchange(_value.join(","));
                    }
                }
            },
            formItemLayout: this.formItemLayout,
        }, {
            name: "videoMongdbId",
            editor: "hidden",
            value: "",
            columns: 1,
            originValue: "",
            displayName: "视频资源",
            formItemLayout: this.formItemLayout,
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                imageUrl: "",
                listType: "picture-card",
                loading: false,
                showUploadList: false,
                accept: "video/*",
                onChange: (info, form, _onchange) => {
                    let { response } = info.file;
                    _onchange(response);
                }
                /* action: urls.file.uploadGetId,
                imageUrl: "",
                listType: "picture-card",
                loading: false,
                showUploadList: false,
                accept: "video/*",
                onChange: (info, form, _onchange) => {
                    if (info.file.status === 'uploading') {
                        this.fieldsList.map(v => {
                            if (v.name === "videoMongdbId") {
                                v.fileUpLoadOption.loading = true;
                            }
                            return;
                        });
                        this.setState({});
                    }
                    if (info.file.status === 'done') {
                        //console.log(info)
                        let { response } = info.file;
                        _onchange(response);
                        this.fieldsList.map(v => {
                            if (v.name === "videoMongdbId") {
                                v.fileUpLoadOption.loading = true;
                                v.fileUpLoadOption.imageUrl = urls.file.viewImg + "/" + response;
                            }
                            return;
                        });
                        this.setState({});
                    }
                } */
            },
            rules: []
        }, {
            name: "studyFileList",
            editor: "attachments",
            value: [],
            originValue: [],
            displayName: "课程附件",
            formItemLayout: this.formItemLayout,
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                accept: "image/*,.doc,.docx,.xlsx,.xls,.ppt,.pdf,.rar,.zip,.txt",
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            },
            rules: []
        }, {
            name: "desp",
            editor: "bEditor",
            value: "",
            columns: 1,
            formItemLayout: this.formItemLayout,
            displayName: "内容"
        }
    ];

    //删除Mongo文件  
    errUpload = (deleteMongoId) => {
        this.setState({ loading: true });
        http.request({
            method: "delete",
            url: urls.file.deleteMongo + '/' + deleteMongoId
        }).then(res => {
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 删除 */
    deleteItem = (item = [], form, $event) => {
        let ids;
        if (form === 'button') {
            ids = item.join(",");
        }
        let that = this;
        this.setState({ loading: true });
        PcService.deleteItem(urls.app.partyMemberEducation.logic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                this.setState({ loading: false });
            }, err => {
                this.setState({ loading: false });
            }
        );
    }
    //全选
    selectAll = () => {
        this.selectItem("selectAll");
    }

    /* 获取列表数据 */
    fetchData = (paramsDta, isToggleView) => {
        let { pagination, toggleView } = this.state;
        let { pageSize, current } = pagination;
        let type = isToggleView ? isToggleView : "";
        if (!type) {
            if (toggleView !== "listView") {
                type = "VIDEO"
            } else {
                type = "PICTURECONTENT"
            }
        }
        let _params = Object.assign({}, { pageSize, current, type }, paramsDta);
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.partyMemberEducation.page, _params, null, (data) => {
                let _pagination = PcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.isSelect = false;
                });
                this.setState({
                    loading: false,
                    isFresh: true,
                    lists: lists,
                    pagination: _pagination
                });
            }, (err) => {
                this.setState({ loading: false });
            });
        });
    }

    selectItem = (id) => {
        const { lists, selectedRowKeys } = this.state;
        let viewDel = false;
        let selectIdList = [];
        lists.map((v, n) => {
            if (id === "selectAll") {
                if (selectedRowKeys.length) {
                    v.isSelect = false;
                    viewDel = false;
                } else {
                    v.isSelect = true;
                    viewDel = true;
                }
            } else {
                if (v.id === id) {
                    v.isSelect = !v.isSelect;
                    viewDel = !viewDel;
                    /* } else {
                        v.isSelect = false; */
                }
            }
        })
        //获取isSelect为true的id
        lists.map((v, n) => {
            if (v.isSelect) selectIdList.push(v.id)
        })
        this.setState({
            lists,
            viewDel
        })
        this.setState({ selectedRowKeys: selectIdList });
    }

    getDictionaryToCourse = () => {
        let classCourse = PcService.getDataDictionary("STKCLB");
        classCourse.length && classCourse.forEach(v => {
            this.tabsMenus.push({
                tab: v.name,
                name: v.name,
                id: v.id,
                active: false,
            });
        });
    }

    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }

    /* 新增修改 */
    createOne = (item, create) => {
        //清除新增显示缓存的图片
        this.fieldsList.map(v => {
            if (v.name === 'courseCoverMongdbId' || v.name === 'videoMongdbId') {
                v.fileUpLoadOption.imageUrl = "";
            }
            if (v.name === "studyFileList") {
                delete v.fileUpLoadOption.fileList;
            }
        })
        this.setState({ imgResponseId: "" });
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            PcService.bindFormdData(item, this.fieldsList);
            //处理rangePicker 
            // let beginEndTime = [moment(item.beginTime || ''), moment(item.endTime || '')];
            this.fieldsList.map(v => {
                /* if (v.name === 'beginEndTime') {
                    v.value = beginEndTime;
                } */
                if (v.name === 'minStudyMinutes') {
                    v.value = v.value ? v.value.toString() : "";
                }
            })
        } else {
            _create = true;
            PcService.initFormList(this.fieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create,
            updateId: item.id ? item.id : "",
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create, imgResponseId, toggleView, updateId } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                //判断新增或修改
                let url = create ? urls.app.partyMemberEducation.insertEntity : urls.app.partyMemberEducation.updateEntity;
                //时间类型转换 
                /* values.beginTime = values.beginEndTime[0]._d;
                values.endTime = values.beginEndTime[1]._d;
                delete values.beginEndTime; */
                values.type = toggleView === "listView" ? 'PICTURECONTENT' : "VIDEO";
                values.id = !create ? updateId : null;

                let _params = Object.assign({}, values);
                this.setState({ loading: true });
                PcService.formSubmit(url, true, _params, null, (data) => {
                    message.success("操作成功!");
                    this.fetchData();
                    this.setState({ loading: false });
                    setTimeout(function () {
                        that.setState({
                            modalOptions: {
                                ...modalOptions,
                                visible: false
                            }
                        })
                    }, 1000);
                }, (err) => {
                    message.error(err);
                    this.setState({ loading: false });
                });
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 切换 */
    navOnchange = (item, index) => {
        let { tabsMenus } = this;
        // if (index === 0) {
        //     this.setState({ buttonAdd: 'block' })
        // } else {
        //     this.setState({ buttonAdd: 'none' })
        // }
        let typeId;
        tabsMenus.map((v, n) => {
            if (n === index) {
                v.active = true
                typeId = { catelogDictId: v.id };
            } else {
                v.active = false
            }
        });
        this.fetchData(typeId);
    }

    /* 详情 */
    detailItem = (item) => {
        let id = item.id;
        PcService.relativePath(`/app/partyMemberEducationDetail?recordId=${id}`);
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    /* 切换视图 */
    toggleButton = () => {
        const { toggleView, lists } = this.state;
        if (toggleView === "listView") {
            this.setState({
                toggleView: 'videoView',
                isFresh: false
            }, data => {
                this.fetchData(null, 'VIDEO');
            })
            //显示最少学习时间
            this.fieldsList.map(v => {
                if (v.name === "minStudyMinutes") {
                    v.editor = "normal";
                    v.rules = [{
                        required: true,
                        message: "请输入最少学习时间"
                    },
                    { pattern: /^([1-9]\d*|\d+\.\d+)$/, message: '请输入非负整数' },
                    { max: 5, message: '最多可以输入5个数字' }];
                }
                if (v.name === "videoMongdbId") {
                    v.editor = "videoWall";
                    v.rules = [{ required: true, message: "请上传视频资源" }]
                }
            })
        } else {
            this.setState({
                toggleView: 'listView',
                isFresh: false
            }, data => {
                this.fetchData(null, 'PICTURECONTENT');
            })
            //显示最少学习时间
            this.fieldsList.map(v => {
                if (v.name === "minStudyMinutes") {
                    v.editor = "hidden";
                    v.rules = [];
                }
                if (v.name === "videoMongdbId") {
                    v.editor = "hidden";
                    v.rules = [];
                }
            })
        }
        lists.map((v, n) => {
            v.isSelect = false;
        })
        this.setState({
            lists,
            viewDel: false,
            selectedRowKeys: []
        })
    }

    componentWillMount() {
        PcService.initFormList(this.seachFieldsList);
        this.fetchData(null, "PICTURECONTENT");
        this.getDictionaryToCourse();
    }

    render() {
        const { visible, loading, selectedRowKeys, pagination, lists, modalOptions, create, toggleView, isFresh } = this.state;
        const { onSelectChange, tabsMenus, toggleButton, navOnchange, columns, fetchData, seachFieldsList, seachOnSubmit, createOne, fieldsList, onOk, onCancel, deleteItem, selectAll, cancel, handleVisibleChange } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 960, title: (create ? "新增" : "编辑") + modalOptions.title }
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <div className="pme-toggle">
                        <div className="pme-label">课程类别：</div>
                        <ul>
                            {tabsMenus.map((v, n) => <li key={n} onClick={navOnchange.bind(this, v, n)}
                                className={v.active ? 'active' : ''}>{v.tab}</li>)}
                        </ul>
                        <div style={{ float: "right" }}>
                            <Button onClick={toggleButton}>{toggleView === "listView" ? "切换视听学习" : "切换图文学习"}</Button>
                        </div>
                    </div>
                    <div className="pme-bars">
                        <div className="pme-btns">
                            <div style={{
                                float: 'left'
                            }}>
                                <Button className="pme-btn" onClick={createOne} icon="file-add" type="primary">新增</Button>
                                {/* <Button className="pme-btn" disabled={!allowDel && !viewDel} onClick={deleteItem.bind(this, selectedRowKeys, 'button')}>删除</Button> */}
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys, 'button')}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                </Popconfirm>
                                {
                                    toggleView !== "listView" ? (<span>
                                        <Divider type="vertical" />
                                        <Button className="pme-btn" icon="check-square" onClick={selectAll}>全选</Button>
                                    </span>) : ""
                                }

                            </div>
                        </div>
                        <div className="pme-forms">
                            <PcForm layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset
                                fieldsList={seachFieldsList} />
                        </div>
                    </div>
                    <div className="pme-content"
                        style={{ display: this.state.toggleView === 'listView' && isFresh ? 'block' : 'none' }}>
                        <PcTable
                            rowSelection={rowSelection}
                            columns={columns}
                            pagination={pagination}
                            dataSource={lists}
                            fetchData={fetchData}
                            bordered={false}
                        />
                    </div>
                    <div style={{ display: this.state.toggleView !== 'listView' && isFresh ? 'block' : 'none' }}>
                        {
                            lists.length > 0 ? <PartyMemberEducationView lists={lists} selectItem={this.selectItem.bind(this)} createOne={this.createOne.bind(this)} />
                                : <div className="ant-empty ant-empty-normal">
                                    <div className="ant-empty-image">
                                        <img alt="暂无数据" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA2NCA0MSIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxlbGxpcHNlIGZpbGw9IiNGNUY1RjUiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3Ii8+CiAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgIDxwYXRoIGQ9Ik01NSAxMi43Nkw0NC44NTQgMS4yNThDNDQuMzY3LjQ3NCA0My42NTYgMCA0Mi45MDcgMEgyMS4wOTNjLS43NDkgMC0xLjQ2LjQ3NC0xLjk0NyAxLjI1N0w5IDEyLjc2MVYyMmg0NnYtOS4yNHoiLz4KICAgICAgPHBhdGggZD0iTTQxLjYxMyAxNS45MzFjMC0xLjYwNS45OTQtMi45MyAyLjIyNy0yLjkzMUg1NXYxOC4xMzdDNTUgMzMuMjYgNTMuNjggMzUgNTIuMDUgMzVoLTQwLjFDMTAuMzIgMzUgOSAzMy4yNTkgOSAzMS4xMzdWMTNoMTEuMTZjMS4yMzMgMCAyLjIyNyAxLjMyMyAyLjIyNyAyLjkyOHYuMDIyYzAgMS42MDUgMS4wMDUgMi45MDEgMi4yMzcgMi45MDFoMTQuNzUyYzEuMjMyIDAgMi4yMzctMS4zMDggMi4yMzctMi45MTN2LS4wMDd6IiBmaWxsPSIjRkFGQUZBIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K" />
                                    </div>
                                    <p className="ant-empty-description">暂无数据</p>
                                </div>
                        }
                    </div>
                </div>
                <PcModal formClassName={"pme-model"} fieldsList={fieldsList} modal={modal} formItemColumns="2" modalsLoading={modal.modalsLoading}
                    formItemLayout={formItemLayout} />
            </Spin>
        )
    }
}

export default PartyMemberEducation;
