import React from 'react';

import { Upload, message } from 'antd';

import {isNotEmpty} from '../../../common/utils';

export default class SysLogoUpload extends React.Component {
    
    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
          const newState= {
             value: nextProps.value || '',
          };
          return newState;
        }
        return null;
   }

   constructor(props)
   {
       super(props);

       const value = props.value ||'';
       this.state = {
            value
       };
   }

   beforeUpload=(file)=>{
        const isLogin = this.props.isSyslogin;
        const isLt2M = isLogin?file.size/1024/1024<2:file.size/1024<10;
        if (!isLt2M) {
            message.error("上传图片不能大于"+(isLogin?" 2MB.":" 10KB."));
        } else {
            this.getBase64(file, this.handleDealResult);
        }
        return false;
   }

   getBase64=(img, callback)=>{
       try
       {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(img);
       }
       catch(e)
       {
           message.error("上传图片错误，不能转换为Base64！");
       }
   }

   handleDealResult=(imageUrl)=>{
       if(imageUrl)
       {
            if (!('value' in this.props)) {
                this.setState({ value:imageUrl });
            }
            this.triggerChange(imageUrl);
       }
   }

   triggerChange = (changedValue) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
   };
    
    render() 
    {
        const uploadButton = (
            <div>
              <div className="ant-upload-text">上传</div>
            </div>
          );
        let {value}=this.state;
        let {altValue,isSyslogin=false}=this.props;
        let customerClass='avatar-uploader';
        if(isSyslogin)
        {
            customerClass="sysLogin";
        }
      return (
           <Upload
                accept="image/*"
                name="avatar"
                listType="picture-card"
                className={customerClass}
                showUploadList={false}
                beforeUpload={this.beforeUpload}
            >
                {isNotEmpty(value) ? <img src={value} alt={altValue} /> : uploadButton}
            </Upload>
         )
      }
}