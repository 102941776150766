import React from 'react';
import { removeClass, addClass, insertBefore, extend } from './colorPickerFun';
import './colorPicker.css';
import colorPicker from './color-picker.png';


class ColorPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = { dragging: false };
    }

    componentDidMount() {
        const { options } = this.props;
        this.options = extend({
            color: '#e7e7e7',
            palettes: ['#646fff', '#fffa1d', '#ffa21f', '#ff391d'],
            onUpdate: function () { }
        }, options);
        this.options.palettes.unshift(this.options.color);
        this.hex = this.options.color;
        this.rgb = this.HEXtoRGB(this.hex);
        this.hsv = this.RGBtoHSV(this.rgb[0], this.rgb[1], this.rgb[2]);
        this.dom = {};
        this.dom.container = document.createElement('div');
        this.dom.container.className = 'color-picker-container';
        let _element = this.refs.colorPicker;
        _element.appendChild(this.dom.container);
        this.initPicker();
        this.initPalettes();
    }

    initPicker() {
        this.dom.picker = {};
        this.dom.picker.container = document.createElement('div');
        this.dom.picker.container.className = 'picker-container';
        this.dom.container.appendChild(this.dom.picker.container);
        this.dom.picker.canvas = {};
        this.dom.picker.canvas.container = document.createElement('div');
        this.dom.picker.canvas.container.className = 'canvas-container';
        this.dom.picker.container.appendChild(this.dom.picker.canvas.container);
        this.dom.picker.canvas.canvas = document.createElement('canvas');
        this.dom.picker.canvas.canvas.className = 'canvas';
        this.dom.picker.canvas.pointer = document.createElement('div');
        this.dom.picker.canvas.pointer.className = 'pointer';
        let ctx = this.dom.picker.canvas.canvas.getContext('2d'),
            image = new Image(),
            $this = this,
            dragging = false;
        this.dom.picker.canvas.canvas.setAttribute('width', 200);
        this.dom.picker.canvas.canvas.setAttribute('height', 200);
        this.dom.picker.canvas.container.appendChild(this.dom.picker.canvas.canvas);
        this.dom.picker.canvas.container.appendChild(this.dom.picker.canvas.pointer);
        image.src = colorPicker;
        image.onload = function () {
            $this.updateCanvasBounds();
            ctx.drawImage(image, 0, 0, 200, 200);
            $this.updateCoordinates($this.dom.picker.canvas.canvas.bounds.centerX, $this.dom.picker.canvas.canvas.bounds.centerY);
            let coordinates = $this.getPositionFromColor($this.hex);
            if (coordinates != null) {
                $this.x = coordinates.x;
                $this.y = coordinates.y;
                $this.updateColor($this.HEXtoRGB($this.hex));
                $this.updateAll(true);
            }
            //$this.options.onUpdate($this.rgb);
        };
        this.dom.picker.canvas.canvas.addEventListener('mousedown', function (e) {
            e.preventDefault();
            dragging = true;
            $this.updateCoordinates(e.clientX, e.clientY);
            let imageData = ctx.getImageData($this.x, $this.y, 1, 1);
            $this.updateColor(imageData.data);
            $this.hsv[2] = 1;
            $this.updateAll();
        });
        document.addEventListener('mousemove', function (e) { // mouse move handler
            if (dragging) {
                $this.updateCoordinates(e.pageX, e.pageY);
                let imageData = ctx.getImageData($this.x, $this.y, 1, 1);
                $this.updateColor(imageData.data);
                $this.hsv[2] = 1;
                $this.updateAll();
            }
        });
        document.addEventListener('mouseup', function (e) { // click event handler
            dragging = false;
        });
        this.dom.picker.canvas.input = document.createElement('input');
        this.dom.picker.canvas.input.disabled = true;
        this.dom.picker.canvas.container.appendChild(this.dom.picker.canvas.input);
        this.dom.picker.canvas.input.addEventListener('keyup', function () {
            if (this.value == $this.hex || '#' + this.value == $this.hex) {
                return;
            }
            let coordinates = $this.getPositionFromColor(this.value);
            if (coordinates != null) {
                $this.x = coordinates.x;
                $this.y = coordinates.y;
                $this.updateColor($this.HEXtoRGB(this.value));
                $this.updateAll();
            }
        });
        this.initSlider();
    };

    initSlider() {
        this.dom.slider = {};
        this.dom.slider.container = document.createElement('div');
        this.dom.slider.container.className = 'slider-container';
        this.dom.slider.slider = document.createElement('div');
        this.dom.slider.slider.className = 'slider';
        this.dom.slider.pointer = document.createElement('div');
        this.dom.slider.pointer.className = 'pointer';
        this.dom.slider.container.appendChild(this.dom.slider.pointer);
        this.dom.slider.container.appendChild(this.dom.slider.slider);
        this.dom.picker.container.appendChild(this.dom.slider.container);
        this.dom.slider.slider.bounds = this.dom.slider.slider.getBoundingClientRect();
        this.dom.slider.pointer.bounds = this.dom.slider.pointer.getBoundingClientRect();
        this.redrawSlider();
        let dragging = false,
            $this = this;
        this.dom.slider.slider.addEventListener('mousedown', function (e) {
            e.preventDefault();
            dragging = true;
            let total = $this.updateSliderCursor(e.clientY);
            $this.updateColor($this.HSVtoRGB($this.hsv[0], $this.hsv[1], 1 - total));
            $this.updateAll();
        });
        this.dom.slider.pointer.addEventListener('mousedown', function (e) {
            e.preventDefault();
            dragging = true;
            let total = $this.updateSliderCursor(e.clientY);
            $this.updateColor($this.HSVtoRGB($this.hsv[0], $this.hsv[1], 1 - total));
            $this.updateAll();
        });
        document.addEventListener('mousemove', function (e) {
            if (!dragging) {
                return;
            }
            let total = $this.updateSliderCursor(e.clientY);
            $this.updateColor($this.HSVtoRGB($this.hsv[0], $this.hsv[1], 1 - total));
            $this.updateAll();
        });
        document.addEventListener('mouseup', function () {
            dragging = false;
        });
    };

    updateColor(pixel) {
        this.hex = this.RGBtoHEX(pixel[0], pixel[1], pixel[2]);
        this.hsv = this.RGBtoHSV(pixel[0], pixel[1], pixel[2]);
        this.rgb = [
            pixel[0],
            pixel[1],
            pixel[2]
        ];
    }

    updateCoordinates(x, y) {
        let angle = Math.atan2((y - this.dom.picker.canvas.canvas.bounds.centerY), (x - this.dom.picker.canvas.canvas.bounds.centerX));
        let radius = Math.sqrt(Math.pow(x - this.dom.picker.canvas.canvas.bounds.centerX, 2) + Math.pow(y - this.dom.picker.canvas.canvas.bounds.centerY, 2));
        if (radius > this.dom.picker.canvas.canvas.bounds.radius - (this.dom.picker.canvas.pointer.bounds.width / 2)) {
            let cos = Math.cos(angle);
            let sin = Math.sin(angle);
            x = cos * (this.dom.picker.canvas.canvas.bounds.radius - (this.dom.picker.canvas.pointer.bounds.width / 2)) + this.dom.picker.canvas.canvas.bounds.centerX;
            y = sin * (this.dom.picker.canvas.canvas.bounds.radius - (this.dom.picker.canvas.pointer.bounds.width / 2)) + this.dom.picker.canvas.canvas.bounds.centerY;
        }
        this.x = Math.floor(x - this.dom.picker.canvas.canvas.bounds.left);
        this.y = Math.floor(y - this.dom.picker.canvas.canvas.bounds.top);
    }

    initPalettes() {
        this.dom.palettes = {};
        this.dom.palettes.list = [];
        this.dom.palettes.container = document.createElement('div');
        addClass(this.dom.palettes.container, 'palletes-container');
        this.dom.container.appendChild(this.dom.palettes.container);
        this.dom.palettes.add = document.createElement('div');
        addClass(this.dom.palettes.add, 'palette add');
        this.dom.palettes.container.appendChild(this.dom.palettes.add);
        let $this = this;
        this.dom.palettes.add.addEventListener('click', function () {
            addClass($this.dom.picker.canvas.container, 'active');
            $this.updateCanvasBounds();
            let palette = $this.addPalette($this.RGBtoHEX($this.rgb[0], $this.rgb[1], $this.rgb[2]));
            for (let i = 0; i < $this.dom.palettes.list.length; i++) {
                removeClass($this.dom.palettes.list[i], 'active');
            }
            addClass(palette, 'active');
            $this.selectedPalette = palette;
        });
        for (let i = 0; i < this.options.palettes.length; i++) {
            this.addPalette(this.options.palettes[i]);
        }
    }

    addPalette(color) {
        let palette = document.createElement('div');
        palette.style.background = color;
        palette.color = color;
        let $this = this;
        palette.addEventListener('click', function () {
            for (let i = 0; i < $this.dom.palettes.list.length; i++) {
                removeClass($this.dom.palettes.list[i], 'active');
            }
            addClass(this, 'active');
            $this.selectedPalette = this;
            let rgb = $this.HEXtoRGB(this.color);
            let coordinates = $this.getPositionFromColor(color);
            $this.x = coordinates.x;
            $this.y = coordinates.y;
            $this.updateColor(rgb);
            $this.updateAll();
        });
        addClass(palette, 'palette');
        insertBefore(palette, this.dom.palettes.add);
        this.dom.palettes.list.push(palette);
        return palette;
    }

    updateAll(continues = false) {
        const { onChange } = this.props;
        this.redrawSlider();
        this.updatePointers();
        this.dom.picker.canvas.input.value = this.hex;
        if (!continues) {
            this.options.onUpdate(this.rgb);
            // 增加onChange方法，返回选中的颜色
            if (onChange) onChange(this.rgb);
        }
        if (this.selectedPalette) {
            this.selectedPalette.style.background = this.hex;
        }
    }

    getPositionFromColor(color) {
        color = this.HEXtoRGB(color);
        if (color === null) {
            return null;
        }
        this.hsv = this.RGBtoHSV(color[0], color[1], color[2]);
        return this.getSVGPositionFromHS(this.hsv[0], this.hsv[1]);
    }

    updateSliderCursor(y) {
        let total = y - this.dom.slider.slider.bounds.top - 6;
        total = this.dom.slider.slider.bounds.height - total;
        total = total / this.dom.slider.slider.bounds.height;
        total = total.toFixed(2);
        if (total < 0) {
            total = 0;
        } else if (total > 1) {
            total = 1;
        }
        total = 1 - total;
        this.dom.slider.pointer.style.top = this.dom.slider.slider.bounds.height * total - (this.dom.slider.pointer.bounds.height / 2) + 'px';
        return total;
    }

    redrawSlider() {
        let rgb = this.HSVtoRGB(this.hsv[0], this.hsv[1], 1);
        let hex = this.RGBtoHEX(rgb[0], rgb[1], rgb[2]);
        let gradient = this.makeGradient(hex, '#000');
        this.dom.slider.slider.setAttribute('style', gradient);
        this.updatePointers();
    };

    updatePointers() {
        if (this.dom.picker.canvas.pointer.bounds) {
            this.dom.picker.canvas.pointer.style.left = this.x - (this.dom.picker.canvas.pointer.bounds.width / 2) + 'px';
            this.dom.picker.canvas.pointer.style.top = this.y - (this.dom.picker.canvas.pointer.bounds.height / 2) + 'px';
        }
        if (this.dom.slider.slider.bounds) {
            let position = this.dom.slider.slider.bounds.height * (1 - this.hsv[2]) - (this.dom.slider.pointer.bounds.height / 2);
            this.dom.slider.pointer.style.top = position + 'px';
        }
    }

    updateCanvasBounds() {
        this.dom.picker.canvas.canvas.bounds = this.dom.picker.canvas.canvas.getBoundingClientRect();
        this.dom.picker.canvas.pointer.bounds = this.dom.picker.canvas.pointer.getBoundingClientRect();
        this.dom.picker.canvas.canvas.bounds.centerX = this.dom.picker.canvas.canvas.bounds.left + (this.dom.picker.canvas.canvas.bounds.width / 2);
        this.dom.picker.canvas.canvas.bounds.centerY = this.dom.picker.canvas.canvas.bounds.top + (this.dom.picker.canvas.canvas.bounds.height / 2);
        this.dom.picker.canvas.canvas.bounds.radius = this.dom.picker.canvas.canvas.bounds.width / 2;
    }

    getSVGPositionFromHS(h, s) {
        let hue = this.scientificToArtisticSmooth(h * 360);
        let theta = hue * (Math.PI / 180);
        let y = Math.sin(theta) * this.dom.picker.canvas.canvas.bounds.radius * s;
        let x = Math.cos(theta) * this.dom.picker.canvas.canvas.bounds.radius * s;
        return {
            x: x + this.dom.picker.canvas.canvas.bounds.radius,
            y: this.dom.picker.canvas.canvas.bounds.radius - y
        }

    };

    scientificToArtisticSmooth(hue) {
        return (
            hue < 35 ? hue * (60 / 35) :
                hue < 60 ? this.mapRange(hue, 35, 60, 60, 122) :
                    hue < 120 ? this.mapRange(hue, 60, 120, 122, 165) :
                        hue < 180 ? this.mapRange(hue, 120, 180, 165, 218) :
                            hue < 240 ? this.mapRange(hue, 180, 240, 218, 275) :
                                hue < 300 ? this.mapRange(hue, 240, 300, 275, 330) :
                                    this.mapRange(hue, 300, 360, 330, 360));
    }

    mapRange(value, fromLower, fromUpper, toLower, toUpper) {
        return (toLower + (value - fromLower) * ((toUpper - toLower) / (fromUpper - fromLower)));
    }


    HEXtoRGB(hex) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    }

    RGBtoHSV(_r, _g, _b) {
        let r = _r / 255, g = _g / 255, b = _b / 255;
        let max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        let h, s, v = max;

        let d = max - min;
        s = max === 0 ? 0 : d / max;

        if (max === min) {
            h = 0; // achromatic
        } else {
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        return [h, s, v];
    }

    HSVtoRGB(h, s, v) {
        let r, g, b;
        let i = Math.floor(h * 6);
        let f = h * 6 - i;
        let p = v * (1 - s);
        let q = v * (1 - f * s);
        let t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0:
                r = v; g = t; b = p;
                break;
            case 1:
                r = q; g = v; b = p;
                break;
            case 2:
                r = p; g = v; b = t;
                break;
            case 3:
                r = p; g = q; b = v;
                break;
            case 4:
                r = t; g = p; b = v;
                break;
            case 5:
                r = v; g = p; b = q;
                break;
        }
        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    RGBtoHEX(r, g, b) {
        function componentToHex(c) {
            let hex = c.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        }
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    makeGradient(colour1, colour2) {
        let gradientString = '\
               /* Mozilla Firefox */ \
               background-image: -moz-linear-gradient(top, {colour1} 0%, {colour2} 100%);\
               /* Opera */ \
               background-image: -o-linear-gradient(top, {colour1} 0%, {colour2} 100%);\
               /* Webkit (Safari/Chrome 10) */ \
               background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, {colour1}), color-stop(1, {colour2}));\
               /* Webkit (Chrome 11+) */ \
               background-image: -webkit-linear-gradient(top, {colour1} 0%, {colour2} 100%);\
               /* IE10+ */\
               background: -ms-linear-gradient(top,  {colour1} 0%,{colour2} 100%);\
               /* W3C */\
               background: linear-gradient(top,  {colour1} 0%,{colour2} 100%);\
           ';

        return gradientString.replace(/\{colour1\}/g, colour1).replace(/\{colour2\}/g, colour2)
    };


    render() {
        return (
            <div ref="colorPicker"></div>
        )
    }
}

export default ColorPicker;
