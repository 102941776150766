// ant d Icon图标库 type
export const menuIconLibrary = [
    'right-square', 'tag', 'share-alt', 'weibo-square', 'star', 'caret-left', 'book', 'ie',
    'cloud-download', 'user-delete', 'bars', 'drag', 'barcode', 'radius-upleft', 'bold', 'behance-square', 'fork',
    'right-circle', 'bg-colors', 'meh', 'chrome', 'notification', 'file-pdf', 'facebook', 'frown', 'filter',
    'radius-setting', 'hdd', 'apartment', 'robot', 'dribbble', 'import', 'select', 'dollar', 'plus-circle',
    'cloud-server', 'loading', 'linkedin', 'codepen-circle', 'code-sandbox', 'file-zip', 'info', 'sketch', 'line-height',
    'zoom-in', 'audio', 'security-scan', 'ellipsis', 'sliders', 'alert', 'file-jpg', 'medium', 'car', 'medicine-box', 'file-excel',
    'file-add', 'table', 'desktop', 'coffee', 'right', 'google-plus', 'unlock', 'loading-3-quarters', 'check', 'rocket', 'amazon',
    'file-search', 'dot-chart', 'box-plot', 'aliyun', 'number', 'pay-circle', 'close-circle', 'poweroff', 'vertical-left',
    'hourglass', 'windows', 'folder-add', 'exclamation', 'line', 'zhihu', 'clock-circle', 'idcard', 'line-chart', 'menu-unfold',
    'radius-upright', 'taobao', 'ant-cloud', 'pie-chart', 'qq', 'pic-left', 'dribbble-square', 'skype', 'check-square', 'to-top',
    'vertical-align-middle', 'gift', 'vertical-align-bottom', 'credit-card', 'funnel-plot', 'highlight', 'heart', 'border',
    'down-circle', 'shop', 'laptop', 'transaction', 'caret-up', 'swap', 'code', 'fast-backward', 'safety', 'pause-circle',
    'step-forward', 'calculator', 'zoom-out', 'trophy', 'border-outer', 'slack-square', 'woman', 'message', 'area-chart',
    'close-square', 'cloud-sync', 'file-word', 'block', 'play-square', 'font-size', 'fire', 'align-left', 'file-unknown',
    'file-text', 'copyright', 'scissor', 'reload', 'ant-design', 'folder-open', 'file-exclamation', 'shrink', 'caret-right',
    'minus', 'question', 'gold', 'bar-chart', 'file-sync', 'arrow-up', 'step-backward', 'export', 'usb', 'bank', 'setting',
    'arrow-down', 'wallet', 'dislike', 'fullscreen-exit', 'deployment-unit', 'play-circle', 'scan', 'paper-clip', 'logout',
    'dingding', 'disconnect', 'alipay', 'small-dash', 'camera', 'minus-square', 'border-horizontal', 'heat-map', 'ci',
    'file-done', 'up-square', 'plus', 'safety-certificate', 'container', 'reddit', 'plus-square', 'redo', 'file-ppt', 'bell',
    'fullscreen', 'arrow-left', 'file-protect', 'snippets', 'profile', 'codepen', 'stop', 'file', 'red-envelope', 'switcher',
    'calendar', 'info-circle', 'weibo-circle', 'project', 'inbox', 'form', 'contacts', 'audit', 'global', 'ordered-list',
    'user-add', 'percentage', 'underline', 'caret-down', 'left-square', 'bulb', 'apple', 'monitor', 'up-circle', 'dash',
    'download', 'up', 'font-colors', 'weibo', 'edit', 'cloud-upload', 'vertical-right', 'align-right', 'yahoo', 'phone',
    'border-right', 'smile', 'euro', 'close', 'upload', 'copy', 'customer-service', 'warning', 'italic', 'swap-right', 'slack',
    'read', 'reconciliation', 'swap-left', 'shake', 'shopping', 'qrcode', 'key', 'thunderbolt', 'pic-right', 'github', 'folder',
    'border-top', 'youtube', 'medium-workmark', 'pause', 'minus-circle', 'mobile', 'solution', 'sound', 'instagram', 'exclamation-circle',
    'file-image', 'sort-descending', 'control', 'down', 'down-square', 'alipay-circle', 'like', 'experiment', 'alibaba', 'skin',
    'database', 'branches', 'home', 'flag', 'fast-forward', 'radius-bottomright', 'tablet', 'property-safety', 'fund',
    'strikethrough', 'wifi', 'appstore', 'border-bottom', 'video-camera', 'exception', 'radar-chart', 'save', 'man',
    'check-circle', 'eye-invisible', 'border-left', 'menu-fold', 'sync', 'double-right', 'cloud', 'build', 'account-book',
    'wechat', 'layout', 'usergroup-delete', 'border-verticle', 'aliwangwang', 'trademark', 'link', 'undo', 'login', 'left',
    'mail', 'forward', 'pound', 'shopping-cart', 'sort-ascending', 'schedule', 'tags', 'behance', 'retweet', 'crown', 'menu',
    'usergroup-add', 'printer', 'file-markdown', 'unordered-list', 'delete', 'environment', 'html5', 'issues-close', 'google',
    'left-circle', 'eye', 'lock', 'twitter', 'carry-out', 'api', 'search', 'compass', 'radius-bottomleft', 'gitlab', 'picture',
    'user', 'arrows-alt', 'enter', 'pic-center', 'double-left', 'android', 'border-inner', 'arrow-right', 'team', 'column-width',
    'rise', 'dropbox', 'gateway', 'vertical-align-top', 'fall', 'dashboard', 'tool', 'rest', 'stock', 'question-circle',
    'align-center', 'cluster', 'insurance', 'taobao-circle', 'rollback', 'pushpin', 'money-collect', 'yuque', 'diff', 'colum-height', 'backward'
];

