import React,{Fragment} from 'react';
import {Card,Popconfirm,message } from 'antd';
import {StandardTable} from '../../../sharedCommponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import { getTabPaneData} from '../../../common/utils';
import AllComponents from '../../../components';
import {convertListToTreeJson} from '../../../common/utils';
import {ButtonAuth,ALinkAuth} from '../../../sharedCommponents/AuthWidget';
import { get,delHttp } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SearchForm from './SearchForm';
import appConfig from './../../../configs/app.config';
import pcService from './../../../other/pc.service';
import contantConfig from './../../../configs/constant.config';
class PostList extends React.Component {

    state={
        loading:false,
        selectedRowKeys:[],
        selectedRows: [],
        data: 
        {
            list: [],
            pagination: {
                pageIndex: 0,
                pageSize: appConfig.maxPageSize,
                total:0
            }
        },
        searchInitValue:{
            keyword:''
        }
    }

    columns=[
        {
            title: '操作',
            width: 240,
            align: 'center',
            render: (text, record) => (
                <Fragment>
                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        onClick={() => this.handleEdit(record)}
                        authCode={'A7553CCFA2D944D298B7EBC55F721954'}
                    >
                        编辑
                    </ALinkAuth>
                    <Popconfirm title="您确定要删除吗?" onConfirm={()=>this.handleDelete(record)} okText="是" cancelText="否">
                        <ALinkAuth
                            // href="javascript:;"
                            authCode={'6A7B1FA22ACB4494AF55ECAA1762E8F8'}
                        >
                            删除
                        </ALinkAuth>
                   </Popconfirm>
                </Fragment>
            ),
        },
        {
            title: '名称',
            dataIndex: 'positionName'
        }
    ]

    componentWillMount()
    {
        this.fetch({});
    }


    componentWillReceiveProps(nextProps)
    {
        if(nextProps.reloadState&&nextProps.reloadState!==this.props.reloadState)
        {
            const params = {
                pagination:{
                    pageIndex: 0
                }
            };
            this.fetch(params);
        }
    }
 


    fetch=(params = {})=>{
        this.setState({loading:true});
        let {pagination}=this.state.data;
        let pageIndex=pagination.pageIndex;
        let pageSize=pagination.pageSize;
        let keyword=this.state.searchInitValue.keyword;
        if(params)
        {
            if(params.pagination)
            {
                pageIndex=params.pagination.pageIndex||0;
                pageSize=params.pagination.pageSize||pageSize;
            }
            if(!params.keyword)
            {
                keyword='';
            }
            else
            {
                keyword=params.keyword
            }
        }
        let queryParams={
            pageIndex,
            pageSize,
            keyword
        };
        get({
            url:apiConfig.rui.basic.post.getAllPosts,
            params:queryParams
        }).then((res)=>{
            if(res)
            {
                let treeData=convertListToTreeJson(res.list,'0','subPosition','positionId','levelBy');
                this.setState({
                    loading:false,
                    data:{
                        list:treeData,
                        pagination:{
                            pageIndex: queryParams.pageIndex,
                            pageSize: queryParams.pageSize,
                            total:res.total
                        }
                    },
                    searchInitValue:{
                        keyword:queryParams.keyword
                    }
                });
            }
            else
            {
                this.setState({ loading:false});
            }
        }).catch((error)=>{
           this.setState({ loading:false});
        })
    } 

    handleSelectRows = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRows,
            selectedRowKeys
        });
    };

    handleStandardTableChange = pagination => {
        const params = {
            pagination:{
                pageIndex: pagination.current-1,
                pageSize: pagination.pageSize
            }
        };
        this.fetch(params);
    };

    handleSearch=(seacheValues)=>{
        //console.log("seacheValues",seacheValues);
        let params;
        if(seacheValues)
        {
           params = {
               pagination:{
                   pageIndex: 0
               },
               keyword:seacheValues.keyword
           };
        }
        else
        {
           params = {
               pagination:{
                   pageIndex: 0
               },
               keyword:''
           };
        }
        this.fetch(params);
    }

    handleDelete=(post)=>{
        //console.log("post",post);
        this.setState({loading:true});
        delHttp({
            url:apiConfig.rui.basic.post.deletePost,
            params:{
                positionId:post.positionId
            }
        }).then((res)=>{
            const params = {
                pagination:{
                    pageIndex: 0
                }
            };
            message.success(contantConfig.DEL_SUCCESS);
            this.fetch(params);
        }).catch((error)=>{
            message.error(contantConfig.DEL_FAIL);
          this.setState({ loading:false});
       })
    }

    handleDeleteAll=()=>{
        let {selectedRowKeys}=this.state;
        let positionIds='';
        if(selectedRowKeys.length===0)
        {
            message.info('请选择要删除的岗位');
            return;
        }
        for(let selectedRowKey of selectedRowKeys)
        {
            positionIds+=selectedRowKey+',';
        }
        if(positionIds)
        {
            positionIds=positionIds.substr(positionIds,positionIds.length-1);
        }
        this.setState({loading:true});
        delHttp({
            url:apiConfig.rui.basic.post.deleteAll,
            params:{
                positionIds:positionIds
            }
        }).then((res)=>{
            this.setState({
                selectedRows:[],
                selectedRowKeys:[]
            },()=>{
                const params = {
                    pagination:{
                        pageIndex: 0
                    }
                };
                message.success(contantConfig.DEL_SUCCESS);
                this.fetch(params);
            });
        }).catch((error)=>{
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading:false});
         });
    }

    handleAdd=()=>{
        let { selectedMenuKey }=this.props;
        let paneKey="add-post-pane";
        let CreatePostForm=AllComponents["CreatePostForm"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"新增岗位",
            content: <CreatePostForm paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
          pcService.relativePath("/app/postManage");
        //this.props.updateTabPane(addPane);
    }

    handleEdit=(post)=>{
        /*let { selectedMenuKey }=this.props;
        let CreatePostForm=AllComponents["CreatePostForm"];
        let paneKey="edit-post-pane";
        let addPane=getTabPaneData({
            key: paneKey,
            title:"编辑岗位",
            content: <CreatePostForm editId={post.positionId} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        this.props.updateTabPane(addPane);*/
        pcService.relativePath("/app/postManage?editId="+post.positionId);
    }

    render() {
        const {/*selectedRows,*/loading, data,searchInitValue} = this.state;
        return (
           <div className="mainDiv">
               <Card bordered={false}>
                   <div className='tableSearchForm'>
                            <SearchForm
                                handleSearch={this.handleSearch}
                                searchInitValue={searchInitValue}
                            />
                   </div>
                   <div className='tableAction'>
                        <ButtonAuth
                                icon="plus"
                                type="primary"
                                dataaction="create"
                                onClick={() => this.handleAdd()}
                                authCode={'84E82F926CBF4499B242A5CB87CF80C0'}
                            >
                                新增
                        </ButtonAuth>
                        <Popconfirm title="您确定要删除选中的岗位吗?" onConfirm={()=>this.handleDeleteAll()} okText="是" cancelText="否">
                                        <ButtonAuth
                                                icon="delete"
                                                type="primary"
                                                dataaction="delete"
                                                loading={loading}
                                                authCode={'277BFD80DFC14562B0BC78237FFA25DD'}
                                            >
                                                批量删除
                                        </ButtonAuth>
                       </Popconfirm>
                   </div>
                   <StandardTable
                            disableSelect={false}
                            loading={loading}
                            rowKey="positionId"
                            data={data}
                            columns={this.columns}
                            childrenColumnName="subPosition"
                            isNeedeSelectedKeys={true}
                            onSelectRow={this.handleSelectRows}
                            isTreeTable={true}
                            onChange={this.handleStandardTableChange}
                        />
               </Card>
           </div>
         )
      }
}


const mapStateToProps = state => {
    const { menuTab } = state;
    return {
        selectedMenuKey:menuTab.selectedMenuKey
    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(PostList);