import React, { Component } from 'react';
import { Row, Col, Spin, Table, Popover } from 'antd';
import './voting-detail.less';
import http from '../../common/axios/request';
import urls from './../../configs/api.config';
import pcService from '../../other/pc.service';
import { setJsonArray } from '../../common/utils';

export class VotingDetail extends Component {

    state = {
        loading: false,
        type: 1,
        id: 0,
        itemData: {},
        lists: [],
        dataSource: [],
        options: [],
        activeItem: {}
    }

    columns = [
        { title: "姓名", key: "name", dataIndex: "name" },
        { title: "票数", align: "right", key: "optionCount", dataIndex: "optionCount" },
    ];

    tColumns = [
        { title: "序号", key: "index", dataIndex: "index", render: (a, b, index) => index + 1 },
        { title: "姓名", key: "name", dataIndex: "name" },
        { title: "类型", key: "type", dataIndex: "type", render: (text) => text === 2 ? "多选" : "单选" },
        {
            title: "选项内容", key: "options", dataIndex: "options",
            render: (text, record, index) => (<ul className="xsse">
                {!!text && text.map((v, n) => <li key={n}>{`${v.name}：${v.items}`.length > 20 ? (
                    <Popover content={`${v.name}：${v.items}`} placement="topRight"
                        overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                        {`${v.name}：${v.items}`.slice(0, 20) + '...'}
                    </Popover>) : `${v.name}：${v.items}`}</li>)}
            </ul>)
        },
        {
            title: "票数", key: "optionCount", dataIndex: "optionCount",
            render: (text, record) => (<ul className="xsse">
                {!!record.options && record.options.map((v, n) => <li key={n}>{`${v.name}：${v.optionCount}票`}</li>)}
            </ul>)
        },
        { title: "有效票数", key: "volidTotalVotes", dataIndex: "volidTotalVotes" },
        { title: "投票人数", key: "votePeople", dataIndex: "votePeople" },
    ]

    /* 获取投票详情 */
    fetchData = () => {
        const { id } = this.state;
        this.setState({ loading: true });
        http.all([
            http.request({
                method: "get",
                params: { votingId: id },
                url: urls.app.voting.findById
            }),
            http.request({
                method: "get",
                url: urls.app.organization.selectStructureTreeById,
                params: { flag: true, type: 0, injob: "0" }
            })
        ]).then(reses => {
            let data = reses[0] ? reses[0].data : [];
            let treeData = reses[1] ? reses[1].data : [];
            let lists = setJsonArray(pcService.renderOrgAndUserNodes({ data: treeData }), "children");
            let dataSource = data.questions;
            let options = dataSource[0] ? dataSource[0].options : [];
            options = options.sort((a, b) => b.optionCount - a.optionCount);
            if (!!options[0]) options[0].active = true;
            let activeItem = options[0] ? options[0] : {};
            this.setState({
                loading: false,
                type: data.type,
                lists,
                itemData: data,
                options,
                activeItem,
                dataSource: data.questions
            });
        }).catch(erres => {
            this.setState({ loading: false });
        })
    }

    toggleUser = (item) => {
        let { options } = this.state;
        let _options = options.map(v => ({ ...v, active: v.id === item.id }))
        this.setState({
            options: _options,
            activeItem: { ...item }
        })
    }

    componentWillMount() {
        const { type, id } = this.props.location.query;
        if (type === undefined || id === undefined) {
            return;
        }
        this.setState({ type, id }, () => {
            this.fetchData();
        });

    }

    render() {
        const { itemData = {}, lists = [], loading, dataSource = [], options, activeItem } = this.state;
        //console.log("options", options);
        const { columns, toggleUser, tColumns } = this;
        const { title = "", orgName = "", type = 1, votingRangeIdTreeShow = [], startTime = "1970-01-01 01:00", endTime = "1970-01-01 01:00", description = "" } = itemData;
        const _votingRangeIdTreeShow = (votingRangeIdTreeShow.map(v => pcService.numberToText(v, lists))).filter(v => !!v);
        return (
            <Spin spinning={loading}>
                <div className="voting-detail">
                    <div className="vd-panel">
                        <h3>基本信息</h3>
                        <div className="vd-content">
                            <Row>
                                <span className="spn-title">投票标题：</span>{title}
                            </Row>
                            <Row>
                                <Col span={8}><span className="spn-title">开展组织：</span>{orgName}</Col>
                                <Col span={8}><span className="spn-title">投票类型：</span>{`投票(${type === 1 ? "人" : "事"})`}</Col>
                                <Col span={8}></Col>
                            </Row>
                            <Row>
                                <Col span={8}><span className="spn-title">开始时间：</span>{pcService.dateFormat(startTime, "yyyy-MM-dd hh:mm")}</Col>
                                <Col span={8}><span className="spn-title">结束时间：</span>{pcService.dateFormat(endTime, "yyyy-MM-dd hh:mm")}</Col>
                                <Col span={8}><span className="spn-title">投票人数：</span>{votingRangeIdTreeShow.length}</Col>
                            </Row>
                            <Row>
                                <span className="spn-title">投票说明：</span>{description}
                            </Row>
                            <Row>
                                <span className="spn-title">投票人员：</span>{_votingRangeIdTreeShow.map((v, n) => <span key={n}>{`${v}${n === _votingRangeIdTreeShow.length - 1 ? "" : "，"}`}</span>)}
                            </Row>
                        </div>
                    </div>
                    <div className="vd-panel has-line">
                        <h3>投票选项</h3>
                        {!loading ? (
                            type === 1 ?
                                //投票（人）
                                <div className="vd-content">
                                    <div className="h3">有效票数{dataSource[0] ? dataSource[0].volidTotalVotes : 0}票，投票人数{dataSource[0] ? dataSource[0].votePeople : 0}人。</div>
                                    <div className="vdcls">
                                        <div className="vdc-lefts">
                                            <h3>投票排名</h3>
                                            <div className="inners-tables">
                                                <table>
                                                    <tbody>
                                                        {options.length === 0 || !options ? (
                                                            <tr className="noData">
                                                                <td colSpan="4">暂无数据</td>
                                                            </tr>
                                                        ) : (
                                                                options.map((v, n) => <tr key={n}>
                                                                    <td><span className={n < 3 ? "active" : ""}>{n + 1}</span></td>
                                                                    {columns.map((_v, _n) => <td align={_v.align} width={_v.width} key={_n}>{v[_v.dataIndex]}</td>)}
                                                                </tr>)
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div className="vdc-right">
                                            <h3>
                                                <ul>{options.map((v, n) => <li className={v.active ? "active" : ""} onClick={toggleUser.bind(this, v)} key={n}>{v.name}</li>)}</ul>
                                            </h3>
                                            <div className="inners-tables" style={{ marginTop: 15 }}>
                                                <div className="innertb-imgs">
                                                    <img width="100" height="120" src={`${urls.file.viewImg}/${activeItem.headImg}`} />
                                                </div>
                                                <div className="innertb-liste">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td width={96}><span className="bigName">{activeItem.name}</span></td>
                                                                <td>{activeItem.optionCount || 0}票</td>
                                                            </tr>
                                                            <tr>
                                                                <td>所属组织：</td>
                                                                <td>{activeItem.structureName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>先进事迹：</td>
                                                                <td>{activeItem.items}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>事迹图片：</td>
                                                                <td>{
                                                                    !!activeItem.imgs && activeItem.imgs instanceof Array && activeItem.imgs.map((v, n) => <li key={n}> <img width="60" height="80" src={`${urls.file.viewImg}/${v}`} /></li>)
                                                                }</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                //投票（事情）
                                <div className="vd-content">
                                    {/* <div className="h3">有效票数{activeItem.volidTotalVotes}票，投票人数{options.length}人。</div> */}
                                    <div className="vdcls">
                                        <Table columns={tColumns} size="small" pagination={false} dataSource={dataSource} />
                                    </div>
                                </div>
                        ) : <div className="vd-content">加载中..</div>}

                    </div>
                </div>
            </Spin>
        )
    }
}

export default VotingDetail
