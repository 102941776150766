import React, { Component } from 'react';
import { Row, Spin, Button, Divider, Icon, message, Modal, Popover } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import pcService from './../../other/pc.service';
import uuid from 'uuid';
import Forms from '../../common/components/form/Forms';
import './votingManagement.less';

class MatterVoting extends Component {

    state = {
        updated: false,
        loading: false,
        selectedRowKeys: [],
        oSelectedRowKeys: [],
        options: [],
        details: true,
        oDetails: true,
        pagination: null,
        oPagination: null,
        create: true,
        oCreate: true,
        modalOptions: {
            title: "投票（事）",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        oModalOptions: {
            title: "投票选项",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        lists: []
    }

    /* 表格列项 */
    columns = [
        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1,

        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, event) => {
                return <span>
                    {/* <a onClick={this.detailItem.bind(this, record, false)} ><Icon type="info-circle" theme="outlined" /> 详情</a>
                    <Divider type="vertical" /> */}
                    <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" theme="outlined" /> 修改</a>
                </span>
            }
        }, {
            title: '类型',
            dataIndex: 'questionTypes',
            key: 'questionTypes',
            render: (text) => text === "1" ? "单选" : "多选"
        }, {
            title: '题目',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: '选项内容',
            dataIndex: 'options',
            key: 'options',
            render: (text = []) => text.map(v => (v.name + " : " + v.items).length < 20 ?
                <p className="mvv-option">{v.name + " : " + v.items}</p> :
                <p className="mvv-option">
                    <Popover content={v.name + " : " + v.items} placement="topRight"
                        overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                        {(v.name + " : " + v.items).slice(0, 20) + '...'}
                    </Popover>
                </p>)
        }, {
            title: '排序',
            dataIndex: 'disorder',
            key: "disorder",
            //sorter: (a, b) => a.disorder - b.disorder
        }
    ];

    /* 表格列项 */
    oColumns = [
        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1,

        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, event) => {
                return <span>
                    <a onClick={this.oDetailItem.bind(this, record, false)} ><Icon type="info-circle" theme="outlined" /> 详情</a>
                    <Divider type="vertical" />
                    <a onClick={this.oCreateOne.bind(this, record, false)} ><Icon type="edit" theme="outlined" /> 修改</a>
                </span>
            }
        }, {
            title: '选项',
            dataIndex: 'name',
            key: 'name',
            render: (text) => pcService.numberToText(text, pcService.getDataDictionary("EXAM_OPTION"))
        }, {
            title: '选项内容',
            dataIndex: 'items',
            key: 'items',
            render: text => text.length > 20 ? <Popover content={text} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {text.slice(0, 20) + '...'}
            </Popover> : text
        }, {
            title: '排序',
            dataIndex: 'disorder',
            key: "disorder",
            sorter: (a, b) => a.disorder - b.disorder
        }
    ];

    /* 详情 */
    detailItem = (item) => {
        let { modalOptions } = this.state;
        pcService.bindFormdData(item, this.fieldsList);
        this.fieldsList.map(v => {
            if (v.editor !== "hidden") {
                v.unavailable = true
            }
        });
        this.setState({
            details: true,
            modalOptions: { ...modalOptions, visible: true },
            options: item["options"]
        })
    }

    /* 详情 */
    oDetailItem = (item) => {
        let { oModalOptions } = this.state;
        pcService.bindFormdData(item, this.oFieldsList);
        this.oFieldsList.map(v => {
            if (v.editor !== "hidden") {
                v.unavailable = true
            }
        });
        this.setState({
            oDetails: true,
            oModalOptions: { ...oModalOptions, visible: true }
        })
    }

    /* 新增、修改 表单列项 */
    fieldsList = [{
        name: 'id',
        value: '',
        originValue: "",
        editor: 'hidden'
    }, {
        name: "name",
        displayName: '题目',
        editor: '',
        value: '',
        originValue: "",
        nodeOptions: { allowClear: false },
        rules: [{ message: "请输入题目", required: true }]
    }, {
        name: 'questionTypes',
        displayName: '类型',
        editor: 'select',
        value: '',
        originValue: "1",
        opts: [
            { id: "1", name: "单选" },
            { id: "2", name: "多选" }
        ],
        rules: [{ message: "请选择类型", required: true }]
    }, {
        name: 'disorder',
        displayName: '排序',
        editor: 'number',
        value: '',
        originValue: "0",
        range: [0, 99999],
        rules: []
    }];

    /*  */
    oFieldsList = [{
        name: "id",
        editor: "hidden",
        value: "",
        originValue: ""
    }, {
        name: "name",
        displayName: '选项',
        editor: 'select',
        opts: pcService.getDataDictionary("EXAM_OPTION"),
        value: '',
        originValue: pcService.getDataDictionary("EXAM_OPTION")[0] ? pcService.getDataDictionary("EXAM_OPTION")[0].id : "",
        rules: [{
            message: "请选择选项",
            required: true
        }]
    }, {
        name: 'items',
        displayName: '选项内容',
        editor: 'textarea',
        value: '',
        originValue: '',
        rules: [
            { message: "请书填写选项内容", required: true },
            { max: 200, message: "字数过长" }
        ]
    }, {
        name: 'disorder',
        displayName: '排序',
        editor: 'number',
        value: '',
        originValue: "0",
        range: [0, 99999],
        rules: []
    }];


    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /*options 选中事件*/
    oOnSelectChange = (oSelectedRowKeys) => {
        this.setState({ oSelectedRowKeys });
    };

    /* options 新增，修改 */
    oCreateOne = (item, create) => {
        let { oModalOptions } = this.state
        let _create = false;
        this.oFieldsList.map(v => {
            if (v.editor !== "hidden") {
                v.unavailable = false;
            }
        });
        if (create === false) {
            pcService.bindFormdData(item, this.oFieldsList);
        } else {
            _create = true;
            pcService.initFormList(this.oFieldsList);
        }
        this.setState({
            oModalOptions: {
                ...oModalOptions,
                visible: true,
                modalsLoading: false
            },
            oCreate: _create,
            oDetails: false
        });
    }

    /* 新增投票选项 */
    createOne = (item, create) => {
        let { modalOptions } = this.state
        let _create = false;
        this.fieldsList.map(v => {
            if (v.editor !== "hidden") {
                v.unavailable = false;
            }
        });
        if (create === false) {
            pcService.bindFormdData(item, this.fieldsList);
        } else {
            _create = true;
            pcService.initFormList(this.fieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
                modalsLoading: false
            },
            options: _create ? [] : item["options"],
            create: _create,
            details: false
        });
    };

    /* modal确定回调函数 */
    onOk = () => {
        let { modalOptions, options, create } = this.state;
        let { questions = [], setParentQuestionsState } = this.props;
        if (options.length === 0) {
            message.info("投票选项不能为空！");
            return;
        }
        let forms = this.refs.forms.getForm();
        forms.validateFields((errors, values) => {
            if (!errors) {
                let data = { ...values, options }
                if (create) {
                    if (questions.some(v => v.name === data.name)) {
                        message.info("已经存在当前投票，请勿重复添加！");
                        return;
                    }
                    data["id"] = uuid.v4();
                    questions.push(data);
                } else {
                    questions = questions.map(v => (v.id === data.id) ? { ...data } : { ...v });
                }
                questions = questions.sort((a, b) => a.disorder - b.disorder);
                this.setState({
                    options: [],
                    modalOptions: { ...modalOptions, visible: false }
                }, () => {
                    setParentQuestionsState({ questions });
                })
            }
        });
    }

    /* options 新增修改提交*/
    oOnOk = (forms) => {
        let { oModalOptions, options, oCreate } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let data = { ...values }
                if (oCreate) {
                    if (options.some(v => v.name === data.name)) {
                        message.info("不能增加相同的选项！");
                        return;
                    }
                    data["id"] = uuid.v4();
                    options.push(data);
                } else {
                    options = options.map(v => (v.id === data.id) ? { ...data } : { ...v });
                }
                options = options.sort((a, b) => a.disorder - b.disorder);
                this.setState({
                    options,
                    oModalOptions: {
                        ...oModalOptions,
                        modalsLoading: false,
                        visible: false
                    }
                })

            }
        });
    }

    /* 删除 */
    deleteItem = (selectedRowKeys = []) => {
        let { setParentQuestionsState, questions } = this.props;
        let _questions = [];
        questions.map(v => {
            if (selectedRowKeys.every(_v => v.id !== _v)) {
                _questions.push(v);
            }
        });
        setParentQuestionsState({ questions: _questions });
    }

    /* options删除 */
    oDeleteItem = (selectedRowKeys = []) => {
        let { options = [] } = this.state;
        let _options = [];
        options.map(v => {
            if (selectedRowKeys.every(_v => v.id !== _v)) {
                _options.push(v);
            }
        });
        this.setState({ options: _options });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /*  */
    oNnCancel = () => {
        let { oModalOptions } = this.state;
        this.setState({
            oModalOptions: {
                ...oModalOptions,
                visible: false
            }
        });
    }

    componentWillMount() {
        pcService.initFormList(this.fieldsList);
    }

    render() {
        const { questions = [] } = this.props;
        const { loading, selectedRowKeys, details, oDetails, pagination, oSelectedRowKeys, modalOptions, create, oCreate, options, oModalOptions, oPagination } = this.state;
        const { onSelectChange, columns, deleteItem, oOnOk, oOnSelectChange, oNnCancel, oColumns, createOne, fieldsList, oFieldsList, onOk, onCancel, oCreateOne } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const oRowSelection = { selectedRowKeys: oSelectedRowKeys, onChange: oOnSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const oModal = { ...oModalOptions, onOk: oOnOk, onCancel: oNnCancel, title: (oDetails ? "查看" : (oCreate ? "新增" : "编辑")) + oModalOptions.title }
        const allowDel = selectedRowKeys.length > 0;
        const oAllowDel = oSelectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 2 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 22 }
            }
        };
        const oFormItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <div className="voting">
                <Spin spinning={loading}>
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Button disabled={!allowDel} onClick={deleteItem.bind(this, selectedRowKeys)} icon="delete" disabled={!allowDel}>删除</Button>
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable rowKey="id"
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={questions}
                                bordered={false}
                            />
                        </div>
                    </Row>
                    <Modal {...modal} >
                        <div className="vm-form">
                            <Forms ref="forms" fieldsList={fieldsList} hideFooter formItemLayout={formItemLayout} />
                            <Row>
                                <div className="sp-bars">
                                    <div className="sp-btns">
                                        <Button icon="file-add" onClick={oCreateOne} type="primary">新增</Button>
                                        <Divider type="vertical" />
                                        <Button disabled={!allowDel} onClick={this.oDeleteItem.bind(this, oSelectedRowKeys)} icon="delete" disabled={!oAllowDel}>删除</Button>
                                    </div>
                                </div>
                                <div className="sp-content">
                                    <PcTable rowKey="id"
                                        rowSelection={oRowSelection}
                                        columns={oColumns}
                                        pagination={oPagination}
                                        dataSource={options}
                                        bordered={false}
                                    />
                                </div>
                            </Row>
                        </div>
                    </Modal>
                    <PcModal fieldsList={oFieldsList} modal={oModal} modalsLoading={oModal.modalsLoading} formItemLayout={oFormItemLayout} />
                </Spin>
            </div>
        )
    }
}

export default MatterVoting;
