import React from 'react';
import {Form, Input, Row, Col,Card,Button,message} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import { get,post } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import constantConfig from './../../../configs/constant.config';
import pcService from './../../../other/pc.service';
const FormItem = Form.Item;

class CreateTenantForm extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            formValueInit:{}
         };
    }

    componentWillMount() {
        if(this.props.location.query.editId)
        {  
            this.init(this.props.location.query.editId);
        }
    }

   componentWillReceiveProps(nextProps)
   {
       if(nextProps.editTenant&&nextProps.editTenant.tenantId!==this.props.editTenant.tenantId)
       {
           this.init(nextProps.editTenant);
       }
   }

   init=(tenantId)=>
   {

        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.tenant.findById,
            params:{
                tenantId:tenantId
            }
        }).then((res)=>{
            if(res)
            {
                this.setState({
                    loading:false,
                    formValueInit:{
                        ...res
                    }
                });
            }
        }).catch((error)=>{
            this.setState({loading:false});
        })
   }

    handleSubmit=(e)=>{
        e.preventDefault();
            this.props.form.validateFields((err, values) => {
            if (!err) {
                let data;
                let url;
                if(!this.props.location.query.editId)
                {
                    url= apiConfig.rui.basic.tenant.addByJson;
                    data={
                        ...values
                    }
                } 
                else
                {
                    url= apiConfig.rui.basic.tenant.editByJson;
                    data={
                        tenantId:this.state.formValueInit.tenantId,
                        ...values
                    }
                }
                this.setState({loading:true});
                post({
                    url,
                    data,
                    headers:{
                        'Content-Type':'application/json'
                    }
                }).then((res)=>{
                    message.success(constantConfig.SAVE_SUCCESS);
                    this.setState({loading:false});
                    this.goBack(true);
                }).catch((error)=>{
                    message.error(constantConfig.SAVE_FAIL);
                    this.setState({loading:false});
                });
            }
        });
   }

   handelCancel=()=>{
        this.goBack(false);
   }

    goBack=(isRefreshParent=false)=>{
        let panelId = pcService.getIdByPath("/app/tenantManage");
        pcService.removePanel(panelId,true,'/app/tenantList');
        //this.props.removeTabPane(this.props.paneKey,isRefreshParent);
    }

    render() {
        const { form } = this.props;
        const { loading,formValueInit }=this.state;
        const formItemLayout = {
          labelCol: {
              xs: { span: 24 },
              sm: { span: 6 }
  
          },
          wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 }
          }
        }  
      return (
            <Card bordered={false}>
              <Form  onSubmit={this.handleSubmit}>
                 <Row gutter={24}>
                        <Col span={12}>
                            <FormItem {...formItemLayout} label="租户名称">
                                {form.getFieldDecorator('tenantName', {
                                    initialValue: formValueInit.tenantName,
                                    rules: [
                                        {required: true, message: '请输入租户名称'},
                                    ],
                                })(<Input placeholder="请输入"/>)}
                            </FormItem>
                        </Col>
                         <Col span={12}>
                             <FormItem {...formItemLayout} label="租户代码">
                                 {form.getFieldDecorator('tenantCode', {
                                     initialValue: formValueInit.tenantCode,
                                     rules: [
                                         {required: true, message: '请输入租户代码'}
                                     ],
                                 })(<Input placeholder="请输入"/>)}
                             </FormItem>
                         </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24} className="operationArea">
                          <Button onClick={this.handelCancel}>取消</Button>
                          <Button type="primary" htmlType="submit" loading={loading}>保存</Button>
                      </Col>
                    </Row>
              </Form>
            </Card>
         )
      }
}

const mapStateToProps = state => {
    // const { menuTab } = state;
    return {
        
    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(CreateTenantForm));