import React, { Component } from 'react';
import { Input } from 'antd';
import './positionPicker.less';

const AMap = window.AMap;
const AMapUI = window.AMapUI;
let map;

class PositionPicker extends Component {


    state = {
        coordinateX: "",
        coordinateY: "",
        value: "",
        errorRange: 200,
        results: [],
        show: false,
        point: {
            position: {
                lat: "",
                lng: ""
            },
            address: "",
            nearestJunction: "",
            nearestRoad: "",
            nearestPOI: ""
        }
    };

    componentWillMount() {
        let { value } = this.props;
        //console.log("value", value);
        this.loadUI();
    }

    /**
     * 初始化地图
     * @param PositionPicker
     */
    initPage = (PositionPicker, position) => {
        let that = this;
        let { useCallback } = this.props;
        let { errorRange } = this.state;
        map = new AMap.Map('mapContainer', {
            zoom: 16,
            scrollWheel: true,
            center: [106.071027,30.790645], //初始地图中心点
        });
        let positionPicker = new PositionPicker({
            mode: 'dragMap',
            map: map
        });

        positionPicker.on('success', function (pr) {
            let point = {
                position: {
                    lng: pr.position.lng,
                    lat: pr.position.lat
                },
                address: pr.address,
                nearestJunction: pr.nearestJunction,
                nearestRoad: pr.nearestRoad,
                nearestPOI: pr.nearestPOI,

            }
            that.setState({
                point
            });
            useCallback && useCallback(point, errorRange);
        });
        positionPicker.on('fail', (positionResult) => {
            /* that.setState({
                point: positionResult
            }); */
        });

        if (position === null) {
            positionPicker.start();
        } else {
            positionPicker.start(position);
        }
    };

    /* 搜索change事件 */
    inputChange = ($event) => {
        let value = $event.target.value;
        let that = this;
        this.setState({ value });
        AMap.plugin('AMap.PlaceSearch', () => {
            const autoOptions = {
                city: '全国',
                pageSize: 10 // 单页显示结果条数
            };
            let placeSearch = new AMap.PlaceSearch(autoOptions);
            placeSearch.search(value, (status, result) => {
                //console.log("result", result);
                // 搜索成功时，result即是对应的匹配数据
                if (status === "complete") {
                    that.setState({
                        results: result.poiList.pois,
                        show: true
                    });
                }
            });
        });
    };

    /* 加载地图UI */
    loadUI() {
        AMapUI.loadUI(['misc/PositionPicker'], positionPicker => {
            this.initPage(positionPicker, null);
        });
    };

    /* 选择搜索结果 */
    checkOne = (item) => {
        let { useCallback } = this.props;
        let { errorRange } = this.state;
        const point = {
            position: {
                lat: item.location.lat,
                lng: item.location.lng
            },
            address: item.address,
            nearestJunction: item.nearestJunction,
            nearestPOI: item.nearestPOI,
            nearestRoad: item.nearestRoad
        };
        this.setState({
            value: item.name,
            show: false,
            point
        });
        useCallback && useCallback(point, errorRange);
        AMapUI.loadUI(['misc/PositionPicker'], positionPicker => {
            this.initPage(positionPicker, [point.position.lng, point.position.lat]);
        });
    }

    /* 显示搜索结果 */
    showAction = () => {
        let { show } = this.state;
        this.setState({
            show: !show
        })
    }

    /* 误差设置 */
    errorRangeChange = ($event) => {
        let { useCallback } = this.props;
        let { point } = this.state;
        let errorRange = $event.target.value;
        this.setState({
            errorRange,
        });
        useCallback && useCallback(point, errorRange);
    }

    render() {

        const { value, results = [], show, point, errorRange } = this.state;
        const { inputChange, checkOne, showAction, errorRangeChange } = this;
        const { position, address, nearestJunction, nearestPOI, nearestRoad } = point;
        const showList = show && results.length > 0;
        return (
            <div className="lab-map">
                <div id="mapContainer"></div>
                <div className="map-actions">
                    <div className="ma-uls">
                        <h3>输入选址</h3>
                        <p>
                            <Input onClick={showAction} placeholder="请输入地址进行搜索" value={value} onChange={inputChange} />
                        </p>
                        <h3>允许误差(米)</h3>
                        <p>
                            <Input placeholder="请输入误差范围" value={errorRange} onChange={errorRangeChange} />
                        </p>
                        <div className="sr-contaner" style={{ 'display': showList ? "block" : "none" }}>
                            <div className="src-abs">
                                <ul>
                                    {
                                        results.map((v, i) => <li key={i} onClick={checkOne.bind(this, v)} className="clickable">{v.name}</li>)
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="ma-uls">
                        <h3>选址结果</h3>
                        <p className="c">经纬度:</p>
                        <p>{position.lng} , {position.lat}</p>
                        <p className="c">地址:</p>
                        <p>{address}</p>
                        <p className="c">最近的路口:</p>
                        <p>{nearestJunction}</p>
                        <p className="c">最近的路:</p>
                        <p>{nearestRoad}</p>
                        <p className="c">最近的POI:</p>
                        <p>{nearestPOI}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default PositionPicker;