import React,{Fragment} from 'react';
import {Icon,Card,Popconfirm,message } from 'antd';
import {StandardTable} from '../../../sharedCommponents';
import {convertListToTreeJson} from '../../../common/utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import {isNotEmpty} from '../../../common/utils';
import { get,delHttp } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import SearchForm from './SearchForm';
import {ButtonAuth,ALinkAuth} from '../../../sharedCommponents/AuthWidget';
import appConfig from './../../../configs/app.config';
import pcService from './../../../other/pc.service';
import { hashHistory } from 'react-router';
import contantConfig from './../../../configs/constant.config';
class MenuList extends React.Component {

    state={
        loading:false,
        selectedRowKeys:[],
        selectedRows: [],
        data: 
        {
            list: [],
            pagination: {
                pageIndex: 0,
                pageSize: appConfig.maxPageSize,
                total:0
            }
        },
        searchInitValue:{
            keyword:''
        }
    }

    columns=[
        {
            title: '操作',
            width: 280,
            align: 'center',
            render: (text, record) => (!record.disabled&&
                <Fragment>
                    {!record.subMenu&&
                        <Fragment>
                            <ALinkAuth
                                // href="javascript:;"
                                isneeddivider={'true'}
                                authCode={'F645B3D4D53844149C82FD052B7AE365'}
                                onClick={() => this.handleSetAuthorities(record)}
                            >
                                所属功能
                            </ALinkAuth>
                        </Fragment>
                    }

                    <ALinkAuth
                        // href="javascript:;"
                        isneeddivider={'true'}
                        authCode={'63C7F69FDF06415ABA9B651864DFC01B'}
                        onClick={() => this.handleEdit(record)}
                    >
                        编辑
                    </ALinkAuth>

                    <Popconfirm title="您确定要删除吗?" onConfirm={()=>this.handleDelete(record)} okText="是" cancelText="否">
                        <ALinkAuth
                            // href="javascript:;"
                            authCode={'3EF1A93C4DA64C26B60B5B35850CF8FF'}
                        >
                            删除
                        </ALinkAuth>
                   </Popconfirm>
                </Fragment>
            )
        },
        {
            title: '名称',
            dataIndex: 'menuName'
        },
        {
            title: '图标',
            dataIndex: 'menuIcon',
            render: menuIcon => isNotEmpty(menuIcon)&&<Icon type={menuIcon} />,
        },
        {
            title: '链接',
            dataIndex: 'menuUrl'
        }
    ]

    componentWillMount()
    {
        this.fetch({});
    }


    componentWillReceiveProps(nextProps)
    {
        if(nextProps.reloadState&&nextProps.reloadState!==this.props.reloadState)
        {
            const params = {
                pagination:{
                    pageIndex: 0
                }
            };
            this.fetch(params);
        }
    }
 


    fetch=(params = {})=>{
        this.setState({loading:true});
        let {pagination}=this.state.data;
        let pageIndex=pagination.pageIndex;
        let pageSize=pagination.pageSize;
        let keyword=this.state.searchInitValue.keyword;
        if(params)
        {
            if(params.pagination)
            {
                pageIndex=params.pagination.pageIndex||0;
                pageSize=params.pagination.pageSize||pageSize;
            }
            if(!params.keyword)
            {
                keyword='';
            }
            else
            {
                keyword=params.keyword
            }
        }
        let queryParams={
            pageIndex,
            pageSize,
            keyword
        };
        get({
            url:apiConfig.rui.basic.menu.getAllMenus,
            params:queryParams
        }).then((res)=>{
            if(res)
            {
                res.list.map((item,index)=>{
                    if(item.menuType==='1')
                    {
                        item.disabled=true;
                    }
                    else
                    {
                        item.disabled=false;
                    }
                });
                let menusTreeData=convertListToTreeJson(res.list);
                this.setState({
                    loading:false,
                    data:{
                        list:menusTreeData,
                        pagination:{
                            pageIndex: queryParams.pageIndex,
                            pageSize: queryParams.pageSize,
                            total:res.total
                        }
                    },
                    searchInitValue:{
                        keyword:queryParams.keyword
                    }
                });
            }
            else
            {
                this.setState({ loading:false});
            }
        }).catch((error)=>{
            this.setState({ loading:false});
        });
    } 

    handleSelectRows = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRows,
            selectedRowKeys
        });
    };

    handleStandardTableChange = pagination => {
        const params = {
            pagination:{
                pageIndex: pagination.current-1,
                pageSize: pagination.pageSize
            }
        };
        this.fetch(params);
    };

    handleSearch=(seacheValues)=>{
        let params;
        if(seacheValues)
        {
           params = {
               pagination:{
                   pageIndex: 0
               },
               keyword:seacheValues.keyword
           };
        }
        else
        {
           params = {
               pagination:{
                   pageIndex: 0
               },
               keyword:''
           };
        }
        this.fetch(params);
    }

    handleDelete=(menu)=>{
        this.setState({loading:true});
        delHttp({
            url:apiConfig.rui.basic.menu.deleteMenu,
            params:{
                menuId:menu.menuId
            }
        }).then((res)=>{
            const params = {
                pagination:{
                    pageIndex: 0
                }
            };
            message.success(contantConfig.DEL_SUCCESS);
            this.fetch(params);
        }).catch((error)=>{
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading:false});
         });
    }

    handleDeleteAll=()=>{
        let {selectedRowKeys}=this.state;
        let menuIds='';
        if(selectedRowKeys.length===0)
        {
            message.info('请选择要删除的菜单');
            return;
        }
        for(let selectedRowKey of selectedRowKeys)
        {
            menuIds+=selectedRowKey+',';
        }
        if(menuIds)
        {
            menuIds=menuIds.substr(menuIds,menuIds.length-1);
        }
        this.setState({loading:true});
        delHttp({
            url:apiConfig.rui.basic.menu.deleteAll,
            params:{
                menuIds:menuIds
            }
        }).then((res)=>{
            this.setState({
                selectedRows:[],
                selectedRowKeys:[]
            },()=>{
                const params = {
                    pagination:{
                        pageIndex: 0
                    }
                };
                message.success(contantConfig.DEL_SUCCESS);
                this.fetch(params);
            });
        }).catch((error)=>{
            message.error(contantConfig.DEL_FAIL);
            this.setState({ loading:false});
         });
    }

    handleAdd=()=>{
        /*let { selectedMenuKey }=this.props;
        let paneKey="add-menu-pane";
        let CreateMenuForm=AllComponents["CreateMenuForm"];
        let addPane=getTabPaneData({
            key: paneKey,
            title:"新增菜单",
            content: <CreateMenuForm paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        //this.props.updateTabPane(addPane);*/
        pcService.relativePath('/app/menuManage');
    }

    handleEdit=(menu)=>{   
       /* let { selectedMenuKey }=this.props;
        let CreateMenuForm=AllComponents["CreateMenuForm"];
        let paneKey="edit-menu-pane";
        let addPane=getTabPaneData({
            key: paneKey,
            title:"编辑菜单",
            content: <CreateMenuForm editId={menu.menuId} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        this.props.updateTabPane(addPane);*/
        hashHistory.push('/app/menuManage?editId='+menu.menuId);
        //.props.history.push({pathname:"/app/menuManage"});
    }

    handleSetAuthorities=(menu)=>{
        /*let { selectedMenuKey }=this.props;
        let SetAuthorities=AllComponents["SetAuthorities"];
        let paneKey="set-menuAuthorities-pane";
        let addPane=getTabPaneData({
            key: paneKey,
            title:"设置菜单所属功能",
            content: <SetAuthorities menu={menu} paneKey={paneKey}/>,
            isWebDefault: false,
            closable:true,
            isMenu:false,
            refKey:selectedMenuKey,
            isExternal:false
          });
        this.props.updateTabPane(addPane);*/
        pcService.relativePath('/app/menuSetAuthorities?setId='+menu.menuId);
    };

    render() {
        const {loading, data,searchInitValue} = this.state;
        //console.log("data",data);
        return (
           <div className="mainDiv">
               <Card bordered={false}>
                   <div className='tableSearchForm'>
                            <SearchForm
                                searchKey={'关键字'}
                                handleSearch={this.handleSearch}
                                searchInitValue={searchInitValue}
                            />
                   </div>
                   <div className='tableAction'>
                        <ButtonAuth
                                icon="plus"
                                type="primary"
                                dataaction="create"
                                onClick={() => this.handleAdd()}
                                authCode={'1CB8A9CB0ECB447B91F92B3B2C8F4A63'}
                            >
                                新增
                        </ButtonAuth>
                        <Popconfirm title="您确定要删除选中的菜单吗?" onConfirm={()=>this.handleDeleteAll()} okText="是" cancelText="否">
                                        <ButtonAuth
                                                icon="delete"
                                                type="primary"
                                                dataaction="delete"
                                                loading={loading}
                                                authCode={'3ECFB657811246B79E21E1423289CEB7'}
                                            >
                                                批量删除
                                        </ButtonAuth>
                       </Popconfirm>
                   </div>
                   <StandardTable
                            disableSelect={false}
                            loading={loading}
                            rowKey="menuId"
                            data={data}
                            columns={this.columns}
                            childrenColumnName="subMenu"
                            isNeedeSelectedKeys={true}
                            isTreeTable={true}
                            onSelectRow={this.handleSelectRows}
                            onChange={this.handleStandardTableChange}
                        />
               </Card>
           </div>
         )
      }
}


const mapStateToProps = state => {
    const { menuTab } = state;
    return {
        selectedMenuKey:menuTab.selectedMenuKey
    }
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(MenuList);