import {Component} from 'react';
function inject_unount (target){
    // 改装componentWillUnmount，销毁的时候记录一下
    let next = target.prototype.componentWillUnmount;
    target.prototype.componentWillUnmount = function () {
        if (next) next.call(this, ...arguments);
        this.unmount = true
     };
     // 对setState的改装，setState查看目前是否已经销毁
    let setState = target.prototype.setState;
    target.prototype.setState = function () {
        if ( this.unmount ) return ;
        setState.call(this, ...arguments)
    }
}
/**
 * 封装基类组件
 * 继承该基类可以防止内存泄漏警告
 */

class BaseComponent extends Component {

}

export default BaseComponent;