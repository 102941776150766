import React from 'react';
import {get} from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import AuthorityList from './AuthorityList';
export default class SetAuthorities extends React.Component {

    state={
      menu:{
          menuId:this.props.location.query.setId
      }
    }
    render() {
      return (
             <AuthorityList {...this.state} />
         )
      }
}