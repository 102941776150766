import React, { Component } from 'react';
import { Spin, message, Button } from 'antd';
import { hashHistory } from 'react-router';
import PcService from './../../other/pc.service';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import Forms from './../../common/components/form/Forms';
import AttachList from './AttachList';
import axios from "axios";
import './targetedPovertyReduction.css';

export class TargetedPovertyReductionUpload extends Component {
    state = {
        loading: false, 
        finish: false,
    }

    isAble = [
        { id: true, name: '是' },
        { id: false, name: '否' }
    ]

    fieldsList = [
        {
            name: "activityTitle",
            editor: "normal",
            displayName: "活动名称",
        }, {
            name: "withSecret",
            displayName: "是否涉密",
            value: "",
            enumsArr: this.isAble
        }, {
            name: "ableMakeUp",
            displayName: "是否可以补课",
            value: "",
            enumsArr: this.isAble
        }, {
            name: "publishStatus",
            displayName: "发布状态",
            value: "",
            enumsArr: PcService.getDataDictionary("PUBLISH_STATUS")
        }, {
            name: "activityStatus",
            displayName: "活动状态",
            value: "",
            enumsArr: PcService.getDataDictionary("ACTIVITY_STATUS")
        }, {
            name: "signPlace",
            displayName: "签到地址",
            value: "",
        }, {
            name: "activityPlace",
            displayName: "活动地点",
            value: "",
        }, {
            name: "dataProcessorName",
            displayName: "活动资料员",
            value: "",
        }, {
            name: "publisherName",
            displayName: "负责人",
            value: "",
        }, {
            name: "teacherName",
            displayName: "授课老师",
            value: "",
        }, {
            name: "orgUnitName",
            displayName: "活动组织单位",
            value: "",
        }, {
            name: "activityBeginTime",
            displayName: "活动开始时间",
            value: "",
        }, {
            name: "activityEndTime",
            displayName: "活动结束时间",
            value: "",
        }, {
            name: "activityType",
            displayName: "活动类型",
            value: "",
            enumsArr: PcService.getDataDictionary("ACTIVITY_TYPE")
        }, {
            name: "signBeginTime",
            displayName: "签到开始时间",
            value: "",
        }, {
            name: "signEndTime",
            displayName: "签到结束时间",
            value: "",
        }, {
            name: "userIds",
            displayName: "参会人员",
            value: "",
        }
    ];
    fieldsListPlan = [
        {
            name: "activityDescription",
            displayName: "活动说明",
            value: "",
        },
        {
            name: "activityAttachments",
            displayName: "活动材料",
            value: "",
        }
    ];
    fieldsListRecord = [
        {
            name: "id",
            editor: "hidden",
            value: "", 
            displayName: "id", 
        },
        {
            name: "record",
            editor: "bEditor",
            value: "",
            columns: 1,
            displayName: "活动记录",
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
        },
        {
            name: "recordAttachments",
            editor: "attachments",
            value: [],
            originValue: [],
            displayName: "上传附件",
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 3 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 21 }
                }
            },
            fileUpLoadOption: {
                action: urls.file.uploadGetId,
                onChange: (fileList, form, _onchange) => {
                    let _value = fileList.map(v => ({
                        fileId: v.response,
                        fileName: v.name,
                        fileSize: v.size,
                        uid: v.response
                    }));
                    _onchange(_value);
                }
            }
        }
    ]; 
    handleCancel = () => { 
        let panelId = PcService.getIdByPath("/app/targetedPovertyReductionUpload");
        PcService.removePanel(panelId);
    }
    /* 获取form参数*/
    onBaseSubmit = (form, $event) => {
        let data = {}, errs = {}, isStop = false;
        $event.preventDefault();
        form.validateFields((err, fieldsValue) => {
            data = fieldsValue;
            errs = err;
        });
        for (let v in errs) {
            if (errs[v]) {
                isStop = true;
            }
        }
        if (isStop) {
            return 'stop';
        } else {
            return data;
        }
    };
    handleSave = ($event) => {
        const newRecordForms = this.refs.newRecordForms.getForm();
        let submitRecord = this.onBaseSubmit(newRecordForms, $event);
        submitRecord.id = this.props.location.query.recordId; 
        if (submitRecord) {
            //修改
            let url = urls.app.partyActivity.supplement;
            let _params = Object.assign({}, submitRecord);
            this.setState({ loading: true });
            PcService.formSubmit(url, true, _params, null, (data) => {
                message.success("操作成功!");
                //console.log(data)
                this.setState({ loading: false });
                let panelId = PcService.getIdByPath("/app/targetedPovertyReductionUpload");
                PcService.removePanel(panelId, true, '/app/targetedPovertyReduction');
            }, (err) => {
                message.error(err);
                this.setState({ loading: false });
            });
        }
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        let that = this;
        this.setState({ loading: true, });
        http.request({
            method: 'get',
            url: urls.app.partyActivity.findById,
            params: { id },
        }).then(res => {
            //console.log(res)
            if (res.success === true) {
                let record = res.data;
                //处理参会人员
                let userID = "";
                record.userIds.map(v => userID += v.name + ",");
                record.userIds = userID;
                //处理活动类型
                record.activityType = record.activityType.split(",");
                PcService.bindFormdData(record, this.fieldsList);
                PcService.bindFormdData(record, this.fieldsListPlan);
                PcService.bindFormdData(record, this.fieldsListRecord);

                // that.fileToList(record);
                that.setState({
                    loading: false
                });
            } else {
                message.error(res.message);
                that.setState({
                    loading: false
                });
            }
            that.setState({
                finish: true,
            });
        }).catch(err => {
            message.error(err.message);
            that.setState({
                loading: false
            });
        });
    }

    render() {
        const { loading, finish } = this.state;
        const { fieldsListRecord = [], handleCancel, handleSave, onBaseSubmit } = this;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };

        return (
            <Spin spinning={loading} className="">
                <div>
                    <div>
                        <div className="panew-marin-tipinfo">
                            <span className="panew-main-tip" />
                            <label > 活动基础信息</label>
                        </div>
                        <div className="padetail-form">
                            <div className="padetail-detail" style={{ clear: 'both' }}>
                                {
                                    this.fieldsList.map((item) => {
                                        return (
                                            <div className="padetail-detail-list" key={item.name} >
                                                <div className="padetail-detail-item-base" value={item.value}>{item.displayName}：{item.enumsArr ? PcService.numberToText(item.value, item.enumsArr) : item.value}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div> 
                    <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label > 活动安排</label>
                            </div>
                            <div className="padetail-form">
                                <div className="padetail-detail" style={{ clear: 'both' }}>
                                    {
                                        this.fieldsListPlan.map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    {
                                                        item.name !== "activityAttachments" ?
                                                            <div className="padetail-detail-list" key={item.name} >
                                                                {
                                                                    item.name === "activityDescription" || item.name === "record" ? <div dangerouslySetInnerHTML={{ __html: item.displayName + "：" + item.value }}></div>
                                                                        : <div className="padetail-detail-item-file" value={item.value}>{item.displayName}：<div className="meetingDesctrib">{item.value}</div></div>
                                                                }
                                                            </div> :
                                                            <AttachList itemAttach={item.value} itemName={"材料附件"} />
                                                    }
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    <div>
                        <div>
                            <div className="panew-marin-tipinfo">
                                <span className="panew-main-tip" />
                                <label > 活动记录</label>
                            </div>
                            <div className="panew-form">
                            {finish ? <Forms ref="newRecordForms" onSubmit={onBaseSubmit} columns="1" fieldsList={fieldsListRecord} hideFooter formItemLayout={formItemLayout} /> : null}
                            </div>
                        </div>
                        <div className="panew-footer-button" >
                            <Button type="default" style={{ marginRight: '8px' }} onClick={handleCancel}>取消</Button>
                            <Button type="primary" onClick={handleSave}>保存</Button>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}

export default TargetedPovertyReductionUpload
