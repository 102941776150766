import React, { Component } from 'react';
import { Upload, Icon, Button } from 'antd';

export class FileUpload extends Component {

    render() {
        const { value, onChange, item, form, disabled = false } = this.props;
        const { fileUpLoadOption = {} } = item;
        const { listType, loading = false, imageUrl = "", accept } = fileUpLoadOption;
        /*  */
        const fileOnChange = (files) => {
            fileUpLoadOption.onChange && fileUpLoadOption.onChange(files, form, onChange, value)
        }
        const UploadButton = () => (
            <div>
                <Icon type={loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">选择文件</div>
            </div>
        );
        /* 自定义上传*/
        const mycustomRequest = (option) => {
            if (fileUpLoadOption.customRequest) {
                fileUpLoadOption.customRequest(option, form, onChange)
            }
        }
        return (
            <Upload disabled={disabled} supportServerRender showUploadList={{ showRemoveIcon: disabled }} {...fileUpLoadOption} value={value} onChange={fileOnChange} customRequest={fileUpLoadOption.customRequest ? mycustomRequest : null} >
                {listType === "picture-card" ?
                    (
                        <div>
                            {imageUrl ? (accept === "video/*" ? <video width="200" height="100" src={imageUrl} /> : <img src={imageUrl} alt="avatar" />) : <UploadButton />}
                        </div>
                    ) : (
                        <Button disabled={disabled}><Icon type="upload" /> 选择文件</Button>
                    )
                }
            </Upload >
        )
    }
}

export default FileUpload;
