import React, { Component } from 'react';
import PcTable from '../../common/components/table/tableComponent';
import urls from '../../configs/api.config';
import PcForm from '../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import SearchList from './SearchList';
import { Spin, message, Row, Button, Divider, Icon, Popconfirm } from 'antd';
import pcService from '../../other/pc.service';
import http from '../../common/axios/request';
import './index.less';

const oneColumns = {
    columns: 1,
    formItemLayout: {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 2 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 22 }
        }
    }
}

export class booksManagement extends Component {

    state = {
        loading: false,
        create: false,
        hasgetColumns: false,
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        activeColumnId: "",
        activefColumnId: "",
        activesColumnId: "",
        lists: [],
        modalOptions: {
            title: "电子书",
            visible: false,
            destroyOnClose: true,
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 1100
        },
        columnList: [
            { id: "", name: "全部" },
        ],
        fColumnList: [
            { id: "", name: "全部" }
        ],
        sColumnList: [
            { id: "", name: "全部" }
        ]
    }

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "keyword",
        editor: "normal",
        value: "",
        originValue: "",
        displayName: "关键字",
        hideDisplayName: true
    }];

    /* 表格列项 */
    columns = [{
        title: '序号',
        dataIndex: 'key1',
        align: 'center',
        width: 60,
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 260,
        render: (text, record) => {
            return <span>
                <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                <Divider type="vertical" />
                <Popconfirm title={`您确定要${record.enabled ? "下架" : "上架"}吗?`} onConfirm={() => this.toggleItem(record)} okText="确定" cancelText="取消">
                    <a><Icon type={record.enabled ? "lock" : "unlock"} theme="outlined" /> {record.enabled ? "下架" : "上架"}</a>
                </Popconfirm>
                <Divider type="vertical" />
                <a onClick={() => this.connectItem(record)}><Icon type="message" theme="outlined" /> 书评</a>
            </span>
        }
    }, {
        title: '书名',
        dataIndex: 'name'
    }, {
        title: '所属栏目',
        dataIndex: 'columnName'
    },/*  {
        title: '二级栏目',
        dataIndex: 'photo',
        render: text => !!text ? <image src={``} /> : ""
    }, */ {
        title: '作者',
        dataIndex: 'author'
    }, {
        title: '下载数',
        dataIndex: 'downloadCount'
    }, {
        title: '在线阅读数',
        dataIndex: 'readCount'
    }, {
        title: '收藏数',
        dataIndex: 'collectCount'
    }, {
        title: '书评数',
        dataIndex: 'commentCount'
    }, {
        title: '状态',
        dataIndex: 'enabled',
        render: text => text ? "上架" : "下架"
    }];

    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'name',
        displayName: '电子书名称',
        editor: '',
        value: '',
        originValue: '',
        rules: ["required"]
    }, {
        name: 'columnId',
        displayName: '所属栏目',
        editor: 'node-select',
        nodeOptions: {
            isTreeData: true,
            treeCheckable: true
        },
        value: "",
        originValue: [],
        opts: [],
        rules: ["required"]
    }, {
        name: 'author',
        displayName: '作者',
        editor: 'normal',
        value: '',
        originValue: '',
        rules: []
    }, {
        name: 'tags',
        displayName: '检索关键字',
        editor: 'normal',
        value: '',
        originValue: ''
    }, {
        name: 'publisher',
        displayName: '出版社',
        editor: 'normal',
        value: '',
        originValue: "",
        rules: []
    }, {
        name: 'publishDate',
        displayName: '出版日期',
        editor: 'datePicker',
        value: '',
        timeOptions: {
            format: "YYYY-MM-DD",
            showTime: false,
        },
        originValue: "",
        rules: []
    }, {
        name: 'format',
        displayName: '文件类型',
        editor: 'select',
        value: '',
        originValue: pcService.getDataDictionary("FILE_TYPE")[0] ? pcService.getDataDictionary("FILE_TYPE")[0].id : "",
        rules: [],
        opts: pcService.getDataDictionary("FILE_TYPE")
    }, {
        name: 'enabled',
        displayName: '状态',
        editor: 'select',
        value: '',
        originValue: true,
        opts: [{
            id: true, name: "上架"
        }, {
            id: false, name: "下架"
        }],
        rules: []
    }, {
        name: 'introduction',
        displayName: '图书简介',
        editor: 'textarea',
        value: '',
        originValue: "",
        rules: [],
        ...oneColumns,
    }, {
        name: "logo",
        editor: "pictureWall",
        value: "",
        displayName: "封面图",
        originValue: "",
        fileUpLoadOption: {
            action: urls.app.ebook.bookUpload,
            imageUrl: "",
            loading: false,
            listType: "picture-card",
            showUploadList: false,
            onPreview: (...args) => {
                //console.log(args);
            },
            accept: "image/*",
            beforeUpload: (file) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('图片必须小于2MB!');
                }
                return isLt2M;
            },
            onChange: (info, form, _onchange) => {
                let _value = info.fileList.map(v => v.response.data);
                _onchange(_value.join(","));
            }
        },
        ...oneColumns,
        rules: [
            {
                required: true,
                message: "请上传封面图"
            }
        ]
    }, {
        name: "book",
        editor: "attachments",
        value: "",
        displayName: "上传电子书",
        originValue: "",
        fileUpLoadOption: {
            action: urls.app.ebook.bookUpload,
            imageUrl: "",
            loading: false,
            listType: "picture-card",
            showUploadList: false,
            maxSize: 200,
            onPreview: (...args) => {
                //console.log(args);
            },
            accept: "application/epub+zip,application/pdf,text/plain",
            beforeUpload: (file) => {
                const isLt2M = file.size / 1024 / 1024 < 200;
                if (!isLt2M) {
                    message.error('图片必须小于200MB!');
                }
                return isLt2M;
            },
            onChange: (fileList = [], form, _onchange) => {
                let routeUrl = fileList.map(v => v.response.data).join(",");
                form.setFieldsValue({ routeUrl })
                _onchange("");
            }
        },
        ...oneColumns
    }, {
        name: 'routeUrl',
        displayName: '访问地址',
        editor: '',
        value: '',
        unavailable: true,
        originValue: "",
        rules: [],
        ...oneColumns,
    }];

    /* 查看书评 */
    connectItem = (item) => {
        pcService.relativePath(`/app/bookConnect?id=${item.id}&name=${item.name}`)
    }

    /* 下架 */
    toggleItem = (item) => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "post",
                url: urls.app.ebook.bookChangeEnabled,
                params: { enabled: !item.enabled, id: item.id }
            }).then(res => {
                if (res.success) {
                    message.success((item.enabled ? "下架" : "上架") + "操作成功！")
                    this.setState({ loading: false }, this.componentDidMount);
                } else {
                    this.setState({ loading: false });
                }
            }).catch(error => {
                message.error(error.message);
                this.setState({ loading: false });
            })
        });
    }

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.singlyFetchData(values);
            }
        });
    }

    /* 新增/编辑书籍 */
    createOne = (_item, create) => {
        const item = { ..._item };
        const { hasgetColumns } = this.state;
        const { modalOptions } = this.state;
        let promise = new Promise((resolve) => {
            if (hasgetColumns) {
                resolve.call();
            } else {
                this.getColumnFindTree(resolve)
            }
        })
        promise.then(() => {
            if (create) {
                pcService.initFormList(this.fieldsList);
            } else {
                pcService.bindFormdData(item, this.fieldsList);
            }
            this.setState({
                create,
                modalOptions: { ...modalOptions, visible: true, modalsLoading: false }
            })
        })
    }

    /* singlyFetchData */
    singlyFetchData = (param = {}) => {
        const { activeColumnId, activesColumnId, activefColumnId, pagination } = this.state;
        const { current, pageSize } = pagination;
        if (!param.columnId) {
            param.columnId = activeColumnId ? activeColumnId : (activesColumnId ? activesColumnId : activefColumnId)
        }
        let forms = this.refs.pcForm;
        forms.validateFields((errors, values) => {
            if (!errors) {
                this.setState({ loading: true })
                http.request({
                    method: "get",
                    params: { ...values, ...param, page: current - 1, size: pageSize },
                    url: urls.app.ebook.bookPage
                }).then(res => {
                    if (res.success) {
                        const lists = res.data.rows || [];
                        const _pagination = res.data ? {
                            current: res.data.pageIndex + 1,
                            pageSize: res.data.pageSize,
                            total: res.data.totalCount
                        } : {};
                        this.setState({ lists, loading: false, pagination: { ...pagination, ..._pagination } })
                    } else {
                        this.setState({ loading: false })
                    }
                }).catch(err => {
                    this.setState({ loading: false })
                })
            }
        });
    }

    columnClick = (item, level) => {
        const { activeColumnId, activefColumnId, activesColumnId } = this.state;
        switch (level) {
            case 0:
                this.setState({ activeColumnId: item.id, activefColumnId: "", activesColumnId: "" }, () => {
                    this.singlyFetchData({ columnId: item.id });
                })
                break;
            case 1:
                this.setState({ activeColumnId: "", activefColumnId: item.id, activesColumnId: "" }, () => {
                    this.getColumnsByParentId(item.id, level);
                    this.singlyFetchData({ columnId: item.id });
                })
                break;
            case 2:
                this.setState({ activeColumnId: "", activesColumnId: item.id }, () => {
                    let columnId = item.id;
                    if (!item.id) {
                        columnId = activefColumnId;
                    }
                    this.singlyFetchData({ columnId });
                })
                break;
            default:
                break;
        }
    }

    /* 批量导入图书 */
    importMore = () => {
        pcService.relativePath("/app/bookImport");
        /*  const a = document.createElement("a");
         a.href = "#/app/bookImport";
         a.click(); */
    }

    onCancel = () => {
        const { modalOptions } = this.state;
        this.setState({
            modalOptions: { ...modalOptions, visible: false, modalsLoading: false }
        })
    }

    /* 根据parentId获取下级栏目 */
    getColumnsByParentId = (parentId, type) => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            params: { parentId, type: type + 1 },
            url: urls.app.ebook.columnPage
        }).then(res => {
            if (res.success) {
                const sColumnList = [{ id: "", name: "全部" }].concat(res.data.rows || []);
                this.setState({
                    sColumnList,
                    loading: false
                })
            } else {
                this.setState({ loading: false })
            }
        }).catch(err => {
            this.setState({ loading: false })
        })
    }

    fetchData = (params = {}) => {
        const { activeColumnId, activesColumnId, pagination } = this.state;
        const { pageSize, current } = pagination;
        const columnId = activeColumnId ? activeColumnId : activesColumnId;
        //sortField: "sortNum", sortType: "ASC"
        let _params = Object.assign({}, { pageSize, current, columnId, sortField: "readCount", sortType: "ASC" }, params);
        _params.size = _params.pageSize ? _params.pageSize : pageSize;
        _params.page = _params.current ? _params.current - 1 : current - 1;
        _params["pageSize"] = undefined;
        _params["current"] = undefined;
        this.setState({ loading: true }, () => {
            http.all([
                http.request({
                    method: "get",
                    url: urls.app.ebook.columnPage,
                    params: { type: 1 }
                }),
                http.request({
                    method: "get",
                    url: urls.app.ebook.bookPage,
                    params: _params
                }),
                http.request({
                    method: "get",
                    url: urls.app.ebook.columnPage,
                    params: { type: 2 }
                }),
                http.request({
                    method: "get",
                    url: urls.app.ebook.columnPage,
                    params: { type: 0 }
                })
            ]).then(reses => {
                const { rows = [] } = reses[0].data;
                const lists = reses[1].data ? reses[1].data.rows : [];
                const _pagination = reses[1].data ? {
                    current: reses[1].data.pageIndex + 1,
                    pageSize: reses[1].data.pageSize,
                    total: reses[1].data.totalCount
                } : {};
                const sColumnList = reses[2].data ? reses[2].data.rows : [];
                const columnList = reses[3].data ? reses[3].data.rows : [];
                this.fieldsList.map(v => {
                    if (v.name === "parentId") v.opts = [{ id: "0", name: "无" }].concat(rows);
                })
                this.setState({
                    loading: false,
                    lists,
                    pagination: { ...pagination, ..._pagination },
                    fColumnList: [{ id: "", name: "全部" }].concat(rows),
                    sColumnList: [{ id: "", name: "全部" }].concat(sColumnList),
                    columnList: [{ id: "", name: "全部" }].concat(columnList)
                })
            }).catch(errors => {
                this.setState({ loading: false });
            })
        });
    }

    /* 获取栏目树形结构数据 */
    getColumnFindTree = (resovle) => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.ebook.columnFindTree
        }).then(res => {
            if (res.success) {
                const opts = res.data || [];
                this.fieldsList.map(v => {
                    if (v.name === "columnId") {
                        v.opts = opts;
                    }
                })
                this.setState({ loading: false, hasgetColumns: true }, resovle);
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    }

    /* reloadData */
    reloadData = () => {
        const forms = this.refs.pcForm;
        this.searchOnSubmit(forms);
    }

    /* 新增编辑提交回调函数 */
    onOk = (forms) => {
        forms.validateFields((errors, values) => {
            if (!errors) {
                const { modalOptions, create } = this.state;
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } });
                let url = urls.app.ebook[create ? "bookAdd" : "bookUpdate"]
                http.request({
                    method: "post",
                    data: {
                        ...values,
                        columnId: values.columnId instanceof Array ? values.columnId.join(",") : values.columnId,
                        book: undefined,
                        publishDate: values.publishDate ? pcService.dateFormat(values.publishDate, "yyyy-MM-dd") : ""
                    },
                    url
                }).then(res => {
                    if (res.success) {
                        message.success((create ? "新增" : "编辑") + "操作成功!");
                        this.setState({ modalOptions: { ...modalOptions, modalsLoading: false, visible: false } }, this.reloadData);
                    } else {
                        message.error(res.message);
                        this.setState({ modalOptions: { ...modalOptions, modalsLoading: false, visible: true } });
                    }
                }).catch(err => {
                    this.setState({ modalOptions: { ...modalOptions, modalsLoading: false, visible: true } });
                })
            }
        })
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
    }

    componentDidMount() {
        let forms = this.refs.pcForm;
        forms.validateFields((err, values) => {
            !!values && this.fetchData(values);
        })
    }


    render() {
        const { loading, pagination, activeColumnId, activefColumnId, activesColumnId, lists, columnList = [], create, fColumnList, sColumnList, modalOptions } = this.state;
        const { onOk, onCancel, columns, fetchData, columnClick, searchOnSubmit, searchFieldsList, fieldsList = [], createOne, importMore } = this;
        const modal = { ...modalOptions, title: (create ? "新增" : "编辑") + modalOptions.title, onOk, onCancel };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="booksManagement">
                    {/*<PcTitle icon="book">图书管理</PcTitle> */}
                    <SearchList label="专栏" level={0} activeColumnId={activeColumnId} searchList={columnList} onCellClick={columnClick} />
                    <SearchList label="一级栏目" level={1} activeColumnId={activefColumnId} searchList={fColumnList} onCellClick={columnClick} />
                    <SearchList label="二级栏目" level={2} activeColumnId={activesColumnId} searchList={sColumnList} onCellClick={columnClick} />
                    <Row className="bookstore-section">
                        <div className="op-navs">
                            <div className="opn-left">
                                <Button onClick={createOne.bind(this, null, true)} icon="file-add" type="primary">新增图书</Button>
                                <Divider type="vertical" />
                                <Button onClick={importMore} icon="upload" type="primary">导入图书</Button>
                            </div>
                            <div className="opn-right">
                                <PcForm ref="pcForm" layout="inline" submitText="搜索" onSubmit={searchOnSubmit} fieldsList={searchFieldsList} />
                            </div>
                        </div>
                    </Row>
                    <PcTable
                        //rowSelection={rowSelection}
                        columns={columns}
                        pagination={pagination}
                        dataSource={lists}
                        fetchData={fetchData}
                        bordered={false}
                    />
                </div>
                <PcModal formItemColumns="2" fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
            </Spin>
        )
    }
}

export default booksManagement
