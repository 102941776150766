import React, { Component } from 'react';
import PcTitle from '../../common/components/title/Titles';
import PcForm from '../../common/components/form/Forms';
import PcService from '../../other/pc.service';
import PcTable from '../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import urls from '../../configs/api.config';
import { Row, Button, Divider, Spin, Icon, message } from 'antd';
import http from '../../common/axios/request';
import { isNotEmpty } from '../../common/utils';
import './dataDictionary.less';

class DataDictionaryDetial extends Component {

    state = {
        loading: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "数据字典",
            visible: false,
            destroyOnClose: true,
            maskClosable: false,
            centered: true,
            modalsLoading: false
        },
        lists: []
    }

    /* 表格列项 */
    columns = [{
        title: '序号',
        dataIndex: 'key1',
        width: 60,
        fixed: "left",
        render: (text, record, index) => (
            <div>
                {index + 1}
            </div>
        )
    }, {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 260,
        fixed: "left",
        render: (text, record, event) => {
            return (
                <span>
                    <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 修改</a>
                </span>
            )
        }
    }, {
        title: '字典值',
        dataIndex: 'dictionaryKey',
        key: 'dictionaryKey'
    }, {
        title: '字典标签',
        dataIndex: 'dictionaryValue',
        key: 'dictionaryValue'
    }, {
        title: '排序号',
        dataIndex: 'sort',
        key: 'sort',
        //sorter: (a, b) => a.sort - b.sort
    }, {
        title: '描述',
        dataIndex: 'description',
        key: 'description'
    }];

    /* 新增修改字段 */
    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'parentId',
        value: '',
        editor: 'hidden'
    }, {
        name: 'dictionaryValue',
        displayName: '字典标签',
        editor: 'normal',
        value: '',
        originValue: '',
        rules: [{
            required: true,
            message: "请输入字典标签"
        }]
    }, {
        name: 'dictionaryKey',
        displayName: '字典值',
        editor: 'normal',
        value: '',
        originValue: '',
        rules: [{
            required: true,
            message: "请输入字典值"
        }]
    }, {
        name: 'description',
        displayName: '描述',
        editor: 'textarea',
        value: '',
        originValue: ''
    }, {
        name: 'sort',
        displayName: '排序',
        editor: 'number',
        value: '0',
        originValue: '',
        range: [0, 20]
    }];

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "dictionaryKey",
        editor: "normal",
        value: "",
        originValue: "",
        hideDisplayName: true,
        displayName: "字典KEY"
    }];

    /* 字典详情 */
    dataItem = (item) => {

    }

    /* radio onChange事件 */
    onChangeCallBack = (from, value) => {
        if (value === 2) {
            this.fieldsList.map(v => {
                if (v.name === "date") {
                    v.editor = "hidden";
                }
            });
        } else {
            this.fieldsList.map(v => {
                if (v.name === "date") {
                    v.editor = "normal";
                }
            });
        }
        this.setState({});
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 打印快递单 */
    printExpress = (item) => {
        let { expressModalOptions } = this.state;
        this.setState({
            expressModalOptions: {
                ...expressModalOptions,
                visible: true
            }
        });
    }

    /* 打印卷宗封面 */
    printBooks = (item) => {
        let { booksModalOptions } = this.state;
        this.setState({
            booksModalOptions: {
                ...booksModalOptions,
                visible: true
            }
        });
    }

    /* 获取列表数据 */
    fetchData = (params) => {
        let { query = {} } = this.props.location;
        let { parentId = "" } = query;
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current, parentId, sortField: "sort", sortType: "ASC" }, params);
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.dataDictionary.dataDictionaryList, _params, null,
                (data) => {
                    let _pagination = PcService.getPagination(data, pagination);
                    this.setState({
                        loading: false,
                        lists: data.rows,
                        pagination: _pagination
                    });
                },
                () => {
                    this.setState({
                        loading: false
                    })
                }
            );
        });

    }

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.fetchData(values);
            }
        });
    }

    /* 启用/停用 */
    toggleItem = (item) => {

    }

    /* 新增一键点亮 */
    createOne = (item, create) => {
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            PcService.bindFormdData(item, this.fieldsList);
        } else {
            _create = true;
            PcService.initFormList(this.fieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = "", method = "post", msg = "";
                if (create) {
                    url = urls.app.dataDictionary.dataDictionaryAdd;
                    msg = "新增";
                } else {
                    url = urls.app.dataDictionary.dataDictionaryUpdate;
                    msg = "修改";
                }
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                });
                http.request({
                    method,
                    url,
                    data: values
                }).then(res => {
                    if (res.success) {
                        message.success(msg + "操作成功!");
                        this.setState({
                            modalOptions: {
                                ...modalOptions,
                                modalsLoading: false,
                                visible: false
                            }
                        }, this.fetchData);
                    } else {
                        message.error(res.message);
                        this.setState({
                            modalOptions: {
                                ...modalOptions,
                                modalsLoading: false
                            }
                        });
                    }

                }).catch(err => {
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    });
                })
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 删除 */
    deleteItem = (selectedRowKeys) => {
        let ids = selectedRowKeys.join(",");
        let that = this;
        this.setState({ loading: true });
        PcService.deleteItem(urls.app.dataDictionary.dataDictionarydeleteLogic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.componentDidMount();
                }, 500);
            }, err => {
                this.setState({
                    loading: false
                });
            }
        );
    }

    componentWillMount() {
        let { query = {} } = this.props.location;
        let { modalOptions } = this.state;
        if (isNotEmpty(query)) {
            let { parentId, name } = query;
            this.fieldsList.map(v => {
                if (v.name === "parentId") {
                    v.originValue = parentId;
                }
            })
            this.setState({
                modalOptions: {
                    ...modalOptions,
                    title: "字典管理(" + name + ")"
                }
            });
        }
        PcService.initFormList(this.searchFieldsList);
    }

    componentDidMount() {
        let forms = this.refs.pcForm;
        this.searchOnSubmit(forms);
    }


    render() {
        const { loading, selectedRowKeys, pagination, lists, modalOptions, create } = this.state;
        const { onSelectChange, columns, fetchData, searchFieldsList, searchOnSubmit, createOne, fieldsList, onOk, onCancel, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, title: (create ? "新增" : "编辑") + modalOptions.title, onOk, onCancel };
        const allowDelete = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 }
            }
        };
        return (
            <Spin spinning={loading}>
                <PcTitle icon="book">{modalOptions.title}</PcTitle>
                <Row className="ddy">
                    <div className="op-navs">
                        <div className="opn-left">
                            <Button onClick={createOne} icon="file-add" type="primary">新增</Button>
                            <Divider type="vertical" />
                            <Button disabled={!allowDelete} onClick={deleteItem.bind(this, selectedRowKeys)} icon="delete" type="default">删除</Button>
                        </div>
                        <div className="opn-right" style={{ display: "none" }}>
                            <PcForm ref="pcForm" layout="inline" submitText="搜索" onSubmit={searchOnSubmit} fieldsList={searchFieldsList} />
                        </div>
                    </div>
                </Row>
                <div className="op-content">
                    <PcTable
                        rowSelection={rowSelection}
                        columns={columns}
                        pagination={pagination}
                        dataSource={lists}
                        fetchData={fetchData}
                        bordered={false}
                    />
                </div>
                <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
            </Spin>
        )
    }
}

export default DataDictionaryDetial;
