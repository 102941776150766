import less from 'less';
import { message } from 'antd';
import {isNotEmpty} from "./index";

/**
 * 设置页面的主题
 * @param obj
 * @param func
 * @param set
 */
export const setThemes = (obj, func, set=true) => {
	less.modifyVars({...obj}).then((e) => {
		if (set) {
            message.destroy(); //过快弹出多个
            message.success('预览主题成功.');
        }
		if (func) func(e);
	});
}

/**
 * 解析页面的主题
 * @param str
 * @return {any}
 */
export const parseThemes = (str) => {
    // console.log('parse',str);
	let obj = {};
    if (isNotEmpty(str)) {
        try {
            obj = JSON.parse(
                str
                    .replace('@header', '@head-back_color')
                    .replace('@sider', '@sider-back_color')
                    .replace('@font', '@sider-select_color')
                    .replace('@button', '@select-color')
                    .replace('@table', '@table_header_color')
            )
        } catch (e) {}
    }
    return obj;
}