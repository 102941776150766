import React, { Component } from 'react';
import { Icon } from 'antd';
import './dashboard.css';
import pcService from '../../../other/pc.service';

class Dashboard extends Component {

    //组件加载完成钩子函数
    componentDidMount() {
        window.onresize = () => {
            let dashboardInner = this.refs.dashboardInner;
            let dashboardCont = this.refs.dashboardCont;
            if (!(dashboardInner || dashboardCont)) return;
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight - 80;
            let innerWidth = dashboardInner.clientWidth + 160;
            let innerHeight = dashboardInner.scrollHeight + 80;
            let widthPercent = clientWidth / innerWidth;
            let heightPercent = clientHeight / innerHeight;
            if (widthPercent >= 1 && heightPercent >= 1) {
                dashboardCont.style.transform = `scale(1)`
            } else if (widthPercent > 0 && heightPercent > 0) {
                if (widthPercent > heightPercent && heightPercent > 0) {
                    dashboardCont.style.transform = `scale(${heightPercent})`;
                } else {
                    dashboardCont.style.transform = `scale(${widthPercent})`;
                }
            } else {
                dashboardCont.style.transform = `scale(1)`;
            }
        }
    }


    render() {
        const { dashboardData = [] } = this.props;
        return (
            <div className="dashboard-page">
                <div ref="dashboardCont" className="dashboard-cont">
                    <div ref="dashboardInner" className="dashboard-inner">
                        <div className="dashboard-table-head">
                            <div className="tr">
                                {dashboardData.map((v, n) => <div key={n} className="th">{v.title}</div>)}
                            </div>
                        </div>
                        <div className="dashboard-table-body">
                            <div className="tr">
                                {dashboardData.map((v, n) => (
                                    <div className="td" key={n}>
                                        {!v.menus || v.menus.length === 0 ? <p style={{ fontSize: 16 }}>暂无！</p> : v.menus.map((_v, _n) => (
                                            <div key={_n} title={_v.title} className="cell" onClick={pcService.relativePath.bind(this, _v.path, _v)}>
                                                <Icon type={_v.icon || "home"} /> {_v.title}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard;
