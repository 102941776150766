import React, { Component } from 'react';
import PcTable from '../../common/components/table/tableComponent';
import PcTitle from '../../common/components/title/Titles';
import PcForm from '../../common/components/form/Forms';
import { Spin, Icon, Divider, Popconfirm, Row, message, Modal, Table } from 'antd';
import http from '../../common/axios/request';
import urls from '../../configs/api.config';
import '../booksManagement/index.less';

export class index extends Component {

    state = {
        loading: false,
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        modalOptions: {
            title: "评论详情",
            visible: false,
            destroyOnClose: true,
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 1100
        },
        lists: [],
        commnetList: []
    }

    /* 表格列项 */
    columns = [{
        title: '序号',
        dataIndex: 'key1',
        align: 'center',
        width: 60,
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 260,
        render: (text, record) => {
            return <span>
                <a onClick={() => this.detailComent(record.id)}><Icon type="message" theme="outlined" /> 评论</a>
                <Divider type="vertical" />
                <Popconfirm title="您确定要删除吗?" onConfirm={() => this.deleteComent(record)} okText="确定" cancelText="取消">
                    <a><Icon type="delete" theme="outlined" /> 删除</a>
                </Popconfirm>
            </span>
        }
    }, {
        title: '内容',
        dataIndex: 'content'
    }, /*  {
        title: '二级栏目',
        dataIndex: 'photo',
        render: text => !!text ? <image src={``} /> : ""
    }, */ {
        title: '评论人',
        dataIndex: 'userName'
    }, {
        title: '头像',
        dataIndex: 'headImg',
        render: text => !!text ? <img src={`${urls.file.viewImg}/${text}`} /> : ""
    }, {
        title: "评分",
        dataIndex: "score"
    }, {
        title: '评论时间',
        dataIndex: 'createdTime'
    }, {
        title: '排序',
        dataIndex: 'sort'
    }];

    cColumns = [{
        title: '序号',
        dataIndex: 'key1',
        align: 'center',
        width: 60,
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 260,
        render: (text, record) => {
            return <span>
                <Popconfirm title="您确定要删除吗?" onConfirm={() => this.deleteComent(record, true)} okText="确定" cancelText="取消">
                    <a><Icon type="delete" theme="outlined" /> 删除</a>
                </Popconfirm>
            </span>
        }
    }, {
        title: '内容',
        dataIndex: 'content'
    }, /*  {
        title: '二级栏目',
        dataIndex: 'photo',
        render: text => !!text ? <image src={``} /> : ""
    }, */ {
        title: '评论人',
        dataIndex: 'userName'
    }, {
        title: '头像',
        dataIndex: 'headImg',
        render: text => !!text ? <img src={`${urls.file.viewImg}/${text}`} /> : ""
    }, {
        title: "评分",
        dataIndex: "score"
    }, {
        title: '评论时间',
        dataIndex: 'createdTime'
    }, {
        title: '排序',
        dataIndex: 'sort'
    }]

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "keyword",
        editor: "normal",
        value: "",
        originValue: "",
        displayName: "关键字",
        hideDisplayName: true
    }];

    //删除评论
    deleteComent = (record, isModal) => {
        const { modalOptions } = this.state;
        let beforeUpdateprops = {}, successUpdateprops = {};
        if (isModal) {
            beforeUpdateprops = {
                modalOptions: { ...modalOptions, modalsLoading: true }
            };
            successUpdateprops = { modalOptions: { ...modalOptions, modalsLoading: false } }
        } else {
            beforeUpdateprops = { loading: true }
            successUpdateprops = { loading: false }
        }
        this.setState({ ...beforeUpdateprops });
        http.request({
            method: "delete",
            url: urls.app.ebook.commnetDelete + record.id
        }).then(res => {
            if (res.success) {
                if (isModal) {
                    this.detailComent(record.parentId, isModal);
                } else {
                    this.componentDidMount();
                }
            } else {
                this.setState({ ...successUpdateprops });
            }
        }).catch(error => {
            message.error(error.message);
            this.setState({ ...successUpdateprops });
        })
    }


    //获取评论的评论
    getCommnets = (id, resolve, isModal) => {
        const { modalOptions } = this.state;
        let beforeUpdateprops = {}, successUpdateprops = {};
        if (isModal) {
            beforeUpdateprops = {
                modalOptions: { ...modalOptions, modalsLoading: true }
            };
            successUpdateprops = {
                modalOptions: { ...modalOptions, modalsLoading: false }
            }
        } else {
            beforeUpdateprops = { loading: true };
            successUpdateprops = { loading: false }
        }
        this.setState({ ...beforeUpdateprops });
        http.request({
            method: "get",
            url: urls.app.ebook.commnetPage,
            params: { parentId: id }
        }).then(res => {
            if (res.success) {
                const commnetList = res.data.rows || [];
                this.setState({ commnetList, ...successUpdateprops }, resolve);
            } else {
                message.error(res.message);
                this.setState({ ...successUpdateprops });
            }
        }).catch(error => {
            message.error(error.message);
            this.setState({ ...successUpdateprops });
        })
    }

    /* detailComent */
    detailComent = (id, isModal) => {
        const { modalOptions } = this.state;
        const promises = new Promise(resolve => {
            this.getCommnets(id, resolve, isModal)
        });
        promises.then(() => {
            if (!isModal) {
                this.setState({
                    modalOptions: { ...modalOptions, visible: true, modalsLoading: false }
                })
            }

        })
    }

    /* 获取评论列表 */
    fetchData = (param = {}) => {
        const { id, pagination } = this.state;
        const { pageSize, current } = pagination;
        this.setState({ loading: true });
        http.request({
            method: "get",
            params: { ...param, pageSize, current, ebookId: id },
            url: urls.app.ebook.commnetPage
        }).then(res => {
            if (res.success) {
                const lists = res.data.rows || [];
                this.setState({ lists, loading: false })
            } else {
                message.error(res.message);
                this.setState({ loading: false })
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false })
        })
    }

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.fetchData(values);
            }
        });
    }

    /* onOk */
    onOk = () => {
        const { modalOptions } = this.state;
        this.setState({ modalOptions: { ...modalOptions, visible: false, modalsLoading: false } })
    }
    componentWillMount() {
        const { id = "", name = "" } = this.props.location.query;
        this.setState({
            id, name
        })
    }
    componentDidMount() {
        const forms = this.refs.pcForm;
        this.searchOnSubmit(forms);
    }

    render() {
        const { loading, pagination, lists, name, commnetList, modalOptions } = this.state;
        const { columns, fetchData, searchFieldsList, searchOnSubmit, onOk, cColumns } = this;
        const modals = { ...modalOptions, onOk, onCancel: onOk }
        return (
            <div className="booksManagement">
                <Spin spinning={loading}>
                    <PcTitle icon="message">{name ? name + "的" : ""}评论</PcTitle>
                    <Row className="bookstore-section">
                        <div className="op-navs">
                            <div className="opn-left"></div>
                            <div className="opn-right">
                                <PcForm ref="pcForm" layout="inline" submitText="搜索" onSubmit={searchOnSubmit} fieldsList={searchFieldsList} />
                            </div>
                        </div>
                    </Row>
                    <PcTable
                        //rowSelection={rowSelection}
                        columns={columns}
                        pagination={pagination}
                        dataSource={lists}
                        fetchData={fetchData}
                        bordered={false}
                    />
                    <Modal {...modals}>
                        <Spin spinning={modals.modalsLoading}>
                            <Table dataSource={commnetList} columns={cColumns} />
                        </Spin>
                    </Modal>
                </Spin>
            </div>
        )
    }
}

export default index
