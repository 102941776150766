import React, { Component } from 'react';
import { Input } from 'antd';
import { isEmpty } from "./../../utils";

const Search = Input.Search;

class SiderSearch extends Component {

  handleChange = (e) => {
    let { handleSearch } = this.props;
    let searchValue = e.target.value; // 变化为空时，还原成原始状态数据
    handleSearch(searchValue);
  };

  render() {
    const { handleSearch } = this.props;
    const { handleChange } = this;
    return (
      <Search allowClear placeholder="搜索菜单导航 ..." onSearch={handleSearch} onChange={handleChange} />
    )
  }
}

export default SiderSearch;