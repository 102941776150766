import React, { Component, Children } from 'react';
import { hashHistory } from 'react-router';
import axios from "axios";
import { Input, message, Button, Spin, Divider, Popconfirm, Modal } from 'antd';
import { getLocal, setLocal, convertListToTreeJson, isNotEmpty } from '../../common/utils';
import TreeList from './../../common/components/tree/TreeList';
import PcModal from '../../common/components/modal/Modals';
import PcService from './../../other/pc.service';
import OrganizationG2 from './OrganizationG2';
import http from './../../common/axios/request';
import ReactToPrint from 'react-to-print';
import urls from './../../configs/api.config';
import './organization.css';
import defaultPic from './../../img/defaultPic.jpg';
const { confirm } = Modal;

let newStructureId = getLocal("structureList");

export class Organization extends Component {

    state = {
        loading: false,
        visible: false,
        treeList: [],
        baseData: [],
        baseDataG2: [],
        ageData: [],
        sexData: [],
        superParty: '',
        partyTotal: '',
        foundingTime: '',
        partyName: '',
        treeSelectedKeys: [],//选中的节点(栏目id) 
        superPartyName: "无",
        superPartyId: "",
        selectPartyType: "",
        rightClickNodeTreeItem: {
            pageX: "",
            pageY: "",
            id: "",
            categoryName: "",
            orderBy: "",
            pLevel: "",
            visible: false,
        },
        orgnationModalOptions: {
            title: "新增",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false
        },
        renameModalOptions: {
            title: "修改",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false
        },
    }

    /* 账号信息fieldsList */
    fieldsList = [
        {
            name: "parentId",
            editor: "hidden",
            value: "",
            originValue: ""
        },
        {
            name: "structureCode",
            editor: "hidden",
            value: "",
            originValue: ""
        },
        {
            name: "structureName",
            editor: "normal",
            value: "",
            displayName: "名称",
            originValue: "",
            rules: [
                { required: true, message: '请输入名称' },
                { max: 20, message: '最多可以输入20个字符' },
                { whitespace: true, message: '不能全为空格' }
            ]
        },
        {
            name: "partyType",
            displayName: '组织类别',
            editor: 'select',
            value: '',
            unavailable: false,
            originValue: "",
            opts: PcService.getDataDictionary("ORG_TYPE"),
            rules: [
                { required: true, message: '请选择组织类别' }
            ],
            onChange: (_item, form, _value, opts) => {
                //console.log(opts)
            },
        },
        {
            name: "orderBy",
            editor: "normal",
            value: "0",
            displayName: "排序",
            originValue: "",
            rules: [
                { max: 5, message: '最多可以输入5个字符' },
                { pattern: /^\d+$/, message: '请输入非负整数' },
                { whitespace: true, message: '不能全为空格' }
            ]
        },
    ];
    fieldsListRename = [
        {
            name: "structureName",
            editor: "normal",
            value: "",
            displayName: "名称",
            originValue: "",
            rules: [
                { required: true, message: '请输入名称' },
                { max: 20, message: '最多可以输入20个字符' },
                { whitespace: true, message: '不能全为空格' }
            ]
        }, {
            name: "orderBy",
            editor: "normal",
            value: "",
            displayName: "排序",
            originValue: "",
            rules: [
                { max: 5, message: '最多可以输入5个字符' },
                { pattern: /^\d+$/, message: '请输入非负整数' },
                { whitespace: true, message: '不能全为空格' }
            ]
        }
    ];

    toOrgNew = () => {
        let { treeList, treeSelectedKeys } = this.state;
        let _treeList = this.setJsonArray(treeList, "children");
        let partyType = _treeList.filter(v => v.structureId === treeSelectedKeys[0])[0].partyType;

        PcService.relativePath(`/app/organizationNew?id=${treeSelectedKeys}&superPartyName=${this.state.superPartyName}&partyType=${partyType}`);
    }

    toOrgDetail = () => {
        let { treeList, treeSelectedKeys } = this.state;
        let _treeList = this.setJsonArray(treeList, "children");
        let partyType = _treeList.filter(v => v.structureId === treeSelectedKeys[0])[0].partyType;
        PcService.relativePath(`/app/organizationDetail?id=${treeSelectedKeys}&superPartyName=${this.state.superPartyName}&partyType=${partyType}`);
    }
    buttonTreeDelete = () => {
        let structureIds = this.state.treeSelectedKeys[0];
        let that = this;
        this.setState({ loading: true }, () => {
            PcService.deleteItem(urls.app.organization.deleteParty, {}, structureIds,
                (data) => {
                    message.success("删除成功!");
                    this.setState({ visible: false })
                    setTimeout(function () {
                        that.getOrganizationTree();
                    }, 500);
                }, (err) => {
                    this.setState({ loading: false });
                }
            );
        });
    }

    print = () => {
        document.getElementById('bindpt').click();
    }

    /* 获取党组织树形结构 */
    getOrganizationTree = (keyWord) => {
        let _params = {
            keyword: keyWord,
            flag: false,
        }
        this.setState({ loading: true })
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success === true) {
                let treeData = res.data;
                // let treeData = convertListToTreeJson(res.data.list, '0', 'subStructure', 'structureId', 'pLevel');
                let treeSelectedKeys = treeData.length > 0 ? [treeData[0].structureId] : [0];
                treeSelectedKeys && this.onSelect(treeSelectedKeys);
                this.setState({
                    treeList: treeData,
                    loading: false,
                    treeSelectedKeys,
                });
                return;
            }
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ loading: false });
        });
    }

    onSearch = (value) => {
        this.getOrganizationTree(value)
    }
    //tree 刷新table
    onSelect = (keys, info, $event) => {
        if (!keys || keys.length === 0 || !keys[0] || keys[0] === this.state.treeSelectedKeys[0]) return;
        const _keys = keys[0];
        //上级组织
        this.getSuperPartyById(_keys)
        this.setState({ loading: true, treeSelectedKeys: keys })
        axios.all([
            http.request({
                method: "get",
                url: urls.app.organization.selectByStructureId,
                params: { structureId: _keys },
            }),
            http.request({
                method: "post",
                url: urls.app.organization.selectMembersBySex,
                params: { structureId: _keys },
            }),
            /* http.request({
                method: "post",
                url: urls.app.organization.selectMembersByAge,
                params: { structureId: _keys },
            }) */
        ]).then(reses => {
            if (reses.every(res => res.success)) {
                let partyName = reses[0].data.rows.length > 0 ? reses[0].data.rows[0].partyName : '';
                let foundingTime = reses[0].data.rows.length > 0 ? reses[0].data.rows[0].foundingTime : '';
                let memberList = reses[0].data.rows.length > 0 ? reses[0].data.rows[0] : {};
                let baseData = reses[0].data.rows.length ? reses[0].data.rows[0].leaderMember : [];
                let baseDataG2 = reses[0].data.rows.length ? reses[0].data.rows[0] : [];
                // for (let v in memberList.leaderMember) {
                //     baseData.push({
                //         name: memberList.leaderMember[v],
                //         position: v
                //     });
                // }
                this.setState({
                    baseData,
                    baseDataG2,
                    sexData: reses[1].data.sex,
                    ageData: reses[1].data.ageGroup,
                    superParty: reses[0].data.rows[0] ? reses[0].data.rows[0].superParty : "",
                    partyTotal: memberList.memberNumber + memberList.probationaryNumber,
                    foundingTime: foundingTime,
                    partyName: partyName,
                })
            } else {
                let errorMessages = reses.filter(v => !v.success);
                message.error(errorMessages[0].message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ loading: false });
        });

        //获取partytype
        let _treeList = this.setJsonArray(this.state.treeList, "children");
        let treeFilter = _treeList.filter(v => v.structureId === keys[0]);
        let partyType = "";
        if (treeFilter && treeFilter.length !== 0) {
            partyType = treeFilter[0].partyType;
        }
        this.setState({ selectPartyType: partyType })

    };
    // tree列表上右键事件
    onRightClick = (e) => {
        this.setState({
            rightClickNodeTreeItem: {
                pageX: e.event.pageX,
                pageY: e.event.pageY,
                id: e.node.props.eventKey,
                categoryName: e.node.props.title,
                orderBy: e.node.props.dataRef.orderBy,
                pLevel: e.node.props.pos,
                visible: true,
            }
        });
        this.onSelect([e.node.props.eventKey])
    };
    //关闭右键按钮
    clearRightMenu = () => {
        const { rightClickNodeTreeItem } = this.state;
        this.setState({
            rightClickNodeTreeItem: {
                ...rightClickNodeTreeItem,
                visible: false
            },
        })
    }

    //初始化渲染右键事件，并隐藏
    getNodeTreeRightClickMenu = () => {
        const { pageX, pageY, id, categoryName, orderBy, pLevel, visible } = { ...this.state.rightClickNodeTreeItem };
        const tmpStyle = {
            display: visible === true ? "block" : "none",
            position: "absolute",
            left: `${pageX - 230}px`,
            top: `${pageY - 110}px`,
        };
        const tmpDelStyle = {
            display: "block",
            position: "absolute",
            left: `${pageX - 230}px`,
            top: `${pageY - 110}px`,
        };
        const menu = (
            <ul style={tmpStyle} className="tree-right-menu">
                {
                    this.partyTypeOpts() ? null : <li onClick={this.buttonTreeNew}>新增</li>
                }
                {
                    pLevel === "0-0" ? null : <li onClick={this.buttonTreeRename}>修改</li>
                }
                {
                    pLevel === "0-0" ? null : <li onClick={this.showConfirm} >删除</li>
                }

            </ul>
        );

        return menu;
        /* return this.isPropsEmpty(this.state.rightClickNodeTreeItem) ? "" : menu; */
    };

    /* isPropsEmpty = (param) => {
        let flag = true;
        for (const key in param) {
            if (param[key]) {
                flag = false;
            }
        }
        return flag;
    } */

    /* 确认删除提示 */
    showConfirm = () => {
        let that = this;
        confirm({
            title: "确定删除该组织吗？",
            content: "",
            centered: true,
            onOk() {
                that.buttonTreeDelete();
            },
            onCancel() { },
        });
    }

    partyTypeOptsNodes = (data = [], treeSelectedId) => data.map((item, index) => {
        if (item.structureId === treeSelectedId) {
            //console.log(item.partyType)
            return item.partyType;
        }
        if (item.children.length > 0) {
            return this.partyTypeOptsNodes(item.children, treeSelectedId);
        }
    });

    //判断组织类别逻辑 
    partyTypeOpts = () => {
        let { treeList, treeSelectedKeys } = this.state;

        let _treeList = this.setJsonArray(treeList, "children");
        let partyType = _treeList.filter(v => v.structureId === treeSelectedKeys[0])[0].partyType;

        let newTypeOpt = [];
        if (partyType === "PARTY_COMM") {
            newTypeOpt = PcService.getDataDictionary("ORG_TYPE").filter(item => !(item.id === partyType || item.id === "PARTY_CLASS"))
        } else if (partyType === "PARTY_TOTAL") {
            newTypeOpt = PcService.getDataDictionary("ORG_TYPE").filter(item => item.id === "PARTY")
        } else if (partyType === "PARTY") {
            newTypeOpt = PcService.getDataDictionary("ORG_TYPE").filter(item => item.id === "PARTY_CLASS")
        } else if (partyType === "PARTY_CLASS") {
            newTypeOpt = [];
            return true;
        } else {
            newTypeOpt = PcService.getDataDictionary("ORG_TYPE");
        }
        this.fieldsList.map(v => {
            if (v.name === "partyType") {
                v.opts = newTypeOpt;
            }
        })
        return false;
    }

    // 嵌套JSON数组转平级 
    setJsonArray = (data, subMenu = "children") => {
        let result = [];
        data.forEach(json => {
            if (json) {
                if (isNotEmpty(json[subMenu])) {
                    result = result.concat(this.setJsonArray(json[subMenu]));
                }
                result.push(json);
            }

        });
        return result;
    };

    /* 根据组织id获取组织名称 */
    getSuperPartyById = (id) => {
        let that = this;
        let { treeList, superPartyName } = this.state;
        let _treeList = this.setJsonArray(treeList, "children");
        let parentTreeList = _treeList.filter(v => v.structureId === id);
        if (parentTreeList.length > 0) {
            let filterTreeList = _treeList.filter(v => v.structureId === parentTreeList[0].parentId);
            that.setState({
                superPartyName: filterTreeList.length > 0 ? filterTreeList[0].structureName : "无",
                superPartyId: filterTreeList.length > 0 ? filterTreeList[0].structureId : ""
            })
            return filterTreeList.length > 0 ? filterTreeList[0].structureName : "无";
        } else {
            that.setState({
                superPartyName: "无",
                superPartyId: ""
            })
        }
        return "无";
    }

    /* 新增 */
    buttonTreeNew = () => {
        let { orgnationModalOptions } = this.state;
        this.setState({
            orgnationModalOptions: {
                ...orgnationModalOptions,
                visible: true,
            },
            loading: false
        });

    }
    /* 新增确定 */
    orgnationOnOk = (forms) => {
        let { orgnationModalOptions, create, treeSelectedKeys } = this.state;
        const { pageX, pageY, id, categoryName, orderBy, pLevel, visible } = { ...this.state.rightClickNodeTreeItem };
        let that = this;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = urls.app.organization.addParty;
                this.setState({
                    orgnationModalOptions: {
                        ...orgnationModalOptions,
                        modalsLoading: true
                    }
                }, () => {
                    let params = {
                        parentId: treeSelectedKeys[0],
                        structureCode: parseInt(Math.random() * 100000 + 1).toString(),
                        pLevel: parseInt(pLevel.length / 2),
                    }
                    PcService.formSubmit(url, true, null, Object.assign({}, values, params), (data) => {
                        //每新增一个组织机构，就往header里放一个id
                        setLocal("structureList", newStructureId + "," + data)
                        message.success("新增组织成功!");
                        setTimeout(function () {
                            that.componentDidMount();
                        }, 500);
                        this.setState({
                            orgnationModalOptions: {
                                ...orgnationModalOptions,
                                modalsLoading: false,
                                visible: false
                            }
                        });
                    }, (err) => {
                        this.setState({
                            orgnationModalOptions: {
                                ...orgnationModalOptions,
                                modalsLoading: false,
                                visible: false
                            }
                        });
                    });
                });
            }
        });
    }

    /* 新增取消 */
    orgnationOnCancel = () => {
        let { orgnationModalOptions } = this.state;
        this.setState({
            orgnationModalOptions: {
                ...orgnationModalOptions,
                visible: false
            }
        });
    }
    /* 修改 */
    buttonTreeRename = () => {
        let { renameModalOptions } = this.state;
        const { pageX, pageY, id, categoryName, orderBy, pLevel, visible } = { ...this.state.rightClickNodeTreeItem };
        this.fieldsListRename.map(v => {
            if (v.name === "structureName") {
                v.value = categoryName;
            }
            if (v.name === "orderBy") {
                v.value = orderBy;
            }
        })
        this.onSelect([id]);
        this.setState({
            renameModalOptions: {
                ...renameModalOptions,
                visible: true,
            },
            loading: false
        });

    }

    /* 修改确定 */
    renameOnOk = (forms) => {
        let { renameModalOptions, treeSelectedKeys } = this.state;
        const { pageX, pageY, id, categoryName, orderBy, pLevel, visible } = { ...this.state.rightClickNodeTreeItem };
        let that = this;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = urls.app.organization.updateBaseOrganizationForParty;
                this.setState({
                    renameModalOptions: {
                        ...renameModalOptions,
                        modalsLoading: true
                    }
                }, () => {
                    let params = {
                        structureId: treeSelectedKeys[0],
                    }
                    PcService.formSubmit(url, true, null, Object.assign({}, values, params), (data) => {
                        message.success("修改组织成功!");
                        setTimeout(function () {
                            that.componentDidMount();
                        }, 500);
                        this.setState({
                            renameModalOptions: {
                                ...renameModalOptions,
                                modalsLoading: false,
                                visible: false
                            }
                        });
                    }, (err) => {
                        this.setState({
                            renameModalOptions: {
                                ...renameModalOptions,
                                modalsLoading: false,
                                visible: false
                            }
                        });
                    });
                });
            }
        });
    }

    /* 修改取消 */
    renameOnCancel = () => {
        let { renameModalOptions } = this.state;
        this.setState({
            renameModalOptions: {
                ...renameModalOptions,
                visible: false
            }
        });
    }

    /*显示隐藏onChange*/
    handleVisibleChange = () => {
        this.setState({ visible: true });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentDidMount() {
        this.getOrganizationTree();
    }
    render() {
        const { getSuperPartyById, toOrgNew, toOrgDetail, print, onSearch, onSelect, getNodeTreeRightClickMenu,
            onRightClick, clearRightMenu, orgnationOnOk, orgnationOnCancel, fieldsList, renameOnOk, renameOnCancel, fieldsListRename } = this;
        const { treeList, loading, ageData, treeSelectedKeys, sexData, baseData, baseDataG2, superPartyName,
            partyTotal, orgnationModalOptions, renameModalOptions, foundingTime, partyName, selectPartyType } = this.state;
        const orgnationModals = { ...orgnationModalOptions, onOk: orgnationOnOk, onCancel: orgnationOnCancel }
        const renameModals = { ...renameModalOptions, onOk: renameOnOk, onCancel: renameOnCancel }
        const orgnationFormItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 }
            }
        };
        return (
            <Spin spinning={loading} className="">
                <div className="org-box" onClick={clearRightMenu}>
                    <div className="org-left">
                        <label className="org-lab">党组织机构</label>
                        <div className="org-tree">
                            <TreeList treeList={treeList} selectedKeys={treeSelectedKeys} onSelect={onSelect} onRightClick={onRightClick}
                                getNodeTreeRightClickMenu={getNodeTreeRightClickMenu} />
                        </div>
                    </div>
                    <ReactToPrint
                        trigger={() => <a href="javascript:void(0);" ref={'bindpt'} id={'bindpt'}></a>}
                        content={() => this.componentRef}
                    />
                    <div className="org-main" ref={el => (this.componentRef = el)} id='docDetails'>
                        <div className="org-main-inner">
                            <div>
                                <label className="org-main-lab">{partyName}</label>
                                <div style={{ float: 'right' }}>
                                    <span style={{display: "none"}}>
                                        <Button value='default' icon="printer" onClick={print}>打印</Button>
                                        <Divider type="vertical" />
                                    </span>
                                    <Button value='large' icon="edit" onClick={toOrgNew}>编辑</Button>
                                    <Divider type="vertical" />
                                    <Button value='small' icon="info-circle" onClick={toOrgDetail}>详情</Button>
                                </div>
                            </div>
                            <div className="org-marin-tipinfo">
                                <span className="org-main-tip" />
                                {
                                    selectPartyType !== "PARTY_CLASS" ? "所属组织基本情况" : "所属小组基本情况"
                                }
                            </div>
                            <div>
                                <div style={{ float: 'left' }}>上级组织：{superPartyName}&emsp;</div>
                                <div style={{ float: 'left' }}>党员总数：{partyTotal ? partyTotal + '人' : '0人'}&emsp;</div>
                                <div style={{ float: 'left' }}>
                                    {
                                        selectPartyType !== "PARTY_CLASS" ? "党组织建立时间：" : "党小组建立时间："
                                    }
                                    {foundingTime}
                                </div>
                                <div style={{ clear: 'both' }} />
                            </div>
                            <div>
                                <div className="org-member-pic">
                                    <ul style={{ minHeight: 120 }}>
                                        {!baseData || baseData.length === 0 ? <li>
                                            <p style={{ padding: '20px 0' }}>暂无数据!</p>
                                        </li> : (baseData.map((v, n) => (
                                            <li key={n}>
                                                <div className="folder-li">
                                                    <img className="img" src={v.photo ? `${urls.file.viewImg}/${v.photo}` : defaultPic} />
                                                    <div>
                                                        {
                                                            selectPartyType !== "PARTY_CLASS" ? v.positionName : (v.positionName.indexOf("书记") !== -1 ? v.positionName.replace("书记", "组长") : v.positionName)
                                                        }
                                                    </div>
                                                    <div >{v.name}</div>
                                                </div>
                                            </li>
                                        )))}
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <OrganizationG2 ageData={ageData} sexData={sexData} baseData={baseDataG2} selectPartyType={selectPartyType} />

                            </div>
                            <PcModal modal={orgnationModals} fieldsList={fieldsList} formItemLayout={orgnationFormItemLayout} modalsLoading={orgnationModals.modalsLoading} />
                            <PcModal modal={renameModals} fieldsList={fieldsListRename} formItemLayout={orgnationFormItemLayout} modalsLoading={renameModals.modalsLoading} />
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}

export default Organization;
