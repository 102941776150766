import React, { Component } from 'react';

import './percentBar.less';

class PercentBar extends Component {

    render() {
        const { percent = 0, title = "", fgColor = '#369', bgColor = "#ddd" } = this.props;
        const deg = percent * 360 / 100 + 90;
        const degStyles = {
            backgroundImage: deg <= 270 ? `linear-gradient(90deg, ${bgColor} 50%, transparent 50%, transparent), linear-gradient(${deg}deg, ${fgColor} 50%, ${bgColor} 50%, ${bgColor})` :
                `linear-gradient(${deg - 360}deg, ${fgColor} 50%, transparent 50%, transparent), linear-gradient(270deg, ${fgColor} 50%, ${bgColor} 50%, ${bgColor})`
        }
        const degBlankStyles = {
            transform: `rotate(${deg <= 270 ? deg + 90 : deg - 270}deg) translate(0, 75px)`,
            borderColor: fgColor,
            boxShadow: `0 0 10px ${fgColor}`
        }
        return (
            <div className="pc-percentBar">
                <div className="hgbs-tdInner" style={degStyles}>
                    <div className="bg-white">
                        <div className="before-blank" style={degBlankStyles}></div>
                        <h1>{percent}<span>%</span></h1>
                        <p>{title}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default PercentBar;
