import { getLocal, setLocal, removeLocal, isNotEmpty, setSession, getSession, removeSession } from '../common/utils';

import logo from '../img/logo.png';
import login_bg from '../img/bg.jpg';

/**
 * 初始化配置环境
 */
const initAppConfig = () => {
    let publicAppConfig = {};
    let publicAppConfigStr = getLocal("publicAppConfig");
    if (publicAppConfigStr) {
        publicAppConfig = publicAppConfigStr;
    }
    return publicAppConfig;
};

/**
 * 默认系统设置
 */
const defaultSystemSet = {
    /**系统名称 */
    sysName: '党员积分制管理系统',
    /**版本号 */
    sysVersion: 'v1.0',
    /**首页地址 */
    sysHome: window.location.protocol + '//' + window.location.host + '/#/app',
    /**主题皮肤 */
    sysSkin: '',
    /**logo图标 */
    sysLogo: logo,
    /**登录背景 */
    sysLogin: login_bg
};

/**
 * 应用本地存储名称
 */
const TenantSetGlobalCacheKey = "global_tenant"; //租户信息
const SystemSetGlobalCacheKey = "global_setting"; //全局设置
const SystemSetUserCacheKey = "current_setting"; //当前设置
const TenantSetCurrentCacheKey = "current_tenant"; //当前租户

export const setTenantSetCurrentCache = (tenantSetCurrent) => {
    setSession(TenantSetCurrentCacheKey, tenantSetCurrent);
};

export const getTenantSetCurrentCache = () => {
    return getSession(TenantSetCurrentCacheKey);
};

export const removeTenantSetCurrentCache = () => {
    removeSession(TenantSetCurrentCacheKey);
};

export const setTenantSetGlobalCache = (tenantSetGlobal, session = false) => {
    if (tenantSetGlobal) {
        !session ? setLocal(TenantSetGlobalCacheKey, JSON.stringify(tenantSetGlobal)) : setSession(TenantSetGlobalCacheKey, JSON.stringify(tenantSetGlobal));
    }
};

export const getTenantSetGlobalCache = (session = false) => {
    let tenantSetGlobal = [];
    let tenantSetGlobalStr = !session ? getLocal(TenantSetGlobalCacheKey) : getSession(TenantSetGlobalCacheKey);
    if (isNotEmpty(tenantSetGlobalStr)) {
        let tenantSetGlobalTemp = JSON.parse(tenantSetGlobalStr);
        for (let key of Object.keys(tenantSetGlobalTemp)) {
            let value = tenantSetGlobalTemp[key];

            if (isNotEmpty(value)) {
                tenantSetGlobal[key] = value;
            }
        }
    }
    return tenantSetGlobal;
};

export const removeTenantSetGlobalCache = (session = false) => {
    !session ? removeLocal(TenantSetGlobalCacheKey) : removeSession(TenantSetGlobalCacheKey);
};

export const setSystemSetGlobalCache = (systemSetGlobal) => {
    if (systemSetGlobal) {
        setLocal(SystemSetGlobalCacheKey, JSON.stringify(systemSetGlobal));
    }
};

export const getSystemSetGlobalCache = () => {
    let systemSetGlobal = {};
    let systemSetGlobalStr = getLocal(SystemSetGlobalCacheKey);
    if (isNotEmpty(systemSetGlobalStr)) {
        let systemSetGlobalTemp = JSON.parse(systemSetGlobalStr);
        for (let key of Object.keys(systemSetGlobalTemp)) {
            let value = systemSetGlobalTemp[key];

            if (isNotEmpty(value)) {
                systemSetGlobal[key] = value;
            }
        }
    } else {
        systemSetGlobal = defaultSystemSet;
    }
    return systemSetGlobal;
};

export const removeSystemSetGlobalCache = () => {
    removeLocal(SystemSetGlobalCacheKey);
};

export const setSystemSetUserCache = (systemSet) => {
    if (systemSet) {
        setLocal(SystemSetUserCacheKey, JSON.stringify(systemSet));
    }
};

export const getSystemSetUserCache = () => {
    let systemSet = {};
    let systemSetStr = getLocal(SystemSetUserCacheKey);
    if (isNotEmpty(systemSetStr)) {
        let systemSetTemp = JSON.parse(systemSetStr);
        for (let key of Object.keys(systemSetTemp)) {
            let value = systemSetTemp[key];
            if (isNotEmpty(value)) {
                systemSet[key] = value;
            }
        }
    } else {
        systemSet = defaultSystemSet;
    }
    return systemSet;
};

export const removetSystemSetUserCache = () => {
    removeLocal(SystemSetUserCacheKey);
};


/**
 * 系统配置-整合默认等信息
 */
const appConfig = {
    /**是否测试应用 */
    isTestApp: false,
    /**首页默认标题 */
    webDefaultTabTitle: '首页',
    /**首页对应的TabPaneKey */
    webDefaultTabPaneKey: '0',
    /**请求超时的毫秒数 */
    httpClientTimeout: 15000,
    /**路由地址配置 */
    routeConfig: {
        /*项目访问根地址*/
        webRoot: "/app",
        /*项目访问根地址*/
        webDefault: "index",
        /**外部模块加载地址 */
        webModules: "modules",
    },
    /**判断是否手机端屏幕宽度 */
    isMobileWith: 992,
    /**不需要分页时设置的最大值 */
    maxPageSize: 99999999,
    initMenu: {
        menuId: '0',
        menuUrl: '#',
        menuIcon: 'user',
        menuName: '平台根级菜单',
        menuOrder: 1,
        parentId: '-1',
        menuLevel: '-1',
        menuGroup: '',
        hasMenu: true,
    },
    initOrg: {
        structureId: '0',
        structureName: '平台根级组织',
        parentId: '-1',
        pLevel: '-1'
    },
    initPost: {
        positionId: '0',
        positionName: '平台根级岗位',
        parentId: '-1',
        levelBy: '-1'
    },
    initRole: {
        roleId: '0',
        roleName: '平台根级角色',
        parentId: '-1',
        levelBy: '-1'
    },
    ...defaultSystemSet,
    ...initAppConfig(),
    ...getSystemSetGlobalCache(),
    ...getSystemSetUserCache()
};

export default appConfig;
