import { getLocal, setLocal } from '../../utils';
/**
 * 应用本地存储名称
 */
const CurrentActiveMenu = "current_histories"; //最近使用


// 获取当前菜单
export const getCurrentMenus = () => {
    return getLocal("userMenus") || [];
};

// 存储当前点击的菜单
export const setCurrentActiveMenu = (menu) => {
    if (menu) {
        let currentMenu = getCurrentActiveMenu();
        let targetMenu = currentMenu.filter(item => item && item.path !== menu.path);
        targetMenu.unshift(menu); // 从开头位置添加进去，最新记录在前
        setLocal(CurrentActiveMenu, targetMenu.slice(0, 10));
    }
};

// 获取常用菜单数据
export const getCurrentActiveMenu = () => {
    let activeMenus = [];
    let activeMenusStr = getLocal(CurrentActiveMenu);
    if (activeMenusStr && activeMenusStr !== '') {
        activeMenus = activeMenusStr;
    }
    return activeMenus;
};