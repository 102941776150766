import React, { Component } from 'react';
import { Spin, message } from 'antd';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import appConfig from './../../configs/app.config';
import './talkDetail.less';

export class TalkDetail extends Component {
    state = {
        loading: false,
        dataInfo: [],
    }

    fetchData = (id) => {
        //获取传参
        let that = this;
        this.setState({ loading: true, });
        http.request({
            method: 'get',
            url: urls.app.talk.findById,
            params: { id },
        }).then(res => {
            if (res.success === true) {
                that.setState({
                    dataInfo: res.data,
                    loading: false
                });
            } else {
                message.error(res.message);
                that.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({
                loading: false
            });
        });
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        this.fetchData(id);
    }
    render() {
        const { loading, dataInfo } = this.state;
        const { enclosureList } = dataInfo;
        return (
            <div className="talk-detail">
                <div className="info-ddetail">
                    <Spin spinning={loading}>
                        <h1>{"谈心谈话详情"}</h1>
                        <div className="author-date">谈话时间：{dataInfo.talkTime} &emsp; 谈话对象：{dataInfo.talkMemberName}</div>
                        <div className="author-mameber">参与人员：{dataInfo.talkLeaderName}</div>
                        <div className="info-ddetail-cont">
                            <div dangerouslySetInnerHTML={{ __html: dataInfo.talkRecord }}></div>
                        </div>
                        <div className={"fileslist"}>
                            附件列表：
                            {
                                enclosureList && enclosureList.map((v, n) => {
                                    return (
                                        <div key={n}>
                                            <a target="_blank" className={"ant-upload-list-item-name"} title={v.fileName}
                                                href={appConfig.pocAddress + 'views/' + v.fileId} >{v.fileName}</a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}

export default TalkDetail
