import React, { Component } from 'react';
import { Badge, Modal, Icon } from 'antd';
import { getLocal } from './../../utils';
import UserNav from './../userNav/UserNav';
import SimpleMessage from './SimpleMessage';
import urls from '../../../configs/api.config';
import { hashHistory } from 'react-router';
/* import icon1 from './../../../img/index-01.png'; */
import icon2 from './../../../img/index-02.png';
import icon3 from './../../../img/index-03.png';
import icon4 from './../../../img/index-04.png';

class HomeHeaderBar extends Component {

    state = {
        userInfo: {
            name: "张小三"
        },
        showSmsg: false,
        modalOptions: {
            title: "联系我们",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            width: 600,
        },
        msgCount: 0,
        msgList: []
    }

    /* 获取用户信息 */
    getUserInfo() {
        let userInfo = getLocal("user_info") || {};
        this.setState({ userInfo });
    }

    /* 通知 */
    msMessage = () => {
        this.setState({
            showSmsg: true
        })
    }

    /* 联系我们 */
    msConnect = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true
            }
        });
    }

    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    setParentState = (args = {}) => {
        this.setState({
            ...args
        });
    }

    componentDidMount() {
        this.getUserInfo();
    }

    toggleMask = () => {
        this.setState({
            showSmsg: false
        });
    }
    /* 返回首页 */
    goHome = () => {
        hashHistory.push("/home");
    }

    render() {
        const { userInfo, modalOptions, showSmsg, msgCount, msgList } = this.state;
        const { msMessage, msConnect, onCancel, setParentState, toggleMask, goHome } = this;
        const modal = { ...modalOptions, onCancel };
        return (
            <div>
                <div className="bars">
                    <ul>
                        <li style={{ display: "block" }} className="user">
                            <a>
                                <span className="user-logo">
                                    <img src={userInfo.headImg ? `${urls.file.viewImg}/${userInfo.headImg}` : icon4} alt="" width="42" height="42" />
                                </span>
                                {userInfo.name}
                            </a>
                            <UserNav />
                        </li>
                        <li onClick={msMessage}>
                            <Badge count={msgCount}>
                                <a className="head-example" >
                                    <img src={icon3} alt="" />
                                </a>
                            </Badge>
                        </li>
                        <li onClick={msConnect}>
                            <a><img src={icon2} alt="" /></a>
                        </li>
                        <li onClick={goHome}>
                            <a><Icon type="home" /></a>
                        </li>
                    </ul>

                </div>
                <Modal {...modal} footer={null} mask={false}>
                    <div style={{ height: 160 }}>
                        <p>联系人：赵伟</p>
                        <p>QQ：563627125</p>
                        <p>微信：15184478941</p>
                        <p>联系电话：15184478941</p>
                        <p>邮箱：wei8.zhao@changhong.com</p>
                    </div>
                </Modal>
                <SimpleMessage smStyle={{ display: showSmsg ? "block" : "none" }} msgList={msgList} setParentState={setParentState} toggleMask={toggleMask} />
            </div>
        )
    }
}

export default HomeHeaderBar;
