import MenuList from './basic/menu/MenuList';
import OnlineList from './basic/online/OnlineList';
import PostList from './basic/post/PostList';
import UserList from './basic/user/UserList';
import CreateUserForm from './basic/user/CreateUserForm';
import RoleList from './basic/role/RoleList';
import TenantList from './basic/tenant/TenantList';
import CreateMenuForm from './basic/menu/CreateMenuForm';
import CreateOrganizationForm from './basic/organization/CreateOrganizationForm';
import CreatePostForm from './basic/post/CreatePostForm';
import CreateRoleForm from './basic/role/CreateRoleForm';
import CreateTenantForm from './basic/tenant/CreateTenantForm';
import SetAuthorities from './basic/menu/SetAuthorities';
import SetPermissions from './basic/role/SetPermissions';
import SetRoles from './basic/user/SetRoles';
import SetOrganizations from './basic/user/SetOrganizations';
import SetPosts from './basic/user/SetPosts';
import SetTenantMenus from './basic/tenant/SetTenantMenus';
import UserInfo from './setting/userInfo/UserInfo';
import CreateSystemSetForm from './setting/systemSet/CreateSystemSetForm';
import SetSystemOrganizations from './setting/systemSet/SetSystemOrganizations';
import SetSystemTenants from './setting/systemSet/SetSystemTenants';
import SystemSetList from './setting/systemSet/SystemSetList';
export default {
    MenuList,
    OnlineList,
    PostList,
    UserList,
    RoleList,
    TenantList,
    CreateMenuForm,
    CreateUserForm,
    CreateOrganizationForm,
    CreatePostForm,
    CreateRoleForm,
    CreateTenantForm,
    //SetAuthorities,
    SetPermissions,
    SetRoles,
    SetOrganizations,
    SetPosts,
    SetTenantMenus,
    UserInfo,
    CreateSystemSetForm,
    SetSystemOrganizations,
    SetSystemTenants,
    SystemSetList
}