import React, { Component } from 'react';
import { Spin, message } from 'antd';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import appConfig from './../../configs/app.config';
import PcService from '../../other/pc.service';
import { convertListToTreeJson } from './../../common/utils';
import './flowManage.less';

export class FlowManageDetail extends Component {
    state = {
        loading: false,
        dataInfo: [],
        orgLists: [],
        userOpts: [],
    }

    fieldsList = [
        {
            name: "id",
            editor: "normal",
            treeCheckable: false,
            value: "",
            displayName: "姓名",
            enumsArr: []
        },
        {
            name: "idCard",
            editor: "normal",
            value: "",
            displayName: "身份证号",
        },
        {
            name: "tel",
            editor: "normal",
            value: "",
            displayName: "联系电话",
        },
        {
            name: "linkedOrg",
            editor: "normal",
            value: "",
            originValue: "",
            displayName: "挂靠组织",
            enumsArr: []
        },
        {
            name: 'importType',
            displayName: '流入类型',
            editor: "normal",
            value: '',
            enumsArr: PcService.getDataDictionary("FLOW_IN_TYPE")
        },
        {
            name: "exportParty",
            editor: "normal",
            value: "",
            opts: [],
            displayName: "原党组织",
            enumsArr: []
        },
        {
            name: "exportLinkedName",
            editor: "normal",
            value: "",
            originValue: "",
            displayName: "原党组织联系人",
            enumsArr: []
        },
        {
            name: "importParty",
            editor: "normal",
            value: "",
            opts: [],
            displayName: "转入党组织",
            enumsArr: []
        },
        {
            name: "importLinkedName",
            editor: "normal",
            value: "",
            originValue: "",
            displayName: "转入党组织联系人",
            enumsArr: []
        },
        {
            name: "flowCause",
            editor: "normal",
            value: "",
            displayName: "流动原因",
        },
        {
            name: "importDate",
            editor: "normal",
            value: "",
            displayName: "流入时间",
        }
    ];

    fetchData = (id) => {
        //获取传参
        let that = this;
        this.setState({ loading: true, });
        http.request({
            method: 'get',
            url: urls.app.partyMember.getPartyMemberById,
            params: { id },
        }).then(res => {
            if (res.success === true) {
                PcService.bindFormdData(res.data, this.fieldsList);
                that.setState({
                    dataInfo: res.data,
                    loading: false
                });
            } else {
                message.error(res.message);
                that.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({
                loading: false
            });
        });
    }

    selectOrgAndUsers = () => {
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: {
                flag: true,
                type: 0,
            },
        }).then(res => {
            let treeData = res.data;
            let lists = PcService.renderOrgAndUserNodes({ data: treeData, type: 0 }); 
            if (res.success === true) {
                this.fieldsList.map(v => {
                    if (v.name === "id" || v.name === "exportLinkedName" || v.name === "importLinkedName") {
                        v.enumsArr = lists;
                    }
                })
                this.setState({
                    userOpts: lists,
                    loading: false
                });
            } else {
                message.error(res.message);
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({
                loading: false
            });
        });
    }
    // 所属党组织
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                let lists = treeData ? treeData : [];
                this.fieldsList.map(v => {
                    if (v.name === "linkedOrg" || v.name === "exportParty" || v.name === "importParty") {
                        v.enumsArr = lists
                    }
                })
                this.setState({
                    orgLists: lists
                })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    renderNodes = (data = []) => data.length && data.map((item, index) => ({
        name: item.structure.structureName,
        id: item.structure.structureId,
        selectable: false,
        children: item.communistInfoList.map((v, i) => ({
            name: v.name,
            id: v.id,
            idCard: v.idCard,
            tel: v.tel
        }))
    }));

    numberToText = (text, opts) => {
        let name;
        opts.map(v => {
            if (v.children && v.children.length) {
                v.children.map(k => {
                    if (text === k.id) {
                        name = k.name;
                    }
                })
            } else {
                if (text === v.id) {
                    name = v.name;
                }
            }
        })
        return name ? name : text;
    }

    componentWillMount() {
        this.selectOrgAndUsers();
        this.getOrganizationTree();
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        this.fetchData(id);
    }
    render() {
        const { loading, dataInfo, orgLists, userOpts } = this.state;
        const { fieldsList } = this;
        const { flowEnclosureList } = dataInfo;
        //console.log(orgLists)
        //console.log(userOpts)
        return (
            <div className="info-ddetail">
                <Spin spinning={loading}>
                    <h1>{"流动党员详情"}</h1>
                    <div className={"flwManage-detail"}>
                        {
                            fieldsList.length && fieldsList.map((v, i) => {
                                return (
                                    <div key={i}>
                                        <div>{v.displayName} ：{v.enumsArr ? this.numberToText(v.value ? v.value.toString() : "", v.enumsArr) : v.value}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div >
                        <div>{"备注"} ：</div>
                        <div dangerouslySetInnerHTML={{ __html: dataInfo.importRemark }}></div>
                    </div>
                    <div className={"fileslist"}>
                        附件列表：
                            {
                            flowEnclosureList && flowEnclosureList.map((v, n) => {
                                return (
                                    <div key={n}>
                                        <a target="_blank" className={"ant-upload-list-item-name"} title={v.fileName}
                                            href={appConfig.pocAddress + 'views/' + v.fileId} >{v.fileName}</a>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Spin>
            </div>
        )
    }
}

export default FlowManageDetail;
