import React, { Component } from 'react';
import OrganizationLifeNew from './OrganizationLifeNew';

export class OrganizationLifeUpdate extends Component {
    render() {
        const id = this.props.location.query.recordId;
        return (
            <div>
                <OrganizationLifeNew updateId={id} />
            </div>
        )
    } 
}

export default OrganizationLifeUpdate
