import React from 'react';
import {Form, Input, Row, Col, Card, Button,message} from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {updateTabPane, removeTabPane} from '../../../action/menuTab.action';
import SelectMenuTree from './SelectMenuTree';
import {get, post} from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import MenuIconChoose from './MenuIconChoose';
import {getLocal, setLocal} from "../../../common/utils";
import pcService from './../../../other/pc.service';
import constantConfig from './../../../configs/constant.config';

const FormItem = Form.Item;
const ChooseScrollTop = 'menu_icon_choose_scroll_top';

class CreateMenuForm extends React.Component {

    currentScrollTop = {}; //记录图标选择框当前滚动条的位置
    state = {
        loading: false,
        formValueInit: {},
        selectTreeData: null
    };

    componentWillMount() {
        if (this.props.location.query.editId) {
            this.init(this.props.location.query.editId);
        }
    }

    init = (editId) => {
        this.setState({loading: true});
        get({
            url: apiConfig.rui.basic.menu.getMenuById,
            params: {
                menuId: editId
            }
        }).then((res) => {
            this.setState({
                loading: false,
                formValueInit: {
                    ...res
                }
            })
        }).catch((error) => {
            this.setState({loading: false});
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.editId && nextProps.editId !== this.props.editId) {
            this.init(nextProps.editId);
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            //console.log('values--->', values);
            if (!err) {
                let menuLevel = 0;
                let isNoChange = false;
                const selectTreeData = this.state.selectTreeData;
                if (this.props.location.query.editId) {
                    if (!selectTreeData) {
                        isNoChange = true;
                        menuLevel = this.state.formValueInit.menuLevel;
                    }
                }
                if (!isNoChange) {
                    let parentLevel = selectTreeData.menuLevel;
                    if (parentLevel) {
                        menuLevel = parseInt(parentLevel) + 1;
                    }
                }
                let data;
                let url;
                if (!this.props.location.query.editId) {
                    url = apiConfig.rui.basic.menu.addMenu;
                    data = {
                        ...values,
                        menuLevel
                    }
                }
                else {
                    url = apiConfig.rui.basic.menu.editMenu;
                    data = {
                        ...this.state.formValueInit,
                        ...values,
                        menuLevel
                    }
                }
                this.setState({loading: true});
                post({
                    url: url,
                    data: data
                }).then((res) => {
                    message.success(constantConfig.SAVE_SUCCESS);
                    this.setState({loading: false});
                    this.addMenuIconScrollTop(this.currentScrollTop);
                    this.goBack(true);
                }).catch((error) => {
                    message.error(constantConfig.SAVE_FAIL);
                    this.setState({loading: false});
                });
            }
        });
    };

    //记录图标所在容器的位置
    addMenuIconScrollTop = (data) => {
        if(!data.type || !data.scrollTop) return;
        const current = getLocal(ChooseScrollTop) ? JSON.parse(getLocal(ChooseScrollTop)) : [];
        let targetIndex = current.findIndex(item => item.type === data.type);
        //如果已经存储了这个值，则更新这个值,否则添加这个值
        if (targetIndex !== -1) {
            current.splice(targetIndex, 1, data)
        } else {
            current.push(data);
        }
        setLocal(ChooseScrollTop, JSON.stringify(current));
    };

    handelCancel = () => {
        this.goBack(false);
    };

    goBack = (isRefreshParent = false) => {
        let panelId = pcService.getIdByPath("/app/menuManage");
        pcService.removePanel(panelId,true,'/app/menuList');
        //this.props.removeTabPane(this.props.paneKey, isRefreshParent);
    };

    handelSelectTreeData = (selectTreeData) => {
        //console.log("selectTreeData",selectTreeData);
        this.setState({
            selectTreeData
        });
    };

    getCurrentScrollTop = (type, scrollTop) => {
        this.currentScrollTop = {type, scrollTop};
    };

    render() {
        const {form} = this.props;
        const {loading, formValueInit} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6}

            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18}
            }
        }

        return (
            <Card bordered={false} className='createMenuFormWrapper'>
                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem {...formItemLayout} label="父级菜单">
                                {form.getFieldDecorator('parentId', {
                                    initialValue: formValueInit.parentId,
                                    rules: [
                                        {required: true, message: '请选择父级菜单'},
                                    ],
                                })(<SelectMenuTree setSelectTreeData={this.handelSelectTreeData}/>)}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem {...formItemLayout} label="名称">
                                {form.getFieldDecorator('menuName', {
                                    initialValue: formValueInit.menuName,
                                    rules: [
                                        {required: true, message: '请输入名称'},
                                        {max: 8, message: '最多输入8个字'},
                                        {pattern: /^[^\s]*$/, message: '请不要输入空格'}
                                    ],
                                })(<Input placeholder="请输入"/>)}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem {...formItemLayout} label="图标">
                                {form.getFieldDecorator('menuIcon', {
                                    initialValue: formValueInit.menuIcon
                                })(<MenuIconChoose getCurrentScrollTop={this.getCurrentScrollTop}/>)}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem {...formItemLayout} label="链接">
                                {form.getFieldDecorator('menuUrl', {
                                    initialValue: formValueInit.menuUrl
                                })(<Input placeholder="请输入"/>)}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem {...formItemLayout} label="排序">
                                {form.getFieldDecorator('menuOrder', {
                                    initialValue: formValueInit.menuOrder,
                                    rules: [
                                        {pattern: /^[^\s]*$/, message: '请不要输入空格'}
                                    ],
                                })(<Input placeholder="请输入"/>)}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} className="operationArea">
                            <Button onClick={this.handelCancel}>取消</Button>
                            <Button type="primary" htmlType="submit" loading={loading}>保存</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreateMenuForm));