import React, { Component } from 'react';
import VideoReact from '../../common/components/video/VideoReact';
import { Spin, message, Button, Input, Icon, Popconfirm } from 'antd';
import http from './../../common/axios/request';
import urls from '../../configs/api.config';
import PcService from './../../other/pc.service';
import { getLocal } from './../../common/utils';
import "./partyMemberEducationViewDetail.less";
import commuImg from './../../img/commuImg.png'

let userId = getLocal("poc_headers") ? getLocal("poc_headers").user_id : "";

export class PartyMemberEducationViewDetail extends Component {
    state = {
        loading: false,
        dataInfo: [],
        lists: [],
        box: "",
        totalCount: "",
        videoHeight: "", 
    }

    componentDidMount() {
        //获取传参
        let id = this.props.location ? this.props.location.query.recordId : '';
        this.fetchData();
        let that = this;
        this.setState({ loading: true, });
        http.request({
            method: 'get',
            url: urls.app.partyMemberEducation.findById,
            params: { id },
        }).then(res => {
            if (res.success === true) {
                that.setState({
                    dataInfo: res.data,
                    loading: false
                });
            } else {
                message.error(res.message);
                that.setState({
                    loading: false
                });
            }
        }).catch(err => {
            message.error(err.message);
            that.setState({
                loading: false
            });
        });

        //根据左侧video重新计算右边高度
        this.updateSize();
    }

    updateSize = () => {
        let videoBox = document.getElementById("toppoint");
        if (videoBox) {
            this.setState({
                videoHeight: videoBox.clientHeight - 130
            })
        }
    }
    /* 获取列表数据 */
    fetchData = (params) => {
        let _params = Object.assign({}, /* { commentType: 'COURSESCHEDULE' },  */{ resourceId: this.props.location.query.recordId }, params);
        this.setState({ loading: true }, () => {
            PcService.fetchData(urls.app.comment.page, _params, null, (data) => {
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.active = "";
                })
                this.setState({
                    loading: false,
                    lists: lists,
                    totalCount: data.totalCount
                });
            }, (err) => {
                this.setState({ loading: false });
            });
        });

    }
    send = () => {
        let sendParam = {
            resourceId: this.props.location.query.recordId,
            content: this.refs.inputvalue.state.value,
            publishStatus: 1,
            operator: userId ? userId : 'string',
            publisher: userId ? userId : 'string',
            commentType: "COURSESCHEDULE"
        }
        this.setState({ loading: true, });
        http.request({
            method: 'post',
            url: urls.app.comment.insertEntity,
            data: sendParam,
        }).then(res => {
            if (res.success === true) {
                this.fetchData();
                this.refs.inputvalue.state.value = "";
                this.setState({ loading: false });
            } else {
                message.error(res.message);
                this.setState({ loading: false });
            }
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }
    click = (id, $event) => {
        this.setState({
            currentId: id,
            box: '1'
        });
    }
    deleteItem = (item, $event) => {
        let url = urls.app.comment.logic;
        let that = this;
        PcService.deleteItem(url, {}, item.id,
            (data) => {
                message.success("删除成功!");
                that.fetchData();
                this.setState({ loading: false });
            }, err => {
                this.setState({ loading: false });
            }
        );
    }; 
    render() {
        const { loading, dataInfo, lists, currentId } = this.state;
        return (
            <Spin spinning={loading} className="">
                <div className={"pmevd"}>
                    <div className={"tital"}>
                        {dataInfo.courseName}
                    </div>
                    <div className={"video-comm"}>
                        <div className={"video"} id="toppoint" >{dataInfo.videoMongdbId ? <VideoReact dataInfo={dataInfo} /> : ""}</div>
                        <div className={"comment"}>
                            <span className={"span1"}> 评论 </span>
                            <div className={"li"} style={{ height: this.state.videoHeight }}>
                                {
                                    lists.map((v, n) => {
                                        return (
                                            <li key={n}>
                                                <div onClick={this.click.bind(this, v.id)} className={`comment-list-border${v.id === currentId ? " active" : ""}`} >
                                                    <div className="comment-inner-pic">
                                                        <div className="pic-img">
                                                            <img src={v.headImg ? urls.file.viewImg + "/" + v.headImg : commuImg}></img>
                                                            <div className="pic-name">{v.publisherName}</div>
                                                        </div>
                                                        
                                                        <Popconfirm
                                                            title="确定要删除吗?" 
                                                            onConfirm={this.deleteItem.bind(this, v)}
                                                            onCancel={this.cancel}
                                                            okText="确定"
                                                            cancelText="取消" >
                                                            <div className="comment-inner-in" ><Icon type="delete" /></div>
                                                        </Popconfirm>
                                                    </div>
                                                    <div className="comment-info" > {v.content}</div>
                                                    <div className="comment-time" > {v.createdTime ? v.createdTime.substring(0,16) : v.createdTime}</div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </div>
                            <Input ref={"inputvalue"}></Input>
                            <Button onClick={this.send}>发表</Button>
                        </div>
                    </div>
                    <div className={"content-info"}>
                        <div>课程名称：{dataInfo.courseName}</div>
                        <div>课程类别：{dataInfo.type === "PICTURECONTENT" ? "视听课程" : "图文课程"}</div>
                        <div>课程学时：{dataInfo.minStudyMinutes}</div>
                        <div>创建时间：{PcService.dateFormat(dataInfo.createdTime, 'yyyy年MM月dd日')}</div>
                        <div>是否必修：{dataInfo.mustRequired ? "是" : "否"}</div>
                        <div className={"fileslist"}>
                        课程附件：
                        {
                            dataInfo.studyFileList && dataInfo.studyFileList.map((v, n) => {
                                return (
                                    <div key={n}>
                                        <a target="_blank" className={"ant-upload-list-item-name"} title={v.fileName}
                                            href={urls.file.viewImg + '/' + v.fileId} >{v.fileName}</a>
                                    </div>
                                )
                            })
                        }
                    </div>
                        <div>课程介绍：<div dangerouslySetInnerHTML={{ __html: dataInfo.desp }}></div></div>
                    </div>
                </div>
            </Spin>
        )
    }
}

export default PartyMemberEducationViewDetail;
