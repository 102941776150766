import React, {Component} from 'react';
import {Tabs} from 'antd';
import DemocraticReviewList from './DemocraticReviewList';
import DemocraticReviewItemBank from './DemocraticReviewItemBank';
import DemocraticReviewTemplate from './DemocraticReviewTemplate';
import './democratic-review.css';

const {TabPane} = Tabs;

class DemocraticReview extends Component {

    render() {
        /* 导航菜单 */
        const TabsMenus = [{
            tab: "评议题库管理",
            component: DemocraticReviewItemBank
        }, {
            tab: "评议模板管理",
            component: DemocraticReviewTemplate
        },{
            tab: "评议任务管理",
            component: DemocraticReviewList
        } ];

        return (
            <div className="democratic-review">
                <Tabs defaultActiveKey="0">
                    {
                        TabsMenus.map((v, n) => (
                            <TabPane tab={v.tab} key={n}>
                                <v.component/>
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        )
    }
}

export default DemocraticReview;
