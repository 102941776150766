import React, { Component } from 'react';
import { Router, Route, hashHistory } from 'react-router';
import Home from './../components/home/Home';
import Login from '../components/login/Login';
import Main from '../components/main/Main';
import mainRouters from './mainRouterArr.service';

const Page404 = () => <div style={{ 'padding': '20px' }}><h1>404</h1><h1>Not found!</h1></div>;

class Routers extends Component {

    render() {
        return (
            <Router history={hashHistory}>
                <Route exact path="/" component={Home} />
                <Route path="home" component={Home} />
                <Route path="login" component={Login} />
                <Route path="app" component={Main} >
                    {
                        mainRouters.map((v, n) => <Route key={n} path={v.path} />)
                    }
                </Route>
                <Route path="404" component={Page404} />
                <Route path="*" component={Page404} />
            </Router >
        )
    }
}

export default Routers;
