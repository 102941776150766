import React, { Component } from 'react';
import { Button, Divider, Icon, message, Modal, Popover, Row, Spin, Popconfirm } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import pcService from './../../other/pc.service';
import urls from './../../configs/api.config';
import ExamQuestionSelectModal from "./ExamQuestionSelectModal";
import DetailModal from "../../common/components/detailModal/DetailModal";
import './examQuestion.css';

class ExamPaper extends Component {

    state = {
        visible: false,
        loading: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        hasFinish: false,
        create: true,
        detailVisible: false,
        selectedQuestion: [],
        totalScore: 0,
        modalOptions: {
            title: "试卷",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: []
    };
    searchFieldsList = [{
        name: "keyWord",
        editor: "normal",
        value: "",
        displayName: "名称或者描述",
        hideDisplayName: true,
        originValue: ""
    }];
    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "id",
            editor: "hidden",
            value: ""
        },
        {
            name: "paperName",
            editor: "normal",
            value: "",
            displayName: "试卷名称",
            rules: ["required",
                { max: 50, message: "试卷名称长度为50个字符" }
            ]
        },
        {
            name: "typeDict",
            editor: "select",
            value: "",
            originValue: pcService.getDataDictionary("EXAM_TYPE")[0] ? pcService.getDataDictionary("EXAM_TYPE")[0].id : "",
            displayName: "考试类型",
            opts: pcService.getDataDictionary("EXAM_TYPE"),
            rules: ["required"]
        },
        {
            name: "totalScore",
            editor: "normal",
            displayName: "试卷总分",
            value: this.state.totalScore,
            originValue: 0,
            unavailable: true,
            rules: []
        },
        {
            //试卷分类，0：考试，1：民主评议
            name: "paperCategory",
            editor: "hidden",
            value: "",
            originValue: 0
        }, {
            name: "description",
            editor: "textarea",
            value: "",
            displayName: "试卷描述",
            rules: [
                { max: 200, message: "试卷描述长度为200个字符" }
            ]
        }
    ];
    /* 详情表单列表 */
    detailList = [...this.fieldsList];

    listColumns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '题目',
            key: "title",
            dataIndex: 'title',
            render: text => text.length > 20 ? <Popover content={text} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {text.slice(0, 20) + '...'}
            </Popover> : text
        },
        {
            title: '分类',
            dataIndex: 'category',
            render: (value) => pcService.numberToText(value, pcService.getDataDictionary("QUESTION_CATEGORY"))
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'clode',
            render: (value) => value === 0 ? "单选" : "多选"
        },
        {
            title: '分值',
            dataIndex: 'score'
        },
        {
            title: '选项',
            dataIndex: 'optionView',
            align: 'center',
            render: text => text.length > 20 ? <Popover content={text} placement="topRight"
                overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                {text.slice(0, 20) + '...'}
            </Popover> : text
        }
    ];
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current }, params);
        //固定参数，该参数值表示考试
        _params.paperCategory = 0;
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.examPaper.page, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                let lists = data.rows ? data.rows : [];
                lists.length && lists.map(v => {
                    v.displayTip = 'none';
                });
                this.setState({
                    loading: false,
                    lists: lists,
                    pagination: _pagination
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });
    };

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) this.fetchData(values);
        });
    };

    /* 新增修改民主评议 */
    createOne = (item, create) => {
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            pcService.bindFormdData(item, this.fieldsList);
            this.setState({
                selectedQuestion: item.questions
            });
        } else {
            _create = true;
            pcService.initFormList(this.fieldsList);
        }
        this.setState({
            modalOptions: { ...modalOptions, visible: true },
            create: _create
        });

    };
    /* modal确定回调函数 */
    onOk = ($event) => {
        let { modalOptions, selectedQuestion, create } = this.state;
        let forms = this.refs.examQuestionSelectModal.refs.forms.getForm();
        forms.validateFields((errors, values) => {
            if (!errors) {
                if (selectedQuestion.length === 0) {
                    message.info("请至少选择一道题试题!");
                    return;
                }
                let url = "";
                if (create) {
                    url = urls.app.examPaper.addEntity;
                } else {
                    url = urls.app.examPaper.updateEntity;
                }
                values.questions = selectedQuestion.map(v => ({ id: v.id }));
                this.setState({ modalOptions: { ...modalOptions, modalsLoading: true } })
                pcService.formSubmit(url, true, values, {}, (data) => {
                    message.success("操作成功");
                    this.setState({
                        loading: false,
                        selectedQuestion: [],
                        modalOptions: { ...modalOptions, visible: false, modalsLoading: false }
                    });
                    this.fetchData();
                }, err => {
                    message.error(err);
                    this.setState({ loading: false, modalOptions: { ...modalOptions, true: false, modalsLoading: false } });
                });
            }
        });
    }
    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            },
            selectedQuestion: []
        });
    };
    onDetailCancel = () => {
        this.setState({
            detailVisible: false
        });
    };
    /* 详情 */
    detailItem = (item) => {
        pcService.bindFormdData(item, this.detailList);
        this.setState({
            selectedQuestion: item.questions,
            detailVisible: true,
        });
    };

    /**
     * 设置父组件状态值
     * @param args
     */
    setParentState = (args) => {
        this.setState({
            ...args
        }, () => {
            let { selectedQuestion } = this.state;
            let score = 0;
            selectedQuestion.map(question => {
                score += (question.score) * 1000
            });
            this.refs.examQuestionSelectModal.refs.forms.getForm().setFieldsValue({ totalScore: score / 1000 });
        });
    };

    /**
     * 删除操作
     * @param item
     * @param form
     * @param $event
     */
    deleteItem = (item = [], form, $event) => {
        let ids = item.join(",");
        let that = this;
        that.setState({ loading: true });
        pcService.deleteItem(urls.app.examPaper.logic, {}, ids,
            (data) => {
                message.success("删除成功!");
                setTimeout(function () {
                    that.fetchData();
                }, 500);
                that.setState({ loading: false, selectedRowKeys: [] });
            }, err => {
                that.setState({ loading: false });
            }
        );
    };

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                        <Divider type="vertical" />
                        <a onClick={this.detailItem.bind(this, record)} ><Icon type="info-circle" /> 详情</a>
                    </span>
                )
            }
        },
        {
            title: '试卷名称',
            dataIndex: 'paperName',
            key: 'paperName'
        },
        {
            title: '试卷编码',
            dataIndex: 'paperCode',
            key: 'paperCode'
        },
        {
            title: '试卷总分',
            dataIndex: 'totalScore',
            key: 'totalScore'
        },
        {
            title: '试卷描述',
            dataIndex: 'description',
            key: 'description',
            render: (value, record, index) => {
                if (value !== null && value.length > 20) {
                    return (
                        <Popover content={value} placement="topRight"
                            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                            {value.slice(0, 20) + '...'}
                        </Popover>)
                } else {
                    return value;
                }
            }
        },
        {
            title: '试卷状态',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (value, record, index) => value === "true" ? "启用" : "停用"
        }
    ];

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        this.fetchData();
    }

    render() {

        const { loading, selectedRowKeys, pagination, lists, visible, modalOptions, create, selectedQuestion } = this.state;
        const { handleVisibleChange, cancel, onSelectChange, columns, fetchData, searchFieldsList, searchOnSubmit, createOne, fieldsList, detailList, onOk, onCancel, setParentState, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, width: 960, title: (create ? "新增" : "编辑") + modalOptions.title }
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        const detailFormItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 21 }
            }
        }
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增</Button>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button icon="delete" disabled={!allowDel} type="default">删除</Button>
                                </Popconfirm>
                            </div>
                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={searchOnSubmit} showReset fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable rowSelection={rowSelection} columns={columns} pagination={pagination} dataSource={lists} fetchData={fetchData} bordered={false} />
                        </div>
                    </Row>
                </div>
                <Modal {...modal}>
                    <ExamQuestionSelectModal modals={modal} ref="examQuestionSelectModal" lists={selectedQuestion} onOk={modal.onOk}
                        fieldsList={fieldsList}
                        modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout}
                        setParentState={setParentState} />
                </Modal>
                <Modal centered title="试卷详情" width={800} visible={this.state.detailVisible} footer={null} onCancel={this.onDetailCancel}>
                    <DetailModal lists={selectedQuestion} fieldsList={detailList} formItemLayout={detailFormItemLayout} columns={this.listColumns} name={"基本信息"} />
                </Modal>
            </Spin>
        )
    }
}

export default ExamPaper;
