import React, { Component } from 'react';
import PartyActivityNew from './PartyActivityNew';

export class PartyActivityUpdate extends Component {
    render() {
        const id = this.props.location.query.recordId;
        return (
            <div>
                <PartyActivityNew updateId={id} />
            </div>
        )
    }
}

export default PartyActivityUpdate
