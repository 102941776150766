import React from 'react';
import { Form, Input, Row, Col, Icon } from 'antd';
import { ButtonAuth } from '../../../sharedCommponents/AuthWidget';
const FormItem = Form.Item;

class SearchForm extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log(values);
                for (let p in values) {//遍历json对象的每个key/value对,p为key
                    if (values[p] === '') {
                        values[p] = null;
                    }
                }
                this.props.handleSearch(values);
            }
        });

    };
    handleFormReset = () => {
        const { form, handleSearch } = this.props;
        form.resetFields();
        handleSearch();
    };

    render() {
        const { form: { getFieldDecorator }, searchInitValue } = this.props;
        const formItemLayout = {
            labelCol: { span: 0 },
            wrapperCol: { span: 24 }
        };
        return (
            <Form onSubmit={this.handleSubmit} layout='inline' className='SearchForm'>
                <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-control-wrapper">
                        <div className="ant-form-item-control">
                            {getFieldDecorator('keyword', {
                                initialValue: searchInitValue.keyword,
                                rules: [{ pattern: /^[^\s]*$/, message: '请不要输入空格' }],
                            })(
                                <Input placeholder="请输入关键字查询" />)}
                            <ButtonAuth type="primary" htmlType="submit"><Icon type="search" /> 查询</ButtonAuth>
                            <ButtonAuth onClick={this.handleFormReset}><Icon type="redo" /> 重置</ButtonAuth>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default Form.create()(SearchForm);