import appConfig from './app.config';

/* API接口应用代理路径(网关地址) */
const rewriteUrl = process.env.NODE_ENV !== 'production' ? 'poc.api/' : appConfig.pocAddress;

/* API接口应用访问地址 */
const apiConfig = {
    //RUI项目
    rui: {
        //注册模块
        register: {
            findAll: rewriteUrl + 'config-center-service/api/platform/findAll',
            getOne: rewriteUrl + 'config-center-service/api/sayHello/getOne'
        },
        //登录模块
        login: {
            loginOn: rewriteUrl + 'basic/login/security',
            loginOut: rewriteUrl + 'basic/login/logout',
            getMenus: rewriteUrl + 'basic/home/menu'
        },
        //设置模块
        setting: {
            add: rewriteUrl + 'basic/setting/add',
            edit: rewriteUrl + 'basic/setting/edit',
            addByJson: rewriteUrl + 'basic/setting/add/json',
            editByJson: rewriteUrl + 'basic/setting/edit/json',
            list: rewriteUrl + 'basic/setting/list',
            delete: rewriteUrl + 'basic/setting/delete',
            deleteAll: rewriteUrl + 'basic/setting/deleteAll',
            findGlobalByOne: rewriteUrl + 'basic/setting/findGlobalByOne',
            resetStructures: rewriteUrl + 'basic/setting/resetStructures',
            resetTenants: rewriteUrl + 'basic/setting/resetTenants',
            findById: rewriteUrl + 'basic/setting/findById'
        },
        basic: {
            monitor: {
                druidIndex: rewriteUrl.rui + 'druid/index',
            },
            //在线用户
            online: {
                list: rewriteUrl.rui + 'basic/online/list',
                logout: rewriteUrl.rui + 'basic/online/logout',
            },
            tenant: {
                add: rewriteUrl + 'basic/tenant/add',
                edit: rewriteUrl + 'basic/tenant/edit',
                addByJson: rewriteUrl + 'basic/tenant/add/json',
                editByJson: rewriteUrl + 'basic/tenant/edit/json',
                list: rewriteUrl + 'basic/tenant/list',
                delete: rewriteUrl + 'basic/tenant/delete',
                deleteAll: rewriteUrl + 'basic/tenant/deleteAll',
                resetMenus: rewriteUrl + 'basic/tenant/resetMenus',
                findById: rewriteUrl + 'basic/tenant/findById'
            },
            menu: {
                getAllMenus: rewriteUrl + 'basic/menu/list',
                getMenuById: rewriteUrl + 'basic/menu/findById',
                getIndexMenus: rewriteUrl + 'basic/menu/index',
                getSubMenus: rewriteUrl + 'basic/menu/getSub',
                addMenu: rewriteUrl + 'basic/menu/add',
                editMenu: rewriteUrl + 'basic/menu/edit',
                deleteMenu: rewriteUrl + 'basic/menu/delete',
                deleteAll: rewriteUrl + 'basic/menu/deleteAll',
                addAuthorities: rewriteUrl + 'basic/menu/addAuthorities',
                getAuthoritiesById: rewriteUrl + 'basic/menu/getAuthoritiesById',
                deleteAuthorities: rewriteUrl + 'basic/menu/deleteAuthorities'
            },
            user: {
                getAllUsers: rewriteUrl + 'basic/user/list',
                addUser: rewriteUrl + 'basic/user/add',
                editUser: rewriteUrl + 'basic/user/edit',
                addUserByJson: rewriteUrl + 'basic/user/add/json',
                editUserByJson: rewriteUrl + 'basic/user/edit/json',
                deleteUser: rewriteUrl + 'basic/user/delete',
                addRoles: rewriteUrl + 'basic/user/addRoles',
                addStructures: rewriteUrl + 'basic/user/addStructures',
                addPositions: rewriteUrl + 'basic/user/addPositions',
                resetRoles: rewriteUrl + 'basic/user/resetRoles',
                resetStructures: rewriteUrl + 'basic/user/resetStructures',
                resetPositions: rewriteUrl + 'basic/user/resetPositions',
                findById: rewriteUrl + 'basic/user/findById',
                deleteAll: rewriteUrl + 'basic/user/deleteAll',
                forgotEmail: rewriteUrl + 'basic/user/forgotEmail',
                forgotReset: rewriteUrl + 'basic/user/forgotReset',
            },
            organization: {
                getAllOrganizations: rewriteUrl + 'basic/structure/list',
                getOrganizationById: rewriteUrl + 'basic/structure/findById',
                getIndexOrganizations: rewriteUrl + 'basic/structure/index',
                getSubOrganizations: rewriteUrl + 'basic/structure/getSub',
                addOrganization: rewriteUrl + 'basic/structure/add',
                editOrganization: rewriteUrl + 'basic/structure/edit',
                deleteOrganization: rewriteUrl + 'basic/structure/delete',
                deleteAll: rewriteUrl + 'basic/structure/deleteAll',
            },
            post: {
                getAllPosts: rewriteUrl + 'basic/position/list',
                getPostById: rewriteUrl + 'basic/position/findById',
                getIndexPosts: rewriteUrl + 'basic/position/index',
                getSubPosts: rewriteUrl + 'basic/position/getSub',
                addPost: rewriteUrl + 'basic/position/add',
                editPost: rewriteUrl + 'basic/position/edit',
                deletePost: rewriteUrl + 'basic/position/delete',
                deleteAll: rewriteUrl + 'basic/position/deleteAll'
            },
            role: {
                getAllRoles: rewriteUrl + 'basic/role/list',
                getRoleById: rewriteUrl + 'basic/role/findById',
                getIndexRoles: rewriteUrl + 'basic/role/index',
                getSubRoles: rewriteUrl + 'basic/role/getSub',
                addRole: rewriteUrl + 'basic/role/add',
                editRole: rewriteUrl + 'basic/role/edit',
                deleteRole: rewriteUrl + 'basic/role/delete',
                deleteAll: rewriteUrl + 'basic/role/deleteAll',
                getRightsAndAuthorities: rewriteUrl + 'basic/role/getRightsAndAuthorities',
                setRightsAndAuthorities: rewriteUrl + 'basic/role/setRightsAndAuthorities'
            }
        }
    },
    //业务逻辑
    app: {
        //电子书
        ebook: {
            //栏目
            columnPage: rewriteUrl + "ebook/column/page",//电子书栏目列表 
            columnFindTree: rewriteUrl + "ebook/column/findTree",//电子书栏目树形结构
            findListGroupByType: rewriteUrl + "ebook/column/findListGroupByType",
            columnAdd: rewriteUrl + "ebook/column/add",//电子书栏目新增
            columnUpdate: rewriteUrl + "ebook/column/update",//电子书栏目修改 
            columnDelete: rewriteUrl + "ebook/column/",//电子书栏目删除  
            changeEnabled: rewriteUrl + "ebook/column/changeEnabled",//电子书栏目启用停用
            columnFindByTree: rewriteUrl + "ebook/column/findTree",//电子书栏目启用停用
            //书籍
            bookPage: rewriteUrl + "ebook/book/page",//电子书栏目列表 
            bookAdd: rewriteUrl + "ebook/book/add",//电子书栏目新增 
            bookUpload: rewriteUrl + "ebook/book/upload",//电子书上传
            bookUpdate: rewriteUrl + "ebook/book/update",//电子书栏目修改
            delete: rewriteUrl + "ebook/book/",//电子书栏目删除   
            bookChangeEnabled: rewriteUrl + "ebook/book/changeEnabled",//电子书栏目启用停用 
            import: rewriteUrl + "ebook/book/import",//电子书导入(压缩包)
            confirm: rewriteUrl + "ebook/book/confirm",//电子书导入确认
            //书评
            commnetPage: rewriteUrl + "ebook/comment/page",//电子书评 
            commnetDelete: rewriteUrl + "ebook/comment/",//电子书评删除
        },
        //首页统计
        index: {
            getCommOrgaNum: rewriteUrl + "index/getCommOrgaNum",//数量统计4
            getPointDistributeByStructureId: rewriteUrl + "index/getPointDistributeByStructureId",//党组织活动积分
            getReleseNumDistribution: rewriteUrl + "index/getReleseNumDistribution",//党建资讯 [numType--0:阅读{默认值} 1:发布]
            getCommPointAttribute: rewriteUrl + "index/getCommPointAttribute",//党员动态积分
            pointRank: rewriteUrl + "point/communist/statistic/pointRank",//积分排名
            getSchduleDistribute: rewriteUrl + "index/getSchduleDistribute",//学习情况
            getStudyRadio: rewriteUrl + "index/getStudyRadio",//学习率
            getExamRadio: rewriteUrl + "index/getExamRadio",//学习率,
            pointPartyStatisticQuarterlyDetail: rewriteUrl + "pointPartyStatisticQuarterlyDetail/page",//党组织动态积分
        },
        organization: {
            addOrganization: rewriteUrl + 'PartyOrganization/addEntity',
            updateEntity: rewriteUrl + 'PartyOrganization/updateEntity',
            deleteLogic: rewriteUrl + 'PartyOrganization/delete/logic',
            page: rewriteUrl + 'PartyOrganization/page',
            communistInfoPage: rewriteUrl + 'CommunistInfo/page',
            selectFlowDataByPage: rewriteUrl + 'CommunistInfo/selectFlowDataByPage',
            partyPage: rewriteUrl + 'PartyOrganization/page',
            findById: rewriteUrl + 'PartyOrganization/findById',
            pageByStructureId: rewriteUrl + 'PartyOrganization/pageByStructureId',
            addParty: rewriteUrl + '/PartyOrganization/addParty',//组织机构及其党组织新增
            deleteParty: rewriteUrl + '/PartyOrganization/deleteParty',
            selectByStructureId: rewriteUrl + 'PartyOrganization/selectByStructureId',
            partyPageByStructureId: rewriteUrl + 'CommunistInfo/pageByStructureId',
            unitsInfoPage: rewriteUrl + 'UnitsInfo/page',//组织单位分页列表(带权限过滤)
            selectMembers: rewriteUrl + 'CommunistInfo/selectMembers',
            selectMembersByAge: rewriteUrl + 'CommunistInfo/selectMembersByAge',
            selectMembersBySex: rewriteUrl + 'CommunistInfo/selectMembersBySex',
            selectOrgAndUsers: rewriteUrl + 'CommunistInfo/selectOrgAndUsers',//查询党组织及其成员
            addEntity: rewriteUrl + 'CommunistInfo/addEntity',//新增数据
            addBaseOrganizationForParty: rewriteUrl + 'PartyOrganization/addParty', //平台框架党建特殊组织机构
            updateBaseOrganizationForParty: rewriteUrl + 'PartyOrganization/updateParty', //平台框架党建特殊组织机构
            deleteFlowEntity: rewriteUrl + 'CommunistInfo/deleteFlowEntity',
            selectStructureTreeById: rewriteUrl + 'CommunistInfo/selectStructureTreeById',//根据用户查询党组织及其成员根树
            partySelectMembers: rewriteUrl + 'CommunistInfo/selectMembers',//根据组织机构id获取党组成员
            resetStructuresForParty: rewriteUrl + '/PartyOrganization/resetStructures' //党建用户管理设置组织结构
        },
        organizationLife: {
            addOrganizationLife: rewriteUrl + 'party/activity/plan/insert',
            page: rewriteUrl + 'party/activity/plan/page',
            communistInfoPage: rewriteUrl + 'CommunistInfo/page',
            partyPage: rewriteUrl + 'PartyOrganization/page',
            findById: rewriteUrl + 'party/activity/plan/findById',
            pageByStructureId: rewriteUrl + 'party/activity/plan/pageByStructureId',
            selectMembersBySex: rewriteUrl + 'CommunistInfo/selectMembersBySex',
            selectMembersByAge: rewriteUrl + 'CommunistInfo/selectMembersByAge',
            selectOrgAndUsers: rewriteUrl + 'CommunistInfo/selectOrgAndUsers', //查看党组织及其成员
        },
        talk: {
            addtalk: rewriteUrl + 'talk/addEntity',
            updateEntity: rewriteUrl + 'talk/updateEntity',//修改数据
            page: rewriteUrl + 'talk/page',
            findById: rewriteUrl + 'talk/findById',
            deleteLogic: rewriteUrl + 'talk/delete/logic'
        },
        message: {
            messageList: rewriteUrl + 'message/page',//列表
            deleteLogic: rewriteUrl + 'message/delete/logic',//逻辑删除
            readMessages: rewriteUrl + 'message/readMessages',//标记已读
            getMessageCount: rewriteUrl + "message/getMessageCount",//获取数量
        },
        //文章发布，内容管理
        informationRelease: {
            list: rewriteUrl + 'informationRelease/page',//列表
            add: rewriteUrl + 'informationRelease/add',//新增
            update: rewriteUrl + 'informationRelease/update',//修改
            delete: rewriteUrl + 'informationRelease/delete/logic',//删除
            findById: rewriteUrl + 'informationRelease/findById',//获取单条数据
        },
        //栏目管理
        columnManage: {
            treeList: rewriteUrl + "poc/columnManage/find/all",//获取全部栏目
            add: rewriteUrl + "poc/columnManage/add",//新增栏目
            update: rewriteUrl + "poc/columnManage/update",//修改栏目
            delete: rewriteUrl + "poc/columnManage/delete",//删除栏目
        },
        partyActivity: {
            logic: rewriteUrl + 'party/activity/plan/delete/logic',//逻辑删除数据 
            findById: rewriteUrl + 'party/activity/plan/findById',//查询单条数据
            insert: rewriteUrl + 'party/activity/plan/insert',//新增一条 活动计划安排
            page: rewriteUrl + 'party/activity/plan/page',//分页列表(带权限过滤)
            pageByStructureId: rewriteUrl + 'party/activity/plan/pageByStructureId',//根据StructureId查询数据
            update: rewriteUrl + 'party/activity/plan/update',//修改
            publish: rewriteUrl + 'party/activity/plan/publish',//活动发布
            updatePublishStatus: rewriteUrl + 'party/activity/plan/updatePublishStatus',//活动发布updatePublishStatus
            signPage: rewriteUrl + 'party/activity/sign/page',//签到分页列表
            supplement: rewriteUrl + 'party/activity/plan/record/supplement',//活动结束后，补充会议记录和附件
        },
        assistRecord: {
            logic: rewriteUrl + 'assistRecord/delete/logic',//逻辑删除数据
            findById: rewriteUrl + 'assistRecord/findById',//查询单条数据
            add: rewriteUrl + 'assistRecord/add',//新增一条 
            page: rewriteUrl + 'assistRecord/page',//分页列表
            update: rewriteUrl + 'assistRecord/update',//修改内容（）
            updatePublishStatus: rewriteUrl + 'assistRecord/updatePublishStatus',//修改困难帮扶记录
            deleteEntityPhysical: rewriteUrl + 'CommunistInfo/delete/physical',//删除困难党员、流动、转接
        },
        partyMember: {
            getUsersByOrgIdBeforAdd: rewriteUrl + 'CommunistInfo/getUsersByOrgIdBeforAdd',//
            addEntity: rewriteUrl + 'CommunistInfo/addEntity',// 新增数据
            uploadsuserdata: rewriteUrl + 'CommunistInfo/uploadsuserdata',//导入党员数据
            updateMember: rewriteUrl + 'CommunistInfo/updateCommunistInfo',
            partyMemberList: rewriteUrl + 'CommunistInfo/page',//党员列表
            partyMemberListOrgan: rewriteUrl + 'CommunistInfo/selectDataByPage',//党员列表
            getPartyMemberById: rewriteUrl + 'CommunistInfo/findById',//获取单个党员信息
            communistOrganizationFindById: rewriteUrl + 'CommunistOrganization/findById',//查询单条数据
            partyMemberLogicDelete: rewriteUrl + 'CommunistInfo/delete/logic',//逻辑删除
            partyMemberLogicDeleteOrgan: rewriteUrl + 'CommunistOrganization/delete/logic',//逻辑删除
            deleteChangeEntity: rewriteUrl + "CommunistInfo/deleteChangeEntity",//删除组织关系转接
            updateCommunistInfo: rewriteUrl + "CommunistInfo/updateCommunistInfo",//
            export: rewriteUrl + "CommunistInfo/export",//导出党员信息
            resetRoles: rewriteUrl + "CommunistInfo/resetRoles",
            listRoles: rewriteUrl + "CommunistInfo/listRoles",
            addFlowEntity: rewriteUrl + "CommunistInfo/addFlowEntity",//流动党员新增
            updateFlowData: rewriteUrl + "CommunistInfo/updateFlowData",//流动党员和组织关系转移新增
            addChangeEntity: rewriteUrl + "CommunistInfo/addChangeEntity",//组织关系转移新增
        },
        jobInfo: {
            getJobInfoById: rewriteUrl + "JobInfo/findById",//获取工作信息（包括党员基本信息）
        },
        dataDictionary: {
            dataDictionaryList: rewriteUrl + 'poc/dataDictionary/page',//数据字典列表
            getAllDataDictionary: rewriteUrl + 'poc/dataDictionary/getDataDictionary',//获取所有子类
            dataDictionaryAdd: rewriteUrl + 'poc/dataDictionary/add',//新增
            dataDictionaryUpdate: rewriteUrl + 'poc/dataDictionary/update',//修改
            dataDictionarydeleteLogic: rewriteUrl + 'poc/dataDictionary/delete/logic',//逻辑删除
            dataDictionarydeletePhysical: rewriteUrl + 'poc/dataDictionary/delete/physical',//物理删除数据
            findByType: rewriteUrl + '/poc/dataDictionary/findByType',//根据类型获取字典数据
        },
        parytFeeManagement: {
            addEntity: rewriteUrl + 'partyDuesRule/addEntity',//新增数据
            logic: rewriteUrl + 'partyDuesRule/delete/logic',//逻辑删除数据 
            deleteEntityPhysical: rewriteUrl + 'partyDuesRule/delete/physical',//物理删除数据 
            findById: rewriteUrl + 'partyDuesRule/findById',//,//查询单条数据
            page: rewriteUrl + 'partyDuesRule/page',//分页列表(带权限过滤)
            pageByStructureId: rewriteUrl + 'partyDuesRule/pageByStructureId',//根据StructureId查询数据
            updateEntity: rewriteUrl + 'partyDuesRule/updateEntity',//修改数据
            //基数变更
            findByIdUpdate: rewriteUrl + 'partyDuesRuleHistory/findById',//查询单条数据
            findByPartyDuesRuleIdUpdate: rewriteUrl + 'partyDuesRuleHistory/findByPartyDuesRuleId',//根据党费基数查询变更记录
            pageUpdate: rewriteUrl + 'partyDuesRuleHistory/page',//分页列表
            //缴纳记录
            addEntityRecord: rewriteUrl + 'partyDuesRecord/addEntity',//手动添加一条缴纳记录
            findByIdRecord: rewriteUrl + 'partyDuesRecord/findById',//查询单条数据
            pageRecord: rewriteUrl + 'partyDuesRecord/page',//分页列表(带权限过滤)
            pageByStructureIdRecord: rewriteUrl + 'partyDuesRecord/pageByStructureId',//根据StructureId查询数据
            downloadTemplate: rewriteUrl + 'partyDuesRule/downloadTemplate',//导入模板下载
            uploadTemplate: rewriteUrl + 'partyDuesRule/upload',//上传待导入文件
            getRuleByCommunistInfoId: rewriteUrl + 'partyDuesRule/getRuleByCommunistInfoId',//根据党员id获取缴纳说明
            getTotalByCommunistInfoIdAndYearMonth: rewriteUrl + 'partyDuesRule/getTotalByCommunistInfoIdAndYearMonth',//根据党员id获取缴纳说明: rewriteUrl + 'partyDuesRule/getRuleByCommunistInfoId',//根据党员id获取缴纳说明
            findByPartyDuesRecordId: rewriteUrl + 'partyDuesDetail/findByPartyDuesRecordId',//根据缴纳记录查询明细
            confirm: rewriteUrl + 'partyDuesRecord/confirm',//确认一笔到款
        },
        partyMemberDevelopment: {
            deleteLogic: rewriteUrl + 'CommunistInfo/delete/logic',//逻辑删除数据党员 
            addEntity: rewriteUrl + 'ProgressMember/addEntity',//新增数据
            logic: rewriteUrl + 'ProgressMember/delete/logic',//逻辑删除数据
            findById: rewriteUrl + 'ProgressMember/findById',//查询单条数据
            page: rewriteUrl + 'ProgressMember/page',//分页列表(带权限过滤)
            pageByStructureId: rewriteUrl + 'ProgressMember/pageByStructureId',//根据StructureId查询数据
            updateEntity: rewriteUrl + 'ProgressMember/updateEntity',//修改数据
        },
        partyMemberEducation: {
            addEntity: rewriteUrl + 'course/schedule/addEntity',//新增数据 
            insertEntity: rewriteUrl + 'course/schedule/insertEntity',//新增数据
            logic: rewriteUrl + 'course/schedule/delete/logic',//逻辑删除数据
            detailById: rewriteUrl + 'course/schedule/detailById',//查询单条数据详情 
            findById: rewriteUrl + 'course/schedule/findById',//查询单条数据 
            page: rewriteUrl + 'course/schedule/page',//分页列表(带权限过滤) 
            pageByStructureId: rewriteUrl + 'course/schedule/pageByStructureId',//根据StructureId查询数据 
            updateEntity: rewriteUrl + 'course/schedule/updateEntity',//修改数据
        },
        //横幅位置
        bannerLocation: {
            page: rewriteUrl + 'bannerLocation/page',//分页列表
            addEntity: rewriteUrl + 'bannerLocation/addEntity',//新增
            updateEntity: rewriteUrl + 'bannerLocation/updateEntity',//修改
            deleteLogic: rewriteUrl + 'bannerLocation/delete/logic',//删除
        },
        //横幅管理
        bannerManage: {
            page: rewriteUrl + 'bannerManage/page',//分页列表
            addEntity: rewriteUrl + 'bannerManage/addEntity',//新增
            updateEntity: rewriteUrl + 'bannerManage/updateEntity',//修改
            deleteLogic: rewriteUrl + 'bannerManage/delete/logic',//删除
            getBannerType: rewriteUrl + "bannerManage/getBannerType",//根据type获取banner
        },
        //评论接口
        comment: {
            logic: rewriteUrl + 'common/comment/delete/logic',//逻辑删除数据
            findById: rewriteUrl + 'common/comment/findById',//查询单条数据
            insertEntity: rewriteUrl + 'common/comment/insertEntity',//新增评论
            page: rewriteUrl + 'common/comment/page',//分页列表
            update: rewriteUrl + 'common/comment/update',//修改评论
        },
        sourceManage: {
            existDirByBzBodyId: rewriteUrl + 'indicator/existDirByBzBodyId',//判断当前组织机构top数据是否存在
            getIndicatorTree: rewriteUrl + 'indicator/getIndicatorTree',//获取目录tree
            page: rewriteUrl + 'indicator/page',//分页列表(带权限过滤)
            getAllByParent: rewriteUrl + 'indicator/getAllByParent',//根据父类id获取子级所有数据
            getParentTreeByIndicatorId: rewriteUrl + 'indicator/getParentTreeByIndicatorId',//根据指标id获取父类tree
            makeNewFolder: rewriteUrl + 'indicator/makeNewFolder',//新建文件夹
            updateFolder: rewriteUrl + 'indicator/updateFolder',//修改文件夹
            findById: rewriteUrl + 'indicator/findById',//根据id查看详情
            delFileIndi: rewriteUrl + 'indicator/folder/delete',//根据id删除文件夹
        },
        infoFile: {
            page: rewriteUrl + 'infoFile/page',//分页列表(带权限过滤)
            getFileTypeList: rewriteUrl + 'infoFile/getFileTypeList',//获取所有的文件类型
            findAllByIndicatorId: rewriteUrl + 'infoFile/findAllByIndicatorId',//根据指标id获取所有文件
            insertFileRecord: rewriteUrl + 'infoFile/insertFileRecord',//添加文件
            renameFile: rewriteUrl + 'infoFile/renameFile',//重命名文件
            deleteEntityPhysical: rewriteUrl + 'infoFile/delete/physical',//物理刪除文件
        },
        //app菜单管理
        menuManage: {
            page: rewriteUrl + 'app/menuManage/page',//分页列表
            addEntity: rewriteUrl + 'app/menuManage/insertEntity',//新增
            updateEntity: rewriteUrl + 'app/menuManage/updateEntity',//修改
            deleteLogic: rewriteUrl + 'app/menuManage/delete/logic',//删除
        },
        //通知管理
        notice: {
            page: rewriteUrl + 'notice/page',//分页列表
            addEntity: rewriteUrl + 'notice/insert',//新增
            updateEntity: rewriteUrl + 'notice/update/notice',//修改
            deleteLogic: rewriteUrl + 'notice/delete/logic',//删除
        },
        //试题管理
        examQuestion: {
            addEntity: rewriteUrl + 'exam/question/add',//新增试题
            updateEntity: rewriteUrl + 'exam/question/update',//修改试题
            page: rewriteUrl + 'exam/question/page',//分页列表(带权限过滤)
            logic: rewriteUrl + 'exam/question/delete/logic',//逻辑删除数据
            changeStatus: rewriteUrl + 'exam/question/changeStatus',//试题启停用
        },
        //评议记录
        examRecords: {
            examRecordPage: rewriteUrl + "exam/record/page",//评议详情中获取个人评议详细
        },
        //试卷管理
        examPaper: {
            addEntity: rewriteUrl + 'exam/paper/add',//新增试卷
            updateEntity: rewriteUrl + 'exam/paper/update',//修改试卷
            page: rewriteUrl + 'exam/paper/page',//分页列表(带权限过滤)
            logic: rewriteUrl + 'exam/paper/delete/logic',//逻辑删除数据
            auto: rewriteUrl + 'exam/paper/selectQuestionAuto',//自动生成试卷问题
            changeStatus: rewriteUrl + 'exam/paper/changeStatus',//试卷启停用
        },
        //考试任务
        examTask: {
            addEntity: rewriteUrl + 'exam/task/add',//新增考试任务
            updateEntity: rewriteUrl + 'exam/task/update',//修改考试任务
            page: rewriteUrl + 'exam/task/page',//分页列表(带权限过滤)-- 民主评议
            examPage: rewriteUrl + 'exam/task/page/exam',//分页列表(带权限过滤) -- 考试
            logic: rewriteUrl + 'exam/task/delete/logic',//逻辑删除数据
            publish: rewriteUrl + 'exam/task/publish',//发布考试任务
            startOrFinish: rewriteUrl + 'exam/task/startOrFinishExam',//开始或者结束任务
            findById: rewriteUrl + '/exam/task/findById',//查询单条数据
            getAlreadyAppraisalListByYear: rewriteUrl + '/exam/task/getAlreadyAppraisalListByYear',//获取某年度已经被评议过的对象列表
        },
        //奖惩
        rewardRecord: {
            addEntity: rewriteUrl + 'rewardRecord/addEntity',//新增奖惩
            updateEntity: rewriteUrl + 'rewardRecord/updateEntity',//修改奖惩
            page: rewriteUrl + 'rewardRecord/page',//分页列表(带权限过滤)
            logicDelete: rewriteUrl + 'rewardRecord/delete/logic',//逻辑删除数据
            cancelEntity: rewriteUrl + 'rewardRecord/cancelEntity',//撤銷奖惩
        },
        //投票
        voting: {
            addEntity: rewriteUrl + 'poc/voting/add',//新增投票
            updateEntity: rewriteUrl + 'poc/voting/update',//修改投票
            page: rewriteUrl + 'poc/voting/pageByStructureId',//分页列表(带权限过滤)
            logicDelete: rewriteUrl + 'poc/voting/delete/logic',//逻辑删除数据
            findById: rewriteUrl + "poc/voting/eventDetailListByVotingId",//查询单条数据
            publishStatus: rewriteUrl + "poc/voting/votingOperate/publishStatus",//修改发布状态
        },
        //积分规则
        pointConfig: {
            addEntity: rewriteUrl + 'PointConfigController/addPointConfigData',//新增投票
            updateEntity: rewriteUrl + 'PointConfigController/updatePointConfigData',//修改投票
            page: rewriteUrl + 'PointConfigController/page',//分页列表(带权限过滤)
            logicDelete: rewriteUrl + 'PointConfigController/delete/logic',//逻辑删除数据
            updatePointConfigStatusData: rewriteUrl + "PointConfigController/updatePointConfigStatusData",//启用停用
            selectPointConfigDatas: rewriteUrl + 'PointConfigController/selectPointConfigDatas',//分页列表(带权限过滤)
        },
        //积分规则类别
        pointConfigType: {
            addEntity: rewriteUrl + 'PointConfigTypeController/addPointConfigData',//新增投票
            updateEntity: rewriteUrl + 'PointConfigTypeController/updatePointConfigTypeData',//修改投票
            page: rewriteUrl + 'PointConfigTypeController/selectDataByTree',//分页列表(带权限过滤)
            logicDelete: rewriteUrl + 'PointConfigTypeController/delete/logic',//逻辑删除数据
            enabled: rewriteUrl + "PointConfigTypeController/updatePointConfigTypeStatusData",//启用停用
            findConfigByTypeAndPointAnnualBootId: rewriteUrl + "PointConfigTypeController/findConfigByTypeAndPointAnnualBootId",//根据规则常量和启动表id查询规则
        },
        pointConfigTypeEvent: {
            pointConfigTypeEventSelectData: rewriteUrl + "PointConfigTypeController/pointConfigTypeEventSelectData",//奖惩事件类型类型
            page: rewriteUrl + "PointConfigTypeEventController/page",//分页获取
            update: rewriteUrl + "PointConfigTypeEventController/updatePointConfigTypeEventData",//修改
            enabled: rewriteUrl + "PointConfigTypeEventController/updatePointConfigTypeEventStatusData",//启用停用
        },
        startYearPoint: {
            start: rewriteUrl + "PointAnnualBootController/startYearPoint",//启动年度积分工作
            selectStartDataByYear: rewriteUrl + "PointAnnualBootController/selectStartDataByYear",//查询年度积分启动
            updateEntity: rewriteUrl + "PointAnnualBootController/updateEntity",//修改数据
            selectYearsAndSettlements: rewriteUrl + "PointAnnualBootController/selectYearsAndSettlements",//获取本党委启动的积分工作的年份和归档状态
        },

        //积分流水
        pointRecord: {
            page: rewriteUrl + "/pointRecord/page",//积分流水
        },
        //党员年度积分
        pointCommunist: {
            page: rewriteUrl + "point/communist/statistic/page",//党员年度积分
            disabled: rewriteUrl + "point/communist/statistic/disableCommunistStatistic",//启用停用
            statistic: rewriteUrl + "point/communist/statistic/findMemberPointStatistic",//分类统计
            add: rewriteUrl + "point/communist/statistic/addBranchMember",//新增党员积分
            update: rewriteUrl + "point/communist/statistic/editCommunistPoints",//修改党员积分
        },
        //党支部年度统计
        pointOrgYearly: {
            changeStatus: rewriteUrl + "pointPartyYearlyStatistic/changeStatus",//对于数据进行启停用操作
            logic: rewriteUrl + "pointPartyYearlyStatistic/delete/logic",//逻辑删除数据
            findById: rewriteUrl + "/pointPartyYearlyStatistic/findById",//查询单条数据
            page: rewriteUrl + "pointPartyYearlyStatistic/page",
            pageByStructureId: rewriteUrl + "pointPartyYearlyStatistic/pageByStructureId",//根据StructureId查询数据
            settlementPartyAndCommunistAnnualPoint: rewriteUrl + "pointPartyYearlyStatistic/settlementPartyAndCommunistAnnualPoint",//党支部和党员（领导）年度积分结算归档
        },
        //党支部季度统计
        pointOrgQuarterly: {
            changeStatus: rewriteUrl + "pointPartyStatisticQuarterlyDetail/changeStatus",//对于数据进行启停用操作
            logic: rewriteUrl + "pointPartyStatisticQuarterlyDetail/delete/logic",//逻辑删除数据
            findById: rewriteUrl + "pointPartyStatisticQuarterlyDetail/findById",//查询单条数据
            page: rewriteUrl + "pointPartyStatisticQuarterlyDetail/page",
            pageByStructureId: rewriteUrl + "pointPartyStatisticQuarterlyDetail/pageByStructureId",//根据StructureId查询数据
        },
        //党员年度统计
        pointPartyYearly: {
            addBranchMember: rewriteUrl + "point/communist/statistic/addBranchMember",//在某一个支部下新增党员
            annualPointCheck: rewriteUrl + "point/communist/statistic/annualPointCheck",//党员/党支部年度积分统计
            batchEditPerformPoint: rewriteUrl + "point/communist/statistic/batchEditPerformPoint",//批量编辑党员的业务考核分值 
            changeStatus: rewriteUrl + "point/communist/statistic/changeStatus",//对于数据进行启停用操作
            logic: rewriteUrl + "point/communist/statistic/delete/logic",//逻辑删除数据
            disableCommunistStatistic: rewriteUrl + "point/communist/statistic/disableCommunistStatistic",//党员个人积分在某一个支部下停用
            editCommunistPoints: rewriteUrl + "point/communist/statistic/editCommunistPoints",//党员积分数据编辑（业务考核和民主评议得分）
            findById: rewriteUrl + "point/communist/statistic/findById",//查询单条数据
            findMemberPointStatistic: rewriteUrl + "point/communist/statistic/findMemberPointStatistic",//党员动态积分分类统计
            memberPointCheck: rewriteUrl + "point/communist/statistic/memberPointCheck",//党员个人积分数据检查 -- 党员年度积分统计第一步
            page: rewriteUrl + "point/communist/statistic/page",
            pageByStructureId: rewriteUrl + "point/communist/statistic/pageByStructureId",//根据StructureId查询数据
            pointRank: rewriteUrl + "point/communist/statistic/pointRank",//党员积分排行榜
            settlePersonPointById: rewriteUrl + "point/communist/statistic/settlePersonPointById",//党员年度积分统计 归档,针对单个数据
            settlePersonPointByYear: rewriteUrl + "point/communist/statistic/settlePersonPointByYear",//党员年度积分统计 归档,针对全部数据
        }
    },
    //文件
    file: {
        uploadGetId: rewriteUrl + 'upload/id',//上传文件
        viewImg: rewriteUrl + '/views',//预览文件
        deleteMongo: rewriteUrl + '/delete',//根据ID删除文件
        downLoad: rewriteUrl + '/files',//根据ID删除文件
    }
}

export default apiConfig;
