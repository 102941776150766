import React, { Component } from 'react';
//import PcTitle from '../../common/components/title/Titles';
import { Spin, Icon, Select, message } from 'antd';
import MessageItem from './MessageItem';
import userPic from "../../img/index-04.png";
import pcService from '../../other/pc.service';
import urls from '../../configs/api.config';
import http from '../../common/axios/request';
import { getLocal } from '../../common/utils';
import './msMessage.less';

const { Option } = Select;

export class MsMessage extends Component {

    state = {
        loading: false,
        type: 0,
        typeEvent: 0,
        msgType: [],
        messageStatus: 0,
        navList: [
            { title: "公告", id: 0, active: true },
            { title: "通知", id: 1, active: false }
        ],
        contentList: [],
        userInfo: {},
        structureName: "",
        totalNumber: 0,
        unReadNumber: 0
    };

    /* 消息类型 */
    msgState = [
        { id: 2, name: "全部信息" },
        { id: 0, name: "未读信息" },
        { id: 1, name: "已读信息" }
    ];

    /*获取消息列表*/
    fetchData = (params = {}) => {
        let { userInfo, typeEvent, type } = this.state;
        this.setState({ loading: true }, () => {
            let _params = !!type ? { ...params, typeEvent, userId: userInfo.userId } : { ...params, userId: userInfo.userId }
            pcService.fetchData(urls.app.message.messageList, _params, null, data => {
                let contentList = data.rows;
                this.setState({ loading: false, contentList })
            }, err => {
                this.setState({ loading: false })
            })
        })
    }

    /* 切换type */
    toggleBar = (item) => {
        let { navList, type } = this.state;
        if (type === item.id) return;
        navList.map((v, n) => {
            if (v.id === item.id) {
                v.active = true;
            } else {
                v.active = false;
            }
        });
        this.setState({
            navList, type: item.id
        }, () => {
            let { type, messageStatus } = this.state;
            this.fetchData({ type, messageStatus });
        })
    }

    /* 更改状态回调 */
    messageStatusOnChange = (messageStatus, $event) => {
        this.setState({ messageStatus }, () => {
            let { type } = this.state;
            this.fetchData({ messageStatus, type });
        });
    }

    /* 标级为已读 */
    allRead = () => {
        //do somethings
        let { contentList } = this.state;
        let data = contentList.map(v => ({ id: v.id }));
        if (data.length === 0) {
            message.info("当前没有未读消息!");
            return;
        }
        this.setState({ loading: true }, () => {
            http.request({
                method: "post",
                url: urls.app.message.readMessages,
                data,
            }).then(res => {
                if (res.success) {
                    message.success("标记成功！");
                    this.componentWillMount();
                    return;
                } else {
                    message.error(res.message);
                }
                this.setState({ loading: false });
            }).catch(err => {
                this.setState({ loading: false });
            });
        });
    }

    /* 切换来源 */
    toggleSource = (_typeEvent) => {
        let { typeEvent } = this.state;
        if (_typeEvent === typeEvent) return;
        this.setState({
            typeEvent: _typeEvent
        }, () => {
            let { type, messageStatus } = this.state;
            this.fetchData({ type, messageStatus });
        });
    }

    getAllCount = () => {
        http.request({
            method: "get",
            url: urls.app.message.getMessageCount
        }).then(res => {
            if (res.success) {
                let { totalNumber = 0, unReadNumber = 0 } = res.data;
                this.setState({ totalNumber, unReadNumber })
            }
        }).catch(err => { })
    }

    componentWillMount() {
        let { type, messageStatus } = this.state;
        let userInfo = getLocal("user_info");
        let structureList = getLocal("structureList");
        let msgType = pcService.getDataDictionary("MSG_RESOURCE_TYPE");
        msgType = msgType.filter(v => v.id !== "msg_001");//去除类型为公告的数据
        msgType.unshift({ id: undefined, name: "全部" });
        let typeEvent = msgType[0].id;
        let structureName = "";
        if (structureList.length > 0) {
            structureName = structureList[0].name;
        }
        this.setState({ userInfo, structureName, msgType, typeEvent }, () => {
            this.fetchData({ type, messageStatus });
            this.getAllCount();
        });
    }

    render() {

        const { loading, navList, msgType, typeEvent, type, contentList, messageStatus, userInfo, structureName, totalNumber, unReadNumber } = this.state;
        const { toggleSource, toggleBar, msgState, messageStatusOnChange, allRead } = this;
        return (
            <div className="ms-message">
                <Spin spinning={loading}>
                    {/*  <PcTitle icon="message">待办通知</PcTitle> */}
                    <div className="mss-content">
                        <div className="mssc-left">
                            <div className="ml-top">
                                <div className="mlt-photo">
                                    <img src={userInfo.headImg ? `${urls.file.viewImg}/${userInfo.headImg}` : userPic} width="100" height="100" />
                                </div>
                                <h2>{userInfo.name}</h2>
                                <p>所属党组织：{structureName}</p>
                            </div>
                            <div className="ml-bottom">
                                <ul>
                                    <li>全部消息：{totalNumber}条</li>
                                    <li>未读消息：{unReadNumber}条</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mssc-right">
                            <div className="msr-tops">
                                <ul className="bars">
                                    {
                                        navList.map((v, n) => <li className={v.active ? "active" : ""} onClick={toggleBar.bind(this, v, n)} key={n}>{v.title}</li>)
                                    }
                                </ul>
                                <div className="right-actions">
                                    <a className="all-read" onClick={allRead}><Icon type="check-circle" /> 标记全部为已读</a>
                                    <Select size="small" value={messageStatus} onChange={messageStatusOnChange}>
                                        {
                                            msgState.map((v, n) => <Option key={n} value={v.id}>{v.name}</Option>)
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className="msr-content">
                                {!!type ? <div className="msr-type">消息来源:{msgType.map((v, n) => <span className={v.id === typeEvent ? "active" : ""} key={n} onClick={toggleSource.bind(this, v.id)}>{v.name}</span>)}</div> : null
                                }
                                <ul>
                                    {(!contentList || contentList.length === 0) ?
                                        <p>暂无数据！</p> :
                                        contentList.map((v, n) => <MessageItem key={n} type={v.type} liObj={v} />)
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default MsMessage;
