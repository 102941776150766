import React from 'react';
import {Form, Input, Row, Col,Card,Button,message} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import SelectPostTree from './SelectPostTree';
import { get,post } from './../../../common/axios';
import apiConfig from './../../../configs/api.config';
import pcService from './../../../other/pc.service';
import contantConfig from './../../../configs/constant.config';
const FormItem = Form.Item;

class CreatePostForm extends React.Component {
    

   state = {
      loading: false,
      formValueInit:{},
      selectTreeData:null
   };

   componentWillMount() {
    if (this.props.location.query.editId) {
        this.init(this.props.location.query.editId);
     }
   }

   init=(editId)=>
   {
        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.post.getPostById,
            params:{
                positionId:editId
            }
        }).then((res)=>{
            this.setState({
                loading:false,
                formValueInit:{
                    ...res
                }
            })
        }).catch((error)=>{
            this.setState({ loading:false});
        });
   }
   
   componentWillReceiveProps(nextProps)
   {
       if(nextProps.editId&&nextProps.editId!==this.props.editId)
       {
          this.init(nextProps.editId);
       }
   }

   handleSubmit=(e)=>{
        e.preventDefault();
            this.props.form.validateFields((err, values) => {
            if (!err) {
                let levelBy=0;
                let isNoChange=false;
                const selectTreeData=this.state.selectTreeData;
                if(this.props.location.query.editId)
                {
                    if(!selectTreeData)
                    {
                        isNoChange=true;
                        levelBy=this.state.formValueInit.levelBy;
                    }
                }
                if(!isNoChange)
                {
                    let parentLevel=selectTreeData.levelBy;
                    if(parentLevel)
                    {
                        levelBy=parseInt(parentLevel)+1;
                    }
                }
                let data;
                let url;
                if(!this.props.location.query.editId)
                {
                    url= apiConfig.rui.basic.post.addPost;
                    data={
                        ...values,
                        levelBy
                    }
                } 
                else
                {
                    url= apiConfig.rui.basic.post.editPost;
                    data={
                        ...this.state.formValueInit,
                        ...values,
                        levelBy
                    }
                } 
                this.setState({loading:true});
                post({
                    url:url,
                    data:data
                }).then((res)=>{
                    this.setState({loading:false});
                    message.success(contantConfig.SAVE_SUCCESS);
                    this.goBack(true);
                }).catch((error)=>{
                    message.error(contantConfig.SAVE_FAIL);
                    this.setState({ loading:false});
                });
            }
        });
   }

   handelCancel=()=>{
        this.goBack(false);
   }

   goBack=(isRefreshParent=false)=>{
       let panelId = pcService.getIdByPath("/app/postManage");
       pcService.removePanel(panelId,true,'/app/postList');
       //this.props.removeTabPane(this.props.paneKey,isRefreshParent);
   }

   handelSelectTreeData=(selectTreeData)=>{
        //console.log("selectTreeData",selectTreeData)
        this.setState({
            selectTreeData
        });
   }

    render() {
      const { form } = this.props;
      const { loading,formValueInit }=this.state;
      const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 }

        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 }
        }
      }

      return (
           <Card bordered={false}>
              <Form  onSubmit={this.handleSubmit}>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="父级岗位">
                            {form.getFieldDecorator('parentId', {
                                initialValue: formValueInit.parentId,
                                rules: [
                                    {required: true, message: '请选择父级岗位'},
                                ],
                            })(<SelectPostTree setSelectTreeData={this.handelSelectTreeData} />)}
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="名称">
                            {form.getFieldDecorator('positionName', {
                                initialValue: formValueInit.positionName,
                                rules: [
                                    {required: true, message: '请输入名称'},
                                    {pattern: /^[^\s]*$/, message: '请不要输入空格'}
                                ],
                            })(<Input placeholder="请输入"/>)}
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="排序">
                            {form.getFieldDecorator('orderBy', {
                                initialValue: formValueInit.orderBy,
                                rules: [
                                    {pattern: /^[^\s]*$/, message: '请不要输入空格'}
                                ],
                            })(<Input placeholder="请输入"/>)}
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                     <Col span={24} className="operationArea">
                         <Button onClick={this.handelCancel}>取消</Button>
                         <Button type="primary" htmlType="submit" loading={loading}>保存</Button>
                     </Col>
                </Row>
            </Form>
           </Card>
         )
      }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(CreatePostForm));