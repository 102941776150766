import React, { Component } from 'react';
import { Form, Button, Icon } from 'antd';
import FormsItem from './../../common/components/form/FormsItem';

const OrgInfoFormLeader = Form.create()(
    class extends Component {  
        render() {
            const { form, fieldsList = [], formStyle = {}, addCommitte, delCommitte, onSubmit, isHide } = this.props;
            const { getFieldDecorator } = form;
            const FormItem = Form.Item;
            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            }
            return (
                <Form onSubmit={onSubmit.bind(this, this.props.form)}>
                    {
                        fieldsList.map((item, index) => {
                            let FormItemStyle = item.editor === "hidden" ? { display: "none" } : null;
                            return (
                                <FormItem style={FormItemStyle} className={item.isIcon === true ? "has-icon" : ""} {...formItemLayout} label={item.displayName} key={index}>
                                    {
                                        getFieldDecorator(item.name,
                                            {
                                                initialValue: item.value,
                                                rules: item.rules,
                                                validateFirst: true
                                            })(<FormsItem item={item} form={form} /> )
                                    }
                                    <Icon type="minus-circle" className="minus-circle" data-key={index} onClick={delCommitte.bind(this, index)} style={{ display: item.isIcon === true ? 'block' : 'none' }} />
                                </FormItem>
                            )
                        })
                    }
                    <div style={{ width: 'fit-content', float: "right", marginRight: "30px", display: isHide ? "none" : "" }}>
                        <Button type="dashed" className="" onClick={addCommitte}> 增加委员 </Button>
                    </div>
                </Form>
            )
        }
    }
);

export default OrgInfoFormLeader;
