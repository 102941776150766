/**
 * http通用工具函数
 */
import axios from 'axios';
import qs from 'qs';
import { message } from 'antd';
import { removeCurrentUser, setUserTimeExpire } from '../../configs/user.service';
import { getLocal } from './../utils';
import appConfig from '../../configs/app.config';

/**
 * 配置是否使用formData数据
 */
const isUseFormData = true;

/**
 * 跨域请求时，使用凭证，允许后台在header响应头中带参数接收
 */
axios.defaults.withCredentials = true;
/**
 * 请求拦截器
 */
axios.interceptors.request.use(function (config) {
    let isLocal = false;
    isLocal = isLocalRequest(config.url);
    if (!isLocal) {
        //const currentUser = getCurrentUser();
        let poc_headers = getLocal("poc_headers");
         //上传特殊处理
        if (!!poc_headers && !config.data instanceof FormData) {
            config["headers"] = {
                ...poc_headers,
                ...config.headers
            };
        }
        /* if (currentUser) {
             config.headers = {
                 ...config.headers,
                 'ecmp_token': currentUser.ecmp_token
             }
         }*/
    }
    /* console.log("request", config); */
    return config;
}, function (error) {
    // 对请求错误做点什么
    console.error("请求错误", error);
    return Promise.reject(error);
});

/**
 * 获取ContentType
 * @param {*} requestType 请求类型，如果不传，则不设置
 */
const getContentType = (requestType) => {
    let contentType = {};

    if (requestType === "json") {
        contentType = {
            'Content-Type': 'application/json'
        }
    }
    else if (requestType === "formData") {
        contentType = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    return contentType;
};

/**
 * 需要退出的响应消息
 */
const isNeedLoginMsg = "请求要求身份认证!";
const isLoginSuccess = "登录成功.";
/**
 * 响应拦截器
 * 目前对项目自定义请求附件有影响
 */
axios.interceptors.response.use(function (response) {

    //上传特殊处理
    if (response.config.data instanceof FormData) {
        return {
            success: true,
            data: response.data,
            message: "上传成功！"
        }
    }
    let isLocal = false;
    isLocal = isLocalRequest(response.config.url);
    if (!isLocal) {
        if (response.data.status === 200 && response.data.success) {
            if (response.data.message === isLoginSuccess) {
                message.success(isLoginSuccess);
            }
            return response.data.data;
        }
        else {
            let errorMsg = response.data.message;
            if (response.data.status === 407) {
                errorMsg = isNeedLoginMsg;
                removeCurrentUser();
                setUserTimeExpire('expire');
                message.destroy();
                window.location.href = '/#/login';
                message.info("登录超时,请重新登录!");
            }
            throw new Error(errorMsg);
        }
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    console.error("响应错误", error);
    return Promise.reject(error);
});

/**
 * 是否本地请求
 * @param {*} url 
 */
const isLocalRequest = (url) => {
    if (url) {
        return url.includes('assets/');
    }
    else {
        return true;
    }
};

/**
 * get请求方法名
 */
export const httpGetMethod = "get";
/**
 * post请求方法名
 */
export const httpPostMethod = "post";
/**
 * delete请求方法
 */
export const httpDeleteMethod = "delete";

/**
 * 配置网络错误信息
 */
const HttpErrorMsgArray = [
    {
        //msg:'timeout of 15000ms exceeded', //对http自身响应超时错误格式信息进行匹配
        msg: 'timeout of ' + appConfig.httpClientTimeout.toString() + 'ms exceeded',
        showMsg: '网络连接超时，请稍后再试！',
    },
    {
        msg: 'Request failed with status code 400',
        showMsg: '请求服务接口错误，服务器无法理解，请联系管理员！'
    },
    {
        msg: 'Request failed with status code 401',
        showMsg: '请求服务接口未授权，需要身份认证，请联系管理员！'
    },
    {
        msg: 'Request failed with status code 403',
        showMsg: '请求服务器禁止跨域访问，请联系管理员！'
    },
    {
        msg: 'Request failed with status code 404',
        showMsg: '请求服务接口失败或者未找到相关服务接口，请联系管理员！'
    },
    {
        msg: 'Request failed with status code 500',
        showMsg: '请求服务器内部错误，请联系管理员！'
    },
    {
        msg: 'Request failed with status code 502',
        showMsg: '请求网关错误，无效的请求，请联系管理员！'
    },
    {
        msg: 'Request failed with status code 504',
        showMsg: '请求网关超时，未及时获取请求，请联系管理员！'
    },
    {
        msg: 'Network Error',
        showMsg: '网络请求错误异常，请稍后再试！'
    }
];

/**
 * 处理网络请求错误信息
 * @param {*} msg 
 */
const getHttpErrorMsg = (msg) => {
    let showMsg;
    let errorMsgObj = HttpErrorMsgArray.find(item => item.msg === msg);
    if (errorMsgObj) {
        showMsg = errorMsgObj.showMsg;
    }
    return showMsg;
};

/**
 * 公用get请求
 * @param url       接口地址
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const get = ({ url, params, headers, config = {} }) => {
    return httpRequest({ url, params, headers, config });
};

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const post = ({ url, params, data, headers, config = {} }) => {
    return httpRequest({ url, params, headers, data, config, method: httpPostMethod });
};

/**
 * 公用delete请求
 * @param url       接口地址
 * @param params    接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const delHttp = ({ url, params, headers, config = {} }) => {
    return httpRequest({ url, params, headers, config, method: httpDeleteMethod });
};

/**
 * 公用http请求
 * @param {*} param0 
 */
export const httpRequest = ({ url, params, headers, data, method = httpGetMethod, config = {} }) => {

    let dataForFormData;
    if (data) {
        if (isUseFormData && !headers) {
            dataForFormData = qs.stringify(data);
        }
        else {
            dataForFormData = data;
        }
    }
    if (!headers) {
        if (isUseFormData) {
            headers = {
                ...getContentType('formData')
            }
        }
    }
    let axiosConfig = getAxiosConfig(url, params, dataForFormData, method, headers, config = {});
    return axios(axiosConfig).then(res => res).catch(err => {
        handleError(err);
    });
};

/**
 * 获取HTTP请求配置
 */
const getAxiosConfig = (url, params, data, method, headers, config = {}) => {
    let initConfig = getInitConfig();
    let axiosConfig = { ...initConfig, ...config, url, params, headers, data, method };
    return axiosConfig;
};

/**
 * 获取默认的HTTP请求配置
 */
const getInitConfig = () => {
    return {
        //请求超时的毫秒数
        timeout: appConfig.httpClientTimeout
    }
};

/**
 * 错误处理
 */
const handleError = (error, msg = "接口异常") => {
    console.error("错误异常", error);
    const showMsg = getHttpErrorMsg(error.message);
    if (showMsg) {
        message.error(showMsg);
    }
    else if (error.message === isNeedLoginMsg) {
        //message.error(isNeedLoginMsg);
    }
    else {
        message.error(error.message);
    }
    throw new Error(error);
};
