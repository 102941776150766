import React, { Component } from 'react';
import { Button, Divider, Icon, message, Popover, Spin, Table } from 'antd';
import Forms from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import PcService from './../../other/pc.service';

const limitDecimals = (value) => {
    const reg = /^(\-)*(\d+)\.(\d\d).*$/;
    if (typeof value === 'string') {
        return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : ''
    } else if (typeof value === 'number') {
        return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : ''
    } else {
        return ''
    }
};

export class ItemBankModal extends Component {

    state = {
        create: true,
        selectedRowKeys: [],
        modalOptions: {
            title: "选项",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            lists: this.props.lists
        }
    };
    /* 新增、修改 表单列项 */
    itemFieldsList = [
        {
            name: "id",
            editor: "hidden",
            value: ""
        },
        {
            name: "examOption",
            editor: "select",
            value: "",
            originValue: PcService.getDataDictionary("EXAM_OPTION")[0].id,
            displayName: "选项",
            opts: PcService.getDataDictionary("EXAM_OPTION"),
            rules: [{ required: true, message: "请选择选项" }]
        },
        {
            name: "content",
            editor: "number",
            value: "",
            originValue: "",
            displayName: "选项分值",
            range: [0, 100],
            rules: [
                "required",
                {
                    validator: (rule, value, callback) => callback(value > 100 ? rule.message : undefined),
                    message: "选项分值不能大于100"
                }
            ],
            numberOptions: {
                formatter: limitDecimals,
                parser: limitDecimals
            }

        }
    ];
    detailItem = () => {

    };
    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    /* 新增修改民主评议 */
    createOne = (item, create) => {
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            PcService.bindFormdData(item, this.itemFieldsList);
        } else {
            _create = true;
            PcService.initFormList(this.itemFieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    };
    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        {/*  <a onClick={this.detailItem.bind(this, record)}><Icon type="info-circle" theme="outlined" /> 详情</a>
                        <Divider type="vertical" />*/}
                        <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                    </span>
                )
            }
        },
        {
            title: '选项',
            dataIndex: 'examOption',
            key: 'clode',
            render: (text) => PcService.numberToText(text, PcService.getDataDictionary("EXAM_OPTION"))
        },
        {
            title: '选项分值',
            dataIndex: 'content',
            render: (value, record, index) => {
                if (value.length > 20) {
                    return (
                        <Popover content={value} placement="topRight"
                            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
                            {value.slice(0, 20) + '...'}
                        </Popover>)
                } else {
                    return value;
                }
            }
        }
    ];
    /* modal确定回调函数 */
    onOk = (forms) => {
        const { setParentState } = this.props;
        let { modalOptions, create } = this.state;
        let that = this;
        forms.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                setTimeout(function () {
                    let newList = modalOptions.lists;
                    if (create) {
                        //判断是否有重复的选项
                        let flag = false;
                        newList.map(item => {
                            if (values.examOption === item.examOption) {
                                flag = true;
                            }
                        });
                        if (flag) {
                            message.error("选项重复");
                            return;
                        }
                        values.id = newList.length + 1;
                        newList.push(values);
                    } else {
                        //验证修改之后是否和其他选项重复
                        let flag = false;
                        let oldItemIndex = 0;
                        newList.map((item, index) => {
                            if (values.id === item.id) {
                                oldItemIndex = index;
                            }
                            if (values.examOption === item.examOption && values.id !== item.id) {
                                flag = true;
                            }
                        });
                        if (flag) {
                            message.error("选项重复");
                            return;
                        }
                        newList.splice(oldItemIndex, 1, values);
                    }
                    setParentState({ options: newList });
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            visible: false
                        }
                    })
                }, 1000);
            }
        });
    };

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    };

    /* 删除操作*/
    deleteItem = () => {
        let { modalOptions, selectedRowKeys } = this.state;
        let oldList = modalOptions.lists.concat();
        selectedRowKeys.map(key => {
            let object = oldList[key];
            modalOptions.lists.splice(modalOptions.lists.indexOf(object), 1)
        });
        this.setState({
            modalOptions: { ...modalOptions }
        })
    };

    render() {
        const { selectedRowKeys, modalOptions, create } = this.state;
        const { fieldsList = [], modalsLoading = false, formItemLayout = {}, formItemColumns, lists = [] } = this.props;
        const { columns, onSelectChange, createOne, onOk, onCancel, itemFieldsList, deleteItem } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const allowDel = selectedRowKeys.length > 0;
        return (
            <div className="itemBank-modal">
                <Spin spinning={modalsLoading}>
                    <div className="ibm-part">
                        <h2>基本信息</h2>
                        <Forms ref="forms" onSubmit={this.props.onOk} columns={formItemColumns} fieldsList={fieldsList} hideFooter
                            formItemLayout={formItemLayout} />
                    </div>
                    <div className="ibm-part">
                        <h2>选项</h2>
                        <div className="ant-form ant-form-horizontal">
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-4">
                                </div>
                                <div className="ant-col ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-20">
                                    <div className="sp-bars">
                                        <div className="sp-btns">
                                            <Button onClick={createOne} type="primary">新增</Button>
                                            <Divider type="vertical" />
                                            <Button disabled={!allowDel} onClick={deleteItem}>删除</Button>
                                        </div>
                                    </div>
                                    <Table size="small" bordered rowSelection={rowSelection} columns={columns}
                                        dataSource={lists} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <PcModal fieldsList={itemFieldsList} modal={modal} modalsLoading={modal.modalsLoading}
                        formItemLayout={formItemLayout} />
                </Spin>
            </div>
        )
    }
}

export default ItemBankModal;
