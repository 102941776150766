import React, { Component } from 'react';
import PcForm from '../../common/components/form/Forms';
import pcService from '../../other/pc.service';
import PcTable from '../../common/components/table/tableComponent';
import PcModal from '../../common/components/modal/Modals';
import urls from '../../configs/api.config';
import { Row, Button, Divider, Spin, Icon, message, Popconfirm, Popover } from 'antd';
import http from '../../common/axios/request';
import { getLocal } from '../../common/utils';
import './rewardsAndPunishments.less';
import DetailModals from '../../common/components/modal/DetailModals';

class OrgRap extends Component {

    state = {
        hasFinish: false,
        visible: false,
        loading: false,
        selectedRowKeys: [],
        partyMembers: [],
        structureList: [],
        pagination: {
            current: 1,
            pageSize: '20',
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "党支部奖励",
            visible: false,
            destroyOnClose: true,
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        dtModalOptions: {
            title: "党支部奖惩详情",
            visible: false,
            destroyOnClose: true,
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        lists: [],
        eventTypes: [],//事件类型列表
    }

    /* 表格列项 */
    columns = [{
        title: '序号',
        dataIndex: 'key1',
        align: 'center',
        width: 80,
        render: (text, record, index) => index + 1
    }, {
        title: '操作',
        key: 'action',
        align: 'left',
        width: 260,
        render: (text, record, index) => record.type !== "0" ?
            <span> <Popconfirm title="确定要撤销此项吗？" onConfirm={this.deleteItem.bind(this, record.id)} okText="确定"
                cancelText="取消">
                <a><Icon type="rollback" /> 撤销</a>
            </Popconfirm>
                <Divider type="vertical" />
                <a onClick={this.detailItem.bind(this, record)}><Icon type="info-circle" theme="outlined" /> 详情</a>
            </span> :
            <span>
                <a onClick={this.detailItem.bind(this, record)}><Icon type="info-circle" theme="outlined" /> 详情</a>
            </span>
    }, {
        title: '奖惩组织',
        dataIndex: 'rewarderOrgName',
        key: 'rewarderOrgName'
    }, {
        title: '奖惩类型',
        dataIndex: 'type',
        key: 'type',
        render: text => text === "1" ? "奖励" : "惩罚"
    }, {
        title: '奖惩说明',
        dataIndex: 'caption',
        key: 'caption',
        render: (value) => !!value && value.length > 20 ? (<Popover content={value} placement="topRight"
            overlayStyle={{ width: 200, wordBreak: 'break-all' }}>
            {value.slice(0, 20) + '...'}
        </Popover>) : value
    }, {
        title: '创建人',
        dataIndex: 'publisher',
        key: 'publisher', 
    }, {
        title: '创建时间',
        dataIndex: 'rewardTime',
        key: 'rewardTime',
        render: text => text ? text.substring(0, 10) : text
    }];

    /* 新增修改表单字段 */
    fieldsList = [{
        name: 'id',
        value: '',
        editor: 'hidden'
    }, {
        name: 'rewardType',
        value: 1,
        originValue: 1,
        editor: 'hidden'
    }, {
        name: 'type',
        displayName: '奖惩类型',
        editor: 'radio',
        unavailable: true,
        value: '',
        originValue: "1",
        opts: [
            { id: "1", name: "奖励" },
            //{ id: "-1", name: "惩罚" }
        ],
        rules: [{
            required: true,
            message: "请输入标题"
        }]
    }, {
        name: 'eventName',
        value: "",
        originValue: "",
        editor: 'hidden'
    }, {
        name: 'eventType',
        displayName: '事件类型',
        editor: 'node-select',
        value: '',
        originValue: "",
        opts: [],
        rules: [{
            required: true,
            message: "请选择事件类型"
        }],
        onChange: (item, form, values, titles) => {
            form.setFieldsValue({ eventName: titles[0] });
        }
    }, {
        name: 'rewarderOrgName',
        value: "",
        originValue: "",
        editor: "hidden"
    }, {
        name: 'rewarderOrgId',
        displayName: '奖惩组织',
        editor: 'node-select',
        value: '',
        originValue: [],
        opts: [],
        nodeOptions: {
            //treeCheckable: true,
            multiple: false,
            searchPlaceholder: "请选择奖惩组织"
        },
        onChange: (item, form, value, titles) => {
            form.setFieldsValue({ rewarderOrgName: titles[0] })
        },
        rules: [{
            required: true,
            message: "请选择奖惩组织"
        }]
    },
    {
        name: 'caption',
        displayName: '奖惩说明',
        editor: 'textarea',
        value: '',
        originValue: '',
        rules: [
            { max: 200, message: "最长为200个字符" }
        ]
    }, {
        name: "enclosureList",
        editor: "attachments",
        value: [],
        originValue: [],
        displayName: "附件",
        fileUpLoadOption: {
            action: urls.file.uploadGetId,
            onChange: (fileList, form, _onchange) => {
                let _value = fileList.map(v => ({
                    fileId: v.response,
                    fileName: v.name,
                    fileSize: v.size,
                    uid: v.response
                }));
                _onchange(_value);
            }
        }
    }];

    /* 搜索表单项 */
    searchFieldsList = [{
        name: "value",
        editor: "normal",
        value: "",
        originValue: "",
        hideDisplayName: true,
        displayName: "党组织名称"
    }];

    /* 详情 */
    detailItem = (_item, $event) => {
        let item = { ..._item, rewarderOrgId: _item.rewarderOrgId.split(",") };
        let { dtModalOptions, partyMembers, eventTypes, hasFinish } = this.state;
        let msPromise = new Promise((resolve) => {
            if (!hasFinish) {
                this.getpartyMember(resolve);
            } else {
                this.fieldsList.map(v => {
                    if (v.name === "eventType") v.opts = eventTypes;
                    if (v.name === "rewarderOrgId") v.opts = partyMembers;
                });
                resolve();
            }
        });
        msPromise.then(() => {
            pcService.bindFormdData(item, this.fieldsList);
            this.setState({
                dtModalOptions: {
                    ...dtModalOptions,
                    modalsLoading: false,
                    visible: true
                }
            })
        })

    }

    /* radio onChange事件 */
    onChangeCallBack = (from, value) => {
        if (value === 2) {
            this.fieldsList.map(v => {
                if (v.name === "date") {
                    v.editor = "hidden";
                }
            });
        } else {
            this.fieldsList.map(v => {
                if (v.name === "date") {
                    v.editor = "normal";
                }
            });
        }
        this.setState({});
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 获取列表数据 */
    fetchData = (params) => {
        let { pagination } = this.state;
        let { pageSize, current } = pagination;
        let _params = Object.assign({}, { pageSize, current, fields: "caption,rewarderOrgName", rewardType: 1 }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.rewardRecord.page, _params, null,
                (data) => {
                    let _pagination = pcService.getPagination(data, pagination);
                    this.setState({
                        loading: false,
                        lists: data.rows,
                        pagination: _pagination
                    });
                },
                () => {
                    this.setState({
                        loading: false
                    })
                }
            );
        });

    }

    /* 搜索 */
    searchOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (!errors) {
                this.fetchData(values);
            }
        });
    }

    /* 获取党组织下面的党员信息 */
    getpartyMember = (resolve) => {
        this.setState({ loading: true }, () => {
            http.all([
                http.request({
                    method: "get",
                    url: urls.app.pointConfigTypeEvent.pointConfigTypeEventSelectData,
                    params: { ruleType: "point_branch_addpoint_root" }
                }),
                http.request({
                    method: "get",
                    params: { flag: false, type: 0, injob: "0" },
                    url: urls.app.organization.selectStructureTreeById
                })
            ]).then(reses => {
                let opts1 = reses[1].data || [];
                let opts0 = reses[0].data || [];
                let orgAndUsers = pcService.renderOrgAndUserNodes({ data: opts1, type: 1 });
                let eventTypes = pcService.renderOrgAndUserNodes({
                    data: opts0,
                    comuId: "event",
                    comuName: "eventName",
                    orgId: "id",
                    orgName: "ruleName",
                    orgCField: "children",
                    comuCField: "pointConfigTypeEventList",
                    isRoot: true
                });
                this.fieldsList.map(v => {
                    if (v.name === "rewarderOrgId") {
                        v.opts = orgAndUsers;
                    }
                    if (v.name === "eventType") {
                        v.opts = eventTypes;
                    }
                });
                this.setState({ loading: false, hasFinish: true, partyMembers: orgAndUsers, eventTypes });
                resolve();
            }).catch((err) => {
                this.setState({ loading: false });
                resolve();
            });
        })
    }


    /* 新增信息公告 */
    createOne = (_item, create) => {
        let item = { ..._item };
        let { modalOptions, hasFinish } = this.state;
        let _create = false;
        let that = this;
        let msPromise = new Promise((resolve) => {
            if (!hasFinish) {
                this.getpartyMember(resolve);
            } else {
                resolve();
            }
        });
        msPromise.then(() => {
            if (create === false) {
                pcService.bindFormdData(item, this.fieldsList);
            } else {
                _create = true;
                pcService.initFormList(this.fieldsList);
            }
            that.setState({
                modalOptions: {
                    ...modalOptions,
                    visible: true,
                },
                create: _create
            });
        })
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, create } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = "", msg = "";
                if (create) {
                    url = urls.app.rewardRecord.addEntity;
                    msg = "新增";
                } else {
                    url = urls.app.rewardRecord.updateEntity;
                    msg = "修改";
                }
                this.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: true
                    }
                });
                pcService.formSubmit(url, create, values, null, (data) => {
                    message.success(msg + "操作成功!");
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: false
                        }
                    }, this.fetchData);
                }, (msg) => {
                    message.error(msg);
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false,
                            visible: true
                        }
                    });
                });
            }
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 详情modal ok回调 */
    dtOnOk = () => {
        let { dtModalOptions } = this.state;
        this.setState({
            dtModalOptions: {
                ...dtModalOptions,
                visible: false
            }
        });
    }

    /* 删除 =>撤销*/
    deleteItem = (ids) => {
        let that = this;
        this.setState({ loading: true });
        pcService.formSubmit(urls.app.rewardRecord.cancelEntity, false, { ids }, {},
            (data) => {
                message.success("撤销成功!");
                setTimeout(function () {
                    that.componentDidMount();
                }, 500);
            }, errMsg => {
                message.error(errMsg);
                this.setState({
                    loading: false
                });
            }
        );
    }

    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
        let structureList = getLocal("structureList");
        this.setState({ structureList });
    }

    componentDidMount() {
        let forms = this.refs.pcForm.getForm();
        !!forms && this.searchOnSubmit(forms);
    }

    render() {
        const { loading, selectedRowKeys, dtModalOptions, pagination, lists, modalOptions, create, visible } = this.state;
        const { handleVisibleChange, dtOnOk, cancel, onSelectChange, columns, fetchData, searchFieldsList, searchOnSubmit, createOne, fieldsList, onOk, onCancel, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, title: (create ? "新增" : "编辑") + modalOptions.title, onOk, onCancel };
        const dtModal = { ...dtModalOptions, onOk: dtOnOk, onCancel: dtOnOk }
        //const allowDelete = selectedRowKeys.length > 0;
        const dtFieldsList = fieldsList.map(v => ({ ...v, unavailable: true }));
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 }
            }
        };
        const dformItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        return (
            <Spin spinning={loading}>
                <Row className="info-rew">
                    <div className="op-navs">
                        <div className="opn-left">
                            <Button onClick={createOne} icon="file-add" type="primary">新增</Button>
                            {/* <Divider type="vertical" />
                            <Popconfirm
                                title="确定要删除吗?"
                                visible={visible}
                                onVisibleChange={handleVisibleChange.bind(this, allowDelete)}
                                onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                onCancel={cancel}
                                okText="确定"
                                cancelText="取消" >
                                <Button disabled={!allowDelete} icon="delete" type="default">删除</Button>
                            </Popconfirm> */}

                        </div>
                        <div className="opn-right">
                            <PcForm ref="pcForm" layout="inline" submitText="搜索" onSubmit={searchOnSubmit} fieldsList={searchFieldsList} />
                        </div>
                    </div>
                </Row>
                <div className="op-content">
                    <PcTable
                        //rowSelection={rowSelection}
                        columns={columns}
                        pagination={pagination}
                        dataSource={lists}
                        fetchData={fetchData}
                        bordered={false}
                    />
                </div>
                <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
                {/* <PcModal fieldsList={dtFieldsList} modal={dtModal} modalsLoading={dtModal.modalsLoading} formItemLayout={formItemLayout} />
             */}
                <DetailModals steped={false} fieldsList={dtFieldsList} modal={dtModal} formItemLayout={dformItemLayout} />
            </Spin>
        )
    }
}

export default OrgRap;
