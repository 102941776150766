import React, { Component } from 'react';
import PcForm from './../../common/components/form/Forms';
import PersonVoting from './PersonVoting';
import MatterVoting from './MatterVoting';
import { Divider, Button, message } from 'antd';
import pcService from '../../other/pc.service';

import './votingForm.less';

class VotingForm extends Component {

    state = {
        questions: []
    }

    cancelAdd = () => {
        let { pathname } = this.props.location;
        let targetKey = pcService.getIdByPath(pathname);
        pcService.removePanel(targetKey);
    }

    votingSubmit = () => {
        let forms = this.refs.formsSubmit.getForm();
        let { questions } = this.state;
        if (questions.length === 0 || questions.some(v => v.options && v.options.length === 0)) {
            message.info("请至少添加一个添加投票选项!");
            return;
        }
        let { onSubmit } = this.props;
        onSubmit(forms, questions);
    }

    // 父组件重传props时
    componentWillReceiveProps(nextProps) {
        this.setState({ questions: nextProps.itemData.questions });
    }

    componentWillMount() {
        this.setState({ questions: this.props.itemData.questions });
    }

    render() {
        const { fieldsList = [], type = 1, formItemLayout = {}, orgAndUsers = [], itemData = {}, setParentQuestionsState = null } = this.props;
        const { cancelAdd, votingSubmit } = this;
        const { questions } = itemData;
        const childProps = { questions, orgAndUsers, setParentQuestionsState }
        return (
            <div className="vtform">
                <div className="vtf-forms">
                    <h3>基本信息</h3>
                    <div className="vtf-content">
                        <PcForm ref="formsSubmit" hideFooter columns="2" formItemLayout={formItemLayout} fieldsList={fieldsList} />
                    </div>
                </div>
                <div className="vtf-forms">
                    <h3>投票选项</h3>
                    <div className="vtf-content">
                        {(type == 1 || !type) ? <PersonVoting {...childProps} /> : <MatterVoting {...childProps} />}
                        <div className="vtf-btns">
                            <Button onClick={cancelAdd} icon="redo">取消</Button>
                            <Divider type="vertical" />
                            <Button onClick={votingSubmit} type="primary" icon="check">确定</Button>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default VotingForm;