export const FormValidate = {
    "required": {required: true, message: "该项为必填项"},
    "contactNumber": {pattern: /(^1([345789])\d{9}$)|(^(\d{3,4}-?|\s)?\d{7,8})$/, message: '请输入正确的电话'},
    "mobilePhone": {pattern: /^1([345789])\d{9}$/, message: '请输入正确的手机号'},
    "telephone": {pattern: /^(\d{3,4}-?|\s)?\d{7,8}/, message: '请输入正确的座机号'},
    "idNumber": {pattern: /(^\d{15}$)|(^\d{17}([0-9]|X)$)/, message: '请输入正确的身份证号'},
    "chineseOrEnglish": {pattern: /^[A-Za-z\u4e00-\u9fa5]+$/, message: '只能输入中文或字母'},
    "postalCode": {pattern: /^[1-9](\d+){5}$/, message: '请输入正确的邮政编码'},
    "mail": {pattern: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/, message: '请输入正确的邮箱地址'},
    "chineseOrEnglishOrNumber": {pattern: /[\dA-Za-z\u4e00-\u9fa5]+$/, message: '只能输入中文、字母或数字'},
    "number": {pattern: /^[0-9]*$/, message: '只能输入数字'},
    "positiveNumber": {pattern: /^[0-9]\d*$/, message: '只能输入正整数'},
    "chinese": {pattern: /^[\u4e00-\u9fa5]+$/, message: '只能输入中文'},
    "english": {pattern: /^[A-Za-z]+$/, message: '只能输入字母'},
    "englishOrNumber": {pattern: /^[A-Za-z0-9_-]+$/, message: '只能输入字母和数字'},
    "money": {pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '输入的金额格式不正确'}
};