import React, { Component } from 'react';
import { Tree } from 'antd';
import './treeList.css';

const DirectoryTree = Tree.DirectoryTree;
const { TreeNode } = Tree;

class TreeList extends Component {


    render() {
        /* ******
        params
        valueAndKey:"value和key"
        childrenName:children名
        ****** */
        const { onSelect, onRightClick, treeList = [], selectedKeys = [], defaultExpandAll = false, valueAndKey = ["structureId", "structureName"], childrenName = "subStructure", getNodeTreeRightClickMenu = null } = this.props;
        const renderTreeNodes = treeList => treeList.map((item) => {
            let children = item[childrenName] || item.children;
            if (children) {
                return (
                    <TreeNode title={item[valueAndKey[1]]} key={item[valueAndKey[0]]} dataRef={item} >
                        {renderTreeNodes(children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item[valueAndKey[1]]} key={item[valueAndKey[0]]} />;
        })

        return (
            <div>
                {treeList.length === 0 ? <p style={{ paddingTop: 16, paddingLeft: 5 }}>暂无数据</p> :
                    <div>
                        <DirectoryTree defaultExpandAll={defaultExpandAll} selectedKeys={selectedKeys} className="my-tree"
                            onSelect={onSelect} onRightClick={onRightClick} >
                            {renderTreeNodes(treeList)}
                        </DirectoryTree>
                        {!!getNodeTreeRightClickMenu && getNodeTreeRightClickMenu()}
                    </div>
                }
            </div>
        )
    }
}

export default TreeList;
