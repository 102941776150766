import React from 'react';
import {Row, Col,Card,Button } from 'antd';
import {getCurrentUser} from '../../../configs/user.service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import './UserInfo.less';

class UserInfo extends React.Component {
   
     handelCancel=()=>{
           this.props.removeTabPane(this.props.paneKey,false);
     }
    render() {
      let user=getCurrentUser();
      if(user)
      {
        user=user.login_user;
      }
      return (
                user?<Card className='userInfo' bordered={false}>
                  <Row className="item">
                      <Col span={12}>
                          <span className="title">名称:</span><span className="value">{user.name}</span>
                      </Col>
                      <Col span={12}>
                          <span className="title">登录名:</span><span  className="value">{user.userName}</span>
                      </Col>
                  </Row>
                  <Row className="item">
                      <Col span={12}>
                          <span className="title">编号:</span><span className="value">{user.no}</span>
                      </Col>
                      <Col span={12}>
                          <span className="title">邮箱:</span><span className="value">{user.email}</span>
                      </Col>
                  </Row>
                  <Row className="item">
                      <Col span={12}>
                          <span className="title">手机:</span><span className="value">{user.phone}</span>
                      </Col>
                      <Col span={12}>
                          <span className="title">备注:</span><span className="value">{user.remark}</span>
                      </Col>
                  </Row>
                  <Row >
                     <Col span={24} className="operationArea">
                         <Button onClick={this.handelCancel}>关闭</Button>
                     </Col>
                </Row>
               </Card>:null
         )
      }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  updateTabPane: bindActionCreators(updateTabPane, dispatch),
  removeTabPane: bindActionCreators(removeTabPane, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(UserInfo);