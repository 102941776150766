import React, { Component } from 'react';
import { Form, Input, Radio, Button, DatePicker, Cascader } from 'antd';
// import Search from 'antd/lib/input/Search';
// import files from './Files2';

const FormItem = Form.Item;

  
const SearchForms = Form.create()(
    class extends Component {
  
        /* 组件状态 */
    state = {
        subtype: [] 
    }

        render() {
            const { RangePicker } = DatePicker;
            const Search = Input.Search;

            const { form, formSubmit,subTypeForSearch  } = this.props; 
            const { getFieldDecorator } = form;
            let { subtype = [] } = this.state;


            const onReset = () => { 
                form.resetFields();
            }
            const getTypeInfo = () => {
                let dataTemp = [];
                if(this.state.subtype.length) return;
                subTypeForSearch.map((value, key) => {
                    dataTemp.push({
                        label: value.name,
                        value: value.value
                    })
                })
                this.setState({
                    subtype: dataTemp
                }) 
            }
             
            return (
                <Form layout="inline" onSubmit={formSubmit.bind(this, form)} > 
                    <div className="my-search-new"> 
                        <Form.Item label="文件类型" >
                            {getFieldDecorator('subtype', { 
                                rules: [],
                            })( 
                                <Cascader className='my-cascader' onClick={getTypeInfo} placeholder="选择" options={subtype} />
                            )}
                        </Form.Item> 
                        <FormItem label="上传日期">
                            {getFieldDecorator('rangePicker', {
                                initialValue: '',
                                rules: [],
                                validateTrigger: 'onChange',
                                validateFirst: true
                            })(<RangePicker className='range-picker' />)
                            }
                        </FormItem> 
                        <FormItem label="文件名">
                            {getFieldDecorator('name', {
                                initialValue: '',
                                rules: [],
                                validateTrigger: 'onChange',
                                validateFirst: true
                            })(<Input placeholder="输入文件名字" />)
                            }
                        </FormItem>

                        <FormItem>
                            <Button type="primary" icon="search" style={{margin:'0 8px'}} onClick={formSubmit.bind(this, form)}>查询</Button>
                            <Button icon="redo" onClick={onReset}>重置</Button>
                        </FormItem>
                    </div>
                </Form>
            )
        }
    }
);



export default SearchForms;
