import {getLocal, setLocal, removeLocal, isNotEmpty, domainString} from '../common/utils';
import { post } from '../common/axios';
import apiConfig from './api.config';
import {removeCurrentMenus,getAuthoritiesCache} from './menu.service';
import {loginPost} from '../common/utils/iframePostMessage';
import appConfig, {
    getTenantSetCurrentCache,
    removeTenantSetCurrentCache,
    removeTenantSetGlobalCache, removetSystemSetUserCache, setSystemSetUserCache
} from "./app.config";

/**
 * 应用本地存储名称
 */
const CurrentUserCachKey="current_user"; //当前用户
export const IsloginPost="current_hosts"; //跨域地址
const RememberMe="rememberMe"; //记住我
const TimeExpire="timeExpire"; //超时标识

export const removeUserTimeExpire=()=>{
    return removeLocal(TimeExpire);
};
export const setUserTimeExpire=(value)=>{
    setLocal(TimeExpire,value);
};
export const getUserTimeExpire=()=>{
    return getLocal(TimeExpire);
};

export const getCurrentUser=()=>{
    let currentUser;
    let currentUserStr=getLocal(CurrentUserCachKey);
    if(currentUserStr&&currentUserStr!=='')
    {
        currentUser=JSON.parse(currentUserStr);
        const authorities=getAuthoritiesCache();
        currentUser.authorities=authorities;
    }
    return currentUser;
};

export const isDeveloper=()=>{
    const currentUser=getCurrentUser();
    return currentUser&&currentUser.login_user&&currentUser.login_user.userId==='1';
    //return currentUser&&currentUser.login_user&&currentUser.login_user.userName==='developer';
};

export const setCurrentUser=(currentUser)=>{
    removeLocal(IsloginPost);
    setLocal(CurrentUserCachKey,JSON.stringify(currentUser));
};

export const removeCurrentUser=()=>{
    removeLocal(IsloginPost);
    removeLocal(CurrentUserCachKey);
    //removetSystemSetUserCache();
    removeCurrentMenus();
    removeTenantSetCurrentCache();
    //完全不启用租户功能时，才清理租户本地缓存：
    if(!appConfig.isUseTenant&&!appConfig.isSelectTenant) removeTenantSetGlobalCache();
};

export const loginPostToIframe=(domainUrl,frameId)=>{
    if (domainUrl.includes(window.location.host)) return; //排除本地域名

    const domain=domainString(domainUrl); //解析得到当前访问的域名
    let isLoginPostArr=getLocal(IsloginPost); //集成功能的多个域名
    if (!isLoginPostArr||isLoginPostArr.indexOf(domain)===-1) { //控制同一个域名只发送一次，不重复发送
        if (domain && frameId) {
            loginPost(getCurrentUser(), domain, frameId);
        } else {
            if (domain) {
                loginPost(getCurrentUser(), domain);
            } else {
                loginPost(getCurrentUser());
            }
        }
        //把集成到平台的所有模块的域名都保存起来，表示已经跨域通知过它们，通知过不再发送
        setLocal(IsloginPost,!isLoginPostArr?domain:isLoginPostArr+','+domain);
    }
};

export const setRememberMe=(loginInfo)=>{
    setLocal(RememberMe,JSON.stringify(loginInfo));
};

export const getRememberMe=()=>{
    let loginInfo;
    let loginInfoStr=getLocal(RememberMe);
    if(isNotEmpty(loginInfoStr))
    {
        loginInfo=JSON.parse(loginInfoStr);
    }
    return loginInfo;
};

export const removeRememberMe=()=>{
    removeLocal(RememberMe);
};

export const logout=(history,isTimeout=false)=>{
    let url=apiConfig.rui.login.loginOut;
    post({
        url,
        params:{
            settingId:appConfig.isUseSystemSet?'enable_login_setting':''
        }
    }).then(res=>{
        removeCurrentUser(); //清空用户
        if(appConfig.isUseSystemSet&&res.login_setting){
            removetSystemSetUserCache(); // 初始清空
            setSystemSetUserCache(res.login_setting);
        }
        history.push(isTimeout?'/timeout':'/login');
    }).catch((error)=>{
        removeCurrentUser(); //清空用户
        history.push(isTimeout?'/timeout':'/login');
    })
};

export const logoutTenant=(history)=>{
    let url=apiConfig.rui.login.loginOut;
    let tenantId=getTenantSetCurrentCache();
    post({
        url:url,
        params:{
            tenantId:tenantId,
            settingId:appConfig.isUseSystemSet?'enable_login_setting':''
        }
    }).then(res=>{
        removeCurrentMenus();
        removeTenantSetCurrentCache();
        if(appConfig.isUseSystemSet&&res.login_setting){
            removetSystemSetUserCache(); // 初始清空
            setSystemSetUserCache(res.login_setting);
        }
        history.push(isNotEmpty(tenantId)?'/tenant':'/login');
    }).catch((error)=>{
        removeCurrentMenus();
        removeTenantSetCurrentCache();
        history.push(isNotEmpty(tenantId)?'/tenant':'/login');
    })
};
