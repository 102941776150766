import React, {PureComponent} from 'react';
import {Card, Row, Col, Button } from 'antd';
import './index.less';
import ColorSelect from './ColorSelect';

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6}
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18}
    }
};

// colorpicker默认参数，暂不做操作
const colorOptions = {
    onUpdate: () => {
    }
};

/**
 * 自定义主题组件
 * hyx 20190609
 */
class CustomizeTheme extends PureComponent {
    constructor(props) {
        super(props);
        this.colorMap = {};
    }

    handleSubmit = e => {
        const {form, onChange} = this.props;
        form.validateFields((err, values) => {
            if (!err && onChange) {
                onChange(values);
            }
        })
    }

    setThemeColor = obj => {
        const {onChange} = this.props;
        this.colorMap = {...this.colorMap, ...obj};
    }

    submitTheme = (e) => {
        e.preventDefault();
        const { onChange } = this.props;
        if (onChange)onChange(this.colorMap);
    }

    render() {
        return (
            <Card bordered={false} bodyStyle={{padding: '0px 10px 0px 10px'}}>
                <Row className="chooseColorItem" gutter={24}>
                    <Col span={6}>
                        <label>头部背景色：</label>
                    </Col>
                    <Col span={16}>
                        <ColorSelect varkey="@head-back_color" placeholder="请选择头部背景色" onChange={this.setThemeColor}/>
                    </Col>
                </Row>
                {/* <Row className="chooseColorItem" gutter={24}>
                    <Col span={6}>
                        <label>左侧背景色：</label>
                    </Col>
                    <Col span={16}>
                        <ColorSelect varkey="@sider-back_color" placeholder="请选择左侧背景色" onChange={this.setThemeColor}/>
                    </Col>
                </Row>
                <Row className="chooseColorItem" gutter={24}>
                    <Col span={6}>
                        <label>左侧字体色：</label>
                    </Col>
                    <Col span={16}>
                        <ColorSelect varkey="@sider-select_color" placeholder="请选择左侧字体色" onChange={this.setThemeColor}/>
                    </Col>
                </Row> */}
                <Row className="chooseColorItem" gutter={24}>
                    <Col span={6}>
                        <label>按钮背景色：</label>
                    </Col>
                    <Col span={16}>
                        <ColorSelect varkey="@select-color" placeholder="请选择按钮背景色" onChange={this.setThemeColor}/>
                    </Col>
                </Row>
                <Row className="chooseColorItem" gutter={24}>
                    <Col span={6}>
                        <label>表头背景色：</label>
                    </Col>
                    <Col span={16}>
                        <ColorSelect varkey="@table_header_color" placeholder="请选择表头背景色" onChange={this.setThemeColor}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="submitArea">
                        <Button icon="check" type="primary" onClick={this.submitTheme}>预览</Button>
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default CustomizeTheme;