import React, { Component } from 'react';
import { Spin, message } from 'antd';
import PcService from '../../other/pc.service';
import urls from './../../configs/api.config';
import './partyMemberDetail.less';
import httpService from '../../common/axios/request';
import appConfig from './../../configs/app.config';
import ShowList from './ShowList';
import dataJson from '../../json/data.json';
import { getLocal } from '../../common/utils';
import AttachList from './AttachList';
import defaultPic from './../../img/defaultPic.jpg';
import defaultGirl from './../../img/defaultGirl.jpg';

export class PartyMemberDetail extends Component {

    state = {
        loading: false,
        userName: "",
        userPhoto: "",
    }
    maritalStatus = [
        { id: 1, name: "已婚" },
        { id: 2, name: "未婚" },
    ]
    degree = [
        { id: 1, name: "小学" },
        { id: 2, name: "初中" },
        { id: 3, name: "高中（中专）" },
        { id: 4, name: "专科（大专）" },
        { id: 5, name: "本科" },
        { id: 6, name: "硕士" },
        { id: 7, name: "博士" },
    ]
    isOrNot = [
        { id: true, name: '是' },
        { id: false, name: '否' }
    ]
    skillScal = [
        { id: "1", name: "初级职称" },
        { id: "2", name: "中级职称" },
        { id: "3", name: "高级职称" },
    ]

    //基本信息
    baseInfo = [
        { name: "sex", displayName: "性别", value: "", enumsArr: PcService.getDataDictionary("SEX") },
        { name: "nativePlace", displayName: "籍贯", value: "" },
        { name: "nation", displayName: "民族", value: "", enumsArr: PcService.getDataDictionary("NATION") },
        { name: "birthday", displayName: "出生日期", value: "" },
        { name: "maritalStatus", displayName: "婚姻状况", value: "", enumsArr: this.maritalStatus },
        { name: "idCard", displayName: "身份证号", value: "" },
        // { name: "familyAddress", displayName: "家庭住址", value: "" },
        { name: "residence", displayName: "居住地", value: "" },
        { name: "specialSkill", displayName: "有何专长", value: "" },
        { name: "info", displayName: "个人简介", value: "" },
    ];

    /* 联系信息 */
    connectionInfo = [
        { name: "tel", displayName: "手机号码", value: "" },
        { name: "email", displayName: "电子邮箱", value: "" },
        { name: "qq", displayName: "QQ", value: "" },
    ];

    /* 教育信息 */
    educationInfo = [
        { name: "degree", displayName: "学历", value: "", enumsArr: this.degree },
        { name: "university", displayName: "毕业院校", value: "" },
        { name: "specialty", displayName: "所学专业", value: "" }
    ];

    /* 考核信息 */
    /* assessmentInfo = [
        { name: "conversionTime", displayName: "加入党组织时间", value: "" }
    ] */

    /* 党籍信息 */
    nativePlaceInfo = [
        { name: "partyName", displayName: "所属党组织", value: "" },
        { name: "type", displayName: "人员类别", value: "", enumsArr: dataJson['MEMEBER_TYPE'] },
        { name: "inJob", displayName: "党内职务", value: "", columns: 1 },
        { name: "memberShipStatus", displayName: "党籍状态", value: "", enumsArr: PcService.getDataDictionary("MEMBER_SHIP_STATUS") },
        { name: "conversionTime", displayName: "转正时间", value: "" },
        { name: "isFlow", displayName: "是否流动党员", value: "", enumsArr: this.isOrNot },
        { name: "isHard", displayName: "是否困难党员", value: "", enumsArr: this.isOrNot },
        { name: "isOut", displayName: "是否长期在外党员", value: "", enumsArr: this.isOrNot },
        { name: "isMissing", displayName: "是否失联党员", value: "", enumsArr: this.isOrNot },
        { name: "isPartyCadre", displayName: "是否专职党务干部", value: "", enumsArr: this.isOrNot },
        { name: "isRetire", displayName: "是否退休", value: "", enumsArr: this.isOrNot },
        { name: "remark", displayName: "备注", value: "", columns: 1 },
    ];
    /* 工作信息 */
    jobesInfo = [
        { name: "unitName", displayName: "工作单位", value: "" },
        { name: "headship", displayName: "行政职务", value: "" },
        //{ name: "title", displayName: "技术职称", value: "", enumsArr: this.skillScal },
        { name: "duty", displayName: "工作岗位", value: "" },
    ];
    /* 党员类型跳转记录 */
    transforType = [
        { name: "applicationTime", displayName: "申请入党时间", value: "" },
        { name: "activistRecommender", displayName: "转为积极分子推荐人", value: "" },
        { name: "activistTime", displayName: "转为积极分子日期", value: "" },
        { name: "activistEnclosureList", displayName: "转为积极分子附件", value: "" },
        { name: "activistRemark", displayName: "转为积极分子备注", value: "" },
        { name: "progressRecommender", displayName: "转为发展对象推荐人", value: "" },
        { name: "progressTime", displayName: "转为发展对象日期", value: "" },
        { name: "progressEnclosureList", displayName: "转为发展对象附件", value: "" },
        { name: "progressRemark", displayName: "转为发展对象备注", value: "" },
        { name: "probationaryRecommender", displayName: "转为预备党员推荐人", value: "" },
        { name: "probationaryTime", displayName: "转为预备党员日期", value: "" },
        { name: "probationaryEnclosureList", displayName: "转为预备党员附件", value: "" },
        { name: "probationaryRemark", displayName: "转为预备党员备注", value: "" },
        { name: "recommender", displayName: "转为正式党员推荐人", value: "" },
        { name: "conversionTime", displayName: "转为正式党员日期", value: "" },
        { name: "conversionEnclosureList", displayName: "转为正式党员附件", value: "" },
        { name: "conversionRemark", displayName: "转为正式党员备注", value: "" },
    ];
    /* 附件信息 */
    enclosureList = [{ name: "enclosureList", displayName: "附件信息", value: "" }]

    /* 获取列表数据 */
    fetchData = () => {
        let { query = {} } = this.props.location;
        let { baseInfo, connectionInfo, jobesInfo, nativePlaceInfo, educationInfo, transforType, enclosureList } = this;
        this.setState({ loading: true }, () => {
            httpService.request({
                method: "get",
                url: urls.app.partyMember.getPartyMemberById,
                params: {
                    id: query.id
                }
            }).then(res => {
                if (res.success) {
                    let details = res.data;
                    let userName = details.name;
                    let userPhoto = details.photo;
                    let jobesInfoValue = details.jobInfoList[0] || {};
                    // let coversiTime = details.conversionTime;
                    // details.conversionTime = details.conversionTime ? details.conversionTime : details.probationaryTime
                    PcService.bindFormdData(details, baseInfo);
                    PcService.bindFormdData(details, connectionInfo);
                    PcService.bindFormdData(details, nativePlaceInfo);
                    PcService.bindFormdData(details, educationInfo);
                    PcService.bindFormdData(jobesInfoValue, jobesInfo);
                    // details.conversionTime = coversiTime;
                    PcService.bindFormdData(details, transforType);
                    PcService.bindFormdData(details, enclosureList);
                    //developmenet入口
                    let { query = {} } = this.props.location;
                    if ((query && query.type) || !details.conversionTime) {
                        this.nativePlaceInfo = this.nativePlaceInfo.filter(v => v.name !== "conversionTime")
                    }
                    this.setState({ loading: false, userName, userPhoto });
                } else {
                    this.setState({ loading: false });
                }
            }).catch(err => {
                message.error(err.message);
                this.setState({ loading: false });
            })
        });
    }

    /*  */
    componentWillMount() {
        this.fetchData();
    }

    render() {
        const { loading, userName, userPhoto } = this.state;
        const { baseInfo, connectionInfo, educationInfo, nativePlaceInfo, jobesInfo, transforType, enclosureList } = this;
        return (
            <Spin spinning={loading}>
                <div className="pm-detail">
                    <header>
                        <div className="left">
                            <img src={userPhoto ? appConfig.pocAddress + 'views/' + userPhoto
                                : baseInfo[0].value === "1" ? defaultGirl : defaultPic} alt="" />
                            {/* <img src={userPhoto} width="126" height="150" alt="" /> */}
                        </div>
                        <div className="right">
                            <h2>{userName}</h2>
                            <ShowList lists={baseInfo} columns="2" />
                        </div>
                    </header>
                    <nav>
                        <div className="item">
                            <h2>联系方式</h2>
                            <ShowList lists={connectionInfo} />
                        </div>
                        <div className="item">
                            <h2>最高学历</h2>
                            <ShowList lists={educationInfo} />
                        </div>
                        {/* <div className="item">
                            <h2>考核</h2>
                            <ShowList lists={assessmentInfo} />
                        </div> */}
                    </nav>
                    <footer>
                        <div className="item">
                            <h2>党籍信息</h2>
                            <ShowList lists={nativePlaceInfo} columns="2" />
                        </div>
                    </footer>
                    <footer>
                        <div className="item">
                            <h2>工作信息</h2>
                            <ShowList lists={jobesInfo} columns="2" />
                        </div>
                    </footer>
                    <footer>
                        <div className="item-request">
                            <h2>党员申请记录信息</h2>
                            <ShowList lists={transforType} columns="2" />
                        </div>
                    </footer>
                    <footer>
                        <div className="item">
                            <h2>附件信息</h2>
                            <AttachList itemAttach={enclosureList[0].value} />
                        </div>
                    </footer>
                </div>
            </Spin>
        )
    }
}

export default PartyMemberDetail;  
