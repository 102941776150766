import React, { Component } from 'react';
import { Table } from 'antd';
import { Resizable } from 'react-resizable';

const ResizeableTitle = (props) => {
    const { onResize, width, ...restProps } = props;
    if (!width) {
        return <th {...restProps} />;
    }
    return (
        <Resizable width={width} height={0} onResize={onResize}>
            <th {...restProps} />
        </Resizable>
    );
};

class tableComponent extends Component {

    state = {
        columns: this.props.columns || []
    };

    //集成 react-resizable 来实现可伸缩列。
    components = {
        header: {
            cell: ResizeableTitle,
        },
    };

    handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return { columns: nextColumns };
        });
    };

    render() {
        const {
            rowSelection = null,
            dataSource = [],
            pagination = false,
            fetchData, scroll = {},
            onRow,
            onChangeSort,
            rowClassName = "",
            _style = {},
            title,
            size = "default",
            rowKey = "id",
            columns = [],
            bordered = true,
        } = this.props;
        const _columns = columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
                width: column.width,
                onResize: this.handleResize(index),
            })
        }));

        /* 分页pageSize 变化的回调 */
        const onShowSizeChange = (current, pageSize) => {
            window.scrollTo(0, 0);
            let params = { pageSize, current };
            fetchData(params);
        };

        /* 页码跳转回调函数 */
        const onChange = (current, pageSize) => {
            window.scrollTo(0, 0);
            let params = { pageSize, current };
            fetchData(params);
        };

        return (
            <Table scroll={scroll} bordered={bordered} components={this.components}
                columns={_columns} dataSource={dataSource} style={_style} rowKey={rowKey}
                pagination={pagination ? {
                    showQuickJumper: true,
                    onShowSizeChange: onShowSizeChange,
                    onChange: onChange,
                    showSizeChanger: true,
                    showTotal: total => `总共${total}条`,
                    current: pagination.page,
                    ...pagination
                } : !!pagination}
                onRow={onRow}
                onChange={onChangeSort}
                rowClassName={rowClassName}
                title={title}
                rowSelection={rowSelection}
                size={size}>
            </Table>
        );
    }
}

export default tableComponent;

