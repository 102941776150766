const initState = { collapsed: false, showFilterMenu: false }
const MainReducer = (state = initState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case 'ClICK_MENU_ACTION':
            return {
                ...state,
                collapsed: !state.collapsed,
                showFilterMenu: state.showFilterMenu
            }
    }
    return state;
}
export default MainReducer;