import {Select_MenuItem,Select_TabPane,Update_TabPane,Remove_TabPane,
    RemoveOthers_TabPane,RemoveAll_TabPane,Refresh_TabPane,Reset_TabPane
} from '../actionTypes/menuTab.actionType';

export const selectMenuItem=(selectedMenuKey)=>{
    return {
        type:Select_MenuItem,
        selectedMenuKey
    }
};

export const selectTabPane=(selectedTabPaneKey)=>{
    return {
        type:Select_TabPane,
        selectedTabPaneKey,
    }
};

export const updateTabPane=(updateTabPane)=>{
    return {
        type:Update_TabPane,
        updateTabPane
    }
};

export const removeTabPane=(removeTabPaneKey,isRefreshParent=false)=>{
    return {
        type:Remove_TabPane,
        removeTabPaneKey,
        isRefreshParent
    }
};

export const removeOthersTabPane=()=>{
    return {
        type:RemoveOthers_TabPane,
    }
};

export const removeAllTabPane=()=>{
    return {
        type:RemoveAll_TabPane,
    }
};

export const refreshTabPane=()=>{
    return {
        type:Refresh_TabPane,
    }
};

export const resetTabPane=()=>{
    return {
        type:Reset_TabPane,
    }
};