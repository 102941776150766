import React from 'react';
import { Row, Col,Card,Tree,Button,Spin,message } from 'antd';
// import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTabPane,removeTabPane } from '../../../action/menuTab.action';
import { get,post } from './../../../common/axios';
import appConfig from './../../../configs/app.config';
import apiConfig from './../../../configs/api.config';
import {isNotEmpty,convertListToTreeJson} from '../../../common/utils';
import pcService from './../../../other/pc.service';
import constantConfig from './../../../configs/constant.config';

const { TreeNode } = Tree;

class SetRoles extends React.Component {

    constructor(props)
    {
        super(props)
        this.state = {
            loading:false,
            user:{},
            treeData:[],
            checkedKeys:[],
            //checkStrictly:true
       };
    }

    componentWillMount()
    {
        if(this.props.location.query.userId)
        {  
            this.init(this.props.location.query.userId);
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps.user&&nextProps.user.userId!==this.props.user.userId)
        {
            this.setState({
                user:nextProps.user
            },()=>{
                this.initUserRoles();
            })
        }
    }

    init=(userId)=> {
        this.initUserData(userId);
     }
      
     initUserData =(userId) =>{
         get({
             url:apiConfig.rui.basic.user.findById,
             params:{
                 userId:userId
             }
         }).then((res)=>{
             if(res)
             {
                 this.setState({
                     loading:false,
                     user:{
                         ...res
                     }
                 },this.initTreeData);
             }
         })
     }

    initTreeData=()=>{
        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.role.getAllRoles,
            params:{
                pageIndex:0,
                pageSize:appConfig.maxPageSize
            }
        }).then((res)=>{
             let roles=[];
             if(res&&res.list&&res.list.length>0)
             {
                roles=[
                    ...res.list
                ];
             }
             let treeData=convertListToTreeJson(roles,'0','subRole','roleId','levelBy');
             this.setState({
                 loading:false,
                 treeData
             },()=>{
                 this.initUserRoles();
             });
        }).catch((error)=>{
            this.setState({ loading:false});
         });
    }

    initUserRoles=()=>
    {
        this.setState({loading:true});
        get({
            url:apiConfig.rui.basic.user.findById,
            params:{
                userId:this.state.user.userId
            }
        }).then((res)=>{
            let checkedKeys=[];
            if(res&&res.role){
                for(let resItem of res.role)
                {
                    if(resItem)
                    {
                        checkedKeys.push(resItem.roleId);
                    }
                }
            }
            this.setState({loading:false,checkedKeys});
        }).catch((error)=>{
            this.setState({loading:false});
        })
    }

    renderTreeNodes = data => data.map((item) => {
        if (item.subRole) {
          return (
            <TreeNode title={item.roleName} key={item.roleId} value={item.roleId} dataRef={item}>
              {this.renderTreeNodes(item.subRole)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.roleName} key={item.roleId} value={item.roleId} dataRef={item} />;
    });


    handleTreeCheck= (checkedKeys, info) => {
         this.setState({
            checkedKeys:checkedKeys,
            //checkStrictly:false
         });
        }

    handelCancel=()=>{
        this.goBack();
    }

    handelSave=()=>{
        
        let roleIds='';
        for(let checkKey of this.state.checkedKeys)
        {
            roleIds+=checkKey+','
        }

        if(isNotEmpty(roleIds))
        {
            roleIds=roleIds.substr(0,roleIds.length-1);
        }        
        this.setState({loading:true});
        post({
            url:apiConfig.rui.basic.user.resetRoles,
            params:{
                userId:this.state.user.userId,
                roleIds
            }
        }).then((res)=>{
            this.setState({
                loading:false
            },()=>{
                message.success(constantConfig.SAVE_SUCCESS);
                this.goBack();
            });
        }).catch((error)=>{
            message.error(constantConfig.SAVE_FAIL);
            this.setState({loading:false});
        })
    }


    goBack=()=>{
        let panelId = pcService.getIdByPath("/app/setRoles");
        pcService.removePanel(panelId,true,'/app/userList');
        //this.props.removeTabPane(this.props.paneKey);
    }


    render() {
        let { loading, treeData,checkedKeys,user}=this.state;
      return (
           <Spin spinning={loading}>
                <Card bordered={false}>
                    <div className="operationTitle" style={{justifyContent:'center'}}>
                            <h1>
                            {`设置【${user.name}】的角色`}
                            </h1>
                    </div>
                    <Row gutter={24}>
                        <Col span={12} offset={4}> 
                        {/* <Scrollbars style={{ width: 800, height: 400}}> */}
                                <Tree /* checkStrictly = {this.state.checkStrictly} */
                                        checkable
                                        checkedKeys={checkedKeys}
                                        onCheck={this.handleTreeCheck} 
                                >
                                    {this.renderTreeNodes(treeData)}
                                </Tree>
                        {/* </Scrollbars> */}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                            <Col span={24} className="operationArea">
                                <Button onClick={this.handelCancel}>取消</Button>
                                <Button type="primary" loading={loading} onClick={this.handelSave}>保存</Button>
                            </Col>
                    </Row>
                </Card>
          </Spin>
         )
      }
}


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    updateTabPane: bindActionCreators(updateTabPane, dispatch),
    removeTabPane: bindActionCreators(removeTabPane, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(SetRoles);