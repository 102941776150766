import React, { Component } from 'react';
import VotingAdd from './VotingAdd';

class VotingUpdate extends Component {
    render() {

        const { id } = this.props.location.query;
        const { location } = this.props;
        return (
            <div>
                <VotingAdd updateId={id} location={location} />
            </div>
        )
    }
}

export default VotingUpdate;
