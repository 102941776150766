import React, { Component } from 'react';
import PcTitle from '../../common/components/title/Titles';
import { Row, Spin, Button, Divider, Icon, Popconfirm, message, Table, Modal, Select } from 'antd';
import PcTable from './../../common/components/table/tableComponent';
import PcForm from './../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import http from '../../common/axios/request';
import './integralRecords.less';
import { setJsonArray } from '../../common/utils';

const getpartyMemberByOrgId = (orgAndUsers = [], orgId) => orgAndUsers.map(v => {
    if (v.id === orgId) {
        return setJsonArray(v.children, "children");
    } else {
        return getpartyMemberByOrgId(v.children, orgId);
    }
});

const getUncheckUsers = (treeShowUsers = [], checkedUser = []) => {
    treeShowUsers = treeShowUsers.filter(v => v.selectable);
    treeShowUsers = treeShowUsers.map(v => ({ ...v, id: v.id.split("_")[0] }))
    let _treeShowUsers = [];
    treeShowUsers.map(v => {
        if (!(_treeShowUsers.some(_v => _v.id === v.id) || checkedUser.some(_v => _v.id === v.id))) {
            _treeShowUsers.push(v);
        }
    });
    //console.log(_treeShowUsers);
    return _treeShowUsers;
}

class IntegralRecords extends Component {

    state = {
        orgName: "",
        loading: false,
        pagination: {
            current: 1,
            pageSize: 100,
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "党员积分",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 600
        },
        sModalOptions: {
            title: "积分分类统计",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 700
        },
        eModalOptions: {
            title: "修改积分信息",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 700
        },
        dModalOptions: {
            title: "积分详细",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 700
        },
        lists: [],
        dLists: [],
        hasFinish: false,
        partyMembers: [],
        statices: [],
        ruleBoot: "",
        activeDetailItem: {}
    }

    /* 积分类型 */
    ruleTypes = [{ id: "", name: "全部" }].concat(pcService.getDataDictionary("POINT"));

    /* 表格列项 */
    columns = [

        {
            title: '序号',
            dataIndex: 'key1',
            render: (text, record, index) => index + 1

        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, event) => {
                return <span>
                    <a onClick={this.createOne.bind(this, record, false)}><Icon type="edit" theme="outlined" /> 编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm title={`确定要停用当前用户吗？`} onConfirm={this.enableItem.bind(this, record)} okText="确定"
                        cancelText="取消">
                        <a><Icon type="unlock" theme="outlined" /> 停用</a>
                    </Popconfirm>
                    <Divider type="vertical" />
                    <a onClick={this.detailitem.bind(this, record, false)}><Icon type="info-circle" theme="outlined" /> 详情</a>
                </span>
            }
        },
        {
            title: '党员姓名',
            dataIndex: 'communistName',
            key: 'communistName',
            render: (text, record, index) => <a onClick={this.statisticItem.bind(this, record)}>{text}</a>
        }, {
            title: '人员类型',
            dataIndex: 'communistType',
            key: 'communistType',
            //render: text => text === 0 ? "新闻" : "投票"
        }, {
            title: '开始月份',
            dataIndex: 'startMonth',
            key: 'startMonth',
            render: text => pcService.dateFormat(text, "yyyy-MM")
        }, {
            title: '停止月份',
            dataIndex: 'stopMonth',
            key: 'stopMonth',
            render: text => pcService.dateFormat(text, "yyyy-MM")
        }, {
            title: '业绩考核',
            dataIndex: 'performPoint',
            key: 'performPoint'
        }, {
            title: '动态积分',
            dataIndex: 'dymanicPoint',
            key: 'dymanicPoint'
        }, {
            title: '民主评议',
            dataIndex: 'commentPoint',
            key: 'commentPoint'
        }, {
            title: '年度积分',
            dataIndex: 'annualPoint',
            key: 'annualPoint'
        }
    ];

    /* 分类统计表头 */
    sColumns = [{
        title: '序号',
        dataIndex: 'key1',
        render: (text, record, index) => index + 1

    }, {
        title: '积分类型',
        dataIndex: 'ruleName',
        key: 'ruleName'
    }, {
        title: '年度积分',
        dataIndex: 'limitYearly',
        key: 'limitYearly'
    }, {
        title: '加分',
        dataIndex: 'pointAdd',
        key: 'pointAdd'
    }, {
        title: '扣分',
        dataIndex: 'pointDeduct',
        key: 'pointDeduct'
    }, {
        title: '实得分',
        dataIndex: 'point',
        key: 'point'
    }];

    /* 明细表头 */
    dColumns = [{
        title: '序号',
        dataIndex: 'key1',
        render: (text, record, index) => index + 1

    }, {
        title: '日期',
        dataIndex: 'obtainTime',
        key: 'obtainTime',
        render: text => pcService.dateFormat(text, "yyyy-MM-dd hh:mm")
    }, {
        title: '积分类型',
        dataIndex: 'ruleBootName',
        key: 'ruleBootName'
    }, {
        title: '积分事件',
        dataIndex: 'eventName',
        key: 'eventName'
    }, {
        title: '积分 ',
        dataIndex: 'point',
        key: 'point'
    }];;

    /* 搜索表单项 */
    searchFieldsList = [/* {
        name: "communistType",
        editor: "select",
        value: "",
        originValue: "",
        opts: [{ id: "", name: "全部" }],
        displayName: "人员类型"
    }, */ {
            name: "communistName",
            editor: "normal",
            value: "",
            originValue: "",
            displayName: "党员姓名"
        }];

    /* 获取积分流水 */
    getDetails = (item, resolve, _params = null) => {
        let { year } = this.props.location.query;
        let { dModalOptions } = this.state;
        let { communistId } = item;
        if (!!_params) {
            this.setState({ dModalOptions: { ...dModalOptions, modalsLoading: true } })
        } else {
            this.setState({ loading: true });
        }
        http.request({
            method: "get",
            url: urls.app.pointRecord.page,
            params: { communistId, yearly: year, ..._params, sortField: "obtainTime", sortType: "DESC" }
        }).then(res => {
            if (res.success) {
                let dLists = (res.data.rows || []).map((v, n) => ({ ...v, index: n }));
                this.setState({
                    loading: false,
                    dModalOptions: { ...dModalOptions, modalsLoading: false },
                    dLists
                });
            }
            resolve();
        }).catch(err => {
            this.setState({ loading: false });
            resolve();
        })
    }

    /* 详情 */
    detailitem = (item, params = null) => {
        let { dModalOptions } = this.state;
        let that = this;
        let msPromise = new Promise((resolve) => {
            this.getDetails(item, resolve, params);
        });
        msPromise.then(function () {
            that.setState({ dModalOptions: { ...dModalOptions, visible: true }, activeDetailItem: item });
        });
    }

    /* 获取用户分类积分 */
    getStatic = (item, resolve) => {
        let { yearly, communistId } = item;
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.app.pointCommunist.statistic,
                params: { yearly, userId: communistId }
            }).then(res => {
                if (res.success) {
                    let statices = (res.data || []).map((v, n) => ({ ...v, index: n }));
                    this.setState({ loading: false, statices });
                } else {
                    message.error(res.message);
                    this.setState({ loading: false });
                } resolve();
            }).catch(err => {
                this.setState({ loading: false });
                resolve();
            })
        });
    }

    /* 统计 */
    statisticItem = (item) => {
        let { sModalOptions } = this.state;
        let that = this;
        let msPromise = new Promise((resolve) => {
            this.getStatic(item, resolve);
        });
        msPromise.then(function () {
            that.setState({ sModalOptions: { ...sModalOptions, visible: true } });
        });
    }

    /* 新增表单列项 */
    fieldsList = [{
        name: 'orgId',
        editor: 'hidden',
        value: '',
        originValue: ''
    }, {
        name: 'orgName',
        displayName: '所属支部',
        editor: '',
        value: '',
        unavailable: true,
        originValue: '',
        opts: [],
        rules: [{
            message: "请输入党支部",
            required: true
        }]
    }, {
        name: 'pointAnnualBootId',
        editor: 'hidden',
        value: '',
        originValue: ''
    }, {
        name: 'communistName',
        editor: 'hidden',
        value: '',
        originValue: ''
    }, {
        name: 'communistId',
        displayName: '党员姓名',
        editor: 'select',
        value: '',
        originValue: '',
        opts: [],
        rules: [{
            message: "请选择党员",
            required: true
        }],
        onChange: (item, form, value, opts = []) => {
            let communistName = (opts.filter(v => v.id === value))[0] ? (opts.filter(v => v.id === value))[0].name : ""
            form.setFieldsValue({ communistName });
        }
    }, {
        name: 'communistType',
        displayName: '人员类型',
        editor: 'select',
        value: '',
        originValue: '',
        opts: [],
        rules: []
    }, {
        name: 'startMonth',
        displayName: '积分开始月份',
        editor: 'datePicker',
        value: null,
        originValue: null,
        maxDate: "stopMouth",
        timeOptions: {
            format: 'YYYY-MM'
        },
        rules: [{
            message: "请选择积分开始月份",
            required: true
        }]
    }, /* {
        name: 'stopMouth',
        displayName: '积分停止月份',
        editor: 'datePicker',
        timeOptions: {
            format: 'YYYY-MM'
        },
        value: null,
        minDate: "startMouth",
        originValue: null,
        rules: [{
            message: "请选择积分停止月份",
            required: true
        }]
    } */];

    /* 修改表单项 */
    eFieldsList = [
        {
            name: 'id',
            editor: 'hidden',
            value: '',
            originValue: ''
        },
        {
            name: 'orgId',
            editor: 'hidden',
            value: '',
            originValue: ''
        }, {
            name: 'orgName',
            displayName: '所属支部',
            editor: '',
            value: '',
            unavailable: true,
            originValue: '',
            opts: [],
            rules: [{
                message: "请输入党支部",
                required: true
            }]
        }, {
            name: 'pointAnnualBootId',
            editor: 'hidden',
            value: '',
            originValue: ''
        }, {
            name: 'communistId',
            editor: 'hidden',
            value: '',
            originValue: ''
        }, {
            name: 'communistName',
            displayName: '党员姓名',
            editor: '',
            unavailable: true,
            value: '',
            originValue: '',
            rules: [{
                message: "请输入党员姓名",
                required: true
            }]
        }, {
            name: 'communistType',
            displayName: '人员类型',
            editor: 'hidden',
            value: '',
            originValue: '',
            opts: [],
            rules: []
        }, {
            name: 'performPoint',
            displayName: '业务考核得分',
            editor: 'number',
            value: "",
            originValue: "",
            rules: [{
                message: "请输入业务考核得分",
                required: true
            }]
        }, {
            name: 'commentPoint',
            displayName: '民主评议得分',
            editor: 'number',
            value: "",
            originValue: "",
            rules: [{
                message: "请输入民主评议得分",
                required: true
            }]
        }];

    /* 获取列表数据 */
    fetchData = (params = {}) => {
        let { pagination } = this.state;
        let { year = 0, orgId = "", pointAnnualBootId = "" } = this.props.location.query;
        if (!(!!orgId && !!year && !!pointAnnualBootId)) return;
        let { pageSize, current } = this.state.pagination;
        let _params = Object.assign({}, { pageSize, current, yearly: year, orgId }, params);
        this.setState({ loading: true }, () => {
            pcService.fetchData(urls.app.pointCommunist.page, _params, null, (data) => {
                let _pagination = pcService.getPagination(data, pagination);
                this.setState({
                    loading: false,
                    lists: data.rows,
                    pagination: _pagination
                });
            }, () => {
                this.setState({
                    loading: false
                })
            });
        });
    }

    /* 搜索 */
    seachOnSubmit = (form) => {
        form.validateFields((errors, values) => {
            if (!errors) this.fetchData(values);
        });
    }

    /* 新增修改横幅 */
    createOne = (item, create = true) => {
        let { modalOptions, hasFinish, eModalOptions } = this.state;
        let { orgId = "", orgName = "", pointAnnualBootId } = this.props.location.query;
        if (!!orgId && create) {
            this.fieldsList.map(v => {
                if (v.name === "orgId") {
                    v.originValue = orgId;
                }
                if (v.name === "orgName") {
                    v.originValue = orgName;
                }
                if (v.name === "pointAnnualBootId") {
                    v.originValue = pointAnnualBootId;
                }
            });
        }
        let that = this;
        let myPromise = new Promise((resolve, reject) => {
            if (!hasFinish) this.getOrgAndUsers(resolve, create);
            else resolve();
        });
        myPromise.then(function () {
            let _create = false;
            if (create === false) {
                pcService.bindFormdData(item, that.eFieldsList);
                that.setState({
                    eModalOptions: {
                        ...eModalOptions,
                        visible: true,
                    },
                    create: _create
                });
            } else {
                _create = true;
                pcService.initFormList(that.fieldsList);
                that.setState({
                    modalOptions: {
                        ...modalOptions,
                        visible: true,
                    },
                    create: _create
                });
            }

        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions, eModalOptions, create } = this.state;
        forms.validateFields((errors, values) => {
            if (!errors) {
                let url = "", msg = "";
                if (create) {
                    url = urls.app.pointCommunist.add;
                    msg = "新增";
                } else {
                    url = urls.app.pointCommunist.update;
                    msg = "修改";
                }
                if (create) {
                    this.setState({
                        modalOptions: { ...modalOptions, modalsLoading: true }
                    });
                } else {
                    this.setState({
                        eModalOptions: { ...eModalOptions, modalsLoading: true }
                    });
                }
                if (!!values["beginTime"]) {
                    values["beginTime"] = pcService.dateFormat(values["beginTime"], "yyyy-MM")
                }
                pcService.formSubmit(url, false, values, null, () => {
                    message.success(msg + "操作成功!");
                    if (create) {
                        this.setState({
                            modalOptions: {
                                ...modalOptions, modalsLoading: false,
                                visible: false
                            }
                        }, this.fetchData);
                    } else {
                        this.setState({
                            eModalOptions: {
                                ...eModalOptions, modalsLoading: false,
                                visible: false
                            }
                        }, this.fetchData);
                    }
                }, (errMsg) => {
                    message.error(errMsg);
                    this.setState({
                        modalOptions: {
                            ...modalOptions,
                            modalsLoading: false
                        },
                        eModalOptions: {
                            ...eModalOptions,
                            modalsLoading: false
                        }
                    });
                })
            }
        });
    }

    /* 启用停用 */
    enableItem = (item) => {
        let that = this;
        this.setState({ loading: true });
        let data = { "id": item.id, "stopOrgId": item.orgId };
        pcService.formSubmit(urls.app.pointCommunist.disabled, false, data, null, (data) => {
            message.success("禁用成功!");
            setTimeout(function () {
                that.componentDidMount();
            }, 500);
        }, (errMsg) => {
            message.error(errMsg);
            this.setState({ loading: false });
        });
    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }
    /* sModal取消回调函数 */
    sOnCancel = () => {
        let { sModalOptions } = this.state;
        this.setState({
            sModalOptions: {
                ...sModalOptions,
                visible: false
            }
        });
    }
    /* dModal取消回调函数 */
    dOnCancel = () => {
        let { dModalOptions } = this.state;
        this.setState({
            dModalOptions: {
                ...dModalOptions,
                visible: false
            }
        });
    }

    /* 获取党组织以及成员 */
    getOrgAndUsers = (resolve = null, create = false) => {
        let { orgId = "" } = this.props.location.query;
        let { lists } = this.state;
        if (!orgId) return;
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                params: { flag: true, type: 0, id: orgId },
                url: urls.app.partyMember.getUsersByOrgIdBeforAdd
            }).then(res => {
                if (res.success) {
                    let opts = res.data || [];
                    /*  
                     let _users = pcService.renderOrgAndUserNodes({ data: opts });
                     let orgAndUsers = getpartyMemberByOrgId(_users, orgId)[0];
                     let users = create ? getUncheckUsers(orgAndUsers, lists) : orgAndUsers;
                     let orgs = pcService.renderOrgAndUserNodes({ data: opts, type: 1 }); */
                    let _opts = opts.filter(v => !lists.some(_v => _v.communistId === v.id));
                    this.fieldsList.map(v => {
                        /*  if (v.name === "orgId") {
                             v.opts = orgs;
                         } */
                        if (v.name === "communistId") {
                            v.opts = _opts;
                        }
                    });
                    /* let partyMembers = setJsonArray(orgAndUsers, "children", []); */
                    this.setState({ loading: false, hasFinish: false, partyMembers: opts });
                    resolve();
                } else {
                    this.setState({ loading: false });
                    resolve();
                }
            }).catch(() => {
                this.setState({ loading: false });
                resolve();
            })
        })

    }

    /* 切换积分规则 */
    ruleTypeOnChange = (ruleBoot) => {
        this.setState({ ruleBoot });
        let { activeDetailItem } = this.state;
        this.detailitem(activeDetailItem, { ruleBoot });
    }

    /* 编辑提交 */
    eOnOk = this.onOk;

    /* 编辑取消 */
    eOnCancel = () => {
        let { eModalOptions } = this.state;
        this.setState({
            eModalOptions: { ...eModalOptions, visible: false, modalsLoading: false }
        })
    }

    componentDidMount() {
        let form = this.refs.search.getForm();
        this.seachOnSubmit(form);
    }

    componentWillMount() {
        pcService.initFormList(this.searchFieldsList);
    }

    render() {
        const { loading, pagination, eModalOptions, orgName, ruleBoot, lists, dLists, statices, modalOptions, create, sModalOptions, dModalOptions } = this.state;
        const { columns, ruleTypeOnChange, sColumns, eFieldsList, dColumns, eOnOk, eOnCancel, ruleTypes = [], fetchData, searchFieldsList, seachOnSubmit, createOne, fieldsList, onOk, onCancel, sOnCancel, dOnCancel } = this;
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const sModals = { ...sModalOptions, onOk: sOnCancel, onCancel: sOnCancel };
        const eModals = { ...eModalOptions, onOk: eOnOk, onCancel: eOnCancel };
        const dModals = { ...dModalOptions, onOk: dOnCancel, onCancel: dOnCancel };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };
        return (
            <div className="integralRecords">
                <Spin spinning={loading}>
                    {/* <PcTitle icon="book">{orgName}{modalOptions.title}</PcTitle> */}
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button icon="file-add" onClick={createOne} type="primary">新增党员</Button>
                            </div>
                            <div className="sp-forms">
                                <PcForm ref="search" layout="inline" submitText="搜索" onSubmit={seachOnSubmit}
                                    fieldsList={searchFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                    <PcModal fieldsList={eFieldsList} modal={eModals} modalsLoading={eModals.modalsLoading}
                        formItemLayout={formItemLayout} />
                    <PcModal fieldsList={fieldsList} modal={modal} modalsLoading={modal.modalsLoading}
                        formItemLayout={formItemLayout} />
                    <Modal {...sModals}>
                        <Table size="small" rowKey={'index'} bordered={false} dataSource={statices} columns={sColumns} pagination={false}></Table>
                    </Modal>
                    <Modal {...dModals}>
                        <Spin spinning={dModals.modalsLoading}>
                            <div className="dintegral">
                                积分类型：<Select value={ruleBoot} onChange={ruleTypeOnChange}>
                                    {ruleTypes.map((v, n) => <Select.Option value={v.id} key={n}>{v.name}</Select.Option>)}
                                </Select>
                            </div>
                            <Table size="small" rowKey={'index'} bordered={false} dataSource={dLists} columns={dColumns} ></Table>
                        </Spin>
                    </Modal>
                </Spin>
            </div>
        )
    }
}

export default IntegralRecords;
