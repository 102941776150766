import React from 'react';
import {Spin,Icon } from "antd";
import IframeWidget  from './IframeWidget';
import {loginPostToIframe} from '../configs/user.service';
import {getIframeHeight} from '../common/utils';
import BaseComponent from './BaseComponent';
import appConfig from "../configs/app.config";

/**
 * 功能页面iframe容器
 */
class IframeContainer extends BaseComponent {

    constructor(props)
    {
        super(props);
        this.isExcute=false;
        this.state={
            moduleHeight:getIframeHeight(),
            loading:appConfig.isUsePlatLoading
        }
    }

    componentDidMount()
    {
        window.onresize = () => {
            this.changeFrameHeight();
        }
    }

    changeFrameHeight=()=>{
        this.setState({
            moduleHeight:getIframeHeight()
        })
    };

    onIframeload=(e)=>{
        this.changeFrameHeight();
        loginPostToIframe(e.target.src,e.target.id);
        setTimeout(()=>{this.setState({loading:false});},100);
    };

    render() {
        const {selectMenu}=this.props;
        const moduleKey=`model_${selectMenu.menuId}`;
        let url=selectMenu.menuUrl;
        return (
            <Spin size="large" tip="loading..." indicator={<Icon type="loading" spin />} spinning={this.state.loading}>
                <IframeWidget url={url} title={selectMenu.menuName} moduleKey={moduleKey} height={this.state.moduleHeight} onIframeload={this.onIframeload} />
            </Spin>
         )
      }
}

export default IframeContainer;
