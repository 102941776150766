import React, { Component } from 'react';
import { Spin, Button, message, DatePicker } from 'antd';
import PcForm from '../../common/components/form/Forms';
import urls from './../../configs/api.config';
import pcService from './../../other/pc.service';
import http from '../../common/axios/request';
import { getLocal, setJsonArray } from '../../common/utils';
import './startupIntegral.less';
import moment from 'moment';

export class StartupIntegral extends Component {

    state = {
        loading: false,
        orgs: [],
        rootStructureId: ""
    }

    /* 基础信息表单项 */
    basicFieldsList = [{
        name: 'orgName',
        editor: 'hidden',
        value: '',
        originValue: ''
    }, {
        name: 'orgId',
        displayName: '所属党委机构',
        editor: 'node-select',
        value: '',
        originValue: '',
        unavailable: true,
        opts: [],
        rules: [{
            message: "请选择所属党委机构",
            required: true
        }],
        onChange: (item, form, value, titles) => {
            form.setFieldsValue({ orgName: titles[0] })
        }
    }, {
        name: 'yearly',
        unavailable: true,
        value: null,
        originValue: new moment(),
        displayName: '积分年度',
        editor: "yearPicker",
        onChange: (item, form, value) => {
            if (!value) {
                form.setFieldsValue({ beginTime: null, stopTime: null });
                return;
            };
            let _value = value.year();
            let beginTime = moment(_value + "-01-01");
            let stopTime = moment(_value + "-12-31");
            form.setFieldsValue({ beginTime, stopTime });
        },
        rules: [
            { message: "请选择积分年度", required: true }
        ]
    }, {
        name: 'beginTime',
        value: null,
        originValue: new moment(new moment().year() + "-01-01"),
        editor: '',
        displayName: '开始日期',
        maxDate: "stopTime",
        editor: "datePicker",
        unavailable: true,
        timeOptions: {
            format: 'YYYY-MM-DD'
        },
        rules: [
            { message: "请选择开始日期", required: true }
        ]
    }, {
        name: 'stopTime',
        value: null,
        originValue: new moment(new moment().year() + "-12-31"),
        editor: '',
        displayName: '结束日期',
        minDate: "beginTime",
        editor: "datePicker",
        unavailable: true,
        timeOptions: {
            format: 'YYYY-MM-DD'
        },
        rules: [
            { message: "请选择结束日期", required: true }
        ]
    },
    {
        name: 'period',
        value: "12",
        originValue: "12",
        unavailable: true,
        displayName: '积分周期(月)',
        editor: "number",
        range: [0, 999999],
        rules: [
            { message: "请输入积分周期", required: true }
        ]
    }];
    /* 积分规则表单项 */
    rulesFieldsList = [{
        name: 'pointConfigId',
        value: null,
        originValue: null,
        editor: '',
        columns: 1,
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        },
        displayName: '积分规则',
        editor: "select",
        rules: [
            { message: "请选择积分规则", required: true }
        ]
    }, {
        name: 'communistPartyWorkWeight',
        displayName: '党员党建积分权重',
        editor: 'addOn',
        addOnOptions: {
            editor: "number",
            addOn: {
                attrs: { disabled: true },
                AddOn: (props) => <span> &nbsp;%</span>
            }
        },
        onChange: (item, forms, value) => {
            forms.setFieldsValue({
                communistBusinessWeight: value < 0 ? 0 : 100 - value
            });
        },
        value: '',
        originValue: '50',
        range: [0, 100],
        rules: []
    }, {
        name: 'communistBusinessWeight',
        displayName: '党员业务积分权重',
        editor: 'addOn',
        addOnOptions: {
            editor: "number",
            addOn: {
                attrs: { disabled: true },
                AddOn: (props) => <span> &nbsp;%</span>
            }
        },
        value: '',
        unavailable: true,
        originValue: '50',
        range: [0, 100],
        rules: []
    }, {
        name: 'leaderPersonalWeight',
        displayName: '领导个人积分权重',
        editor: 'addOn',
        addOnOptions: {
            editor: "number",
            addOn: {
                attrs: { disabled: true },
                AddOn: (props) => <span> &nbsp;%</span>
            }
        },
        onChange: (item, forms, value) => {
            forms.setFieldsValue({
                leaderAvgWeight: value < 0 ? 0 : 100 - value
            });
        },
        value: '',
        originValue: '50',
        range: [0, 100],
        rules: []
    }, {
        name: 'leaderAvgWeight',
        displayName: '领导平均积分权重',
        editor: 'addOn',
        addOnOptions: {
            editor: "number",
            addOn: {
                attrs: { disabled: true },
                AddOn: (props) => <span> &nbsp;%</span>
            }
        },
        value: '',
        originValue: '50',
        range: [0, 100],
        unavailable: true,
        rules: []
    }];

    /* 获取党组织下面的党员信息 */
    getpartyMember = (updateId) => {
        let poc_headers = getLocal("poc_headers");
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                params: { flag: false, type: 0 },
                url: urls.app.organization.selectStructureTreeById
            }).then(res => {
                if (res.success) {
                    let opts = res.data || [];
                    let orgs = pcService.renderOrgAndUserNodes({ data: opts, type: 1, renderAll: true });
                    this.basicFieldsList.map(v => {
                        if (v.name === "orgId") {
                            v.opts = orgs;
                            v.originValue = poc_headers.root_structure_id
                        }
                    });
                    let oneArr = setJsonArray(orgs, "children");
                    oneArr = oneArr.filter(v => v.id !== poc_headers.root_structure_id && v.pLevel < 2);
                    pcService.initFormList(this.basicFieldsList);
                    pcService.initFormList(this.rulesFieldsList);
                    this.setState({ loading: false, orgs: oneArr, rootStructureId: poc_headers.root_structure_id });
                } else {
                    this.setState({ loading: false });
                }
            }).catch(() => {
                this.setState({ loading: false });
            })
        })
    }

    /* 获取积分规则列表 */
    getIntegralRules = () => {
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                params: {
                    page: 0,
                    size: 9999,
                    useStatus: 1,
                    orgId: "structurId"
                },
                url: urls.app.pointConfig.selectPointConfigDatas
            }).then(res => {
                if (res.success) {
                    let opts = (res.data.rows || []).map(v => ({
                        name: v.configName,
                        id: v.id
                    }));
                    this.rulesFieldsList.map(v => {
                        if (v.name === "pointConfigId") {
                            v.opts = opts;
                            v.originValue = opts[0] ? opts[0].id : ""
                            return;
                        }
                    });
                    pcService.initFormList(this.rulesFieldsList);
                    this.setState({ loading: false });
                } else {
                    this.setState({ loading: false });
                }
            }).catch(() => {
                this.setState({ loading: false });
            })
        })
    }

    /* 全选 */
    allCheck = () => {
        let { orgs } = this.state;
        let isAllCheck = orgs.every(v => v.active);
        let _orgs = orgs.map(v => ({ ...v, active: isAllCheck ? false : true }));
        this.setState({ orgs: _orgs });
    }

    /* 提交 */
    onSubmit = () => {
        let { orgs } = this.state;
        let basicForm = this.refs.basicForm.getForm();
        let rulesForm = this.refs.rulesForm.getForm();
        let that = this;
        basicForm.validateFields((errors, values) => {
            if (!errors) {
                rulesForm.validateFields((errs, vals) => {
                    if (!errs) {
                        let applyOrgsIds = (orgs.filter(v => v.active)).map(v => v.id);
                        let allValues = { ...values, ...vals, applyOrgsIds: applyOrgsIds.join(",") }
                        allValues["beginTime"] = pcService.dateFormat(allValues["beginTime"], "yyyy-MM-dd");
                        allValues["stopTime"] = pcService.dateFormat(allValues["stopTime"], "yyyy-MM-dd");
                        allValues["yearly"] = pcService.dateFormat(allValues["yearly"], "yyyy");
                        if (applyOrgsIds.length !== 0) {
                            that.setState({ loading: true });
                            pcService.formSubmit(urls.app.startYearPoint.start, false, allValues, null, (data) => {
                                message.success("启动成功！");
                                that.setState({ loading: false }, () => {
                                    let panelId = pcService.getIdByPath("/app/startupIntegral");
                                    pcService.removePanel(panelId, true, "/app/orgIntegralRecords");
                                })
                            }, errMsg => {
                                message.error(errMsg);
                                that.setState({ loading: false });
                            })
                        } else {
                            message.info("请至少选择一个实施范围！");
                        }
                    }
                });
            }
        });

    }

    /* 选择组织 */
    checkOrg = (item = {}) => {
        let { orgs } = this.state;
        let _orgs = orgs.map(v => ({ ...v, active: v.id === item.id ? !v.active : !!v.active }));
        this.setState({ orgs: _orgs });
    }

    componentWillMount() {
        this.getpartyMember();
        this.getIntegralRules();
    }


    render() {
        const { loading, orgs } = this.state;
        const { basicFieldsList, checkOrg, rulesFieldsList, allCheck, onSubmit } = this;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        return (
            <div className="sti">
                <Spin spinning={loading}>
                    <div className="panel">
                        <h3>基础信息</h3>
                        <div className="content">
                            <PcForm ref="basicForm" columns="3" formItemLayout={formItemLayout} fieldsList={basicFieldsList} hideFooter />
                        </div>
                    </div>
                    <div className="panel">
                        <h3>积分规则配置</h3>
                        <div className="content">
                            <PcForm ref="rulesForm" columns="3" formItemLayout={formItemLayout} fieldsList={rulesFieldsList} hideFooter />
                        </div>
                    </div>
                    <div className="panel">
                        <h3>实施范围</h3>
                        <div className="content">
                            <div className="btns">
                                <Button onClick={allCheck}>全选</Button>
                            </div>
                            <div className="opts">
                                <ul>
                                    {orgs.map((v, n) => (
                                        <li key={n}>
                                            <span className={v.active ? "active" : ""} onClick={checkOrg.bind(this, v)}>{v.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="submit-btns">
                                <Button onClick={onSubmit} type="primary" icon="check">启动</Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default StartupIntegral
