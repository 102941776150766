import React, { Component } from 'react';
import { Row, Spin, Button, Divider, message, Icon, Popconfirm } from 'antd';
import PcTable from '../../common/components/table/tableComponent';
import PcForm from '../../common/components/form/Forms';
import PcModal from '../../common/components/modal/Modals';
import PcService from '../../other/pc.service';
import urls from '../../configs/api.config';
import http from '../../common/axios/request';
import pcService from '../../other/pc.service';
import moment from 'moment';
import './hardPartyMembers.css';

class HardPartyMembers extends Component {

    state = {
        loading: false,
        visible: false,
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            pageSizeOptions: ['20', '30', '40', '60', '80', '100']
        },
        create: true,
        modalOptions: {
            title: "困难党员库",
            visible: false,
            destroyOnClose: true,
            okText: "确定",
            cancelText: "取消",
            maskClosable: false,
            centered: true,
            modalsLoading: false,
            width: 800
        },
        lists: []
    }

    columns = [
        {
            title: '序号 ',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <span>
                        <a onClick={this.createOne.bind(this, record, false)} ><Icon type="edit" /> 编辑</a>
                    </span>
                )
            }
        },
        {
            title: '困难党员',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '困难类型',
            dataIndex: 'hardType',
            render: (text, record, index) => text ? PcService.numberToText(text, PcService.getDataDictionary("HARD_TYPE")) : ""
        },
        {
            title: '年收入',
            dataIndex: 'annualIncome'
        },
        {
            title: '劳动能力',
            dataIndex: 'workAble',
            render: (text, record, index) => text ? PcService.numberToText(text, PcService.getDataDictionary("HARD_ENERGY")) : ""
        },
        {
            title: '困难描述',
            dataIndex: 'hardInfo'
        },
        {
            title: '所属党支部',
            dataIndex: 'partyName'
        }
    ];

    seachFieldsList = [
        {
            name: "name",
            editor: "normal",
            value: "",
            displayName: "输入姓名",
            hideDisplayName: false,
            originValue: ""
        }, {
            name: "structureId",
            editor: "node-select",
            value: "",
            originValue: "",
            opts: [],
            displayName: "党组织",
        }];

    /* 新增、修改 表单列项 */
    fieldsList = [
        {
            name: "idTreeShow",
            editor: "node-select",
            value: "",
            originValue: "",
            displayName: "困难党员",
            rules: [
                { required: true, message: "请选择困难党员" }
            ],
            opts: [],
        },
        {
            name: "name",
            editor: "hidden",
            value: undefined,
            originValue: undefined,
            displayName: "困难党员",
            unavailable: true,
        },
        {
            name: "hardType",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "困难类型",
            opts: PcService.getDataDictionary("HARD_TYPE"),
            rules: [
                {
                    required: true,
                    message: "请选择困难类型"
                }
            ]
        },
        {
            name: "annualIncome",
            editor: "normal",
            value: "",
            displayName: "年收入(元)",
            rules: ["positiveNumber", { max: 5, message: '最多可以输入5位' }]
        },
        {
            name: "workAble",
            editor: "select",
            value: "",
            originValue: "",
            displayName: "劳动能力",
            opts: PcService.getDataDictionary("HARD_ENERGY"),
            rules: []
        },
        {
            name: "hardInfo",
            editor: "normal",
            value: "",
            displayName: "困难描述",
            rules: [
                { max: 200, message: '最多可以输入200个字符' },
            ]
        }
    ];
    /* 钩子函数 */
    componentDidMount() {
        this.fetchData();
    }

    /* 选中事件 */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    /* 获取列表数据 */
    fetchData = (params) => {
        this.setState({ loading: true }, () => {
            let _params = Object.assign({}, params, { isHard: true })
            PcService.fetchData(urls.app.organization.communistInfoPage, _params, null, (data) => {
                this.setState({
                    loading: false,
                    lists: data.rows
                });
            }, () => {
                this.setState({
                    loading: true
                })
            });
        });

    }

    /* 搜索 */
    seachOnSubmit = form => {
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            } else {
                this.fetchData(values);
            }
        });
    }

    /* 新增党组织关系 */
    createOne = (item, create) => {
        let _item = { ...item };
        let { modalOptions } = this.state;
        let _create = false;
        if (create === false) {
            this.fieldsList.map(v => {
                if (v.name === 'idTreeShow') {
                    v.editor = "hidden";
                }
                if (v.name === "name") {
                    v.editor = "normal";
                }
            })
            PcService.bindFormdData(_item, this.fieldsList);
        } else {
            _create = true;
            this.fieldsList.map(v => {
                if (v.name === 'idTreeShow') {
                    v.editor = "node-select";
                }
                if (v.name === "name") {
                    v.editor = "hidden";
                }
            })
            PcService.initFormList(this.fieldsList);
        }
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: true,
            },
            create: _create
        });
    }

    /* modal确定回调函数 */
    onOk = (forms) => {
        let { modalOptions } = this.state;
        let that = this;
        forms.validateFields((errors, value) => {
            if (errors) {
                return;
            } else {
                setTimeout(function () {
                    that.setState({
                        modalOptions: {
                            ...modalOptions,
                            visible: false
                        }
                    })
                }, 1000);
            }
            value.isHard = true;
            this.setState({
                modalOptions: {
                    ...modalOptions,
                    modalsLoading: true
                }
            })
            pcService.formSubmit(urls.app.partyMember.updateCommunistInfo, this.state.create, value, {}, () => {
                that.fetchData();
                that.getpartyMember();
                that.setState({
                    modalOptions: {
                        ...modalOptions,
                        visible: false,
                        modalsLoading: false
                    }
                })
            }, () => {
                that.setState({
                    modalOptions: {
                        ...modalOptions,
                        modalsLoading: false
                    }
                })
            }) 
        });

    }

    /* modal取消回调函数 */
    onCancel = () => {
        let { modalOptions } = this.state;
        this.setState({
            modalOptions: {
                ...modalOptions,
                visible: false
            }
        });
    }

    /* 删除列表项 */
    deleteItem = (item, $event) => {
        let url = urls.app.assistRecord.updateEntitysCommunistInfo;
        let addStrng = '';
        item.map(v => {
            addStrng += v + ',';
        });
        let that = this;
        let values = {};
        values.ids = addStrng;
        values.type = "isHard";
        that.setState({ loading: true });
        PcService.formSubmit(url, false, {}, values, {}, () => {
            message.success("删除成功!");
            setTimeout(function () {
                that.fetchData();
                that.getpartyMember();
            }, 500);
            that.setState({
                loading: false
            });
        }, () => {
            that.setState({
                loading: false
            });
        })
    };

    /* 获取党单位下面的党员信息 */
    getpartyMember = () => {
        let _params = {
            flag: true,
            type: 0,
            isHard: -1,
        }
        this.setState({ loading: true }, () => {
            http.request({
                method: "get",
                url: urls.app.organization.selectStructureTreeById,
                params: _params,
            }).then(res => {
                if (res.success) {
                    let treeData = res.data;
                    let lists = pcService.renderOrgAndUserNodes({ data: treeData, type: 0 });
                    this.fieldsList.map(v => {
                        if (v.name === "idTreeShow") {
                            v.opts = lists;
                        }
                    })
                    this.setState({ loading: false });
                } else {
                    message.info("未查询到党员信息");
                    this.setState({ loading: false });
                }
            }).catch(() => {
                this.setState({ loading: false });
            })
        })
    }
    /*显示隐藏onChange*/
    handleVisibleChange = (allowDelete, visible) => {
        this.setState({ visible: allowDelete && visible });
    }

    /* 取消删除 */
    cancel = () => {
        this.setState({ visible: false });
    }

    // 所属党组织
    getOrganizationTree = () => {
        let _params = {
            flag: false,
        }
        this.setState({ loading: true });
        http.request({
            method: "get",
            url: urls.app.organization.selectStructureTreeById,
            params: _params,
        }).then(res => {
            if (res.success) {
                let treeData = res.data;
                let lists = treeData ? treeData : [];
                this.seachFieldsList.map(v => {
                    if (v.name === "structureId") {
                        v.opts = [{ id: "", name: "全部" }].concat(lists);
                    }
                })
            } else {
                message.error(res.message);
            }
            this.setState({ loading: false });
        }).catch(err => {
            message.error(err.message);
            this.setState({ loading: false });
        });
    }

    componentWillMount() {
        PcService.initFormList(this.seachFieldsList);
        this.getpartyMember();
        //获取党组织
        this.getOrganizationTree();
    }

    render() {

        const { loading, selectedRowKeys, pagination, lists, modalOptions, create, visible } = this.state;
        const { handleVisibleChange, cancel, onSelectChange, columns, fetchData, seachFieldsList, seachOnSubmit, createOne, fieldsList, onOk, onCancel, deleteItem } = this;
        const rowSelection = { selectedRowKeys, onChange: onSelectChange };
        const modal = { ...modalOptions, onOk, onCancel, title: (create ? "新增" : "编辑") + modalOptions.title }
        const allowDel = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 }

            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 }
            }
        };
        return (
            <Spin spinning={loading}>
                <div className="simple-page">
                    <Row>
                        <div className="sp-bars">
                            <div className="sp-btns">
                                <Button onClick={createOne} icon="file-add" type="primary">新增</Button>
                                {/* <Button disabled={!allowDel} icon="delete" onClick={deleteItem.bind(this, selectedRowKeys)}>删除</Button> */}
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="确定要删除吗?"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange.bind(this, allowDel)}
                                    onConfirm={deleteItem.bind(this, selectedRowKeys)}
                                    onCancel={cancel}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button disabled={!allowDel} icon="delete" type="default">删除</Button>
                                </Popconfirm>
                            </div>
                            <div className="sp-forms">
                                <PcForm layout="inline" submitText="搜索" onSubmit={seachOnSubmit} showReset fieldsList={seachFieldsList} />
                            </div>
                        </div>
                        <div className="sp-content">
                            <PcTable
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                dataSource={lists}
                                fetchData={fetchData}
                                bordered={false}
                            />
                        </div>
                    </Row>
                </div>
                <PcModal fieldsList={fieldsList} modal={modal} columns="2" modalsLoading={modal.modalsLoading} formItemLayout={formItemLayout} />
            </Spin >
        )
    }
}

export default HardPartyMembers;
